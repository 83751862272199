import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./StartupsAndCommunities.module.css";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
// import profileImage from "../../assets/icons/defaultImage.jpg";
import startupImage from "../../assets/images/startupImage.jpg";
import CustomDropdown from "../../components/Dropdowns/CustomDropdown";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);

const startupsIndex = searchClient.initIndex("startups_index");
const communitiesIndex = searchClient.initIndex("communities_index");

function StartupsAndCommunities() {
  const [activeTab, setActiveTab] = useState("startups");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [categoryInput, setCategoryInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleTab = (tab) => {
    setActiveTab(tab);
    setNameInput("");
    setSelectedCountry(null);
    setSelectedCity(null);
    setCategoryInput("");
    setSelectedCategory(null);
    setCountryInputValue("");
    setCityInputValue("");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    if (tab === "startups") {
      fetchInitialStartups();
    } else {
      fetchInitialCommunities();
    }
  };

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = React.useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error loading countries:", error);
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      console.error("Error loading cities:", error);
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const checkIfAnyInputHasValue = () => {
    return (
      nameInput.trim() !== "" ||
      !!selectedCountry ||
      !!selectedCity ||
      (activeTab === "communities" && !!selectedCategory)
    );
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(value.trim() !== "");
  };

  const fetchInitialStartups = useCallback(async () => {
    if (!currentUser) return;
    setIsInitialLoading(true);
    try {
      const { hits } = await startupsIndex.search("", {
        hitsPerPage: 20,
      });
      const sortedResults = hits.sort(
        (a, b) => b.followingCount - a.followingCount
      );
      setInitialResults(sortedResults);
    } catch (error) {
      console.error("Error fetching initial startups:", error);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const fetchInitialCommunities = useCallback(async () => {
    if (!currentUser) return;
    setIsInitialLoading(true);
    try {
      const { hits } = await communitiesIndex.search("", {
        hitsPerPage: 20,
      });
      const sortedResults = hits.sort(
        (a, b) => b.membersCount - a.membersCount
      );
      setInitialResults(sortedResults);
    } catch (error) {
      console.error("Error fetching initial communities:", error);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const handleSearch = useCallback(async () => {
    if (!isChanged) return;
    setIsSearching(true);
    setHasSearched(true);
    try {
      const searchParams = {
        hitsPerPage: 20,
      };

      const filterConditions = [];
      const orConditions = [];

      if (nameInput) {
        searchParams.query = nameInput;
      }

      if (selectedCountry) {
        orConditions.push(`country:"${selectedCountry.name}"`);
      }

      if (selectedCity) {
        orConditions.push(`city:"${selectedCity.name}"`);
      }

      if (activeTab === "communities" && selectedCategory) {
        orConditions.push(`category:"${selectedCategory.name}"`);
      }

      if (orConditions.length > 0) {
        filterConditions.push(`(${orConditions.join(" OR ")})`);
      }

      if (filterConditions.length > 0) {
        searchParams.filters = filterConditions.join(" AND ");
      }

      const { hits } = await (activeTab === "startups"
        ? startupsIndex
        : communitiesIndex
      ).search(searchParams.query || "", searchParams);

      const sortedResults = hits.sort((a, b) =>
        activeTab === "startups"
          ? b.followingCount - a.followingCount
          : b.membersCount - a.membersCount
      );

      setSearchResults(sortedResults);
    } catch (error) {
      console.error("Error performing search:", error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    nameInput,
    selectedCountry,
    selectedCity,
    selectedCategory,
    activeTab,
    isChanged,
  ]);

  useEffect(() => {
    if (currentUser && initialResults.length === 0) {
      if (activeTab === "startups") {
        fetchInitialStartups();
      } else {
        fetchInitialCommunities();
      }
    }
  }, [
    currentUser,
    activeTab,
    fetchInitialStartups,
    fetchInitialCommunities,
    initialResults.length,
  ]);

  useEffect(() => {
    if (isSearching) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSearching, handleSearch]);

  const triggerSearch = () => {
    if (isChanged) {
      setIsSearching(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleItemClick = (item) => {
    if (activeTab === "startups") {
      navigate(`/startup/${item.startupUrlSlug}`);
    } else {
      navigate(`/community/${item.communityUrlSlug}`);
    }
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const handleRefresh = () => {
    setNameInput("");
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setCategoryInput("");
    setSelectedCategory(null);
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    if (activeTab === "startups") {
      fetchInitialStartups();
    } else {
      fetchInitialCommunities();
    }
  };

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage} />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.toggleButtons}>
              <button
                className={`${classes.toggleButton} ${
                  activeTab === "startups" ? classes.active : ""
                }`}
                onClick={() => toggleTab("startups")}
              >
                Startups
              </button>
              <button
                className={`${classes.toggleButton} ${
                  activeTab === "communities" ? classes.active : ""
                }`}
                onClick={() => toggleTab("communities")}
              >
                Communities
              </button>
            </div>

            <div className={classes.cardTitle}>
              Filters
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder={
                    activeTab === "startups"
                      ? "Ex: Acme Inc"
                      : "Ex: Tech Enthusiasts"
                  }
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {showFilters && (
                <div className={classes.filters}>
                  {activeTab === "communities" && (
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Category</div>
                      <CustomDropdown
                        options={[
                          { name: "Technology" },
                          { name: "Business" },
                          { name: "Design" },
                          { name: "Marketing" },
                          { name: "Education" },
                          { name: "Arts" },
                          { name: "Social Impact" },
                          { name: "Health & Wellness" },
                          { name: "Sports & Recreation" },
                          { name: "Other" },
                        ]}
                        selectedValue={selectedCategory}
                        onChange={setSelectedCategory}
                        inputValue={categoryInput}
                        setInputValue={setCategoryInput}
                        disabled={loading}
                        placeholder="Select a category"
                      />
                    </div>
                  )}
                  <div className={classes.bottom}>
                    <div htmlFor="country" className={classes.filterLabel}>
                      Country
                    </div>
                    <CustomDropdown
                      options={countries}
                      selectedValue={selectedCountry}
                      onChange={handleCountryChange}
                      inputValue={countryInputValue}
                      setInputValue={setCountryInputValue}
                      disabled={loading}
                      placeholder="Ex: United Kingdom"
                    />
                    <div
                      htmlFor="city"
                      className={`${classes.filterLabel} ${
                        !selectedCountry || loading ? classes.disabled : ""
                      }`}
                    >
                      City
                    </div>
                    <CustomDropdown
                      options={cities}
                      selectedValue={selectedCity}
                      onChange={handleCityChange}
                      inputValue={cityInputValue}
                      setInputValue={setCityInputValue}
                      disabled={!selectedCountry || loading}
                      placeholder="Ex: London"
                    />
                  </div>
                </div>
              )}

              <button
                type="button"
                className={`${classes.button} ${
                  isSearching ? classes.loading : ""
                }`}
                disabled={!isChanged || isSearching}
                onClick={triggerSearch}
              >
                {isSearching ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  <>Search</>
                )}
              </button>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((item) => (
                <div
                  key={item.objectID}
                  className={classes.profileCard}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img
                        src={
                          activeTab === "startups"
                            ? item.startupImage || startupImage
                            : item.communityImage || startupImage
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={classes.profileTitle}>
                    <span>
                      {activeTab === "startups"
                        ? item.startupName
                        : item.communityName}
                    </span>
                    <span className={classes.connectionType}>
                      • {activeTab === "startups" ? "Startup" : "Community"}
                    </span>
                  </div>
                  <div>
                    <div className={classes.bio}>
                      {activeTab === "startups" ? item.bio : item.description}
                    </div>
                    <div className={classes.location}>
                      {item.city}, {item.country}
                    </div>
                    {item.link && (
                      <div className={classes.link}>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {item.linkText || item.link}
                        </a>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                    <div className={classes.data}>
                      {activeTab === "startups" ? (
                        <>
                          <span className={classes.numbers}>
                            {item.teamMembersCount}{" "}
                            {item.teamMembersCount === 1 ? "Member" : "Members"}
                          </span>
                          <span className={classes.dot}>•</span>
                          <span className={classes.numbers}>
                            {item.followingCount || 0} Followers
                          </span>
                        </>
                      ) : (
                        <>
                          <span className={classes.numbers}>
                            {item.membersCount}{" "}
                            {item.membersCount === 1 ? "Member" : "Members"}
                          </span>
                          {item.category && (
                            <>
                              <span className={classes.dot}>•</span>
                              <span className={classes.numbers}>
                                {item.category}
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <button
                      className={classes.viewProfileButton}
                      onClick={() => handleItemClick(item)}
                    >
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      View
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : (
            initialResults.map((item) => (
              <div
                key={item.objectID}
                className={classes.profileCard}
                onClick={() => handleItemClick(item)}
              >
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img
                      src={
                        activeTab === "startups"
                          ? item.startupImage || startupImage
                          : item.communityImage || startupImage
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  <span>
                    {activeTab === "startups"
                      ? item.startupName
                      : item.communityName}
                  </span>
                  <span className={classes.connectionType}>
                    • {activeTab === "startups" ? "Startup" : "Community"}
                  </span>
                </div>
                <div>
                  <div className={classes.bio}>
                    {activeTab === "startups" ? item.bio : item.description}
                  </div>
                  <div className={classes.location}>
                    {item.city}, {item.country}
                  </div>
                  {item.link && (
                    <div className={classes.link}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item.linkText || item.link}
                      </a>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangeRed"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.data}>
                    {activeTab === "startups" ? (
                      <>
                        <span className={classes.numbers}>
                          {item.teamMembersCount}{" "}
                          {item.teamMembersCount === 1 ? "Member" : "Members"}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span className={classes.numbers}>
                          {item.followingCount || 0} Followers
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={classes.numbers}>
                          {item.membersCount}{" "}
                          {item.membersCount === 1 ? "Member" : "Members"}
                        </span>
                        {item.category && (
                          <>
                            <span className={classes.dot}>•</span>
                            <span className={classes.numbers}>
                              {item.category}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <button
                    className={classes.viewProfileButton}
                    onClick={() => handleItemClick(item)}
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    View
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default StartupsAndCommunities;
