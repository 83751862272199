import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./StartupInteractionToggle.module.css";
import StartupInteractionModal from "../Modals/StartupInteractionModal.js";

const StartupInteractionToggle = ({
  currentUser,
  onStartupSelect,
  selectedStartup,
  currentUserProfileImage,
  onModalOpen,
  onModalClose,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [userStartups, setUserStartups] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser?.uid) return;
      try {
        // First, fetch the user's own data
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        }

        // Then fetch startups
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserStartups(startupsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleOpenModal = () => {
    setShowModal(true);
    onModalOpen();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    onModalClose();
  };

  // Don't render anything if there are no startups
  if (userStartups.length === 0) {
    return (
      <div className={classes.interactionButton}>
        <img
          src={currentUserProfileImage}
          alt="Active profile"
          style={{
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }

  return (
    <>
      <button
        onClick={handleOpenModal}
        className={classes.interactionButton}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              selectedStartup
                ? selectedStartup.startupImage
                : currentUserProfileImage
            }
            alt="Active profile"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginLeft: "0.25rem" }}
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </div>
      </button>

      {showModal && (
        <StartupInteractionModal
          onClose={handleCloseModal}
          currentUser={{
            ...currentUser,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            bio: userData?.bio,
          }}
          onStartupSelect={(startup) => {
            onStartupSelect(startup);
            handleCloseModal();
          }}
          selectedStartup={selectedStartup}
          currentUserProfileImage={currentUserProfileImage}
        />
      )}
    </>
  );
};

export default StartupInteractionToggle;
