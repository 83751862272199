import React, { useState, useRef, useEffect } from "react";
import classes from "./MonthDropdown.module.css"; // Reusing the same styles

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    const ampm = hour < 12 ? "AM" : "PM";
    const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    times.push(`${displayHour}:00 ${ampm}`);
    times.push(`${displayHour}:30 ${ampm}`);
  }
  return times;
};

const TimeDropdown = ({
  label,
  selectedTime,
  onTimeChange,
  disabled,
  placeholder,
  minTime,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const timeOptions = generateTimeOptions();

  const getFilteredTimes = () => {
    if (!minTime) return timeOptions;
    const minIndex = timeOptions.indexOf(minTime);
    return minIndex >= 0 ? timeOptions.slice(minIndex) : timeOptions;
  };

  const filteredTimes = getFilteredTimes();

  useEffect(() => {
    setInputValue(selectedTime || "");
  }, [selectedTime]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (time) => {
    onTimeChange(time);
    setInputValue(time);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredTimes.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === -1
            ? filteredTimes.length - 1
            : Math.max(prevIndex - 1, 0)
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredTimes[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <label className={`${classes.label} ${disabled ? classes.disabled : ""}`}>
        {label}
      </label>
      <div className={classes.dropdownContainer}>
        <div className={classes.inputWrapper}>
          <input
            type="text"
            className={`${classes.dropdownHeader} ${
              disabled ? classes.disabled : ""
            } ${isOpen ? classes.active : ""}`}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onClick={() => setIsOpen(!isOpen)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          <svg
            className={`${classes.dropdownIcon} ${
              disabled ? classes.disabled : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m8 15 4 4 4-4m0-6-4-4-4 4"
            />
          </svg>
        </div>

        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList} ref={listRef}>
              {filteredTimes.map((time, index) => (
                <li
                  key={time}
                  className={`${classes.dropdownListItem} ${
                    index === highlightedIndex ? classes.highlightedItem : ""
                  }`}
                  onClick={() => handleSelect(time)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {time}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeDropdown;
