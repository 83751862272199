import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import classes from "./FollowModal.module.css";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  increment,
  where,
  orderBy,
} from "firebase/firestore";

const FollowModal = ({
  onClose,
  currentUserId,
  onUnfollowed,
  showDropdown = true,
  mode = "following", // New prop to determine modal mode: 'following' or 'followers'
  startupData = null, // New prop for startup data when showing followers
}) => {
  const [items, setItems] = useState([]); // Generic name since it can be followers or following
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  // Default placeholder image for startups without an image
  const defaultStartupImage = "https://via.placeholder.com/150";

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUserId && !startupData) return;

      try {
        setLoading(true);

        if (mode === "following") {
          // Existing logic for fetching followed startups
          const followingRef = collection(
            firestore,
            `users/${currentUserId}/startupsFollowing`
          );
          const followingSnapshot = await getDocs(followingRef);

          const startupsData = await Promise.all(
            followingSnapshot.docs.map(async (followDoc) => {
              const startupId = followDoc.id;
              const followData = followDoc.data();

              if (!followData.ownerId) {
                console.error(`No ownerId found for startup ${startupId}`);
                return null;
              }

              const startupRef = doc(
                firestore,
                `users/${followData.ownerId}/startups/${startupId}`
              );
              const startupDoc = await getDoc(startupRef);

              if (startupDoc.exists()) {
                const data = startupDoc.data();
                return {
                  id: startupId,
                  startupName: data.startupName || "Unnamed Startup",
                  startupImage: data.startupImage || defaultStartupImage,
                  bio: data.bio || "No description available",
                  city: data.city || "",
                  country: data.country || "",
                  startupUrlSlug: data.startupUrlSlug || startupId,
                  ownerId: followData.ownerId,
                  followedAt: followData.followedAt?.toDate() || new Date(),
                };
              }
              return null;
            })
          );

          const filteredStartups = startupsData.filter(Boolean);
          filteredStartups.sort((a, b) => b.followedAt - a.followedAt);

          setItems(filteredStartups);
          setTotalCount(filteredStartups.length);
        } else {
          // New logic for fetching startup followers
          const followersRef = collection(
            firestore,
            `users/${startupData.ownerId}/startups/${startupData.id}/followers`
          );
          const followersSnapshot = await getDocs(followersRef);

          const followersData = await Promise.all(
            followersSnapshot.docs.map(async (followerDoc) => {
              const userId = followerDoc.id;
              const userDoc = await getDoc(doc(firestore, "users", userId));

              if (userDoc.exists()) {
                const userData = userDoc.data();
                return {
                  id: userId,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  profileImage: userData.profileImage || defaultImage,
                  bio: userData.bio || "",
                  urlSlug: userData.urlSlug,
                  followedAt:
                    followerDoc.data().followedAt?.toDate() || new Date(),
                };
              }
              return null;
            })
          );

          const validFollowers = followersData.filter(Boolean);
          validFollowers.sort((a, b) => b.followedAt - a.followedAt);

          setItems(validFollowers);
          setTotalCount(validFollowers.length);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUserId, startupData, mode]);

  const handleUnfollow = async (e, startup) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const batch = writeBatch(firestore);

      const followRef = doc(
        firestore,
        `users/${currentUserId}/startupsFollowing/${startup.id}`
      );
      batch.delete(followRef);

      const startupRef = doc(
        firestore,
        `users/${startup.ownerId}/startups/${startup.id}`
      );
      batch.update(startupRef, {
        followingCount: increment(-1),
      });

      const userRef = doc(firestore, `users/${currentUserId}`);
      batch.update(userRef, {
        followingCount: increment(-1),
      });

      await batch.commit();

      setItems((prev) => prev.filter((s) => s.id !== startup.id));
      setTotalCount((prev) => prev - 1);
      setOpenDropdown(null);

      if (onUnfollowed) {
        onUnfollowed();
      }
    } catch (error) {
      console.error("Error unfollowing startup:", error);
    }
  };

  const handleItemClick = (item) => {
    onClose();
    if (mode === "following") {
      navigate(`/startup/${item.startupUrlSlug}`);
    } else {
      navigate(`/${item.urlSlug}`);
    }
  };

  const filteredItems = useMemo(() => {
    if (mode === "following") {
      return items.filter((startup) =>
        startup.startupName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return items.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }
  }, [items, searchTerm, mode]);

  const formatTimestamp = (date) => {
    if (!(date instanceof Date)) {
      console.error("Invalid date:", date);
      return "unknown";
    }

    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => document.body.classList.remove(classes.bodyNoScroll);
  }, []);

  const renderFollowingItem = (startup) => (
    <div key={startup.id} className={classes.connectionItem}>
      <img
        onClick={() => handleItemClick(startup)}
        src={startup.startupImage}
        alt={startup.startupName}
        className={classes.profileImage} // Keep this square for startups
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultStartupImage;
        }}
      />
      <div className={classes.connectionInfo}>
        <h3 className={classes.name}>
          <div className={classes.nameWrapper}>
            <span
              className={classes.nameText}
              onClick={() => handleItemClick(startup)}
            >
              {startup.startupName}
            </span>
            <span className={classes.connectionType}>• Startup</span>
          </div>
        </h3>
        <p className={`${classes.bio} ${!startup.bio && classes.noBio}`}>
          {startup.bio}
        </p>
        <span className={classes.connectionDate}>
          {formatTimestamp(startup.followedAt)}
        </span>
      </div>

      {showDropdown && (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === startup.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDropdown(openDropdown === startup.id ? null : startup.id);
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === startup.id && (
            <div className={classes.optionsDropdown}>
              <button onClick={(e) => handleUnfollow(e, startup)}>
                <svg
                  className={classes.removeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Unfollow
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderFollowerItem = (user) => (
    <div key={user.id} className={classes.connectionItem}>
      <img
        onClick={() => handleItemClick(user)}
        src={user.profileImage}
        alt={`${user.firstName} ${user.lastName}`}
        className={`${classes.profileImage} ${classes.userProfileImage}`} // Add this class
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultImage;
        }}
      />

      <div className={classes.connectionInfo}>
        <h3 className={classes.name}>
          <div className={classes.nameWrapper}>
            <span
              className={classes.nameText}
              onClick={() => handleItemClick(user)}
            >
              {user.firstName} {user.lastName}
            </span>
          </div>
        </h3>
        <p className={`${classes.bio} ${!user.bio && classes.noBio}`}>
          {user.bio}
        </p>
        <span className={classes.connectionDate}>
          {formatTimestamp(user.followedAt)}
        </span>
      </div>
    </div>
  );

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {totalCount} {mode === "following" ? "Following" : "Followers"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredItems.length > 0 ? (
              filteredItems.map((item) =>
                mode === "following"
                  ? renderFollowingItem(item)
                  : renderFollowerItem(item)
              )
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>
                  No {mode === "following" ? "following" : "followers"} found
                </div>
                <div className={classes.text}>
                  {mode === "following"
                    ? "Try searching for startups, communities, or events."
                    : "No one is following this startup yet."}
                </div>
                {mode === "following" && (
                  <Link to="/people" className={classes.homeButton}>
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/24"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    People
                  </Link>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FollowModal;
