import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
} from "react";

const UploadContext = createContext();

const initialState = {
  uploadingPosts: {},
  cancelledPosts: {},
};

function uploadReducer(state, action) {
  switch (action.type) {
    case "UPDATE_PROGRESS":
      return {
        ...state,
        uploadingPosts: {
          ...state.uploadingPosts,
          [action.postId]: {
            ...state.uploadingPosts[action.postId],
            progress: action.progress,
            status: action.status,
          },
        },
      };
    case "REMOVE_POST":
      const { [action.postId]: removedPost, ...restUploadingPosts } =
        state.uploadingPosts;
      return {
        ...state,
        uploadingPosts: restUploadingPosts,
      };
    case "CANCEL_UPLOAD":
      const { [action.postId]: cancelledPost, ...restUploading } =
        state.uploadingPosts;
      return {
        ...state,
        uploadingPosts: restUploading,
        cancelledPosts: {
          ...state.cancelledPosts,
          [action.postId]: true,
        },
      };
    case "CLEAR_CANCELLED":
      const { [action.postId]: clearedPost, ...restCancelled } =
        state.cancelledPosts;
      return {
        ...state,
        cancelledPosts: restCancelled,
      };
    default:
      return state;
  }
}

export function UploadProvider({ children }) {
  const [state, dispatch] = useReducer(uploadReducer, initialState);
  const cancelCallbacks = useRef({});

  const updateUploadProgress = useCallback(
    (postId, progress, status = "uploading") => {
      if (!state.cancelledPosts[postId]) {
        dispatch({ type: "UPDATE_PROGRESS", postId, progress, status });
      }
    },
    [state.cancelledPosts]
  );

  const removeUploadingPost = useCallback((postId) => {
    dispatch({ type: "REMOVE_POST", postId });
    delete cancelCallbacks.current[postId];
  }, []);

  const cancelUpload = useCallback((postId) => {
    dispatch({ type: "CANCEL_UPLOAD", postId });
    if (cancelCallbacks.current[postId]) {
      cancelCallbacks.current[postId]();
    }
  }, []);

  const registerCancelCallback = useCallback((postId, callback) => {
    cancelCallbacks.current[postId] = callback;
  }, []);

  const value = useMemo(
    () => ({
      state,
      updateUploadProgress,
      removeUploadingPost,
      cancelUpload,
      registerCancelCallback,
    }),
    [
      state,
      updateUploadProgress,
      removeUploadingPost,
      cancelUpload,
      registerCancelCallback,
    ]
  );

  return (
    <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
  );
}

export const useUpload = () => useContext(UploadContext);
