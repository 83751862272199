export const getTopThreeEmojis = (reactions) => {
  if (!reactions) return [];
  const emojiCounts = Object.entries(reactions).reduce((acc, [id, emoji]) => {
    acc[emoji] = (acc[emoji] || 0) + 1;
    return acc;
  }, {});
  return Object.entries(emojiCounts)
    .sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0])) // Add tie-breaking
    .slice(0, 3)
    .map(([emoji]) => emoji);
};
