import React, { useState } from "react";
import Navbar from "../../components/Navbars/Navbar";
import Footer from "../../components/Footer/Footer";
import career from "../../assets/illustrations/career.png";
import classes from "./Careers.module.css";
import { sendEmail } from "../../utils/careersForm";

function Careers() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    sendEmail(name, email, message, setSuccess, setError);
  };

  return (
    <div className={classes.page}>
      <Navbar />
      <div className={classes.content}>
        <div className={classes.careersHeader}>
          <div className={classes.headerAbout}>
            <h2 className={classes.title1}>
              We're a different kind of startup.
            </h2>
            <p className={classes.careersAbout}>
              At <span className={classes.foundry}>Foundry</span>, we're
              building a startup for startups. We're looking for people like you
              who believe in our mission to be the place where startups start.
              <br></br>
              <br></br>
              In these early stages, we can't offer financial compensation, but
              we're committed to providing equity and future compensation.
              <br></br>
              <br></br>
              If you're ready to be part of something extraordinary, tell us who
              you are and why you want to join{" "}
              <span className={classes.foundry}>Foundry</span>. We'll be in
              touch soon.
            </p>
            <form
              onSubmit={handleEmailSubmit}
              className={classes.careersInteractions}
            >
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={classes.email}
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.email}
              />
              <textarea
                placeholder="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={classes.messageInput}
              />
              <button type="submit" className={classes.button}>
                Submit
              </button>
            </form>
            {success === true && (
              <p className={classes.success}>
                <div className={classes.message}>
                  <div className={classes.icons}>
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                  </div>
                  Your message has been sent successfully!
                </div>
              </p>
            )}
            {error && (
              <p className={classes.error}>
                <div className={classes.message}>
                  <div className={classes.icons}>
                    <span className="material-symbols-outlined">error</span>
                  </div>
                  {error}
                </div>
              </p>
            )}
          </div>
          <img src={career} alt="" className={classes.illustrationOne} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Careers;
