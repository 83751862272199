import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  memo,
} from "react";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import RepostsModal from "../../components/Modals/RepostsModal";
import {
  collection,
  query,
  addDoc,
  increment,
  where,
  getDocs,
  orderBy,
  doc,
  writeBatch,
  getDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./AllReactions.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import {
  getStorage,
  ref,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import PostActionPopup from "../Posts/PostActionPopup";
import PostModal from "../../components/Modals/PostModal";

function AllReactions() {
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const location = useLocation();
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [mainEmojiPickerOpen, setMainEmojiPickerOpen] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const { urlSlug } = useParams();
  const [reactions, setReactions] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState({});
  const [videoVolume, setVideoVolume] = useState({});
  const videoRefs = useRef({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const navigate = useNavigate();
  const storage = getStorage();
  const [showRepostsModal, setShowRepostsModal] = useState({});
  const [currentReactionEmojis, setCurrentReactionEmojis] = useState({});
  const [repostMessage, setRepostMessage] = useState(null);
  const [commentCounts, setCommentCounts] = useState({});
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [isSafari, setIsSafari] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  // New state variables for comments
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState({});
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState({});
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [isEditingComplete, setIsEditingComplete] = useState(false);

  const commentInputRef = useRef({});
  const replyInputRef = useRef({});

  const handleEditPost = (post) => {
    setPostModalScrollPosition(window.scrollY);
    setEditingPost(post);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  // Make sure to include the checkConnectionType function
  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage,
        bio: selectedStartup.bio || "",
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUserProfileImage || profileImage,
      bio: currentUser.bio || "",
      connectionType: "You",
      isStartup: false,
    };
  };

  const fetchComments = useCallback(
    async (postId, postUserId) => {
      if (!postId || !currentUser) return;

      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) {
        console.log("Post not found");
        return;
      }

      try {
        // Get the correct base path based on whether it's a startup post
        const basePath = reaction.originalPost.user.isStartup
          ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
          : `users/${reaction.originalPost.user.id}`;

        const commentsRef = collection(
          firestore,
          `${basePath}/posts/${postId}/comments`
        );
        const q = query(commentsRef, orderBy("createdAt", "asc"));

        const snapshot = await getDocs(q);
        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();

            // Handle startup comments
            if (commentData.isStartup) {
              // Find the startup data
              const startupRef = doc(
                firestore,
                `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
              );
              const startupSnapshot = await getDoc(startupRef);

              if (!startupSnapshot.exists()) {
                console.log(
                  "Startup not found for comment:",
                  commentData.startupId
                );
                return null;
              }

              const startupData = startupSnapshot.data();
              return {
                id: docSnapshot.id,
                ...commentData,
                userName: startupData.startupName,
                userImage: startupData.startupImage || profileImage,
                connectionType: "Startup",
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                startupUrlSlug: startupData.startupUrlSlug,
                postId: postId,
                postUserId: reaction.originalPost.user.id,
                rootCommentId: commentData.rootCommentId,
                isRootComment: commentData.isRootComment,
              };
            }

            // Handle regular user comments
            const userDocRef = doc(firestore, "users", commentData.userId);
            const userDocSnapshot = await getDoc(userDocRef);
            if (!userDocSnapshot.exists()) {
              console.log("User not found for comment:", commentData.userId);
              return null;
            }

            const userData = userDocSnapshot.data();
            let connectionType = "Unknown";
            try {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            } catch (error) {
              console.error("Error checking connection type:", error);
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              userUrlSlug: userData.urlSlug,
              postId: postId,
              postUserId: reaction.originalPost.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
            };
          })
        );

        const validComments = fetchedComments.filter(
          (comment) => comment !== null
        );

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: validComments,
        }));
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },
    [currentUser, reactions]
  );

  useEffect(() => {
    if (reactions.length > 0 && currentUser) {
      // Fetch comments for all posts initially
      reactions.forEach((reaction) => {
        if (!comments[reaction.originalPost.id]) {
          fetchComments(
            reaction.originalPost.id,
            reaction.originalPost.user.id
          );
        }
      });
    }
  }, [reactions, currentUser, fetchComments]);

  // Add another useEffect to fetch comments when showComments changes
  useEffect(() => {
    Object.entries(showComments).forEach(([postId, isShown]) => {
      if (isShown && !comments[postId]) {
        const reaction = reactions.find((r) => r.originalPost.id === postId);
        if (reaction) {
          fetchComments(postId, reaction.originalPost.user.id);
        }
      }
    });
  }, [showComments, reactions, fetchComments]);

  const handleAddComment = async (postId, parentCommentId = null) => {
    if (!currentUser) return;

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) throw new Error("Post not found");

      let commentText;
      if (parentCommentId) {
        commentText = replyComments[postId]?.[parentCommentId] || "";
      } else {
        commentText = newComment[postId] || "";
      }

      if (!commentText.trim()) return;

      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      const userData = userDoc.data();

      let commenterInfo;
      if (selectedStartup) {
        const startupRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${selectedStartup.id}`
        );
        const startupSnap = await getDoc(startupRef);
        if (!startupSnap.exists()) throw new Error("Startup not found");
        const startupData = startupSnap.data();

        commenterInfo = {
          id: `startup_${selectedStartup.id}`,
          name: selectedStartup.startupName,
          image: selectedStartup.startupImage || profileImage,
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
          connectionType: "Startup",
          isStartup: true,
          startupId: selectedStartup.id,
          startupOwnerId: currentUser.uid,
          startupUrlSlug: startupData.startupUrlSlug,
        };
      } else {
        commenterInfo = {
          id: currentUser.uid,
          name: `${userData.firstName} ${userData.lastName}`,
          image: userData.profileImage || profileImage,
          bio: userData.bio || "",
          link: userData.link || null,
          linkText: userData.linkText || null,
          connectionType: "You",
          isStartup: false,
          userUrlSlug: userData.urlSlug,
        };
      }

      const processedCommentText = convertUrlsToLinks(commentText);

      const commentsPath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}/comments`
        : `users/${reaction.originalPost.user.id}/posts/${postId}/comments`;

      const commentsRef = collection(firestore, commentsPath);

      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${postId}`
          );

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) throw new Error("Post not found");

      const postData = postSnap.data();
      const rootCommentId = parentCommentId
        ? await getRootCommentId(
            reaction.originalPost.user.id,
            postId,
            parentCommentId
          )
        : null;

      const now = serverTimestamp();

      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        bio: commenterInfo.bio,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        connectionType: commenterInfo.connectionType,
        createdAt: now,
        parentCommentId: parentCommentId || null,
        rootCommentId: rootCommentId || null,
        isRootComment: !parentCommentId,
        reactions: {},
        postId: postId,
        postUserId: reaction.originalPost.user.id,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup && {
          startupId: commenterInfo.startupId,
          startupOwnerId: commenterInfo.startupOwnerId,
          startupUrlSlug: commenterInfo.startupUrlSlug,
        }),
        ...(!commenterInfo.isStartup && {
          userUrlSlug: commenterInfo.userUrlSlug,
        }),
      };

      const newCommentRef = await addDoc(commentsRef, commentData);

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: now,
        originalDirectCommentTime: now,
      });

      const commentedPostsPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}/commentedPosts`
        : `users/${currentUser.uid}/commentedPosts`;

      const commentedPostRef = doc(firestore, commentedPostsPath, postId);
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId:
          reaction.originalPost.user.startupOwnerId ||
          reaction.originalPost.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: postId,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const localComment = {
        ...commentData,
        id: newCommentRef.id,
        createdAt: new Date(),
      };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [...(prevComments[postId] || []), localComment],
      }));

      setReactions((prevReactions) =>
        prevReactions.map((r) =>
          r.originalPost.id === postId
            ? {
                ...r,
                originalPost: {
                  ...r.originalPost,
                  commentCount: (r.originalPost.commentCount || 0) + 1,
                  comments: {
                    ...(r.originalPost.comments || {}),
                    [commenterInfo.id]: now,
                  },
                  originalDirectCommentTime: now,
                },
              }
            : r
        )
      );

      const batch = writeBatch(firestore);

      const shouldNotifyPostOwner =
        !parentCommentId &&
        ((reaction.originalPost.user.isStartup &&
          currentUser.uid !== reaction.originalPost.user.startupOwnerId &&
          (!commenterInfo.isStartup ||
            commenterInfo.startupId !==
              reaction.originalPost.user.startupId)) ||
          (!reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.id));

      if (shouldNotifyPostOwner) {
        const notificationRef = doc(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: postId,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: now,
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(notificationRef, notificationData);
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.data();

        const shouldNotifyParentCommenter =
          parentCommentData &&
          ((parentCommentData.isStartup &&
            currentUser.uid !== parentCommentData.startupOwnerId &&
            (!commenterInfo.isStartup ||
              commenterInfo.startupId !== parentCommentData.startupId)) ||
            (!parentCommentData.isStartup &&
              currentUser.uid !== parentCommentData.userId));

        if (shouldNotifyParentCommenter) {
          const notificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const notificationData = {
            type: "reply",
            postId: postId,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: now,
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(notificationRef, notificationData);
        }
      }

      await batch.commit();

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
        if (commentInputRef.current[postId]) {
          commentInputRef.current[postId].style.height = "auto";
        }
      }
      setActiveCommentId((prev) => ({ ...prev, [postId]: null }));
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const updateCommentVisibility = async (postId, commenterId, commentData) => {
    const reaction = reactions.find((r) => r.originalPost.id === postId);
    if (!reaction) return;

    const postRef = doc(
      firestore,
      `users/${reaction.originalPost.user.id}/posts/${postId}`
    );
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const postData = postDoc.data();
      const currentUserConnectionType = await checkConnectionType(
        currentUser.uid,
        postData.userId
      );
      const commenterConnectionType = await checkConnectionType(
        currentUser.uid,
        commenterId
      );

      if (
        (currentUserConnectionType === "Indirect" ||
          currentUserConnectionType === "Extended") &&
        commenterConnectionType === "Direct"
      ) {
        await updateDoc(postRef, {
          visibleCommentedBy: {
            id: commenterId,
            firstName: commentData.firstName,
            lastName: commentData.lastName,
            commentedAt: commentData.commentedAt,
            commentText: commentData.commentText,
          },
          originalDirectCommentTime:
            postData.originalDirectCommentTime || commentData.commentedAt,
        });
      }
    }
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      // Find the reaction to determine if it's a startup post
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) throw new Error("Post not found");

      // Use the correct path based on whether it's a startup post
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${postUserId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      } else {
        return getRootCommentId(
          postUserId,
          postId,
          commentData.parentCommentId
        );
      }
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({ ...prev, [postId]: textarea.value }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyInputChange = (e, postId, commentId) => {
    const textarea = e.target;

    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: textarea.value,
      },
    }));

    // Auto-adjust height
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = (postId, commentId) => {
    const commentExists = comments[postId]?.some((c) => c.id === commentId);

    if (!commentExists) {
      setErrorPopup({
        message: "Cannot reply to a non-existent comment.",
        isError: true,
      });
      return;
    }

    setActiveCommentId((prev) => ({
      ...prev,
      [postId]: prev[postId] === commentId ? null : commentId,
    }));

    // Initialize the reply comment state if it doesn't exist
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: "",
      },
    }));

    // Add a slight delay before focusing
    setTimeout(() => {
      if (!replyInputRef.current[postId]) {
        replyInputRef.current[postId] = {};
      }

      if (replyInputRef.current[postId]?.[commentId]) {
        const inputElement = replyInputRef.current[postId][commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  const handleViewMoreReplies = (postId, rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [rootCommentId]: (prev[postId]?.[rootCommentId] || 1) + 9,
      },
    }));
  };

  const toggleComments = (postId) => {
    setShowComments((prev) => {
      const newState = {
        ...prev,
        [postId]: !prev[postId],
      };

      // If we're showing comments and haven't loaded them yet, fetch them
      if (newState[postId] && !comments[postId]) {
        const reaction = reactions.find((r) => r.originalPost.id === postId);
        if (reaction) {
          fetchComments(postId, reaction.originalPost.user.id);
        }
      }

      return newState;
    });
  };

  const toggleCommentExpansion = (postId, commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: !prev[postId]?.[commentId],
      },
    }));
  };

  const toggleReplyExpansion = (postId, replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: !prev[postId]?.[replyId],
      },
    }));
  };

  const handleCommentReactionClick = (postId, commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker(
      activeEmojiPicker === `${postId}_${commentId}`
        ? null
        : `${postId}_${commentId}`
    );
  };

  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) throw new Error("Post not found");

      // Get the correct path for comments based on whether it's a startup post
      const commentsPath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}/comments`
        : `users/${reaction.originalPost.user.id}/posts/${postId}/comments`;

      const commentRef = doc(firestore, commentsPath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const reactorName = selectedStartup
        ? selectedStartup.startupName
        : `${currentUser.firstName} ${currentUser.lastName}`;
      const reactorImage = selectedStartup
        ? selectedStartup.startupImage
        : currentUserProfileImage || profileImage;

      // Check if reactor owns the comment
      const isReactorOwner =
        (selectedStartup &&
          commentData.isStartup &&
          commentData.startupId === selectedStartup.id) ||
        (!selectedStartup &&
          !commentData.isStartup &&
          commentData.userId === currentUser.uid) ||
        (commentData.isStartup &&
          commentData.startupOwnerId === currentUser.uid) ||
        (!commentData.isStartup && commentData.userId === currentUser.uid);

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        // Delete notification
        if (!isReactorOwner) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        // Add or update reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        // Determine if notification should be sent
        const shouldNotify =
          !isReactorOwner &&
          ((selectedStartup &&
            commentData.userId !== `startup_${selectedStartup.id}` &&
            (!commentData.isStartup ||
              commentData.startupId !== selectedStartup.id)) ||
            (!selectedStartup &&
              commentData.userId !== currentUser.uid &&
              (!commentData.isStartup ||
                commentData.startupOwnerId !== currentUser.uid)));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: reaction.originalPost.id,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: reactorName,
            reactorImage: reactorImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment reaction:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
  }) => {
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={(e) => onCommentChange(e)}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment.trim() ? classes.disabled : ""
          }`}
          disabled={!newComment.trim()}
        >
          Comment
        </div>
      </div>
    );
  };

  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
  }) => {
    // Use comment.postId since we pass it when creating the comment
    const postId = comment.postId;
    const commentId = comment.id;

    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUserProfileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={() =>
                handleNavigate(
                  selectedStartup
                    ? `startup/${selectedStartup.startupUrlSlug}`
                    : currentUser.uid
                )
              }
            />
          </div>
          <textarea
            ref={(el) => {
              if (!replyInputRef.current[postId]) {
                replyInputRef.current[postId] = {};
              }
              replyInputRef.current[postId][commentId] = el;
            }}
            value={replyComments[postId]?.[commentId] || ""}
            onChange={(e) => onReplyChange(e, postId, commentId)}
            onTouchStart={handleTouchStart}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={() => onSubmit(postId, commentId)}
          className={`${classes.addReplyButton} ${
            !replyComments[postId]?.[commentId]?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  useEffect(() => {
    replyInputRef.current = {};
  }, []);

  const renderCommentHeader = (comment) => {
    const isStartupComment = comment.isStartup;
    const profileUrl = isStartupComment
      ? `/startup/${comment.startupUrlSlug}`
      : `/${comment.userUrlSlug}`;

    return (
      <div className={classes.commentHeader}>
        <div className={classes.commentUserInfo}>
          <img
            src={comment.userImage}
            alt={comment.userName}
            className={classes.commentUserImage}
            onClick={() =>
              handleNavigate(
                isStartupComment ? comment.startupId : comment.userId
              )
            }
            style={{ cursor: "pointer" }}
          />
          <div className={classes.commentUserDetails}>
            <div className={classes.commentUserNameContainer}>
              <span
                className={classes.commentUserName}
                onClick={() =>
                  handleNavigate(
                    isStartupComment ? comment.startupId : comment.userId
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {comment.userName}
              </span>
              <span className={classes.commentConnectionType}>
                • {isStartupComment ? "Startup" : comment.connectionType}
              </span>
            </div>
            {comment.bio && (
              <p className={classes.commentUserBio}>{comment.bio}</p>
            )}
            {comment.link && (
              <div className={classes.commentUserLinkContainer}>
                <a
                  href={comment.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={classes.commentUserLink}
                >
                  <span className={classes.commentUserLinkText}>
                    {comment.linkText || comment.link}
                  </span>
                  <svg
                    className={classes.commentUserLinkIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangered"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </a>
              </div>
            )}
            <div className={classes.commentTimeAndEditDetails}>
              <p className={classes.commentTimestamp}>
                {formatTimestamp(comment.createdAt)}
              </p>
              {comment.edited && (
                <div className={classes.commentEditDot}>
                  •<span className={classes.commentEditText}>Edited</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleCommentDropdown = (postId, commentId) => {
    setOpenCommentDropdown((prev) =>
      prev === `${postId}_${commentId}` ? null : `${postId}_${commentId}`
    );
  };

  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) return;

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) throw new Error("Post not found");

      // Get the correct base path for comments
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${reaction.originalPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.isStartup) {
        canDelete =
          (selectedStartup && selectedStartup.id === commentData.startupId) ||
          currentUser.uid === commentData.startupOwnerId ||
          currentUser.uid === reaction.originalPost.user.startupOwnerId;
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          currentUser.uid === reaction.originalPost.user.id;
      }

      if (!canDelete) {
        throw new Error("Not authorized to delete this comment");
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        // Delete reactions and notifications
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          // Delete user notifications
          const notificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("commentId", "==", commentRef.id)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);
          await Promise.all(
            notificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
          );

          // Delete startup notifications
          const startupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of startupsSnapshot.docs) {
            const startupNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupDoc.id}`)
            );
            const startupNotificationsSnapshot = await getDocs(
              startupNotificationsQuery
            );
            await Promise.all(
              startupNotificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
            );
          }
        }

        // Delete replies
        const repliesQuery = query(
          collection(firestore, `${basePath}/posts/${postId}/comments`),
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentRef);

      const batch = writeBatch(firestore);

      // Update post comment count
      const postRef = doc(firestore, `${basePath}/posts/${postId}`);
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      // Clean up commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${postId}`
        );
        const commentedPostDoc = await getDoc(commentedPostRef);

        if (commentedPostDoc.exists()) {
          const updatedComments = { ...commentedPostDoc.data().comments };
          deletedCommentIds.forEach((id) => delete updatedComments[id]);

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Clean up commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const startupCommentedPostRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${startupId}/commentedPosts/${postId}`
        );
        const startupCommentedPostDoc = await getDoc(startupCommentedPostRef);

        if (startupCommentedPostDoc.exists()) {
          const updatedComments = {
            ...startupCommentedPostDoc.data().comments,
          };
          deletedCommentIds.forEach((id) => delete updatedComments[id]);

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(startupCommentedPostRef);
          } else {
            batch.update(startupCommentedPostRef, {
              comments: updatedComments,
            });
          }
        }
      }

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setReactions((prevReactions) =>
        prevReactions.map((r) => {
          if (r.originalPost.id === postId) {
            const updatedPost = {
              ...r.originalPost,
              commentCount: Math.max(
                (r.originalPost.commentCount || 0) - deletedCount,
                0
              ),
            };
            const updatedComments = { ...updatedPost.comments };
            affectedUserIds.forEach((userId) => {
              delete updatedComments[userId];
            });
            affectedStartupIds.forEach((startupId) => {
              delete updatedComments[`startup_${startupId}`];
            });
            updatedPost.comments = updatedComments;
            return { ...r, originalPost: updatedPost };
          }
          return r;
        })
      );

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to delete this comment"
            ? "You are not authorized to delete this comment."
            : "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const renderCommentText = (postId, comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[postId]?.[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[postId]?.[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(postId, comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const renderComments = (postId) => {
    const postComments = comments[postId] || [];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === `${postId}_${comment.id}`
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${comment.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {(comment.userId === currentUser.uid ||
                        (comment.isStartup &&
                          comment.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(postId, comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== comment.postUserId &&
                        comment.userId !== currentUser.uid &&
                        (!comment.isStartup ||
                          comment.startupOwnerId !== currentUser.uid) && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}

                      {(currentUser.uid === comment.postUserId ||
                        comment.userId === currentUser.uid ||
                        (comment.isStartup &&
                          comment.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(postId, comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() =>
                      handleCommentReactionClick(postId, comment.id)
                    }
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === `${postId}_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}

              {activeCommentId[postId] === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments}
                  onReplyChange={handleReplyInputChange}
                  onSubmit={handleAddComment}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    const postComments = comments[postId] || [];
    const replies = postComments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[postId]?.[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === `${postId}_${reply.id}`
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${reply.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {(reply.userId === currentUser.uid ||
                        (reply.isStartup &&
                          reply.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() =>
                            handleEditComment(postId, reply.id, true)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== reply.postUserId &&
                        reply.userId !== currentUser.uid &&
                        (!reply.isStartup ||
                          reply.startupOwnerId !== currentUser.uid) && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}

                      {(currentUser.uid === reply.postUserId ||
                        reply.userId === currentUser.uid ||
                        (reply.isStartup &&
                          reply.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(postId, reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(postId, reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(postId, reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId[postId] === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUserProfileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.uid
                          )
                        }
                      />
                    </div>
                    <textarea
                      ref={(el) =>
                        (replyInputRef.current[postId] = {
                          ...replyInputRef.current[postId],
                          [reply.id]: el,
                        })
                      }
                      value={replyComments[postId]?.[reply.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, reply.id)
                      }
                      placeholder={`Reply as ${
                        selectedStartup
                          ? selectedStartup.startupName
                          : "yourself"
                      }...`}
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() =>
                      replyComments[postId]?.[reply.id]?.trim() &&
                      handleAddComment(postId, reply.id)
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[postId]?.[reply.id]?.trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(postId, rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  // Add this function to fetch comment counts
  const fetchCommentCounts = async (postIds) => {
    const counts = {};
    for (const postId of postIds) {
      const commentsRef = collection(
        firestore,
        `users/${postId.split("_")[0]}/posts/${postId}/comments`
      );
      const q = query(commentsRef);
      const snapshot = await getDocs(q);
      counts[postId] = snapshot.size;
    }
    setCommentCounts(counts);
  };

  const hasInteractions = (reaction) => {
    if (!reaction || !reaction.originalPost) return false;

    const reactionCount = reaction.originalPost.reactions
      ? Object.keys(reaction.originalPost.reactions).length
      : 0;
    const repostCount = reaction.originalPost.reposts
      ? Object.keys(reaction.originalPost.reposts).length
      : 0;
    const commentCount = reaction.originalPost.commentCount || 0;

    return reactionCount > 0 || repostCount > 0 || commentCount > 0;
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  useEffect(() => {
    if (reactions && currentUser) {
      const currentEmojis = reactions.reduce((acc, reaction) => {
        // For the current emoji, we want to check if the current user (or selected startup) has reacted
        // NOT use the profile owner's reaction
        const reactionKey = selectedStartup
          ? `startup_${selectedStartup.id}`
          : currentUser.uid;

        acc[reaction.originalPost.id] =
          reaction.originalPost.reactions?.[reactionKey] || null;
        return acc;
      }, {});
      setCurrentReactionEmojis(currentEmojis);
    }
  }, [reactions, currentUser, selectedStartup]); // Added selectedStartup as dependency

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    const reaction = reactions.find((r) => r.originalPost.id === postId);
    if (!reaction) return;

    try {
      // Get the correct post reference based on whether it's a startup post
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${postId}`
          );

      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      const repostsPath = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reposts`
        : `users/${currentUser.uid}/reposts`;

      const repostedPostRef = doc(firestore, repostsPath, postId);

      let updatedReposts = { ...(reaction.originalPost.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        // Remove repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        // Delete notification
        const notificationRef = doc(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`,
          `repost_${postId}_${reposterId}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: reaction.originalDirectRepostTime || now,
        });

        // Only send notification if:
        // 1. User reposting startup post and not the startup owner
        // 2. Startup reposting post and not owned by the post owner
        // 3. User reposting user post and not the post owner
        const shouldNotify =
          (reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.startupOwnerId &&
            (!selectedStartup ||
              selectedStartup.id !== reaction.originalPost.user.startupId)) ||
          (selectedStartup &&
            currentUser.uid !== reaction.originalPost.user.id &&
            (!reaction.originalPost.user.isStartup ||
              selectedStartup.id !== reaction.originalPost.user.startupId)) ||
          (!selectedStartup &&
            !reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `repost_${postId}_${reposterId}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterId,
            reposterName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reposterImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setReactions((prevReactions) =>
        prevReactions.map((r) =>
          r.originalPost.id === postId
            ? {
                ...r,
                originalPost: { ...r.originalPost, reposts: updatedReposts },
              }
            : r
        )
      );
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleReact = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) return;

      const postRef = doc(
        firestore,
        "users",
        reaction.originalPost.user.id,
        "posts",
        postId
      );
      const reactedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reactedPosts",
        postId
      );

      let updatedReactions = { ...(reaction.originalPost.reactions || {}) };

      if (updatedReactions[currentUser.uid] === emoji) {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReactions[currentUser.uid];
        await deleteDoc(reactedPostRef);

        const notificationRef = doc(
          firestore,
          "users",
          reaction.originalPost.user.id,
          "notifications",
          `reaction_${postId}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: emoji,
        });
        updatedReactions[currentUser.uid] = emoji;

        await setDoc(reactedPostRef, {
          originalPosterId: reaction.originalPost.user.id,
          postId: postId,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        if (reaction.originalPost.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            reaction.originalPost.user.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );

          const currentUserDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const currentUserData = currentUserDoc.data();

          await setDoc(notificationRef, {
            type: "reaction",
            postId: postId,
            reactorId: currentUser.uid,
            reactorName: `${currentUserData.firstName} ${currentUserData.lastName}`,
            reactorImage: currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
          });
        }
      }

      // Update local state
      setReactions((prevReactions) =>
        prevReactions.map((r) =>
          r.originalPost.id === postId
            ? {
                ...r,
                originalPost: {
                  ...r.originalPost,
                  reactions: updatedReactions,
                },
              }
            : r
        )
      );

      // Update current reaction emoji
      setCurrentReactionEmojis((prev) => ({
        ...prev,
        [postId]: updatedReactions[currentUser.uid] || null,
      }));
    } catch (error) {
      console.error("Error in handleReact:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchCurrentUserProfileImage(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchRepostData = useCallback(async (reposts) => {
    const repostData = {};
    for (const [userId, timestamp] of Object.entries(reposts)) {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        repostData[userId] = {
          id: userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
          timestamp: timestamp,
        };
      }
    }
    return repostData;
  }, []);

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // First check if this post exists in the user's regular posts
      const userPostRef = doc(
        firestore,
        `users/${originalPosterId}/posts/${postId}`
      );
      const userPostSnap = await getDoc(userPostRef);

      if (userPostSnap.exists()) {
        return userPostRef;
      }

      // If not found in user's posts, look in their startups
      const startupsRef = collection(
        firestore,
        `users/${originalPosterId}/startups`
      );
      const startupsSnapshot = await getDocs(startupsRef);

      // Check each startup's posts collection
      for (const startupDoc of startupsSnapshot.docs) {
        const startupPostRef = doc(
          firestore,
          `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
        );
        const startupPostSnap = await getDoc(startupPostRef);

        if (startupPostSnap.exists()) {
          return startupPostRef;
        }
      }

      // If we get here, no post was found
      console.warn(
        `Could not find post. PostId: ${postId}, UserId: ${originalPosterId}`
      );
      return null;
    } catch (error) {
      console.error("Error finding post:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setHasAttemptedLoad(false);
      try {
        const { pathname } = location;
        const { state } = location;
        const isStartupPath = pathname.includes("/startup/");
        const fromStartupReactions = state?.fromStartupReactions;
        const startupData = state?.startupData;

        if ((isStartupPath || fromStartupReactions) && startupData) {
          setUserData({
            id: `startup_${startupData.startupId}`,
            startupId: startupData.startupId,
            startupName: startupData.startupName,
            startupImage: startupData.startupImage,
            isStartup: true,
            ...startupData,
          });

          const reactionsRef = collection(
            firestore,
            `users/${startupData.startupOwnerId}/startups/${startupData.startupId}/reactedPosts`
          );
          const q = query(reactionsRef, orderBy("reactedAt", "desc"));
          const querySnapshot = await getDocs(q);

          const fetchedReactions = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const reactionData = docSnapshot.data();

              let originalPostRef = await getOriginalPostRef(
                reactionData.originalPosterId,
                docSnapshot.id
              );

              if (!originalPostRef) return null;

              const originalPostSnap = await getDoc(originalPostRef);
              if (!originalPostSnap.exists()) return null;

              const originalPostData = originalPostSnap.data();
              let originalPostUserData;
              let mediaUrl = null;

              if (originalPostRef.path.includes("/startups/")) {
                const pathParts = originalPostRef.path.split("/");
                const ownerId = pathParts[1];
                const startupId = pathParts[3];

                const startupRef = doc(
                  firestore,
                  `users/${ownerId}/startups/${startupId}`
                );
                const startupSnap = await getDoc(startupRef);

                if (startupSnap.exists()) {
                  const startupData = startupSnap.data();
                  originalPostUserData = {
                    id: `startup_${startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage || profileImage,
                    isStartup: true,
                    startupId: startupId,
                    startupOwnerId: ownerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                  };
                }
              } else {
                const userDoc = await getDoc(
                  doc(firestore, "users", reactionData.originalPosterId)
                );
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  originalPostUserData = {
                    id: reactionData.originalPosterId,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    profileImage: userData.profileImage || profileImage,
                    isStartup: false,
                    urlSlug: userData.urlSlug,
                    bio: userData.bio || "",
                    link: userData.link || null,
                    linkText: userData.linkText || null,
                  };
                }
              }

              if (originalPostData.content?.url) {
                if (Array.isArray(originalPostData.content.url)) {
                  mediaUrl = await Promise.all(
                    originalPostData.content.url.map(async (url) => {
                      try {
                        const storageRef = ref(storage, url);
                        return await getDownloadURL(storageRef);
                      } catch (error) {
                        console.error("Error fetching media URL:", error);
                        return null;
                      }
                    })
                  );
                } else {
                  try {
                    const storageRef = ref(
                      storage,
                      originalPostData.content.url
                    );
                    mediaUrl = await getDownloadURL(storageRef);
                  } catch (error) {
                    console.error("Error fetching media URL:", error);
                  }
                }
              }

              return {
                id: docSnapshot.id,
                ...reactionData,
                user: {
                  id: `startup_${startupData.startupId}`,
                  firstName: startupData.startupName,
                  lastName: "",
                  profileImage: startupData.startupImage,
                  isStartup: true,
                  startupId: startupData.startupId,
                  startupOwnerId: startupData.startupOwnerId,
                  startupUrlSlug: startupData.startupUrlSlug,
                },
                originalPost: {
                  ...originalPostData,
                  id: docSnapshot.id,
                  user: {
                    ...originalPostUserData,
                    connectionType: originalPostUserData.isStartup
                      ? "Startup"
                      : await checkConnectionType(
                          currentUser.uid,
                          originalPostUserData.id
                        ),
                  },
                  content: originalPostData.content
                    ? {
                        ...originalPostData.content,
                        url: mediaUrl || originalPostData.content?.url,
                      }
                    : null,
                  reactions: originalPostData.reactions || {},
                  reposts: originalPostData.reposts || {},
                  commentCount: originalPostData.commentCount || 0,
                  createdAt: originalPostData.createdAt || null,
                  isEdited: originalPostData.isEdited || false,
                },
              };
            })
          );

          const validReactions = fetchedReactions.filter(
            (reaction) => reaction !== null
          );
          setReactions(validReactions);
        } else {
          let userId;
          if (urlSlug === "me") {
            if (!auth.currentUser) {
              console.error("No current user");
              setLoading(false);
              return;
            }
            userId = auth.currentUser.uid;
          } else if (urlSlug) {
            const usersRef = collection(firestore, "users");
            const q = query(usersRef, where("urlSlug", "==", urlSlug));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              userId = querySnapshot.docs[0].id;
            } else {
              console.error("User not found");
              setLoading(false);
              return;
            }
          } else {
            console.error("No urlSlug provided");
            setLoading(false);
            return;
          }

          const userDoc = await getDoc(doc(firestore, "users", userId));
          if (userDoc.exists()) {
            setUserData({ id: userId, ...userDoc.data() });
          }

          const reactedPostsRef = collection(
            firestore,
            `users/${userId}/reactedPosts`
          );
          const q = query(reactedPostsRef, orderBy("reactedAt", "desc"));
          const querySnapshot = await getDocs(q);
          const reactionsToDelete = [];

          const fetchedReactions = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const reactionData = docSnapshot.data();
              let originalPostRef = await getOriginalPostRef(
                reactionData.originalPosterId,
                docSnapshot.id
              );

              if (!originalPostRef) {
                reactionsToDelete.push(docSnapshot.id);
                return null;
              }

              const originalPostSnap = await getDoc(originalPostRef);
              if (!originalPostSnap.exists()) {
                reactionsToDelete.push(docSnapshot.id);
                return null;
              }

              const originalPostData = originalPostSnap.data();
              let originalPostUserData;
              let mediaUrl = null;

              if (originalPostRef.path.includes("/startups/")) {
                const pathParts = originalPostRef.path.split("/");
                const ownerId = pathParts[1];
                const startupId = pathParts[3];

                const startupRef = doc(
                  firestore,
                  `users/${ownerId}/startups/${startupId}`
                );
                const startupSnap = await getDoc(startupRef);

                if (startupSnap.exists()) {
                  const startupData = startupSnap.data();
                  originalPostUserData = {
                    id: `startup_${startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage || profileImage,
                    isStartup: true,
                    startupId: startupId,
                    startupOwnerId: ownerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                  };
                }
              } else {
                const userDoc = await getDoc(
                  doc(firestore, "users", reactionData.originalPosterId)
                );
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  originalPostUserData = {
                    id: reactionData.originalPosterId,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    profileImage: userData.profileImage || profileImage,
                    isStartup: false,
                    urlSlug: userData.urlSlug,
                    bio: userData.bio || "",
                    link: userData.link || null,
                    linkText: userData.linkText || null,
                  };
                }
              }

              if (originalPostData.content?.url) {
                if (Array.isArray(originalPostData.content.url)) {
                  mediaUrl = await Promise.all(
                    originalPostData.content.url.map(async (url) => {
                      try {
                        const storageRef = ref(storage, url);
                        return await getDownloadURL(storageRef);
                      } catch (error) {
                        console.error("Error fetching media URL:", error);
                        return null;
                      }
                    })
                  );
                } else {
                  try {
                    const storageRef = ref(
                      storage,
                      originalPostData.content.url
                    );
                    mediaUrl = await getDownloadURL(storageRef);
                  } catch (error) {
                    console.error("Error fetching media URL:", error);
                  }
                }
              }

              if (mediaUrl) {
                preloadImages(Array.isArray(mediaUrl) ? mediaUrl : [mediaUrl]);
              }

              const connectionType = await checkConnectionType(
                userId,
                reactionData.originalPosterId
              );

              return {
                id: docSnapshot.id,
                ...reactionData,
                originalPost: {
                  id: docSnapshot.id,
                  ...originalPostData,
                  commentCount: originalPostData.commentCount || 0,
                  content: originalPostData.content
                    ? {
                        ...originalPostData.content,
                        url: mediaUrl || originalPostData.content?.url,
                      }
                    : null,
                  user: {
                    ...originalPostUserData,
                    connectionType: originalPostUserData?.isStartup
                      ? "Startup"
                      : originalPostUserData?.id === currentUser.uid
                      ? "You"
                      : connectionType,
                  },
                },
              };
            })
          );

          const validReactions = fetchedReactions.filter(
            (reaction) => reaction !== null
          );
          setReactions(validReactions);

          if (reactionsToDelete.length > 0) {
            await cleanupDeletedReactions(userId, reactionsToDelete);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setHasAttemptedLoad(true);
      }
    };

    fetchUserData();
  }, [urlSlug, currentUser]);

  const cleanupDeletedReactions = async (userId, reactionsToDelete) => {
    const batch = writeBatch(firestore);

    reactionsToDelete.forEach((reactionId) => {
      const reactionRef = doc(
        firestore,
        `users/${userId}/reactedPosts/${reactionId}`
      );
      batch.delete(reactionRef);
    });

    await batch.commit();
  };

  const fetchCurrentUserProfileImage = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setCurrentUserProfileImage(userDoc.data().profileImage || profileImage);
      }
    } catch (error) {
      console.error("Error fetching current user profile image:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (userId) => {
    console.log("handleNavigate called with userId:", userId);
    console.log("Current user ID:", currentUser?.uid);

    if (!userId) {
      console.error("handleNavigate called with no userId");
      return;
    }

    try {
      console.log("Fetching user data for ID:", userId);
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data:", userData);
        if (userData.urlSlug) {
          console.log("Navigating to urlSlug:", userData.urlSlug);
          navigate(`/${userData.urlSlug}`);
        } else {
          console.log("User does not have a urlSlug, using userId");
          navigate(`/user/${userId}`);
        }
      } else {
        console.error("User not found for ID:", userId);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const renderPostText = (post) => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
          onClick={(e) => handleLinkClick(e, post.id)}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPosts((prev) => ({ ...prev, [post.id]: true }));
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const handlePrevImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + totalImages) % totalImages,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const renderPostContent = (post) => {
    console.log("Rendering post content:", post.id, post.content);
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex[post.id] || 0]}
                    alt=""
                    className={classes.postContentImage}
                    onClick={() =>
                      handleNextImage(post.id, post.content.url.length)
                    }
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === (currentImageIndex[post.id] || 0)
                              ? classes.activeDot
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex((prev) => ({
                              ...prev,
                              [post.id]: index,
                            }));
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );

      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={(el) => (videoRefs.current[post.id] = el)}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume[post.id]}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume((prev) => ({
                      ...prev,
                      [post.id]: !prev[post.id],
                    }));
                  }}
                >
                  {!videoVolume[post.id] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        return (
          <div className={classes.postContentWrapper}>
            <a
              href={post.content.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.pdfPreview} ${
                !hasInteractions(post) ? classes.pdfPreviewNoInteractions : ""
              }`}
            >
              <div className={classes.pdfBox}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>
                    {(() => {
                      let fileName = "Unnamed PDF";
                      if (post.content.fileName) {
                        if (Array.isArray(post.content.fileName)) {
                          fileName = post.content.fileName[0] || "Unnamed PDF";
                        } else if (typeof post.content.fileName === "string") {
                          fileName = post.content.fileName;
                        }
                        // Remove .pdf extension if present
                        fileName = fileName.replace(/\.pdf$/i, "");
                      }
                      return fileName;
                    })()}
                  </div>
                  <div className={classes.open}>
                    View
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [reactions]);

  const handleLinkClick = (e, postId) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
    // Remove the else block that was navigating to the post
  };

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) return;

      // Get the correct post reference based on whether it's a startup post
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${postId}`
          );

      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      const reactedPostPath = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reactedPosts`
        : `users/${currentUser.uid}/reactedPosts`;

      const reactedPostRef = doc(firestore, reactedPostPath, postId);
      let updatedReactions = { ...(reaction.originalPost.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];
        await deleteDoc(reactedPostRef);

        // Delete notification
        if (reaction.originalPost.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `reaction_${postId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        await setDoc(reactedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: postId,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Only send notification if:
        // 1. User reacting to startup post and not the startup owner
        // 2. Startup reacting to post and not owned by the post owner
        // 3. User reacting to user post and not the post owner
        const shouldNotify =
          (reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.startupOwnerId) ||
          (selectedStartup &&
            currentUser.uid !== reaction.originalPost.user.id &&
            (!reaction.originalPost.user.isStartup ||
              selectedStartup.id !== reaction.originalPost.user.startupId)) ||
          (!selectedStartup &&
            !reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `reaction_${postId}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: postId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setReactions((prevReactions) =>
        prevReactions.map((r) =>
          r.originalPost.id === postId
            ? {
                ...r,
                originalPost: {
                  ...r.originalPost,
                  reactions: updatedReactions,
                },
              }
            : r
        )
      );

      setCurrentReactionEmojis((prev) => ({
        ...prev,
        [postId]: updatedReactions[reactorId] || null,
      }));
      setMainEmojiPickerOpen(null);

      setTimeout(() => {
        window.scrollTo(0, emojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const handleReactionClick = (postId) => {
    const reaction = reactions.find((r) => r.originalPost.id === postId);
    if (!reaction) return;

    const reactionKey = selectedStartup
      ? `startup_${selectedStartup.id}`
      : currentUser?.uid;
    const currentEmoji = selectedStartup
      ? reaction.originalPost.reactions?.[`startup_${selectedStartup.id}`]
      : reaction.originalPost.reactions?.[currentUser?.uid];

    if (currentEmoji) {
      handleEmojiSelect(currentEmoji, postId);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setMainEmojiPickerOpen(mainEmojiPickerOpen === postId ? null : postId);
    }
  };

  const handleReactionsClick = useCallback((postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal((prev) => ({ ...prev, [postId]: true }));
    setIsReactionsModalOpen(true);
  }, []);

  const handleDeletePost = async (postId) => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) {
        console.error("Post not found");
        return;
      }

      // Get the correct post reference based on whether it's a startup post
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${postId}`
          );

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete = reaction.originalPost.user.isStartup
        ? reaction.originalPost.user.startupOwnerId === currentUser.uid
        : reaction.originalPost.user.id === currentUser.uid;

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      const batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      // Get comments and collect affected users/startups
      const commentsRef = collection(
        firestore,
        reaction.originalPost.user.isStartup
          ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}/comments`
          : `users/${reaction.originalPost.user.id}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      // Clean up comments in commentedPosts for regular users
      for (const userId of affectedUserIds) {
        try {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${postId}`
          );
          batch.delete(commentedPostRef);
          operationCount++;
          await commitBatchIfNeeded();
        } catch (error) {
          console.error(
            `Error deleting commentedPost for user ${userId}:`,
            error
          );
        }
      }

      // Clean up comments in commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
            );
            batch.delete(startupCommentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          } catch (error) {
            console.error(
              `Error cleaning up startup commentedPost for ${startupId}:`,
              error
            );
          }
        }
      }

      // Clean up all notifications
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        try {
          const notificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("postId", "==", postId)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);

          notificationsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
            operationCount++;
          });
          await commitBatchIfNeeded();
        } catch (error) {
          console.error(
            `Error cleaning up notifications for user ${userDoc.id}:`,
            error
          );
        }
      }

      // Process reactions
      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupReactedPostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reactedPosts/${postId}`
                );
                batch.delete(startupReactedPostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userReactedPostRef = doc(
                firestore,
                `users/${userId}/reactedPosts/${postId}`
              );
              batch.delete(userReactedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up reaction for ${userId}:`, error);
          }
        }
      }

      // Process reposts
      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupRepostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reposts/${postId}`
                );
                batch.delete(startupRepostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userRepostRef = doc(
                firestore,
                `users/${userId}/reposts/${postId}`
              );
              batch.delete(userRepostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up repost for ${userId}:`, error);
          }
        }
      }

      // Delete all comments
      const commentDeletePromises = commentsSnapshot.docs.map((commentDoc) =>
        deleteDoc(commentDoc.ref)
      );
      await Promise.all(commentDeletePromises);

      // Clean up media content
      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${reaction.originalPost.user.id}/${postId}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error deleting files from storage:", error);
        }
      }

      // Delete the post itself
      batch.delete(postRef);
      operationCount++;

      // Commit final batch
      await batch.commit();

      // Update local state
      const updatedReactions = reactions.filter(
        (r) => r.originalPost.id !== postId
      );
      setReactions(updatedReactions);

      // Navigate only if there are no more reactions
      if (updatedReactions.length === 0) {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (reaction.originalPost.user.isStartup) {
            navigate(`/startup/${reaction.originalPost.user.startupUrlSlug}`);
          } else {
            navigate(`/${userData.urlSlug}`);
          }
        } else {
          navigate("/");
        }
      } else {
        setDeleteMessage({
          text: "Post deleted successfully.",
          postId,
          isSuccess: true,
        });
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = (postId) => {
    // Implement report post functionality
    console.log("Report post clicked for post:", postId);
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const handleRepostsClick = useCallback((postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal((prev) => ({ ...prev, [postId]: true }));
    setIsRepostsModalOpen(true);
  }, []);

  const handleEditComment = async (postId, commentId, isReply = false) => {
    const commentToEdit = comments[postId]?.find((c) => c.id === commentId);
    if (!commentToEdit) return;

    const canEdit =
      (commentToEdit.isStartup &&
        (selectedStartup?.id === commentToEdit.startupId ||
          currentUser.uid === commentToEdit.startupOwnerId)) ||
      (!commentToEdit.isStartup && commentToEdit.userId === currentUser.uid);

    if (!canEdit) {
      setErrorPopup({
        message: "You are not authorized to edit this comment.",
        isError: true,
      });
      return;
    }

    setEditingCommentId(commentId);
    const strippedText = stripHtmlTags(commentToEdit.text);
    setEditCommentText(strippedText);
    setOpenCommentDropdown(null);

    setTimeout(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.focus();
        adjustTextareaHeight(editTextareaRef.current);
        const length = editTextareaRef.current.value.length;
        editTextareaRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const reaction = reactions.find((r) => r.originalPost.id === postId);
      if (!reaction) throw new Error("Post not found");

      // Get the correct base path based on whether the post is from a startup
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${reaction.originalPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      const canEdit =
        (commentData.isStartup &&
          (selectedStartup?.id === commentData.startupId ||
            currentUser.uid === commentData.startupOwnerId)) ||
        (!commentData.isStartup && commentData.userId === currentUser.uid);

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Update comment text in notifications
      const notificationsQuery = query(
        collection(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`
        ),
        where("commentId", "==", commentId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);

      const batch = writeBatch(firestore);
      notificationsSnapshot.forEach((doc) => {
        const notificationData = doc.data();
        if (
          notificationData.type === "comment" ||
          notificationData.type === "reply"
        ) {
          batch.update(doc.ref, {
            commentText: trimmedText,
          });
        }
      });
      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to edit this comment"
            ? "You are not authorized to edit this comment."
            : "Error updating comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${editingPost.user.id}/posts/${editingPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setReactions((prevReactions) =>
        prevReactions.map((reaction) =>
          reaction.originalPost.id === editingPost.id
            ? {
                ...reaction,
                originalPost: {
                  ...reaction.originalPost,
                  text: updatedText,
                  isEdited: isEdited,
                },
              }
            : reaction
        )
      );

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        isStartupModalOpen ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker
          ? {
              top: `-${
                activeEmojiPicker.includes("_")
                  ? commentEmojiPickerScrollPosition
                  : emojiPickerScrollPosition
              }px`,
            }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserProfileImage} />
      ) : (
        <MainNavbar userImage={currentUserProfileImage} />
      )}
      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}

      {isReactionsModalOpen && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal((prev) => ({
              ...prev,
              [activePostReactions.postId]: false,
            }));
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser?.uid}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {isRepostsModalOpen && activePostReposts && (
        <RepostsModal
          onClose={() => {
            setShowRepostsModal((prev) => ({
              ...prev,
              [activePostReposts.postId]: false,
            }));
            setIsRepostsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, repostsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser?.uid}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasAttemptedLoad && reactions.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No reactions found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            <div className={classes.reactionsContainer}>
              {reactions.map((reaction) => (
                <div key={reaction.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>
                    {userData.isStartup ? (
                      // Startup reaction header
                      <>
                        <img
                          src={userData.startupImage || profileImage}
                          alt={userData.startupName}
                          className={classes.reactionImage}
                          onClick={() =>
                            handleNavigate(`startup_${userData.startupId}`)
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <div className={classes.reactionTextWrapper}>
                          <span className={classes.reactionText}>
                            <span
                              className={classes.reactionUsername}
                              onClick={() =>
                                handleNavigate(`startup_${userData.startupId}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {userData.startupName}
                            </span>{" "}
                            reacted{" "}
                            <span className={classes.textEmoji}>
                              {currentReactionEmojis[
                                reaction.originalPost.id
                              ] || reaction.emoji}{" "}
                            </span>
                            to this
                          </span>
                        </div>
                      </>
                    ) : (
                      // User reaction header
                      <>
                        <img
                          src={userData.profileImage || profileImage}
                          alt={`${userData.firstName} ${userData.lastName}`}
                          className={classes.reactionImage}
                          onClick={() => handleNavigate(userData.id)}
                          style={{ cursor: "pointer" }}
                        />
                        <div className={classes.reactionTextWrapper}>
                          <span className={classes.reactionText}>
                            <span
                              className={classes.reactionUsername}
                              onClick={() => handleNavigate(userData.id)}
                              style={{ cursor: "pointer" }}
                            >
                              {isOwnProfile
                                ? "You"
                                : `${userData.firstName} ${userData.lastName}`}
                            </span>{" "}
                            reacted{" "}
                            <span className={classes.textEmoji}>
                              {currentReactionEmojis[
                                reaction.originalPost.id
                              ] || reaction.emoji}{" "}
                            </span>
                            to this
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={
                          reaction.originalPost.user.profileImage ||
                          profileImage
                        }
                        alt=""
                        onClick={() =>
                          handleNavigate(reaction.originalPost.user.id)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() =>
                              handleNavigate(reaction.originalPost.user.id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className={classes.nameText}>
                              {`${reaction.originalPost.user.firstName} ${reaction.originalPost.user.lastName}`}
                            </span>
                          </p>
                          {reaction.originalPost.user.connectionType && (
                            <span className={classes.connectionType}>
                              • {reaction.originalPost.user.connectionType}
                            </span>
                          )}
                        </div>
                        {reaction.originalPost.user.bio && (
                          <p className={classes.bio}>
                            {reaction.originalPost.user.bio}
                          </p>
                        )}
                        {reaction.originalPost.user.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={reaction.originalPost.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {reaction.originalPost.user.linkText ||
                                  reaction.originalPost.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {reaction.originalPost.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(reaction.originalPost.createdAt)}
                            </p>
                          )}
                          {reaction.originalPost.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[reaction.id]
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDropdown((prev) => ({
                            ...prev,
                            [reaction.originalPost.id]:
                              !prev[reaction.originalPost.id],
                          }));
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[reaction.originalPost.id] && (
                        <div className={classes.optionsDropdown}>
                          {reaction.originalPost.user.id === currentUser?.uid ||
                          (reaction.originalPost.user.isStartup &&
                            reaction.originalPost.user.startupOwnerId ===
                              currentUser.uid) ? (
                            <>
                              <button
                                onClick={() =>
                                  handleEditPost(reaction.originalPost)
                                }
                                className={classes.editPost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  handleDeletePost(reaction.originalPost.id)
                                }
                                className={classes.deletePost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() =>
                                handleReportPost(reaction.originalPost.id)
                              }
                              className={classes.reportPost}
                            >
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {reaction.originalPost.text &&
                    renderPostText(reaction.originalPost)}
                  {renderPostContent(reaction.originalPost)}
                  {hasInteractions(reaction) && (
                    <div className={classes.postInteractionsInfo}>
                      {reaction.originalPost.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(
                                reaction.originalPost.reactions
                              )
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(
                                          reaction.originalPost.reactions
                                        ).length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                      marginBottom:
                                        "var(--emoji-margin-bottom)",
                                      marginTop: "var(--emoji-margin-top)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            {Object.keys(reaction.originalPost.reactions)
                              .length > 0 && (
                              <span
                                className={classes.reactionCount}
                                style={{
                                  marginLeft: "var(--count-margin-left)",
                                }}
                              >
                                {
                                  Object.keys(reaction.originalPost.reactions)
                                    .length
                                }
                              </span>
                            )}
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {reaction.originalPost.commentCount > 0 && (
                          <span
                            className={classes.commentCount}
                            onClick={() =>
                              toggleComments(reaction.originalPost.id)
                            }
                          >
                            {reaction.originalPost.commentCount} comment
                            {reaction.originalPost.commentCount !== 1
                              ? "s"
                              : ""}
                          </span>
                        )}
                        {reaction.originalPost.commentCount > 0 &&
                          reaction.originalPost.reposts &&
                          Object.keys(reaction.originalPost.reposts).length >
                            0 && <span className={classes.dot}>•</span>}
                        {reaction.originalPost.reposts &&
                          Object.keys(reaction.originalPost.reposts).length >
                            0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(
                                  reaction.originalPost.id,
                                  reaction.originalPost.reposts
                                )
                              }
                            >
                              {
                                Object.keys(reaction.originalPost.reposts)
                                  .length
                              }{" "}
                              repost
                              {Object.keys(reaction.originalPost.reposts)
                                .length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                  <div className={classes.postInteractions}>
                    <StartupInteractionToggle
                      currentUser={{
                        ...currentUser,
                        firstName: reaction?.user?.firstName,
                        lastName: reaction?.user?.lastName,
                        bio: reaction?.user?.bio,
                      }}
                      onStartupSelect={setSelectedStartup}
                      selectedStartup={selectedStartup}
                      currentUserProfileImage={currentUserProfileImage}
                      onModalOpen={() => {
                        setStartupModalScrollPosition(window.scrollY);
                        setIsStartupModalOpen(true);
                      }}
                      onModalClose={() => {
                        setIsStartupModalOpen(false);
                        setTimeout(() => {
                          window.scrollTo(0, startupModalScrollPosition);
                        }, 0);
                      }}
                    />
                    <button
                      className={`${classes.interactionButton} ${
                        (selectedStartup &&
                          reaction.originalPost.reactions?.[
                            `startup_${selectedStartup.id}`
                          ]) ||
                        (!selectedStartup &&
                          currentReactionEmojis[reaction.originalPost.id])
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() =>
                        handleReactionClick(reaction.originalPost.id)
                      }
                    >
                      {selectedStartup ? (
                        reaction.originalPost.reactions?.[
                          `startup_${selectedStartup.id}`
                        ] ? (
                          <span>
                            {
                              reaction.originalPost.reactions[
                                `startup_${selectedStartup.id}`
                              ]
                            }
                          </span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )
                      ) : currentReactionEmojis[reaction.originalPost.id] ? (
                        <span>
                          {currentReactionEmojis[reaction.originalPost.id]}
                        </span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        showComments[reaction.originalPost.id]
                          ? classes.activeCommentButton
                          : ""
                      }`}
                      onClick={() => toggleComments(reaction.originalPost.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        reaction.originalPost.reposts?.[currentUser?.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(reaction.originalPost.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>
                  {mainEmojiPickerOpen === reaction.originalPost.id && (
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(emoji, reaction.originalPost.id)
                      }
                      onClose={() => {
                        setMainEmojiPickerOpen(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  {/* Add comments section */}
                  {showComments[reaction.originalPost.id] && (
                    <div className={classes.commentsSection}>
                      <CommentInputSection
                        currentUser={currentUser}
                        selectedStartup={selectedStartup}
                        newComment={newComment[reaction.originalPost.id] || ""}
                        onCommentChange={(e) =>
                          handleCommentInputChange(e, reaction.originalPost.id)
                        }
                        onSubmit={() =>
                          handleAddComment(reaction.originalPost.id)
                        }
                      />
                      <div className={classes.commentsList}>
                        {renderComments(reaction.originalPost.id)}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default AllReactions;
