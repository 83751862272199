import React, { useState } from "react";
import { waitlist } from "../../utils/waitlistForm";
import profile from "../../assets/illustrations/profile.png";
import startup from "../../assets/illustrations/startup.png";
import community from "../../assets/illustrations/community.png";
import events from "../../assets/illustrations/events.png";
import rocket from "../../assets/illustrations/rocket.png";
import classes from "./Content.module.css";

function Content() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSignUp = (e) => {
    e.preventDefault();
    waitlist(email, setSuccess, setError);
  };

  return (
    <div>
      <div className={classes.contentHeader}>
        <img
          src={profile}
          alt=""
          className={`${classes.leftImage} ${classes.image}`}
        />
        <div className={`${classes.headerAbout} ${classes.textContainer}`}>
          <h2 className={classes.title1}>The startup community,</h2>
          <h2 className={classes.title}>at your fingertips.</h2>
          <p className={classes.contentP}>
            Need a co-founder? Investor? Advisor? It’s easy with
            <span className={classes.foundry}> Foundry</span>. Create a
            <span className={classes.featureWord}> Profile</span>, add your
            <span className={classes.featureWord}> Skills/Interests</span>, and
            make
            <span className={classes.featureWord}> Connections</span>.
          </p>
        </div>
      </div>
      <div className={classes.contentHeader}>
        <div className={`${classes.headerAbout} ${classes.textContainer}`}>
          <h2 className={classes.title1}>Where startups start,</h2>
          <h2 className={classes.title}>grow, and thrive.</h2>
          <p className={classes.contentP}>
            Whoever. Wherever. You can always start a Startup on{" "}
            <span className={classes.foundry}> Foundry</span>. Grow a{" "}
            <span className={classes.featureWord}> Following</span>, build a
            <span className={classes.featureWord}> Team</span>, and raise money
            with <span className={classes.featureWord}> Support</span> from the
            community.
          </p>
        </div>
        <img
          src={startup}
          alt=""
          className={`${classes.rightImage} ${classes.image}`}
        />
      </div>
      <div className={classes.contentHeader}>
        <img
          src={community}
          alt=""
          className={`${classes.leftImage} ${classes.image}`}
        />
        <div className={`${classes.headerAbout} ${classes.textContainer}`}>
          <h2 className={classes.title1}>Join the conversation, </h2>
          <h2 className={classes.title}>shape the future.</h2>
          <p className={classes.contentP}>
            Whatever your interests are, there’s a place for them on
            <span className={classes.foundry}> Foundry</span>. Create or join a
            <span className={classes.featureWord}> Community</span> and engage
            with like-minded{" "}
            <span className={classes.featureWord}> Members</span>.
          </p>
        </div>
      </div>
      <div className={classes.contentHeader}>
        <div className={`${classes.headerAbout} ${classes.textContainer}`}>
          <h2 className={classes.title1}>Digital meets physical,</h2>
          <h2 className={classes.title}>and beyond.</h2>
          <p className={classes.contentP}>
            Meet-ups? Hackathons? Accelerators? You name it,{" "}
            <span className={classes.foundry}> Foundry</span> has them all.
            Register for <span className={classes.featureWord}> Events</span>,
            apply for <span className={classes.featureWord}> Programs</span>, or
            create your own.
          </p>
        </div>
        <img
          src={events}
          alt=""
          className={`${classes.rightImage} ${classes.image}`}
        />
      </div>
      <div className={classes.contentHeader}>
        <img
          src={rocket}
          alt=""
          className={`${classes.rocketImage} ${classes.image}`}
        />
        <div className={`${classes.headerAbout} ${classes.textContainer}`}>
          <h1 className={classes.title1}>Make ideas</h1>
          <h1 className={classes.title}>happen.</h1>
          <form onSubmit={handleSignUp} className={classes.footerInteractions}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.email}
            />
            <button type="submit" className={classes.button}>
              Join waitlist
            </button>
          </form>
          {success === true && (
            <p className={classes.success}>
              <div className={classes.message}>
                <div className={classes.icons}>
                  <span class="material-symbols-outlined">check_circle</span>
                </div>
                You're now on the waitlist!
              </div>
            </p>
          )}
          {error && (
            <p className={classes.error}>
              <div className={classes.message}>
                <div className={classes.icons}>
                  <span class="material-symbols-outlined">error</span>
                </div>
                {error}
              </div>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
export default Content;
