import React, { useState, useRef, useEffect } from "react";
import defaultImage from "../../assets/images/startupImage.jpg";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./AddCommunityModal.module.css";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import { generateCommunityUrlSlug } from "../../utils/communityUrlUtils";

const AddCommunityModal = ({ onClose, currentUser, onCommunityAdded }) => {
  const [communityName, setCommunityName] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [tagline, setTagline] = useState("");
  const [industry, setIndustry] = useState("");
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const [communityImage, setCommunityImage] = useState(null);
  const [currentCommunityImageUrl, setCurrentCommunityImageUrl] =
    useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleCommunityNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setCommunityName(e.target.value);
    }
  };

  const handleTaglineChange = (e) => {
    if (e.target.value.length <= 250) {
      setTagline(e.target.value);
    }
  };

  const handleIndustryChange = (e) => {
    if (e.target.value.length <= 50) {
      setIndustry(e.target.value);
    }
  };

  const handleTagsChange = (e) => {
    const inputValue = e.target.value;
    setTagInput(inputValue);

    // Process the input into tags array
    const processedTags = inputValue
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);

    setTags(processedTags);
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      setIsValidUrl(validateUrl(newLink));
      if (!newLink) {
        setLinkText("");
      }
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCommunityImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentCommunityImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);
        setCommunityImage(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const areRequiredFieldsFilled = () => {
    return communityName.trim() !== "" && industry.trim() !== "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!communityName || !industry) {
      setError("Please fill in all required fields.");
      return false;
    }

    setLoading(true);
    setError("");

    try {
      let communityImageUrl = "";

      if (communityImage && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          currentCommunityImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const tempCommunityId = `temp_${Date.now()}`;
        const tempPostId = "profile";
        const communityImageRef = ref(
          storage,
          `communityImages/${
            currentUser.uid
          }/${tempCommunityId}/${tempPostId}/${Date.now()}.jpg`
        );

        await uploadBytes(communityImageRef, blob);
        communityImageUrl = await getDownloadURL(communityImageRef);
      }

      const communityUrlSlug = await generateCommunityUrlSlug(communityName);
      const timestamp = serverTimestamp();

      const communityData = {
        communityName,
        tagline,
        link,
        linkText: link ? linkText : "",
        communityImage: communityImageUrl,
        communityUrlSlug,
        industry,
        tags,
        followingCount: 0,
        createdAt: timestamp,
        isPrivate,
      };

      const communitiesRef = collection(
        firestore,
        `users/${currentUser.uid}/communities`
      );
      const docRef = await addDoc(communitiesRef, communityData);

      if (onCommunityAdded) {
        onCommunityAdded({
          id: docRef.id,
          ...communityData,
          createdAt: new Date(),
        });
      }

      onClose(true);
      return true;
    } catch (error) {
      console.error("Error adding community:", error);
      setError("Failed to add community. Please try again.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const cropSize = { width: 128, height: 128 };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Add community
            <svg
              className={classes.closeIcon}
              onClick={() => onClose(false)}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <div className={classes.imageContainer}>
                <div className={classes.profileImage}>
                  {currentCommunityImageUrl && isCropping ? (
                    <div className={classes.cropContainer}>
                      <Cropper
                        image={currentCommunityImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        cropSize={cropSize}
                        objectFit="cover"
                      />
                    </div>
                  ) : (
                    <img
                      src={currentCommunityImageUrl || defaultImage}
                      alt="Community"
                    />
                  )}
                </div>
                <button
                  type="button"
                  className={classes.uploadButton}
                  onClick={() =>
                    document.getElementById("communityImage").click()
                  }
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="gray"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <input
                type="file"
                id="communityImage"
                accept="image/*"
                onChange={handleCommunityImageChange}
                className={classes.profileButton}
              />

              <label htmlFor="communityName" className={classes.label}>
                Name*
              </label>
              <input
                id="communityName"
                type="text"
                placeholder="Ex: Web Development"
                className={classes.userInput}
                value={communityName}
                onChange={handleCommunityNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{communityName.length}/50</div>
              <div className={classes.border}></div>

              <label htmlFor="tagline" className={classes.label}>
                Tagline
              </label>
              <textarea
                id="tagline"
                value={tagline}
                onChange={handleTaglineChange}
                className={classes.textArea}
                placeholder="Ex: A community for web developers to share knowledge and experiences"
              />
              <div className={classes.charCount}>{tagline.length}/250</div>
              <div className={classes.border}></div>

              <label htmlFor="industry" className={classes.label}>
                Industry*
              </label>
              <input
                id="industry"
                type="text"
                placeholder="Ex: Technology"
                className={classes.userInput}
                value={industry}
                onChange={handleIndustryChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{industry.length}/50</div>

              <div className={classes.privacySection}>
                <label className={classes.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                    className={classes.checkbox}
                  />
                  <span className={classes.labelText}>
                    Make this community private
                  </span>
                </label>
                <div className={classes.privacyHint}>
                  {isPrivate
                    ? "New members will need approval to join this community"
                    : "Anyone can join this community without approval"}
                </div>
              </div>
              <div className={classes.border}></div>

              <div className={classes.border}></div>

              <label htmlFor="link" className={classes.label}>
                Link
              </label>
              <input
                id="link"
                type="url"
                placeholder="Ex: https://example.com"
                className={classes.userInput}
                value={link}
                onChange={handleLinkChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{link.length}/250</div>

              <label
                htmlFor="linkText"
                className={`${classes.linkTextLabel} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                Link text
              </label>
              <input
                id="linkText"
                type="text"
                placeholder="Ex: example.com"
                className={`${classes.userInput} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
                value={linkText}
                onChange={handleLinkTextChange}
                disabled={loading || !isValidUrl}
                style={{ opacity: isValidUrl ? 1 : 0.5 }}
              />
              <div
                className={`${classes.smallPrint} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                <p>Customise how your link will appear (optional).</p>
                <span className={classes.charCount}>{linkText.length}/50</span>
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""} ${
              !areRequiredFieldsFilled() ? classes.disabled : ""
            }`}
            disabled={loading || !areRequiredFieldsFilled()}
            onClick={() =>
              formRef.current?.dispatchEvent(
                new Event("submit", { bubbles: true, cancelable: true })
              )
            }
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCommunityModal;
