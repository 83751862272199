import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export async function generateEventUrlSlug(eventName) {
  const baseSlug = eventName.toLowerCase().replace(/\s+/g, "");
  let slug = baseSlug;
  let counter = 1;

  while (true) {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    let slugExists = false;

    for (const userDoc of usersSnapshot.docs) {
      const eventsRef = collection(userDoc.ref, "events");
      const q = query(eventsRef, where("eventUrlSlug", "==", slug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        slugExists = true;
        break;
      }
    }

    if (!slugExists) {
      return slug;
    }

    counter++;
    slug = `${baseSlug}${counter}`;
  }
}

export async function updateEventUrlSlug(userId, eventId, eventName) {
  const eventRef = doc(firestore, `users/${userId}/events/${eventId}`);
  const newSlug = await generateEventUrlSlug(eventName);
  await updateDoc(eventRef, { eventUrlSlug: newSlug });
  return newSlug;
}
