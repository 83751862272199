import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import StartupInteractionToggle from "../../../components/Dropdowns/StartupInteractionToggle";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import { Link } from "react-router-dom";
import classes from "./Dashboard.module.css";
import { firestore, auth } from "../../../firebase";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "../../../components/Dropdowns/EmojiPicker";
import RepostsModal from "../../../components/Modals/RepostsModal";
import { useUpload } from "../../../contexts/UploadContext";
import { openDB } from "idb";
import {
  doc,
  getDoc,
  collection,
  where,
  query,
  orderBy,
  writeBatch,
  limit,
  setDoc,
  getDocs,
  startAfter,
  deleteDoc,
  addDoc, // Add this import
  updateDoc,
  deleteField,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  onSnapshot,
  collectionGroup,
  increment,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import PostModal from "../../../components/Modals/PostModal";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { convertUrlsToLinks } from "../../../utils/textUtils";
import PostActionPopup from "../../Posts/PostActionPopup";

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

function Dashboard() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [globalStartupModalState, setGlobalStartupModalState] = useState({
    isOpen: false,
    scrollPosition: 0,
    sourceLocation: null, // 'topCard' or 'postInteraction'
    postId: null, // Track which post triggered the modal
  });
  // Add these state variables with your other useState declarations
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [postInteractionModalState, setPostInteractionModalState] = useState({
    isOpen: false,
    scrollPosition: 0,
    postId: null,
  });
  const [startupModalInteraction, setStartupModalInteraction] = useState({
    postId: null,
    isOpen: false,
  });
  const [mainEmojiPickerOpen, setMainEmojiPickerOpen] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [successPostMessage, setSuccessPostMessage] = useState(null);
  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();
  const [cancelMessage, setCancelMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [isMobileFocused, setIsMobileFocused] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);

  const POST_HEIGHT = 400; // Estimate of average post height, adjust as needed
  const OVERSCAN_COUNT = 5; // Number of items to render beyond the visible area

  const [itemSizes, setItemSizes] = useState({});
  const listRef = useRef();
  const rowHeights = useRef({});

  const getItemSize = (index) => rowHeights.current[index] || 50; // Default to 50px if height is unknown

  const [isSafari, setIsSafari] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);

  const [commentedPosts, setCommentedPosts] = useState({});

  const [errorPopup, setErrorPopup] = useState(null);

  const [seenPosts, setSeenPosts] = useState({});

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);

  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isHovered, setIsHovered] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [posts, setPosts] = useState({
    directAndInteracted: [],
    indirectSuggested: [],
    extendedSuggested: [],
  });
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [directConnectionIds, setDirectConnectionIds] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);
  const [repostMessage, setRepostMessage] = useState(null);
  const [deleteTimeouts, setDeleteTimeouts] = useState({});

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);

  const [isIOS, setIsIOS] = useState(false);

  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});

  const [activeCommentId, setActiveCommentId] = useState(null);

  const replyInputRef = useRef({});

  const commentInputRef = useRef({});

  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);

  const [removedPosts, setRemovedPosts] = useState({});

  // const [uploadingPosts, setUploadingPosts] = useState({});
  const uploadingPostsRef = useRef(state.uploadingPosts);
  const [canceledUploads, setCanceledUploads] = useState({});

  const [showDropdown, setShowDropdown] = useState({});

  const [visiblePosts, setVisiblePosts] = useState([]);
  const [page, setPage] = useState(0);
  const loaderRef = useRef(null);
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});

  const [openDropdown, setOpenDropdown] = useState(null);

  const [openUploadDropdown, setOpenUploadDropdown] = useState(null);

  const [postReactions, setPostReactions] = useState({});

  const [replyComments, setReplyComments] = useState({});
  const [replyingTo, setReplyingTo] = useState(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [activePostId, setActivePostId] = useState(null);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [comments, setComments] = useState({});

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName || "",
        image: selectedStartup.startupImage || profileImage,
        bio: selectedStartup.bio || "",
        link: selectedStartup.link || null,
        linkText: selectedStartup.linkText || null,
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName || ""} ${
        currentUser.lastName || ""
      }`.trim(),
      image: currentUser.profileImage || profileImage,
      bio: currentUser.bio || "",
      link: currentUser.link || null,
      linkText: currentUser.linkText || null,
      connectionType: "You",
      isStartup: false,
    };
  };

  const handleReplyInputChange = (e, postId, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const groupedPosts = Object.values(posts).reduce((acc, postGroup) => {
    postGroup.forEach((post) => {
      if (!post || typeof post !== "object") {
        console.error("Invalid post object:", post);
        return acc;
      }

      if (!post.createdAt && !post.latestActivity) {
        console.warn("Post with invalid timestamps:", post.id);
        return acc;
      }

      if (!acc[post.id]) {
        acc[post.id] = {
          ...post,
          reactions: [],
          reposts: [],
        };
      }

      Object.entries(post.reactions || {}).forEach(([userId, emoji]) => {
        if (
          directConnectionIds.includes(userId) &&
          userId !== currentUser.uid
        ) {
          acc[post.id].reactions.push({ userId, emoji });
        }
      });

      Object.entries(post.reposts || {}).forEach(([userId]) => {
        if (
          directConnectionIds.includes(userId) &&
          userId !== currentUser.uid
        ) {
          acc[post.id].reposts.push({ userId });
        }
      });
    });

    return acc;
  }, {});

  const getValidTimestamp = (post) => {
    if (!post || post.isRemovedMessage) {
      return Date.now(); // Return current timestamp for removed messages
    }

    const getFieldTimestamp = (field) => {
      if (!field) return 0;
      if (typeof field.toMillis === "function") return field.toMillis();
      if (typeof field.seconds === "number") return field.seconds * 1000;
      if (field instanceof Date) return field.getTime();
      return 0;
    };

    const latestActivityTime = getFieldTimestamp(post.latestActivity);
    const createdAtTime = getFieldTimestamp(post.createdAt);

    return Math.max(latestActivityTime, createdAtTime, 0);
  };

  const sortDirectPosts = (posts) => {
    return posts.sort((a, b) => {
      const timeA = getPostSortTime(a);
      const timeB = getPostSortTime(b);
      return timeB - timeA;
    });
  };

  const sortedPosts = useMemo(() => {
    return Object.values(groupedPosts).sort((a, b) => {
      return getValidTimestamp(b) - getValidTimestamp(a);
    });
  }, [groupedPosts]);

  const validSortedPosts = useMemo(
    () => sortedPosts.filter((post) => getValidTimestamp(post) > 0),
    [sortedPosts]
  );

  const loadMorePosts = useCallback(() => {
    const newPosts = validSortedPosts.slice(page * 10, (page + 1) * 10);
    setVisiblePosts((prevPosts) => [...prevPosts, ...newPosts]);
    setPage((prevPage) => prevPage + 1);
  }, [validSortedPosts, page]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        loadMorePosts();
      }
    },
    [loadMorePosts, hasMore]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleReplyButton = (commentId) => {
    setReplyingTo((prevReplyingTo) => {
      if (prevReplyingTo === commentId) {
        // If the reply input is already open for this comment, close it
        setActiveCommentId(null);
        setIsMobileFocused(false);
        return null;
      } else {
        // Open the reply input for this comment
        setActiveCommentId(commentId);
        setIsMobileFocused(true);

        // Add a slight delay before focusing
        setTimeout(() => {
          if (replyInputRef.current[commentId]) {
            const inputElement = replyInputRef.current[commentId];
            inputElement.focus();
            inputElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });

            if (isIOS) {
              forceKeyboardOpen(inputElement);
            }
          }
        }, 100);

        return commentId;
      }
    });

    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  const handleEditPost = (post) => {
    console.log("Original post for editing:", post);

    // For startup posts, we need the actual owner's ID, not the startup_* ID
    const isStartupPost = post.user?.isStartup;
    const ownerId = isStartupPost ? post.user.startupOwnerId : post.user.id;
    const startupId = isStartupPost ? post.user.startupId : null;

    // Create the post object with correct path information
    const postToEdit = {
      ...post,
      _path: {
        userId: post.user.startupOwnerId, // Use the actual owner's ID for startups
        startupId: post.user.startupId, // Include startup ID if it's a startup post
        isStartup: post.user.isStartup,
      },
    };

    console.log("Modified post for editing:", {
      isStartupPost,
      ownerId,
      startupId,
      path: postToEdit._path,
    });

    setEditingPost(postToEdit);
    setShowEditModal(true);
    setPostModalScrollPosition(window.scrollY);
    setIsPostModalOpen(true);
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const makeLinksClickable = (text) => {
    const urlRegex =
      /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part.startsWith("http") ? part : `https://${part}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "orangered", fontWeight: 900 }}
          onClick={(e) => e.stopPropagation()}
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (isMobileFocused) {
      const timer = setTimeout(() => {
        if (replyInputRef.current[activeCommentId]) {
          openMobileKeyboard(replyInputRef.current[activeCommentId]);
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isMobileFocused, activeCommentId]);

  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({
      ...prev,
      [postId]: textarea.value,
    }));

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = "auto";
    // Set the height to the scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const checkDirectInteraction = async (post) => {
    if (!post || !currentUser) return false;

    const directConnections = await fetchDirectConnections(currentUser.uid);
    const followedStartupsRef = collection(
      firestore,
      `users/${currentUser.uid}/startupsFollowing`
    );
    const followedStartupsSnapshot = await getDocs(
      query(followedStartupsRef, where("status", "==", "following"))
    );
    const followedStartupIds = followedStartupsSnapshot.docs.map(
      (doc) => `startup_${doc.id}`
    );

    // Check all types of interactions
    for (const interactionType of ["reactions", "reposts", "comments"]) {
      if (post[interactionType]) {
        for (const interactorId of Object.keys(post[interactionType])) {
          if (
            directConnections.includes(interactorId) ||
            followedStartupIds.includes(interactorId)
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const fetchFollowedStartups = async (userId) => {
    const startupsFollowingRef = collection(
      firestore,
      `users/${userId}/startupsFollowing`
    );
    const startupsFollowingSnapshot = await getDocs(
      query(startupsFollowingRef, where("status", "==", "following"))
    );
    return startupsFollowingSnapshot.docs.map((doc) => ({
      startupId: doc.id,
      ownerId: doc.data().ownerId,
    }));
  };

  const checkConnectionType = useCallback(async (userId, targetId) => {
    if (userId === targetId) return "You";

    if (targetId.startsWith("startup_")) {
      const startupId = targetId.replace("startup_", "");
      const startupFollowingRef = doc(
        firestore,
        `users/${userId}/startupsFollowing/${startupId}`
      );
      const startupFollowingDoc = await getDoc(startupFollowingRef);
      if (
        startupFollowingDoc.exists() &&
        startupFollowingDoc.data().status === "following"
      ) {
        return "Direct";
      }
    }

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${targetId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  }, []);

  const findPostById = useCallback(
    (postId) => {
      // Check in each category
      const post = [
        ...posts.directAndInteracted,
        ...posts.indirectSuggested,
        ...posts.extendedSuggested,
      ].find((p) => p?.id === postId);

      if (post && post.user.isStartup) {
        return {
          ...post,
          user: {
            ...post.user,
            startupOwnerId: post.user.startupOwnerId || currentUser.uid,
          },
        };
      }
      return post;
    },
    [posts, currentUser]
  );

  const fetchComments = useCallback(
    async (postId, postUserId) => {
      if (!postId || !postUserId || !currentUser) {
        console.log("Missing data for fetching comments", {
          postId,
          postUserId,
          currentUser,
        });
        return;
      }

      try {
        const post = findPostById(postId);
        if (!post) {
          console.error("Post not found");
          return;
        }

        // Determine the correct path based on whether it's a startup post
        const basePath = post.user.isStartup
          ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
          : `users/${postUserId}`;

        const commentsRef = collection(
          firestore,
          `${basePath}/posts/${postId}/comments`
        );
        const q = query(commentsRef, orderBy("createdAt", "asc"));

        const snapshot = await getDocs(q);
        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();

            // Handle startup comments
            if (commentData.isStartup) {
              const startupOwnerId = commentData.startupOwnerId;
              const startupId = commentData.startupId;

              // Fetch startup owner's data to get any additional info if needed
              const startupOwnerDoc = await getDoc(
                doc(firestore, "users", startupOwnerId)
              );
              if (!startupOwnerDoc.exists()) {
                console.log("Startup owner not found:", startupOwnerId);
                return null;
              }

              // Fetch startup data
              const startupDoc = await getDoc(
                doc(firestore, `users/${startupOwnerId}/startups/${startupId}`)
              );
              if (!startupDoc.exists()) {
                console.log("Startup not found:", startupId);
                return null;
              }

              const startupData = startupDoc.data();

              return {
                id: docSnapshot.id,
                ...commentData,
                userName: startupData.startupName,
                userImage: startupData.startupImage,
                connectionType: "Startup",
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                postId,
                postUserId,
                rootCommentId: commentData.rootCommentId,
                isRootComment: commentData.isRootComment,
              };
            }

            // Handle regular user comments
            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) {
              console.log("User not found for comment:", commentData.userId);
              return null;
            }

            const userData = userDoc.data();
            let connectionType = "Unknown";
            try {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            } catch (error) {
              console.error("Error checking connection type:", error);
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId,
              postUserId,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
            };
          })
        );

        const validComments = fetchedComments.filter(
          (comment) => comment !== null
        );

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: validComments,
        }));
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },
    [currentUser, checkConnectionType, findPostById]
  );

  const [activeCommentPostId, setActiveCommentPostId] = useState(null);
  const [newComment, setNewComment] = useState("");

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown((prev) => (prev === commentId ? null : commentId));
  };

  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        console.error(`Post not found for comment: ${commentId}`);
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      // Determine the correct base path based on whether it's a startup post
      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        console.error("Comment does not exist");
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid ||
          currentUser.uid ===
            (targetPost.user.isStartup
              ? targetPost.user.startupOwnerId
              : targetPost.user.id);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          currentUser.uid ===
            (targetPost.user.isStartup
              ? targetPost.user.startupOwnerId
              : targetPost.user.id);
      }

      if (!canDelete) {
        console.error("User not authorized to delete this comment");
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();

              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {
          console.error("Error in deleteStartupNotifications:", error);
        }
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        // Use the correct post owner ID for notification deletion
        const postOwnerId = targetPost.user.isStartup
          ? targetPost.user.startupOwnerId
          : targetPost.user.id;
        if (commentData.userId !== postOwnerId) {
          await deleteDoc(
            doc(
              firestore,
              `users/${postOwnerId}/notifications/comment_${commentRef.id}`
            )
          );
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.userId.startsWith("startup_")
            ) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        const reactionPromises = [];
        if (commentData.isStartup) {
          const startupOwnerReactionsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const startupOwnerReactionsSnapshot = await getDocs(
            startupOwnerReactionsQuery
          );
          reactionPromises.push(
            ...startupOwnerReactionsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );
        }

        // Delete reaction notifications
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          reactionPromises.push(
            ...userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            reactionPromises.push(
              ...startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }
        await Promise.all(reactionPromises);

        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentToDeleteRef);

      const batch = writeBatch(firestore);

      // Use the correct path for updating the post
      const postRef = doc(firestore, `${basePath}/posts/${postId}`);

      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }
      for (const startupId of affectedStartupIds) {
        batch.update(postRef, {
          [`comments.startup_${startupId}`]: deleteField(),
        });
      }

      // Update commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${postId}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Update commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));

        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );

            if (startupCommentedPostSnap.exists()) {
              const startupCommentedPostData = startupCommentedPostSnap.data();
              const updatedComments = { ...startupCommentedPostData.comments };

              deletedCommentIds.forEach((commentId) => {
                delete updatedComments[commentId];
              });

              if (Object.keys(updatedComments).length === 0) {
                batch.delete(startupCommentedPostRef);
              } else {
                batch.update(startupCommentedPostRef, {
                  comments: updatedComments,
                });
              }
            }
          } catch (error) {
            console.error(
              `Error updating commentedPosts for startup ${startupId} under user ${userDoc.id}:`,
              error
            );
          }
        }
      }

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((p) => {
            if (p.id === postId) {
              const updatedPost = {
                ...p,
                commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
              };
              const updatedComments = { ...updatedPost.comments };
              affectedUserIds.forEach((userId) => {
                delete updatedComments[userId];
              });
              affectedStartupIds.forEach((startupId) => {
                delete updatedComments[`startup_${startupId}`];
              });
              updatedPost.comments = updatedComments;
              return updatedPost;
            }
            return p;
          });

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const organizeComments = (comments) => {
    const commentMap = {};
    const rootComments = [];

    comments.forEach((comment) => {
      commentMap[comment.id] = { ...comment, replies: [] };
    });

    comments.forEach((comment) => {
      if (comment.parentCommentId) {
        const parentComment = commentMap[comment.parentCommentId];
        if (parentComment) {
          parentComment.replies.push(commentMap[comment.id]);
        } else {
          // If parent comment is not found, treat it as a root comment
          rootComments.push(commentMap[comment.id]);
        }
      } else {
        rootComments.push(commentMap[comment.id]);
      }
    });

    return rootComments;
  };

  // Helper function to calculate total comment count including replies
  const calculateTotalCommentCount = (comments) => {
    return comments.reduce((total, comment) => {
      // Count the comment itself
      let count = 1;
      // If the comment has replies, recursively count them
      if (comment.replies && comment.replies.length > 0) {
        count += calculateTotalCommentCount(comment.replies);
      }
      return total + count;
    }, 0);
  };

  // Add this function near your other handler functions
  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        console.error("Post not found in any category:", postId);
        throw new Error("Post not found");
      }

      // Determine the base path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      let commentRef;
      let commentToUpdate;
      let isReply = false;

      // Fetch the comments for this post
      const postComments = comments[postId] || [];
      const comment = postComments.find(
        (c) => c.id === commentId || c.replies?.some((r) => r.id === commentId)
      );

      if (!comment) {
        throw new Error("Comment not found");
      }

      // Find the correct comment reference
      for (const comment of postComments) {
        if (comment.id === commentId) {
          commentRef = doc(
            firestore,
            `${basePath}/posts/${postId}/comments/${commentId}`
          );
          commentToUpdate = comment;
          break;
        }
        if (comment.replies) {
          const reply = comment.replies.find((r) => r.id === commentId);
          if (reply) {
            commentRef = doc(
              firestore,
              `${basePath}/posts/${postId}/comments/${commentId}`
            );
            commentToUpdate = reply;
            isReply = true;
            break;
          }
        }
      }

      if (!commentRef || !commentToUpdate) {
        throw new Error("Comment not found");
      }

      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      let updatedReactions = { ...(commentToUpdate.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        delete updatedReactions[reactorId];
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });

        const notificationRef = doc(
          firestore,
          `users/${
            commentToUpdate.isStartup
              ? commentToUpdate.startupOwnerId
              : commentToUpdate.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        // Add or change reaction
        updatedReactions[reactorId] = emoji;
        await updateDoc(commentRef, { reactions: updatedReactions });

        const shouldNotify =
          (selectedStartup &&
            !(
              commentToUpdate.userId === currentUser.uid ||
              (commentToUpdate.isStartup &&
                commentToUpdate.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentToUpdate.isStartup
              ? commentToUpdate.startupOwnerId !== currentUser.uid
              : commentToUpdate.userId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentToUpdate.isStartup
                ? commentToUpdate.startupOwnerId
                : commentToUpdate.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: postId,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUser.profileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentToUpdate.text,
            isReply: isReply,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentToUpdate.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment/reply reaction:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
      } else if (error.message === "Comment not found") {
        setErrorPopup({
          message: "This comment has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const updateCommentVisibility = async (postId, commenterId, commentData) => {
    const postRef = doc(
      firestore,
      `users/${commentData.originalPosterId}/posts/${postId}`
    );
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const postData = postDoc.data();
      const currentUserConnectionType = await checkConnectionType(
        currentUser.uid,
        postData.userId
      );
      const commenterConnectionType = await checkConnectionType(
        currentUser.uid,
        commenterId
      );

      if (
        (currentUserConnectionType === "Indirect" ||
          currentUserConnectionType === "Extended") &&
        commenterConnectionType === "Direct"
      ) {
        await updateDoc(postRef, {
          visibleCommentedBy: {
            id: commenterId,
            firstName: commentData.firstName,
            lastName: commentData.lastName,
            commentedAt: commentData.commentedAt,
            commentText: commentData.commentText,
          },
          originalDirectCommentTime:
            postData.originalDirectCommentTime || commentData.commentedAt,
        });
      }
    }
  };

  // Helper function to update the comments state
  const updateCommentsState = (comments, commentId, updatedReactions) => {
    return comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, reactions: updatedReactions };
      }
      if (comment.replies) {
        const updatedReplies = comment.replies.map((reply) =>
          reply.id === commentId
            ? { ...reply, reactions: updatedReactions }
            : reply
        );
        return { ...comment, replies: updatedReplies };
      }
      return comment;
    });
  };

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const fetchIndirectConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Indirect"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  // Helper function to fetch extended connections
  const fetchExtendedConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Extended"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  const fetchAllConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(connectionsRef);

    const connections = {
      direct: [],
      indirect: [],
      extended: [],
    };

    for (const doc of connectionsSnapshot.docs) {
      const connectionData = doc.data();
      if (connectionData.status === "Connected") {
        connections.direct.push(doc.id);
      } else if (connectionData.status === "Indirect") {
        connections.indirect.push(doc.id);
      } else if (connectionData.status === "Extended") {
        connections.extended.push(doc.id);
      }
    }

    return connections;
  };

  const fetchIndirectAndExtendedConnections = async (userId) => {
    console.log("Finding indirect and extended connections for user:", userId);

    // First get direct connections
    const directConnections = await fetchDirectConnections(userId);
    console.log("Direct connections:", directConnections);

    // Get all connections of direct connections to find indirect connections
    const indirectConnections = new Set();
    const extendedConnections = new Set();
    const processedUsers = new Set([userId, ...directConnections]);

    // Get all users
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    const allUsers = usersSnapshot.docs.map((doc) => doc.id);

    // First pass - find indirect connections
    for (const directConnectionId of directConnections) {
      const connectionRef = collection(
        firestore,
        `users/${directConnectionId}/connections`
      );
      const connectionsSnapshot = await getDocs(
        query(connectionRef, where("status", "==", "Connected"))
      );

      for (const doc of connectionsSnapshot.docs) {
        const potentialIndirect = doc.id;
        if (!processedUsers.has(potentialIndirect)) {
          indirectConnections.add(potentialIndirect);
          processedUsers.add(potentialIndirect);
        }
      }
    }

    // Second pass - remaining users are extended connections
    for (const userId of allUsers) {
      if (!processedUsers.has(userId)) {
        extendedConnections.add(userId);
      }
    }

    console.log("Found connections:", {
      indirect: Array.from(indirectConnections),
      extended: Array.from(extendedConnections),
    });

    return {
      indirectConnections: Array.from(indirectConnections),
      extendedConnections: Array.from(extendedConnections),
    };
  };

  const fetchAllUsers = async () => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    return usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const checkIfConnected = async (userId1, userId2) => {
    const connectionRef = doc(
      firestore,
      `users/${userId1}/connections/${userId2}`
    );
    const connectionDoc = await getDoc(connectionRef);
    return (
      connectionDoc.exists() && connectionDoc.data().status === "Connected"
    );
  };

  const fetchAndSubscribeToPosts = async (loadMore = false) => {
    if (currentUser && !loading) {
      setLoading(true);
      try {
        console.log("Starting fetch posts process for user:", currentUser.uid);
        let allPosts = [];

        const directConnections = await fetchDirectConnections(currentUser.uid);
        const { indirectConnections, extendedConnections } =
          await fetchIndirectAndExtendedConnections(currentUser.uid);

        console.log("Connection counts:", {
          direct: directConnections.length,
          indirect: indirectConnections.length,
          extended: extendedConnections.length,
        });

        const userPosts = await fetchUserPosts(currentUser.uid);
        allPosts = [...allPosts, ...userPosts];

        const userStartupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const userStartupsSnapshot = await getDocs(userStartupsRef);
        for (const startupDoc of userStartupsSnapshot.docs) {
          const startupPosts = await fetchUserPosts(
            currentUser.uid,
            startupDoc.id
          );
          allPosts = [...allPosts, ...startupPosts];
        }

        for (const connectionId of directConnections) {
          const connectionPosts = await fetchUserPosts(connectionId);
          const reposts = await fetchReposts(connectionId);
          const commentedPosts = await fetchCommentedPosts(connectionId);
          const reactedPosts = await fetchReactedPosts(connectionId);
          allPosts = [
            ...allPosts,
            ...connectionPosts,
            ...reposts,
            ...commentedPosts,
            ...reactedPosts,
          ];
        }

        const followedStartupsSnapshot = await getDocs(
          collection(firestore, `users/${currentUser.uid}/startupsFollowing`)
        );
        console.log("Fetching followed startup posts...");

        for (const startupDoc of followedStartupsSnapshot.docs) {
          const startupId = startupDoc.id;
          const ownerId = startupDoc.data().ownerId;
          console.log("Fetching posts for startup:", { startupId, ownerId });

          const startupPosts = await fetchUserPosts(ownerId, startupId);
          const startupReposts = await fetchStartupReposts(ownerId, startupId);
          const startupCommentedPosts = await fetchStartupCommentedPosts(
            ownerId,
            startupId
          );
          const startupReactedPosts = await fetchStartupReactedPosts(
            ownerId,
            startupId
          );

          console.log("Startup interactions:", {
            posts: startupPosts.length,
            reposts: startupReposts.length,
            comments: startupCommentedPosts.length,
            reactions: startupReactedPosts.length,
          });

          allPosts = [
            ...allPosts,
            ...startupPosts,
            ...startupReposts,
            ...startupCommentedPosts,
            ...startupReactedPosts,
          ];
        }

        const directConnectionsFollowedStartups =
          await fetchDirectConnectionsFollowedStartups(directConnections);
        for (const startup of directConnectionsFollowedStartups) {
          const startupPosts = await fetchUserPosts(
            startup.ownerId,
            startup.startupId
          );
          const startupReposts = await fetchStartupReposts(
            startup.ownerId,
            startup.startupId
          );
          const startupCommentedPosts = await fetchStartupCommentedPosts(
            startup.ownerId,
            startup.startupId
          );
          const startupReactedPosts = await fetchStartupReactedPosts(
            startup.ownerId,
            startup.startupId
          );

          allPosts = [
            ...allPosts,
            ...startupPosts,
            ...startupReposts,
            ...startupCommentedPosts,
            ...startupReactedPosts,
          ];
        }

        const allStartups = await fetchAllStartups();
        for (const startup of allStartups) {
          const isFollowed =
            [...directConnectionsFollowedStartups].some(
              (s) => s.startupId === startup.startupId
            ) ||
            followedStartupsSnapshot.docs.some(
              (doc) => doc.id === startup.startupId
            );
          const isOwned = startup.ownerId === currentUser.uid;

          if (!isFollowed && !isOwned) {
            const startupPosts = await fetchUserPosts(
              startup.ownerId,
              startup.startupId
            );
            allPosts = [...allPosts, ...startupPosts];
          }
        }

        for (const connectionId of indirectConnections) {
          const posts = await fetchUserPosts(connectionId);
          console.log(
            `Fetched ${posts.length} posts from indirect connection:`,
            connectionId
          );
          allPosts = [...allPosts, ...posts];
        }

        for (const connectionId of extendedConnections) {
          const posts = await fetchUserPosts(connectionId);
          console.log(
            `Fetched ${posts.length} posts from extended connection:`,
            connectionId
          );
          allPosts = [...allPosts, ...posts];
        }

        allPosts = Array.from(
          new Set(allPosts.filter(Boolean).map((post) => post.id))
        )
          .map((id) => allPosts.find((post) => post && post.id === id))
          .filter(Boolean);

        console.log("Total unprocessed posts:", allPosts.length);

        const processedPosts = await processPosts(allPosts, {
          directConnections,
          indirectConnections,
          extendedConnections,
        });

        console.log("Processed posts:", {
          directAndInteracted: processedPosts.directAndInteracted.length,
          indirectSuggested: processedPosts.indirectSuggested.length,
          extendedSuggested: processedPosts.extendedSuggested.length,
        });

        setPosts(processedPosts);
        setHasAttemptedLoad(true); // Add this line here
        if (allPosts.length === 0 || allPosts.length < 20) {
          setHasMore(false);
        }

        await cachePosts(allPosts);
      } catch (error) {
        console.error("Error in fetchAndSubscribeToPosts:", error);
        setHasMore(false);
        setHasAttemptedLoad(true); // And here to handle errors
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchFollowedStartupPosts = async (userId) => {
    console.log("Fetching followed startup posts for user:", userId);
    try {
      let followedStartupPosts = [];

      // Get all startups the user follows (now just getting all docs in startupsFollowing)
      const followingRef = collection(
        firestore,
        `users/${userId}/startupsFollowing`
      );
      const followingSnapshot = await getDocs(followingRef);

      // For each followed startup
      for (const followDoc of followingSnapshot.docs) {
        const startupId = followDoc.id;
        const { ownerId } = followDoc.data();

        console.log("Found followed startup:", startupId, "owned by:", ownerId);

        // Get the startup's posts
        const startupPostsRef = collection(
          firestore,
          `users/${ownerId}/startups/${startupId}/posts`
        );
        const postsSnapshot = await getDocs(
          query(startupPostsRef, orderBy("createdAt", "desc"), limit(20))
        );

        console.log("Found posts for startup:", postsSnapshot.docs.length);

        // Get the startup details
        const startupRef = doc(
          firestore,
          `users/${ownerId}/startups/${startupId}`
        );
        const startupDoc = await getDoc(startupRef);
        const startupData = startupDoc.data();

        if (!startupDoc.exists()) {
          console.log("Startup document not found:", startupId);
          continue;
        }

        // Process each post
        const posts = await Promise.all(
          postsSnapshot.docs.map(async (postDoc) => {
            const postData = postDoc.data();
            return createPostObject(ownerId, postDoc.id, {
              ...postData,
              user: {
                id: `startup_${startupId}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                connectionType: "Direct", // Treat followed startups as direct
                isStartup: true,
                startupId: startupId,
                startupOwnerId: ownerId,
              },
            });
          })
        );

        followedStartupPosts = [...followedStartupPosts, ...posts];
      }

      console.log("Found followed startup posts:", followedStartupPosts.length);
      return followedStartupPosts;
    } catch (error) {
      console.error("Error fetching followed startup posts:", error);
      return [];
    }
  };

  const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  const fetchStartupReposts = async (ownerId, startupId) => {
    const repostsRef = collection(
      firestore,
      `users/${ownerId}/startups/${startupId}/reposts`
    );
    const repostsSnapshot = await getDocs(repostsRef);
    const reposts = [];

    console.log("Fetching reposts for startup:", startupId, {
      repostsSnapshot: repostsSnapshot.docs.map((d) => ({
        id: d.id,
        originalPosterId: d.data().originalPosterId,
        repostedAt: d.data().repostedAt,
        allData: d.data(),
      })),
      ownerID: ownerId,
    });

    for (const docSnapshot of repostsSnapshot.docs) {
      try {
        const repostData = docSnapshot.data();
        if (!repostData.originalPosterId) continue;

        let startupOwnerId;
        if (repostData.originalPosterId.startsWith("startup_")) {
          const targetStartupId = repostData.originalPosterId.replace(
            "startup_",
            ""
          );
          const usersSnapshot = await getDocs(collection(firestore, "users"));
          for (const userDoc of usersSnapshot.docs) {
            const startupDoc = await getDoc(
              doc(firestore, `users/${userDoc.id}/startups/${targetStartupId}`)
            );
            if (startupDoc.exists()) {
              startupOwnerId = userDoc.id;
              break;
            }
          }
        }

        const originalPostPath = repostData.originalPosterId.startsWith(
          "startup_"
        )
          ? `users/${startupOwnerId}/startups/${repostData.originalPosterId.replace(
              "startup_",
              ""
            )}/posts/${docSnapshot.id}`
          : `users/${repostData.originalPosterId}/posts/${docSnapshot.id}`;

        const originalPostRef = doc(firestore, originalPostPath);
        const originalPostSnap = await getDoc(originalPostRef);

        console.log("Processing repost:", {
          originalPosterId: repostData.originalPosterId,
          isStartupPost: repostData.originalPosterId.startsWith("startup_"),
          originalPostPath,
          exists: originalPostSnap.exists(),
          postData: originalPostSnap.exists() ? originalPostSnap.data() : null,
        });

        if (originalPostSnap.exists()) {
          const postData = originalPostSnap.data();

          let userData;
          if (repostData.originalPosterId.startsWith("startup_")) {
            const targetStartupId = repostData.originalPosterId.replace(
              "startup_",
              ""
            );
            const startupDoc = await getDoc(
              doc(
                firestore,
                `users/${startupOwnerId}/startups/${targetStartupId}`
              )
            );
            if (startupDoc.exists()) {
              userData = startupDoc.data();
            }
          } else {
            userData = await getUserData(repostData.originalPosterId);
          }

          const post = {
            ...postData,
            id: docSnapshot.id,
            user: {
              id: repostData.originalPosterId,
              firstName: repostData.originalPosterId.startsWith("startup_")
                ? userData.startupName
                : userData.firstName,
              lastName: repostData.originalPosterId.startsWith("startup_")
                ? ""
                : userData.lastName,
              profileImage: repostData.originalPosterId.startsWith("startup_")
                ? userData.startupImage
                : userData.profileImage,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              connectionType: repostData.originalPosterId.startsWith("startup_")
                ? "Startup"
                : "Extended",
              ...(repostData.originalPosterId.startsWith("startup_") && {
                isStartup: true,
                startupId: repostData.originalPosterId.replace("startup_", ""),
                startupOwnerId: startupOwnerId,
              }),
            },
            createdAt: postData.createdAt,
            reposts: postData.reposts || {},
            reactions: postData.reactions || {},
            comments: postData.comments || {},
            commentCount: postData.commentCount || 0,
          };

          reposts.push(post);
        }
      } catch (error) {
        console.error("Error processing repost:", error);
        continue;
      }
    }

    return reposts;
  };

  const fetchStartupCommentedPosts = async (ownerId, startupId) => {
    const commentedPostsRef = collection(
      firestore,
      `users/${ownerId}/startups/${startupId}/commentedPosts`
    );
    const commentedPostsSnapshot = await getDocs(commentedPostsRef);
    const commentedPosts = [];

    console.log("Fetching commented posts for startup:", startupId, {
      commentedPostsSnapshot: commentedPostsSnapshot.docs.map((d) => ({
        id: d.id,
        originalPosterId: d.data().originalPosterId,
        lastCommentedAt: d.data().lastCommentedAt,
        allData: d.data(),
      })),
      ownerID: ownerId,
    });

    for (const docSnapshot of commentedPostsSnapshot.docs) {
      try {
        const commentedData = docSnapshot.data();
        if (!commentedData.originalPosterId) continue;

        let startupOwnerId;
        if (commentedData.originalPosterId.startsWith("startup_")) {
          const targetStartupId = commentedData.originalPosterId.replace(
            "startup_",
            ""
          );
          const usersSnapshot = await getDocs(collection(firestore, "users"));
          for (const userDoc of usersSnapshot.docs) {
            const startupDoc = await getDoc(
              doc(firestore, `users/${userDoc.id}/startups/${targetStartupId}`)
            );
            if (startupDoc.exists()) {
              startupOwnerId = userDoc.id;
              break;
            }
          }
        }

        const originalPostPath = commentedData.originalPosterId.startsWith(
          "startup_"
        )
          ? `users/${startupOwnerId}/startups/${commentedData.originalPosterId.replace(
              "startup_",
              ""
            )}/posts/${docSnapshot.id}`
          : `users/${commentedData.originalPosterId}/posts/${docSnapshot.id}`;

        const originalPostRef = doc(firestore, originalPostPath);
        const originalPostSnap = await getDoc(originalPostRef);

        console.log("Processing commented post:", {
          originalPosterId: commentedData.originalPosterId,
          isStartupPost: commentedData.originalPosterId.startsWith("startup_"),
          originalPostPath,
          exists: originalPostSnap.exists(),
          postData: originalPostSnap.exists() ? originalPostSnap.data() : null,
        });

        if (originalPostSnap.exists()) {
          const postData = originalPostSnap.data();

          let userData;
          if (commentedData.originalPosterId.startsWith("startup_")) {
            const targetStartupId = commentedData.originalPosterId.replace(
              "startup_",
              ""
            );
            const startupDoc = await getDoc(
              doc(
                firestore,
                `users/${startupOwnerId}/startups/${targetStartupId}`
              )
            );
            if (startupDoc.exists()) {
              userData = startupDoc.data();
            }
          } else {
            userData = await getUserData(commentedData.originalPosterId);
          }

          const post = {
            ...postData,
            id: docSnapshot.id,
            user: {
              id: commentedData.originalPosterId,
              firstName: commentedData.originalPosterId.startsWith("startup_")
                ? userData.startupName
                : userData.firstName,
              lastName: commentedData.originalPosterId.startsWith("startup_")
                ? ""
                : userData.lastName,
              profileImage: commentedData.originalPosterId.startsWith(
                "startup_"
              )
                ? userData.startupImage
                : userData.profileImage,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              connectionType: commentedData.originalPosterId.startsWith(
                "startup_"
              )
                ? "Startup"
                : "Extended",
              ...(commentedData.originalPosterId.startsWith("startup_") && {
                isStartup: true,
                startupId: commentedData.originalPosterId.replace(
                  "startup_",
                  ""
                ),
                startupOwnerId: startupOwnerId,
              }),
            },
            createdAt: postData.createdAt,
            reposts: postData.reposts || {},
            reactions: postData.reactions || {},
            comments: postData.comments || {},
            commentCount: postData.commentCount || 0,
          };

          commentedPosts.push(post);
        }
      } catch (error) {
        console.error("Error processing commented post:", error);
        continue;
      }
    }

    return commentedPosts;
  };

  const fetchStartupReactedPosts = async (ownerId, startupId) => {
    const reactedPostsRef = collection(
      firestore,
      `users/${ownerId}/startups/${startupId}/reactedPosts`
    );
    const reactedPostsSnapshot = await getDocs(reactedPostsRef);
    const reactedPosts = [];

    console.log("Fetching reacted posts for startup:", startupId, {
      reactedPostsSnapshot: reactedPostsSnapshot.docs.map((d) => ({
        id: d.id,
        originalPosterId: d.data().originalPosterId,
        reactedAt: d.data().reactedAt,
        allData: d.data(),
      })),
      ownerID: ownerId,
    });

    for (const docSnapshot of reactedPostsSnapshot.docs) {
      try {
        const reactedData = docSnapshot.data();
        if (!reactedData.originalPosterId) continue;

        let startupOwnerId;
        if (reactedData.originalPosterId.startsWith("startup_")) {
          const targetStartupId = reactedData.originalPosterId.replace(
            "startup_",
            ""
          );
          const usersSnapshot = await getDocs(collection(firestore, "users"));
          for (const userDoc of usersSnapshot.docs) {
            const startupDoc = await getDoc(
              doc(firestore, `users/${userDoc.id}/startups/${targetStartupId}`)
            );
            if (startupDoc.exists()) {
              startupOwnerId = userDoc.id;
              break;
            }
          }
        }

        const originalPostPath = reactedData.originalPosterId.startsWith(
          "startup_"
        )
          ? `users/${startupOwnerId}/startups/${reactedData.originalPosterId.replace(
              "startup_",
              ""
            )}/posts/${docSnapshot.id}`
          : `users/${reactedData.originalPosterId}/posts/${docSnapshot.id}`;

        const originalPostRef = doc(firestore, originalPostPath);
        const originalPostSnap = await getDoc(originalPostRef);

        console.log("Processing reacted post:", {
          originalPosterId: reactedData.originalPosterId,
          isStartupPost: reactedData.originalPosterId.startsWith("startup_"),
          originalPostPath,
          exists: originalPostSnap.exists(),
          postData: originalPostSnap.exists() ? originalPostSnap.data() : null,
        });

        if (originalPostSnap.exists()) {
          const postData = originalPostSnap.data();

          let userData;
          if (reactedData.originalPosterId.startsWith("startup_")) {
            const targetStartupId = reactedData.originalPosterId.replace(
              "startup_",
              ""
            );
            const startupDoc = await getDoc(
              doc(
                firestore,
                `users/${startupOwnerId}/startups/${targetStartupId}`
              )
            );
            if (startupDoc.exists()) {
              userData = startupDoc.data();
            }
          } else {
            userData = await getUserData(reactedData.originalPosterId);
          }

          const post = {
            ...postData,
            id: docSnapshot.id,
            user: {
              id: reactedData.originalPosterId,
              firstName: reactedData.originalPosterId.startsWith("startup_")
                ? userData.startupName
                : userData.firstName,
              lastName: reactedData.originalPosterId.startsWith("startup_")
                ? ""
                : userData.lastName,
              profileImage: reactedData.originalPosterId.startsWith("startup_")
                ? userData.startupImage
                : userData.profileImage,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              connectionType: reactedData.originalPosterId.startsWith(
                "startup_"
              )
                ? "Startup"
                : "Extended",
              ...(reactedData.originalPosterId.startsWith("startup_") && {
                isStartup: true,
                startupId: reactedData.originalPosterId.replace("startup_", ""),
                startupOwnerId: startupOwnerId,
              }),
            },
            createdAt: postData.createdAt,
            reposts: postData.reposts || {},
            reactions: postData.reactions || {},
            comments: postData.comments || {},
            commentCount: postData.commentCount || 0,
          };

          reactedPosts.push(post);
        }
      } catch (error) {
        console.error("Error processing reacted post:", error);
        continue;
      }
    }

    return reactedPosts;
  };

  const debouncedFetchAndSubscribeToPosts = debounce(
    fetchAndSubscribeToPosts,
    300
  );

  const dbPromise = openDB("postsDB", 1, {
    upgrade(db) {
      db.createObjectStore("posts");
    },
  });

  async function cachePosts(posts) {
    const db = await dbPromise;
    const tx = db.transaction("posts", "readwrite");
    posts.forEach((post) => tx.store.put(post, post.id));
    await tx.done;
  }

  async function getCachedPosts() {
    const db = await dbPromise;
    return db.getAll("posts");
  }

  useEffect(() => {
    console.log("Posts in useEffect:", posts);
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        console.log("Checking post:", post);
        if (
          post &&
          typeof post === "object" &&
          "id" in post &&
          !post.isRemovedMessage &&
          !comments[post.id]
        ) {
          console.log("Fetching comments for post:", post.id);
          fetchComments(post.id, post.user?.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, comments]);

  const itemCount = hasMore
    ? validSortedPosts.length + 1
    : validSortedPosts.length;

  const isItemLoaded = (index) => !hasMore || index < validSortedPosts.length;

  const loadMoreItems = loading ? () => {} : loadMorePosts;

  const renderComments = (postId, postOwnerId) => {
    const postComments = comments[postId] || [];

    // Ensure postComments is an array
    const commentsArray = Array.isArray(postComments)
      ? postComments
      : Object.values(postComments);

    const rootComments = commentsArray.filter(
      (comment) => comment && comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = commentsArray.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        •{" "}
                        {comment.connectionType ||
                          (comment.userId === currentUser?.uid
                            ? "You"
                            : "Unknown")}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {(comment.userId === currentUser.uid ||
                        (comment.isStartup &&
                          comment.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(postId, comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== postOwnerId &&
                        comment.userId !== currentUser.uid &&
                        (!comment.isStartup ||
                          comment.startupOwnerId !== currentUser.uid) && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}

                      {(currentUser.uid === postOwnerId ||
                        comment.userId === currentUser.uid ||
                        (comment.isStartup &&
                          comment.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === `comment_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  } // NEW
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {renderReplies(postId, comment.id, postOwnerId)}

              {activeCommentId === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments[postId]?.[comment.id] || ""}
                  onReplyChange={(e) =>
                    handleReplyInputChange(e, postId, comment.id)
                  }
                  onSubmit={() => handleAddComment(postId, comment.id)}
                  replyInputRef={(el) =>
                    (replyInputRef.current[postId] = {
                      ...replyInputRef.current[postId],
                      [comment.id]: el,
                    })
                  }
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  // Add this function to fetch startups followed by direct connections
  const fetchDirectConnectionsFollowedStartups = async (directConnections) => {
    const followedStartups = new Set();

    for (const connectionId of directConnections) {
      const followingRef = collection(
        firestore,
        `users/${connectionId}/startupsFollowing`
      );
      const followingSnapshot = await getDocs(
        query(followingRef, where("status", "==", "following"))
      );

      followingSnapshot.docs.forEach((doc) => {
        followedStartups.add({
          startupId: doc.id,
          ownerId: doc.data().ownerId,
          followedBy: connectionId,
        });
      });
    }

    return followedStartups;
  };

  // Add this function to fetch all startups in the system
  const fetchAllStartups = async () => {
    const startups = new Set();
    const usersSnapshot = await getDocs(collection(firestore, "users"));

    for (const userDoc of usersSnapshot.docs) {
      const startupsRef = collection(firestore, `users/${userDoc.id}/startups`);
      const startupsSnapshot = await getDocs(startupsRef);

      startupsSnapshot.docs.forEach((doc) => {
        startups.add({
          startupId: doc.id,
          ownerId: userDoc.id,
          startupData: doc.data(),
        });
      });
    }

    return startups;
  };

  const processPosts = async (allPosts, connections) => {
    const directAndInteracted = [];
    const indirectSuggested = [];
    const extendedSuggested = [];
    const processedIds = new Set();

    const followedStartupsSnapshot = await getDocs(
      collection(firestore, `users/${currentUser.uid}/startupsFollowing`)
    );
    const followedStartupIds = followedStartupsSnapshot.docs.map((doc) => ({
      id: `startup_${doc.id}`,
      ownerId: doc.data().ownerId,
    }));

    const userStartupsSnapshot = await getDocs(
      collection(firestore, `users/${currentUser.uid}/startups`)
    );
    const userStartupIds = userStartupsSnapshot.docs.map(
      (doc) => `startup_${doc.id}`
    );

    const directConnectionsStartups = new Set();

    // Track both followed and owned startups for direct connections
    const directConnectionStartupInfo = new Map();

    for (const connectionId of connections.directConnections) {
      // Get startups followed by direct connection
      const connectionFollowingRef = collection(
        firestore,
        `users/${connectionId}/startupsFollowing`
      );
      const connectionFollowingSnapshot = await getDocs(connectionFollowingRef);

      connectionFollowingSnapshot.docs.forEach((doc) => {
        directConnectionsStartups.add({
          startupId: doc.id,
          ownerId: doc.data().ownerId,
          followedBy: connectionId,
          relationship: "followed",
        });
        directConnectionStartupInfo.set(doc.id, {
          ownerId: doc.data().ownerId,
          relationship: "followed",
          connectionId,
        });
      });

      // Get startups owned by direct connection
      const connectionStartupsRef = collection(
        firestore,
        `users/${connectionId}/startups`
      );
      const connectionStartupsSnapshot = await getDocs(connectionStartupsRef);

      connectionStartupsSnapshot.docs.forEach((doc) => {
        directConnectionsStartups.add({
          startupId: doc.id,
          ownerId: connectionId,
          relationship: "owned",
        });
        directConnectionStartupInfo.set(doc.id, {
          ownerId: connectionId,
          relationship: "owned",
          connectionId,
        });
      });
    }

    for (const post of allPosts) {
      if (!post || !post.user || !post.user.id) {
        console.log("Skipping invalid post:", post);
        continue;
      }

      if (processedIds.has(post.id)) {
        console.log("Skipping duplicate post:", post.id);
        continue;
      }

      const isOwnStartupPost =
        post.user?.isStartup && post.user?.startupOwnerId === currentUser.uid;
      const isFollowedStartup =
        post.user?.isStartup &&
        followedStartupIds.some(
          (s) => s.id === `startup_${post.user.startupId}`
        );

      // Check if startup is either followed by or owned by a direct connection
      const isStartupFollowedByDirectConnection =
        post.user?.isStartup &&
        Array.from(directConnectionsStartups).some(
          (s) => s.startupId === post.user.startupId
        );
      const isStartupOwnedByDirectConnection =
        post.user?.isStartup &&
        connections.directConnections.includes(post.user.startupOwnerId);

      let hasFollowedStartupInteraction = false;
      let hasDirectInteraction = false;
      let hasOwnInteraction = false;
      let visibleInteraction = null;

      if (
        post.reactions?.[currentUser.uid] ||
        post.reposts?.[currentUser.uid] ||
        post.comments?.[currentUser.uid] ||
        userStartupIds.some(
          (startupId) =>
            post.reactions?.[startupId] ||
            post.reposts?.[startupId] ||
            post.comments?.[startupId]
        )
      ) {
        hasOwnInteraction = true;
      }

      for (const startup of followedStartupIds) {
        if (post.reposts?.[startup.id]) {
          hasFollowedStartupInteraction = true;
          const startupData = await getStartupData(
            startup.id.replace("startup_", ""),
            startup.ownerId
          );
          visibleInteraction = {
            type: "repost",
            user: {
              id: startup.id,
              firstName: startupData.startupName,
              lastName: "",
              profileImage: startupData.startupImage || profileImage,
              isStartup: true,
              startupId: startup.id.replace("startup_", ""),
              ownerId: startup.ownerId,
            },
          };
          break;
        }

        if (!visibleInteraction && post.comments?.[startup.id]) {
          hasFollowedStartupInteraction = true;
          const startupData = await getStartupData(
            startup.id.replace("startup_", ""),
            startup.ownerId
          );
          visibleInteraction = {
            type: "comment",
            user: {
              id: startup.id,
              firstName: startupData.startupName,
              lastName: "",
              profileImage: startupData.startupImage || profileImage,
              isStartup: true,
              startupId: startup.id.replace("startup_", ""),
              ownerId: startup.ownerId,
            },
          };
          break;
        }

        if (!visibleInteraction && post.reactions?.[startup.id]) {
          hasFollowedStartupInteraction = true;
          const startupData = await getStartupData(
            startup.id.replace("startup_", ""),
            startup.ownerId
          );
          visibleInteraction = {
            type: "reaction",
            user: {
              id: startup.id,
              firstName: startupData.startupName,
              lastName: "",
              profileImage: startupData.startupImage || profileImage,
              isStartup: true,
              startupId: startup.id.replace("startup_", ""),
              ownerId: startup.ownerId,
            },
            emoji: post.reactions[startup.id],
          };
          break;
        }
      }

      if (!visibleInteraction) {
        for (const userId of Object.keys(post.reposts || {})) {
          if (
            connections.directConnections.includes(userId) &&
            userId !== currentUser.uid
          ) {
            const userData = await getUserData(userId);
            visibleInteraction = {
              type: "repost",
              user: {
                id: userId,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage || profileImage,
              },
            };
            hasDirectInteraction = true;
            break;
          }
        }

        if (!visibleInteraction) {
          for (const userId of Object.keys(post.comments || {})) {
            if (
              connections.directConnections.includes(userId) &&
              userId !== currentUser.uid
            ) {
              const userData = await getUserData(userId);
              visibleInteraction = {
                type: "comment",
                user: {
                  id: userId,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  profileImage: userData.profileImage || profileImage,
                },
              };
              hasDirectInteraction = true;
              break;
            }
          }
        }

        if (!visibleInteraction) {
          for (const userId of Object.keys(post.reactions || {})) {
            if (
              connections.directConnections.includes(userId) &&
              userId !== currentUser.uid
            ) {
              const userData = await getUserData(userId);
              visibleInteraction = {
                type: "reaction",
                user: {
                  id: userId,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  profileImage: userData.profileImage || profileImage,
                },
                emoji: post.reactions[userId],
              };
              hasDirectInteraction = true;
              break;
            }
          }
        }
      }

      const isDirectConnection = connections.directConnections.includes(
        post.user.id
      );
      const isIndirectConnection = connections.indirectConnections.includes(
        post.user.id
      );
      const isExtendedConnection = connections.extendedConnections.includes(
        post.user.id
      );

      const processedPost = {
        ...post,
        visibleReactedBy:
          visibleInteraction?.type === "reaction"
            ? visibleInteraction.user
            : null,
        visibleRepostedBy:
          visibleInteraction?.type === "repost"
            ? visibleInteraction.user
            : null,
        visibleCommentedBy:
          visibleInteraction?.type === "comment"
            ? visibleInteraction.user
            : null,
      };

      if (
        post.user.id === currentUser.uid ||
        isOwnStartupPost ||
        isDirectConnection ||
        hasDirectInteraction ||
        isFollowedStartup ||
        hasFollowedStartupInteraction ||
        hasOwnInteraction
      ) {
        directAndInteracted.push(processedPost);
      } else if (
        isIndirectConnection ||
        (post.user?.isStartup &&
          (isStartupFollowedByDirectConnection ||
            isStartupOwnedByDirectConnection) &&
          !isOwnStartupPost &&
          !isFollowedStartup)
      ) {
        indirectSuggested.push(processedPost);
      } else if (
        isExtendedConnection ||
        (post.user?.isStartup &&
          !isStartupFollowedByDirectConnection &&
          !isStartupOwnedByDirectConnection &&
          !isOwnStartupPost &&
          !isFollowedStartup)
      ) {
        extendedSuggested.push(processedPost);
      }

      processedIds.add(post.id);
    }

    return {
      directAndInteracted: sortDirectPosts(directAndInteracted),
      indirectSuggested: sortByEngagement(indirectSuggested),
      extendedSuggested: sortByEngagement(extendedSuggested),
    };
  };

  const getStartupData = async (startupId, ownerId) => {
    const startupDoc = await getDoc(
      doc(firestore, `users/${ownerId}/startups/${startupId}`)
    );
    return startupDoc.data();
  };

  const sortPostsByTime = (posts) => {
    return posts.sort((a, b) => {
      const timeA = getValidTimestamp(a);
      const timeB = getValidTimestamp(b);
      return timeB - timeA;
    });
  };

  const sortByEngagement = (posts) => {
    return posts.sort((a, b) => {
      const engagementA = calculateEngagement(a);
      const engagementB = calculateEngagement(b);
      if (engagementB !== engagementA) {
        return engagementB - engagementA;
      }
      // If engagement is equal, sort by timestamp
      return getValidTimestamp(b) - getValidTimestamp(a);
    });
  };

  const calculateEngagement = (post) => {
    const reactionCount = Object.keys(post.reactions || {}).length;
    const commentCount = post.commentCount || 0;
    const repostCount = Object.keys(post.reposts || {}).length;
    return reactionCount + commentCount + repostCount;
  };

  const handleAddComment = async (postId, parentCommentId = null) => {
    if (!currentUser) return;

    const post = findPostById(postId);
    if (!post) {
      console.error("Post not found in any category:", postId);
      setErrorPopup({
        message: "This post has been deleted or is no longer available.",
        isError: true,
      });
      return;
    }

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[postId]?.[parentCommentId] || "";
    } else {
      commentText = newComment[postId] || "";
    }

    if (!commentText.trim()) return;

    try {
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      if (!userDoc.exists()) {
        throw new Error("User data not found");
      }
      const userData = userDoc.data();

      const processedCommentText = convertUrlsToLinks(commentText);
      const commenterInfo = getCommenterInfo(currentUser, selectedStartup);

      let completeCommenterData = {};
      if (commenterInfo.isStartup) {
        const startupDoc = await getDoc(
          doc(
            firestore,
            `users/${currentUser.uid}/startups/${selectedStartup.id}`
          )
        );
        if (!startupDoc.exists()) {
          throw new Error("Startup data not found");
        }
        const startupData = startupDoc.data();
        completeCommenterData = {
          userName: startupData.startupName,
          userImage: startupData.startupImage,
          connectionType: "Startup",
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
        };
      } else {
        completeCommenterData = {
          userName: `${userData.firstName} ${userData.lastName}`,
          userImage: userData.profileImage || profileImage,
          connectionType: "You",
          bio: userData.bio || "",
          link: userData.link || null,
          linkText: userData.linkText || null,
        };
      }

      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const postRef = doc(firestore, `${basePath}/posts/${postId}`);
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: new Date(),
        parentCommentId: parentCommentId || null,
        rootCommentId: parentCommentId
          ? await getRootCommentId(post.user.id, postId, parentCommentId)
          : null,
        isRootComment: !parentCommentId,
        bio: completeCommenterData.bio,
        link: completeCommenterData.link,
        linkText: completeCommenterData.linkText,
        connectionType: completeCommenterData.connectionType,
        postUserId: post.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commenterInfo.isStartup && selectedStartup) {
        commentData.startupId = selectedStartup.id;
        commentData.startupOwnerId = currentUser.uid;
      }

      const firestoreCommentData = {
        ...commentData,
        createdAt: serverTimestamp(),
      };

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${postId}/comments`
      );
      const newCommentRef = await addDoc(commentsRef, firestoreCommentData);

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${postId}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: serverTimestamp(),
        commentText: processedCommentText,
        originalPosterId: post.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: serverTimestamp(),
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: serverTimestamp(),
        });
      }

      const batch = writeBatch(firestore);

      const shouldNotifyPostOwner =
        !parentCommentId &&
        !(
          // Don't notify if commenter is the post owner (user)
          (
            (!commenterInfo.isStartup && currentUser.uid === post.user.id) ||
            // Don't notify if commenter is a startup owned by post owner
            (commenterInfo.isStartup &&
              commenterInfo.ownerId === post.user.id) ||
            // Don't notify if post is by a startup and commenter is the startup owner
            (!commenterInfo.isStartup &&
              post.user.isStartup &&
              currentUser.uid === post.user.startupOwnerId) ||
            // Don't notify if commenter is a startup and post is by a startup owned by the same user
            (commenterInfo.isStartup &&
              post.user.isStartup &&
              currentUser.uid === post.user.startupOwnerId)
          )
        );

      if (shouldNotifyPostOwner) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: postId,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.isStartup
            ? selectedStartup.startupImage
            : userData.profileImage || profileImage,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(postOwnerNotificationRef, notificationData);
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          !(
            (commenterInfo.isStartup &&
              parentCommentData.isStartup &&
              commenterInfo.startupId === parentCommentData.startupId) ||
            (commenterInfo.isStartup &&
              parentCommentData.userId === commenterInfo.ownerId) ||
            (!commenterInfo.isStartup &&
              parentCommentData.userId === currentUser.uid) ||
            (!commenterInfo.isStartup &&
              parentCommentData.isStartup &&
              parentCommentData.startupOwnerId === currentUser.uid)
          );

        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const replyNotificationData = {
            type: "reply",
            postId: postId,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.isStartup
              ? selectedStartup.startupImage
              : userData.profileImage || profileImage,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(replyNotificationRef, replyNotificationData);
        }
      }

      await batch.commit();

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId,
        postUserId: post.user.id,
        userName: completeCommenterData.userName,
        userImage: completeCommenterData.userImage,
        bio: completeCommenterData.bio,
        link: completeCommenterData.link,
        linkText: completeCommenterData.linkText,
        connectionType: completeCommenterData.connectionType,
      };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [...(prevComments[postId] || []), newCommentObject],
      }));

      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((p) =>
            p.id === postId
              ? {
                  ...p,
                  commentCount: (p.commentCount || 0) + 1,
                  comments: {
                    ...(p.comments || {}),
                    [commenterInfo.id]: serverTimestamp(),
                  },
                  originalDirectCommentTime: serverTimestamp(),
                }
              : p
          );

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
        if (commentInputRef.current[postId]) {
          commentInputRef.current[postId].style.height = "auto";
        }
      }
      setActiveCommentId((prev) => ({ ...prev, [postId]: null }));
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "User data not found"
            ? "Unable to fetch your user data. Please try again."
            : error.message === "Startup data not found"
            ? "Unable to fetch startup data. Please try again."
            : error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[postId]?.[replyId];
    return !replyText || !replyText.trim();
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderReplies = (postId, rootCommentId, postOwnerId) => {
    const postComments = comments[postId] || [];
    const replies = postComments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || defaultImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        •{" "}
                        {reply.connectionType ||
                          (reply.userId === currentUser.uid
                            ? "You"
                            : "Unknown")}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {(reply.userId === currentUser.uid ||
                        (reply.isStartup &&
                          reply.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.editCommentButton}
                          onClick={() =>
                            handleEditComment(postId, reply.id, true)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      )}

                      {currentUser.uid !== postOwnerId &&
                        reply.userId !== currentUser.uid &&
                        (!reply.isStartup ||
                          reply.startupOwnerId !== currentUser.uid) && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report
                          </button>
                        )}

                      {(currentUser.uid === postOwnerId ||
                        reply.userId === currentUser.uid ||
                        (reply.isStartup &&
                          reply.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(postId, reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleReplyReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}

              {activeEmojiPicker === `reply_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  } // NEW
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUser?.profileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser?.uid
                          )
                        }
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[postId]?.[reply.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, reply.id)
                      }
                      placeholder={`Reply as ${
                        selectedStartup
                          ? selectedStartup.startupName
                          : "yourself"
                      }...`}
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(postId, reply.id)}
                    className={`${classes.addReplyButton} ${
                      !isReplyButtonDisabled(postId, reply.id)
                        ? ""
                        : classes.disabled
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      // Construct the correct path for comments based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${postUserId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      // If this is a root comment or has no parent, return its ID
      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      // If it's a reply, find its root comment
      return getRootCommentId(postUserId, postId, commentData.parentCommentId);
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const toggleCommentInput = useCallback(
    (postId) => {
      setActiveCommentPostId((prevId) => (prevId === postId ? null : postId));
      if (!comments[postId]) {
        const post = findPostById(postId);
        if (post) {
          fetchComments(postId, post.user.id);
        }
      }
    },
    [comments, findPostById, fetchComments]
  );

  const handleCommentsCountClick = (postId) => {
    toggleCommentInput(postId);
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: 10, // Show initial 10 comments
    }));
  };

  const CancelPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const SuccessPostPopup = ({ message, onClose, postId }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          <Link to={`/post/${postId}`} className={classes.viewPostLink}>
            View post
          </Link>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleNavigate = async (userId) => {
    if (!userId) return;

    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Always navigate to urlSlug, even for current user
        navigate(`/${userData.urlSlug}`);
      } else {
        console.error("User not found");
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (activeEmojiPicker !== null) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${emojiPickerScrollPosition}px`;
    } else {
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, emojiPickerScrollPosition);
    }
  }, [activeEmojiPicker, emojiPickerScrollPosition]);

  const handleReactionClick = async (postId) => {
    try {
      const post = findPostById(postId);

      if (!post) {
        throw new Error("Post not found");
      }

      if (post.reactions && post.reactions[currentUser.uid]) {
        // User has already reacted, so remove the reaction
        try {
          const postRef = doc(
            firestore,
            "users",
            post.user.id,
            "posts",
            postId
          );
          await updateDoc(postRef, {
            [`reactions.${currentUser.uid}`]: deleteField(),
          });

          // Remove from reactedPosts
          const reactedPostRef = doc(
            firestore,
            "users",
            currentUser.uid,
            "reactedPosts",
            postId
          );
          await deleteDoc(reactedPostRef);

          // Remove notification
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );
          await deleteDoc(notificationRef);

          // Remove reaction
          const updatedReactions = { ...post.reactions };
          delete updatedReactions[currentUser.uid];

          setPosts((prevPosts) => {
            const updateCategory = (category) =>
              prevPosts[category].map((p) =>
                p.id === postId
                  ? {
                      ...p,
                      reactions: updatedReactions,
                      originalDirectReactionTime:
                        Object.keys(updatedReactions).length === 0
                          ? null
                          : p.originalDirectReactionTime,
                    }
                  : p
              );

            return {
              directAndInteracted: updateCategory("directAndInteracted"),
              indirectSuggested: updateCategory("indirectSuggested"),
              extendedSuggested: updateCategory("extendedSuggested"),
            };
          });
        } catch (error) {
          console.error("Error removing reaction:", error);
          throw error;
        }
      } else {
        // Toggle EmojiPicker with proper scroll position handling
        setEmojiPickerScrollPosition(window.scrollY);
        setActiveEmojiPicker((prev) =>
          prev === `post_${postId}` ? null : `post_${postId}`
        );
      }
    } catch (error) {
      console.error("Error in handleReactionClick:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const handleCommentReactionClick = (commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `comment_${commentId}` ? null : `comment_${commentId}`
    );
  };

  const handleReplyReactionClick = (replyId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `reply_${replyId}` ? null : `reply_${replyId}`
    );
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        setUserData(userDoc.data());

        const connectionsRef = collection(
          firestore,
          "users",
          userId,
          "connections"
        );
        const connectionsSnapshot = await getDocs(
          query(connectionsRef, where("status", "==", "Connected"))
        );
        setDirectConnectionIds(connectionsSnapshot.docs.map((doc) => doc.id));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleReportPost = (postId) => {
    // Implement report post functionality
    console.log(`Reporting post with ID: ${postId}`);
    // You might want to open a modal or make an API call here
    // For now, we'll just log to the console
  };

  const hasInteractions = (post) => {
    if (!post) return false;
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0) ||
      (comments[post.id] && comments[post.id].length > 0)
    );
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUser?.profileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={() =>
                handleNavigate(
                  selectedStartup
                    ? `startup/${selectedStartup.startupUrlSlug}`
                    : currentUser?.uid
                )
              }
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  // Update the ReplySection component
  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUser?.profileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={() =>
                handleNavigate(
                  selectedStartup
                    ? `startup/${selectedStartup.startupUrlSlug}`
                    : currentUser?.uid
                )
              }
            />
          </div>
          <textarea
            ref={replyInputRef}
            value={replyComments}
            onChange={onReplyChange}
            onTouchStart={handleTouchStart}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addReplyButton} ${
            !replyComments?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  const handleEmojiSelect = useCallback(
    async (emoji, postId) => {
      if (!currentUser) return;

      try {
        const post = findPostById(postId);
        if (!post) {
          throw new Error("Post not found");
        }

        const postRef = getPostRef(post); // Use helper function
        const reactorId = selectedStartup
          ? `startup_${selectedStartup.id}`
          : currentUser.uid;
        const path = selectedStartup
          ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
          : `users/${currentUser.uid}`;
        const reactedPostRef = doc(firestore, `${path}/reactedPosts/${postId}`);

        const postDoc = await getDoc(postRef);
        let updatedReactions = { ...(postDoc.data()?.reactions || {}) };

        if (updatedReactions[reactorId] === emoji) {
          // Remove reaction
          await updateDoc(postRef, {
            [`reactions.${reactorId}`]: deleteField(),
          });
          delete updatedReactions[reactorId];
          await deleteDoc(reactedPostRef);

          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `reaction_${postId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        } else {
          // Add or change reaction
          await updateDoc(postRef, {
            [`reactions.${reactorId}`]: emoji,
          });
          updatedReactions[reactorId] = emoji;

          await setDoc(reactedPostRef, {
            originalPosterId: post.user.id,
            postId: postId,
            reactedAt: serverTimestamp(),
            emoji: emoji,
          });

          if (post.user.id !== currentUser.uid) {
            const notificationRef = doc(
              firestore,
              "users",
              post.user.id,
              "notifications",
              `reaction_${postId}_${reactorId}`
            );

            const notificationData = {
              type: "reaction",
              postId: postId,
              reactorId: reactorId,
              reactorName: selectedStartup
                ? selectedStartup.startupName
                : `${currentUser.firstName} ${currentUser.lastName}`,
              reactorImage: selectedStartup
                ? selectedStartup.startupImage
                : currentUser.profileImage || profileImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              postPreview: {
                text: postDoc.data().text || "",
                mediaType: postDoc.data().content?.type || null,
                mediaUrl: postDoc.data().content?.url || null,
                fileName: postDoc.data().fileName || null,
              },
              ...(selectedStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage: selectedStartup.startupImage, // Explicitly include startup image
              }),
            };

            await setDoc(notificationRef, notificationData);
          }
        }

        // Update local state
        setPosts((prevPosts) => {
          const updateCategory = (category) =>
            prevPosts[category].map((p) =>
              p.id === postId ? { ...p, reactions: updatedReactions } : p
            );

          return {
            directAndInteracted: updateCategory("directAndInteracted"),
            indirectSuggested: updateCategory("indirectSuggested"),
            extendedSuggested: updateCategory("extendedSuggested"),
          };
        });

        setActiveEmojiPicker(null);
        setTimeout(() => {
          window.scrollTo(0, emojiPickerScrollPosition);
        }, 0);
      } catch (error) {
        console.error("Error updating reaction:", error);
        if (error.message === "Post not found") {
          setErrorPopup({
            message: "This post has been deleted or is no longer available.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: "An error occurred while updating the reaction.",
            isError: true,
          });
        }
      }
    },
    [currentUser, selectedStartup, posts]
  );

  useEffect(() => {
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        if (!comments[post.id]) {
          fetchComments(post.id, post.user.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, comments]);

  const sortPosts = (posts) => {
    return posts.sort((a, b) => {
      if (
        a.user.connectionType === "You" ||
        a.user.connectionType === "Direct"
      ) {
        return getValidTimestamp(b) - getValidTimestamp(a);
      } else {
        const aTime = seenPosts[a.id]?.firstSeenAt || getValidTimestamp(a);
        const bTime = seenPosts[b.id]?.firstSeenAt || getValidTimestamp(b);
        if (aTime === bTime) {
          return (
            (seenPosts[a.id]?.originalPosition || 0) -
            (seenPosts[b.id]?.originalPosition || 0)
          );
        }
        return bTime - aTime;
      }
    });
  };

  const debouncedSetPosts = useCallback(
    debounce((updateFunction) => {
      setPosts((prevPosts) => {
        const updatedPosts = updateFunction(prevPosts);
        console.log("Setting new posts:", updatedPosts);
        return Array.isArray(updatedPosts)
          ? updatedPosts.filter(
              (post) => post && typeof post === "object" && "id" in post
            )
          : prevPosts; // Return previous posts if update resulted in non-array
      });
    }, 100),
    []
  );

  const getPostSortTime = (post) => {
    if (!post || post.isRemovedMessage || !post.user) {
      console.log("Invalid post in getPostSortTime:", post);
      return 0;
    }
    const connectionType = post.user.connectionType;
    if (connectionType === "You" || connectionType === "Direct") {
      return getValidTimestamp(post);
    } else {
      const reactionTime = getValidTimestamp({
        createdAt: post.originalDirectReactionTime,
      });
      const repostTime = getValidTimestamp({
        createdAt: post.originalDirectRepostTime,
      });
      const commentTime = getValidTimestamp({
        createdAt: post.originalDirectCommentTime,
      });
      return Math.max(
        reactionTime,
        repostTime,
        commentTime,
        getValidTimestamp(post)
      );
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const videoRefs = useRef({});
  const [videoVolumes, setVideoVolumes] = useState({});

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  const toggleVolume = (postId) => {
    setVideoVolumes((prev) => {
      const newVolumes = { ...prev };
      newVolumes[postId] = !prev[postId];
      return newVolumes;
    });

    const video = videoRefs.current[postId];
    if (video) {
      video.muted = !video.muted;
    }
  };

  const toggleDropdown = (postId) => {
    setShowDropdown((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handleCloseReactionsModal = () => {
    setShowReactionsModal(false);
    setIsReactionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, reactionsModalScrollPosition);
    }, 0);
  };

  const handleCloseRepostsModal = () => {
    setShowRepostsModal(false);
    setIsRepostsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, repostsModalScrollPosition);
    }, 0);
  };

  const fetchNewPost = async (postId) => {
    try {
      let postDoc;
      if (selectedStartup) {
        // If posting as startup, fetch from startup's posts collection
        postDoc = await getDoc(
          doc(
            firestore,
            `users/${currentUser.uid}/startups/${selectedStartup.id}/posts/${postId}`
          )
        );
      } else {
        // Regular user post
        postDoc = await getDoc(
          doc(firestore, `users/${currentUser.uid}/posts/${postId}`)
        );
      }

      if (postDoc.exists()) {
        const postData = postDoc.data();
        const newPost = await createPostObject(currentUser.uid, postId, {
          ...postData,
          user: selectedStartup
            ? {
                id: `startup_${selectedStartup.id}`,
                firstName: selectedStartup.startupName,
                lastName: "",
                profileImage: selectedStartup.startupImage,
                bio: selectedStartup.bio || "",
                link: selectedStartup.link || null,
                linkText: selectedStartup.linkText || null,
                connectionType: "You",
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
              }
            : undefined,
        });

        setPosts((prevPosts) => {
          const updateCategory = (category) => [
            newPost,
            ...prevPosts[category],
          ];

          return {
            directAndInteracted: updateCategory("directAndInteracted"),
            indirectSuggested: prevPosts.indirectSuggested,
            extendedSuggested: prevPosts.extendedSuggested,
          };
        });

        setSuccessPostMessage({
          text: "Post successful.",
          postId: postId,
        });
      }
    } catch (error) {
      console.error("Error fetching new post:", error);
      setErrorPopup({
        message: "Error loading the new post.",
        isError: true,
      });
    }
  };

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClosePostModal = useCallback(
    (success, newPostId, isUploading, progress) => {
      setShowPostModal(false);
      setIsPostModalOpen(false);
      setSelectedFile(null);

      if (success && newPostId) {
        if (isUploading) {
          updateUploadProgress(newPostId, progress, "uploading");
        } else {
          removeUploadingPost(newPostId);
          fetchNewPost(newPostId);
        }
      }
    },
    [updateUploadProgress, removeUploadingPost, fetchNewPost]
  );

  const getPostRef = (post) => {
    if (post.user.isStartup) {
      return doc(
        firestore,
        `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
      );
    }
    return doc(firestore, `users/${post.user.id}/posts/${post.id}`);
  };

  const handleCancelUpload = useCallback(
    async (postId) => {
      console.log(`Cancelling upload for post ${postId}`);
      cancelUpload(postId);
      updateUploadProgress(postId, 0, "cancelling");

      try {
        // Delete from Firestore
        const postRef = doc(
          firestore,
          `users/${currentUser?.uid}/posts/${postId}`
        );
        await deleteDoc(postRef);

        // Delete from Storage
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${currentUser?.uid}/${postId}`
        );
        const items = await listAll(storageRef);
        await Promise.all(items.items.map((itemRef) => deleteObject(itemRef)));

        console.log(`Successfully cancelled and cleaned up post ${postId}`);

        // Set the cancel message
        setCancelMessage("Post cancelled.");
      } catch (error) {
        console.error(`Error cleaning up cancelled post ${postId}:`, error);
      } finally {
        removeUploadingPost(postId);
      }
    },
    [currentUser, cancelUpload, updateUploadProgress, removeUploadingPost]
  );

  const handlePostComplete = useCallback(
    (postId) => {
      removeUploadingPost(postId);
      fetchNewPost(postId);
    },
    [removeUploadingPost, fetchNewPost]
  );

  const UploadingPost = React.memo(
    ({ postId, progress, status, onCancelUpload }) => {
      const handleCancelUpload = useCallback(() => {
        onCancelUpload(postId);
      }, [onCancelUpload, postId]);

      const roundedProgress = Math.round(progress);

      if (status === "cancelling") {
        return null; // Don't render anything if cancelling
      }

      return (
        <div className={classes.uploadingPost}>
          <div className={classes.uploadingHeader}>
            <span>Posting...</span>
            <div onClick={handleCancelUpload} className={classes.cancelButton}>
              Cancel
            </div>
          </div>
          <div className={classes.barAndText}>
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFill}
                style={{ width: `${roundedProgress}%` }}
              ></div>
            </div>
            <div className={classes.progressText}>{roundedProgress}%</div>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.status === nextProps.status &&
        Math.floor(prevProps.progress) === Math.floor(nextProps.progress) &&
        prevProps.postId === nextProps.postId
      );
    }
  );

  UploadingPost.displayName = "UploadingPost";

  const onCancelUpload = useCallback(
    (postId) => {
      handleCancelUpload(postId);
    },
    [handleCancelUpload]
  );

  // Memoize the UploadingPost component
  const MemoizedUploadingPost = useMemo(() => React.memo(UploadingPost), []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (currentUser && !loading && hasMore) {
        setLoading(true);
        try {
          const fetchedPosts = await fetchAndSubscribeToPosts();
          if (isMounted) {
            setPosts(fetchedPosts);
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [currentUser, fetchAndSubscribeToPosts, loading, hasMore]);

  const setRowHeight = useCallback((index, size) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
  }, []);

  const PostActionPopup = ({ message, onClose, isDelete, isSuccess }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill={isSuccess ? "orangered" : "red"}
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d={
                isSuccess
                  ? "M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  : "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              }
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const handleDeletePost = async (postId) => {
    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        console.error("Post not found");
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Get the correct post reference based on whether it's a startup post
      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${postId}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        console.error("Post not found");
        setErrorPopup({
          message: "This post has already been deleted.",
          isError: true,
        });
        return;
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete =
        targetPost.user.id === currentUser.uid ||
        (targetPost.user.isStartup &&
          targetPost.user.startupOwnerId === currentUser.uid);

      if (!canDelete) {
        console.error("User not authorized to delete this post");
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      // Get the correct comments collection reference
      const commentsRef = collection(
        firestore,
        targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}/comments`
          : `users/${targetPost.user.id}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      const deletePromises = commentsSnapshot.docs.map(async (commentDoc) => {
        const commentData = commentDoc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteDoc(commentDoc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      });

      await Promise.all(deletePromises);

      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupReactedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reactedPosts/${postId}`
              );
              batch.delete(startupReactedPostRef);
              operationCount++;

              const reactionNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
              );
              batch.delete(reactionNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userReactedPostRef = doc(
              firestore,
              `users/${userId}/reactedPosts/${postId}`
            );
            batch.delete(userReactedPostRef);
            operationCount++;

            const reactionNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
            );
            batch.delete(reactionNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupRepostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reposts/${postId}`
              );
              batch.delete(startupRepostRef);
              operationCount++;

              const repostNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
              );
              batch.delete(repostNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userRepostRef = doc(
              firestore,
              `users/${userId}/reposts/${postId}`
            );
            batch.delete(userRepostRef);
            operationCount++;

            const repostNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
            );
            batch.delete(repostNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupCommentedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
              );
              batch.delete(startupCommentedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } else {
            const commentedPostRef = doc(
              firestore,
              `users/${userId}/commentedPosts/${postId}`
            );
            batch.delete(commentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          }
        }
      }

      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        const notificationsQuery = query(
          collection(firestore, `users/${userDoc.id}/notifications`),
          where("postId", "==", postId)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);

        const notificationDeletePromises = notificationsSnapshot.docs.map(
          (doc) => {
            batch.delete(doc.ref);
            operationCount++;
            return commitBatchIfNeeded();
          }
        );

        await Promise.all(notificationDeletePromises);
      }

      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${postId}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            // Delete main file
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            // If it's a video, also delete the thumbnail
            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          // Delete entire folder contents to ensure nothing is left
          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error deleting files from storage:", error);
        }
      }

      batch.delete(postRef);
      operationCount++;

      await batch.commit();

      setPosts((prevPosts) => {
        const newPosts = {
          directAndInteracted: prevPosts.directAndInteracted.filter(
            (p) => p.id !== postId
          ),
          indirectSuggested: prevPosts.indirectSuggested.filter(
            (p) => p.id !== postId
          ),
          extendedSuggested: prevPosts.extendedSuggested.filter(
            (p) => p.id !== postId
          ),
        };
        return newPosts;
      });

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      setDeleteMessage({
        text: "Post deleted successfully.",
        postId,
        isSuccess: true,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    uploadingPostsRef.current = state.uploadingPosts;
  }, [state.uploadingPosts]);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      togglePostModal();
      event.target.value = "";
    }
  };

  const fileInputRef = useRef(null);

  const handleAddPost = () => {
    Object.values(deleteTimeouts).forEach(clearTimeout);
    setDeleteTimeouts({});
    togglePostModal();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());

          const connectionsRef = collection(
            firestore,
            "users",
            userId,
            "connections"
          );
          const connectionsSnapshot = await getDocs(
            query(connectionsRef, where("status", "==", "Connected"))
          );
          setDirectConnectionIds(connectionsSnapshot.docs.map((doc) => doc.id));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const userImage = userData.profileImage || profileImage;

  const shouldKeepPostVisible = async (post, reactions, reposts) => {
    if (!post || !reactions || !reposts) {
      console.error(
        "Invalid post or reactions or reposts:",
        post,
        reactions,
        reposts
      );
      return false;
    }

    const postUserId = post.user?.id || post.userId;
    if (!postUserId) {
      console.error("Invalid post user ID:", post);
      return false;
    }

    const connectionType = await checkConnectionType(
      currentUser.uid,
      postUserId
    );

    if (connectionType === "You" || connectionType === "Direct") {
      return true;
    }

    if (reactions[currentUser.uid]) {
      return true;
    }

    if (reposts[currentUser.uid]) {
      return true;
    }

    const directConnections = await fetchDirectConnections(currentUser.uid);
    for (const connectionId of directConnections) {
      if (reactions[connectionId]) {
        return true;
      }
      if (reposts[connectionId]) {
        return true;
      }
    }

    return false;
  };

  const fetchDirectConnections = async (userId) => {
    const connectionsRef = collection(
      firestore,
      "users",
      userId,
      "connections"
    );
    const connectionsSnapshot = await getDocs(
      query(connectionsRef, where("status", "==", "Connected"))
    );
    return connectionsSnapshot.docs.map((doc) => doc.id);
  };

  const fetchReactedPosts = async (userId) => {
    const reactedPostsRef = collection(
      firestore,
      "users",
      userId,
      "reactedPosts"
    );
    const reactedPostsSnapshot = await getDocs(
      query(reactedPostsRef, orderBy("reactedAt", "desc"), limit(20))
    );
    const reactedPosts = [];

    for (const docSnapshot of reactedPostsSnapshot.docs) {
      const reactedPostData = docSnapshot.data();
      const originalPosterId = reactedPostData.originalPosterId;

      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${reactedPostData.postId}`
            );
            const startupPostSnapshot = await getDoc(startupPostRef);

            if (startupPostSnapshot.exists()) {
              const postData = startupPostSnapshot.data();
              const startupData = startupDoc.data();
              const reactorData = await getUserData(userId);

              const post = await createPostObject(
                userDoc.id,
                reactedPostData.postId,
                {
                  ...postData,
                  user: {
                    id: `startup_${startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage,
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                    connectionType: "Extended",
                    isStartup: true,
                    startupId: startupId,
                    startupOwnerId: userDoc.id,
                  },
                  originalDirectReactionTime:
                    postData.originalDirectReactionTime,
                },
                {
                  id: userId,
                  firstName: reactorData.firstName,
                  lastName: reactorData.lastName,
                  reactedAt: reactedPostData.reactedAt,
                  emoji: reactedPostData.emoji,
                }
              );

              reactedPosts.push(post);
              break;
            }
          }
        }
      } else {
        const originalPostRef = doc(
          firestore,
          "users",
          originalPosterId,
          "posts",
          reactedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const posterData = await getUserData(originalPosterId);
          const reactorData = await getUserData(userId);
          const connectionType = await checkConnectionType(
            currentUser.uid,
            originalPosterId
          );

          const post = await createPostObject(
            reactedPostData.originalPosterId,
            reactedPostData.postId,
            {
              ...postData,
              user: {
                id: originalPosterId,
                firstName: posterData.firstName || "",
                lastName: posterData.lastName || "",
                profileImage: posterData.profileImage || profileImage,
                bio: posterData.bio || "",
                link: posterData.link || null,
                linkText: posterData.linkText || null,
                connectionType: connectionType,
                isStartup: false,
              },
              originalDirectReactionTime: postData.originalDirectReactionTime,
            },
            {
              id: userId,
              firstName: reactorData.firstName,
              lastName: reactorData.lastName,
              reactedAt: reactedPostData.reactedAt,
              emoji: reactedPostData.emoji,
            }
          );

          reactedPosts.push(post);
        }
      }
    }
    return reactedPosts;
  };

  const fetchReposts = async (userId) => {
    const repostsRef = collection(firestore, "users", userId, "reposts");
    const repostsSnapshot = await getDocs(
      query(repostsRef, orderBy("repostedAt", "desc"), limit(20))
    );
    const reposts = [];

    for (const docSnapshot of repostsSnapshot.docs) {
      const repostData = docSnapshot.data();
      const originalPosterId = repostData.originalPosterId;

      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${repostData.postId}`
            );
            const startupPostSnapshot = await getDoc(startupPostRef);

            if (startupPostSnapshot.exists()) {
              const postData = startupPostSnapshot.data();
              const startupData = startupDoc.data();
              const reposterData = await getUserData(userId);

              const post = await createPostObject(
                userDoc.id,
                repostData.postId,
                {
                  ...postData,
                  user: {
                    id: `startup_${startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage,
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                    connectionType: "Extended",
                    isStartup: true,
                    startupId: startupId,
                    startupOwnerId: userDoc.id,
                  },
                  originalDirectRepostTime: postData.originalDirectRepostTime,
                },
                null,
                {
                  id: userId,
                  firstName: reposterData.firstName,
                  lastName: reposterData.lastName,
                  repostedAt: repostData.repostedAt,
                }
              );

              reposts.push(post);
              break;
            }
          }
        }
      } else {
        const originalPostRef = doc(
          firestore,
          "users",
          originalPosterId,
          "posts",
          repostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const posterData = await getUserData(originalPosterId);
          const reposterData = await getUserData(userId);
          const connectionType = await checkConnectionType(
            currentUser.uid,
            originalPosterId
          );

          const post = await createPostObject(
            repostData.originalPosterId,
            repostData.postId,
            {
              ...postData,
              user: {
                id: originalPosterId,
                firstName: posterData.firstName || "",
                lastName: posterData.lastName || "",
                profileImage: posterData.profileImage || profileImage,
                bio: posterData.bio || "",
                link: posterData.link || null,
                linkText: posterData.linkText || null,
                connectionType: connectionType,
                isStartup: false,
              },
              originalDirectRepostTime: postData.originalDirectRepostTime,
            },
            null,
            {
              id: userId,
              firstName: reposterData.firstName,
              lastName: reposterData.lastName,
              repostedAt: repostData.repostedAt,
            }
          );

          reposts.push(post);
        }
      }
    }
    return reposts;
  };

  const fetchCommentedPosts = async (userId) => {
    const commentedPostsRef = collection(
      firestore,
      "users",
      userId,
      "commentedPosts"
    );
    const commentedPostsSnapshot = await getDocs(
      query(commentedPostsRef, orderBy("lastCommentedAt", "desc"), limit(20))
    );
    const commentedPosts = [];

    for (const docSnapshot of commentedPostsSnapshot.docs) {
      const commentedPostData = docSnapshot.data();
      const originalPosterId = commentedPostData.originalPosterId;

      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${commentedPostData.postId}`
            );
            const startupPostSnapshot = await getDoc(startupPostRef);

            if (startupPostSnapshot.exists()) {
              const postData = startupPostSnapshot.data();
              const startupData = startupDoc.data();
              const commenterData = await getUserData(userId);

              const mostRecentComment = Object.values(
                commentedPostData.comments
              )
                .sort(
                  (a, b) => b.commentedAt.toMillis() - a.commentedAt.toMillis()
                )
                .shift();

              const post = await createPostObject(
                userDoc.id,
                commentedPostData.postId,
                {
                  ...postData,
                  user: {
                    id: `startup_${startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage,
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                    connectionType: "Extended",
                    isStartup: true,
                    startupId: startupId,
                    startupOwnerId: userDoc.id,
                  },
                  originalDirectCommentTime: postData.originalDirectCommentTime,
                },
                null,
                null,
                {
                  id: userId,
                  firstName: commenterData.firstName,
                  lastName: commenterData.lastName,
                  commentedAt: mostRecentComment.commentedAt,
                  commentText: mostRecentComment.commentText,
                }
              );

              commentedPosts.push(post);
              break;
            }
          }
        }
      } else {
        const originalPostRef = doc(
          firestore,
          "users",
          originalPosterId,
          "posts",
          commentedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const posterData = await getUserData(originalPosterId);
          const commenterData = await getUserData(userId);
          const connectionType = await checkConnectionType(
            currentUser.uid,
            originalPosterId
          );

          const mostRecentComment = Object.values(commentedPostData.comments)
            .sort((a, b) => b.commentedAt.toMillis() - a.commentedAt.toMillis())
            .shift();

          const post = await createPostObject(
            commentedPostData.originalPosterId,
            commentedPostData.postId,
            {
              ...postData,
              user: {
                id: originalPosterId,
                firstName: posterData.firstName || "",
                lastName: posterData.lastName || "",
                profileImage: posterData.profileImage || profileImage,
                bio: posterData.bio || "",
                link: posterData.link || null,
                linkText: posterData.linkText || null,
                connectionType: connectionType,
                isStartup: false,
              },
              originalDirectCommentTime: postData.originalDirectCommentTime,
            },
            null,
            null,
            {
              id: userId,
              firstName: commenterData.firstName,
              lastName: commenterData.lastName,
              commentedAt: mostRecentComment.commentedAt,
              commentText: mostRecentComment.commentText,
            }
          );

          commentedPosts.push(post);
        }
      }
    }
    return commentedPosts;
  };

  const fetchUserPosts = async (userId, startupId = null) => {
    try {
      let postsRef;
      if (startupId) {
        postsRef = collection(
          firestore,
          `users/${userId}/startups/${startupId}/posts`
        );
      } else {
        postsRef = collection(firestore, `users/${userId}/posts`);
      }

      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      return Promise.all(
        postsSnapshot.docs.map(async (docSnapshot) => {
          // Changed from doc to docSnapshot
          const postData = docSnapshot.data();
          if (startupId) {
            // This is a startup post
            const startupDoc = await getDoc(
              doc(firestore, `users/${userId}/startups/${startupId}`)
            );
            const startupData = startupDoc.data();

            return createPostObject(userId, docSnapshot.id, {
              // Changed from doc.id to docSnapshot.id
              ...postData,
              user: {
                id: `startup_${startupId}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                connectionType: "Direct", // Treat followed startups as direct connections
                isStartup: true,
                startupId: startupId,
                startupOwnerId: userId,
              },
            });
          } else {
            return createPostObject(userId, docSnapshot.id, postData); // Changed from doc.id to docSnapshot.id
          }
        })
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
      return [];
    }
  };

  const getUserData = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    return userDocSnapshot.data();
  };

  const getLatestActivityTimestamp = (post) => {
    if (post.visibleReactedBy && post.visibleReactedBy.reactedAt) {
      return post.visibleReactedBy.reactedAt.toMillis();
    }
    if (post.visibleRepostedBy && post.visibleRepostedBy.repostedAt) {
      return post.visibleRepostedBy.repostedAt.toMillis();
    }
    return post.createdAt.toMillis();
  };

  const handleCommentedPostUpdates = async (snapshot) => {
    const updatedCommentedPosts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const commentedPostData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          commentedPostData.originalPosterId,
          "posts",
          commentedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const commenterId = change.doc.ref.parent.parent.id;
          const commenterData = await getUserData(commenterId);

          let mostRecentComment = null;
          if (
            commentedPostData.comments &&
            typeof commentedPostData.comments === "object"
          ) {
            mostRecentComment = Object.values(commentedPostData.comments)
              .filter((comment) => comment.commentedAt)
              .sort((a, b) => {
                const aTime = a.commentedAt ? a.commentedAt.toMillis() : 0;
                const bTime = b.commentedAt ? b.commentedAt.toMillis() : 0;
                return bTime - aTime;
              })
              .shift();
          }

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === commentedPostData.postId
            ) ||
            posts.indirectSuggested.find(
              (p) => p.id === commentedPostData.postId
            ) ||
            posts.extendedSuggested.find(
              (p) => p.id === commentedPostData.postId
            );

          const updatedPost = await createPostObject(
            commentedPostData.originalPosterId,
            commentedPostData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              originalDirectRepostTime: existingPost?.originalDirectRepostTime,
              originalDirectCommentTime:
                existingPost?.originalDirectCommentTime,
            },
            null,
            null,
            change.type !== "removed" && mostRecentComment
              ? {
                  id: commenterId,
                  firstName: commenterData.firstName,
                  lastName: commenterData.lastName,
                  commentedAt: mostRecentComment.commentedAt,
                  commentText: mostRecentComment.commentText,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: commentedPostData.postId, removed: true };
        } else {
          return { id: commentedPostData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedCommentedPosts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleCommentedBy:
                  up.visibleCommentedBy || newPosts[index].visibleCommentedBy,
                originalDirectCommentTime:
                  up.originalDirectCommentTime ||
                  newPosts[index].originalDirectCommentTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  useEffect(() => {
    const handlePostUpdates = async (snapshot) => {
      const updatedPosts = await Promise.all(
        snapshot.docChanges().map(async (change) => {
          if (change.type === "added" || change.type === "modified") {
            const updatedPost = await createPostObject(
              change.doc.ref.parent.parent.id,
              change.doc.id,
              change.doc.data()
            );
            const existingPost = findPostById(change.doc.id);
            if (existingPost) {
              updatedPost.visibleReactedBy = existingPost.visibleReactedBy;
              updatedPost.originalDirectReactionTime =
                existingPost.originalDirectReactionTime;
              updatedPost.visibleRepostedBy = existingPost.visibleRepostedBy;
              updatedPost.originalDirectRepostTime =
                existingPost.originalDirectRepostTime;
              updatedPost.sortTime =
                existingPost.sortTime || getPostSortTime(updatedPost);
            } else {
              updatedPost.sortTime = getPostSortTime(updatedPost);
            }
            return updatedPost;
          } else if (change.type === "removed") {
            return { id: change.doc.id, removed: true };
          }
        })
      );

      setPosts((prevPosts) => {
        const updateCategory = (category) => {
          let newPosts = [...prevPosts[category]];
          updatedPosts.forEach((up) => {
            if (up && up.removed) {
              newPosts = newPosts.filter((p) => p.id !== up.id);
            } else if (up) {
              const index = newPosts.findIndex((p) => p.id === up.id);
              if (index !== -1) {
                newPosts[index] = {
                  ...newPosts[index],
                  ...up,
                  sortTime: newPosts[index].sortTime || up.sortTime,
                };
              } else {
                newPosts.push(up);
              }
            }
          });
          return sortPosts(newPosts.filter(Boolean));
        };

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });
    };
  }, [posts, currentUser, checkConnectionType, firestore, profileImage]);

  const handleReactedPostUpdates = async (snapshot) => {
    const updatedReactedPosts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const reactedPostData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          reactedPostData.originalPosterId,
          "posts",
          reactedPostData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const reactorId = change.doc.ref.parent.parent.id;
          const reactorData = await getUserData(reactorId);

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === reactedPostData.postId
            ) ||
            posts.indirectSuggested.find(
              (p) => p.id === reactedPostData.postId
            ) ||
            posts.extendedSuggested.find(
              (p) => p.id === reactedPostData.postId
            );

          const updatedPost = await createPostObject(
            reactedPostData.originalPosterId,
            reactedPostData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              originalDirectRepostTime: existingPost?.originalDirectRepostTime,
              originalDirectCommentTime:
                existingPost?.originalDirectCommentTime,
            },
            change.type !== "removed"
              ? {
                  id: reactorId,
                  firstName: reactorData.firstName,
                  lastName: reactorData.lastName,
                  reactedAt: reactedPostData.reactedAt,
                  emoji: reactedPostData.emoji,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: reactedPostData.postId, removed: true };
        } else {
          return { id: reactedPostData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedReactedPosts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleReactedBy:
                  up.visibleReactedBy || newPosts[index].visibleReactedBy,
                originalDirectReactionTime:
                  up.originalDirectReactionTime ||
                  newPosts[index].originalDirectReactionTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  const handleRepostsUpdates = async (snapshot) => {
    const updatedReposts = await Promise.all(
      snapshot.docChanges().map(async (change) => {
        const repostsData = change.doc.data();
        const originalPostRef = doc(
          firestore,
          "users",
          repostsData.originalPosterId,
          "posts",
          repostsData.postId
        );
        const originalPostSnapshot = await getDoc(originalPostRef);

        if (originalPostSnapshot.exists()) {
          const postData = originalPostSnapshot.data();
          const reposterId = change.doc.ref.parent.parent.id;
          const reposterData = await getUserData(reposterId);

          const existingPost =
            posts.directAndInteracted.find(
              (p) => p.id === repostsData.postId
            ) ||
            posts.indirectSuggested.find((p) => p.id === repostsData.postId) ||
            posts.extendedSuggested.find((p) => p.id === repostsData.postId);

          const updatedPost = await createPostObject(
            repostsData.originalPosterId,
            repostsData.postId,
            {
              ...postData,
              visibleReactedBy: existingPost?.visibleReactedBy,
              originalDirectReactionTime:
                existingPost?.originalDirectReactionTime,
              visibleRepostedBy: existingPost?.visibleRepostedBy,
              visibleCommentedBy: existingPost?.visibleCommentedBy,
            },
            null,
            change.type !== "removed"
              ? {
                  id: reposterId,
                  firstName: reposterData.firstName,
                  lastName: reposterData.lastName,
                  repostedAt: repostsData.repostedAt,
                }
              : null
          );

          const shouldBeVisible = await shouldPostBeVisible(updatedPost);
          return shouldBeVisible
            ? updatedPost
            : { id: repostsData.postId, removed: true };
        } else {
          return { id: repostsData.postId, removed: true };
        }
      })
    );

    setPosts((prevPosts) => {
      const updateCategory = (category) => {
        let newPosts = [...prevPosts[category]];
        updatedReposts.forEach((up) => {
          if (up && up.removed) {
            newPosts = newPosts.filter((p) => p.id !== up.id);
          } else if (up) {
            const index = newPosts.findIndex((p) => p.id === up.id);
            if (index !== -1) {
              newPosts[index] = {
                ...newPosts[index],
                ...up,
                visibleRepostedBy:
                  up.visibleRepostedBy || newPosts[index].visibleRepostedBy,
                originalDirectRepostTime:
                  up.originalDirectRepostTime ||
                  newPosts[index].originalDirectRepostTime,
                visibleReactedBy:
                  newPosts[index].visibleReactedBy || up.visibleReactedBy,
                originalDirectReactionTime:
                  newPosts[index].originalDirectReactionTime ||
                  up.originalDirectReactionTime,
              };
            } else {
              newPosts.push(up);
            }
          }
        });
        return sortPosts(newPosts.filter(Boolean));
      };

      return {
        directAndInteracted: updateCategory("directAndInteracted"),
        indirectSuggested: updateCategory("indirectSuggested"),
        extendedSuggested: updateCategory("extendedSuggested"),
      };
    });
  };

  const createPostObject = async (userId, postId, postData) => {
    const userDocRef = doc(firestore, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();

    let connectionType;
    if (postData.isStartup) {
      connectionType = "Startup";
    } else if (userId === currentUser.uid) {
      connectionType = "You";
    } else {
      connectionType = await checkConnectionType(currentUser.uid, userId);
    }

    const postObject = {
      id: postId,
      ...postData,
      user: {
        id: postData.isStartup ? `startup_${postData.startupId}` : userId,
        firstName: postData.isStartup
          ? postData.user.firstName
          : userData.firstName || "Unknown",
        lastName: postData.isStartup ? "" : userData.lastName || "User",
        profileImage: postData.isStartup
          ? postData.user.profileImage
          : userData.profileImage || profileImage,
        bio: postData.isStartup ? postData.user.bio : userData.bio || "",
        link: postData.isStartup ? postData.user.link : userData.link || null,
        linkText: postData.isStartup
          ? postData.user.linkText
          : userData.linkText || null,
        connectionType: connectionType,
        ...(postData.isStartup && {
          isStartup: true,
          startupId: postData.startupId,
          startupOwnerId: postData.startupOwnerId,
        }),
      },
      reactions: postData.reactions || {},
      reposts: postData.reposts || {},
      comments: postData.comments || {},
      createdAt: postData.createdAt || new Date(),
      commentCount: postData.commentCount || 0,
      firstSeenAt: seenPosts[postId]
        ? seenPosts[postId].firstSeenAt
        : new Date(),
    };

    if (!seenPosts[postId]) {
      setSeenPosts((prev) => ({
        ...prev,
        [postId]: {
          firstSeenAt: new Date(),
          originalPosition: Object.keys(prev).length,
        },
      }));
    }

    return postObject;
  };

  const shouldPostBeVisible = async (post) => {
    if (!post || !post.user || !post.user.id) return false;

    // User's own post or direct posts
    if (
      post.user.id === currentUser.uid ||
      post.user.connectionType === "Direct"
    )
      return true;

    // Current user interactions
    if (
      post.reactions?.[currentUser.uid] ||
      post.reposts?.[currentUser.uid] ||
      post.comments?.[currentUser.uid]
    )
      return true;

    // Get followed startups
    const followedStartupsSnapshot = await getDocs(
      collection(firestore, `users/${currentUser.uid}/startupsFollowing`)
    );
    const followedStartupIds = followedStartupsSnapshot.docs.map(
      (doc) => `startup_${doc.id}`
    );

    // Check if post is from a followed startup
    if (post.user.isStartup) {
      const startupId = `startup_${post.user.startupId}`;
      if (followedStartupIds.includes(startupId)) return true;
    }

    // Check followed startup interactions
    for (const startupId of followedStartupIds) {
      // Check reactions
      if (post.reactions?.[startupId]) {
        return true;
      }

      // Check reposts
      if (post.reposts?.[startupId]) {
        return true;
      }

      // Check comments
      if (post.comments?.[startupId]) {
        return true;
      }
    }

    // Check direct connections' interactions only if no startup interactions found
    const directConnections = await fetchDirectConnections(currentUser.uid);
    const hasDirectConnectionInteraction =
      Object.keys(post.reactions || {}).some((id) =>
        directConnections.includes(id)
      ) ||
      Object.keys(post.reposts || {}).some((id) =>
        directConnections.includes(id)
      ) ||
      Object.keys(post.comments || {}).some((id) =>
        directConnections.includes(id)
      );

    return hasDirectConnectionInteraction;
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchInitialPosts = async () => {
      if (currentUser && !loading) {
        setLoading(true);
        try {
          await fetchAndSubscribeToPosts();
          if (isMounted) {
            setHasAttemptedLoad(true); // This line may not be getting called
          }
        } catch (error) {
          console.error("Error fetching initial posts:", error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    if (currentUser) {
      fetchInitialPosts();
    }

    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  const handleScroll = useCallback(() => {
    if (
      !loading &&
      hasMore &&
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
    ) {
      fetchAndSubscribeToPosts(true);
    }
  }, [loading, hasMore, fetchAndSubscribeToPosts]);

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 500);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, [handleScroll]);

  const togglePostModal = () => {
    if (!showPostModal) {
      setPostModalScrollPosition(window.scrollY);
    }
    setShowPostModal(!showPostModal);
    setIsPostModalOpen(!isPostModalOpen);
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}y`;
    }
  };

  const renderPostText = (post) => {
    if (!post || !post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    const isSuggestedPost =
      post.user.connectionType === "Indirect" ||
      post.user.connectionType === "Extended";

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              togglePostExpansion(post.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const togglePostExpansion = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handlePrevImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + totalImages) % totalImages,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const renderPostContent = (post) => {
    if (!post || typeof post !== "object") {
      console.log("Invalid post object:", post);
      return null;
    }

    console.log("Rendering post content for post", post.id);
    console.log("Post content:", post.content);
    console.log(
      "Current image index for post",
      post.id,
      ":",
      currentImageIndex[post.id]
    );

    if (!post.content) {
      console.log("No content for post", post.id);
      return null;
    }

    // Preload images when rendering post content
    if (
      post.content &&
      post.content.type === "image" &&
      Array.isArray(post.content.url)
    ) {
      preloadImages(post.content.url);
    }

    const hasNoInteractions =
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    return (
      <div className={classes.postContentWrapper}>
        {post.content && post.content.type === "image" && (
          <div className={classes.imageCarousel}>
            {Array.isArray(post.content.url) ? (
              <>
                <img
                  key={currentImageIndex[post.id] || 0}
                  src={post.content.url[currentImageIndex[post.id] || 0]}
                  alt={""}
                  className={classes.postContentImage}
                  onClick={() =>
                    handleNextImage(post.id, post.content.url.length)
                  }
                />
                {post.content.url.length > 1 && (
                  <div className={classes.dotNavigation}>
                    {post.content.url.map((_, index) => (
                      <span
                        key={index}
                        className={`${classes.dotNav} ${
                          index === (currentImageIndex[post.id] || 0)
                            ? classes.activeDot
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentImageIndex((prev) => ({
                            ...prev,
                            [post.id]: index,
                          }));
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <img
                src={post.content.url}
                alt=""
                className={classes.postContentImage}
              />
            )}
          </div>
        )}

        {post.content.type === "video" && (
          <div className={classes.videoContainer}>
            <video
              ref={(el) => (videoRefs.current[post.id] = el)}
              className={classes.postContentVideo}
              playsInline
              muted={videoVolumes[post.id] !== true}
              loop
              autoPlay
              preload="metadata"
              poster={post.content.thumbnail || ""}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
            >
              <source src={post.content.url} type="video/mp4" />
            </video>
            <div className={classes.videoControls}>
              <button
                className={classes.volumeControl}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVolume(post.id);
                }}
              >
                {videoVolumes[post.id] !== true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        )}
        {post.content.type === "pdf" && (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              hasNoInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log("currentImageIndex state updated:", currentImageIndex);
  }, [currentImageIndex]);

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const postRef = getPostRef(post); // Use helper function
      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;
      const repostedPostRef = doc(firestore, `${path}/reposts/${postId}`);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          "users",
          post.user.id,
          "notifications",
          `repost_${postId}_${reposterId}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        if (post.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            post.user.id,
            "notifications",
            `repost_${postId}_${reposterId}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterId,
            reposterName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reposterImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((p) =>
            p.id === postId
              ? {
                  ...p,
                  reposts: updatedReposts,
                  originalDirectRepostTime: updatedReposts[reposterId]
                    ? now
                    : p.originalDirectRepostTime,
                }
              : p
          );

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });
    } catch (error) {
      console.error("Error in handleRepost:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the repost.",
          isError: true,
        });
      }
    }
  };

  const MemoizedStartupInteractionToggle = React.memo(
    ({
      currentUser,
      onStartupSelect,
      selectedStartup,
      currentUserProfileImage,
      onModalOpen,
      onModalClose,
    }) => (
      <StartupInteractionToggle
        currentUser={currentUser}
        onStartupSelect={onStartupSelect}
        selectedStartup={selectedStartup}
        currentUserProfileImage={currentUserProfileImage}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
      />
    )
  );

  const handleStartupSelect = useCallback((startup) => {
    setSelectedStartup(startup);
  }, []);

  const handleModalOpen = useCallback((postId) => {
    setGlobalStartupModalState({
      isOpen: true,
      scrollPosition: window.scrollY,
      sourceLocation: "postInteraction",
      postId: postId,
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setGlobalStartupModalState((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setTimeout(() => {
      window.scrollTo(0, globalStartupModalState.scrollPosition);
    }, 0);
  }, [globalStartupModalState.scrollPosition]);

  const determineVisibleAction = async (post) => {
    if (!post || !post.user) return null;

    const postUserId = post.user?.id || post.userId;
    if (!postUserId) {
      console.error("Invalid post user ID:", post);
      return null;
    }

    // Check if it's user's own post or a startup post owned by the user
    const isOwnContent =
      post.user.connectionType === "You" ||
      post.user.connectionType === "Direct" ||
      (post.user.isStartup && post.user.startupOwnerId === currentUser.uid);

    if (isOwnContent) {
      return null;
    }

    // For startup posts, check if user follows the startup
    if (post.user.isStartup) {
      const startupFollowingRef = doc(
        firestore,
        `users/${currentUser.uid}/startupsFollowing/${post.user.startupId}`
      );
      const startupFollowingDoc = await getDoc(startupFollowingRef);
      if (startupFollowingDoc.exists()) {
        return null; // Don't show interaction header for followed startups
      }
    }

    const storedPost = seenPosts[post.id];
    if (storedPost) {
      // Check in order of priority: repost > comment > reaction
      if (storedPost.visibleRepostedBy) {
        return {
          type: "repost",
          user: storedPost.visibleRepostedBy,
          message: "reposted this",
        };
      } else if (storedPost.visibleCommentedBy) {
        return {
          type: "comment",
          user: storedPost.visibleCommentedBy,
          message: "commented on this",
        };
      } else if (storedPost.visibleReactedBy) {
        return {
          type: "reaction",
          user: storedPost.visibleReactedBy,
          message: "reacted",
          emoji: storedPost.visibleReactedBy.emoji,
        };
      }
    }

    if (
      post.visibleRepostedBy &&
      post.visibleRepostedBy.id !== currentUser.uid
    ) {
      return {
        type: "repost",
        user: post.visibleRepostedBy,
        message: "reposted this",
      };
    }

    if (
      post.visibleCommentedBy &&
      post.visibleCommentedBy.id !== currentUser.uid
    ) {
      return {
        type: "comment",
        user: post.visibleCommentedBy,
        message: "commented on this",
      };
    }

    if (post.visibleReactedBy && post.visibleReactedBy.id !== currentUser.uid) {
      return {
        type: "reaction",
        user: post.visibleReactedBy,
        message: "reacted",
        emoji: post.visibleReactedBy.emoji,
      };
    }

    return null;
  };

  const [visibleActions, setVisibleActions] = useState({});

  useEffect(() => {
    const getVisibleActions = async () => {
      const actions = {};
      for (const post of posts.directAndInteracted) {
        const action = await determineVisibleAction(post);
        if (action) {
          actions[post.id] = action;
        }
      }
      setVisibleActions(actions);
    };

    getVisibleActions();
  }, [posts.directAndInteracted]);

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const handleEditComment = (postId, commentId, isReply = false) => {
    const postComments = comments[postId];
    if (!postComments) {
      console.error(`No comments found for post ${postId}`);
      return;
    }

    const commentToEdit = postComments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
      console.error(`Comment ${commentId} not found in post ${postId}`);
    }
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const post = findPostById(postId);
      if (!post) throw new Error("Post not found");

      // Determine the correct base path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      // Verify permission to edit
      let canEdit = false;
      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Update local state for comments
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      // Update commentedPosts record if this is a root comment
      if (!isReply) {
        const commenterPath = commentData.userId.startsWith("startup_")
          ? `users/${
              commentData.startupOwnerId
            }/startups/${commentData.userId.replace("startup_", "")}`
          : `users/${commentData.userId}`;

        const commentedPostRef = doc(
          firestore,
          `${commenterPath}/commentedPosts/${postId}`
        );

        const commentedPostSnap = await getDoc(commentedPostRef);
        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          if (
            commentedPostData.comments &&
            commentedPostData.comments[commentId]
          ) {
            await updateDoc(commentedPostRef, {
              [`comments.${commentId}.commentText`]: processedText,
            });
          }
        }
      }

      // Clean up edit state
      setEditingCommentId(null);
      setEditCommentText("");

      console.log("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Post not found"
            ? "The post containing this comment has been deleted."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      // Determine the correct document path based on whether it's a startup post
      let postRef;
      if (editingPost.user.isStartup) {
        // For startup posts, use the startup owner's ID and startup ID
        postRef = doc(
          firestore,
          `users/${editingPost.user.startupOwnerId}/startups/${editingPost.user.startupId}/posts/${editingPost.id}`
        );
      } else {
        // For regular user posts
        postRef = doc(
          firestore,
          `users/${editingPost.user.id}/posts/${editingPost.id}`
        );
      }

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPosts((prevPosts) => {
        const updateCategory = (category) =>
          prevPosts[category].map((post) =>
            post.id === editingPost.id
              ? { ...post, text: updatedText, isEdited: isEdited }
              : post
          );

        return {
          directAndInteracted: updateCategory("directAndInteracted"),
          indirectSuggested: updateCategory("indirectSuggested"),
          extendedSuggested: updateCategory("extendedSuggested"),
        };
      });

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  console.log(
    "Dashboard - Current selectedStartup before rendering PostModal:",
    selectedStartup
  );

  const modalState = useMemo(
    () => ({
      isOpen:
        globalStartupModalState.isOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen,
      scrollPosition:
        globalStartupModalState.scrollPosition ||
        reactionsModalScrollPosition ||
        repostsModalScrollPosition ||
        postModalScrollPosition,
    }),
    [
      globalStartupModalState.isOpen,
      isReactionsModalOpen,
      isRepostsModalOpen,
      isPostModalOpen,
      globalStartupModalState.scrollPosition,
      reactionsModalScrollPosition,
      repostsModalScrollPosition,
      postModalScrollPosition,
    ]
  );

  return (
    <div
      className={`${classes.page} ${
        globalStartupModalState.isOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        activeEmojiPicker?.startsWith("post_") ||
        mainEmojiPickerOpen !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        globalStartupModalState.isOpen
          ? { top: `-${globalStartupModalState.scrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker?.startsWith("post_") // Changed this line
          ? { top: `-${emojiPickerScrollPosition}px` }
          : mainEmojiPickerOpen !== null
          ? { top: `-${emojiPickerScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}

      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}
      {showPostModal && (
        <PostModal
          onClose={handleClosePostModal}
          onManualClose={() => {
            setShowPostModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialFile={selectedFile}
          canceledUploads={canceledUploads}
          selectedStartup={selectedStartup} // Make sure this is being passed
        />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={handleCloseReactionsModal}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser.uid}
        />
      )}
      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={handleCloseRepostsModal}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser.uid}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {cancelMessage && (
        <CancelPopup
          message={cancelMessage}
          onClose={() => setCancelMessage(null)}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {!loading &&
            (posts.directAndInteracted.length > 0 ||
              posts.indirectSuggested.length > 0 ||
              posts.extendedSuggested.length > 0) && (
              <div className={classes.topCard}>
                <div className={classes.postContent}>
                  <div className={classes.profileImageContainer}>
                    <StartupInteractionToggle
                      currentUser={currentUser}
                      onStartupSelect={setSelectedStartup}
                      selectedStartup={selectedStartup}
                      currentUserProfileImage={currentUserProfileImage}
                      onModalOpen={() => {
                        setGlobalStartupModalState({
                          isOpen: true,
                          scrollPosition: window.scrollY,
                          sourceLocation: "topCard",
                        });
                      }}
                      onModalClose={() => {
                        setGlobalStartupModalState((prev) => ({
                          ...prev,
                          isOpen: false,
                        }));
                        setTimeout(() => {
                          window.scrollTo(
                            0,
                            globalStartupModalState.scrollPosition
                          );
                        }, 0);
                      }}
                    />
                  </div>
                  <div className={classes.postButton} onClick={handleAddPost}>
                    Add a post
                  </div>
                </div>
              </div>
            )}

          {/* Show uploading posts section */}
          {Object.entries(state.uploadingPosts).map(
            ([postId, postData]) =>
              !state.cancelledPosts[postId] && (
                <UploadingPost
                  key={postId}
                  postId={postId}
                  progress={postData.progress}
                  status={postData.status}
                  onCancelUpload={handleCancelUpload}
                />
              )
          )}

          {/* Rest of your existing post sections */}
          {posts.directAndInteracted &&
            posts.directAndInteracted.length > 0 && (
              <div className={classes.postSection}>
                {posts.directAndInteracted.map((post) => (
                  <div key={post.id} className={classes.postCard}>
                    {(() => {
                      const visibleAction = visibleActions[post.id];
                      if (visibleAction) {
                        return (
                          <div className={classes.reactionHeader}>
                            <img
                              src={
                                visibleAction.user.profileImage || defaultImage
                              }
                              alt={`${visibleAction.user.firstName} ${visibleAction.user.lastName}`}
                              className={classes.reactionImage}
                              onClick={() =>
                                handleNavigate(visibleAction.user.id)
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <span className={classes.reactionText}>
                              <span
                                className={classes.reactorName}
                                onClick={() =>
                                  handleNavigate(visibleAction.user.id)
                                }
                              >
                                {visibleAction.user.firstName}{" "}
                                {visibleAction.user.lastName}
                              </span>{" "}
                              {visibleAction.message}
                              {visibleAction.type === "reaction" &&
                                visibleAction.emoji && (
                                  <span className={classes.reactorEmoji}>
                                    {" "}
                                    {visibleAction.emoji}
                                  </span>
                                )}
                              {visibleAction.type === "reaction"
                                ? " to this"
                                : ""}
                            </span>
                          </div>
                        );
                      }
                      return null;
                    })()}
                    <div className={classes.postHeader}>
                      <div className={classes.userInfo}>
                        <img
                          className={classes.postImage}
                          src={post.user.profileImage || defaultImage}
                          alt=""
                          onClick={() => handleNavigate(post.user.id)}
                        />
                        <div className={classes.userDetails}>
                          <div className={classes.userNameContainer}>
                            <p
                              className={classes.userName}
                              onClick={() => handleNavigate(post.user.id)}
                            >
                              <span className={classes.nameText}>
                                {`${post.user.firstName} ${post.user.lastName}`}
                              </span>
                            </p>
                            {post.user.connectionType && (
                              <span className={classes.connectionType}>
                                • {post.user.connectionType}
                              </span>
                            )}
                          </div>
                          {post.user.bio && (
                            <p className={classes.bio}>{post.user.bio}</p>
                          )}
                          {post.user.link && (
                            <div className={classes.userLinkContainer}>
                              <a
                                href={post.user.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                className={classes.userLink}
                              >
                                <span className={classes.userLinkText}>
                                  {post.user.linkText || post.user.link}
                                </span>
                                <svg
                                  className={classes.userLinkIcon}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="orangered"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            </div>
                          )}
                          <div className={classes.timeAndEditDetails}>
                            {post.createdAt && (
                              <p className={classes.time}>
                                {formatTimestamp(post.createdAt)}
                              </p>
                            )}
                            {post.isEdited && (
                              <div className={classes.editDot}>
                                •
                                <span className={classes.editedPostText}>
                                  Edited
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.postOptions}>
                        <svg
                          className={`${classes.optionsIcon} ${
                            showDropdown[post.id]
                              ? classes.optionsIconActive
                              : ""
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(post.id);
                          }}
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeWidth="3"
                            d="M6 12h.01m6 0h.01m5.99 0h.01"
                          />
                        </svg>
                        {showDropdown[post.id] && (
                          <div className={classes.optionsDropdown}>
                            {post.user.id === currentUser.uid ||
                            (post.user.isStartup &&
                              post.user.startupOwnerId === currentUser.uid) ? (
                              <>
                                <button
                                  onClick={() => handleEditPost(post)}
                                  className={classes.editPost}
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Edit
                                </button>
                                <button
                                  onClick={() => handleDeletePost(post.id)}
                                  className={classes.deletePost}
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="gray"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Delete
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={() => handleReportPost(post.id)}
                                className={classes.reportPost}
                              >
                                <svg
                                  className={
                                    "w-6 h-6 text-gray-800 dark:text-white"
                                  }
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="gray"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                  />
                                </svg>
                                Report
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {post.text && renderPostText(post)}
                    {renderPostContent(post)}
                    {hasInteractions(post) && (
                      <div className={classes.postInteractionsInfo}>
                        {post.reactions &&
                          Object.keys(post.reactions).length > 0 && (
                            <div
                              className={classes.reactionsSummary}
                              onClick={() =>
                                handleReactionsClick(post.id, post.reactions)
                              }
                            >
                              <div className={classes.reactionStack}>
                                {getTopThreeEmojis(post.reactions)
                                  .reverse()
                                  .map((emoji, index) => (
                                    <div
                                      key={index}
                                      className={classes.reactionEmoji}
                                      style={{
                                        zIndex:
                                          getTopThreeEmojis(post.reactions)
                                            .length - index,
                                        fontSize: "var(--emoji-font-size)",
                                        marginLeft: "var(--emoji-margin-left)",
                                        marginBottom:
                                          "var(--emoji-margin-bottom)",
                                        marginTop: "var(--emoji-margin-top)",
                                      }}
                                    >
                                      {emoji}
                                    </div>
                                  ))}
                              </div>
                              <span
                                className={classes.reactionCount}
                                style={{
                                  marginLeft: "var(--count-margin-left)",
                                }}
                              >
                                {Object.keys(post.reactions).length}
                              </span>
                            </div>
                          )}
                        <div className={classes.rightInteractions}>
                          {post.commentCount > 0 && (
                            <span
                              className={classes.commentCount}
                              onClick={() => handleCommentsCountClick(post.id)}
                            >
                              {post.commentCount} comment
                              {post.commentCount !== 1 ? "s" : ""}
                            </span>
                          )}
                          {post.commentCount > 0 &&
                            post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span className={classes.dot}>•</span>
                            )}
                          {post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span
                                className={classes.repostCount}
                                onClick={() =>
                                  handleRepostsClick(post.id, post.reposts)
                                }
                              >
                                {Object.keys(post.reposts).length} repost
                                {Object.keys(post.reposts).length !== 1
                                  ? "s"
                                  : ""}
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                    <div className={classes.postInteractions}>
                      <StartupInteractionToggle
                        currentUser={{
                          ...currentUser,
                          firstName: post?.user?.firstName,
                          lastName: post?.user?.lastName,
                          bio: post?.user?.bio,
                        }}
                        onStartupSelect={setSelectedStartup}
                        selectedStartup={selectedStartup}
                        currentUserProfileImage={currentUserProfileImage}
                        onModalOpen={() => {
                          setGlobalStartupModalState({
                            isOpen: true,
                            scrollPosition: window.scrollY,
                            sourceLocation: "postInteraction",
                            postId: post.id,
                          });
                        }}
                        onModalClose={() => {
                          setGlobalStartupModalState((prev) => ({
                            ...prev,
                            isOpen: false,
                          }));
                          setTimeout(() => {
                            window.scrollTo(
                              0,
                              globalStartupModalState.scrollPosition
                            );
                          }, 0);
                        }}
                      />
                      <button
                        className={`${classes.interactionButton} ${
                          (selectedStartup &&
                            post.reactions?.[
                              `startup_${selectedStartup.id}`
                            ]) ||
                          (!selectedStartup &&
                            post.reactions?.[currentUser?.uid])
                            ? classes.emojiButton
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedStartup) {
                            if (
                              post.reactions?.[`startup_${selectedStartup.id}`]
                            ) {
                              handleEmojiSelect(
                                post.reactions[`startup_${selectedStartup.id}`],
                                post.id
                              );
                            } else {
                              setEmojiPickerScrollPosition(window.scrollY);
                              setActiveEmojiPicker(
                                activeEmojiPicker === `post_${post.id}`
                                  ? null
                                  : `post_${post.id}`
                              );
                            }
                          } else {
                            if (post.reactions?.[currentUser?.uid]) {
                              handleEmojiSelect(
                                post.reactions[currentUser.uid],
                                post.id
                              );
                            } else {
                              setEmojiPickerScrollPosition(window.scrollY);
                              setActiveEmojiPicker(
                                activeEmojiPicker === `post_${post.id}`
                                  ? null
                                  : `post_${post.id}`
                              );
                            }
                          }
                        }}
                      >
                        {selectedStartup ? (
                          post.reactions?.[`startup_${selectedStartup.id}`] ? (
                            <span>
                              {post.reactions[`startup_${selectedStartup.id}`]}
                            </span>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                              />
                            </svg>
                          )
                        ) : post.reactions?.[currentUser?.uid] ? (
                          <span>{post.reactions[currentUser.uid]}</span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )}
                      </button>
                      <button
                        className={classes.interactionButton}
                        onClick={() => toggleCommentInput(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                          />
                        </svg>
                      </button>
                      <button
                        className={`${classes.interactionButton} ${
                          post.reposts?.[currentUser.uid]
                            ? classes.repostedButton
                            : ""
                        }`}
                        onClick={() => handleRepost(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                          />
                        </svg>
                      </button>
                      <button className={classes.interactionButton}>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                          />
                        </svg>
                      </button>
                    </div>
                    {activeEmojiPicker === `post_${post.id}` && ( // Note the change here to include 'post_' prefix
                      <EmojiPicker
                        onEmojiClick={(emoji) =>
                          handleEmojiSelect(emoji, post.id)
                        }
                        onClose={() => {
                          setActiveEmojiPicker(null);
                          setTimeout(() => {
                            window.scrollTo(0, emojiPickerScrollPosition);
                          }, 0);
                        }}
                      />
                    )}
                    <div className={classes.commentsSection}>
                      {activeCommentPostId === post.id && (
                        <>
                          <CommentInputSection
                            currentUser={currentUser}
                            selectedStartup={selectedStartup}
                            newComment={newComment[post.id] || ""}
                            onCommentChange={(e) =>
                              handleCommentInputChange(e, post.id)
                            }
                            onSubmit={() => handleAddComment(post.id)}
                            commentInputRef={(el) =>
                              (commentInputRef.current[post.id] = el)
                            }
                          />
                          {comments[post.id] &&
                            comments[post.id].length > 0 && (
                              <div className={classes.commentsList}>
                                {renderComments(post.id, post.user.id)}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          {posts.indirectSuggested && posts.indirectSuggested.length > 0 && (
            <div>
              {posts.indirectSuggested.map((post) => (
                <div key={post.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>Suggested</div>
                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={post.user.profileImage || defaultImage}
                        alt=""
                        onClick={() => handleNavigate(post.user.id)}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() => handleNavigate(post.user.id)}
                          >
                            <span className={classes.nameText}>
                              {`${post.user.firstName} ${post.user.lastName}`}
                            </span>
                          </p>
                          {post.user.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.user.connectionType}
                            </span>
                          )}
                        </div>
                        {post.user.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(post.id);
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && (
                        <div className={classes.optionsDropdown}>
                          {post.user.id === currentUser.uid ||
                          (post.user.isStartup &&
                            post.user.startupOwnerId === currentUser.uid) ? (
                            <>
                              <button
                                onClick={() => handleEditPost(post)}
                                className={classes.editPost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeletePost(post.id)}
                                className={classes.deletePost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleReportPost(post.id)}
                              className={classes.reportPost}
                            >
                              <svg
                                className={
                                  "w-6 h-6 text-gray-800 dark:text-white"
                                }
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}
                  {hasInteractions(post) && (
                    <div className={classes.postInteractionsInfo}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(post.reactions)
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(post.reactions)
                                          .length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                      marginBottom:
                                        "var(--emoji-margin-bottom)",
                                      marginTop: "var(--emoji-margin-top)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            <span
                              className={classes.reactionCount}
                              style={{ marginLeft: "var(--count-margin-left)" }}
                            >
                              {Object.keys(post.reactions).length}
                            </span>
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {post.commentCount > 0 && (
                          <span
                            className={classes.commentCount}
                            onClick={() => handleCommentsCountClick(post.id)}
                          >
                            {post.commentCount} comment
                            {post.commentCount !== 1 ? "s" : ""}
                          </span>
                        )}
                        {post.commentCount > 0 &&
                          post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span className={classes.dot}>•</span>
                          )}
                        {post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(post.id, post.reposts)
                              }
                            >
                              {Object.keys(post.reposts).length} repost
                              {Object.keys(post.reposts).length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  <div className={classes.postInteractions}>
                    <StartupInteractionToggle
                      currentUser={{
                        ...currentUser,
                        firstName: post?.user?.firstName,
                        lastName: post?.user?.lastName,
                        bio: post?.user?.bio,
                      }}
                      onStartupSelect={setSelectedStartup}
                      selectedStartup={selectedStartup}
                      currentUserProfileImage={currentUserProfileImage}
                      onModalOpen={() => {
                        setGlobalStartupModalState({
                          isOpen: true,
                          scrollPosition: window.scrollY,
                          sourceLocation: "postInteraction",
                          postId: post.id,
                        });
                      }}
                      onModalClose={() => {
                        setGlobalStartupModalState((prev) => ({
                          ...prev,
                          isOpen: false,
                        }));
                        setTimeout(() => {
                          window.scrollTo(
                            0,
                            globalStartupModalState.scrollPosition
                          );
                        }, 0);
                      }}
                    />
                    <button
                      className={`${classes.interactionButton} ${
                        (selectedStartup &&
                          post.reactions?.[`startup_${selectedStartup.id}`]) ||
                        (!selectedStartup && post.reactions?.[currentUser?.uid])
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() => {
                        if (selectedStartup) {
                          if (
                            post.reactions?.[`startup_${selectedStartup.id}`]
                          ) {
                            handleEmojiSelect(
                              post.reactions[`startup_${selectedStartup.id}`],
                              post.id
                            );
                          } else {
                            setEmojiPickerScrollPosition(window.scrollY);
                            setActiveEmojiPicker(
                              activeEmojiPicker === `post_${post.id}`
                                ? null
                                : `post_${post.id}`
                            );
                          }
                        } else {
                          if (post.reactions?.[currentUser?.uid]) {
                            handleEmojiSelect(
                              post.reactions[currentUser.uid],
                              post.id
                            );
                          } else {
                            setEmojiPickerScrollPosition(window.scrollY);
                            setActiveEmojiPicker(
                              activeEmojiPicker === `post_${post.id}`
                                ? null
                                : `post_${post.id}`
                            );
                          }
                        }
                      }}
                    >
                      {selectedStartup ? (
                        post.reactions?.[`startup_${selectedStartup.id}`] ? (
                          <span>
                            {post.reactions[`startup_${selectedStartup.id}`]}
                          </span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )
                      ) : post.reactions?.[currentUser?.uid] ? (
                        <span>{post.reactions[currentUser.uid]}</span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={classes.interactionButton}
                      onClick={() => toggleCommentInput(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reposts?.[currentUser.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>
                  {activeEmojiPicker === `post_${post.id}` && ( // Note the change here to include 'post_' prefix
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(emoji, post.id)
                      }
                      onClose={() => {
                        setActiveEmojiPicker(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  <div className={classes.commentsSection}>
                    {activeCommentPostId === post.id && (
                      <>
                        <CommentInputSection
                          currentUser={currentUser}
                          selectedStartup={selectedStartup}
                          newComment={newComment[post.id] || ""}
                          onCommentChange={(e) =>
                            handleCommentInputChange(e, post.id)
                          }
                          onSubmit={() => handleAddComment(post.id)}
                          commentInputRef={(el) =>
                            (commentInputRef.current[post.id] = el)
                          }
                        />
                        {comments[post.id] && comments[post.id].length > 0 && (
                          <div className={classes.commentsList}>
                            {renderComments(post.id, post.user.id)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {posts.extendedSuggested && posts.extendedSuggested.length > 0 && (
            <div>
              {posts.extendedSuggested.map((post) => (
                <div key={post.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>Suggested</div>

                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={post.user.profileImage || defaultImage}
                        alt=""
                        onClick={() => handleNavigate(post.user.id)}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() => handleNavigate(post.user.id)}
                          >
                            <span className={classes.nameText}>
                              {`${post.user.firstName} ${post.user.lastName}`}
                            </span>
                          </p>
                          {post.user.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.user.connectionType}
                            </span>
                          )}
                        </div>
                        {post.user.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(post.id);
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && (
                        <div className={classes.optionsDropdown}>
                          {post.user.id === currentUser.uid ? (
                            <>
                              <button
                                onClick={() => handleEditPost(post)}
                                className={classes.editPost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeletePost(post.id)}
                                className={classes.deletePost}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleReportPost(post.id)}
                              className={classes.reportPost}
                            >
                              <svg
                                className={
                                  "w-6 h-6 text-gray-800 dark:text-white"
                                }
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}
                  {hasInteractions(post) && (
                    <div className={classes.postInteractionsInfo}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(post.reactions)
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(post.reactions)
                                          .length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                      marginBottom:
                                        "var(--emoji-margin-bottom)",
                                      marginTop: "var(--emoji-margin-top)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            <span
                              className={classes.reactionCount}
                              style={{ marginLeft: "var(--count-margin-left)" }}
                            >
                              {Object.keys(post.reactions).length}
                            </span>
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {post.commentCount > 0 && (
                          <span
                            className={classes.commentCount}
                            onClick={() => handleCommentsCountClick(post.id)}
                          >
                            {post.commentCount} comment
                            {post.commentCount !== 1 ? "s" : ""}
                          </span>
                        )}
                        {post.commentCount > 0 &&
                          post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span className={classes.dot}>•</span>
                          )}
                        {post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(post.id, post.reposts)
                              }
                            >
                              {Object.keys(post.reposts).length} repost
                              {Object.keys(post.reposts).length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                  <div className={classes.postInteractions}>
                    <StartupInteractionToggle
                      currentUser={{
                        ...currentUser,
                        firstName: post?.user?.firstName,
                        lastName: post?.user?.lastName,
                        bio: post?.user?.bio,
                      }}
                      onStartupSelect={setSelectedStartup}
                      selectedStartup={selectedStartup}
                      currentUserProfileImage={currentUserProfileImage}
                      onModalOpen={() => {
                        setGlobalStartupModalState({
                          isOpen: true,
                          scrollPosition: window.scrollY,
                          sourceLocation: "postInteraction",
                          postId: post.id,
                        });
                      }}
                      onModalClose={() => {
                        setGlobalStartupModalState((prev) => ({
                          ...prev,
                          isOpen: false,
                        }));
                        setTimeout(() => {
                          window.scrollTo(
                            0,
                            globalStartupModalState.scrollPosition
                          );
                        }, 0);
                      }}
                    />
                    <button
                      className={`${classes.interactionButton} ${
                        (selectedStartup &&
                          post.reactions?.[`startup_${selectedStartup.id}`]) ||
                        (!selectedStartup && post.reactions?.[currentUser?.uid])
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() => {
                        if (selectedStartup) {
                          if (
                            post.reactions?.[`startup_${selectedStartup.id}`]
                          ) {
                            handleEmojiSelect(
                              post.reactions[`startup_${selectedStartup.id}`],
                              post.id
                            );
                          } else {
                            setEmojiPickerScrollPosition(window.scrollY);
                            setActiveEmojiPicker(
                              activeEmojiPicker === `post_${post.id}`
                                ? null
                                : `post_${post.id}`
                            );
                          }
                        } else {
                          if (post.reactions?.[currentUser?.uid]) {
                            handleEmojiSelect(
                              post.reactions[currentUser.uid],
                              post.id
                            );
                          } else {
                            setEmojiPickerScrollPosition(window.scrollY);
                            setActiveEmojiPicker(
                              activeEmojiPicker === `post_${post.id}`
                                ? null
                                : `post_${post.id}`
                            );
                          }
                        }
                      }}
                    >
                      {selectedStartup ? (
                        post.reactions?.[`startup_${selectedStartup.id}`] ? (
                          <span>
                            {post.reactions[`startup_${selectedStartup.id}`]}
                          </span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )
                      ) : post.reactions?.[currentUser?.uid] ? (
                        <span>{post.reactions[currentUser.uid]}</span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={classes.interactionButton}
                      onClick={() => toggleCommentInput(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reposts?.[currentUser.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>
                  {activeEmojiPicker === `post_${post.id}` && ( // Note the change here to include 'post_' prefix
                    <EmojiPicker
                      onEmojiClick={(emoji) =>
                        handleEmojiSelect(emoji, post.id)
                      }
                      onClose={() => {
                        setActiveEmojiPicker(null);
                        setTimeout(() => {
                          window.scrollTo(0, emojiPickerScrollPosition);
                        }, 0);
                      }}
                    />
                  )}
                  <div className={classes.commentsSection}>
                    {activeCommentPostId === post.id && (
                      <>
                        <CommentInputSection
                          currentUser={currentUser}
                          selectedStartup={selectedStartup}
                          newComment={newComment[post.id] || ""}
                          onCommentChange={(e) =>
                            handleCommentInputChange(e, post.id)
                          }
                          onSubmit={() => handleAddComment(post.id)}
                          commentInputRef={(el) =>
                            (commentInputRef.current[post.id] = el)
                          }
                        />
                        {comments[post.id] && comments[post.id].length > 0 && (
                          <div className={classes.commentsList}>
                            {renderComments(post.id, post.user.id)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {loading && hasMore && (
            <div className={classes.loadingIndicator}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          )}
          {console.log("Render check:", { hasAttemptedLoad, posts })}
          {hasAttemptedLoad &&
            (!posts ||
              (posts.directAndInteracted.length === 0 &&
                posts.indirectSuggested.length === 0 &&
                posts.extendedSuggested.length === 0)) && (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No posts found</div>
                <div className={classes.text}>
                  Please check your internet connection.
                </div>
                <div
                  className={classes.homeButton}
                  onClick={() => window.location.reload()}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
