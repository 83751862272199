import React, { createContext, useContext, useReducer, useEffect } from "react";
import { firestore, auth } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const NotificationContext = createContext();

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_COUNT":
      return { ...state, count: action.payload };
    case "RESET_COUNT":
      return { ...state, count: 0 };
    default:
      return state;
  }
};

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, { count: 0 });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const notificationsRef = collection(
          firestore,
          `users/${user.uid}/notifications`
        );
        const q = query(notificationsRef, where("isNew", "==", true));

        const notificationListener = onSnapshot(q, (snapshot) => {
          dispatch({ type: "SET_COUNT", payload: snapshot.size });
        });

        return () => notificationListener();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
