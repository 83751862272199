import React, { useState, useRef, useEffect } from "react";
import classes from "./SchoolDropdown.module.css";

const SchoolDropdown = ({
  label,
  selectedSchool,
  onSchoolChange,
  onInputChange,
  disabled,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(selectedSchool ? selectedSchool.name : "");
  }, [selectedSchool]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 50) {
      setInputValue(newValue);
      onInputChange(newValue);
      onSchoolChange({ name: newValue, id: newValue });
    }
  };

  return (
    <div className={classes.customDropdown}>
      <div className={classes.dropdownContainer}>
        <input
          ref={inputRef}
          className={`${classes.dropdownHeader} ${
            disabled ? classes.disabled : ""
          }`}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          disabled={disabled}
          maxLength={50}
        />
      </div>
      <div className={classes.charCount}>{inputValue.length}/50</div>
    </div>
  );
};

export default SchoolDropdown;
