import React, { useState, useRef, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./EditSingleStartupModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import CompanyTypeDropdown from "../Dropdowns/CompanyTypeDropdown";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import monthsList from "../../utils/monthsList";
import { updateStartupUrlSlug } from "../../utils/startupUrlUtils";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const EditSingleStartupModal = ({
  startup,
  onClose,
  onUpdate,
  currentUser,
  isFromStartupPage = false,
}) => {
  const initialState = {
    startupName: startup.startupName || "",
    role: startup.role || "",
    industry: startup.industry || "",
    companyType: startup.companyType || null,
    bio: startup.bio || "",
    link: startup.link || "",
    linkText: startup.linkText || "",
    country: startup.country
      ? { name: startup.country, iso2: startup.countryISO2 }
      : null,
    city: startup.city ? { name: startup.city } : null,
    monthFounded: monthsList.indexOf(startup.monthFounded) + 1,
    yearFounded: startup.yearFounded.toString(),
    startupImage: startup.startupImage || "",
  };

  const [startupName, setStartupName] = useState(initialState.startupName);
  const [role, setRole] = useState(initialState.role);
  const [industry, setIndustry] = useState(initialState.industry);
  const [companyType, setCompanyType] = useState(initialState.companyType);
  const [bio, setBio] = useState(initialState.bio);
  const [link, setLink] = useState(initialState.link);
  const [linkText, setLinkText] = useState(initialState.linkText);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialState.country);
  const [selectedCity, setSelectedCity] = useState(initialState.city);
  const [countryInputValue, setCountryInputValue] = useState(
    initialState.country?.name || ""
  );
  const [cityInputValue, setCityInputValue] = useState(
    initialState.city?.name || ""
  );
  const [monthFounded, setMonthFounded] = useState(initialState.monthFounded);
  const [yearFounded, setYearFounded] = useState(initialState.yearFounded);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(!!initialState.link);
  const [startupImage, setStartupImage] = useState(null);
  const [currentStartupImageUrl, setCurrentStartupImageUrl] = useState(
    initialState.startupImage
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const formRef = useRef(null);
  const cityCache = useRef({});
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  // Form validation effect
  useEffect(() => {
    const isAllFieldsFilled =
      startupName.trim() !== "" &&
      role.trim() !== "" &&
      industry.trim() !== "" &&
      companyType !== null &&
      selectedCountry !== null &&
      selectedCity !== null &&
      monthFounded !== null &&
      yearFounded !== "";

    setIsFormValid(isAllFieldsFilled);
  }, [
    startupName,
    role,
    industry,
    companyType,
    selectedCountry,
    selectedCity,
    monthFounded,
    yearFounded,
  ]);

  // Change detection effect
  useEffect(() => {
    const hasChanged =
      startupName !== initialState.startupName ||
      role !== initialState.role ||
      industry !== initialState.industry ||
      companyType !== initialState.companyType ||
      bio !== initialState.bio ||
      link !== initialState.link ||
      linkText !== initialState.linkText ||
      monthFounded !== initialState.monthFounded ||
      yearFounded !== initialState.yearFounded ||
      selectedCountry?.name !== initialState.country?.name ||
      selectedCity?.name !== initialState.city?.name ||
      startupImage !== null;

    setIsChanged(hasChanged);
  }, [
    startupName,
    role,
    industry,
    companyType,
    bio,
    link,
    linkText,
    monthFounded,
    yearFounded,
    selectedCountry,
    selectedCity,
    startupImage,
  ]);

  // Load countries effect
  useEffect(() => {
    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    loadCountries();
  }, []);

  // Load cities effect
  useEffect(() => {
    const loadCities = async () => {
      if (!selectedCountry) return;

      if (cityCache.current[selectedCountry.iso2]) {
        setCities(cityCache.current[selectedCountry.iso2]);
        return;
      }

      try {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        const citiesList = Array.isArray(data) ? data : [];
        cityCache.current[selectedCountry.iso2] = citiesList;
        setCities(citiesList);
      } catch (error) {
        setCities([]);
      }
    };

    loadCities();
  }, [selectedCountry]);

  const handleStartupNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setStartupName(e.target.value);
    }
  };

  const handleRoleChange = (e) => {
    if (e.target.value.length <= 50) {
      setRole(e.target.value);
    }
  };

  const handleIndustryChange = (e) => {
    if (e.target.value.length <= 50) {
      setIndustry(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      if (!newLink) {
        setLinkText("");
      }
      setIsValidUrl(validateUrl(newLink));
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleStartupImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentStartupImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);
        setStartupImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        console.error("Error compressing image:", error);
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  // In EditSingleStartupModal.js, modify the handleSubmit function:

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }

    setError("");

    try {
      let newStartupImageUrl = currentStartupImageUrl;

      // Handle image update if there's a new image
      if (startupImage && croppedAreaPixels) {
        // Delete old image if it exists and isn't the default image
        if (
          initialState.startupImage &&
          !initialState.startupImage.includes("startupImage.jpg")
        ) {
          try {
            const storage = getStorage();
            const imageUrl = new URL(initialState.startupImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const oldImageRef = ref(storage, imagePath);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error("Error deleting old startup image:", error);
            // Continue with update even if old image deletion fails
          }
        }

        // Upload new image using the same path structure as AddStartupModal
        const croppedImage = await getCroppedImg(
          currentStartupImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const startupImageRef = ref(
          storage,
          `startupImages/${currentUser.uid}/${
            startup.id
          }/profile/${Date.now()}.jpg`
        );
        await uploadBytes(startupImageRef, blob);
        newStartupImageUrl = await getDownloadURL(startupImageRef);
      }

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const startupDocRef = doc(userDocRef, "startups", startup.id);

      // Update URL slug if startup name changed
      let startupUrlSlug = startup.startupUrlSlug;
      if (startupName !== initialState.startupName) {
        startupUrlSlug = await updateStartupUrlSlug(
          currentUser.uid,
          startup.id,
          startupName
        );
      }

      const updatedStartup = {
        startupName: startupName.trim(),
        role: role.trim(),
        industry: industry.trim(),
        companyType,
        bio: bio.trim(),
        link: link.trim(),
        linkText: link.trim() ? linkText.trim() : "",
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
        monthFounded: monthsList[monthFounded - 1],
        yearFounded: Number(yearFounded),
        startupImage: newStartupImageUrl,
        startupUrlSlug,
      };

      await updateDoc(startupDocRef, updatedStartup);
      onUpdate({
        id: startup.id,
        ...updatedStartup,
        membershipType: "owner", // This is crucial - it maintains the startup in the Owner section
      });
      onClose(true);
    } catch (error) {
      console.error("Error updating startup:", error);
      setError("Failed to update startup. Please try again.");
    }
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          {isFromStartupPage ? (
            <div className={classes.cardTitle}>
              Edit startup
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 24 24"
                onClick={() => onClose(false)}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </div>
          ) : (
            <div className={classes.goBackHeader}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={onClose}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 12h14M5 12l4-4m-4 4 4 4"
                />
              </svg>
              <span className={classes.goBack}>Go back</span>
            </div>
          )}
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <div className={classes.imageContainer}>
                <div className={classes.profileImage}>
                  {currentStartupImageUrl && isCropping ? (
                    <div className={classes.cropContainer}>
                      <Cropper
                        image={currentStartupImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        cropSize={{ width: 128, height: 128 }}
                        objectFit="cover"
                      />
                    </div>
                  ) : (
                    <img
                      src={currentStartupImageUrl || defaultImage}
                      alt="Startup"
                      className={classes.startupImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImage;
                      }}
                    />
                  )}
                </div>
                <button
                  type="button"
                  className={classes.uploadButton}
                  onClick={() =>
                    document.getElementById("startupImage").click()
                  }
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="gray"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <input
                type="file"
                id="startupImage"
                accept="image/*"
                onChange={handleStartupImageChange}
                className={classes.profileButton}
              />

              <label htmlFor="startupName" className={classes.label}>
                Name*
              </label>
              <input
                id="startupName"
                name="startupName"
                type="text"
                placeholder="Ex: AirBedAndBreakfast"
                className={classes.userInput}
                value={startupName}
                onChange={handleStartupNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{startupName.length}/50</div>
              <div className={classes.border}></div>
              <label htmlFor="bio" className={classes.label}>
                Tagline
              </label>
              <textarea
                id="bio"
                value={bio}
                onChange={handleBioChange}
                className={classes.textArea}
                placeholder="Ex: Reimagining travel through home sharing"
              />
              <div className={classes.charCount}>{bio.length}/250</div>
              <div className={classes.border}></div>
              <label htmlFor="role" className={classes.label}>
                Role*
              </label>
              <input
                id="role"
                name="role"
                type="text"
                placeholder="Ex: Founder & CEO"
                className={classes.userInput}
                value={role}
                onChange={handleRoleChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{role.length}/50</div>
              <div className={classes.border}></div>

              <label htmlFor="industry" className={classes.label}>
                Industry*
              </label>
              <input
                id="industry"
                name="industry"
                type="text"
                placeholder="Ex: Technology"
                className={classes.userInput}
                value={industry}
                onChange={handleIndustryChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{industry.length}/50</div>
              <div className={classes.border}></div>

              <label htmlFor="companyType" className={classes.label}>
                Company Type*
              </label>
              <CompanyTypeDropdown
                selectedType={companyType}
                onTypeChange={setCompanyType}
                disabled={loading}
              />
              <div className={classes.border}></div>

              <label htmlFor="dateFounded" className={classes.label}>
                Date founded*
              </label>
              <div className={classes.dateContainer}>
                <MonthDropdown
                  selectedMonth={monthFounded}
                  onMonthChange={setMonthFounded}
                  disabled={loading}
                  maxMonth={
                    yearFounded === currentYear.toString() ? currentMonth : 12
                  }
                  placeholder="Month"
                  isEndDate={false}
                  startYear={yearFounded}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                />
                <YearDropdown
                  selectedYear={yearFounded}
                  onYearChange={setYearFounded}
                  disabled={loading}
                  maxYear={currentYear}
                  placeholder="Year"
                />
              </div>
              <div className={classes.border}></div>

              <label htmlFor="country" className={classes.label}>
                Location*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United States"
              />
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: San Francisco"
              />
              <div className={classes.border}></div>

              <label htmlFor="link" className={classes.label}>
                Link
              </label>
              <input
                id="link"
                name="link"
                type="url"
                placeholder="Ex: https://example.com"
                className={classes.userInput}
                value={link}
                onChange={handleLinkChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{link.length}/250</div>

              <label
                htmlFor="linkText"
                className={`${classes.linkTextLabel} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                Link text
              </label>
              <input
                id="linkText"
                name="linkText"
                type="text"
                placeholder="Ex: example.com"
                className={`${classes.userInput} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
                value={linkText}
                onChange={handleLinkTextChange}
                disabled={loading || !isValidUrl}
                style={{ opacity: isValidUrl ? 1 : 0.5 }}
              />
              <div
                className={`${classes.smallPrint} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                <p>Customise how your link will appear (optional).</p>
                <span className={classes.charCount}>{linkText.length}/50</span>
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          {/* <div className={classes.smallPrint}>
            <svg
              className={classes.infoIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                clipRule="evenodd"
              />
            </svg>
            <p>*Required information</p>
          </div> */}
          <button
            type="button"
            className={classes.button}
            disabled={!isFormValid || !isChanged}
            onClick={() => {
              if (formRef.current) {
                formRef.current.dispatchEvent(
                  new Event("submit", { bubbles: true, cancelable: true })
                );
                // Only close if there are no errors and form is valid
                if (!error && isFormValid) {
                  onClose(true);
                }
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSingleStartupModal;
