export const convertUrlsToLinks = (text) => {
  const urlRegex =
    /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
  return text
    .split(urlRegex)
    .map((part, i) =>
      urlRegex.test(part)
        ? `<a href="${
            part.startsWith("http") ? part : "https://" + part
          }" target="_blank" rel="noopener noreferrer" style="color: orangered; font-weight: 900;">${part}</a>`
        : part
    )
    .join("");
};
