import React, { useState, useEffect } from "react";
import { firestore } from "./firebase"; // Adjust the path as needed
import { collection, getCountFromServer } from "firebase/firestore";
import classes from "./WaitlistCount.module.css";

function WaitlistCount() {
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCount() {
      try {
        const coll = collection(firestore, "waitlist");
        const snapshot = await getCountFromServer(coll);
        setCount(snapshot.data().count);
        setLoading(false);
      } catch (e) {
        console.error("Error fetching waitlist count: ", e);
        setError("Failed to fetch waitlist count");
        setLoading(false);
      }
    }

    fetchCount();
  }, []);

  if (loading) return <div>Loading waitlist count...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={classes.waitlistCount}>
      There are currently <span className={classes.number}>{count}</span>people
      on the waitlist.
    </div>
  );
}

export default WaitlistCount;
