import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./People.module.css";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import InterestsDropdown from "../../../components/Dropdowns/InterestsDropdown";
import SkillsDropdown from "../../../components/Dropdowns/SkillsDropdown";
import CompanyDropdown from "../../../components/Dropdowns/CompanyDropdown";
import RoleDropdown from "../../../components/Dropdowns/RoleDropdown";
import SchoolDropdown from "../../../components/Dropdowns/SchoolDropdown";
import DegreeDropdown from "../../../components/Dropdowns/DegreeDropdown";
import SubjectDropdown from "../../../components/Dropdowns/SubjectDropdown";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import TypeDropdown from "../../../components/Dropdowns/TypeDropdown";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import MutualConnectionsModal from "../../../components/Modals/MutualConnectionsModal";
import FollowModal from "../../../components/Modals/FollowModal";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);
const index = searchClient.initIndex("users_index");

function People() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });

  const [showFollowModal, setShowFollowModal] = useState(false);
  const [selectedUserForFollowing, setSelectedUserForFollowing] =
    useState(null);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isInterestsMenuOpen, setIsInterestsMenuOpen] = useState(false);
  const [interests, setInterests] = useState([]);
  const [isSkillsMenuOpen, setIsSkillsMenuOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [isExperienceMenuOpen, setIsExperienceMenuOpen] = useState(false);
  const [company, setCompany] = useState({ object: null, inputValue: "" });
  const [role, setRole] = useState({ object: null, inputValue: "" });
  const [isEducationMenuOpen, setIsEducationMenuOpen] = useState(false);
  const [school, setSchool] = useState({ object: null, inputValue: "" });
  const [degree, setDegree] = useState({ object: null, inputValue: "" });
  const [subject, setSubject] = useState({ object: null, inputValue: "" });
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [country, setCountry] = useState({ object: null, inputValue: "" });
  const [city, setCity] = useState({ object: null, inputValue: "" });
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);
  const [connectionType, setConnectionType] = useState("Extended");
  const [hasSearched, setHasSearched] = useState(false);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [
    mutualConnectionsModalScrollPosition,
    setMutualConnectionsModalScrollPosition,
  ] = useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [isMutualConnectionsModalOpen, setIsMutualConnectionsModalOpen] =
    useState(false);
  const [mutualConnections, setMutualConnections] = useState({});
  const [showMutualConnectionsModal, setShowMutualConnectionsModal] =
    useState(false);
  const [
    selectedUserForMutualConnections,
    setSelectedUserForMutualConnections,
  ] = useState(null);

  const fetchConnectionCount = async (userId) => {
    try {
      const connectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      console.error("Error fetching connection count:", error);
      return 0;
    }
  };

  const handleShowFollowingModal = (userId, e) => {
    e.stopPropagation(); // Add this to prevent event bubbling
    setFollowModalScrollPosition(window.scrollY);
    setSelectedUserForFollowing(userId);
    setShowFollowModal(true);
    setIsFollowModalOpen(true);
  };

  const handleCloseFollowModal = () => {
    setShowFollowModal(false);
    setSelectedUserForFollowing(null);
    setIsFollowModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, followModalScrollPosition);
    }, 0);
  };

  const getConnectionTypePriority = (type) => {
    switch (type) {
      case "Direct":
        return 3;
      case "Indirect":
        return 2;
      case "Extended":
        return 1;
      default:
        return 0;
    }
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const fetchIndirectConnections = async (userId) => {
    const indirectConnections = [];
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsSnapshot = await getDocs(
      query(userConnectionsRef, where("status", "==", "Connected"))
    );

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionsRef = collection(
        firestore,
        `users/${conn.id}/connections`
      );
      const indirectConnectionsSnapshot = await getDocs(
        query(indirectConnectionsRef, where("status", "==", "Connected"))
      );

      for (const indirectConn of indirectConnectionsSnapshot.docs) {
        if (
          indirectConn.id !== userId &&
          !indirectConnections.some((user) => user.objectID === indirectConn.id)
        ) {
          const userDoc = await getDoc(
            doc(firestore, "users", indirectConn.id)
          );
          indirectConnections.push({
            objectID: indirectConn.id,
            ...userDoc.data(),
            connectionType: "Indirect",
          });
        }
      }
    }

    return indirectConnections;
  };

  const fetchUsersByCityOrCountry = async (location, type) => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(
      query(usersRef, where(type, "==", location))
    );
    return usersSnapshot.docs
      .filter((doc) => doc.id !== currentUser.uid)
      .map((doc) => ({
        objectID: doc.id,
        ...doc.data(),
        connectionType: "Extended",
      }));
  };

  const fetchMutualConnections = async (currentUserId, profileUserId) => {
    try {
      const currentUserConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const profileUserConnectionsRef = collection(
        firestore,
        `users/${profileUserId}/connections`
      );

      const currentUserConnectionsQuery = query(
        currentUserConnectionsRef,
        where("status", "==", "Connected")
      );
      const profileUserConnectionsQuery = query(
        profileUserConnectionsRef,
        where("status", "==", "Connected")
      );

      const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
        await Promise.all([
          getDocs(currentUserConnectionsQuery),
          getDocs(profileUserConnectionsQuery),
        ]);

      const currentUserConnections = new Set(
        currentUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );
      const profileUserConnections = new Set(
        profileUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );

      const mutualConnectionIds = [...currentUserConnections].filter((id) =>
        profileUserConnections.has(id)
      );

      const mutualConnectionsData = await Promise.all(
        mutualConnectionIds.map(async (userId) => {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          return {
            id: userId,
            ...userDoc.data(),
            profileImage: userDoc.data().profileImage,
          };
        })
      );

      return mutualConnectionsData;
    } catch (error) {
      console.error("Error fetching mutual connections:", error);
      return [];
    }
  };

  const fetchAllUsers = async (currentUserId) => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    const currentUserConnections = await getDocs(
      collection(firestore, `users/${currentUserId}/connections`)
    );
    const directConnectionIds = new Set(
      currentUserConnections.docs.map((doc) => doc.id)
    );

    return usersSnapshot.docs
      .filter(
        (doc) => doc.id !== currentUserId && !directConnectionIds.has(doc.id)
      )
      .map((doc) => ({
        objectID: doc.id,
        ...doc.data(),
      }));
  };

  const getConnectionType = async (userId, currentUserId) => {
    const currentUserRef = doc(firestore, `users/${currentUserId}`);
    const connectionRef = doc(currentUserRef, `connections/${userId}`);
    const connectionDoc = await getDoc(connectionRef);

    if (connectionDoc.exists() && connectionDoc.data().status === "Connected") {
      return "Direct";
    }

    // Check for indirect connections
    const currentUserConnections = await getDocs(
      collection(currentUserRef, "connections")
    );
    for (const conn of currentUserConnections.docs) {
      if (conn.data().status === "Connected") {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${userId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }
    }

    return "Extended";
  };

  const fetchInitialResults = useCallback(async () => {
    if (!currentUser) return;

    setIsInitialLoading(true);
    try {
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      const userData = userDoc.data();
      const userCity = userData.city;
      const userCountry = userData.country;

      let resultsToShow = [];

      // Fetch all users
      const allUsers = await fetchAllUsers(currentUser.uid);

      // Process all users
      const processedUsers = await Promise.all(
        allUsers.map(async (user) => {
          const connectionType = await getConnectionType(
            user.objectID,
            currentUser.uid
          );
          const connectionsCount = await fetchConnectionCount(user.objectID);
          const userDoc = await getDoc(doc(firestore, "users", user.objectID));
          const userData = userDoc.data();
          const followingCount = userData.followingCount || 0;
          let mutualConnectionsData = [];

          if (connectionType !== "Direct") {
            mutualConnectionsData = await fetchMutualConnections(
              currentUser.uid,
              user.objectID
            );
          }

          return {
            ...user,
            connectionType,
            connectionsCount,
            followingCount,
            mutualConnectionsData,
            mutualConnectionsCount: mutualConnectionsData.length,
          };
        })
      );

      // Separate Direct, Indirect and Extended connections
      const directConnections = processedUsers.filter(
        (user) => user.connectionType === "Direct"
      );
      const indirectConnections = processedUsers.filter(
        (user) => user.connectionType === "Indirect"
      );
      const extendedConnections = processedUsers.filter(
        (user) => user.connectionType === "Extended"
      );

      // Enhanced sort function
      const sortConnections = (a, b) => {
        // First, prioritize by connection type
        if (a.connectionType !== b.connectionType) {
          const typePriority = { Direct: 3, Indirect: 2, Extended: 1 };
          return (
            typePriority[b.connectionType] - typePriority[a.connectionType]
          );
        }

        // Then, apply location hierarchy
        if (a.country === userCountry && b.country === userCountry) {
          if (a.city === userCity && b.city === userCity) {
            // Both users are in the same city and country as the current user
            if (a.mutualConnectionsCount !== b.mutualConnectionsCount) {
              return b.mutualConnectionsCount - a.mutualConnectionsCount;
            }
            return b.connectionsCount - a.connectionsCount;
          } else if (a.city === userCity) {
            return -1;
          } else if (b.city === userCity) {
            return 1;
          }
          // Same country, different cities
          if (a.mutualConnectionsCount !== b.mutualConnectionsCount) {
            return b.mutualConnectionsCount - a.mutualConnectionsCount;
          }
          return b.connectionsCount - a.connectionsCount;
        } else if (a.country === userCountry) {
          return -1;
        } else if (b.country === userCountry) {
          return 1;
        }

        // If locations are different, prioritize by mutual connections count
        if (a.mutualConnectionsCount !== b.mutualConnectionsCount) {
          return b.mutualConnectionsCount - a.mutualConnectionsCount;
        }

        // Finally, sort by connection count
        return b.connectionsCount - a.connectionsCount;
      };

      // Sort each connection type
      const sortedDirectConnections = directConnections.sort(sortConnections);
      const sortedIndirectConnections =
        indirectConnections.sort(sortConnections);
      const sortedExtendedConnections =
        extendedConnections.sort(sortConnections);

      // Combine results, showing Direct connections first, then Indirect, then Extended
      resultsToShow = [
        ...sortedDirectConnections,
        ...sortedIndirectConnections,
        ...sortedExtendedConnections,
      ];

      const mutualConnectionsObj = {};
      for (const user of resultsToShow) {
        if (user.connectionType !== "Direct") {
          mutualConnectionsObj[user.objectID] = user.mutualConnectionsData;
        }
      }

      setInitialResults(resultsToShow);
      setMutualConnections(mutualConnectionsObj);
    } catch (error) {
      console.error("Error fetching initial results:", error);
    } finally {
      setIsInitialLoading(false);
    }
  }, [
    currentUser,
    fetchAllUsers,
    getConnectionType,
    fetchConnectionCount,
    fetchMutualConnections,
  ]);

  useEffect(() => {
    if (currentUser && initialResults.length === 0) {
      fetchInitialResults();
    }
  }, [currentUser, fetchInitialResults, initialResults.length]);

  const handleTypeChange = (newType) => {
    setConnectionType(newType);
    setIsChanged(true);
  };

  const toggleTypeMenu = () => {
    setIsTypeMenuOpen(!isTypeMenuOpen);
  };

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error loading countries:", error);
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      console.error("Error loading cities:", error);
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  useEffect(() => {}, [interests]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const checkIfAnyInputHasValue = () => {
    return (
      nameInput.trim() !== "" ||
      interests.length > 0 ||
      skills.length > 0 ||
      company.inputValue.trim() !== "" ||
      role.inputValue.trim() !== "" ||
      school.inputValue.trim() !== "" ||
      degree.inputValue.trim() !== "" ||
      subject.inputValue.trim() !== "" ||
      !!selectedCountry ||
      !!selectedCity ||
      connectionType !== "Extended"
    );
  };

  useEffect(() => {
    setIsChanged(checkIfAnyInputHasValue());
  }, [
    nameInput,
    interests,
    skills,
    company,
    role,
    school,
    degree,
    subject,
    selectedCountry,
    selectedCity,
    connectionType,
  ]);

  const handleCountryInputChange = (inputValue) => {
    setCountry((prev) => ({ ...prev, inputValue }));
  };

  const handleCityInputChange = (inputValue) => {
    setCity((prev) => ({ ...prev, inputValue }));
  };

  const toggleInterestsMenu = () => {
    setIsInterestsMenuOpen(!isInterestsMenuOpen);
  };

  const handleInterestsChange = (newInterests) => {
    setInterests(newInterests);
    setIsChanged(true);
  };

  const toggleSkillsMenu = () => {
    setIsSkillsMenuOpen(!isSkillsMenuOpen);
  };

  const handleSkillsChange = (newSkills) => {
    setSkills(newSkills);
    setIsChanged(true);
  };

  const toggleExperienceMenu = () => {
    setIsExperienceMenuOpen(!isExperienceMenuOpen);
  };

  const handleCompanyChange = (newCompany) => {
    setCompany({
      object: newCompany,
      inputValue: newCompany ? newCompany.name.slice(0, 50) : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleRoleChange = (newRole) => {
    setRole({
      object: newRole,
      inputValue: newRole ? newRole.name.slice(0, 50) : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCompanyInputChange = (inputValue) => {
    setCompany((prev) => ({ ...prev, inputValue: inputValue.slice(0, 50) }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleRoleInputChange = (inputValue) => {
    setRole((prev) => ({ ...prev, inputValue: inputValue.slice(0, 50) }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const toggleEducationMenu = () => {
    setIsEducationMenuOpen(!isEducationMenuOpen);
  };

  const handleSchoolChange = (newSchool) => {
    setSchool({
      object: newSchool,
      inputValue: newSchool ? newSchool.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleDegreeChange = (newDegree) => {
    setDegree({
      object: newDegree,
      inputValue: newDegree ? newDegree.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSubjectChange = (newSubject) => {
    setSubject({
      object: newSubject,
      inputValue: newSubject ? newSubject.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSchoolInputChange = (inputValue) => {
    setSchool((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleDegreeInputChange = (inputValue) => {
    setDegree((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSubjectInputChange = (inputValue) => {
    setSubject((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(value.trim() !== "");
  };

  const handleUserClick = (user) => {
    // Save the current state to localStorage
    localStorage.setItem(
      "peoplePageState",
      JSON.stringify({
        nameInput,
        interests,
        skills,
        company,
        role,
        school,
        degree,
        subject,
        selectedCountry,
        selectedCity,
        connectionType,
        searchResults,
        hasSearched,
        scrollPosition: window.scrollY,
        isReturning: true,
        mutualConnections, // Add this line
      })
    );
    navigate(`/${user.urlSlug}`, {
      state: { userData: { id: user.objectID, ...user } },
    });
  };

  const handleInputChange = (inputValue, type) => {
    const currentValues = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    const newValues = currentValues.map((name) => ({ name, id: name }));

    if (type === "skills") {
      setSkills(newValues);
    } else if (type === "interests") {
      setInterests(newValues);
    }

    setIsChanged(true);
  };

  useEffect(() => {
    const testSearch = async () => {
      try {
        const { hits } = await index.search("");
        console.log("Test search results:", hits);
      } catch (error) {
        console.error("Error in test search:", error);
      }
    };
    testSearch();
  }, []);

  const scoreMatch = (
    user,
    searchInterests,
    searchSkills,
    company,
    role,
    school,
    degree,
    subject,
    searchCountry,
    searchCity
  ) => {
    let matches = 0;
    const nameParts = nameInput.toLowerCase().trim().split(" ");

    if (nameInput) {
      if (user.firstName.toLowerCase().trim().includes(nameParts[0])) matches++;
      if (
        user.lastName
          .toLowerCase()
          .trim()
          .includes(nameParts[1] || "")
      )
        matches++;
      if (
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .trim()
          .includes(nameInput.toLowerCase().trim())
      )
        matches++;
    }

    const userInterests = (user.interests || []).map((i) =>
      i.toLowerCase().trim()
    );
    searchInterests.forEach((interest) => {
      if (
        userInterests.some((ui) =>
          ui.includes(interest.name.toLowerCase().trim())
        )
      ) {
        matches++;
      }
    });

    const userSkills = (user.skills || []).map((s) => s.toLowerCase().trim());
    searchSkills.forEach((skill) => {
      if (
        userSkills.some((us) => us.includes(skill.name.toLowerCase().trim()))
      ) {
        matches++;
      }
    });

    if (user.experiences) {
      user.experiences.forEach((exp) => {
        if (
          company.object &&
          exp.organisation
            .toLowerCase()
            .trim()
            .includes(company.object.name.toLowerCase().trim())
        )
          matches++;
        if (
          role.object &&
          exp.role
            .toLowerCase()
            .trim()
            .includes(role.object.name.toLowerCase().trim())
        )
          matches++;
      });
    }

    if (user.education) {
      user.education.forEach((edu) => {
        if (
          school.object &&
          edu.school
            .toLowerCase()
            .trim()
            .includes(school.object.name.toLowerCase().trim())
        )
          matches++;
        if (
          degree.object &&
          edu.degree
            .toLowerCase()
            .trim()
            .includes(degree.object.name.toLowerCase().trim())
        )
          matches++;
        if (
          subject.object &&
          edu.subject
            .toLowerCase()
            .trim()
            .includes(subject.object.name.toLowerCase().trim())
        )
          matches++;
      });
    }

    if (
      searchCountry &&
      user.country.toLowerCase().trim() ===
        searchCountry.name.toLowerCase().trim()
    )
      matches++;
    if (
      searchCity &&
      user.city.toLowerCase().trim() === searchCity.name.toLowerCase().trim()
    )
      matches++;

    return matches;
  };

  const handleSearch = useCallback(async () => {
    if (!isChanged) return;
    setIsSearching(true);
    setHasSearched(true);
    try {
      const searchParams = {
        hitsPerPage: 1000,
        attributesToRetrieve: [
          "firstName",
          "lastName",
          "bio",
          "city",
          "country",
          "profileImage",
          "interests",
          "skills",
          "education",
          "experiences",
          "connectionsCount",
        ],
      };

      const filterConditions = [];
      const orConditions = [];

      if (nameInput) {
        searchParams.query = nameInput;
      }

      if (interests.length > 0) {
        const interestFilters = interests.map((i) => `interests:"${i.name}"`);
        orConditions.push(`(${interestFilters.join(" OR ")})`);
      }

      if (skills.length > 0) {
        const skillFilters = skills.map((s) => `skills:"${s.name}"`);
        orConditions.push(`(${skillFilters.join(" OR ")})`);
      }

      if (school.object && school.object.name) {
        orConditions.push(`education.school:"${school.object.name}"`);
      }

      if (degree.object && degree.object.name) {
        orConditions.push(`education.degree:"${degree.object.name}"`);
      }

      if (subject.object && subject.object.name) {
        orConditions.push(`education.subject:"${subject.object.name}"`);
      }

      if (company.object && company.object.name) {
        orConditions.push(`experiences.organisation:"${company.object.name}"`);
      }

      if (role.object && role.object.name) {
        orConditions.push(`experiences.role:"${role.object.name}"`);
      }

      if (selectedCountry) {
        orConditions.push(`country:"${selectedCountry.name}"`);
      }

      if (selectedCity) {
        orConditions.push(`city:"${selectedCity.name}"`);
      }

      if (orConditions.length > 0) {
        filterConditions.push(`(${orConditions.join(" OR ")})`);
      }

      if (filterConditions.length > 0) {
        searchParams.filters = filterConditions.join(" AND ");
      }

      let { hits } = await index.search(searchParams.query || "", searchParams);

      hits = hits.filter((hit) => hit.objectID !== currentUser.uid);

      const updatedHits = await Promise.all(
        hits.map(async (hit) => {
          const userDoc = await getDoc(doc(firestore, "users", hit.objectID));
          const userData = userDoc.data();
          const connectionType = await getConnectionType(
            hit.objectID,
            currentUser.uid
          );
          const connectionsCount = await fetchConnectionCount(hit.objectID);
          const mutualConnectionsData = await fetchMutualConnections(
            currentUser.uid,
            hit.objectID
          );

          const connectionDoc = await getDoc(
            doc(
              firestore,
              `users/${currentUser.uid}/connections/${hit.objectID}`
            )
          );
          const connectedAt = connectionDoc.exists()
            ? connectionDoc.data().connectedAt?.toDate()
            : null;

          const matches = scoreMatch(
            { ...hit, ...userData },
            interests,
            skills,
            company,
            role,
            school,
            degree,
            subject,
            selectedCountry,
            selectedCity
          );

          return {
            ...hit,
            ...userData,
            connectionType,
            connectionsCount,
            mutualConnectionsData,
            connectedAt,
            matches,
          };
        })
      );

      const filteredHits = updatedHits.filter(
        (user) =>
          connectionType === "Extended" ||
          user.connectionType === connectionType
      );

      const sortedResults = filteredHits.sort((a, b) => {
        if (b.matches !== a.matches) {
          return b.matches - a.matches;
        }

        if (a.connectionType !== b.connectionType) {
          const typePriority = { Direct: 3, Indirect: 2, Extended: 1 };
          return (
            typePriority[b.connectionType] - typePriority[a.connectionType]
          );
        }

        if (
          a.country === currentUser.country &&
          b.country === currentUser.country
        ) {
          if (a.city === currentUser.city && b.city === currentUser.city) {
            if (
              a.mutualConnectionsData.length !== b.mutualConnectionsData.length
            ) {
              return (
                b.mutualConnectionsData.length - a.mutualConnectionsData.length
              );
            }
            return b.connectionsCount - a.connectionsCount;
          } else if (a.city === currentUser.city) {
            return -1;
          } else if (b.city === currentUser.city) {
            return 1;
          }
          if (
            a.mutualConnectionsData.length !== b.mutualConnectionsData.length
          ) {
            return (
              b.mutualConnectionsData.length - a.mutualConnectionsData.length
            );
          }
          return b.connectionsCount - a.connectionsCount;
        } else if (a.country === currentUser.country) {
          return -1;
        } else if (b.country === currentUser.country) {
          return 1;
        }

        if (a.mutualConnectionsData.length !== b.mutualConnectionsData.length) {
          return (
            b.mutualConnectionsData.length - a.mutualConnectionsData.length
          );
        }

        return b.connectionsCount - a.connectionsCount;
      });

      setSearchResults(sortedResults);

      const mutualConnectionsObj = {};
      sortedResults.forEach((user) => {
        mutualConnectionsObj[user.objectID] = user.mutualConnectionsData;
      });
      setMutualConnections(mutualConnectionsObj);
    } catch (error) {
      console.error("Error performing search:", error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    nameInput,
    interests,
    skills,
    school,
    degree,
    subject,
    company,
    role,
    selectedCountry,
    selectedCity,
    currentUser,
    connectionType,
    getConnectionType,
    fetchConnectionCount,
    fetchMutualConnections,
    scoreMatch,
    isChanged,
  ]);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      // We use debounce to avoid excessive updates
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentState = JSON.parse(
          localStorage.getItem("peoplePageState") || "{}"
        );
        localStorage.setItem(
          "peoplePageState",
          JSON.stringify({
            ...currentState,
            scrollPosition: window.scrollY,
          })
        );
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (isSearching) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSearching, handleSearch]);

  const triggerSearch = () => {
    if (isChanged) {
      setIsSearching(true);
      handleSearch();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser({ uid: user.uid });
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserData(userData);

        // Fetch connections
        const connectionsSnapshot = await getDocs(
          collection(firestore, "users", uid, "connections")
        );
        const connections = connectionsSnapshot.docs.map((doc) => doc.id);
        setCurrentUser({ uid, connections });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const userImage = userData.profileImage || profileImage;

  const shouldShowMatchTally = () => {
    // Only show tally if we have searched AND have search criteria beyond just Type or Name
    return (
      hasSearched &&
      !isSearching &&
      (interests.length > 0 ||
        skills.length > 0 ||
        company.object ||
        role.object ||
        school.object ||
        degree.object ||
        subject.object ||
        selectedCountry ||
        selectedCity)
    );
  };

  const handleOpenConnectionsModal = (userId) => {
    setConnectionsModalScrollPosition(window.scrollY);
    setSelectedUserId(userId);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleCloseConnectionsModal = () => {
    setShowConnectionsModal(false);
    setSelectedUserId(null);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  const handleOpenMutualConnectionsModal = (userId) => {
    setMutualConnectionsModalScrollPosition(window.scrollY);
    setSelectedUserForMutualConnections(userId);
    setShowMutualConnectionsModal(true);
    setIsMutualConnectionsModalOpen(true);
  };

  const handleCloseMutualConnectionsModal = () => {
    setShowMutualConnectionsModal(false);
    setSelectedUserForMutualConnections(null);
    setIsMutualConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, mutualConnectionsModalScrollPosition);
    }, 0);
  };

  useEffect(() => {
    const savedState = localStorage.getItem("peoplePageState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      console.log("Restored state:", parsedState);
      setNameInput(parsedState.nameInput || "");
      setInterests(parsedState.interests || []);
      setSkills(parsedState.skills || []);
      setCompany(parsedState.company || { object: null, inputValue: "" });
      setRole(parsedState.role || { object: null, inputValue: "" });
      setSchool(parsedState.school || { object: null, inputValue: "" });
      setDegree(parsedState.degree || { object: null, inputValue: "" });
      setSubject(parsedState.subject || { object: null, inputValue: "" });
      setSelectedCountry(parsedState.selectedCountry || null);
      setSelectedCity(parsedState.selectedCity || null);
      setConnectionType(parsedState.connectionType || "Extended");
      setSearchResults(parsedState.searchResults || []);
      setHasSearched(parsedState.hasSearched || false);
      setMutualConnections(parsedState.mutualConnections || {});

      // Restore the scroll position
      if (parsedState.isReturning && parsedState.scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, parsedState.scrollPosition);
        }, 0);
      }

      // Clear the saved state
      localStorage.removeItem("peoplePageState");
    } else {
      console.log("No saved state found");
    }
  }, []);

  const handleRefresh = () => {
    setNameInput("");
    setInterests([]);
    setSkills([]);
    setCompany({ object: null, inputValue: "" });
    setRole({ object: null, inputValue: "" });
    setSchool({ object: null, inputValue: "" });
    setDegree({ object: null, inputValue: "" });
    setSubject({ object: null, inputValue: "" });
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setConnectionType("Extended");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    fetchInitialResults();
  };

  return (
    <div
      className={`${classes.page} ${
        isConnectionsModalOpen ||
        isMutualConnectionsModalOpen ||
        isFollowModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={{
        top: isConnectionsModalOpen
          ? `-${connectionsModalScrollPosition}px`
          : isMutualConnectionsModalOpen
          ? `-${mutualConnectionsModalScrollPosition}px`
          : isFollowModalOpen // Add this line
          ? `-${followModalScrollPosition}px` // Add this line
          : "",
        position:
          isConnectionsModalOpen ||
          isMutualConnectionsModalOpen ||
          isFollowModalOpen // Add this line
            ? "fixed"
            : "",
        width: "100%",
      }}
    >
      {isMobile ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      {showFollowModal && (
        <FollowModal
          onClose={handleCloseFollowModal}
          currentUserId={selectedUserForFollowing}
          showDropdown={false}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          onClose={handleCloseConnectionsModal}
          currentUserId={selectedUserId}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={false}
          showConnectionDate={false}
        />
      )}
      {showMutualConnectionsModal && (
        <MutualConnectionsModal
          onClose={handleCloseMutualConnectionsModal}
          currentUserId={selectedUserForMutualConnections}
          loggedInUserId={currentUser?.uid}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Filters
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="email" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Ex: Ada Lovelace"
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {showFilters && (
                <>
                  <div className={classes.filters}>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Connection</div>
                      <div>
                        <TypeDropdown
                          selectedType={connectionType}
                          onTypeChange={handleTypeChange}
                        />
                      </div>
                    </div>
                    <div className={classes.bottom}>
                      <div htmlFor="country" className={classes.filterLabel}>
                        Country
                      </div>
                      <CustomDropdown
                        options={countries}
                        selectedValue={selectedCountry}
                        onChange={handleCountryChange}
                        inputValue={countryInputValue}
                        setInputValue={setCountryInputValue}
                        disabled={loading}
                        placeholder="Ex: United Kingdom"
                      />
                      <div
                        htmlFor="city"
                        className={`${classes.filterLabel} ${
                          !selectedCountry || loading ? classes.disabled : ""
                        }`}
                      >
                        City
                      </div>
                      <CustomDropdown
                        options={cities}
                        selectedValue={selectedCity}
                        onChange={handleCityChange}
                        inputValue={cityInputValue}
                        setInputValue={setCityInputValue}
                        disabled={!selectedCountry || loading}
                        placeholder="Ex: London"
                      />
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Skills</div>
                      <SkillsDropdown
                        selectedSkills={skills}
                        onSkillsChange={handleSkillsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "skills")
                        }
                        disabled={loading}
                        placeholder="Ex: HTML, CSS, Javascript"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          className={classes.infoIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Skills must be separated with commas</p>
                      </div>
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Interests</div>
                      <InterestsDropdown
                        selectedInterests={interests}
                        onInterestsChange={handleInterestsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "interests")
                        }
                        disabled={loading}
                        placeholder="Ex: Skateboarding, Reading"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          className={classes.infoIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Interests must be separated with commas</p>
                      </div>
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Organisation</div>
                      <CompanyDropdown
                        selectedCompany={company.object}
                        onCompanyChange={handleCompanyChange}
                        onInputChange={handleCompanyInputChange}
                        inputValue={company.inputValue}
                        disabled={loading}
                        placeholder="Ex: Apple"
                      />
                      <div className={classes.charCount}>
                        {company.inputValue.length}/50
                      </div>
                      <div className={classes.organisationLabel}>Role</div>
                      <RoleDropdown
                        selectedRole={role.object}
                        onRoleChange={handleRoleChange}
                        onInputChange={handleRoleInputChange}
                        inputValue={role.inputValue}
                        disabled={loading}
                        placeholder="Ex: Software Engineer"
                      />
                      <div className={classes.roleCharCount}>
                        {role.inputValue.length}/50
                      </div>
                    </div>
                    <div className={classes.bottom}>
                      <div className={classes.filterLabel}>School</div>
                      <SchoolDropdown
                        selectedSchool={school.object}
                        onSchoolChange={handleSchoolChange}
                        onInputChange={handleSchoolInputChange}
                        inputValue={school.inputValue}
                        disabled={loading}
                        placeholder="Ex: London South Bank University"
                      />
                      <div className={classes.filterLabel}>Degree</div>
                      <DegreeDropdown
                        selectedDegree={degree.object}
                        onDegreeChange={handleDegreeChange}
                        onInputChange={handleDegreeInputChange}
                        inputValue={degree.inputValue}
                        disabled={loading}
                        placeholder="Ex: Bachelor of Science (BSc)"
                      />
                      <div className={classes.filterLabel}>Subject</div>
                      <SubjectDropdown
                        selectedSubject={subject.object}
                        onSubjectChange={handleSubjectChange}
                        onInputChange={handleSubjectInputChange}
                        inputValue={subject.inputValue}
                        disabled={loading}
                        placeholder="Ex: Product Design"
                      />
                    </div>
                  </div>
                </>
              )}

              <button
                type="button"
                className={`${classes.button} ${
                  isSearching ? classes.loading : ""
                }`}
                disabled={!isChanged || isSearching}
                onClick={() => {
                  console.log("Search button clicked");
                  triggerSearch();
                }}
              >
                {isSearching ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  <>Search</>
                )}
              </button>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((user) => (
                <div
                  key={user.objectID}
                  className={classes.profileCard}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserClick(user);
                  }}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img src={user.profileImage || profileImage} alt="" />
                    </div>
                    {shouldShowMatchTally() && hasSearched && !isSearching && (
                      <div className={classes.matchTally}>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.matchCount}>
                          {user.matches}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={classes.profileTitle}>
                    <span className={classes.spacing}>{user.firstName}</span>
                    <span>{user.lastName}</span>
                    <span className={classes.connectionType}>
                      • {user.connectionType}
                    </span>
                  </div>
                  <div>
                    <div className={classes.bio}>{user.bio}</div>
                    <div className={classes.location}>
                      {user.city}, {user.country}
                    </div>
                    {user.link && (
                      <div className={classes.link}>
                        <a
                          href={user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user.linkText || user.link}
                        </a>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                    <div className={classes.data}>
                      <span
                        className={classes.numbers}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenConnectionsModal(user.objectID);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {user.connectionsCount}{" "}
                        {user.connectionsCount === 1
                          ? "Connection"
                          : "Connections"}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span
                        className={classes.numbers}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShowFollowingModal(user.objectID, e);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {user.followingCount || 0} Following
                      </span>
                    </div>
                    {mutualConnections[user.objectID] &&
                      mutualConnections[user.objectID].length > 0 && (
                        <div
                          className={classes.mutualConnections}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenMutualConnectionsModal(user.objectID);
                          }}
                        >
                          <div
                            className={`${classes.mutualConnectionsImages} ${
                              mutualConnections[user.objectID].length === 1
                                ? classes.singleImage
                                : classes.multipleImages
                            }`}
                          >
                            {mutualConnections[user.objectID].length >= 1 && (
                              <img
                                src={
                                  mutualConnections[user.objectID][0]
                                    .profileImage || profileImage
                                }
                                alt=""
                                className={classes.mutualConnectionImage}
                              />
                            )}
                            {mutualConnections[user.objectID].length >= 2 && (
                              <img
                                src={
                                  mutualConnections[user.objectID][1]
                                    .profileImage || profileImage
                                }
                                alt=""
                                className={`${classes.mutualConnectionImage} ${classes.secondImage}`}
                              />
                            )}
                          </div>
                          <p className={classes.mutualConnectionText}>
                            {mutualConnections[user.objectID].length === 1 && (
                              <>
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][0]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][0].lastName}
                                </span>{" "}
                                is a mutual connection
                              </>
                            )}
                            {mutualConnections[user.objectID].length === 2 && (
                              <>
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][0]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][0].lastName}
                                </span>{" "}
                                and{" "}
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][1]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][1].lastName}
                                </span>{" "}
                                are mutual connections
                              </>
                            )}
                            {mutualConnections[user.objectID].length === 3 && (
                              <>
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][0]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][0].lastName}
                                </span>
                                ,{" "}
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][1]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][1].lastName}
                                </span>
                                , and{" "}
                                <span
                                  className={classes.mutualConnectionNumber}
                                >
                                  1
                                </span>{" "}
                                other mutual connection
                              </>
                            )}
                            {mutualConnections[user.objectID].length > 3 && (
                              <>
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][0]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][0].lastName}
                                </span>
                                ,{" "}
                                <span className={classes.boldName}>
                                  {
                                    mutualConnections[user.objectID][1]
                                      .firstName
                                  }{" "}
                                  {mutualConnections[user.objectID][1].lastName}
                                </span>
                                , and{" "}
                                <span
                                  className={classes.mutualConnectionNumber}
                                >
                                  {mutualConnections[user.objectID].length - 2}
                                </span>{" "}
                                other mutual connections
                              </>
                            )}
                          </p>
                        </div>
                      )}{" "}
                    <button
                      className={classes.viewProfileButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUserClick(user);
                      }}
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      View
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : initialResults.length > 0 ? (
            initialResults.map((user) => (
              <div
                key={user.objectID}
                className={classes.profileCard}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserClick(user);
                }}
              >
                {user.connectionType !== "Direct" &&
                  user.connectionType !== "You" && (
                    <div className={classes.reactionHeader}>Suggested</div>
                  )}
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={user.profileImage || profileImage} alt="" />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  <span className={classes.spacing}>{user.firstName}</span>
                  <span>{user.lastName}</span>
                  <span className={classes.connectionType}>
                    • {user.connectionType}
                  </span>
                </div>
                <div>
                  <div className={classes.bio}>{user.bio}</div>
                  <div className={classes.location}>
                    {user.city}, {user.country}
                  </div>
                  {user.link && (
                    <div className={classes.link}>
                      <a
                        href={user.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {user.linkText || user.link}
                      </a>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangeRed"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.data}>
                    <span
                      className={classes.numbers}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenConnectionsModal(user.objectID);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {user.connectionsCount}{" "}
                      {user.connectionsCount === 1
                        ? "Connection"
                        : "Connections"}
                    </span>
                    <span className={classes.dot}>•</span>
                    <span
                      className={classes.numbers}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowFollowingModal(user.objectID, e);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {user.followingCount || 0} Following
                    </span>
                  </div>
                  {mutualConnections[user.objectID] &&
                    mutualConnections[user.objectID].length > 0 && (
                      <div
                        className={classes.mutualConnections}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenMutualConnectionsModal(user.objectID);
                        }}
                      >
                        <div
                          className={`${classes.mutualConnectionsImages} ${
                            mutualConnections[user.objectID].length === 1
                              ? classes.singleImage
                              : classes.multipleImages
                          }`}
                        >
                          {mutualConnections[user.objectID].length >= 1 && (
                            <img
                              src={
                                mutualConnections[user.objectID][0]
                                  .profileImage || profileImage
                              }
                              alt=""
                              className={classes.mutualConnectionImage}
                            />
                          )}
                          {mutualConnections[user.objectID].length >= 2 && (
                            <img
                              src={
                                mutualConnections[user.objectID][1]
                                  .profileImage || profileImage
                              }
                              alt=""
                              className={`${classes.mutualConnectionImage} ${classes.secondImage}`}
                            />
                          )}
                        </div>
                        <p className={classes.mutualConnectionText}>
                          {mutualConnections[user.objectID].length === 1 && (
                            <>
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][0].firstName}{" "}
                                {mutualConnections[user.objectID][0].lastName}
                              </span>{" "}
                              is a mutual connection
                            </>
                          )}
                          {mutualConnections[user.objectID].length === 2 && (
                            <>
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][0].firstName}{" "}
                                {mutualConnections[user.objectID][0].lastName}
                              </span>{" "}
                              and{" "}
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][1].firstName}{" "}
                                {mutualConnections[user.objectID][1].lastName}
                              </span>{" "}
                              are mutual connections
                            </>
                          )}
                          {mutualConnections[user.objectID].length === 3 && (
                            <>
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][0].firstName}{" "}
                                {mutualConnections[user.objectID][0].lastName}
                              </span>
                              ,{" "}
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][1].firstName}{" "}
                                {mutualConnections[user.objectID][1].lastName}
                              </span>
                              , and{" "}
                              <span className={classes.mutualConnectionNumber}>
                                1
                              </span>{" "}
                              other mutual connection
                            </>
                          )}
                          {mutualConnections[user.objectID].length > 3 && (
                            <>
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][0].firstName}{" "}
                                {mutualConnections[user.objectID][0].lastName}
                              </span>
                              ,{" "}
                              <span className={classes.boldName}>
                                {mutualConnections[user.objectID][1].firstName}{" "}
                                {mutualConnections[user.objectID][1].lastName}
                              </span>
                              , and{" "}
                              <span className={classes.mutualConnectionNumber}>
                                {mutualConnections[user.objectID].length - 2}
                              </span>{" "}
                              other mutual connections
                            </>
                          )}
                        </p>
                      </div>
                    )}
                </div>
                <button
                  className={classes.viewProfileButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserClick(user);
                  }}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  View
                </button>
              </div>
            ))
          ) : (
            <div className={classes.initialState}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          )}
        </div>

        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default People;
