import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  memo,
} from "react";
import defaultImage from "../../assets/icons/profileImage.jpg";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import RepostsModal from "../../components/Modals/RepostsModal";
import PostActionPopup from "../Posts/PostActionPopup";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import {
  collection,
  addDoc,
  increment,
  query,
  limit,
  where,
  getDocs,
  orderBy,
  doc,
  writeBatch,
  getDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./AllComments.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import {
  getStorage,
  ref,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import PostModal from "../../components/Modals/PostModal";

function AllComments() {
  const location = useLocation();
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [mainEmojiPickerOpen, setMainEmojiPickerOpen] = useState(null);
  const [isEditingComplete, setIsEditingComplete] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const { urlSlug } = useParams();
  const [posts, setPosts] = useState([]);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState({});
  const [videoVolume, setVideoVolume] = useState({});
  const videoRefs = useRef({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const navigate = useNavigate();
  const storage = getStorage();
  const [showRepostsModal, setShowRepostsModal] = useState({});
  const [repostMessage, setRepostMessage] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [isSafari, setIsSafari] = useState(false);

  // New state variables for comments
  const [errorPopup, setErrorPopup] = useState(null);

  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState({});
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState({});

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);

  const commentInputRef = useRef({});
  const replyInputRef = useRef({});

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      // Only include fields that we know exist
      const commenterInfo = {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage || profileImage,
        bio: selectedStartup.bio || "",
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        startupOwnerId: currentUser.uid,
      };

      // Only add optional fields if they exist
      if (selectedStartup.link) {
        commenterInfo.link = selectedStartup.link;
      }
      if (selectedStartup.linkText) {
        commenterInfo.linkText = selectedStartup.linkText;
      }
      if (selectedStartup.startupUrlSlug) {
        commenterInfo.startupUrlSlug = selectedStartup.startupUrlSlug;
      }

      return commenterInfo;
    }

    // Regular user info
    const commenterInfo = {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUserProfileImage || profileImage,
      bio: currentUser.bio || "",
      connectionType: "You",
      isStartup: false,
    };

    // Only add optional fields if they exist
    if (currentUser.link) {
      commenterInfo.link = currentUser.link;
    }
    if (currentUser.linkText) {
      commenterInfo.linkText = currentUser.linkText;
    }

    return commenterInfo;
  };

  // Update CommentInputSection component
  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  // Update ReplySection component
  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={replyInputRef}
            value={replyComments}
            onChange={onReplyChange}
            onTouchStart={handleTouchStart}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addReplyButton} ${
            !replyComments?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0) ||
      (post.commentCount && post.commentCount > 0)
    );
  };

  const handleEditPost = (post) => {
    setPostModalScrollPosition(window.scrollY);
    setEditingPost(post);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  // Add the isReplyButtonDisabled function here
  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[replyId] || "";
    return !replyText.trim();
  };

  const fetchRepostData = useCallback(async (reposts) => {
    const repostData = {};
    for (const [userId, timestamp] of Object.entries(reposts)) {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        repostData[userId] = {
          id: userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
          timestamp: timestamp,
        };
      }
    }
    return repostData;
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderCommentOptions = (comment) => {
    // First check if this is a startup comment and if the current user is properly authorized
    const isOwnComment =
      (selectedStartup &&
        comment.isStartup &&
        selectedStartup.id === comment.startupId) || // Currently selected startup made the comment
      (!selectedStartup &&
        !comment.isStartup &&
        comment.userId === currentUser.uid); // User's own comment when not in startup mode

    const isPostOwner = comment.postUserId === currentUser.uid;

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() => handleEditComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                clipRule="evenodd"
              />
            </svg>
            Edit
          </button>
        )}

        {(isOwnComment || isPostOwner) && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(comment.postId, comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                clipRule="evenodd"
              />
            </svg>
            Delete
          </button>
        )}

        {!isOwnComment && !isPostOwner && (
          <button
            className={classes.reportCommentButton}
            onClick={() => handleReportComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const handleReportComment = (commentId) => {
    console.log(`Reporting comment: ${commentId}`);
    setOpenCommentDropdown(null);
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderComments = (postId) => {
    const postComments = comments[postId] || [];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === `${postId}_${comment.id}`
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {/* Inside renderComments() in AllComments.js */}
                  {openCommentDropdown === `${postId}_${comment.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {/* Show Edit if:
        1. Regular user owns comment OR
        2. Selected startup owns comment AND current user owns that startup */}
                      {(!comment.isStartup &&
                        comment.userId === currentUser.uid) ||
                      (comment.isStartup &&
                        selectedStartup?.id === comment.startupId &&
                        comment.startupOwnerId === currentUser.uid) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(postId, comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete if:
        1. User owns the comment OR
        2. Regular user owns comment OR 
        3. Selected startup owns comment AND current user owns that startup */}
                      {(comment.postUserId === currentUser.uid ||
                        (!comment.isStartup &&
                          comment.userId === currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id === comment.startupId &&
                          comment.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}

                      {/* Show Report only if user can't edit/delete the comment */}
                      {!(
                        comment.postUserId === currentUser.uid ||
                        (!comment.isStartup &&
                          comment.userId === currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id === comment.startupId &&
                          comment.startupOwnerId === currentUser.uid)
                      ) && (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() =>
                            console.log(`Reporting comment: ${comment.id}`)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                            />
                          </svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(postId, comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() =>
                      handleCommentReactionClick(postId, comment.id)
                    }
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === `${postId}_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}

              {activeCommentId[postId] === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments[postId]?.[comment.id] || ""}
                  onReplyChange={(e) =>
                    handleReplyInputChange(e, postId, comment.id)
                  }
                  onSubmit={() => handleAddComment(postId, comment.id)}
                  replyInputRef={(el) =>
                    (replyInputRef.current[postId] = {
                      ...replyInputRef.current[postId],
                      [comment.id]: el,
                    })
                  }
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    const postComments = comments[postId] || [];
    const replies = postComments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[postId]?.[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;
          // Get reply text from the correct nested structure
          const replyText = replyComments[postId]?.[reply.id] || "";

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || defaultImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === `${postId}_${reply.id}`
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${reply.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {/* Show Edit if:
      1. Regular user owns reply OR
      2. Selected startup owns reply AND current user owns that startup */}
                      {(!reply.isStartup && reply.userId === currentUser.uid) ||
                      (reply.isStartup &&
                        selectedStartup?.id === reply.startupId &&
                        reply.startupOwnerId === currentUser.uid) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() =>
                            handleEditComment(postId, reply.id, true)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete if:
      1. User owns the post OR
      2. Regular user owns reply OR 
      3. Selected startup owns reply AND current user owns that startup */}
                      {(currentUser.uid === reply.postUserId ||
                        (!reply.isStartup &&
                          reply.userId === currentUser.uid) ||
                        (reply.isStartup &&
                          selectedStartup?.id === reply.startupId &&
                          reply.startupOwnerId === currentUser.uid)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(postId, reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}

                      {/* Show Report only if user can't edit/delete the reply */}
                      {!(
                        currentUser.uid === reply.postUserId ||
                        (!reply.isStartup &&
                          reply.userId === currentUser.uid) ||
                        (reply.isStartup &&
                          selectedStartup?.id === reply.startupId &&
                          reply.startupOwnerId === currentUser.uid)
                      ) && (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() => handleReportComment(reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                            />
                          </svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(postId, reply)}
              </div>

              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(postId, reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}

              {activeEmojiPicker === `${postId}_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {activeCommentId[postId] === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUserProfileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.uid
                          )
                        }
                      />
                    </div>
                    <textarea
                      ref={(el) => {
                        if (!replyInputRef.current[postId]) {
                          replyInputRef.current[postId] = {};
                        }
                        replyInputRef.current[postId][reply.id] = el;
                      }}
                      value={replyComments[postId]?.[reply.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, reply.id)
                      }
                      placeholder={`Reply as ${
                        selectedStartup
                          ? selectedStartup.startupName
                          : "yourself"
                      }...`}
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(postId, reply.id)}
                    className={`${classes.addReplyButton} ${
                      !(replyComments[postId]?.[reply.id] || "").trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(postId, rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  const updateCommentVisibility = async (postId, commenterId, commentData) => {
    const postRef = doc(
      firestore,
      `users/${commentData.originalPosterId}/posts/${postId}`
    );
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const postData = postDoc.data();
      const currentUserConnectionType = await checkConnectionType(
        currentUser.uid,
        postData.userId
      );
      const commenterConnectionType = await checkConnectionType(
        currentUser.uid,
        commenterId
      );

      if (
        (currentUserConnectionType === "Indirect" ||
          currentUserConnectionType === "Extended") &&
        commenterConnectionType === "Direct"
      ) {
        await updateDoc(postRef, {
          visibleCommentedBy: {
            id: commenterId,
            firstName: commentData.firstName,
            lastName: commentData.lastName,
            commentedAt: commentData.commentedAt,
            commentText: commentData.commentText,
          },
          originalDirectCommentTime:
            postData.originalDirectCommentTime || commentData.commentedAt,
        });
      }
    }
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const PostedByMessage = ({ post, currentUser }) => {
    console.log("PostedByMessage received:", { post, currentUser });

    if (!post?.commenter) {
      console.log("Missing commenter data for post:", post);
      return null;
    }

    const isCurrentUserComment = post.commenter.id === currentUser?.uid;
    const isProfileOwnerComment = post.commenter.id === userData?.id;

    let commenterName;
    if (isCurrentUserComment) {
      commenterName = "You";
    } else if (isProfileOwnerComment) {
      commenterName =
        `${userData?.firstName || ""} ${userData?.lastName || ""}`.trim() ||
        "Profile Owner";
    } else {
      commenterName =
        `${post.commenter.firstName || ""} ${
          post.commenter.lastName || ""
        }`.trim() || "Unknown User";
    }

    return (
      <div className={classes.reactionHeader}>
        <img
          src={post.commenter.profileImage || defaultImage}
          alt={commenterName}
          className={classes.reactionImage}
          onClick={() => post.commenter && handleNavigate(post.commenter.id)}
        />
        <span className={classes.reactionText}>
          <span
            className={classes.reactionUsername}
            onClick={() => post.commenter && handleNavigate(post.commenter.id)}
          >
            {commenterName}
          </span>{" "}
          commented on this
        </span>
      </div>
    );
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    const post = posts.find((p) => p.id === postId);
    if (!post) return;

    try {
      // Get the correct post reference based on whether it's a startup post
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${postId}`);

      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reposts`
        : `users/${currentUser.uid}/reposts`;
      const repostedPostRef = doc(firestore, path, postId);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        // Delete notification
        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `repost_${postId}_${reposterId}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        // Only send notification if:
        // 1. User reposting startup post and not the startup owner
        // 2. Startup reposting post and not owned by the post owner
        // 3. User reposting user post and not the post owner
        const shouldNotify =
          (post.user.isStartup &&
            currentUser.uid !== post.user.startupOwnerId &&
            (!selectedStartup || selectedStartup.id !== post.user.startupId)) ||
          (selectedStartup &&
            currentUser.uid !== post.user.id &&
            (!post.user.isStartup ||
              selectedStartup.id !== post.user.startupId)) ||
          (!selectedStartup &&
            !post.user.isStartup &&
            currentUser.uid !== post.user.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `repost_${postId}_${reposterId}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterId,
            reposterName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reposterImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reposts: updatedReposts } : p
        )
      );
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const handleRepostsClick = useCallback(
    async (postId, reposts) => {
      setRepostsModalScrollPosition(window.scrollY);
      const repostData = {};

      for (const [userId, timestamp] of Object.entries(reposts)) {
        try {
          if (userId.startsWith("startup_")) {
            // Handle startup reposts
            const startupId = userId.replace("startup_", "");
            const allUsersSnapshot = await getDocs(
              collection(firestore, "users")
            );

            for (const userDoc of allUsersSnapshot.docs) {
              const startupRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}`
              );
              const startupSnap = await getDoc(startupRef);

              if (startupSnap.exists()) {
                const startupData = startupSnap.data();
                repostData[userId] = {
                  id: userId,
                  firstName: startupData.startupName,
                  lastName: "",
                  profileImage: startupData.startupImage || profileImage,
                  timestamp: timestamp,
                  isStartup: true,
                  startupId: startupId,
                  startupOwnerId: userDoc.id,
                };
                break; // Found the startup, no need to continue searching
              }
            }
          } else {
            // Handle user reposts
            const userDoc = await getDoc(doc(firestore, "users", userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              repostData[userId] = {
                id: userId,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage || profileImage,
                timestamp: timestamp,
                isStartup: false,
              };
            }
          }
        } catch (error) {
          console.error("Error fetching repost data:", error);
        }
      }

      setActivePostReposts({ postId, reposts: repostData });
      setShowRepostsModal((prev) => ({ ...prev, [postId]: true }));
      setIsRepostsModalOpen(true);
    },
    [firestore, profileImage]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      console.log("Getting original post ref for:", {
        originalPosterId,
        postId,
      });

      // Check if this is a startup post
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        // Find the owner of this startup
        const allUsersRef = collection(firestore, "users");
        const allUsersSnapshot = await getDocs(allUsersRef);

        for (const userDoc of allUsersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            // Found the startup, now check for the post
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
            );
            const startupPostSnap = await getDoc(startupPostRef);

            if (startupPostSnap.exists()) {
              console.log("Found post in startup:", startupId);
              return startupPostRef;
            }
          }
        }
      } else {
        // Handle regular user posts
        const userPostRef = doc(
          firestore,
          `users/${originalPosterId}/posts/${postId}`
        );
        const userPostSnap = await getDoc(userPostRef);

        if (userPostSnap.exists()) {
          console.log("Found post in user posts");
          return userPostRef;
        }

        // Check user's startups
        const startupsRef = collection(
          firestore,
          `users/${originalPosterId}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);

        for (const startupDoc of startupsSnapshot.docs) {
          const startupPostRef = doc(
            firestore,
            `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
          );
          const startupPostSnap = await getDoc(startupPostRef);

          if (startupPostSnap.exists()) {
            console.log("Found post in startup:", startupDoc.id);
            return startupPostRef;
          }
        }
      }

      console.log("Could not find post anywhere");
      return null;
    } catch (error) {
      console.error("Error in getOriginalPostRef:", error);
      return null;
    }
  };

  const fetchUserData = async () => {
    if (!currentUser) {
      console.log("No current user, skipping fetch");
      return;
    }

    setLoading(true);
    setHasAttemptedLoad(false);
    try {
      const { pathname } = location;
      const { state } = location;
      const isStartupPath = pathname.includes("/startup/");
      const fromStartupComments = state?.fromStartupComments;
      const startupData = state?.startupData;

      console.log("Path info:", {
        pathname,
        isStartupPath,
        fromStartupComments,
        startupData,
      });

      if ((isStartupPath || fromStartupComments) && startupData) {
        setUserData({
          id: `startup_${startupData.startupId}`,
          startupId: startupData.startupId,
          startupName: startupData.startupName,
          startupImage: startupData.startupImage,
          isStartup: true,
          ...startupData,
        });

        const commentedPostsPath = `users/${startupData.startupOwnerId}/startups/${startupData.startupId}/commentedPosts`;
        console.log("Querying commentedPosts at path:", commentedPostsPath);

        const commentedPostsRef = collection(firestore, commentedPostsPath);
        const commentedPostsQuery = query(
          commentedPostsRef,
          orderBy("lastCommentedAt", "desc")
        );
        const querySnapshot = await getDocs(commentedPostsQuery);

        console.log("Found commented posts:", querySnapshot.size);

        const fetchedPosts = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const commentedPostData = docSnapshot.data();
            console.log("Processing commented post:", commentedPostData);

            let originalPostRef = await getOriginalPostRef(
              commentedPostData.originalPosterId,
              commentedPostData.postId
            );

            if (!originalPostRef) {
              console.log("Could not find original post ref");
              return null;
            }

            const originalPostSnap = await getDoc(originalPostRef);
            if (!originalPostSnap.exists()) {
              console.log("Original post does not exist");
              return null;
            }

            const originalPostData = originalPostSnap.data();
            let originalPostUserData;
            let mediaUrl = null;

            // Get user/startup data based on the post path
            if (originalPostRef.path.includes("/startups/")) {
              const pathParts = originalPostRef.path.split("/");
              const ownerId = pathParts[1];
              const startupId = pathParts[3];

              const startupRef = doc(
                firestore,
                `users/${ownerId}/startups/${startupId}`
              );
              const startupSnap = await getDoc(startupRef);

              if (startupSnap.exists()) {
                const startupData = startupSnap.data();
                originalPostUserData = {
                  id: `startup_${startupId}`,
                  firstName: startupData.startupName,
                  lastName: "",
                  profileImage: startupData.startupImage || profileImage,
                  isStartup: true,
                  startupId: startupId,
                  startupOwnerId: ownerId,
                  startupUrlSlug: startupData.startupUrlSlug,
                };
              }
            } else {
              const userDoc = await getDoc(
                doc(firestore, "users", commentedPostData.originalPosterId)
              );
              if (userDoc.exists()) {
                const userData = userDoc.data();
                originalPostUserData = {
                  id: commentedPostData.originalPosterId,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  profileImage: userData.profileImage || profileImage,
                  isStartup: false,
                  urlSlug: userData.urlSlug,
                };
              }
            }

            // Handle media URL
            if (originalPostData.content?.url) {
              if (Array.isArray(originalPostData.content.url)) {
                mediaUrl = await Promise.all(
                  originalPostData.content.url.map(async (url) => {
                    try {
                      const storageRef = ref(storage, url);
                      return await getDownloadURL(storageRef);
                    } catch (error) {
                      console.error("Error fetching media URL:", error);
                      return null;
                    }
                  })
                );
              } else {
                try {
                  const storageRef = ref(storage, originalPostData.content.url);
                  mediaUrl = await getDownloadURL(storageRef);
                } catch (error) {
                  console.error("Error fetching media URL:", error);
                }
              }
            }

            return {
              id: commentedPostData.postId,
              text: originalPostData.text,
              content: originalPostData.content
                ? {
                    ...originalPostData.content,
                    url: mediaUrl || originalPostData.content.url,
                  }
                : null,
              user: originalPostUserData,
              reactions: originalPostData.reactions || {},
              reposts: originalPostData.reposts || {},
              commentCount: originalPostData.commentCount || 0,
              commenter: {
                id: `startup_${startupData.startupId}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage,
                isStartup: true,
                startupId: startupData.startupId,
                startupOwnerId: startupData.startupOwnerId,
              },
              createdAt: originalPostData.createdAt,
              lastCommentedAt: commentedPostData.lastCommentedAt,
              isEdited: originalPostData.isEdited || false,
            };
          })
        );

        const validPosts = fetchedPosts.filter((post) => post !== null);
        console.log("Final posts structure:", validPosts);
        setPosts(validPosts);
      } else {
        let userId;
        if (urlSlug === "me") {
          if (!auth.currentUser) {
            console.error("No authenticated user found");
            setLoading(false);
            return;
          }
          userId = auth.currentUser.uid;
        } else {
          const usersRef = collection(firestore, "users");
          const q = query(usersRef, where("urlSlug", "==", urlSlug));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            userId = querySnapshot.docs[0].id;
          } else {
            console.error("User not found");
            setLoading(false);
            return;
          }
        }

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData({ id: userId, ...userDoc.data() });
        } else {
          console.error("User document not found");
          setLoading(false);
          return;
        }

        const commentedPostsRef = collection(
          firestore,
          `users/${userId}/commentedPosts`
        );
        const commentedPostsQuery = query(
          commentedPostsRef,
          orderBy("lastCommentedAt", "desc"),
          limit(20)
        );
        const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

        const fetchedPosts = await Promise.all(
          commentedPostsSnapshot.docs.map(async (commentedPostDoc) => {
            const commentedPostData = commentedPostDoc.data();
            if (
              !commentedPostData ||
              !commentedPostData.originalPosterId ||
              !commentedPostData.postId
            ) {
              console.error("Invalid commented post data", commentedPostDoc.id);
              return null;
            }

            const postRef = doc(
              firestore,
              `users/${commentedPostData.originalPosterId}/posts/${commentedPostData.postId}`
            );
            const postSnap = await getDoc(postRef);

            if (!postSnap.exists()) {
              console.error(`Post ${commentedPostData.postId} not found`);
              return null;
            }

            const postData = postSnap.data();
            const postOwnerDoc = await getDoc(
              doc(firestore, "users", commentedPostData.originalPosterId)
            );
            const postOwnerData = postOwnerDoc.data() || {};

            const commenterData = userDoc.data() || {};

            const connectionType = await checkConnectionType(
              currentUser.uid,
              commentedPostData.originalPosterId
            );

            let mediaUrl = null;
            if (
              postData.content &&
              postData.content.type &&
              postData.content.url
            ) {
              if (Array.isArray(postData.content.url)) {
                mediaUrl = await Promise.all(
                  postData.content.url.map(async (url) => {
                    try {
                      const storageRef = ref(storage, url);
                      return await getDownloadURL(storageRef);
                    } catch (error) {
                      console.error("Error fetching media URL:", error);
                      return null;
                    }
                  })
                );
              } else {
                try {
                  const storageRef = ref(storage, postData.content.url);
                  mediaUrl = await getDownloadURL(storageRef);
                } catch (error) {
                  console.error("Error fetching media URL:", error);
                }
              }
            }

            if (mediaUrl) {
              preloadImages(Array.isArray(mediaUrl) ? mediaUrl : [mediaUrl]);
            }

            return {
              id: commentedPostData.postId,
              ...postData,
              commenter: {
                id: userId,
                firstName: commenterData.firstName || "Unknown",
                lastName: commenterData.lastName || "User",
                profileImage: commenterData.profileImage || profileImage,
                bio: commenterData.bio || "",
                link: commenterData.link || null,
                linkText: commenterData.linkText || null,
              },
              user: {
                id: commentedPostData.originalPosterId,
                firstName: postOwnerData.firstName || "Unknown",
                lastName: postOwnerData.lastName || "User",
                profileImage: postOwnerData.profileImage || profileImage,
                bio: postOwnerData.bio || "",
                link: postOwnerData.link || null,
                linkText: postOwnerData.linkText || null,
                connectionType: connectionType,
              },
              content: {
                ...postData.content,
                url: mediaUrl || postData.content?.url,
              },
              reactions: postData.reactions || {},
              reposts: postData.reposts || {},
              commentCount: postData.commentCount || 0,
              lastCommentedAt: commentedPostData.lastCommentedAt,
            };
          })
        );

        const validPosts = fetchedPosts.filter((post) => post !== null);
        const sortedPosts = validPosts.sort((a, b) => {
          return b.lastCommentedAt.toMillis() - a.lastCommentedAt.toMillis();
        });

        setPosts(sortedPosts);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setHasAttemptedLoad(true);
    }
  };

  const handlePrevImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + totalImages) % totalImages,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (!userId || !connectionId) return "Extended";
    if (userId === connectionId) return "You";

    try {
      const userConnectionRef = doc(
        firestore,
        `users/${userId}/connections/${connectionId}`
      );
      const userConnectionDoc = await getDoc(userConnectionRef);

      if (
        userConnectionDoc.exists() &&
        userConnectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      // Check for indirect connections
      const userConnectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      for (const conn of userConnectionsSnapshot.docs) {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${connectionId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }
    } catch (error) {
      console.error("Error checking connection type:", error);
    }

    return "Extended";
  };

  const getPostPath = (post) => {
    if (!post) {
      console.error("No post provided to getPostPath");
      return null;
    }

    if (!post.user) {
      console.error("Post has no user information:", post);
      return null;
    }

    if (post.user.isStartup) {
      if (!post.user.startupOwnerId || !post.user.startupId) {
        console.error("Startup post missing required fields:", post);
        return null;
      }
      return `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`;
    }

    if (!post.user.id) {
      console.error("User post missing user ID:", post);
      return null;
    }
    return `users/${post.user.id}/posts/${post.id}`;
  };

  const getCommentsPath = (post) => {
    const postPath = getPostPath(post);
    if (!postPath) return null;
    return `${postPath}/comments`;
  };

  // Update fetchComments function with better error handling
  const fetchComments = useCallback(
    async (postId, post) => {
      if (!postId || !post || !currentUser) {
        console.log("Missing required parameters for fetchComments:", {
          postId,
          post,
          currentUser,
        });
        return;
      }

      try {
        // First verify we have a valid post object
        if (!post.user) {
          console.error(
            "Invalid post object - missing user information:",
            post
          );
          return;
        }

        const commentsPath = post.user.isStartup
          ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}/comments`
          : `users/${post.user.id}/posts/${postId}/comments`;

        const commentsRef = collection(firestore, commentsPath);
        const q = query(commentsRef, orderBy("createdAt", "asc"));
        const snapshot = await getDocs(q);

        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();

            try {
              if (commentData.userId.startsWith("startup_")) {
                const startupId = commentData.userId.replace("startup_", "");
                const startupRef = doc(
                  firestore,
                  `users/${commentData.startupOwnerId}/startups/${startupId}`
                );
                const startupSnap = await getDoc(startupRef);

                if (startupSnap.exists()) {
                  const startupData = startupSnap.data();
                  return {
                    id: docSnapshot.id,
                    ...commentData,
                    userName: startupData.startupName,
                    userImage: startupData.startupImage || profileImage,
                    connectionType: "Startup",
                    bio: startupData.bio || "",
                    link: startupData.link || null,
                    linkText: startupData.linkText || null,
                    postId,
                    postUserId: post.user.isStartup
                      ? post.user.startupOwnerId
                      : post.user.id,
                    rootCommentId: commentData.rootCommentId,
                    isRootComment: commentData.isRootComment,
                    isStartup: true,
                    startupId,
                    startupOwnerId: commentData.startupOwnerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                  };
                }
              } else {
                const userDoc = await getDoc(
                  doc(firestore, "users", commentData.userId)
                );
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  const connectionType = await checkConnectionType(
                    currentUser.uid,
                    commentData.userId
                  );
                  return {
                    id: docSnapshot.id,
                    ...commentData,
                    userName: `${userData.firstName} ${userData.lastName}`,
                    userImage: userData.profileImage || profileImage,
                    connectionType,
                    bio: userData.bio || "",
                    link: userData.link || null,
                    linkText: userData.linkText || null,
                    urlSlug: userData.urlSlug,
                    postId,
                    postUserId: post.user.isStartup
                      ? post.user.startupOwnerId
                      : post.user.id,
                    rootCommentId: commentData.rootCommentId,
                    isRootComment: commentData.isRootComment,
                    isStartup: false,
                  };
                }
              }
            } catch (error) {
              console.error("Error processing comment:", error, commentData);
              return null;
            }
            return null;
          })
        );

        const validComments = fetchedComments.filter(
          (comment) => comment !== null
        );

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: validComments,
        }));

        setPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId ? { ...p, commentCount: validComments.length } : p
          )
        );
      } catch (error) {
        console.error("Error fetching comments:", error);
        setErrorPopup({
          message: "Error loading comments.",
          isError: true,
        });
      }
    },
    [currentUser, checkConnectionType, profileImage]
  );

  // Update the useEffect that calls fetchComments
  useEffect(() => {
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        if (!comments[post.id] && post && post.user) {
          fetchComments(post.id, post);
        }
      });
    }
  }, [posts, currentUser, fetchComments]);

  useEffect(() => {
    Object.entries(showComments).forEach(([postId, isShown]) => {
      if (isShown) {
        const post = posts.find((p) => p.id === postId);
        if (post && post.user && !comments[postId]) {
          fetchComments(postId, post);
        }
      }
    });
  }, [showComments, posts, fetchComments]);

  useEffect(() => {
    if (!currentUser) return; // Add this guard
    fetchUserData();
  }, [urlSlug, currentUser]);

  // Function to handle adding a comment
  const handleAddComment = async (postId, parentCommentId = null) => {
    if (!currentUser) return;

    const post = posts.find((p) => p.id === postId);
    if (!post) {
      setErrorPopup({
        message: "Post not found.",
        isError: true,
      });
      return;
    }

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[postId]?.[parentCommentId] || "";
    } else {
      commentText = newComment[postId] || "";
    }

    if (!commentText.trim()) {
      console.log("Comment text is empty");
      return;
    }

    try {
      const commenterInfo = getCommenterInfo(currentUser, selectedStartup);
      const processedCommentText = convertUrlsToLinks(commentText);

      const commentsPath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}/comments`
        : `users/${post.user.id}/posts/${postId}/comments`;

      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${postId}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      let rootCommentId = null;
      if (parentCommentId) {
        const parentCommentRef = doc(firestore, commentsPath, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);

        if (!parentCommentSnap.exists()) {
          throw new Error("Parent comment not found");
        }

        const parentCommentData = parentCommentSnap.data();
        rootCommentId = parentCommentData.rootCommentId || parentCommentId;
      }

      const now = serverTimestamp();

      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio || "",
        connectionType: commenterInfo.connectionType,
        postUserId: post.user.isStartup
          ? post.user.startupOwnerId
          : post.user.id,
        reactions: {},
        isStartup: commenterInfo.isStartup,
      };

      if (commenterInfo.link) commentData.link = commenterInfo.link;
      if (commenterInfo.linkText) commentData.linkText = commenterInfo.linkText;

      if (commenterInfo.isStartup) {
        commentData.startupId = commenterInfo.startupId;
        commentData.startupOwnerId = commenterInfo.startupOwnerId;
        if (commenterInfo.startupUrlSlug) {
          commentData.startupUrlSlug = commenterInfo.startupUrlSlug;
        }
      }

      const commentsRef = collection(firestore, commentsPath);
      const newCommentRef = await addDoc(commentsRef, commentData);
      const newCommentId = newCommentRef.id;

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: now,
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [
          ...(prevComments[postId] || []),
          { ...commentData, id: newCommentId },
        ],
      }));

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
      }

      setActiveCommentId((prev) => ({ ...prev, [postId]: null }));

      if (parentCommentId && replyInputRef.current[postId]?.[parentCommentId]) {
        replyInputRef.current[postId][parentCommentId].style.height = "auto";
      } else if (commentInputRef.current[postId]) {
        commentInputRef.current[postId].style.height = "auto";
      }

      const batch = writeBatch(firestore);

      if (!parentCommentId && post.user.id !== commenterInfo.id) {
        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `comment_${newCommentId}`
        );

        const notificationData = {
          type: "comment",
          postId: postId,
          commentId: newCommentId,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: now,
          isNew: true,
          postPreview: {
            text: post.text || "",
            mediaType: post.content?.type || null,
            mediaUrl: post.content?.url || null,
            fileName: post.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(notificationRef, notificationData);
      }

      if (parentCommentId) {
        const parentCommentRef = doc(firestore, commentsPath, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);

        if (parentCommentSnap.exists()) {
          const parentCommentData = parentCommentSnap.data();
          const shouldNotify =
            parentCommentData.userId !== commenterInfo.id &&
            (!parentCommentData.isStartup ||
              parentCommentData.startupId !== commenterInfo.startupId);

          if (shouldNotify) {
            const notificationRef = doc(
              firestore,
              `users/${
                parentCommentData.isStartup
                  ? parentCommentData.startupOwnerId
                  : parentCommentData.userId
              }/notifications`,
              `reply_${newCommentId}`
            );

            const notificationData = {
              type: "reply",
              postId: postId,
              commentId: newCommentId,
              parentCommentId: parentCommentId,
              replierId: commenterInfo.id,
              replierName: commenterInfo.name,
              replierImage: commenterInfo.image,
              replyText: commentText,
              createdAt: now,
              isNew: true,
              parentCommentPreview: {
                text: parentCommentData.text || "",
              },
              postPreview: {
                text: post.text || "",
                mediaType: post.content?.type || null,
                mediaUrl: post.content?.url || null,
                fileName: post.content?.fileName || null,
              },
              ...(commenterInfo.isStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage: selectedStartup.startupImage,
              }),
            };

            batch.set(notificationRef, notificationData);
          }
        }
      }

      await batch.commit();
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : error.message === "Parent comment not found"
            ? "The comment you're replying to has been deleted."
            : "Error adding comment.",
        isError: true,
      });
    }
  };

  // Function to get the root comment ID
  const getRootCommentId = async (post, postId, commentId) => {
    if (!post || !postId || !commentId) {
      console.error("Missing required parameters in getRootCommentId:", {
        post,
        postId,
        commentId,
      });
      throw new Error("Missing required parameters");
    }

    try {
      const commentsPath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}/comments`
        : `users/${post.user.id}/posts/${postId}/comments`;

      const commentRef = doc(firestore, commentsPath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      if (commentData.isRootComment) {
        return commentId;
      } else if (commentData.rootCommentId) {
        return commentData.rootCommentId;
      } else if (commentData.parentCommentId) {
        // If we have a parent comment ID but no root comment ID, recursively find the root
        return getRootCommentId(post, postId, commentData.parentCommentId);
      } else {
        // If we somehow get here, use this comment as the root
        return commentId;
      }
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  // Function to handle comment input change
  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({ ...prev, [postId]: textarea.value }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Function to handle reply input change
  const handleReplyInputChange = (e, postId, commentId) => {
    const textarea = e.target;

    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: textarea.value,
      },
    }));

    // Auto-adjust height
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Function to handle reply button click
  const handleReplyButton = (postId, commentId) => {
    setActiveCommentId((prev) => ({
      ...prev,
      [postId]: prev[postId] === commentId ? null : commentId,
    }));

    // Initialize the reply comment state if it doesn't exist
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: "",
      },
    }));

    setTimeout(() => {
      if (!replyInputRef.current[postId]) {
        replyInputRef.current[postId] = {};
      }

      if (replyInputRef.current[postId]?.[commentId]) {
        const inputElement = replyInputRef.current[postId][commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  // Function to force open the keyboard on iOS
  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Function to handle loading more comments
  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  // Function to handle viewing more replies
  const handleViewMoreReplies = (postId, rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [rootCommentId]: (prev[postId]?.[rootCommentId] || 1) + 9,
      },
    }));
  };

  // Function to toggle comments visibility
  const toggleComments = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  // Function to toggle comment expansion
  const toggleCommentExpansion = (postId, commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: !prev[postId]?.[commentId],
      },
    }));
  };

  // Function to toggle reply expansion
  const toggleReplyExpansion = (postId, replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: !prev[postId]?.[replyId],
      },
    }));
  };

  // Function to handle comment reaction click
  const handleCommentReactionClick = (postId, commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker(
      activeEmojiPicker === `${postId}_${commentId}`
        ? null
        : `${postId}_${commentId}`
    );
  };

  // Function to handle comment emoji selection
  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const post = posts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      // Get the correct path for comments based on whether it's a startup post
      const commentsPath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}/comments`
        : `users/${post.user.id}/posts/${postId}/comments`;

      const commentRef = doc(firestore, commentsPath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const reactorName = selectedStartup
        ? selectedStartup.startupName
        : `${currentUser.firstName} ${currentUser.lastName}`;
      const reactorImage = selectedStartup
        ? selectedStartup.startupImage
        : currentUserProfileImage || profileImage;

      // Check if reactor owns the comment
      const isReactorOwner =
        (selectedStartup &&
          commentData.isStartup &&
          commentData.startupId === selectedStartup.id) ||
        (!selectedStartup &&
          !commentData.isStartup &&
          commentData.userId === currentUser.uid) ||
        (commentData.isStartup &&
          commentData.startupOwnerId === currentUser.uid) ||
        (!commentData.isStartup && commentData.userId === currentUser.uid);

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        // Delete notification
        if (!isReactorOwner) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        // Add or update reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        // Determine if notification should be sent
        const shouldNotify =
          !isReactorOwner &&
          ((selectedStartup &&
            commentData.userId !== `startup_${selectedStartup.id}` &&
            (!commentData.isStartup ||
              commentData.startupId !== selectedStartup.id)) ||
            (!selectedStartup &&
              commentData.userId !== currentUser.uid &&
              (!commentData.isStartup ||
                commentData.startupOwnerId !== currentUser.uid)));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: post.id,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: reactorName,
            reactorImage: reactorImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment/reply reaction:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "The post containing this comment has been deleted."
            : error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  // Function to toggle comment dropdown
  const toggleCommentDropdown = (postId, commentId) => {
    setOpenCommentDropdown((prev) =>
      prev === `${postId}_${commentId}` ? null : `${postId}_${commentId}`
    );
  };

  // Function to handle comment deletion
  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) return;

    try {
      const post = posts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      const commentsPath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}/comments`
        : `users/${post.user.id}/posts/${postId}/comments`;

      const commentRef = doc(firestore, commentsPath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.isStartup) {
        canDelete =
          (selectedStartup && selectedStartup.id === commentData.startupId) ||
          currentUser.uid === commentData.startupOwnerId ||
          (post.user.isStartup &&
            currentUser.uid === post.user.startupOwnerId) ||
          (!post.user.isStartup && currentUser.uid === post.user.id);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          (post.user.isStartup &&
            currentUser.uid === post.user.startupOwnerId) ||
          (!post.user.isStartup && currentUser.uid === post.user.id);
      }

      if (!canDelete) {
        throw new Error("Not authorized to delete this comment");
      }

      const batch = writeBatch(firestore);
      let deletedCount = 0;

      const deleteNotificationsForComment = async (commentRef) => {
        const notificationsQuery = query(
          collection(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`
          ),
          where("commentId", "==", commentRef.id)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);
        notificationsSnapshot.docs.forEach((doc) => batch.delete(doc.ref));

        if (commentData.reactions) {
          for (const [reactorId, emoji] of Object.entries(
            commentData.reactions
          )) {
            if (reactorId.startsWith("startup_")) {
              const startupId = reactorId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );
              for (const userDoc of usersSnapshot.docs) {
                const reactionNotificationRef = doc(
                  firestore,
                  `users/${userDoc.id}/notifications`,
                  `comment_reaction_${commentRef.id}_${reactorId}`
                );
                batch.delete(reactionNotificationRef);
              }
            } else {
              const reactionNotificationRef = doc(
                firestore,
                `users/${reactorId}/notifications`,
                `comment_reaction_${commentRef.id}_${reactorId}`
              );
              batch.delete(reactionNotificationRef);
            }
          }
        }
      };

      await deleteNotificationsForComment(commentRef);

      const repliesQuery = query(
        collection(firestore, commentsPath),
        where("rootCommentId", "==", commentId)
      );
      const repliesSnapshot = await getDocs(repliesQuery);

      for (const replyDoc of repliesSnapshot.docs) {
        await deleteNotificationsForComment(replyDoc.ref);
        batch.delete(replyDoc.ref);
        deletedCount++;
      }

      batch.delete(commentRef);
      deletedCount++;

      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${postId}`);

      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      if (commentData.isStartup) {
        const startupCommentedPostRef = doc(
          firestore,
          `users/${commentData.startupOwnerId}/startups/${commentData.startupId}/commentedPosts/${postId}`
        );
        const startupCommentedPostDoc = await getDoc(startupCommentedPostRef);

        if (startupCommentedPostDoc.exists()) {
          const updatedComments = {
            ...startupCommentedPostDoc.data().comments,
          };
          delete updatedComments[commentId];

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(startupCommentedPostRef);
          } else {
            batch.update(startupCommentedPostRef, {
              comments: updatedComments,
            });
          }
        }
      } else {
        const userCommentedPostRef = doc(
          firestore,
          `users/${commentData.userId}/commentedPosts/${postId}`
        );
        const userCommentedPostDoc = await getDoc(userCommentedPostRef);

        if (userCommentedPostDoc.exists()) {
          const updatedComments = { ...userCommentedPostDoc.data().comments };
          delete updatedComments[commentId];

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(userCommentedPostRef);
          } else {
            batch.update(userCommentedPostRef, { comments: updatedComments });
          }
        }
      }

      await batch.commit();

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: (prevComments[postId] || []).filter(
          (comment) =>
            comment.id !== commentId && comment.rootCommentId !== commentId
        ),
      }));

      setPosts((prevPosts) =>
        prevPosts.map((p) => {
          if (p.id === postId) {
            return {
              ...p,
              commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
            };
          }
          return p;
        })
      );

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to delete this comment"
            ? "You don't have permission to delete this comment."
            : "Error deleting comment.",
        isError: true,
      });
    }
  };

  // Function to render comment text
  const renderCommentText = (postId, comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[postId]?.[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[postId]?.[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(postId, comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Function to render reply text
  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div className={classes.cancelEditButton} onClick={handleCancelEdit}>
        Cancel
      </div>
    </div>
  );

  // Function to get top three emojis
  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const fetchCurrentUserProfileImage = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setCurrentUserProfileImage(userDoc.data().profileImage || profileImage);
      }
    } catch (error) {
      console.error("Error fetching current user profile image:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (userId) => {
    console.log("handleNavigate called with userId:", userId);
    console.log("Current user ID:", currentUser?.uid);

    if (!userId) {
      console.error("handleNavigate called with no userId");
      return;
    }

    try {
      console.log("Fetching user data for ID:", userId);
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data:", userData);
        // Always navigate to urlSlug, even for current user
        if (userData.urlSlug) {
          console.log("Navigating to urlSlug:", userData.urlSlug);
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("No urlSlug found for user:", userId);
          setErrorPopup({
            message: "User profile not found.",
            isError: true,
          });
        }
      } else {
        console.error("User not found for ID:", userId);
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  const renderPostText = (post) => {
    if (!post || !post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
          onClick={(e) => handleLinkClick(e, post.id)}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={() =>
              setExpandedPosts((prev) => ({ ...prev, [post.id]: true }))
            }
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const renderPostContent = (post) => {
    console.log("Rendering post content:", post.id, post.content);
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex[post.id] || 0]}
                    alt=""
                    className={classes.postContentImage}
                    onClick={() =>
                      handleNextImage(post.id, post.content.url.length)
                    }
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === (currentImageIndex[post.id] || 0)
                              ? classes.activeDot
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex((prev) => ({
                              ...prev,
                              [post.id]: index,
                            }));
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );

      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={(el) => (videoRefs.current[post.id] = el)}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume[post.id]}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume((prev) => ({
                      ...prev,
                      [post.id]: !prev[post.id],
                    }));
                  }}
                >
                  {!videoVolume[post.id] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        return (
          <div className={classes.postContentWrapper}>
            <a
              href={post.content.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.pdfPreview} ${
                !hasInteractions(post) ? classes.pdfPreviewNoInteractions : ""
              }`}
            >
              <div className={classes.pdfBox}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>
                    {(() => {
                      let fileName = "Unnamed PDF";
                      if (post.content.fileName) {
                        if (Array.isArray(post.content.fileName)) {
                          fileName = post.content.fileName[0] || "Unnamed PDF";
                        } else if (typeof post.content.fileName === "string") {
                          fileName = post.content.fileName;
                        }
                        // Remove .pdf extension if present
                        fileName = fileName.replace(/\.pdf$/i, "");
                      }
                      return fileName;
                    })()}
                  </div>
                  <div className={classes.open}>
                    View
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = videoRefs.current[entry.target.dataset.postId];
        if (video) {
          if (entry.isIntersecting) {
            video
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            video.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    posts.forEach((post) => {
      const video = videoRefs.current[post.id];
      if (video) {
        video.dataset.postId = post.id;
        observer.observe(video);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [posts]);

  const handleLinkClick = (e, postId) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
    // Remove the else block that was navigating to the post
  };

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const post = posts.find((p) => p.id === postId);
      if (!post) return;

      // Get the correct post reference based on whether it's a startup post
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${postId}`);

      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reactedPosts`
        : `users/${currentUser.uid}/reactedPosts`;

      const reactedPostRef = doc(firestore, path, postId);
      let updatedReactions = { ...(post.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];
        await deleteDoc(reactedPostRef);

        // Delete notification
        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `reaction_${postId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        await setDoc(reactedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Only send notification if:
        // 1. User reacting to startup post and not the startup owner
        // 2. Startup reacting to post and not owned by the post owner
        // 3. User reacting to user post and not the post owner
        const shouldNotify =
          (post.user.isStartup &&
            currentUser.uid !== post.user.startupOwnerId &&
            (!selectedStartup || selectedStartup.id !== post.user.startupId)) ||
          (selectedStartup &&
            currentUser.uid !== post.user.id &&
            (!post.user.isStartup ||
              selectedStartup.id !== post.user.startupId)) ||
          (!selectedStartup &&
            !post.user.isStartup &&
            currentUser.uid !== post.user.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `reaction_${postId}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: postId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reactions: updatedReactions } : p
        )
      );

      setMainEmojiPickerOpen(null);
      setTimeout(() => {
        window.scrollTo(0, emojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal((prev) => ({ ...prev, [postId]: true }));
    setIsReactionsModalOpen(true);
  };

  const handleDeletePost = async (postId) => {
    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const targetPost = posts.find((p) => p.id === postId);
      if (!targetPost) {
        console.error("Post not found");
        return;
      }

      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${postId}`);

      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        console.error("Post not found");
        return;
      }

      const postData = postSnap.data();

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const commentsRef = collection(
        firestore,
        `users/${targetPost.user.id}/posts/${postId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupReactedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reactedPosts/${postId}`
              );
              batch.delete(startupReactedPostRef);
              operationCount++;

              const reactionNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
              );
              batch.delete(reactionNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userReactedPostRef = doc(
              firestore,
              `users/${userId}/reactedPosts/${postId}`
            );
            batch.delete(userReactedPostRef);
            operationCount++;

            const reactionNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/reaction_${postId}_${userId}`
            );
            batch.delete(reactionNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupRepostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/reposts/${postId}`
              );
              batch.delete(startupRepostRef);
              operationCount++;

              const repostNotificationRef = doc(
                firestore,
                `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
              );
              batch.delete(repostNotificationRef);
              operationCount++;

              await commitBatchIfNeeded();
            }
          } else {
            const userRepostRef = doc(
              firestore,
              `users/${userId}/reposts/${postId}`
            );
            batch.delete(userRepostRef);
            operationCount++;

            const repostNotificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications/repost_${postId}_${userId}`
            );
            batch.delete(repostNotificationRef);
            operationCount++;

            await commitBatchIfNeeded();
          }
        }
      }

      if (postData.comments) {
        for (const userId of Object.keys(postData.comments)) {
          if (userId.startsWith("startup_")) {
            const startupId = userId.replace("startup_", "");
            const usersSnapshot = await getDocs(collection(firestore, "users"));

            for (const userDoc of usersSnapshot.docs) {
              const startupCommentedPostRef = doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/commentedPosts/${postId}`
              );
              batch.delete(startupCommentedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } else {
            const commentedPostRef = doc(
              firestore,
              `users/${userId}/commentedPosts/${postId}`
            );
            batch.delete(commentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          }
        }
      }

      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        const notificationsQuery = query(
          collection(firestore, `users/${userDoc.id}/notifications`),
          where("postId", "==", postId)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);

        const notificationDeletePromises = notificationsSnapshot.docs.map(
          (doc) => {
            batch.delete(doc.ref);
            operationCount++;
            return commitBatchIfNeeded();
          }
        );

        await Promise.all(notificationDeletePromises);
      }

      const deletePromises = commentsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${postId}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error deleting files from storage:", error);
        }
      }

      batch.delete(postRef);
      operationCount++;

      await batch.commit();

      setPosts((prevPosts) => {
        const updatedPosts = prevPosts.filter((p) => p.id !== postId);

        if (updatedPosts.length === 0 && location.state?.startupView) {
          const userDoc = getDoc(doc(firestore, "users", currentUser.uid));
          userDoc.then((doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              navigate(`/startup/${userData.startupUrlSlug}`);
            }
          });
        }

        return updatedPosts;
      });

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      setDeleteMessage({
        text: "Post deleted successfully.",
        postId,
        isSuccess: true,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = (postId) => {
    // Implement report post functionality
    console.log("Report post clicked for post:", postId);
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const handleEditComment = (postId, commentId, isReply = false) => {
    if (!postId || !comments[postId]) {
      console.error(`No comments found for post ${postId}`);
      return;
    }

    const postComments = comments[postId];
    const commentToEdit = postComments.find((c) => c.id === commentId);

    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
      console.error(`Comment ${commentId} not found in post ${postId}`);
    }
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const targetPost = posts.find((p) => p.id === postId);
    if (!targetPost) return;

    const trimmedText = editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      // Get the correct path based on whether it's a startup post
      const basePath = targetPost.user.isStartup
        ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}`
        : `users/${targetPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      // Fetch the comment to verify ownership
      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      // Verify ownership
      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      // Update the comment
      const processedText = convertUrlsToLinks(trimmedText);
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");

      // Update commentedPosts collections
      const commenterPath = commentData.isStartup
        ? `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
        : `users/${commentData.userId}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${postId}`
      );
      const commentedPostSnap = await getDoc(commentedPostRef);

      if (commentedPostSnap.exists()) {
        const commentedPostData = commentedPostSnap.data();
        if (commentedPostData.comments?.[commentId]) {
          await updateDoc(commentedPostRef, {
            [`comments.${commentId}.commentText`]: processedText,
          });
        }
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const renderPostDropdown = (post) => {
    // Check if current user owns the post (either directly or via startup ownership)
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid // User owns the startup
      : post.user.id === currentUser.uid; // User's own post

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button
              onClick={() => handleEditPost(post)}
              className={classes.editPost}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                  clipRule="evenodd"
                />
              </svg>
              Edit
            </button>
            <button
              onClick={() => handleDeletePost(post.id)}
              className={classes.deletePost}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="gray"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                  clipRule="evenodd"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <button
            onClick={() => handleReportPost(post.id)}
            className={classes.reportPost}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${editingPost.user.id}/posts/${editingPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editingPost.id
            ? { ...post, text: updatedText, isEdited: isEdited }
            : post
        )
      );

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        isStartupModalOpen ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker
          ? {
              top: `-${
                activeEmojiPicker.includes("_")
                  ? commentEmojiPickerScrollPosition
                  : emojiPickerScrollPosition
              }px`,
            }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUser?.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={currentUser?.profileImage || profileImage} />
      )}
      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}

      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasAttemptedLoad && posts.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No comments found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            <div className={classes.postsContainer}>
              {posts.map((post) => {
                if (!post || !post.user || !post.commenter) return null; // Skip invalid posts

                return (
                  <div key={post.id} className={classes.postCard}>
                    <PostedByMessage post={post} currentUser={currentUser} />
                    <div className={classes.postHeader}>
                      <div className={classes.userInfo}>
                        <img
                          className={classes.postImage}
                          src={post.user.profileImage || defaultImage}
                          alt=""
                          onClick={() =>
                            post.user && handleNavigate(post.user.id)
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <div className={classes.userDetails}>
                          <div className={classes.userNameContainer}>
                            <p
                              className={classes.userName}
                              onClick={() =>
                                post.user && handleNavigate(post.user.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span className={classes.nameText}>
                                {`${post.user.firstName || ""} ${
                                  post.user.lastName || ""
                                }`.trim() || "Unknown User"}
                              </span>
                            </p>
                            {post.user.connectionType && (
                              <span className={classes.connectionType}>
                                • {post.user.connectionType}
                              </span>
                            )}
                          </div>
                          {post.user.bio && (
                            <p className={classes.bio}>{post.user.bio}</p>
                          )}
                          {post.user.link && (
                            <div className={classes.userLinkContainer}>
                              <a
                                href={post.user.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                className={classes.userLink}
                              >
                                <span className={classes.userLinkText}>
                                  {post.user.linkText || post.user.link}
                                </span>
                                <svg
                                  className={classes.userLinkIcon}
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="orangered"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            </div>
                          )}
                          <div className={classes.timeAndEditDetails}>
                            {post.createdAt && (
                              <p className={classes.time}>
                                {formatTimestamp(post.createdAt)}
                              </p>
                            )}
                            {post.isEdited && (
                              <div className={classes.editDot}>
                                •
                                <span className={classes.editedPostText}>
                                  Edited
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.postOptions}>
                        <svg
                          className={`${classes.optionsIcon} ${
                            showDropdown[post.id]
                              ? classes.optionsIconActive
                              : ""
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown((prev) => ({
                              ...prev,
                              [post.id]: !prev[post.id],
                            }));
                          }}
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeWidth="3"
                            d="M6 12h.01m6 0h.01m5.99 0h.01"
                          />
                        </svg>
                        {showDropdown[post.id] && renderPostDropdown(post)}
                      </div>
                    </div>
                    {post.text && renderPostText(post)}
                    {renderPostContent(post)}
                    {(hasInteractions(post) || post.commentCount > 0) && (
                      <div className={classes.postInteractionsInfo}>
                        {post.reactions &&
                          Object.keys(post.reactions).length > 0 && (
                            <div
                              className={classes.reactionsSummary}
                              onClick={() =>
                                handleReactionsClick(post.id, post.reactions)
                              }
                            >
                              <div className={classes.reactionStack}>
                                {getTopThreeEmojis(post.reactions)
                                  .reverse()
                                  .map((emoji, index) => (
                                    <div
                                      key={index}
                                      className={classes.reactionEmoji}
                                      style={{
                                        zIndex:
                                          getTopThreeEmojis(post.reactions)
                                            .length - index,
                                        fontSize: "var(--emoji-font-size)",
                                        marginLeft: "var(--emoji-margin-left)",
                                      }}
                                    >
                                      {emoji}
                                    </div>
                                  ))}
                              </div>
                              <span className={classes.reactionCount}>
                                {Object.keys(post.reactions).length}
                              </span>
                            </div>
                          )}
                        <div className={classes.rightInteractions}>
                          {post.commentCount > 0 && (
                            <span
                              className={`${classes.commentCount} ${
                                showComments[post.id]
                                  ? classes.activeCommentCount
                                  : ""
                              }`}
                              onClick={() => toggleComments(post.id)}
                            >
                              {post.commentCount} comment
                              {post.commentCount !== 1 ? "s" : ""}
                            </span>
                          )}
                          {post.commentCount > 0 &&
                            post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span className={classes.dot}>•</span>
                            )}
                          {post.reposts &&
                            Object.keys(post.reposts).length > 0 && (
                              <span
                                className={classes.repostCount}
                                onClick={() =>
                                  handleRepostsClick(post.id, post.reposts)
                                }
                              >
                                {Object.keys(post.reposts).length} repost
                                {Object.keys(post.reposts).length !== 1
                                  ? "s"
                                  : ""}
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                    <div className={classes.postInteractions}>
                      <StartupInteractionToggle
                        currentUser={{
                          ...currentUser,
                          firstName: post?.user?.firstName,
                          lastName: post?.user?.lastName,
                          bio: post?.user?.bio,
                        }}
                        onStartupSelect={setSelectedStartup}
                        selectedStartup={selectedStartup}
                        currentUserProfileImage={currentUserProfileImage}
                        onModalOpen={() => {
                          setStartupModalScrollPosition(window.scrollY);
                          setIsStartupModalOpen(true);
                        }}
                        onModalClose={() => {
                          setIsStartupModalOpen(false);
                          setTimeout(() => {
                            window.scrollTo(0, startupModalScrollPosition);
                          }, 0);
                        }}
                      />
                      <button
                        className={`${classes.interactionButton} ${
                          (selectedStartup &&
                            post.reactions?.[
                              `startup_${selectedStartup.id}`
                            ]) ||
                          (!selectedStartup &&
                            post.reactions?.[currentUser?.uid])
                            ? classes.emojiButton
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedStartup) {
                            if (
                              post.reactions?.[`startup_${selectedStartup.id}`]
                            ) {
                              handleEmojiSelect(
                                post.reactions[`startup_${selectedStartup.id}`],
                                post.id
                              );
                            } else {
                              setEmojiPickerScrollPosition(window.scrollY);
                              setMainEmojiPickerOpen(post.id); // Changed this line
                            }
                          } else {
                            if (post.reactions?.[currentUser?.uid]) {
                              handleEmojiSelect(
                                post.reactions[currentUser.uid],
                                post.id
                              );
                            } else {
                              setEmojiPickerScrollPosition(window.scrollY);
                              setMainEmojiPickerOpen(post.id); // Changed this line
                            }
                          }
                        }}
                      >
                        {selectedStartup ? (
                          post.reactions?.[`startup_${selectedStartup.id}`] ? (
                            <span>
                              {post.reactions[`startup_${selectedStartup.id}`]}
                            </span>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                              />
                            </svg>
                          )
                        ) : post.reactions?.[currentUser?.uid] ? (
                          <span>{post.reactions[currentUser?.uid]}</span>
                        ) : (
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                            />
                          </svg>
                        )}
                      </button>
                      <button
                        className={classes.interactionButton}
                        onClick={() => toggleComments(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                          />
                        </svg>
                      </button>

                      <button
                        className={`${classes.interactionButton} ${
                          post.reposts?.[currentUser?.uid]
                            ? classes.repostedButton
                            : ""
                        }`}
                        onClick={() => handleRepost(post.id)}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                          />
                        </svg>
                      </button>
                      <button className={classes.interactionButton}>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                          />
                        </svg>
                      </button>
                    </div>

                    {mainEmojiPickerOpen === post.id && (
                      <EmojiPicker
                        onEmojiClick={(emoji) =>
                          handleEmojiSelect(emoji, post.id)
                        }
                        onClose={() => {
                          setMainEmojiPickerOpen(null);
                          setTimeout(() => {
                            window.scrollTo(0, emojiPickerScrollPosition);
                          }, 0);
                        }}
                      />
                    )}
                    {showComments[post.id] && (
                      <div className={classes.commentsSection}>
                        <CommentInputSection
                          currentUser={currentUser}
                          selectedStartup={selectedStartup}
                          newComment={newComment[post.id] || ""}
                          onCommentChange={(e) =>
                            handleCommentInputChange(e, post.id)
                          }
                          onSubmit={() => handleAddComment(post.id)}
                          commentInputRef={(el) =>
                            (commentInputRef.current[post.id] = el)
                          }
                        />
                        <div className={classes.commentsList}>
                          {renderComments(post.id)}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {isReactionsModalOpen && activePostReactions && (
            <ReactionsModal
              onClose={() => {
                setShowReactionsModal((prev) => ({
                  ...prev,
                  [activePostReactions.postId]: false,
                }));
                setIsReactionsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, reactionsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReactions.postId}
              reactions={activePostReactions.reactions || {}}
              currentUserId={currentUser?.uid}
            />
          )}

          {isRepostsModalOpen && activePostReposts && (
            <RepostsModal
              onClose={() => {
                setShowRepostsModal((prev) => ({
                  ...prev,
                  [activePostReposts.postId]: false,
                }));
                setIsRepostsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, repostsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReposts.postId}
              reposts={activePostReposts.reposts || {}}
              currentUserId={currentUser?.uid}
            />
          )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default AllComments;
