import React, { useState, useCallback, useEffect, useRef } from "react";
import AddModeratorsModal from "../../../components/Modals/AddModeratorsModal";
import EditModeratorsModal from "../../../components/Modals/EditModeratorsModal";
import EditMembersModal from "../../../components/Modals/EditMembersModal";
import { useNavigate, useParams, Link } from "react-router-dom";
import defaultImage from "../../../assets/icons/communityImage.jpg";
import defaultProfileImage from "../../../assets/icons/profileImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Community.module.css";
import { firestore } from "../../../firebase";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import TagsModal from "../../../components/Modals/TagsModal";
import MembersModal from "../../../components/Modals/MembersModal";
import { useUpload } from "../../../contexts/UploadContext";
import EmojiPicker from "../../../components/Dropdowns/EmojiPicker";
import StartupInteractionToggle from "../../../components/Dropdowns/StartupInteractionToggle";
import PostModal from "../../../components/Modals/PostModal";
import EditSingleCommunityModal from "../../../components/Modals/EditSingleCommunityModal";
import IntroModal from "../../../components/Modals/IntroModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import AddMembersModal from "../../../components/Modals/AddMembersModal";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import {
  doc,
  getDoc,
  writeBatch,
  deleteField,
  collection,
  getDocs,
  setDoc,
  query,
  increment,
  where,
  orderBy,
  serverTimestamp,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";

const Community = () => {
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [removeMessage, setRemoveMessage] = useState(null);
  const { communityUrlSlug } = useParams();
  const navigate = useNavigate();
  const [isSafari, setIsSafari] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [communityData, setCommunityData] = useState({
    communityName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    communityImage: "",
    intro: "",
    introHtml: "",
    tags: [],
  });
  const [showEditMembersModal, setShowEditMembersModal] = useState(false);
  const [isEditMembersModalOpen, setIsEditMembersModalOpen] = useState(false);
  const [editMembersModalScrollPosition, setEditMembersModalScrollPosition] =
    useState(0);
  const [connectionTypes, setConnectionTypes] = useState({});
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [addMemberModalScrollPosition, setAddMemberModalScrollPosition] =
    useState(0);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [membersModalScrollPosition, setMembersModalScrollPosition] =
    useState(0);
  const [visiblePostsCount, setVisiblePostsCount] = useState(3);
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [tagsModalScrollPosition, setTagsModalScrollPosition] = useState(0);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [hasAvailableConnections, setHasAvailableConnections] = useState(false);
  const [showAddModeratorModal, setShowAddModeratorModal] = useState(false);
  const [showEditModeratorModal, setShowEditModeratorModal] = useState(false);
  const [isAddModeratorModalOpen, setIsAddModeratorModalOpen] = useState(false);
  const [isEditModeratorModalOpen, setIsEditModeratorModalOpen] =
    useState(false);
  const [inviteCount, setInviteCount] = useState(1);
  const [removeCount, setRemoveCount] = useState(1);
  const [currentModalType, setCurrentModalType] = useState(null);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [hasAvailableMemberConnections, setHasAvailableMemberConnections] =
    useState(false);
  const [posts, setPosts] = useState([]);
  const profileImage = defaultProfileImage; // Make sure defaultProfileImage is imported
  const [addModeratorModalScrollPosition, setAddModeratorModalScrollPosition] =
    useState(0);
  const [
    editModeratorModalScrollPosition,
    setEditModeratorModalScrollPosition,
  ] = useState(0);

  const [visibleModeratorsCount, setVisibleModeratorsCount] = useState(2);
  const [visibleTagsCount, setVisibleTagsCount] = useState(9);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [deleteTimeouts, setDeleteTimeouts] = useState({});
  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [showEditModeratorsModal, setShowEditModeratorsModal] = useState(false);
  const [
    editModeratorsModalScrollPosition,
    setEditModeratorsModalScrollPosition,
  ] = useState(0);
  const [isEditModeratorsModalOpen, setIsEditModeratorsModalOpen] =
    useState(false);
  const [moderators, setModerators] = useState([]);
  const [repostMessage, setRepostMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [communityNotFound, setcommunityNotFound] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [showEditSingleCommunityModal, setShowEditSingleCommunityModal] =
    useState(false);
  const [isEditSingleCommunityModalOpen, setIsEditSingleCommunityModalOpen] =
    useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [
    editSingleCommunityModalScrollPosition,
    setEditSingleCommunityModalScrollPosition,
  ] = useState(0);
  const [visibleMembersCount, setVisibleMembersCount] = useState(3);
  const [members, setMembers] = useState([]);
  const [hasMoreMembers, setHasMoreMembers] = useState(false);
  const [showDropdown, setShowDropdown] = useState({});
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [membershipStatus, setMembershipStatus] = useState("Join");
  const [hasReceivedInvite, setHasReceivedInvite] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isLoading, setIsLoading] = useState(true);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [showPostModal, setShowPostModal] = useState(false);
  const [communityPosts, setCommunityPosts] = useState([]);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [comments, setComments] = useState({});
  const [activeCommentPostId, setActiveCommentPostId] = useState(null);
  const [newComment, setNewComment] = useState({});
  const commentInputRef = useRef({});
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [videoVolumes, setVideoVolumes] = useState({});
  const videoRefs = useRef({});
  const [replyComments, setReplyComments] = useState({});
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [isCommunityMember, setIsCommunityMember] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [isIOS, setIsIOS] = useState(false);
  const [visibleComments, setVisibleComments] = useState({});
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [activeCommentId, setActiveCommentId] = useState(null);
  const replyInputRef = useRef(Object.create(null));
  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const [showInvitePopup, setShowInvitePopup] = useState(false);

  const [cancelMessage, setCancelMessage] = useState(null);
  const [successPostMessage, setSuccessPostMessage] = useState(null);

  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [visibleReplies, setVisibleReplies] = useState({});

  const getPostRef = (post) => {
    if (!post) return null;

    return doc(
      firestore,
      `users/${post.communityData.ownerId}/communities/${post.communityData.id}/posts/${post.communityPostId}`
    );
  };

  const fetchConnectionsCount = async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const snapshot = await getDocs(q);
      setConnectionsCount(snapshot.size);
    } catch (error) {
      console.error("Error fetching connections count:", error);
      setConnectionsCount(0);
    }
  };

  useEffect(() => {
    if (currentUser?.uid) {
      fetchConnectionsCount();
    }
  }, [currentUser?.uid]);

  const toggleAddMemberModal = () => {
    if (!showAddMemberModal) {
      setAddMemberModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addMemberModalScrollPosition);
      }, 0);
    }
    setShowAddMemberModal(!showAddMemberModal);
    setIsAddMemberModalOpen(!isAddMemberModalOpen);
  };

  const toggleEditModeratorsModal = () => {
    if (!showEditModeratorsModal) {
      setEditModeratorsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editModeratorsModalScrollPosition);
      }, 0);
    }
    setShowEditModeratorsModal(!showEditModeratorsModal);
    setIsEditModeratorsModalOpen(!isEditModeratorsModalOpen);
  };

  const toggleTagsModal = () => {
    if (!showTagsModal) {
      setTagsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, tagsModalScrollPosition);
      }, 0);
    }
    setShowTagsModal(!showTagsModal);
    setIsTagsModalOpen(!isTagsModalOpen);
  };

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  const checkAvailableMemberConnections = useCallback(async () => {
    if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
      return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get only ACTIVE moderators
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      // Get only actual members (not invited ones)
      const membersRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members`
      );
      const membersQuery = query(membersRef, where("status", "==", "Member"));
      const membersSnapshot = await getDocs(membersQuery);

      // Create a Set of excluded IDs (active moderators, actual members, and owner)
      const excludedIds = new Set([
        ...moderatorsSnapshot.docs.map((doc) => doc.id),
        ...membersSnapshot.docs.map((doc) => doc.id),
        communityData.ownerId,
      ]);

      // Check if there are any connections that aren't moderators, members, or owner
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !excludedIds.has(doc.id)
      );

      setHasAvailableMemberConnections(hasAvailable);
    } catch (error) {
      console.error("Error checking available member connections:", error);
    }
  }, [currentUser?.uid, communityData?.id, communityData?.ownerId]);
  useEffect(() => {
    checkAvailableMemberConnections();
  }, [checkAvailableMemberConnections]);

  const checkAvailableConnections = useCallback(async () => {
    if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
      return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get ONLY ACTIVE moderators
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      // Create a Set of only active moderators
      const activeModeratorIds = new Set(
        moderatorsSnapshot.docs.map((doc) => doc.id)
      );

      // Check if there are any connections that aren't active moderators
      // (pending invites don't count - we still want to show the addIcon)
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !activeModeratorIds.has(doc.id)
      );

      setHasAvailableConnections(hasAvailable);
    } catch (error) {
      console.error("Error checking available connections:", error);
    }
  }, [currentUser?.uid, communityData?.id, communityData?.ownerId]);

  const RemovePopup = ({ onClose, count = 1, type = "moderator", message }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>
          {message ||
            `${
              count > 1
                ? `${count} ${type}s removed.`
                : `${type.charAt(0).toUpperCase() + type.slice(1)} removed.`
            }`}
        </p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  useEffect(() => {
    checkAvailableConnections();
  }, [
    currentUser?.uid,
    communityData?.id,
    communityData?.ownerId,
    checkAvailableConnections,
  ]);

  // Add this component for the invite popup if you don't already have it
  const InvitePopup = ({ onClose, count = 1 }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{count > 1 ? "Invites sent." : "Invite sent."}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Add these modal toggle functions
  const toggleAddModeratorModal = () => {
    if (!showAddModeratorModal) {
      setAddModeratorModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addModeratorModalScrollPosition);
      }, 0);
    }
    setShowAddModeratorModal(!showAddModeratorModal);
    setIsAddModeratorModalOpen(!isAddModeratorModalOpen);
  };

  const toggleEditModeratorModal = () => {
    if (!showEditModeratorModal) {
      setEditModeratorModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editModeratorModalScrollPosition);
      }, 0);
    }
    setShowEditModeratorModal(!showEditModeratorModal);
    setIsEditModeratorModalOpen(!isEditModeratorModalOpen);
  };

  const fetchMembers = async () => {
    if (!communityData?.id || !communityData.ownerId) return;

    try {
      const membersRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members`
      );
      const membersQuery = query(membersRef, where("status", "==", "Member"));
      const membersSnapshot = await getDocs(membersQuery);

      const membersData = await Promise.all(
        membersSnapshot.docs.map(async (memberDoc) => {
          const userUUID = memberDoc.id;
          const memberData = memberDoc.data();

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
              id: memberDoc.id,
              userUUID: userUUID,
              joinedAt: memberData.joinedAt,
              status: memberData.status,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validMembers = membersData
        .filter((member) => member !== null)
        .sort((a, b) => b.joinedAt - a.joinedAt);

      setMembers(validMembers);
      setHasMoreMembers(validMembers.length > 3);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const handleRemoveModerator = async () => {
    if (!currentUser || !communityData.id || !communityData.ownerId) return;

    try {
      const batch = writeBatch(firestore);

      // Remove from moderators collection
      const moderatorRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators/${currentUser.uid}`
      );
      batch.delete(moderatorRef);

      // Remove from user's communitiesModerating collection
      const communityModeratingRef = doc(
        firestore,
        `users/${currentUser.uid}/communitiesModerating/${communityData.id}`
      );
      batch.delete(communityModeratingRef);

      await batch.commit();

      // Update local state
      setMembershipStatus("Join");
      setModerators((prev) => prev.filter((mod) => mod.id !== currentUser.uid));
    } catch (error) {
      console.error("Error removing moderator:", error);
      setErrorPopup({
        message: "An error occurred while leaving as moderator.",
        isError: true,
      });
    }
  };

  const handleShowMoreMembers = () => {
    if (visibleMembersCount > 3) {
      // Show less
      setVisibleMembersCount(3);
    } else {
      // Show more (2x previous amount)
      const nextCount = (visibleMembersCount - 3) * 2 + 6;
      setVisibleMembersCount(nextCount);
    }
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchMembers();
    }
  }, [communityData?.id]);

  const fetchModerators = async () => {
    if (!communityData.id || !communityData.ownerId) return;

    try {
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      const moderatorsData = await Promise.all(
        moderatorsSnapshot.docs.map(async (moderatorDoc) => {
          const userDoc = await getDoc(
            doc(firestore, "users", moderatorDoc.id)
          );
          if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
              id: moderatorDoc.id,
              userUUID: moderatorDoc.id,
              acceptedAt: moderatorDoc.data().acceptedAt, // Make sure we get the acceptedAt
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validModerators = moderatorsData.filter(Boolean).sort((a, b) => {
        // Convert timestamps to dates for comparison
        const dateA = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const dateB = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return dateA - dateB; // Oldest to newest
      });

      setModerators(validModerators);
    } catch (error) {
      console.error("Error fetching moderators:", error);
    }
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchModerators();
    }
  }, [communityData?.id]);

  const UploadingPost = React.memo(
    ({ postId, progress, status, onCancelUpload }) => {
      const handleCancelUpload = useCallback(() => {
        onCancelUpload(postId);
      }, [onCancelUpload, postId]);

      const roundedProgress = Math.round(progress);

      if (status === "cancelling") {
        return null;
      }

      return (
        <div className={classes.uploadingPost}>
          <div className={classes.uploadingHeader}>
            <span>Posting...</span>
            <div onClick={handleCancelUpload} className={classes.cancelButton}>
              Cancel
            </div>
          </div>
          <div className={classes.barAndText}>
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFill}
                style={{ width: `${roundedProgress}%` }}
              ></div>
            </div>
            <div className={classes.progressText}>{roundedProgress}%</div>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.status === nextProps.status &&
        Math.floor(prevProps.progress) === Math.floor(nextProps.progress) &&
        prevProps.postId === nextProps.postId
      );
    }
  );

  // Add this before the return statement in Community.js
  const fetchMemberCount = async (ownerId, communityId) => {
    try {
      const membersRef = collection(
        firestore,
        `users/${ownerId}/communities/${communityId}/members`
      );
      const q = query(membersRef, where("status", "==", "Member"));
      const querySnapshot = await getDocs(q);
      setMemberCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching member count:", error);
      setMemberCount(0);
    }
  };

  const checkMembershipStatus = async (
    currentUserId,
    profileCommunityId,
    communityOwnerId
  ) => {
    if (currentUserId === communityOwnerId) {
      setMembershipStatus("Owner");
      return;
    }

    try {
      // First check if they're a moderator
      const moderatorRef = doc(
        firestore,
        `users/${communityOwnerId}/communities/${profileCommunityId}/moderators/${currentUserId}`
      );
      const moderatorDoc = await getDoc(moderatorRef);

      if (moderatorDoc.exists() && moderatorDoc.data().status === "active") {
        setMembershipStatus("Moderator");
        return;
      }

      // If not a moderator, check member status
      const membershipRef = doc(
        firestore,
        `users/${communityOwnerId}/communities/${profileCommunityId}/members/${currentUserId}`
      );
      const membershipDoc = await getDoc(membershipRef);

      if (membershipDoc.exists()) {
        const status = membershipDoc.data().status;
        if (status === "Member") {
          setMembershipStatus("Member");
        } else if (status === "request_pending") {
          setMembershipStatus("Pending");
        } else {
          // For invite_pending or any other status, show Join
          setMembershipStatus("Join");
        }
      } else {
        setMembershipStatus("Join");
      }
    } catch (error) {
      console.error("Error checking membership status:", error);
      setMembershipStatus("Join");
    }
  };

  const handleJoin = async () => {
    if (!currentUser || !communityData.id) return;

    const batch = writeBatch(firestore);
    const membershipRef = doc(
      firestore,
      `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
    );

    try {
      if (membershipStatus === "Join") {
        // Get current user's data
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.data();

        if (communityData.isPrivate) {
          // For private communities - send join request
          batch.set(membershipRef, {
            status: "request_pending",
            requestedAt: serverTimestamp(),
          });

          // Create notification for community owner
          const notificationRef = doc(
            collection(
              firestore,
              `users/${communityData.ownerId}/notifications`
            )
          );

          batch.set(notificationRef, {
            type: "join_request",
            from: currentUser.uid,
            fromUserName: `${userData.firstName} ${userData.lastName}`,
            fromUserImage: userData.profileImage || defaultImage,
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage || defaultImage,
            communityUrlSlug: communityData.communityUrlSlug,
            createdAt: new Date(),
            isNew: true,
          });

          await batch.commit();
          setMembershipStatus("Pending");
        } else {
          // For public communities - directly add as member
          batch.set(membershipRef, {
            status: "Member",
            joinedAt: new Date(),
          });

          // Add to user's communitiesMemberOf collection
          const userCommunityRef = doc(
            firestore,
            `users/${currentUser.uid}/communitiesMemberOf/${communityData.id}`
          );

          batch.set(userCommunityRef, {
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage,
            communityUrlSlug: communityData.communityUrlSlug,
            ownerId: communityData.ownerId,
            joinedAt: new Date(),
            status: "Member",
          });

          // Create notification for community owner
          const notificationRef = doc(
            collection(
              firestore,
              `users/${communityData.ownerId}/notifications`
            )
          );

          batch.set(notificationRef, {
            type: "member_added",
            from: currentUser.uid,
            fromUserName: `${userData.firstName} ${userData.lastName}`,
            fromUserImage: userData.profileImage || defaultImage,
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage || defaultImage,
            communityUrlSlug: communityData.communityUrlSlug,
            message: `${userData.firstName} ${userData.lastName} is now a member.`,
            createdAt: new Date(),
            isNew: true,
          });

          // Delete any existing member invite notifications for this user
          const userNotificationsRef = collection(
            firestore,
            `users/${currentUser.uid}/notifications`
          );

          const memberInvitesQuery = query(
            userNotificationsRef,
            where("type", "==", "member_invite"),
            where("communityId", "==", communityData.id)
          );

          const memberInvitesSnapshot = await getDocs(memberInvitesQuery);
          memberInvitesSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          // Remove pending member status if it exists
          const memberRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
          );
          const memberDoc = await getDoc(memberRef);
          if (memberDoc.exists() && memberDoc.data().status === "Pending") {
            batch.delete(memberRef);
          }

          await batch.commit();
          setMembershipStatus("Member");
          setMemberCount((prev) => prev + 1);
          setIsCommunityMember(true);
        }
      } else if (membershipStatus === "Pending") {
        // Cancel pending request
        batch.delete(membershipRef);

        const notificationsRef = collection(
          firestore,
          `users/${communityData.ownerId}/notifications`
        );

        const q = query(
          notificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", communityData.id)
        );

        const notificationSnapshot = await getDocs(q);
        notificationSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        setMembershipStatus("Join");
      }
    } catch (error) {
      console.error("Error handling join:", error);
    }
  };

  // Update the useEffect where you fetch community data to include these:
  useEffect(() => {
    if (communityData.id && communityData.ownerId) {
      fetchMemberCount(communityData.ownerId, communityData.id);
    }
  }, [communityData.id, communityData.ownerId]);

  useEffect(() => {
    if (currentUser && communityData.id && communityData.ownerId) {
      checkMembershipStatus(
        currentUser.uid,
        communityData.id,
        communityData.ownerId
      );
    }
  }, [currentUser, communityData.id, communityData.ownerId]);

  const handleRemoveMember = async () => {
    if (!currentUser || !communityData.id || !communityData.ownerId) return;

    try {
      const batch = writeBatch(firestore);

      const memberRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
      );
      const userCommunityRef = doc(
        firestore,
        `users/${currentUser.uid}/communitiesMemberOf/${communityData.id}`
      );

      batch.delete(memberRef);
      batch.delete(userCommunityRef);

      const userNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const ownerNotificationsRef = collection(
        firestore,
        `users/${communityData.ownerId}/notifications`
      );

      // Only delete member-related notifications
      const memberNotificationsQuery = query(
        userNotificationsRef,
        where("communityId", "==", communityData.id),
        where("type", "in", [
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
          "member_invite",
          "member_invite_response",
        ])
      );
      const memberNotificationsSnapshot = await getDocs(
        memberNotificationsQuery
      );

      memberNotificationsSnapshot.forEach((doc) => {
        if (doc.exists()) {
          batch.delete(doc.ref);
        }
      });

      // Similarly, only delete member-related notifications from owner
      const ownerNotificationsQuery = query(
        ownerNotificationsRef,
        where("from", "==", currentUser.uid),
        where("communityId", "==", communityData.id),
        where("type", "in", [
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
        ])
      );
      const ownerNotificationsSnapshot = await getDocs(ownerNotificationsQuery);

      ownerNotificationsSnapshot.forEach((doc) => {
        if (doc.exists()) {
          batch.delete(doc.ref);
        }
      });

      await batch.commit();

      // Update local states
      setMemberCount((prev) => Math.max(0, prev - 1));
      setMembershipStatus("Join");
      setIsCommunityMember(false);
      // Filter out the current user from the members list
      setMembers((prevMembers) =>
        prevMembers.filter((member) => member.userUUID !== currentUser.uid)
      );
    } catch (error) {
      console.error("Error removing member:", error);
      setErrorPopup({
        message: "An error occurred while leaving the community.",
        isError: true,
      });
    }
  };

  const handleAddComment = async (postId, parentCommentId = null) => {
    console.log("Adding comment with:", {
      postId,
      parentCommentId,
      replyComments: replyComments[postId]?.[parentCommentId],
      availableComments: comments[postId],
    });

    if (!currentUser) return;

    const targetPost = communityPosts.find((p) => p.id === postId);
    if (!targetPost) return;

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[postId]?.[parentCommentId] || "";
    } else {
      commentText = newComment[postId] || "";
    }

    if (!commentText.trim()) return;

    try {
      let commenterInfo;
      if (selectedStartup) {
        const startupRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${selectedStartup.id}`
        );
        const startupDoc = await getDoc(startupRef);
        const startupData = startupDoc.data();

        commenterInfo = {
          id: `startup_${selectedStartup.id}`,
          name: selectedStartup.startupName,
          image: selectedStartup.startupImage,
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
          connectionType: "Startup",
          isStartup: true,
          startupId: selectedStartup.id,
          ownerId: currentUser.uid,
          startupUrlSlug: startupData.startupUrlSlug,
        };
      } else {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.data();

        commenterInfo = {
          id: currentUser.uid,
          name: `${userData.firstName} ${userData.lastName}`,
          image: userData.profileImage || profileImage,
          bio: userData.bio || "",
          link: userData.link || null,
          linkText: userData.linkText || null,
          connectionType: "You",
          isStartup: false,
          urlSlug: userData.urlSlug,
        };
      }

      const processedCommentText = convertUrlsToLinks(commentText);
      const basePath = `users/${communityData.ownerId}/communities/${communityData.id}`;
      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${targetPost.communityPostId}/comments`
      );
      const postRef = doc(
        firestore,
        `${basePath}/posts/${targetPost.communityPostId}`
      );
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();
      const rootCommentId = parentCommentId
        ? await getRootCommentId(
            targetPost,
            targetPost.communityPostId,
            parentCommentId
          )
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio,
        connectionType: commenterInfo.connectionType,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        postUserId: targetPost.user.id,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup
          ? {
              startupId: commenterInfo.startupId,
              startupOwnerId: commenterInfo.ownerId,
              startupUrlSlug: commenterInfo.startupUrlSlug,
            }
          : {
              urlSlug: commenterInfo.urlSlug,
            }),
      };

      const newCommentRef = await addDoc(commentsRef, {
        ...commentData,
        createdAt: serverTimestamp(),
      });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedCommunityPosts/${postId}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId: targetPost.user.startupOwnerId || targetPost.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId:
            targetPost.user.startupOwnerId || targetPost.user.id,
          postId: postId,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      const shouldNotifyPostOwner =
        !parentCommentId &&
        !(
          (!commenterInfo.isStartup &&
            currentUser.uid === targetPost.user.id) ||
          (commenterInfo.isStartup &&
            commenterInfo.ownerId === targetPost.user.id)
        );

      if (shouldNotifyPostOwner) {
        const notificationRef = doc(
          firestore,
          `users/${
            targetPost.user.startupOwnerId || targetPost.user.id
          }/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: postId,
          communityId: communityData.id, // Add this
          communityOwnerId: communityData.ownerId, // Add this
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(notificationRef, notificationData);
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          !(
            (commenterInfo.isStartup &&
              commentData.isStartup &&
              commenterInfo.startupId === parentCommentData.startupId) ||
            (commenterInfo.isStartup &&
              parentCommentData.userId === commenterInfo.startupOwnerId) ||
            (!commenterInfo.isStartup &&
              parentCommentData.userId === currentUser.uid) ||
            (!commenterInfo.isStartup &&
              parentCommentData.isStartup &&
              parentCommentData.startupOwnerId === currentUser.uid)
          );

        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          // Get the full post data from the post reference
          const postRef = doc(
            firestore,
            `${basePath}/posts/${targetPost.communityPostId}`
          );
          const postSnap = await getDoc(postRef);
          const fullPostData = postSnap.data();

          const replyNotificationData = {
            type: "reply",
            postId: postId,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: fullPostData.text || "",
              mediaType: fullPostData.content?.type || null,
              mediaUrl: fullPostData.content?.url || null,
              fileName: fullPostData.content?.fileName || null,
              ...(fullPostData.content?.type === "video" && {
                thumbnail: fullPostData.content.thumbnail,
              }),
            },
            originalPosterId: fullPostData.userId || fullPostData.user?.id,
            userId: fullPostData.userId || fullPostData.user?.id,
            reactionCount: Object.keys(fullPostData.communityReactions || {})
              .length,
            commentCount: fullPostData.commentCount || 0,
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          // Also add a debugging log to check what data we actually have
          console.log("Post data for notification:", {
            postId,
            postText: fullPostData.text,
            postContent: fullPostData.content,
            fullPost: fullPostData,
          });

          batch.set(replyNotificationRef, replyNotificationData);
        }
      }

      await batch.commit();

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId,
        postUserId: targetPost.user.id,
      };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [...(prevComments[postId] || []), newCommentObject],
      }));

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
        if (commentInputRef.current[postId]) {
          commentInputRef.current[postId].style.height = "auto";
        }
      }

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? { ...p, commentCount: (p.commentCount || 0) + 1 }
            : p
        )
      );

      setActiveCommentId((prev) => ({ ...prev, [postId]: null }));
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const checkConnectionType = async (userId, currentUserId) => {
    if (userId === currentUserId) return "You";

    try {
      // Check for direct connection
      const connectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${userId}`
      );
      const connectionDoc = await getDoc(connectionRef);

      if (
        connectionDoc.exists() &&
        connectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      // Check for indirect connections
      const userConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      for (const conn of userConnectionsSnapshot.docs) {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${userId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }

      return "Extended";
    } catch (error) {
      console.error("Error checking connection type:", error);
      return "Extended";
    }
  };

  useEffect(() => {
    const fetchConnectionTypes = async () => {
      if (!currentUser?.uid) return;

      const types = {};

      // Add owner
      if (communityData?.ownerId) {
        types[communityData.ownerId] = await checkConnectionType(
          communityData.ownerId,
          currentUser.uid
        );
      }

      // Add moderators
      for (const moderator of moderators) {
        if (!types[moderator.userUUID]) {
          types[moderator.userUUID] = await checkConnectionType(
            moderator.userUUID,
            currentUser.uid
          );
        }
      }

      // Add members
      for (const member of members) {
        if (!types[member.userUUID]) {
          types[member.userUUID] = await checkConnectionType(
            member.userUUID,
            currentUser.uid
          );
        }
      }

      // Add post authors
      for (const post of communityPosts) {
        if (!types[post.user.id]) {
          types[post.user.id] = await checkConnectionType(
            post.user.id,
            currentUser.uid
          );
        }
      }

      setConnectionTypes(types);
    };

    fetchConnectionTypes();
  }, [
    currentUser?.uid,
    communityData?.ownerId,
    moderators,
    members,
    communityPosts,
  ]);

  // Update fetchComments to use the correct path:

  const fetchComments = useCallback(
    async (postId) => {
      if (!communityData?.id || !postId) return;

      try {
        const targetPost = communityPosts.find((p) => p.id === postId);
        if (!targetPost) {
          console.error("Post not found:", postId);
          return;
        }

        // Use the community's path for accessing comments
        const commentsRef = collection(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
        );
        const q = query(commentsRef, orderBy("createdAt", "asc"));
        const snapshot = await getDocs(q);

        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();

            if (commentData.isStartup) {
              // Handle startup comments
              return {
                id: docSnapshot.id,
                ...commentData,
                userName: commentData.userName,
                userImage: commentData.userImage,
                connectionType: "Startup",
                bio: commentData.bio || "",
                link: commentData.link || null,
                linkText: commentData.linkText || null,
                postId,
                postUserId: targetPost.user.id,
                rootCommentId: commentData.rootCommentId,
                isRootComment: commentData.isRootComment,
                isStartup: true,
                startupId: commentData.startupId,
                startupOwnerId: commentData.startupOwnerId,
              };
            } else {
              // Handle regular user comments
              const userDoc = await getDoc(
                doc(firestore, "users", commentData.userId)
              );
              if (!userDoc.exists()) {
                console.log(
                  "User document not found for comment:",
                  docSnapshot.id
                );
                return null;
              }

              const userData = userDoc.data();
              const connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );

              return {
                id: docSnapshot.id,
                ...commentData,
                userName: `${userData.firstName} ${userData.lastName}`,
                userImage: userData.profileImage || defaultProfileImage,
                connectionType,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                urlSlug: userData.urlSlug,
                postId,
                postUserId: targetPost.user.id,
                rootCommentId: commentData.rootCommentId,
                isRootComment: commentData.isRootComment,
                isStartup: false,
              };
            }
          })
        );

        // Filter out any null values and update states
        const validComments = fetchedComments.filter(Boolean);

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: validComments,
        }));

        setVisibleComments((prev) => ({
          ...prev,
          [postId]: 10,
        }));

        // Update the post's comment count in local state
        setCommunityPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId ? { ...p, commentCount: validComments.length } : p
          )
        );
      } catch (error) {
        console.error("Error fetching comments:", error);
        setErrorPopup({
          message: "Error loading comments.",
          isError: true,
        });
      }
    },
    [
      currentUser?.uid,
      communityData?.id,
      communityData?.ownerId,
      checkConnectionType,
    ]
  );

  // Add this function to handle edit comment

  // Add helper function to strip HTML tags
  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    // If a comment section is active, ensure we have the comments
    if (activeCommentPostId && !comments[activeCommentPostId]) {
      fetchComments(activeCommentPostId);
    }
  }, [activeCommentPostId, comments, fetchComments]);

  const fetchCommunityPosts = async () => {
    if (!communityUrlSlug || !communityData?.id) return;

    try {
      const communityPostsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts`
      );
      const q = query(communityPostsRef, orderBy("createdAt", "desc"));
      const postsSnapshot = await getDocs(q);

      const fullPosts = await Promise.all(
        postsSnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();

          // Log the community post data to check commentCount
          console.log("Community Post Data:", postData);

          const basePath = postData.isStartup
            ? `users/${postData.userId}/startups/${postData.startupId}/communityPosts`
            : `users/${postData.userId}/communityPosts`;

          try {
            const fullPostRef = doc(
              firestore,
              `${basePath}/${postData.postId}`
            );
            const fullPostSnap = await getDoc(fullPostRef);

            if (!fullPostSnap.exists()) return null;

            // Get the comment count from the community's post document
            const communityPostRef = doc(
              firestore,
              `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postDoc.id}`
            );
            const communityPostSnap = await getDoc(communityPostRef);
            const communityPostData = communityPostSnap.data();

            // Log the data to see what we're getting
            console.log(
              "Community Post Data for ID:",
              postDoc.id,
              communityPostData
            );
            console.log("Comment Count:", communityPostData?.commentCount);

            const postWithReactions = {
              id: postData.postId,
              ...fullPostSnap.data(),
              _path: {
                isStartup: postData.isStartup,
                userId: postData.userId,
                startupId: postData.startupId,
              },
              communityReactions: fullPostSnap.data().communityReactions || {},
              commentCount: communityPostData?.commentCount || 0,
              communityPostId: postDoc.id,
            };

            // Log the final post object
            console.log("Final Post Object:", postWithReactions);

            return postWithReactions;
          } catch (error) {
            console.error("Error fetching individual post:", error);
            return null;
          }
        })
      );

      const validPosts = fullPosts.filter(Boolean);
      setCommunityPosts(validPosts);
    } catch (error) {
      console.error("Error fetching community posts:", error);
    }
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchCommunityPosts().then(() => {
        console.log("Community posts loaded:", communityPosts);
      });
    }
  }, [communityData?.id]);

  const hasInteractions = (post) => {
    if (!post) return false;
    const hasReactions =
      post.communityReactions &&
      Object.keys(post.communityReactions).length > 0;
    const hasComments = post.commentCount && post.commentCount > 0;
    const hasReposts = post.reposts && Object.keys(post.reposts).length > 0;
    return hasReactions || hasComments || hasReposts;
  };

  const toggleDropdown = (postId) => {
    setShowDropdown((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchCommunityPosts();
    }
  }, [communityData?.id]);

  // Update the PostModal close handler
  const handleClosePostModal = useCallback(
    (success, newPostId, isUploading, progress) => {
      setShowPostModal(false);
      setIsPostModalOpen(false);

      if (success && newPostId) {
        if (isUploading) {
          updateUploadProgress(newPostId, progress, "uploading");
        } else {
          removeUploadingPost(newPostId);
          fetchCommunityPosts();
        }
      }

      setTimeout(() => {
        window.scrollTo(0, postModalScrollPosition);
      }, 0);
    },
    [
      updateUploadProgress,
      removeUploadingPost,
      fetchCommunityPosts,
      postModalScrollPosition,
    ]
  );

  const handleCancelUpload = useCallback(
    async (postId) => {
      console.log(`Cancelling upload for post ${postId}`);
      cancelUpload(postId);
      updateUploadProgress(postId, 0, "cancelling");

      try {
        // Delete from Firestore - need to handle both user/startup paths
        let postRef;
        if (selectedStartup) {
          postRef = doc(
            firestore,
            `users/${currentUser?.uid}/startups/${selectedStartup.id}/communityPosts/${postId}`
          );
        } else {
          postRef = doc(
            firestore,
            `users/${currentUser?.uid}/communityPosts/${postId}`
          );
        }
        await deleteDoc(postRef);

        // Also delete from community's posts collection
        if (communityData?.id && communityData?.ownerId) {
          const communityPostRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postId}`
          );
          await deleteDoc(communityPostRef);
        }

        // Delete from Storage - note the communityContent path
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `communityContent/${communityData.id}/${currentUser?.uid}/${postId}`
        );

        try {
          // Delete all files in the directory
          const items = await listAll(storageRef);
          await Promise.all(
            items.items.map((itemRef) => deleteObject(itemRef))
          );
        } catch (error) {
          // Handle case where directory might not exist yet
          console.log("No files found to delete in storage:", error);
        }

        console.log(`Successfully cancelled and cleaned up post ${postId}`);
        setCancelMessage("Post cancelled.");
      } catch (error) {
        console.error(`Error cleaning up cancelled post ${postId}:`, error);
      } finally {
        removeUploadingPost(postId);
      }
    },
    [
      currentUser,
      selectedStartup,
      communityData?.id,
      communityData?.ownerId,
      cancelUpload,
      updateUploadProgress,
      removeUploadingPost,
    ]
  );

  const handlePostComplete = useCallback(
    (postId) => {
      removeUploadingPost(postId);
      fetchCommunityPosts(); // Your existing fetch function
    },
    [removeUploadingPost, fetchCommunityPosts]
  );

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const findPostById = useCallback(
    (postId) => {
      const post = communityPosts.find((p) => p.id === postId);
      console.log("Looking for post:", postId);
      console.log("Found post:", post);

      if (!post) return null;

      // Return post with the structure needed for commenting
      return {
        id: post.id,
        user: {
          id: post.userId,
          ...post.user,
          startupOwnerId: post.user?.startupOwnerId || currentUser?.uid,
          startupId: post._path?.startupId,
          isStartup: post._path?.isStartup || false,
        },
        ...post,
        _path: {
          isStartup: post._path?.isStartup || false,
          userId: post.userId,
          startupId: post._path?.startupId,
          communityId: post.communityData.id,
          communityOwnerId: post.communityData.ownerId,
        },
      };
    },
    [communityPosts, currentUser]
  );

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.postDot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  // Add this useEffect to handle textarea height adjustments
  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const SuccessPostPopup = ({ message, onClose, postId }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          <Link to={`/post/${postId}`} className={classes.viewPostLink}>
            View post
          </Link>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const CancelPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  // Add this function to handle edit comment
  const handleEditComment = (commentId, isReply = false) => {
    const commentToEdit = Object.values(comments)
      .flat()
      .find((c) => c.id === commentId);

    if (commentToEdit && commentToEdit.postId) {
      const post = communityPosts.find((p) => p.id === commentToEdit.postId);
      if (!post) {
        console.error("Post not found:", commentToEdit.postId);
        return;
      }

      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
      console.error("Comment or post not found for comment:", commentId);
    }
  };

  // Add this function to handle saving edited comments
  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const post = communityPosts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      // Use the community's path structure
      const commentRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.communityPostId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
        updatedAt: serverTimestamp(),
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const convertUrlsToLinks = (text) => {
    const urlRegex =
      /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
    return text.replace(urlRegex, (url) => {
      const fullUrl = url.startsWith("http") ? url : `https://${url}`;
      return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" style="color: orangered; font-weight: 900;">${url}</a>`;
    });
  };

  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[postId]?.[replyId];
    return !replyText || !replyText.trim();
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  // Add this useEffect to detect iOS devices
  useEffect(() => {
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const CommentInputSection = ({
    currentUser,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={currentUser?.profileImage || defaultProfileImage}
              alt="Your profile"
              className={classes.commentUserImage}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder="Comment as yourself..."
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  const ReplySection = ({
    comment,
    currentUser,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={currentUser?.profileImage || defaultProfileImage}
              alt="Your profile"
              className={classes.replyUserImage}
            />
          </div>
          <textarea
            ref={(el) => {
              if (replyInputRef && replyInputRef.current) {
                replyInputRef.current[comment.id] = el;
              }
            }}
            value={replyComments[comment.id] || ""}
            onChange={(e) => onReplyChange(e, comment.id)}
            placeholder="Reply as yourself..."
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={() => onSubmit(comment.id)}
          className={`${classes.addReplyButton} ${
            !replyComments[comment.id]?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleEditPost = (post) => {
    console.log("Original post for editing:", post);

    const postToEdit = {
      ...post,
      communityPostId: post.communityPostId,
      _path: {
        userId: post.user.startupOwnerId || post.user.id,
        startupId: post.user.startupId,
        isStartup: post.user.isStartup,
        communityId: communityData.id,
        communityOwnerId: communityData.ownerId,
      },
      user: {
        ...post.user,
        startupOwnerId: post.user.startupOwnerId || post.user.id,
      },
    };

    setEditingPost(postToEdit);
    setShowEditModal(true);
    setPostModalScrollPosition(window.scrollY);
    setIsPostModalOpen(true);
  };

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      if (!editingPost) return;

      // Determine the correct path based on whether it's a startup post
      let postRef;
      if (editingPost.user.isStartup) {
        postRef = doc(
          firestore,
          `users/${editingPost.user.startupOwnerId}/startups/${editingPost.user.startupId}/communityPosts/${editingPost.id}`
        );
      } else {
        postRef = doc(
          firestore,
          `users/${editingPost.user.id}/communityPosts/${editingPost.id}`
        );
      }

      // Also update the post in the community's posts collection
      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${editingPost.communityPostId}`
      );

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
        latestActivity: serverTimestamp(),
      };

      // Update both the user's/startup's post and the community post
      await Promise.all([
        updateDoc(postRef, updateData),
        updateDoc(communityPostRef, {
          lastUpdated: serverTimestamp(),
        }),
      ]);

      // Update local state
      setCommunityPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editingPost.id
            ? { ...post, text: updatedText, isEdited: isEdited }
            : post
        )
      );

      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);

      // Show success message
      setSuccessPostMessage({
        text: "Post updated successfully.",
        postId: editingPost.id,
      });
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  const handleDeletePost = async (postId) => {
    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    if (!currentUser) return;

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Get all comments first to track affected users and startups
      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      const batch = writeBatch(firestore);

      // Delete all comments and their associated notifications
      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();

        // Track users and startups affected by comments
        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
          affectedUserIds.add(commentData.startupOwnerId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        // Delete notifications for reactions to this comment
        if (commentData.reactions) {
          for (const [reactorId, emoji] of Object.entries(
            commentData.reactions
          )) {
            // Handle startup reactions
            if (reactorId.startsWith("startup_")) {
              const startupOwnerId = commentData.startupOwnerId;
              const notificationRef = doc(
                firestore,
                `users/${startupOwnerId}/notifications`,
                `comment_reaction_${commentDoc.id}_${reactorId}`
              );
              batch.delete(notificationRef);
            } else {
              // Handle user reactions
              const notificationRef = doc(
                firestore,
                `users/${commentData.userId}/notifications`,
                `comment_reaction_${commentDoc.id}_${reactorId}`
              );
              batch.delete(notificationRef);
            }
          }
        }

        // Delete comment notifications for post owner
        const commentNotificationRef = doc(
          firestore,
          `users/${targetPost.user.id}/notifications`,
          `comment_${commentDoc.id}`
        );
        batch.delete(commentNotificationRef);

        // Delete reply notifications for parent comment owner
        if (commentData.parentCommentId) {
          // Get parent comment data to find its owner
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);

          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            const parentOwnerId = parentCommentData.isStartup
              ? parentCommentData.startupOwnerId
              : parentCommentData.userId;

            const replyNotificationRef = doc(
              firestore,
              `users/${parentOwnerId}/notifications`,
              `reply_${commentDoc.id}`
            );
            batch.delete(replyNotificationRef);
          }
        }

        // Delete notifications for reactions to replies
        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentDoc.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);

        for (const replyDoc of repliesSnapshot.docs) {
          const replyData = replyDoc.data();
          if (replyData.reactions) {
            for (const [reactorId, emoji] of Object.entries(
              replyData.reactions
            )) {
              const replyOwnerId = replyData.isStartup
                ? replyData.startupOwnerId
                : replyData.userId;

              const reactionNotificationRef = doc(
                firestore,
                `users/${replyOwnerId}/notifications`,
                `comment_reaction_${replyDoc.id}_${reactorId}`
              );
              batch.delete(reactionNotificationRef);
            }
          }
        }
      }

      // Delete the post's reactions notifications
      if (targetPost.communityReactions) {
        for (const reactorId of Object.keys(targetPost.communityReactions)) {
          if (reactorId.startsWith("startup_")) {
            const startupId = reactorId.replace("startup_", "");
            const ownerDoc = await getDoc(
              doc(firestore, "users", targetPost.user.startupOwnerId)
            );
            if (ownerDoc.exists()) {
              const notificationRef = doc(
                firestore,
                `users/${ownerDoc.id}/notifications`,
                `community_reaction_${postId}_${reactorId}`
              );
              batch.delete(notificationRef);
            }
          } else {
            const notificationRef = doc(
              firestore,
              `users/${targetPost.user.id}/notifications`,
              `community_reaction_${postId}_${reactorId}`
            );
            batch.delete(notificationRef);
          }
        }
      }

      // Delete the post's repost notifications
      if (targetPost.reposts) {
        for (const reposterId of Object.keys(targetPost.reposts)) {
          const notificationRef = doc(
            firestore,
            `users/${targetPost.user.id}/notifications`,
            `community_repost_${postId}_${reposterId}`
          );
          batch.delete(notificationRef);
        }
      }

      // Delete all comments
      const deleteCommentsPromises = commentsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deleteCommentsPromises);

      // Delete storage content if exists
      if (targetPost.content && targetPost.content.type) {
        const storage = getStorage();
        const storageBasePath = `communityContent/${communityData.id}/${targetPost.user.id}/${postId}`;

        try {
          if (
            targetPost.content.type === "image" &&
            Array.isArray(targetPost.content.url)
          ) {
            for (const imageUrl of targetPost.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef).catch((error) =>
                console.error("Error deleting image:", error)
              );
            }
          } else if (
            ["image", "video", "pdf"].includes(targetPost.content.type)
          ) {
            const fileRef = ref(storage, targetPost.content.url);
            await deleteObject(fileRef).catch((error) =>
              console.error("Error deleting file:", error)
            );

            if (
              targetPost.content.type === "video" &&
              targetPost.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, targetPost.content.thumbnail);
              await deleteObject(thumbnailRef).catch((error) =>
                console.error("Error deleting thumbnail:", error)
              );
            }
          }

          const folderRef = ref(storage, storageBasePath);
          const folderContents = await listAll(folderRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error cleaning up storage:", error);
        }
      }

      // Delete the post from community
      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}`
      );
      batch.delete(communityPostRef);

      await batch.commit();

      // Update local state
      setCommunityPosts((prevPosts) =>
        prevPosts.filter((p) => p.id !== postId)
      );
      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      setDeleteMessage({
        text: "Post deleted successfully.",
        postId,
        isSuccess: true,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  const handleReportPost = (postId) => {
    console.log(`Reporting post with ID: ${postId}`);
  };

  const handleReactionsClick = (postId, communityReactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions: communityReactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleCommentsCountClick = (postId) => {
    toggleCommentInput(postId);
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: 10,
    }));
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handleEmojiSelect = useCallback(
    async (emoji, postId) => {
      if (!currentUser) return;

      try {
        const post = findPostById(postId);
        if (!post) {
          throw new Error("Post not found");
        }

        const basePath = post.user.isStartup
          ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
          : `users/${post.user.id}`;

        const postRef = doc(firestore, `${basePath}/communityPosts/${postId}`);
        const reactorId = selectedStartup
          ? `startup_${selectedStartup.id}`
          : currentUser.uid;

        const path = selectedStartup
          ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reactedCommunityPosts`
          : `users/${currentUser.uid}/reactedCommunityPosts`;

        const reactedPostRef = doc(firestore, `${path}/${postId}`);

        let updatedReactions = { ...(post.communityReactions || {}) };

        if (updatedReactions[reactorId] === emoji) {
          await updateDoc(postRef, {
            [`communityReactions.${reactorId}`]: deleteField(),
          });
          delete updatedReactions[reactorId];
          await deleteDoc(reactedPostRef);

          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `community_reaction_${postId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        } else {
          await updateDoc(postRef, {
            [`communityReactions.${reactorId}`]: emoji,
          });
          updatedReactions[reactorId] = emoji;

          await setDoc(reactedPostRef, {
            originalPosterId: post.user.startupOwnerId || post.user.id,
            postId: postId,
            reactedAt: serverTimestamp(),
            emoji: emoji,
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage || null,
          });

          const shouldNotify = post.user.isStartup
            ? post.user.startupOwnerId !== currentUser.uid ||
              (selectedStartup && post.user.startupId !== selectedStartup.id)
            : post.user.id !== currentUser.uid;

          // In handleEmojiSelect in Community.js
          if (shouldNotify) {
            const notificationRef = doc(
              firestore,
              `users/${post.user.startupOwnerId || post.user.id}/notifications`,
              `community_reaction_${postId}_${reactorId}`
            );

            // In handleEmojiSelect in Community.js
            const notificationData = {
              type: "reaction",
              postId: postId,
              communityId: communityData.id, // Add this
              communityOwnerId: communityData.ownerId, // Add this
              communityId: communityData.id,
              communityName: communityData.communityName,
              reactorId: reactorId,
              reactorName: selectedStartup
                ? selectedStartup.startupName
                : `${currentUser.firstName} ${currentUser.lastName}`,
              reactorImage: selectedStartup
                ? selectedStartup.startupImage
                : currentUserProfileImage || defaultImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              postPreview: {
                text: post.text,
                mediaType: post.content?.type || null,
                mediaUrl: post.content?.url || null,
                ...(post.content?.fileName && {
                  fileName: post.content.fileName,
                }),
                ...(post.content?.type === "video" && {
                  thumbnail: post.content.thumbnail,
                }),
              },
              originalPosterId: post.user.id || post.userId,
              userId: post.user.id || post.userId,
              reactionCount: Object.keys(post.communityReactions || {}).length,
              commentCount: post.commentCount || 0,
              ...(selectedStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage: selectedStartup.startupImage,
              }),
            };

            // Also add a debugging log to check what data we actually have
            console.log("Post data for notification:", {
              postId,
              postText: post.text,
              postContent: post.content,
              fullPost: post,
            });

            await setDoc(notificationRef, notificationData);
          }
        }

        setCommunityPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId ? { ...p, communityReactions: updatedReactions } : p
          )
        );

        setActiveEmojiPicker(null);
        setTimeout(() => {
          window.scrollTo(0, emojiPickerScrollPosition);
        }, 0);
      } catch (error) {
        console.error("Error updating reaction:", error);
        if (error.message === "Post not found") {
          setErrorPopup({
            message: "This post has been deleted or is no longer available.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: "An error occurred while updating the reaction.",
            isError: true,
          });
        }
      }
    },
    [currentUser, selectedStartup, communityData, findPostById]
  );

  // Update handleReactionClick to receive postId and access post data correctly
  const handleReactionClick = async (postId) => {
    const post = findPostById(postId);
    if (!post) return;

    if (post.communityReactions?.[currentUser?.uid]) {
      handleEmojiSelect(post.communityReactions[currentUser.uid], postId);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(
        activeEmojiPicker === `post_${postId}` ? null : `post_${postId}`
      );
    }
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const postRef = getPostRef(post);
      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;
      const repostedPostRef = doc(firestore, `${path}/reposts/${postId}`);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          repostedAt: now,
        });

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reposts: updatedReposts } : p
        )
      );
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setErrorPopup({
        message: "An error occurred while updating the repost.",
        isError: true,
      });
    }
  };

  const fetchCommunityMembershipStatus = async () => {
    if (!currentUser || !communityData?.id) return;
    try {
      const membershipRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
      );
      const membershipDoc = await getDoc(membershipRef);
      setIsCommunityMember(membershipDoc.exists());
    } catch (error) {
      console.error("Error fetching membership status:", error);
    }
  };

  useEffect(() => {
    fetchCommunityMembershipStatus();
  }, [currentUser, communityData?.id]);

  const renderPostText = (post) => {
    if (!post || !post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractions =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractions || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <div
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              togglePostExpansion(post.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    // Cleanup previous observations
    const cleanup = () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };

    // Observe all current video elements
    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    // Cleanup on unmount or when posts/visiblePostsCount changes
    return cleanup;
  }, [communityPosts, visiblePostsCount]); // Add visiblePostsCount as a dependency

  const renderPostContent = (post) => {
    if (!post?.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.imageCarousel}>
            {Array.isArray(post.content.url) ? (
              <>
                <img
                  key={currentImageIndex[post.id] || 0}
                  src={post.content.url[currentImageIndex[post.id] || 0]}
                  alt=""
                  className={classes.postContentImage}
                  onClick={() =>
                    handleNextImage(post.id, post.content.url.length)
                  }
                />
                {post.content.url.length > 1 && (
                  <div className={classes.dotNavigation}>
                    {post.content.url.map((_, index) => (
                      <span
                        key={index}
                        className={`${classes.dotNav} ${
                          index === (currentImageIndex[post.id] || 0)
                            ? classes.activeDot
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentImageIndex((prev) => ({
                            ...prev,
                            [post.id]: index,
                          }));
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <img
                src={post.content.url}
                alt=""
                className={classes.postContentImage}
              />
            )}
          </div>
        );
      case "video":
        return (
          <div className={classes.videoContainer}>
            <video
              ref={(el) => (videoRefs.current[post.id] = el)}
              className={classes.postContentVideo}
              playsInline
              muted={videoVolumes[post.id] !== true}
              loop
              autoPlay
              preload="metadata"
              poster={post.content.thumbnail || ""}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
            >
              <source src={post.content.url} type="video/mp4" />
            </video>
            <div className={classes.videoControls}>
              <button
                className={classes.volumeControl}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVolume(post.id);
                }}
              >
                {videoVolumes[post.id] !== true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        );
      case "pdf":
        return (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              (!post.reactions || Object.keys(post.reactions).length === 0) &&
              (!post.reposts || Object.keys(post.reposts).length === 0) &&
              (!post.commentCount || post.commentCount === 0)
                ? classes.pdfPreviewNoInteractions
                : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        );
      default:
        return null;
    }
  };

  const togglePostExpansion = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const toggleVolume = (postId) => {
    setVideoVolumes((prev) => {
      const newVolumes = { ...prev };
      newVolumes[postId] = !prev[postId];
      return newVolumes;
    });

    const video = videoRefs.current[postId];
    if (video) {
      video.muted = !video.muted;
    }
  };

  const handleNavigate = async (userId) => {
    if (!userId) return;
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Add new handlers for post interactions
  // In Community.js, modify the handleAddPost function
  const handleAddPost = () => {
    if (!currentUser) return;

    // Clear any existing timeouts for delete messages
    Object.values(deleteTimeouts).forEach(clearTimeout);
    setDeleteTimeouts({});

    // Set the scroll position before opening modal
    setPostModalScrollPosition(window.scrollY);
    setShowPostModal(true);
    setIsPostModalOpen(true);
  };

  const toggleCommentInput = useCallback(
    (postId) => {
      setActiveCommentPostId((prevId) => (prevId === postId ? null : postId));
      // Fetch comments immediately when opening the comments section
      if (!comments[postId]) {
        fetchComments(postId);
      }
    },
    [comments, fetchComments]
  );

  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({
      ...prev,
      [postId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const getRootCommentId = async (post, postId, commentId) => {
    try {
      if (!post || !commentId || !communityData?.id) {
        throw new Error("Missing required data for getRootCommentId");
      }

      // Use the community owner's path, not the current user's path
      const basePath = `users/${communityData.ownerId}/communities/${communityData.id}`;
      const commentsPath = `${basePath}/posts/${post.communityPostId}/comments/${commentId}`;

      console.log("Accessing comment at path:", commentsPath);
      console.log("Looking for comment:", commentId);
      console.log("In post:", post.communityPostId);

      const commentRef = doc(firestore, commentsPath);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        // Debug: Get all comments in this post to see what's available
        const allCommentsRef = collection(
          firestore,
          `${basePath}/posts/${post.communityPostId}/comments`
        );
        const allCommentsSnap = await getDocs(allCommentsRef);
        console.log(
          "Available comments:",
          allCommentsSnap.docs.map((doc) => doc.id)
        );

        throw new Error(`Comment not found at path: ${commentsPath}`);
      }

      const commentData = commentSnap.data();
      console.log("Found comment data:", commentData);

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(
        post,
        post.communityPostId,
        commentData.parentCommentId
      );
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const handleReaction = async (postId, emoji) => {
    if (!currentUser) return;

    try {
      const post = communityPosts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      const basePath = post.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const postRef = doc(firestore, `${basePath}/communityPosts/${postId}`);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      await updateDoc(postRef, {
        [`communityReactions.${reactorId}`]: emoji,
      });

      // Update local state
      setCommunityPosts((prev) =>
        prev.map((p) =>
          p.id === postId
            ? {
                ...p,
                communityReactions: {
                  ...p.communityReactions,
                  [reactorId]: emoji,
                },
              }
            : p
        )
      );
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  const toggleEditSingleCommunityModal = () => {
    if (!showEditSingleCommunityModal) {
      setEditSingleCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editSingleCommunityModalScrollPosition);
      }, 0);
    }
    setShowEditSingleCommunityModal(!showEditSingleCommunityModal);
    setIsEditSingleCommunityModalOpen(!isEditSingleCommunityModalOpen);
  };

  const handleCommunityUpdate = (updatedCommunity) => {
    setCommunityData((prevData) => ({
      ...prevData,
      ...updatedCommunity,
    }));
  };

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderIntroText = () => {
    const introText = communityData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: introText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: true,
      };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatJoinDate = (date) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinal = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return `${month} ${day}${getOrdinal(day)} ${year}`;
  };

  const checkOwnership = useCallback(
    async (communityData) => {
      if (!currentUser) {
        setIsOwner(false);
        return;
      }

      try {
        const userCommunitiesRef = collection(
          firestore,
          `users/${currentUser.uid}/communities`
        );
        const communitiesQuery = query(
          userCommunitiesRef,
          where("communityUrlSlug", "==", communityUrlSlug)
        );
        const communitiesSnapshot = await getDocs(communitiesQuery);

        setIsOwner(!communitiesSnapshot.empty);
      } catch (error) {
        console.error("Error checking community ownership:", error);
        setIsOwner(false);
      }
    },
    [currentUser, communityUrlSlug]
  );

  const fetchCommunityData = async (urlSlug) => {
    try {
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let communityFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const communitiesRef = collection(
          firestore,
          `users/${userDoc.id}/communities`
        );
        const communitiesQuery = query(
          communitiesRef,
          where("communityUrlSlug", "==", urlSlug)
        );
        const communitiesSnapshot = await getDocs(communitiesQuery);

        if (!communitiesSnapshot.empty) {
          communityFound = true;
          const communityDoc = communitiesSnapshot.docs[0];
          const data = communityDoc.data();

          // Get owner's user document
          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          setCommunityData({
            id: communityDoc.id,
            ownerId: userDoc.id,
            communityName: data.communityName || "",
            tagline: data.tagline || "",
            link: data.link || "",
            linkText: data.linkText || "",
            communityImage: data.communityImage || "",
            industry: data.industry || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            tags: data.tags || [],
            communityUrlSlug: data.communityUrlSlug || "",
            isPrivate: data.isPrivate || false, // Add this line
            createdAt: data.createdAt, // Make sure this line is present
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              bio: ownerData.bio || "",
              link: ownerData.link || "",
              linkText: ownerData.linkText || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
            },
          });
          break;
        }
      }

      if (!communityFound) {
        setcommunityNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching community data:", error);
      setcommunityNotFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let unsubscribe;
    let isMounted = true;

    const initializeApp = async () => {
      try {
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!isMounted) return;

          if (user) {
            // Fetch full user data including profile image
            const userDoc = await getDoc(doc(firestore, "users", user.uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setCurrentUser({
                ...user,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage || defaultImage,
              });
              setCurrentUserProfileImage(userData.profileImage || defaultImage);
            } else {
              setCurrentUser(user);
              setCurrentUserProfileImage(defaultImage);
            }
          } else {
            setCurrentUser(null);
            setCurrentUserProfileImage(null);
            setIsOwner(false);
          }

          if (communityUrlSlug) {
            await fetchCommunityData(communityUrlSlug);
          }
        });
      } catch (error) {
        console.error("Error initializing app:", error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initializeApp();

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [communityUrlSlug]);

  useEffect(() => {
    const checkCommunityOwnership = async () => {
      if (currentUser && communityUrlSlug) {
        await checkOwnership();
      }
    };

    checkCommunityOwnership();
  }, [currentUser, communityUrlSlug, checkOwnership]);

  const renderDescriptionCard = () => {
    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            !communityData.introHtml && isOwner ? classes.emptyCardTitle : ""
          }`}
        >
          About
          {isOwner && (
            <div onClick={toggleIntroModal}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={classes.introText}>
          {communityData.introHtml ? (
            <>
              <pre>
                {renderIntroText().text}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={() => setShowFullIntro(true)}
                  >
                    ...more
                  </span>
                )}
              </pre>
              <div className={classes.noPostBorder}></div>
            </>
          ) : (
            isOwner && (
              <>
                <span className={classes.emptyIntroText}>
                  Tell everyone about your community. What brings people here?
                  What can they expect? Share your community's story.
                </span>
                <div className={classes.noPostBorder}></div>
              </>
            )
          )}
        </div>

        <div className={classes.cardAbout}>
          {/* Privacy Status */}
          <div className={classes.skillContainer}>
            {communityData.isPrivate ? (
              <>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z" />
                  <path d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                  <path d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                </svg>
                <span className={classes.skillText}>Private</span>
              </>
            ) : (
              <>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>Public</span>
              </>
            )}
          </div>

          {/* Created Date */}
          {communityData.createdAt && (
            <div className={classes.skillContainer}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M14 19V5h4a1 1 0 0 1 1 1v11h1a1 1 0 0 1 0 2h-6Z" />
                <path
                  fill-rule="evenodd"
                  d="M12 4.571a1 1 0 0 0-1.275-.961l-5 1.428A1 1 0 0 0 5 6v11H4a1 1 0 0 0 0 2h1.86l4.865 1.39A1 1 0 0 0 12 19.43V4.57ZM10 11a1 1 0 0 1 1 1v.5a1 1 0 0 1-2 0V12a1 1 0 0 1 1-1Z"
                  clip-rule="evenodd"
                />
              </svg>

              <span className={classes.skillText}>
                {formatJoinDate(
                  communityData.createdAt?.toDate?.() ||
                    new Date(communityData.createdAt)
                )}
              </span>
            </div>
          )}

          {/* Community URL */}
          {communityData.communityUrlSlug && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              </svg>
              <span className={classes.skillText}>
                {`foundry.bz/community/${communityData.communityUrlSlug}`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const toggleShowAllKeywords = () => setShowAllKeywords(true);

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const renderCommentOptions = (comment, postId) => {
    const isOwnComment =
      (selectedStartup &&
        comment.isStartup &&
        selectedStartup.id === comment.startupId) ||
      (!selectedStartup &&
        !comment.isStartup &&
        comment.userId === currentUser.uid);

    const post = communityPosts.find((p) => p.id === postId);
    const isPostOwner = post?.user?.id === currentUser?.uid;

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() => handleEditComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                clipRule="evenodd"
              />
            </svg>
            Edit
          </button>
        )}
        {(isOwnComment || isPostOwner) && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                clipRule="evenodd"
              />
            </svg>
            Delete
          </button>
        )}
        {!isOwnComment && !isPostOwner && (
          <button
            className={classes.reportCommentButton}
            onClick={() => handleReportComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const renderComments = (postId) => {
    if (!communityPosts || !comments[postId]) return null;

    const postComments = comments[postId];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {comment.userId === currentUser.uid ||
                      (comment.isStartup &&
                        comment.startupOwnerId === currentUser.uid) ? (
                        <>
                          <button
                            className={classes.editCommentButton}
                            onClick={() => handleEditComment(comment.id)}
                          >
                            <svg>...</svg>
                            Edit
                          </button>
                          <button
                            className={classes.deleteCommentButton}
                            onClick={() =>
                              handleDeleteComment(postId, comment.id)
                            }
                          >
                            <svg>...</svg>
                            Delete
                          </button>
                        </>
                      ) : currentUser.uid === communityData.ownerId ||
                        moderators.some((mod) => mod.id === currentUser.uid) ? (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg>...</svg>
                          Delete
                        </button>
                      ) : (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() => handleReportComment(comment.id)}
                        >
                          <svg>...</svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.postDot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}
              {activeEmojiPicker === `comment_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}
              {activeCommentId === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments[postId] || {}}
                  onReplyChange={(e) =>
                    handleReplyInputChange(e, postId, comment.id)
                  }
                  onSubmit={() => handleAddComment(postId, comment.id)}
                  replyInputRef={replyInputRef}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    if (!communityPosts || !comments[postId]) return null;

    const postComments = comments[postId];
    const replies = postComments.filter(
      (reply) => reply.rootCommentId === rootCommentId && !reply.isRootComment
    );

    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || defaultImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {reply.userId === currentUser.uid ||
                      (reply.isStartup &&
                        reply.startupOwnerId === currentUser.uid) ? (
                        <>
                          <button
                            className={classes.editCommentButton}
                            onClick={() => handleEditComment(reply.id, true)}
                          >
                            <svg>...</svg>
                            Edit
                          </button>
                          <button
                            className={classes.deleteCommentButton}
                            onClick={() =>
                              handleDeleteComment(postId, reply.id)
                            }
                          >
                            <svg>...</svg>
                            Delete
                          </button>
                        </>
                      ) : currentUser.uid === communityData.ownerId ||
                        moderators.some((mod) => mod.id === currentUser.uid) ? (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(postId, reply.id)}
                        >
                          <svg>...</svg>
                          Delete
                        </button>
                      ) : (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() => handleReportComment(reply.id)}
                        >
                          <svg>...</svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleReplyReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `reply_${reply.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUser?.profileImage || defaultImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.uid
                          )
                        }
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[postId]?.[reply.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, reply.id)
                      }
                      placeholder={`Reply as ${
                        selectedStartup
                          ? selectedStartup.startupName
                          : "yourself"
                      }...`}
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(postId, reply.id)}
                    className={`${classes.addReplyButton} ${
                      !replyComments[postId]?.[reply.id]?.trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  const handleReportComment = (commentId) => {
    console.log(`Reporting comment: ${commentId}`);
    setShowDropdown(false); // Close the dropdown after clicking
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}y`;
    }
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown((prev) => (prev === commentId ? null : commentId));
  };

  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) return;

    try {
      const targetPost = findPostById(postId);
      if (!targetPost) {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      // Check if user is community owner or moderator first
      const isOwnerOrModerator =
        currentUser.uid === communityData.ownerId ||
        moderators.some(
          (mod) => mod.id === currentUser.uid && mod.status === "active"
        );

      if (isOwnerOrModerator) {
        canDelete = true;
      } else if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid || // Comment owner (if startup)
          currentUser.uid === targetPost.user.id || // Post owner
          (targetPost.user.isStartup &&
            currentUser.uid === targetPost.user.startupOwnerId); // Post owner (if startup post)
      } else {
        canDelete =
          currentUser.uid === commentData.userId || // Comment owner (if user)
          currentUser.uid === targetPost.user.id || // Post owner
          (targetPost.user.isStartup &&
            currentUser.uid === targetPost.user.startupOwnerId); // Post owner (if startup post)
      }

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {
          console.error("Error in deleteStartupNotifications:", error);
        }
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        const reactionNotificationsQuery = query(
          collection(firestore, `users/${commentData.userId}/notifications`),
          where("type", "==", "comment_reaction"),
          where("commentId", "==", commentRef.id)
        );
        const reactionNotificationsSnapshot = await getDocs(
          reactionNotificationsQuery
        );
        await Promise.all(
          reactionNotificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
        );

        const postOwnerId = targetPost.user.isStartup
          ? targetPost.user.startupOwnerId
          : targetPost.user.id;
        if (commentData.userId !== postOwnerId) {
          await deleteDoc(
            doc(
              firestore,
              `users/${postOwnerId}/notifications/comment_${commentRef.id}`
            )
          );
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (parentCommentData.userId !== commentData.userId) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      const batch = writeBatch(firestore);
      await deleteCommentsAndReplies(commentToDeleteRef);

      const postRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}`
      );
      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      await batch.commit();

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) => {
          if (p.id === postId) {
            return {
              ...p,
              commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
            };
          }
          return p;
        })
      );

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || !comment.text) return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.postId, comment.id, false)}
        </div>
      );
    }

    const text = comment.text;
    const isLongComment = text.length > 149;
    const displayText = expandedComments[comment.id]
      ? text
      : text.slice(0, 149);

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={() => toggleCommentExpansion(comment.id)}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const handleCommentReactionClick = (commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `comment_${commentId}` ? null : `comment_${commentId}`
    );
  };

  const handleReplyReactionClick = (replyId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker((prev) =>
      prev === `reply_${replyId}` ? null : `reply_${replyId}`
    );
  };

  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser || !commentId) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.communityPostId}/comments`
      );
      const commentRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const notificationRef = doc(
          firestore,
          `users/${
            commentData.isStartup
              ? commentData.startupOwnerId
              : commentData.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const shouldNotify =
          (selectedStartup &&
            !(
              commentData.userId === currentUser.uid ||
              (commentData.isStartup &&
                commentData.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentData.isStartup
              ? commentData.startupOwnerId !== currentUser.uid
              : commentData.userId !== currentUser.uid));

        if (shouldNotify) {
          const postRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.communityPostId}`
          );
          const postSnap = await getDoc(postRef);
          const fullPostData = postSnap.data();

          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: postId,
            communityId: communityData.id, // Add this
            communityOwnerId: communityData.ownerId, // Add this
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUser.profileImage || defaultImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: fullPostData.text || "",
              mediaType: fullPostData.content?.type || null,
              mediaUrl: fullPostData.content?.url || null,
              fileName: fullPostData.content?.fileName || null,
              ...(fullPostData.content?.type === "video" && {
                thumbnail: fullPostData.content.thumbnail,
              }),
            },
            originalPosterId: post.user.id,
            userId: post.user.id,
            reactionCount: Object.keys(post.communityReactions || {}).length,
            commentCount: post.commentCount || 0,
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment reaction:", error);
      if (error.message === "Post not found") {
        setErrorPopup({
          message: "This post has been deleted.",
          isError: true,
        });
      } else if (error.message === "Comment not found") {
        setErrorPopup({
          message: "This comment has been deleted.",
          isError: true,
        });
      } else {
        setErrorPopup({
          message: "An error occurred while updating the reaction.",
          isError: true,
        });
      }
    }
  };

  const handleShare = () => {
    const communityUrl = `${window.location.origin}/community/${communityData.communityUrlSlug}`;
    navigator.clipboard
      .writeText(communityUrl)
      .then(() => {
        setShareButtonText("Copied");
        setTimeout(() => {
          setShareButtonText("Share");
        }, 5000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const handleReplyButton = (commentId) => {
    setActiveCommentId((prevId) => (prevId === commentId ? null : commentId));
    // Initialize empty reply for this comment if it doesn't exist
    setReplyComments((prev) => ({
      ...prev,
      [commentId]: prev[commentId] || "",
    }));
  };

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  const handleReplyInputChange = (e, postId, commentId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const toggleEditMembersModal = () => {
    if (!showEditMembersModal) {
      setEditMembersModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editMembersModalScrollPosition);
      }, 0);
    }
    setShowEditMembersModal(!showEditMembersModal);
    setIsEditMembersModalOpen(!isEditMembersModalOpen);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(
    currentUser?.profileImage || defaultImage
  );

  const shouldShowContent = (contentType) => {
    const isModerator = moderators.some((mod) => mod.id === currentUser?.uid);

    switch (contentType) {
      case "topCard":
        return isOwner || isModerator || isCommunityMember;
      case "posts":
        return isOwner || isModerator || isCommunityMember;
      case "tags":
        return (
          isOwner ||
          (!isOwner && communityData.tags && communityData.tags.length > 0)
        );
      case "intro":
        return true;
      case "editButtons":
        return isOwner;
      default:
        return false;
    }
  };

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}

        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (communityNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        isStartupModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions || {}}
          currentUserId={currentUser?.uid}
        />
      )}
      {showAddMemberModal && (
        <AddMembersModal
          onClose={toggleAddMemberModal}
          currentUser={currentUser}
          communityData={communityData}
          onMemberInviteSent={(count) => {
            setInviteCount(count);
            setShowInvitePopup(true);
          }}
        />
      )}
      {showRemovePopup && currentModalType === "moderator" && (
        <RemovePopup
          onClose={() => {
            setShowRemovePopup(false);
            setRemoveMessage(null); // Clear the message when closing
          }}
          count={removeCount}
          type="moderator"
          message={removeMessage?.text}
        />
      )}

      {showInvitePopup && (
        <InvitePopup
          onClose={() => setShowInvitePopup(false)}
          count={inviteCount}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          onClose={toggleConnectionsModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isModeratorSelection={true} // Enable moderator selection mode
          isEditModerators={false} // We're adding new moderators
          communityData={communityData}
          moderators={moderators}
          onModeratorInviteSent={() => setShowInvitePopup(true)} // Show popup when invite sent
          onModeratorRemoved={() => {
            fetchModerators();
          }}
        />
      )}

      {showEditModeratorsModal && (
        <ConnectionsModal
          onClose={toggleEditModeratorsModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isModeratorSelection={false} // Disable selection mode
          isEditModerators={true} // Enable edit mode
          communityData={communityData}
          moderators={moderators}
          onModeratorRemoved={() => {
            fetchModerators();
          }}
        />
      )}
      {showTagsModal && (
        <TagsModal
          onClose={toggleTagsModal}
          currentUser={currentUser}
          onTagsUpdate={handleCommunityUpdate}
          communityId={communityData.id}
          communityOwnerId={communityData.ownerId}
        />
      )}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}
      {showEditMembersModal && (
        <EditMembersModal
          onClose={() => {
            toggleEditMembersModal();
          }}
          communityData={communityData}
          currentUser={currentUser}
          onMemberRemoved={(updatedMembers, removedCount) => {
            setMembers(updatedMembers);
            setMemberCount(memberCount - removedCount);
            checkAvailableMemberConnections(); // Add this line
          }}
        />
      )}
      {cancelMessage && (
        <CancelPopup
          message={cancelMessage}
          onClose={() => setCancelMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {showEditSingleCommunityModal && (
        <EditSingleCommunityModal
          community={communityData}
          onClose={toggleEditSingleCommunityModal}
          onUpdate={handleCommunityUpdate}
          currentUser={currentUser}
          isFromCommunityPage={true} // Add this prop
        />
      )}

      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isCommunity={true}
          communityData={communityData}
          communityOwnerId={communityData.ownerId}
          onProfileUpdate={(updatedData) => {
            setCommunityData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={
                  shouldShowContent("editButtons")
                    ? toggleEditSingleCommunityModal
                    : undefined
                }
                style={{
                  cursor: shouldShowContent("editButtons")
                    ? "pointer"
                    : "default",
                }}
              >
                <img
                  src={communityData.communityImage || defaultImage}
                  alt="Community"
                />
              </div>
              {shouldShowContent("editButtons") && (
                <div onClick={toggleEditSingleCommunityModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {communityData.communityName}{" "}
              <span className={classes.connectionType}>• Community</span>
            </div>

            {/* Only render userDetails div if there's content to show */}
            {(communityData.tagline || communityData.link) && (
              <div className={classes.userDetails}>
                {communityData.tagline && (
                  <div className={classes.bio}>{communityData.tagline}</div>
                )}
                {communityData.link && (
                  <div className={classes.link}>
                    <a
                      href={communityData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {communityData.linkText || communityData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}
            <div className={classes.buttons}>
              {!isOwner && (
                <button
                  className={
                    membershipStatus === "Member" ||
                    membershipStatus === "Pending" ||
                    membershipStatus === "Moderator"
                      ? classes.following
                      : classes.headlineButton
                  }
                  onClick={() => {
                    if (membershipStatus === "Member") {
                      handleRemoveMember();
                    } else if (membershipStatus === "Moderator") {
                      // Handle moderator removal
                      handleRemoveModerator();
                    } else {
                      handleJoin();
                    }
                  }}
                >
                  <span>
                    {membershipStatus === "Member" ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : membershipStatus === "Moderator" ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : membershipStatus === "Pending" ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  {membershipStatus}
                </button>
              )}

              {shouldShowContent("editButtons") && !communityData.tagline && (
                <div
                  className={classes.headlineButton}
                  onClick={toggleEditSingleCommunityModal}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a headline
                </div>
              )}

              {shouldShowContent("editButtons") && !communityData.link && (
                <div
                  className={classes.button}
                  onClick={toggleEditSingleCommunityModal}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a link
                </div>
              )}

              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          {shouldShowContent("intro") && renderDescriptionCard()}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Moderators
              {isOwner && (
                <div className={classes.iconContainer}>
                  {connectionsCount > 0 && hasAvailableConnections && (
                    <div onClick={toggleAddModeratorModal}>
                      <svg
                        className={classes.addIcon}
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                    </div>
                  )}
                  {moderators.length > 0 && (
                    <div onClick={toggleEditModeratorModal}>
                      <svg
                        className={classes.experienceIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Add the modals */}
            {showAddModeratorModal && (
              <AddModeratorsModal
                onClose={(result) => {
                  if (result === "success") {
                    fetchModerators();
                    checkAvailableConnections();
                  }
                  toggleAddModeratorModal();
                }}
                communityData={communityData}
                currentUser={currentUser}
                onModeratorInviteSent={(count) => {
                  setInviteCount(count);
                  setShowInvitePopup(true);
                }}
              />
            )}

            {showEditModeratorModal && (
              <EditModeratorsModal
                onClose={(result) => {
                  toggleEditModeratorModal();
                  if (result === "success") {
                    checkAvailableConnections();
                  }
                }}
                communityData={communityData}
                currentUser={currentUser}
                onModeratorRemoved={(
                  updatedModerators,
                  removedCount,
                  isSelfRemoval
                ) => {
                  setModerators(updatedModerators);
                  setRemoveCount(removedCount);
                  setCurrentModalType("moderator");
                  setShowRemovePopup(true);

                  if (isSelfRemoval) {
                    setShowEditModeratorModal(false);
                    setIsEditModeratorModalOpen(false);
                    setRemoveMessage({
                      text: "You're no longer a moderator.",
                      isSuccess: true,
                    });

                    // Immediately check membership status and fetch posts if needed
                    checkMembershipStatus(
                      currentUser.uid,
                      communityData.id,
                      communityData.ownerId
                    );

                    // Only fetch posts if they're still a member
                    if (!isCommunityMember) {
                      setCommunityPosts([]); // Clear posts if they're not a member
                    } else {
                      fetchCommunityPosts(); // Refresh posts if they are a member
                    }
                  } else if (updatedModerators.length === 0) {
                    // When owner removes all moderators
                    setShowEditModeratorModal(false);
                    setIsEditModeratorModalOpen(false);
                    checkAvailableConnections();
                  }

                  if (updatedModerators.length === 0) {
                    checkAvailableConnections();
                  }
                }}
              />
            )}

            <div className={classes.userContainer}>
              <img
                src={
                  communityData.ownerData?.profileImage || defaultProfileImage
                }
                alt={
                  communityData.ownerData
                    ? `${communityData.ownerData.firstName} ${communityData.ownerData.lastName}`
                    : "Owner"
                }
                className={classes.userImage}
                onClick={() => navigate(`/${communityData.ownerData?.urlSlug}`)}
                style={{ cursor: "pointer" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultProfileImage;
                }}
              />
              <div className={classes.userInfo}>
                <div className={classes.userNameContainer}>
                  <div
                    className={classes.userName}
                    onClick={() =>
                      navigate(`/${communityData.ownerData?.urlSlug}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {communityData.ownerData?.firstName}{" "}
                    {communityData.ownerData?.lastName}
                  </div>
                  <span className={classes.userConnectionType}>
                    • {connectionTypes[communityData.ownerId] || "Extended"}
                  </span>
                </div>
                {communityData.ownerData?.bio && (
                  <div className={classes.userBio}>
                    {communityData.ownerData.bio}
                  </div>
                )}
                {communityData.ownerData?.link && (
                  <a
                    href={communityData.ownerData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.userLink}
                  >
                    <span>
                      {communityData.ownerData.linkText ||
                        communityData.ownerData.link}
                    </span>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </a>
                )}
                <div className={classes.userBadge}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span className={classes.skillText}>Owner</span>
                </div>
              </div>
            </div>

            {moderators
              .slice(0, visibleModeratorsCount)
              .map((moderator, index) => (
                <div key={moderator.id} className={classes.userContainer}>
                  <img
                    src={moderator.userData.profileImage || defaultProfileImage}
                    alt={`${moderator.userData.firstName} ${moderator.userData.lastName}`}
                    className={classes.userImage}
                    onClick={() => navigate(`/${moderator.userData.urlSlug}`)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div
                        className={classes.userName}
                        onClick={() =>
                          navigate(`/${moderator.userData.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {moderator.userData.firstName}{" "}
                        {moderator.userData.lastName}
                      </div>
                      <span className={classes.userConnectionType}>
                        • {connectionTypes[moderator.userUUID] || "Extended"}
                      </span>
                    </div>
                    {moderator.userData.bio && (
                      <div className={classes.userBio}>
                        {moderator.userData.bio}
                      </div>
                    )}
                    {moderator.userData.link && (
                      <a
                        href={moderator.userData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>
                          {moderator.userData.linkText ||
                            moderator.userData.link}
                        </span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              ))}

            {moderators.length > 3 && (
              <div
                className={classes.showAllPosts}
                onClick={() => {
                  if (visibleModeratorsCount >= moderators.length) {
                    // Reset to initial count of 3
                    setVisibleModeratorsCount(2);
                  } else {
                    // Double the current visible count
                    setVisibleModeratorsCount(
                      Math.min(visibleModeratorsCount * 2, moderators.length)
                    );
                  }
                }}
              >
                {visibleModeratorsCount >= moderators.length
                  ? "Show less"
                  : "Show more"}
                {visibleModeratorsCount >= moderators.length ? (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          {(members.length > 0 ||
            isOwner ||
            moderators.some((mod) => mod.id === currentUser?.uid)) && (
            <div className={classes.card}>
              <div
                className={`${classes.cardTitle} ${
                  members.length === 0 ? classes.emptyCardTitle : ""
                }`}
              >
                Members
                {(isOwner ||
                  moderators.some((mod) => mod.id === currentUser?.uid)) && (
                  <div>
                    {/* Only show add icon if they have connections that could be members */}
                    {connectionsCount > 0 && hasAvailableMemberConnections && (
                      <div onClick={toggleAddMemberModal}>
                        <svg
                          className={classes.addIcon}
                          width="36"
                          height="36"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 12h14m-7 7V5"
                          />
                        </svg>
                      </div>
                    )}

                    {/* Keep showing edit icon when there are members */}
                    {(isOwner ||
                      moderators.some((mod) => mod.id === currentUser?.uid)) &&
                      members.length > 0 && (
                        <div onClick={toggleEditMembersModal}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                  </div>
                )}
              </div>

              {members.length === 0 ? (
                <>
                  <div>
                    <p className={classes.defaultText}>
                      {connectionsCount === 0
                        ? "You can only invite your connections to become members of this community. Connect with like-minded people in the startup community and build meaningful relationships."
                        : "Spread the word about your community and invite your connections to be members. Help grow your community and make ideas happen."}
                    </p>
                  </div>
                  {connectionsCount === 0 && (
                    <div className={classes.people}>
                      <div
                        className={classes.button}
                        style={{ width: "fit-content" }}
                        onClick={() => navigate("/people")}
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        People
                      </div>
                    </div>
                  )}
                  <div className={classes.noPostBorder}></div>
                  {/* Preview of what a member looks like */}
                  <div className={classes.userContainer}>
                    <img
                      src={greyProfileImage}
                      alt="Preview member"
                      className={classes.userImage}
                    />
                    <div className={classes.userInfo}>
                      <div className={classes.userNameContainer}>
                        <div className={classes.noStartupRole}>Username</div>
                        <span className={classes.userConnectionType}>
                          • Extended
                        </span>
                      </div>
                      <div className={classes.noStartupName}>Headline</div>
                      <div className={classes.noUserLink}>
                        Link
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {members
                    .slice(0, visibleMembersCount)
                    .map((member, index) => (
                      <div key={member.id} className={classes.userContainer}>
                        <img
                          src={
                            member.userData.profileImage || defaultProfileImage
                          }
                          alt={`${member.userData.firstName} ${member.userData.lastName}`}
                          className={classes.userImage}
                          onClick={() =>
                            navigate(`/${member.userData.urlSlug}`)
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div
                              className={classes.userName}
                              onClick={() =>
                                navigate(`/${member.userData.urlSlug}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {member.userData.firstName}{" "}
                              {member.userData.lastName}
                            </div>
                            <span className={classes.userConnectionType}>
                              • {connectionTypes[member.userUUID] || "Extended"}
                            </span>
                          </div>
                          {member.userData.bio && (
                            <div className={classes.userBio}>
                              {member.userData.bio}
                            </div>
                          )}
                          {member.userData.link && (
                            <a
                              href={member.userData.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.userLink}
                            >
                              <span>
                                {member.userData.linkText ||
                                  member.userData.link}
                              </span>
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          )}
                        </div>
                      </div>
                    ))}

                  {members.length > 3 && (
                    <div
                      className={classes.showAllPosts}
                      onClick={() => {
                        if (visibleMembersCount >= members.length) {
                          setVisibleMembersCount(3);
                        } else {
                          setVisibleMembersCount(
                            Math.min(visibleMembersCount * 2, members.length)
                          );
                        }
                      }}
                    >
                      {visibleMembersCount >= members.length
                        ? "Show less"
                        : "Show more"}
                      {visibleMembersCount >= members.length ? (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m5 15 7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 9-7 7-7-7"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {/* Add Post Card */}
          {shouldShowContent("topCard") && (
            <div className={classes.topCard}>
              <div className={classes.postContent}>
                <div className={classes.profileImageContainer}>
                  <img
                    src={currentUser?.profileImage || defaultProfileImage}
                    alt="Profile"
                    style={{
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className={classes.postButton} onClick={handleAddPost}>
                  Add a post
                </div>
              </div>
            </div>
          )}
          {showPostModal && (
            <PostModal
              onClose={handleClosePostModal}
              onManualClose={() => {
                setShowPostModal(false);
                setIsPostModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, postModalScrollPosition);
                }, 0);
              }}
              currentUser={currentUser}
              selectedStartup={selectedStartup}
              communityId={communityData.id}
              communityOwnerId={communityData.ownerId}
            />
          )}

          {/* This is for editing existing posts */}
          {showEditModal && editingPost && (
            <PostModal
              onClose={() => {
                setShowEditModal(false);
                setEditingPost(null);
                setIsPostModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, postModalScrollPosition);
                }, 0);
              }}
              currentUser={currentUser}
              initialPost={editingPost}
              onEditComplete={handleEditComplete}
              selectedStartup={selectedStartup}
              communityId={communityData.id}
              communityOwnerId={communityData.ownerId}
            />
          )}
          {Object.entries(state.uploadingPosts).map(
            ([postId, postData]) =>
              !state.cancelledPosts[postId] && (
                <UploadingPost
                  key={postId}
                  postId={postId}
                  progress={postData.progress}
                  status={postData.status}
                  onCancelUpload={handleCancelUpload}
                />
              )
          )}

          {/* Posts List */}
          {shouldShowContent("posts") ? (
            <>
              {communityPosts && communityPosts.length > 0 ? (
                <>
                  {communityPosts.slice(0, visiblePostsCount).map((post) => {
                    if (!post || !post.user) return null;
                    return (
                      <div key={post.id} className={classes.postCard}>
                        <div className={classes.postHeader}>
                          <div className={classes.postUserInfo}>
                            <img
                              className={classes.postImage}
                              src={
                                post.user?.profileImage || defaultProfileImage
                              }
                              alt=""
                              onClick={() => handleNavigate(post.user.id)}
                            />
                            <div className={classes.postUserDetails}>
                              <div className={classes.postUserNameContainer}>
                                <p
                                  className={classes.postUserName}
                                  onClick={() => handleNavigate(post.user.id)}
                                >
                                  <span className={classes.nameText}>
                                    {post.user.firstName} {post.user.lastName}
                                  </span>
                                </p>
                                <span className={classes.postConnectionType}>
                                  •{" "}
                                  {connectionTypes[post.user.id] ||
                                    post.user.connectionType ||
                                    "Extended"}
                                </span>
                              </div>
                              {post.user.bio && (
                                <p className={classes.postBio}>
                                  {post.user.bio}
                                </p>
                              )}
                              {post.user.link && (
                                <div className={classes.userLinkContainer}>
                                  <a
                                    href={post.user.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                    className={classes.postUserLink}
                                  >
                                    <span className={classes.postUserLinkText}>
                                      {post.user.linkText || post.user.link}
                                    </span>
                                    <svg
                                      className={classes.postUserLinkIcon}
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="orangered"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                      />
                                    </svg>
                                  </a>
                                </div>
                              )}
                              <div className={classes.timeAndEditDetails}>
                                {post.createdAt && (
                                  <p className={classes.time}>
                                    {formatTimestamp(post.createdAt)}
                                  </p>
                                )}
                                {post.isEdited && (
                                  <div className={classes.editDot}>
                                    •
                                    <span className={classes.editedPostText}>
                                      Edited
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={classes.postOptions}>
                            <svg
                              className={`${classes.optionsIcon} ${
                                showDropdown[post.id]
                                  ? classes.optionsIconActive
                                  : ""
                              }`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="none"
                              viewBox="0 0 24 24"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(post.id);
                              }}
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeWidth="3"
                                d="M6 12h.01m6 0h.01m5.99 0h.01"
                              />
                            </svg>
                            {showDropdown[post.id] && (
                              <div className={classes.optionsDropdown}>
                                {post.user.id === currentUser.uid ||
                                (post.user.isStartup &&
                                  post.user.startupOwnerId ===
                                    currentUser.uid) ? (
                                  <>
                                    <button
                                      onClick={() => handleEditPost(post)}
                                      className={classes.editPost}
                                    >
                                      <svg>...</svg>
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => handleDeletePost(post.id)}
                                      className={classes.deletePost}
                                    >
                                      <svg>...</svg>
                                      Delete
                                    </button>
                                  </>
                                ) : currentUser.uid === communityData.ownerId ||
                                  moderators.some(
                                    (mod) => mod.id === currentUser.uid
                                  ) ? (
                                  <button
                                    onClick={() => handleDeletePost(post.id)}
                                    className={classes.deletePost}
                                  >
                                    <svg>...</svg>
                                    Delete
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => handleReportPost(post.id)}
                                    className={classes.reportPost}
                                  >
                                    <svg>...</svg>
                                    Report
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        {post.text && renderPostText(post)}
                        {renderPostContent(post)}

                        {hasInteractions(post) && (
                          <div className={classes.postInteractionsInfo}>
                            {post.communityReactions &&
                              Object.keys(post.communityReactions).length >
                                0 && (
                                <div
                                  className={classes.reactionsSummary}
                                  onClick={() =>
                                    handleReactionsClick(
                                      post.id,
                                      post.communityReactions
                                    )
                                  }
                                >
                                  <div className={classes.reactionStack}>
                                    {getTopThreeEmojis(post.communityReactions)
                                      .reverse()
                                      .map((emoji, index) => (
                                        <div
                                          key={index}
                                          className={classes.reactionEmoji}
                                          style={{
                                            zIndex:
                                              getTopThreeEmojis(
                                                post.communityReactions
                                              ).length - index,
                                            fontSize: "var(--emoji-font-size)",
                                            marginLeft:
                                              "var(--emoji-margin-left)",
                                            marginBottom:
                                              "var(--emoji-margin-bottom)",
                                            marginTop:
                                              "var(--emoji-margin-top)",
                                          }}
                                        >
                                          {emoji}
                                        </div>
                                      ))}
                                  </div>
                                  <span
                                    className={classes.reactionCount}
                                    style={{
                                      marginLeft: "var(--count-margin-left)",
                                    }}
                                  >
                                    {
                                      Object.keys(post.communityReactions)
                                        .length
                                    }
                                  </span>
                                </div>
                              )}
                            <div className={classes.rightInteractions}>
                              {post.commentCount > 0 && (
                                <span
                                  className={classes.commentCount}
                                  onClick={() =>
                                    handleCommentsCountClick(post.id)
                                  }
                                >
                                  {post.commentCount} comment
                                  {post.commentCount !== 1 ? "s" : ""}
                                </span>
                              )}
                              {post.commentCount > 0 &&
                                post.reposts &&
                                Object.keys(post.reposts).length > 0 && (
                                  <span className={classes.postDot}>•</span>
                                )}
                              {post.reposts &&
                                Object.keys(post.reposts).length > 0 && (
                                  <span
                                    className={classes.repostCount}
                                    onClick={() =>
                                      handleRepostsClick(post.id, post.reposts)
                                    }
                                  >
                                    {Object.keys(post.reposts).length} repost
                                    {Object.keys(post.reposts).length !== 1
                                      ? "s"
                                      : ""}
                                  </span>
                                )}
                            </div>
                          </div>
                        )}

                        <div className={classes.postInteractions}>
                          <button
                            className={classes.interactionButton}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={
                                  currentUser?.profileImage ||
                                  defaultProfileImage
                                }
                                alt="Profile"
                                className={classes.interactionProfileImage}
                                style={{
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </button>

                          <button
                            className={`${classes.interactionButton} ${
                              (selectedStartup &&
                                post.communityReactions?.[
                                  `startup_${selectedStartup.id}`
                                ]) ||
                              (!selectedStartup &&
                                post.communityReactions?.[currentUser?.uid])
                                ? classes.emojiButton
                                : ""
                            }`}
                            onClick={() => handleReactionClick(post.id)}
                          >
                            {selectedStartup ? (
                              post.communityReactions?.[
                                `startup_${selectedStartup.id}`
                              ] ? (
                                <span>
                                  {
                                    post.communityReactions[
                                      `startup_${selectedStartup.id}`
                                    ]
                                  }
                                </span>
                              ) : (
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                                  />
                                </svg>
                              )
                            ) : post.communityReactions?.[currentUser?.uid] ? (
                              <span>
                                {post.communityReactions[currentUser.uid]}
                              </span>
                            ) : (
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                                />
                              </svg>
                            )}
                          </button>
                          <button
                            className={classes.interactionButton}
                            onClick={() => toggleCommentInput(post.id)}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                              />
                            </svg>
                          </button>
                        </div>

                        {activeEmojiPicker === `post_${post.id}` && (
                          <EmojiPicker
                            onEmojiClick={(emoji) =>
                              handleEmojiSelect(emoji, post.id)
                            }
                            onClose={() => {
                              setActiveEmojiPicker(null);
                              setTimeout(() => {
                                window.scrollTo(0, emojiPickerScrollPosition);
                              }, 0);
                            }}
                          />
                        )}

                        <div className={classes.commentsSection}>
                          {activeCommentPostId === post.id && (
                            <>
                              <CommentInputSection
                                currentUser={currentUser}
                                selectedStartup={selectedStartup}
                                newComment={newComment[post.id] || ""}
                                onCommentChange={(e) =>
                                  handleCommentInputChange(e, post.id)
                                }
                                onSubmit={() => handleAddComment(post.id)}
                                commentInputRef={(el) =>
                                  (commentInputRef.current[post.id] = el)
                                }
                              />
                              {comments[post.id] &&
                                comments[post.id].length > 0 && (
                                  <div className={classes.commentsList}>
                                    {renderComments(post.id, post.user.id)}
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {communityPosts.length > visiblePostsCount && (
                    <button
                      className={`${classes.miscButton} ${classes.miscButton}`}
                      onClick={() => {
                        setVisiblePostsCount(
                          Math.min(visiblePostsCount * 2, communityPosts.length)
                        );
                      }}
                    >
                      Show more
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    </button>
                  )}

                  {visiblePostsCount > 3 &&
                    visiblePostsCount >= communityPosts.length && (
                      <button
                        className={`${classes.miscButton} ${classes.miscButton}`}
                        onClick={() => {
                          setVisiblePostsCount(3);
                        }}
                      >
                        Show less
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m5 15 7-7 7 7"
                          />
                        </svg>
                      </button>
                    )}
                </>
              ) : null}
            </>
          ) : (
            <div className={classes.profileCard}>
              <div
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "900",
                  marginBottom: "1rem",
                }}
              >
                Members-Only Content
              </div>

              <div
                style={{
                  color: "gray",

                  marginBottom: "1rem",
                }}
              >
                To access this community's content and engage with other
                members, you'll need to join first. As a member, you can:
              </div>

              <div
                style={{
                  backgroundColor: "rgba(255, 69, 0, 0.1)",

                  padding: "1rem",

                  borderRadius: "0.5rem",

                  width: "100%",

                  boxSizing: "border-box",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  {[
                    "View and engage with community posts",

                    "Share your own updates and insights",

                    "Connect with community members",
                  ].map((benefit, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",

                        alignItems: "center",

                        marginBottom: index !== 2 ? "0.5rem" : 0,
                      }}
                    >
                      <svg
                        className={classes.check}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="orangered"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span style={{ color: "orangered", fontWeight: "900" }}>
                        {benefit}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Cards */}
        <div className={classes.rightCards}>
          {shouldShowContent("tags") && (
            <div className={classes.card}>
              <div
                className={`${classes.cardTitle} ${
                  !communityData.tags || communityData.tags.length === 0
                    ? classes.emptyCardTitle
                    : ""
                }`}
              >
                Tags
                {isOwner && (
                  <div onClick={toggleTagsModal}>
                    <svg
                      className={classes.closeIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className={classes.cardAbout}>
                {communityData.tags && communityData.tags.length > 0
                  ? communityData.tags
                      .slice(0, visibleTagsCount)
                      .map((tag, index) => (
                        <div key={index} className={classes.skillContainer}>
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className={classes.skillText}>{tag}</span>
                        </div>
                      ))
                  : isOwner && (
                      <div className={classes.emptyContainer}>
                        <div className={classes.defaultText}>
                          Add tags to help people find and understand your
                          community.
                        </div>
                        <div className={classes.defaultContainer}>
                          <div className={classes.emptySkillContainer}>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                            </svg>
                            <span className={classes.skillText}>Tag</span>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
              {communityData.tags && communityData.tags.length > 9 && (
                <div
                  className={classes.showAllPosts}
                  onClick={() => {
                    if (visibleTagsCount >= communityData.tags.length) {
                      // Reset to initial count of 9
                      setVisibleTagsCount(9);
                    } else {
                      // Double the current visible count
                      setVisibleTagsCount(
                        Math.min(
                          visibleTagsCount * 2,
                          communityData.tags.length
                        )
                      );
                    }
                  }}
                >
                  {visibleTagsCount >= communityData.tags.length
                    ? "Show less"
                    : "Show more"}
                  {visibleTagsCount >= communityData.tags.length ? (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          )}
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Community;
