import React, { useState, useRef, useEffect, useMemo } from "react";
import classes from "./MonthDropdown.module.css";

const generateTimezoneOptions = () => {
  const timezones = [];
  const now = new Date();
  const tzNames = Intl.supportedValuesOf("timeZone");

  tzNames.forEach((zoneName) => {
    try {
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: zoneName,
        timeZoneName: "long",
      });

      const offset = -new Date(
        now.toLocaleString("en-US", { timeZone: zoneName })
      ).getTimezoneOffset();

      const offsetHours = Math.abs(Math.floor(offset / 60));
      const offsetMinutes = Math.abs(offset % 60);
      const offsetStr = `${offset >= 0 ? "+" : "-"}${String(
        offsetHours
      ).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

      timezones.push({
        name: zoneName,
        display: `(UTC${offsetStr}) ${zoneName.replace(/_/g, " ")}`,
        offset: offset,
        searchStr: `${zoneName.toLowerCase()} utc${offsetStr}`.replace(
          /_/g,
          " "
        ),
      });
    } catch (e) {
      console.warn(`Error processing timezone ${zoneName}:`, e);
    }
  });

  return timezones.sort((a, b) => {
    if (a.offset === b.offset) return a.name.localeCompare(b.name);
    return a.offset - b.offset;
  });
};

const ALL_TIMEZONES = generateTimezoneOptions();

const TimezoneDropdown = ({
  selectedTimezone,
  onTimezoneChange,
  disabled,
  placeholder = "Select timezone",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    setInputValue(selectedTimezone?.display || "");
  }, [selectedTimezone]);

  const filteredOptions = useMemo(() => {
    const searchTerm = inputValue.toLowerCase();
    if (!searchTerm) return ALL_TIMEZONES;
    return ALL_TIMEZONES.filter((tz) => tz.searchStr.includes(searchTerm));
  }, [inputValue]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (timezone) => {
    onTimezoneChange(timezone);
    setInputValue(timezone.display);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === -1
            ? filteredOptions.length - 1
            : Math.max(prevIndex - 1, 0)
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <label className={`${classes.label} ${disabled ? classes.disabled : ""}`}>
        Timezone*
      </label>
      <div className={classes.dropdownContainer}>
        <div className={classes.inputWrapper}>
          <input
            type="text"
            className={`${classes.dropdownHeader} ${
              disabled ? classes.disabled : ""
            } ${isOpen ? classes.active : ""}`}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onClick={() => !disabled && setIsOpen(!isOpen)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          <svg
            className={`${classes.dropdownIcon} ${
              disabled ? classes.disabled : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m8 15 4 4 4-4m0-6-4-4-4 4"
            />
          </svg>
        </div>

        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList} ref={listRef}>
              {filteredOptions.map((timezone, index) => (
                <li
                  key={timezone.name}
                  className={`${classes.dropdownListItem} ${
                    index === highlightedIndex ? classes.highlightedItem : ""
                  }`}
                  onClick={() => handleSelect(timezone)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {timezone.display}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const formatTimezone = (timezoneName, offset) => {
  if (!timezoneName) return null;

  const offsetHours = Math.abs(Math.floor(offset / 60));
  const offsetMinutes = Math.abs(offset % 60);
  const offsetStr = `${offset >= 0 ? "+" : "-"}${String(offsetHours).padStart(
    2,
    "0"
  )}:${String(offsetMinutes).padStart(2, "0")}`;

  return {
    name: timezoneName,
    display: `(UTC${offsetStr}) ${timezoneName.replace(/_/g, " ")}`,
    offset: offset,
  };
};

export default React.memo(TimezoneDropdown);
