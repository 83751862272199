import React, { useState, useRef, useEffect } from "react";
import classes from "./CompanyTypeDropdown.module.css";

const companyTypes = [
  "Educational",
  "Government agency",
  "Non profit",
  "Partnership",
  "Privately held",
  "Public Company",
  "Self Employed",
  "Self Owned",
];

const CompanyTypeDropdown = ({
  selectedType,
  onTypeChange,
  disabled,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  useEffect(() => {
    if (isOpen && listRef.current && highlightedIndex >= 0) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [isOpen, highlightedIndex]);

  const handleSelect = (value) => {
    onTypeChange(value);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, companyTypes.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === -1
            ? companyTypes.length - 1
            : Math.max(prevIndex - 1, 0)
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(companyTypes[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <div className={classes.dropdownContainer}>
        <div className={classes.inputWrapper}>
          <input
            type="text"
            className={`${classes.dropdownHeader} ${
              disabled ? classes.disabled : ""
            } ${isOpen ? classes.active : ""}`}
            placeholder="Select company type"
            value={selectedType || ""}
            onClick={() => setIsOpen(!isOpen)}
            onKeyDown={handleKeyDown}
            readOnly
            disabled={disabled}
          />
          <span className={classes.chevron}>
            {isOpen ? (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </span>
        </div>

        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList} ref={listRef}>
              {loading ? (
                <li className={classes.dropdownListItem}>Loading...</li>
              ) : (
                companyTypes.map((type, index) => (
                  <li
                    key={type}
                    className={`${classes.dropdownListItem} ${
                      index === highlightedIndex ? classes.highlightedItem : ""
                    }`}
                    onClick={() => handleSelect(type)}
                    onMouseEnter={() => setHighlightedIndex(index)}
                  >
                    {type}
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyTypeDropdown;
