import React, { useState, useEffect, useMemo } from "react";

import classes from "./EmojiPicker.module.css";

const emojiCategories = {
  "Smileys & People": [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "🥹",
    "😅",
    "😂",
    "🤣",
    "🥲",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🥸",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥺",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😶‍🌫️",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🫣",
    "🤭",
    "🫢",
    "🫡",
    "🤫",
    "🫠",
    "🤥",
    "😶",
    "🫥",
    "😐",
    "🫤",
    "😑",
    "🫨",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "🥱",
    "😴",
    "🤤",
    "😪",
    "😮‍💨",
    "😵",
    "😵‍💫",
    "🤐",
    "🥴",
    "🤢",
    "🤮",
    "🤧",
    "😷",
    "🤒",
    "🤕",
    "🤑",
    "🤠",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "💩",
    "👻",
    "💀",
    "👽",
    "👾",
    "🤖",
    "🎃",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "😾",
    "🫶",
    "🤲",
    "👐",
    "🙌",
    "👏",
    "🤝",
    "👍",
    "👎",
    "👊",
    "✊",
    "🤛",
    "🤜",
    "🤞",
    "🫰",
    "🤟",
    "🤘",
    "👌",
    "🤌",
    "🤏",
    "👈",
    "👉",
    "👆",
    "👇",
    "✋",
    "🤚",
    "🖐️",
    "🖖",
    "👋",
    "🤙",
    "💪",
    "🦾",
    "🙏",
    "🫵",
    "🦶",
    "🦵",
    "🦿",
    "💄",
    "💋",
    "👄",
    "🦷",
    "👅",
    "👂",
    "🦻",
    "👃",
    "👣",
    "👁️",
    "👀",
    "🫀",
    "🫁",
    "🧠",
    "🗣️",
    "👤",
    "👥",
    "🫂",
    "👶",
    "👧",
    "🧒",
    "👦",
    "👩",
    "🧑",
    "👨",
    "👩‍🦱",
    "🧑‍🦱",
    "👩‍🦰",
    "🧑‍🦰",
    "👨‍🦰",
    "👱‍♀️",
    "👱",
    "👱‍♂️",
    "👩‍🦳",
    "🧑‍🦳",
    "👨‍🦳",
    "👩‍🦲",
    "🧔‍♀️",
    "🧔",
    "🧔‍♂️",
    "👵",
    "🧓",
    "👴",
    "👲",
    "👳‍♀️",
    "👳",
    "👳‍♂️",
    "🧕",
    "👮‍♀️",
    "👮",
    "👮‍♂️",
    "👷‍♀️",
    "👷",
    "👷‍♂️",
    "💂‍♀️",
    "🕵️‍♀️",
    "🕵️‍♂️",
    "👩‍⚕️",
    "🧑‍⚕️",
    "👨‍⚕️",
    "👨‍⚕️",
    "👩‍🌾",
    "🧑‍🌾",
    "👨‍🌾",
    "👩‍🍳",
    "🧑‍🍳",
    "👨‍🍳",
    "👩‍🎓",
    "🧑‍🎓",
    "👨‍🎓",
    "👩‍🎤",
    "🧑‍🎤",
    "👨‍🎤",
    "👩‍🏫",
    "🧑‍🏫",
    "👨‍🏫",
    "👩‍🏭",
    "🧑‍🏭",
    "👨‍🏭",
    "👩‍💻",
    "🧑‍💻",
    "👨‍💻",
    "👩‍💼",
    "🧑‍💼",
    "👨‍💼",
    "👩‍🔧",
    "🧑‍🔧",
    "👨‍🔧",
    "👩‍🔬",
    "👨‍🔬",
    "👩‍🎨",
    "🧑‍🎨",
    "👨‍🎨",
    "👩‍🚒",
    "🧑‍🚒",
    "👨‍🚒",
    "👩‍✈️",
    "🧑‍✈️",
    "👨‍✈️",
    "👩‍🚀",
    "🧑‍🚀",
    "👨‍🚀",
    "👩‍⚖️",
    "🧑‍⚖️",
    "👨‍⚖️",
    "👰‍♀️",
    "👰",
    "👰‍♂️",
    "🤵‍♀️",
    "🤵",
    "🤵",
    "👸",
    "🫅",
    "🤴",
    "🥷",
    "🦸‍♀️",
    "🦸",
    "🦸‍♂️",
    "🦹‍♀️",
    "🦹",
    "🦹‍♂️",
    "🤶",
    "🧑‍🎄",
    "🎅",
    "🧙‍♀️",
    "🧙",
    "🧙‍♂️",
    "🧝‍♀️",
    "🧝",
    "🧝‍♂️",
    "🧌",
    "🧛‍♀️",
    "🧛",
    "🧛‍♂️",
    "🧟‍♀️",
    "🧟",
    "🧟‍♂️",
    "🧞‍♀️",
    "🧞",
    "🧞‍♂️",
    "🧜‍♀️",
    "🧜",
    "🧜‍♂️",
    "🧚‍♀️",
    "🧚",
    "🧚‍♂️",
    "👼",
    "🤰",
    "🫄",
    "🫃",
    "🤱",
    "👩‍🍼",
    "🧑‍🍼",
    "👨‍🍼",
    "🙇‍♀️",
    "🙇",
    "🙇‍♂️",
    "💁‍♀️",
    "💁",
    "💁‍♂️",
    "🙅‍♀️",
    "🙅",
    "🙅‍♂️",
    "🙆‍♀️",
    "🙆",
    "🙆‍♂️",
    "🙋‍♀️",
    "🙋",
    "🙋‍♂️",
    "🧏‍♀️",
    "🧏",
    "🧏‍♂️",
    "🤦‍♀️",
    "🤦",
    "🤦‍♂️",
    "🤷‍♀️",
    "🤷",
    "🤷‍♂️",
    "🙎‍♀️",
    "🙎",
    "🙎‍♂️",
    "🙍‍♀️",
    "🙍",
    "🙍‍♂️",
    "💇‍♀️",
    "💇",
    "💇‍♂️",
    "💆‍♀️",
    "💆",
    "💆‍♂️",
    "🧖‍♀️",
    "🧖",
    "🧖‍♂️",
    "💅",
    "🤳",
    "💃",
    "🕺",
    "👯‍♀️",
    "👯",
    "👯‍♂️",
    "🕴️",
    "👩‍🦽",
    "🧑‍🦽",
    "👨‍🦽",
    "👩‍🦼",
    "🧑‍🦼",
    "👨‍🦼",
    "🚶‍♀️",
    "🚶",
    "🚶‍♂️",
    "👩‍🦯",
    "🧑‍🦯",
    "👨‍🦯",
    "🧎‍♀️",
    "🧎",
    "🧎‍♂️",
    "🏃‍♀️",
    "🏃",
    "🏃‍♂️",
    "🧍‍♀️",
    "🧍",
    "🧍‍♂️",
    "👩🏽‍🤝‍👨🏿",
    "👩🏻‍🤝‍👩🏾",
    "👨🏾‍🤝‍👨🏼",
    "👩‍❤️‍👨",
    "👩‍❤️‍👩",
    "💑",
    "👨‍❤️‍👨",
    "👩‍❤️‍💋‍👨",
    "👩‍❤️‍💋‍👩",
    "💏",
    "👨‍👩‍👦",
    "👨‍👩‍👧",
    "👨‍👩‍👧‍👦",
    "👨‍👩‍👦‍👦",
    "👨‍👩‍👧‍👧",
    "👩‍👩‍👦",
    "👩‍👩‍👧",
    "👩‍👩‍👧‍👦",
    "👩‍👩‍👦‍👦",
    "👩‍👩‍👧‍👧",
    "👨‍👨‍👦",
    "👨‍👨‍👧",
    "👨‍👨‍👧‍👦",
    "👨‍👨‍👦‍👦",
    "👨‍👨‍👧‍👧",
    "👩‍👦",
    "👩‍👧",
    "👩‍👧‍👦",
    "👩‍👦‍👦",
    "👩‍👧‍👧",
    "👨‍👦",
    "👨‍👧",
    "👨‍👧‍👦",
    "👨‍👦‍👦",
    "👨‍👧‍👧",
    "🪢",
    "🧶",
    "🧵",
    "🪡",
    "🧥",
    "🥼",
    "🦺",
    "👚",
    "👕",
    "👖",
    "🩲",
    "🩳",
    "👔",
    "👗",
    "👙",
    "🩱",
    "👘",
    "🥻",
    "🩴",
    "🥿",
    "👠",
    "👡",
    "👢",
    "👞",
    "👟",
    "🥾",
    "🧦",
    "🧤",
    "🧣",
    "🎩",
    "🧢",
    "👒",
    "🎓",
    "⛑️",
    "🪖",
    "👑",
    "💍",
    "👝",
    "👛",
    "👜",
    "💼",
    "🎒",
    "🧳",
    "👓",
    "🕶️",
    "🥽",
    "🌂",
  ],
  "Animals & Nature": [
    "🐶",
    "🐱",
    "🐭",
    "🐹",
    "🐰",
    "🦊",
    "🐻",
    "🐼",
    "🐻‍❄️",
    "🐨",
    "🐯",
    "🦁",
    "🐮",
    "🐷",
    "🐽",
    "🐸",
    "🐵",
    "🙈",
    "🙉",
    "🙊",
    "🐒",
    "🐔",
    "🐧",
    "🐦",
    "🐤",
    "🐣",
    "🐥",
    "🪿",
    "🦆",
    "🐦‍⬛",
    "🦅",
    "🦉",
    "🦇",
    "🐺",
    "🐗",
    "🐴",
    "🦄",
    "🫎",
    "🐝",
    "🪱",
    "🐛",
    "🦋",
    "🐌",
    "🐞",
    "🐜",
    "🪰",
    "🪲",
    "🪳",
    "🦟",
    "🦗",
    "🕷️",
    "🕸️",
    "🦂",
    "🐢",
    "🐍",
    "🦎",
    "🦖",
    "🦕",
    "🐙",
    "🦑",
    "🪼",
    "🦐",
    "🦞",
    "🦀",
    "🐡",
    "🐠",
    "🐟",
    "🐬",
    "🐳",
    "🐋",
    "🦈",
    "🦭",
    "🐊",
    "🐅",
    "🐆",
    "🦓",
    "🦍",
    "🦧",
    "🦣",
    "🐘",
    "🦛",
    "🦏",
    "🐪",
    "🐫",
    "🦒",
    "🦘",
    "🦬",
    "🐃",
    "🐂",
    "🐄",
    "🫏",
    "🐎",
    "🐖",
    "🐏",
    "🐑",
    "🦙",
    "🐐",
    "🦌",
    "🐕",
    "🐩",
    "🦮",
    "🐕‍🦺",
    "🐈",
    "🐈‍⬛",
    "🪶",
    "🪽",
    "🐓",
    "🦃",
    "🦤",
    "🦚",
    "🦜",
    "🦢",
    "🦩",
    "🕊️",
    "🐇",
    "🦝",
    "🦨",
    "🦡",
    "🦫",
    "🦦",
    "🦥",
    "🐁",
    "🐀",
    "🐿️",
    "🦔",
    "🐾",
    "🐉",
    "🐲",
    "🌵",
    "🎄",
    "🌲",
    "🌳",
    "🌴",
    "🪵",
    "🌱",
    "🌿",
    "🍀",
    "🎍",
    "🪴",
    "🎋",
    "🍃",
    "🍂",
    "🍁",
    "🪺",
    "🪹",
    "🍄",
    "🐚",
    "🪸",
    "🪨",
    "🌾",
    "💐",
    "🌷",
    "🌹",
    "🥀",
    "🪻",
    "🪷",
    "🌺",
    "🌸",
    "🌼",
    "🌻",
    "🌞",
    "🌝",
    "🌛",
    "🌜",
    "🌚",
    "🌕",
    "🌖",
    "🌗",
    "🌘",
    "🌑",
    "🌒",
    "🌓",
    "🌔",
    "🌙",
    "🌎",
    "🌍",
    "🌏",
    "🪐",
    "💫",
    "⭐️",
    "🌟",
    "✨",
    "💥",
    "🔥",
    "🌪️",
    "🌈",
    "🌤️",
    "⛅️",
    "🌥️",
    "🌦️",
    "🌧️",
    "⛈️",
    "🌩️",
    "🌨️",
    "⛄️",
    "🌬️",
    "💨",
    "💧",
    "💦",
    "🫧",
    "🌊",
    "🌫️",
  ],
  "Food & Drink": [
    "🍏",
    "🍎",
    "🍐",
    "🍊",
    "🍋",
    "🍌",
    "🍉",
    "🍇",
    "🍓",
    "🫐",
    "🍈",
    "🍒",
    "🍑",
    "🥭",
    "🍍",
    "🥥",
    "🥝",
    "🍅",
    "🍆",
    "🥑",
    "🫛",
    "🥦",
    "🥬",
    "🥒",
    "🌶️",
    "🫑",
    "🌽",
    "🥕",
    "🫒",
    "🧄",
    "🧅",
    "🥔",
    "🍠",
    "🫚",
    "🥐",
    "🥯",
    "🍞",
    "🥖",
    "🥨",
    "🧀",
    "🥚",
    "🍳",
    "🧈",
    "🥞",
    "🧇",
    "🥓",
    "🥩",
    "🍗",
    "🍖",
    "🦴",
    "🌭",
    "🍔",
    "🍟",
    "🍕",
    "🫓",
    "🥪",
    "🥙",
    "🧆",
    "🌮",
    "🌯",
    "🫔",
    "🥗",
    "🥘",
    "🫕",
    "🥫",
    "🫙",
    "🍝",
    "🍜",
    "🍲",
    "🍛",
    "🍣",
    "🍱",
    "🥟",
    "🦪",
    "🍤",
    "🍙",
    "🍚",
    "🍘",
    "🍥",
    "🥠",
    "🥮",
    "🍢",
    "🍡",
    "🍧",
    "🍨",
    "🍦",
    "🥧",
    "🧁",
    "🍰",
    "🎂",
    "🍮",
    "🍭",
    "🍬",
    "🍫",
    "🍿",
    "🍩",
    "🍪",
    "🌰",
    "🥜",
    "🫘",
    "🍯",
    "🥛",
    "🫗",
    "🍼",
    "🫖",
    "🍵",
    "🧃",
    "🥤",
    "🧋",
    "🍶",
    "🍺",
    "🍻",
    "🥂",
    "🍷",
    "🥃",
    "🍸",
    "🍹",
    "🧉",
    "🍾",
    "🧊",
    "🥄",
    "🍴",
    "🍽️",
    "🥣",
    "🥡",
    "🥢",
    "🧂",
  ],
  Activity: [
    "⚽️",
    "🏀",
    "🏈",
    "⚾️",
    "🥎",
    "🎾",
    "🏐",
    "🏉",
    "🥏",
    "🎱",
    "🪀",
    "🏓",
    "🏸",
    "🏒",
    "🏑",
    "🥍",
    "🏏",
    "🪃",
    "🥅",
    "⛳️",
    "🪁",
    "🛝",
    "🏹",
    "🎣",
    "🤿",
    "🥊",
    "🥋",
    "🎽",
    "🛹",
    "🛼",
    "🛷",
    "⛸️",
    "🥌",
    "🎿",
    "⛷️",
    "🏂",
    "🪂",
    "🏋️‍♀️",
    "🏋️",
    "🏋️‍♂️",
    "🤼‍♀️",
    "🤼",
    "🤼‍♂️",
    "🤸‍♀️",
    "🤸",
    "🤸‍♂️",
    "⛹️‍♀️",
    "⛹️",
    "⛹️‍♂️",
    "🤺",
    "🤾‍♀️",
    "🤾",
    "🤾‍♂️",
    "🏌️‍♀️",
    "🏌️",
    "🏌️‍♂️",
    "🏇",
    "🧘‍♀️",
    "🧘",
    "🧘‍♂️",
    "🏄‍♀️",
    "🏄",
    "🏄‍♂️",
    "🏊‍♀️",
    "🏊",
    "🏊‍♂️",
    "🤽‍♀️",
    "🤽",
    "🤽‍♂️",
    "🚣‍♀️",
    "🚣",
    "🚣‍♂️",
    "🧗‍♀️",
    "🧗",
    "🧗‍♂️",
    "🚵‍♀️",
    "🚵",
    "🚵‍♂️",
    "🚴‍♀️",
    "🚴",
    "🚴‍♂️",
    "🏆",
    "🥇",
    "🥈",
    "🥉",
    "🏅",
    "🎖️",
    "🏵️",
    "🎗️",
    "🎫",
    "🎟️",
    "🎪",
    "🤹‍♀️",
    "🤹",
    "🤹‍♂️",
    "🎭",
    "🩰",
    "🎨",
    "🎬",
    "🎤",
    "🎼",
    "🎹",
    "🪇",
    "🥁",
    "🪘",
    "🎷",
    "🎺",
    "🪗",
    "🎸",
    "🪕",
    "🎻",
    "🪈",
    "🎲",
    "🎯",
    "🎳",
    "🎮",
    "🎰",
    "🧩",
  ],
  "Travel & Places": [
    "🚗",
    "🚕",
    "🚙",
    "🚌",
    "🚎",
    "🏎️",
    "🚓",
    "🚑",
    "🚒",
    "🚐",
    "🛻",
    "🚚",
    "🚛",
    "🚜",
    "🦯",
    "🦽",
    "🦼",
    "🩼",
    "🛴",
    "🚲",
    "🛵",
    "🏍️",
    "🛺",
    "🛞",
    "🚨",
    "🚔",
    "🚍",
    "🚘",
    "🚖",
    "🚡",
    "🚠",
    "🚟",
    "🚃",
    "🚋",
    "🚞",
    "🚝",
    "🚄",
    "🚅",
    "🚈",
    "🚂",
    "🚆",
    "🚇",
    "🚊",
    "🚉",
    "🛫",
    "🛬",
    "🛩️",
    "💺",
    "🛰️",
    "🚀",
    "🛸",
    "🚁",
    "🛶",
    "⛵️",
    "🚤",
    "🛥️",
    "🛳️",
    "⛴️",
    "🚢",
    "🛟",
    "🪝",
    "⛽️",
    "🚧",
    "🚦",
    "🚥",
    "🚏",
    "🗺️",
    "🗿",
    "🗽",
    "🗼",
    "🏰",
    "🏯",
    "🏟️",
    "🎡",
    "🎢",
    "🎠",
    "⛲️",
    "⛱️",
    "🏖️",
    "🏝️",
    "🏜️",
    "🌋",
    "⛰️",
    "🏔️",
    "🗻",
    "🏕️",
    "⛺️",
    "🛖",
    "🏠",
    "🏡",
    "🏘️",
    "🏚️",
    "🏗️",
    "🏭",
    "🏢",
    "🏬",
    "🏣",
    "🏤",
    "🏥",
    "🏦",
    "🏨",
    "🏪",
    "🏫",
    "🏩",
    "💒",
    "🏛️",
    "⛪️",
    "🕌",
    "🕍",
    "🛕",
    "🕋",
    "⛩️",
    "🛤️",
    "🛣️",
    "🗾",
    "🎑",
    "🏞️",
    "🌅",
    "🌄",
    "🌠",
    "🎇",
    "🎆",
    "🌇",
    "🌆",
    "🏙️",
    "🌃",
    "🌌",
    "🌉",
    "🌁",
  ],
  Objects: [
    "⌚️",
    "📱",
    "📲",
    "💻",
    "🖥️",
    "🖨️",
    "🖱️",
    "🖲️",
    "🕹️",
    "🗜️",
    "💽",
    "💾",
    "💿",
    "📀",
    "📼",
    "📷",
    "📸",
    "📹",
    "🎥",
    "📽️",
    "🎞️",
    "📞",
    "📟",
    "📠",
    "📺",
    "📻",
    "🎙️",
    "🎚️",
    "🎛️",
    "🧭",
    "⏱️",
    "⏲️",
    "⏰",
    "🕰️",
    "⌛️",
    "⏳",
    "📡",
    "🔋",
    "🪫",
    "🔌",
    "💡",
    "🔦",
    "🕯️",
    "🪔",
    "🧯",
    "🛢️",
    "💸",
    "💵",
    "💴",
    "💶",
    "💷",
    "🪙",
    "💰",
    "💳",
    "🪪",
    "💎",
    "🪜",
    "🧰",
    "🪛",
    "🔧",
    "🔨",
    "🛠️",
    "⛏️",
    "🪚",
    "🔩",
    "🪤",
    "🧱",
    "⛓️",
    "🧲",
    "🔫",
    "💣",
    "🧨",
    "🪓",
    "🔪",
    "🗡️",
    "🛡️",
    "🚬",
    "🪦",
    "🏺",
    "🔮",
    "📿",
    "🧿",
    "🪬",
    "💈",
    "🔭",
    "🔬",
    "🕳️",
    "🩻",
    "🩹",
    "🩺",
    "💊",
    "💉",
    "🩸",
    "🧬",
    "🦠",
    "🧫",
    "🧪",
    "🌡️",
    "🧹",
    "🪠",
    "🧺",
    "🧻",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧼",
    "🪥",
    "🪒",
    "🪮",
    "🧽",
    "🪣",
    "🧴",
    "🛎️",
    "🔑",
    "🗝️",
    "🚪",
    "🪑",
    "🛋️",
    "🛏️",
    "🛌",
    "🧸",
    "🪆",
    "🖼️",
    "🪞",
    "🪟",
    "🛍️",
    "🛒",
    "🎁",
    "🎈",
    "🎏",
    "🎀",
    "🪄",
    "🪅",
    "🎊",
    "🎉",
    "🎎",
    "🪭",
    "🏮",
    "🎐",
    "🪩",
    "🧧",
    "📩",
    "📨",
    "📧",
    "💌",
    "📥",
    "📤",
    "📦",
    "🏷️",
    "🪧",
    "📪",
    "📫",
    "📬",
    "📭",
    "📮",
    "📯",
    "📜",
    "📃",
    "📄",
    "📑",
    "🧾",
    "📊",
    "📈",
    "📉",
    "🗒️",
    "🗓️",
    "📆",
    "📅",
    "🗑️",
    "📇",
    "🗃️",
    "🗳️",
    "🗄️",
    "📋",
    "📁",
    "📂",
    "🗂️",
    "🗞️",
    "📰",
    "📓",
    "📔",
    "📒",
    "📕",
    "📗",
    "📘",
    "📙",
    "📚",
    "📖",
    "🔖",
    "🧷",
    "🔗",
    "📎",
    "🖇️",
    "📐",
    "📏",
    "🧮",
    "📌",
    "📍",
    "🖊️",
    "🖋️",
    "🖌️",
    "🖍️",
    "✏️",
    "📝",
    "🔍",
    "🔎",
    "🔏",
    "🔐",
    "🔒",
    "🔓",
  ],
  Symbols: [
    "🩷",
    "❤️",
    "🧡",
    "💛",
    "💚",
    "🩵",
    "💙",
    "💜",
    "🖤",
    "🩶",
    "🤍",
    "🤎",
    "💔",
    "❤️‍🔥",
    "❤️‍🩹",
    "❣️",
    "💕",
    "💞",
    "💓",
    "💗",
    "💖",
    "💘",
    "💝",
    "💟",
    "☮️",
    "✝️",
    "☪️",
    "🕉️",
    "☸️",
    "🪯",
    "✡️",
    "🔯",
    "🕎",
    "☯️",
    "☦️",
    "🛐",
    "⛎",
    "♈️",
    "♉️",
    "♊️",
    "♋️",
    "♌️",
    "♍️",
    "♎️",
    "♏️",
    "♐️",
    "♑️",
    "♒️",
    "♓️",
    "🆔",
    "⚛️",
    "🉑",
    "☢️",
    "☣️",
    "📴",
    "📳",
    "🈶",
    "🈚️",
    "🈸",
    "🈺",
    "🈷️",
    "✴️",
    "🆚",
    "💮",
    "🉐",
    "㊙️",
    "㊗️",
    "🈴",
    "🈵",
    "🈹",
    "🈲",
    "🅰️",
    "🅱️",
    "🆎",
    "🆑",
    "🅾️",
    "🆘",
    "❌",
    "⭕️",
    "🛑",
    "⛔️",
    "📛",
    "🚫",
    "💯",
    "💢",
    "♨️",
    "🚷",
    "🚯",
    "🚳",
    "🚱",
    "🔞",
    "📵",
    "🚭",
    "❗️",
    "❕",
    "❓",
    "❔",
    "‼️",
    "⁉️",
    "🔅",
    "🔆",
    "〽️",
    "⚠️",
    "🚸",
    "🔱",
    "⚜️",
    "🔰",
    "♻️",
    "✅",
    "🈯️",
    "💹",
    "❇️",
    "✳️",
    "❎",
    "🌐",
    "💠",
    "Ⓜ️",
    "🌀",
    "💤",
    "🏧",
    "🚾",
    "♿️",
    "🅿️",
    "🛗",
    "🈳",
    "🈂️",
    "🛂",
    "🛃",
    "🛄",
    "🛅",
    "🛜",
    "🚹",
    "🚺",
    "🚼",
    "⚧️",
    "🚻",
    "🚮",
    "🎦",
    "📶",
    "🈁",
    "🔣",
    "ℹ️",
    "🔤",
    "🔡",
    "🔠",
    "🆖",
    "🆗",
    "🆙",
    "🆒",
    "🆕",
    "🆓",
    "0️⃣",
    "1️⃣",
    "2️⃣",
    "3️⃣",
    "4️⃣",
    "5️⃣",
    "6️⃣",
    "7️⃣",
    "6️⃣",
    "9️⃣",
    "🔟",
    "🔢",
    "#️⃣",
    "*️⃣",
    "⏏️",
    "▶️",
    "⏸️",
    "⏯️",
    "⏹️",
    "⏭️",
    "⏮️",
    "⏩️",
    "⏪️",
    "⏫️",
    "⏬️",
    "◀️",
    "🔼",
    "🔽",
    "➡️",
    "⬅️",
    "⬆️",
    "⬇️",
    "↗️",
    "↘️",
    "↙️",
    "↖️",
    "↕️",
    "↔️",
    "↪️",
    "↩️",
    "⤴️",
    "⤵️",
    "🔀",
    "🔁",
    "🔂",
    "🔄",
    "🔃",
    "🎵",
    "🎶",
    "➕",
    "➖",
    "➗",
    "✖️",
    "🟰",
    "♾️",
    "💲",
    "💱",
    "™️",
    "©️",
    "®️",
    "👁️‍🗨️",
    "🔚",
    "🔙",
    "🔛",
    "🔝",
    "🔜",
    "〰️",
    "➰",
    "➿",
    "✔️",
    "☑️",
    "🔘",
    "🔴",
    "🟠",
    "🟡",
    "🟢",
    "🔵",
    "🟣",
    "⚫️",
    "⚪️",
    "🟤",
    "🔺",
    "🔻",
    "🔸",
    "🔹",
    "🔶",
    "🔷",
    "🔳",
    "🔲",
    "▪️",
    "▫️",
    "◾️",
    "◽️",
    "◼️",
    "◻️",
    "🟥",
    "🟧",
    "🟨",
    "🟩",
    "🟦",
    "🟪",
    "⬛️",
    "⬜️",
    "🟫",
    "🔈",
    "🔇",
    "🔉",
    "🔊",
    "🔔",
    "🔕",
    "📣",
    "📢",
    "💬",
    "💭",
    "🗯️",
    "♠️",
    "♣️",
    "♥️",
    "♦️",
    "🃏",
    "🎴",
    "🀄️",
    "🕐",
    "🕑",
    "🕒",
    "🕓",
    "🕔",
    "🕕",
    "🕖",
    "🕗",
    "🕘",
    "🕙",
    "🕚",
    "🕛",
    "🕜",
    "🕝",
    "🕞",
    "🕟",
    "🕠",
    "🕡",
    "🕢",
    "🕣",
    "🕤",
    "🕥",
    "🕦",
    "🕧",
  ],
  Flags: [
    "🏳️",
    "🏴",
    "🏴‍☠️",
    "🏁",
    "🚩",
    "🏳️‍🌈",
    "🏳️‍⚧️",
    "🇺🇳",
    "🇦🇫",
    "🇦🇽",
    "🇦🇱",
    "🇩🇿",
    "🇦🇸",
    "🇦🇩",
    "🇦🇴",
    "🇦🇮",
    "🇦🇶",
    "🇦🇬",
    "🇦🇷",
    "🇦🇲",
    "🇦🇼",
    "🇦🇺",
    "🇦🇹",
    "🇦🇿",
    "🇧🇸",
    "🇧🇭",
    "🇧🇩",
    "🇧🇧",
    "🇧🇾",
    "🇧🇪",
    "🇧🇿",
    "🇧🇯",
    "🇧🇲",
    "🇧🇹",
    "🇧🇴",
    "🇧🇦",
    "🇧🇼",
    "🇧🇷",
    "🇻🇬",
    "🇧🇳",
    "🇧🇬",
    "🇧🇫",
    "🇧🇮",
    "🇰🇭",
    "🇨🇲",
    "🇨🇦",
    "🇮🇨",
    "🇨🇻",
    "🇧🇶",
    "🇰🇾",
    "🇨🇫",
    "🇹🇩",
    "🇮🇴",
    "🇨🇱",
    "🇨🇳",
    "🇨🇽",
    "🇨🇨",
    "🇨🇴",
    "🇰🇲",
    "🇨🇬",
    "🇨🇩",
    "🇨🇰",
    "🇨🇷",
    "🇨🇮",
    "🇭🇷",
    "🇨🇺",
    "🇨🇼",
    "🇨🇾",
    "🇨🇿",
    "🇩🇰",
    "🇩🇯",
    "🇩🇲",
    "🇩🇴",
    "🇪🇨",
    "🇪🇬",
    "🇸🇻",
    "🇬🇶",
    "🇪🇷",
    "🇪🇪",
    "🇸🇿",
    "🇪🇹",
    "🇪🇺",
    "🇫🇰",
    "🇫🇴",
    "🇫🇯",
    "🇫🇮",
    "🇫🇷",
    "🇬🇫",
    "🇵🇫",
    "🇹🇫",
    "🇬🇦",
    "🇬🇲",
    "🇬🇪",
    "🇩🇪",
    "🇬🇭",
    "🇬🇮",
    "🇬🇷",
    "🇬🇱",
    "🇬🇩",
    "🇬🇵",
    "🇬🇺",
    "🇬🇹",
    "🇬🇬",
    "🇬🇳",
    "🇬🇼",
    "🇬🇾",
    "🇭🇹",
    "🇭🇳",
    "🇭🇰",
    "🇭🇺",
    "🇮🇸",
    "🇮🇳",
    "🇮🇩",
    "🇮🇷",
    "🇮🇶",
    "🇮🇪",
    "🇮🇲",
    "🇮🇱",
    "🇮🇹",
    "🇯🇲",
    "🇯🇵",
    "🎌",
    "🇯🇪",
    "🇯🇴",
    "🇰🇿",
    "🇰🇪",
    "🇰🇮",
    "🇽🇰",
    "🇰🇼",
    "🇰🇬",
    "🇱🇦",
    "🇱🇻",
    "🇱🇧",
    "🇱🇸",
    "🇱🇷",
    "🇱🇾",
    "🇱🇮",
    "🇱🇹",
    "🇱🇺",
    "🇲🇴",
    "🇲🇬",
    "🇲🇼",
    "🇲🇾",
    "🇲🇻",
    "🇲🇱",
    "🇲🇹",
    "🇲🇭",
    "🇲🇶",
    "🇲🇷",
    "🇲🇺",
    "🇾🇹",
    "🇲🇽",
    "🇫🇲",
    "🇲🇩",
    "🇲🇨",
    "🇲🇳",
    "🇲🇪",
    "🇲🇸",
    "🇲🇦",
    "🇲🇿",
    "🇲🇲",
    "🇳🇦",
    "🇳🇷",
    "🇳🇵",
    "🇳🇱",
    "🇳🇨",
    "🇳🇿",
    "🇳🇮",
    "🇳🇪",
    "🇳🇬",
    "🇳🇺",
    "🇳🇫",
    "🇰🇵",
    "🇲🇰",
    "🇲🇵",
    "🇳🇴",
    "🇴🇲",
    "🇵🇰",
    "🇵🇼",
    "🇵🇸",
    "🇵🇦",
    "🇵🇬",
    "🇵🇾",
    "🇵🇪",
    "🇵🇭",
    "🇵🇳",
    "🇵🇱",
    "🇵🇹",
    "🇵🇷",
    "🇶🇦",
    "🇷🇪",
    "🇷🇴",
    "🇷🇺",
    "🇷🇼",
    "🇼🇸",
    "🇸🇲",
    "🇸🇹",
    "🇸🇦",
    "🇸🇳",
    "🇷🇸",
    "🇸🇨",
    "🇸🇱",
    "🇸🇬",
    "🇸🇽",
    "🇸🇰",
    "🇸🇮",
    "🇬🇸",
    "🇸🇧",
    "🇸🇴",
    "🇿🇦",
    "🇰🇷",
    "🇸🇸",
    "🇪🇸",
    "🇱🇰",
    "🇧🇱",
    "🇸🇭",
    "🇰🇳",
    "🇱🇨",
    "🇵🇲",
    "🇻🇨",
    "🇸🇩",
    "🇸🇷",
    "🇸🇪",
    "🇨🇭",
    "🇸🇾",
    "🇹🇼",
    "🇹🇯",
    "🇹🇿",
    "🇹🇭",
    "🇹🇱",
    "🇹🇬",
    "🇹🇰",
    "🇹🇴",
    "🇹🇹",
    "🇹🇳",
    "🇹🇷",
    "🇹🇲",
    "🇹🇨",
    "🇹🇻",
    "🇺🇬",
    "🇺🇦",
    "🇦🇪",
    "🇬🇧",
    "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "󠁧󠁢󠁥󠁮🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "󠁧󠁢󠁥🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    "󠁧󠁢󠁥🇺🇸",
    "󠁧󠁢󠁥🇺🇾",
    "󠁧󠁢󠁥🇻🇮",
    "󠁧󠁢󠁥🇺🇿",
    "󠁧󠁢󠁥🇻🇺",
    "󠁧󠁢󠁥🇻🇦",
    "󠁧󠁢󠁥🇻🇪",
    "󠁧󠁢󠁥🇻🇳",
    "󠁧󠁢󠁥🇼🇫",
    "󠁧󠁢󠁥🇪🇭",
    "󠁧󠁢󠁥🇾🇪",
    "󠁧󠁢󠁥🇿🇲",
    "󠁧󠁢󠁥🇿🇼",
  ],
};

const allEmojis = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "🥹",
  "😅",
  "😂",
  "🤣",
  "🥲",
  "😊",
  "😇",
  "🙂",
  "🙃",
  "😉",
  "😌",
  "😍",
  "🥰",
  "😘",
  "😗",
  "😙",
  "😚",
  "😋",
  "😛",
  "😝",
  "😜",
  "🤪",
  "🤨",
  "🧐",
  "🤓",
  "😎",
  "🥸",
  "🤩",
  "🥳",
  "😏",
  "😒",
  "😞",
  "😔",
  "😟",
  "😕",
  "🙁",
  "😣",
  "😖",
  "😫",
  "😩",
  "🥺",
  "😢",
  "😭",
  "😤",
  "😠",
  "😡",
  "🤬",
  "🤯",
  "😳",
  "🥵",
  "🥶",
  "😶‍🌫️",
  "😱",
  "😨",
  "😰",
  "😥",
  "😓",
  "🤗",
  "🤔",
  "🫣",
  "🤭",
  "🫢",
  "🫡",
  "🤫",
  "🫠",
  "🤥",
  "😶",
  "🫥",
  "😐",
  "🫤",
  "😑",
  "🫨",
  "😬",
  "🙄",
  "😯",
  "😦",
  "😧",
  "😮",
  "😲",
  "🥱",
  "😴",
  "🤤",
  "😪",
  "😮‍💨",
  "😵",
  "😵‍💫",
  "🤐",
  "🥴",
  "🤢",
  "🤮",
  "🤧",
  "😷",
  "🤒",
  "🤕",
  "🤑",
  "🤠",
  "😈",
  "👿",
  "👹",
  "👺",
  "🤡",
  "💩",
  "👻",
  "💀",
  "👽",
  "👾",
  "🤖",
  "🎃",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "🫶",
  "🤲",
  "👐",
  "🙌",
  "👏",
  "🤝",
  "👍",
  "👎",
  "👊",
  "✊",
  "🤛",
  "🤜",
  "🤞",
  "🫰",
  "🤟",
  "🤘",
  "👌",
  "🤌",
  "🤏",
  "👈",
  "👉",
  "👆",
  "👇",
  "✋",
  "🤚",
  "🖐️",
  "🖖",
  "👋",
  "🤙",
  "💪",
  "🦾",
  "🙏",
  "🫵",
  "🦶",
  "🦵",
  "🦿",
  "💄",
  "💋",
  "👄",
  "🦷",
  "👅",
  "👂",
  "🦻",
  "👃",
  "👣",
  "👁️",
  "👀",
  "🫀",
  "🫁",
  "🧠",
  "🗣️",
  "👤",
  "👥",
  "🫂",
  "👶",
  "👧",
  "🧒",
  "👦",
  "👩",
  "🧑",
  "👨",
  "👩‍🦱",
  "🧑‍🦱",
  "👩‍🦰",
  "🧑‍🦰",
  "👨‍🦰",
  "👱‍♀️",
  "👱",
  "👱‍♂️",
  "👩‍🦳",
  "🧑‍🦳",
  "👨‍🦳",
  "👩‍🦲",
  "🧔‍♀️",
  "🧔",
  "🧔‍♂️",
  "👵",
  "🧓",
  "👴",
  "👲",
  "👳‍♀️",
  "👳",
  "👳‍♂️",
  "🧕",
  "👮‍♀️",
  "👮",
  "👮‍♂️",
  "👷‍♀️",
  "👷",
  "👷‍♂️",
  "💂‍♀️",
  "🕵️‍♀️",
  "🕵️‍♂️",
  "👩‍⚕️",
  "🧑‍⚕️",
  "👨‍⚕️",
  "👨‍⚕️",
  "👩‍🌾",
  "🧑‍🌾",
  "👨‍🌾",
  "👩‍🍳",
  "🧑‍🍳",
  "👨‍🍳",
  "👩‍🎓",
  "🧑‍🎓",
  "👨‍🎓",
  "👩‍🎤",
  "🧑‍🎤",
  "👨‍🎤",
  "👩‍🏫",
  "🧑‍🏫",
  "👨‍🏫",
  "👩‍🏭",
  "🧑‍🏭",
  "👨‍🏭",
  "👩‍💻",
  "🧑‍💻",
  "👨‍💻",
  "👩‍💼",
  "🧑‍💼",
  "👨‍💼",
  "👩‍🔧",
  "🧑‍🔧",
  "👨‍🔧",
  "👩‍🔬",
  "👨‍🔬",
  "👩‍🎨",
  "🧑‍🎨",
  "👨‍🎨",
  "👩‍🚒",
  "🧑‍🚒",
  "👨‍🚒",
  "👩‍✈️",
  "🧑‍✈️",
  "👨‍✈️",
  "👩‍🚀",
  "🧑‍🚀",
  "👨‍🚀",
  "👩‍⚖️",
  "🧑‍⚖️",
  "👨‍⚖️",
  "👰‍♀️",
  "👰",
  "👰‍♂️",
  "🤵‍♀️",
  "🤵",
  "🤵",
  "👸",
  "🫅",
  "🤴",
  "🥷",
  "🦸‍♀️",
  "🦸",
  "🦸‍♂️",
  "🦹‍♀️",
  "🦹",
  "🦹‍♂️",
  "🤶",
  "🧑‍🎄",
  "🎅",
  "🧙‍♀️",
  "🧙",
  "🧙‍♂️",
  "🧝‍♀️",
  "🧝",
  "🧝‍♂️",
  "🧌",
  "🧛‍♀️",
  "🧛",
  "🧛‍♂️",
  "🧟‍♀️",
  "🧟",
  "🧟‍♂️",
  "🧞‍♀️",
  "🧞",
  "🧞‍♂️",
  "🧜‍♀️",
  "🧜",
  "🧜‍♂️",
  "🧚‍♀️",
  "🧚",
  "🧚‍♂️",
  "👼",
  "🤰",
  "🫄",
  "🫃",
  "🤱",
  "👩‍🍼",
  "🧑‍🍼",
  "👨‍🍼",
  "🙇‍♀️",
  "🙇",
  "🙇‍♂️",
  "💁‍♀️",
  "💁",
  "💁‍♂️",
  "🙅‍♀️",
  "🙅",
  "🙅‍♂️",
  "🙆‍♀️",
  "🙆",
  "🙆‍♂️",
  "🙋‍♀️",
  "🙋",
  "🙋‍♂️",
  "🧏‍♀️",
  "🧏",
  "🧏‍♂️",
  "🤦‍♀️",
  "🤦",
  "🤦‍♂️",
  "🤷‍♀️",
  "🤷",
  "🤷‍♂️",
  "🙎‍♀️",
  "🙎",
  "🙎‍♂️",
  "🙍‍♀️",
  "🙍",
  "🙍‍♂️",
  "💇‍♀️",
  "💇",
  "💇‍♂️",
  "💆‍♀️",
  "💆",
  "💆‍♂️",
  "🧖‍♀️",
  "🧖",
  "🧖‍♂️",
  "💅",
  "🤳",
  "💃",
  "🕺",
  "👯‍♀️",
  "👯",
  "👯‍♂️",
  "🕴️",
  "👩‍🦽",
  "🧑‍🦽",
  "👨‍🦽",
  "👩‍🦼",
  "🧑‍🦼",
  "👨‍🦼",
  "🚶‍♀️",
  "🚶",
  "🚶‍♂️",
  "👩‍🦯",
  "🧑‍🦯",
  "👨‍🦯",
  "🧎‍♀️",
  "🧎",
  "🧎‍♂️",
  "🏃‍♀️",
  "🏃",
  "🏃‍♂️",
  "🧍‍♀️",
  "🧍",
  "🧍‍♂️",
  "👩🏽‍🤝‍👨🏿",
  "👩🏻‍🤝‍👩🏾",
  "👨🏾‍🤝‍👨🏼",
  "👩‍❤️‍👨",
  "👩‍❤️‍👩",
  "💑",
  "👨‍❤️‍👨",
  "👩‍❤️‍💋‍👨",
  "👩‍❤️‍💋‍👩",
  "💏",
  "👨‍👩‍👦",
  "👨‍👩‍👧",
  "👨‍👩‍👧‍👦",
  "👨‍👩‍👦‍👦",
  "👨‍👩‍👧‍👧",
  "👩‍👩‍👦",
  "👩‍👩‍👧",
  "👩‍👩‍👧‍👦",
  "👩‍👩‍👦‍👦",
  "👩‍👩‍👧‍👧",
  "👨‍👨‍👦",
  "👨‍👨‍👧",
  "👨‍👨‍👧‍👦",
  "👨‍👨‍👦‍👦",
  "👨‍👨‍👧‍👧",
  "👩‍👦",
  "👩‍👧",
  "👩‍👧‍👦",
  "👩‍👦‍👦",
  "👩‍👧‍👧",
  "👨‍👦",
  "👨‍👧",
  "👨‍👧‍👦",
  "👨‍👦‍👦",
  "👨‍👧‍👧",
  "🪢",
  "🧶",
  "🧵",
  "🪡",
  "🧥",
  "🥼",
  "🦺",
  "👚",
  "👕",
  "👖",
  "🩲",
  "🩳",
  "👔",
  "👗",
  "👙",
  "🩱",
  "👘",
  "🥻",
  "🩴",
  "🥿",
  "👠",
  "👡",
  "👢",
  "👞",
  "👟",
  "🥾",
  "🧦",
  "🧤",
  "🧣",
  "🎩",
  "🧢",
  "👒",
  "🎓",
  "⛑️",
  "🪖",
  "👑",
  "💍",
  "👝",
  "👛",
  "👜",
  "💼",
  "🎒",
  "🧳",
  "👓",
  "🕶️",
  "🥽",
  "🌂",
  "🐶",
  "🐱",
  "🐭",
  "🐹",
  "🐰",
  "🦊",
  "🐻",
  "🐼",
  "🐻‍❄️",
  "🐨",
  "🐯",
  "🦁",
  "🐮",
  "🐷",
  "🐽",
  "🐸",
  "🐵",
  "🙈",
  "🙉",
  "🙊",
  "🐒",
  "🐔",
  "🐧",
  "🐦",
  "🐤",
  "🐣",
  "🐥",
  "🪿",
  "🦆",
  "🐦‍⬛",
  "🦅",
  "🦉",
  "🦇",
  "🐺",
  "🐗",
  "🐴",
  "🦄",
  "🫎",
  "🐝",
  "🪱",
  "🐛",
  "🦋",
  "🐌",
  "🐞",
  "🐜",
  "🪰",
  "🪲",
  "🪳",
  "🦟",
  "🦗",
  "🕷️",
  "🕸️",
  "🦂",
  "🐢",
  "🐍",
  "🦎",
  "🦖",
  "🦕",
  "🐙",
  "🦑",
  "🪼",
  "🦐",
  "🦞",
  "🦀",
  "🐡",
  "🐠",
  "🐟",
  "🐬",
  "🐳",
  "🐋",
  "🦈",
  "🦭",
  "🐊",
  "🐅",
  "🐆",
  "🦓",
  "🦍",
  "🦧",
  "🦣",
  "🐘",
  "🦛",
  "🦏",
  "🐪",
  "🐫",
  "🦒",
  "🦘",
  "🦬",
  "🐃",
  "🐂",
  "🐄",
  "🫏",
  "🐎",
  "🐖",
  "🐏",
  "🐑",
  "🦙",
  "🐐",
  "🦌",
  "🐕",
  "🐩",
  "🦮",
  "🐕‍🦺",
  "🐈",
  "🐈‍⬛",
  "🪶",
  "🪽",
  "🐓",
  "🦃",
  "🦤",
  "🦚",
  "🦜",
  "🦢",
  "🦩",
  "🕊️",
  "🐇",
  "🦝",
  "🦨",
  "🦡",
  "🦫",
  "🦦",
  "🦥",
  "🐁",
  "🐀",
  "🐿️",
  "🦔",
  "🐾",
  "🐉",
  "🐲",
  "🌵",
  "🎄",
  "🌲",
  "🌳",
  "🌴",
  "🪵",
  "🌱",
  "🌿",
  "🍀",
  "🎍",
  "🪴",
  "🎋",
  "🍃",
  "🍂",
  "🍁",
  "🪺",
  "🪹",
  "🍄",
  "🐚",
  "🪸",
  "🪨",
  "🌾",
  "💐",
  "🌷",
  "🌹",
  "🥀",
  "🪻",
  "🪷",
  "🌺",
  "🌸",
  "🌼",
  "🌻",
  "🌞",
  "🌝",
  "🌛",
  "🌜",
  "🌚",
  "🌕",
  "🌖",
  "🌗",
  "🌘",
  "🌑",
  "🌒",
  "🌓",
  "🌔",
  "🌙",
  "🌎",
  "🌍",
  "🌏",
  "🪐",
  "💫",
  "⭐️",
  "🌟",
  "✨",
  "💥",
  "🔥",
  "🌪️",
  "🌈",
  "🌤️",
  "⛅️",
  "🌥️",
  "🌦️",
  "🌧️",
  "⛈️",
  "🌩️",
  "🌨️",
  "⛄️",
  "🌬️",
  "💨",
  "💧",
  "💦",
  "🫧",
  "🌊",
  "🌫️",
  "🍏",
  "🍎",
  "🍐",
  "🍊",
  "🍋",
  "🍌",
  "🍉",
  "🍇",
  "🍓",
  "🫐",
  "🍈",
  "🍒",
  "🍑",
  "🥭",
  "🍍",
  "🥥",
  "🥝",
  "🍅",
  "🍆",
  "🥑",
  "🫛",
  "🥦",
  "🥬",
  "🥒",
  "🌶️",
  "🫑",
  "🌽",
  "🥕",
  "🫒",
  "🧄",
  "🧅",
  "🥔",
  "🍠",
  "🫚",
  "🥐",
  "🥯",
  "🍞",
  "🥖",
  "🥨",
  "🧀",
  "🥚",
  "🍳",
  "🧈",
  "🥞",
  "🧇",
  "🥓",
  "🥩",
  "🍗",
  "🍖",
  "🦴",
  "🌭",
  "🍔",
  "🍟",
  "🍕",
  "🫓",
  "🥪",
  "🥙",
  "🧆",
  "🌮",
  "🌯",
  "🫔",
  "🥗",
  "🥘",
  "🫕",
  "🥫",
  "🫙",
  "🍝",
  "🍜",
  "🍲",
  "🍛",
  "🍣",
  "🍱",
  "🥟",
  "🦪",
  "🍤",
  "🍙",
  "🍚",
  "🍘",
  "🍥",
  "🥠",
  "🥮",
  "🍢",
  "🍡",
  "🍧",
  "🍨",
  "🍦",
  "🥧",
  "🧁",
  "🍰",
  "🎂",
  "🍮",
  "🍭",
  "🍬",
  "🍫",
  "🍿",
  "🍩",
  "🍪",
  "🌰",
  "🥜",
  "🫘",
  "🍯",
  "🥛",
  "🫗",
  "🍼",
  "🫖",
  "🍵",
  "🧃",
  "🥤",
  "🧋",
  "🍶",
  "🍺",
  "🍻",
  "🥂",
  "🍷",
  "🥃",
  "🍸",
  "🍹",
  "🧉",
  "🍾",
  "🧊",
  "🥄",
  "🍴",
  "🍽️",
  "🥣",
  "🥡",
  "🥢",
  "🧂",
  "⚽️",
  "🏀",
  "🏈",
  "⚾️",
  "🥎",
  "🎾",
  "🏐",
  "🏉",
  "🥏",
  "🎱",
  "🪀",
  "🏓",
  "🏸",
  "🏒",
  "🏑",
  "🥍",
  "🏏",
  "🪃",
  "🥅",
  "⛳️",
  "🪁",
  "🛝",
  "🏹",
  "🎣",
  "🤿",
  "🥊",
  "🥋",
  "🎽",
  "🛹",
  "🛼",
  "🛷",
  "⛸️",
  "🥌",
  "🎿",
  "⛷️",
  "🏂",
  "🪂",
  "🏋️‍♀️",
  "🏋️",
  "🏋️‍♂️",
  "🤼‍♀️",
  "🤼",
  "🤼‍♂️",
  "🤸‍♀️",
  "🤸",
  "🤸‍♂️",
  "⛹️‍♀️",
  "⛹️",
  "⛹️‍♂️",
  "🤺",
  "🤾‍♀️",
  "🤾",
  "🤾‍♂️",
  "🏌️‍♀️",
  "🏌️",
  "🏌️‍♂️",
  "🏇",
  "🧘‍♀️",
  "🧘",
  "🧘‍♂️",
  "🏄‍♀️",
  "🏄",
  "🏄‍♂️",
  "🏊‍♀️",
  "🏊",
  "🏊‍♂️",
  "🤽‍♀️",
  "🤽",
  "🤽‍♂️",
  "🚣‍♀️",
  "🚣",
  "🚣‍♂️",
  "🧗‍♀️",
  "🧗",
  "🧗‍♂️",
  "🚵‍♀️",
  "🚵",
  "🚵‍♂️",
  "🚴‍♀️",
  "🚴",
  "🚴‍♂️",
  "🏆",
  "🥇",
  "🥈",
  "🥉",
  "🏅",
  "🎖️",
  "🏵️",
  "🎗️",
  "🎫",
  "🎟️",
  "🎪",
  "🤹‍♀️",
  "🤹",
  "🤹‍♂️",
  "🎭",
  "🩰",
  "🎨",
  "🎬",
  "🎤",
  "🎼",
  "🎹",
  "🪇",
  "🥁",
  "🪘",
  "🎷",
  "🎺",
  "🪗",
  "🎸",
  "🪕",
  "🎻",
  "🪈",
  "🎲",
  "🎯",
  "🎳",
  "🎮",
  "🎰",
  "🧩",
  "🚗",
  "🚕",
  "🚙",
  "🚌",
  "🚎",
  "🏎️",
  "🚓",
  "🚑",
  "🚒",
  "🚐",
  "🛻",
  "🚚",
  "🚛",
  "🚜",
  "🦯",
  "🦽",
  "🦼",
  "🩼",
  "🛴",
  "🚲",
  "🛵",
  "🏍️",
  "🛺",
  "🛞",
  "🚨",
  "🚔",
  "🚍",
  "🚘",
  "🚖",
  "🚡",
  "🚠",
  "🚟",
  "🚃",
  "🚋",
  "🚞",
  "🚝",
  "🚄",
  "🚅",
  "🚈",
  "🚂",
  "🚆",
  "🚇",
  "🚊",
  "🚉",
  "🛫",
  "🛬",
  "🛩️",
  "💺",
  "🛰️",
  "🚀",
  "🛸",
  "🚁",
  "🛶",
  "⛵️",
  "🚤",
  "🛥️",
  "🛳️",
  "⛴️",
  "🚢",
  "🛟",
  "🪝",
  "⛽️",
  "🚧",
  "🚦",
  "🚥",
  "🚏",
  "🗺️",
  "🗿",
  "🗽",
  "🗼",
  "🏰",
  "🏯",
  "🏟️",
  "🎡",
  "🎢",
  "🎠",
  "⛲️",
  "⛱️",
  "🏖️",
  "🏝️",
  "🏜️",
  "🌋",
  "⛰️",
  "🏔️",
  "🗻",
  "🏕️",
  "⛺️",
  "🛖",
  "🏠",
  "🏡",
  "🏘️",
  "🏚️",
  "🏗️",
  "🏭",
  "🏢",
  "🏬",
  "🏣",
  "🏤",
  "🏥",
  "🏦",
  "🏨",
  "🏪",
  "🏫",
  "🏩",
  "💒",
  "🏛️",
  "⛪️",
  "🕌",
  "🕍",
  "🛕",
  "🕋",
  "⛩️",
  "🛤️",
  "🛣️",
  "🗾",
  "🎑",
  "🏞️",
  "🌅",
  "🌄",
  "🌠",
  "🎇",
  "🎆",
  "🌇",
  "🌆",
  "🏙️",
  "🌃",
  "🌌",
  "🌉",
  "🌁",
  "⌚️",
  "📱",
  "📲",
  "💻",
  "🖥️",
  "🖨️",
  "🖱️",
  "🖲️",
  "🕹️",
  "🗜️",
  "💽",
  "💾",
  "💿",
  "📀",
  "📼",
  "📷",
  "📸",
  "📹",
  "🎥",
  "📽️",
  "🎞️",
  "📞",
  "📟",
  "📠",
  "📺",
  "📻",
  "🎙️",
  "🎚️",
  "🎛️",
  "🧭",
  "⏱️",
  "⏲️",
  "⏰",
  "🕰️",
  "⌛️",
  "⏳",
  "📡",
  "🔋",
  "🪫",
  "🔌",
  "💡",
  "🔦",
  "🕯️",
  "🪔",
  "🧯",
  "🛢️",
  "💸",
  "💵",
  "💴",
  "💶",
  "💷",
  "🪙",
  "💰",
  "💳",
  "🪪",
  "💎",
  "🪜",
  "🧰",
  "🪛",
  "🔧",
  "🔨",
  "🛠️",
  "⛏️",
  "🪚",
  "🔩",
  "🪤",
  "🧱",
  "⛓️",
  "🧲",
  "🔫",
  "💣",
  "🧨",
  "🪓",
  "🔪",
  "🗡️",
  "🛡️",
  "🚬",
  "🪦",
  "🏺",
  "🔮",
  "📿",
  "🧿",
  "🪬",
  "💈",
  "🔭",
  "🔬",
  "🕳️",
  "🩻",
  "🩹",
  "🩺",
  "💊",
  "💉",
  "🩸",
  "🧬",
  "🦠",
  "🧫",
  "🧪",
  "🌡️",
  "🧹",
  "🪠",
  "🧺",
  "🧻",
  "🚽",
  "🚰",
  "🚿",
  "🛁",
  "🛀",
  "🧼",
  "🪥",
  "🪒",
  "🪮",
  "🧽",
  "🪣",
  "🧴",
  "🛎️",
  "🔑",
  "🗝️",
  "🚪",
  "🪑",
  "🛋️",
  "🛏️",
  "🛌",
  "🧸",
  "🪆",
  "🖼️",
  "🪞",
  "🪟",
  "🛍️",
  "🛒",
  "🎁",
  "🎈",
  "🎏",
  "🎀",
  "🪄",
  "🪅",
  "🎊",
  "🎉",
  "🎎",
  "🪭",
  "🏮",
  "🎐",
  "🪩",
  "🧧",
  "📩",
  "📨",
  "📧",
  "💌",
  "📥",
  "📤",
  "📦",
  "🏷️",
  "🪧",
  "📪",
  "📫",
  "📬",
  "📭",
  "📮",
  "📯",
  "📜",
  "📃",
  "📄",
  "📑",
  "🧾",
  "📊",
  "📈",
  "📉",
  "🗒️",
  "🗓️",
  "📆",
  "📅",
  "🗑️",
  "📇",
  "🗃️",
  "🗳️",
  "🗄️",
  "📋",
  "📁",
  "📂",
  "🗂️",
  "🗞️",
  "📰",
  "📓",
  "📔",
  "📒",
  "📕",
  "📗",
  "📘",
  "📙",
  "📚",
  "📖",
  "🔖",
  "🧷",
  "🔗",
  "📎",
  "🖇️",
  "📐",
  "📏",
  "🧮",
  "📌",
  "📍",
  "🖊️",
  "🖋️",
  "🖌️",
  "🖍️",
  "✏️",
  "📝",
  "🔍",
  "🔎",
  "🔏",
  "🔐",
  "🔒",
  "🔓",
  "🩷",
  "❤️",
  "🧡",
  "💛",
  "💚",
  "🩵",
  "💙",
  "💜",
  "🖤",
  "🩶",
  "🤍",
  "🤎",
  "💔",
  "❤️‍🔥",
  "❤️‍🩹",
  "❣️",
  "💕",
  "💞",
  "💓",
  "💗",
  "💖",
  "💘",
  "💝",
  "💟",
  "☮️",
  "✝️",
  "☪️",
  "🕉️",
  "☸️",
  "🪯",
  "✡️",
  "🔯",
  "🕎",
  "☯️",
  "☦️",
  "🛐",
  "⛎",
  "♈️",
  "♉️",
  "♊️",
  "♋️",
  "♌️",
  "♍️",
  "♎️",
  "♏️",
  "♐️",
  "♑️",
  "♒️",
  "♓️",
  "🆔",
  "⚛️",
  "🉑",
  "☢️",
  "☣️",
  "📴",
  "📳",
  "🈶",
  "🈚️",
  "🈸",
  "🈺",
  "🈷️",
  "✴️",
  "🆚",
  "💮",
  "🉐",
  "㊙️",
  "㊗️",
  "🈴",
  "🈵",
  "🈹",
  "🈲",
  "🅰️",
  "🅱️",
  "🆎",
  "🆑",
  "🅾️",
  "🆘",
  "❌",
  "⭕️",
  "🛑",
  "⛔️",
  "📛",
  "🚫",
  "💯",
  "💢",
  "♨️",
  "🚷",
  "🚯",
  "🚳",
  "🚱",
  "🔞",
  "📵",
  "🚭",
  "❗️",
  "❕",
  "❓",
  "❔",
  "‼️",
  "⁉️",
  "🔅",
  "🔆",
  "〽️",
  "⚠️",
  "🚸",
  "🔱",
  "⚜️",
  "🔰",
  "♻️",
  "✅",
  "🈯️",
  "💹",
  "❇️",
  "✳️",
  "❎",
  "🌐",
  "💠",
  "Ⓜ️",
  "🌀",
  "💤",
  "🏧",
  "🚾",
  "♿️",
  "🅿️",
  "🛗",
  "🈳",
  "🈂️",
  "🛂",
  "🛃",
  "🛄",
  "🛅",
  "🛜",
  "🚹",
  "🚺",
  "🚼",
  "⚧️",
  "🚻",
  "🚮",
  "🎦",
  "📶",
  "🈁",
  "🔣",
  "ℹ️",
  "🔤",
  "🔡",
  "🔠",
  "🆖",
  "🆗",
  "🆙",
  "🆒",
  "🆕",
  "🆓",
  "0️⃣",
  "1️⃣",
  "2️⃣",
  "3️⃣",
  "4️⃣",
  "5️⃣",
  "6️⃣",
  "7️⃣",
  "6️⃣",
  "9️⃣",
  "🔟",
  "🔢",
  "#️⃣",
  "*️⃣",
  "⏏️",
  "▶️",
  "⏸️",
  "⏯️",
  "⏹️",
  "⏭️",
  "⏮️",
  "⏩️",
  "⏪️",
  "⏫️",
  "⏬️",
  "◀️",
  "🔼",
  "🔽",
  "➡️",
  "⬅️",
  "⬆️",
  "⬇️",
  "↗️",
  "↘️",
  "↙️",
  "↖️",
  "↕️",
  "↔️",
  "↪️",
  "↩️",
  "⤴️",
  "⤵️",
  "🔀",
  "🔁",
  "🔂",
  "🔄",
  "🔃",
  "🎵",
  "🎶",
  "➕",
  "➖",
  "➗",
  "✖️",
  "🟰",
  "♾️",
  "💲",
  "💱",
  "™️",
  "©️",
  "®️",
  "👁️‍🗨️",
  "🔚",
  "🔙",
  "🔛",
  "🔝",
  "🔜",
  "〰️",
  "➰",
  "➿",
  "✔️",
  "☑️",
  "🔘",
  "🔴",
  "🟠",
  "🟡",
  "🟢",
  "🔵",
  "🟣",
  "⚫️",
  "⚪️",
  "🟤",
  "🔺",
  "🔻",
  "🔸",
  "🔹",
  "🔶",
  "🔷",
  "🔳",
  "🔲",
  "▪️",
  "▫️",
  "◾️",
  "◽️",
  "◼️",
  "◻️",
  "🟥",
  "🟧",
  "🟨",
  "🟩",
  "🟦",
  "🟪",
  "⬛️",
  "⬜️",
  "🟫",
  "🔈",
  "🔇",
  "🔉",
  "🔊",
  "🔔",
  "🔕",
  "📣",
  "📢",
  "💬",
  "💭",
  "🗯️",
  "♠️",
  "♣️",
  "♥️",
  "♦️",
  "🃏",
  "🎴",
  "🀄️",
  "🕐",
  "🕑",
  "🕒",
  "🕓",
  "🕔",
  "🕕",
  "🕖",
  "🕗",
  "🕘",
  "🕙",
  "🕚",
  "🕛",
  "🕜",
  "🕝",
  "🕞",
  "🕟",
  "🕠",
  "🕡",
  "🕢",
  "🕣",
  "🕤",
  "🕥",
  "🕦",
  "🕧",
  "🏳️",
  "🏴",
  "🏴‍☠️",
  "🏁",
  "🚩",
  "🏳️‍🌈",
  "🏳️‍⚧️",
  "🇺🇳",
  "🇦🇫",
  "🇦🇽",
  "🇦🇱",
  "🇩🇿",
  "🇦🇸",
  "🇦🇩",
  "🇦🇴",
  "🇦🇮",
  "🇦🇶",
  "🇦🇬",
  "🇦🇷",
  "🇦🇲",
  "🇦🇼",
  "🇦🇺",
  "🇦🇹",
  "🇦🇿",
  "🇧🇸",
  "🇧🇭",
  "🇧🇩",
  "🇧🇧",
  "🇧🇾",
  "🇧🇪",
  "🇧🇿",
  "🇧🇯",
  "🇧🇲",
  "🇧🇹",
  "🇧🇴",
  "🇧🇦",
  "🇧🇼",
  "🇧🇷",
  "🇻🇬",
  "🇧🇳",
  "🇧🇬",
  "🇧🇫",
  "🇧🇮",
  "🇰🇭",
  "🇨🇲",
  "🇨🇦",
  "🇮🇨",
  "🇨🇻",
  "🇧🇶",
  "🇰🇾",
  "🇨🇫",
  "🇹🇩",
  "🇮🇴",
  "🇨🇱",
  "🇨🇳",
  "🇨🇽",
  "🇨🇨",
  "🇨🇴",
  "🇰🇲",
  "🇨🇬",
  "🇨🇩",
  "🇨🇰",
  "🇨🇷",
  "🇨🇮",
  "🇭🇷",
  "🇨🇺",
  "🇨🇼",
  "🇨🇾",
  "🇨🇿",
  "🇩🇰",
  "🇩🇯",
  "🇩🇲",
  "🇩🇴",
  "🇪🇨",
  "🇪🇬",
  "🇸🇻",
  "🇬🇶",
  "🇪🇷",
  "🇪🇪",
  "🇸🇿",
  "🇪🇹",
  "🇪🇺",
  "🇫🇰",
  "🇫🇴",
  "🇫🇯",
  "🇫🇮",
  "🇫🇷",
  "🇬🇫",
  "🇵🇫",
  "🇹🇫",
  "🇬🇦",
  "🇬🇲",
  "🇬🇪",
  "🇩🇪",
  "🇬🇭",
  "🇬🇮",
  "🇬🇷",
  "🇬🇱",
  "🇬🇩",
  "🇬🇵",
  "🇬🇺",
  "🇬🇹",
  "🇬🇬",
  "🇬🇳",
  "🇬🇼",
  "🇬🇾",
  "🇭🇹",
  "🇭🇳",
  "🇭🇰",
  "🇭🇺",
  "🇮🇸",
  "🇮🇳",
  "🇮🇩",
  "🇮🇷",
  "🇮🇶",
  "🇮🇪",
  "🇮🇲",
  "🇮🇱",
  "🇮🇹",
  "🇯🇲",
  "🇯🇵",
  "🎌",
  "🇯🇪",
  "🇯🇴",
  "🇰🇿",
  "🇰🇪",
  "🇰🇮",
  "🇽🇰",
  "🇰🇼",
  "🇰🇬",
  "🇱🇦",
  "🇱🇻",
  "🇱🇧",
  "🇱🇸",
  "🇱🇷",
  "🇱🇾",
  "🇱🇮",
  "🇱🇹",
  "🇱🇺",
  "🇲🇴",
  "🇲🇬",
  "🇲🇼",
  "🇲🇾",
  "🇲🇻",
  "🇲🇱",
  "🇲🇹",
  "🇲🇭",
  "🇲🇶",
  "🇲🇷",
  "🇲🇺",
  "🇾🇹",
  "🇲🇽",
  "🇫🇲",
  "🇲🇩",
  "🇲🇨",
  "🇲🇳",
  "🇲🇪",
  "🇲🇸",
  "🇲🇦",
  "🇲🇿",
  "🇲🇲",
  "🇳🇦",
  "🇳🇷",
  "🇳🇵",
  "🇳🇱",
  "🇳🇨",
  "🇳🇿",
  "🇳🇮",
  "🇳🇪",
  "🇳🇬",
  "🇳🇺",
  "🇳🇫",
  "🇰🇵",
  "🇲🇰",
  "🇲🇵",
  "🇳🇴",
  "🇴🇲",
  "🇵🇰",
  "🇵🇼",
  "🇵🇸",
  "🇵🇦",
  "🇵🇬",
  "🇵🇾",
  "🇵🇪",
  "🇵🇭",
  "🇵🇳",
  "🇵🇱",
  "🇵🇹",
  "🇵🇷",
  "🇶🇦",
  "🇷🇪",
  "🇷🇴",
  "🇷🇺",
  "🇷🇼",
  "🇼🇸",
  "🇸🇲",
  "🇸🇹",
  "🇸🇦",
  "🇸🇳",
  "🇷🇸",
  "🇸🇨",
  "🇸🇱",
  "🇸🇬",
  "🇸🇽",
  "🇸🇰",
  "🇸🇮",
  "🇬🇸",
  "🇸🇧",
  "🇸🇴",
  "🇿🇦",
  "🇰🇷",
  "🇸🇸",
  "🇪🇸",
  "🇱🇰",
  "🇧🇱",
  "🇸🇭",
  "🇰🇳",
  "🇱🇨",
  "🇵🇲",
  "🇻🇨",
  "🇸🇩",
  "🇸🇷",
  "🇸🇪",
  "🇨🇭",
  "🇸🇾",
  "🇹🇼",
  "🇹🇯",
  "🇹🇿",
  "🇹🇭",
  "🇹🇱",
  "🇹🇬",
  "🇹🇰",
  "🇹🇴",
  "🇹🇹",
  "🇹🇳",
  "🇹🇷",
  "🇹🇲",
  "🇹🇨",
  "🇹🇻",
  "🇺🇬",
  "🇺🇦",
  "🇦🇪",
  "🇬🇧",
  "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
  "󠁧󠁢󠁥󠁮🏴󠁧󠁢󠁳󠁣󠁴󠁿",
  "󠁧󠁢󠁥🏴󠁧󠁢󠁷󠁬󠁳󠁿",
  "󠁧󠁢󠁥🇺🇸",
  "󠁧󠁢󠁥🇺🇾",
  "󠁧󠁢󠁥🇻🇮",
  "󠁧󠁢󠁥🇺🇿",
  "󠁧󠁢󠁥🇻🇺",
  "󠁧󠁢󠁥🇻🇦",
  "󠁧󠁢󠁥🇻🇪",
  "󠁧󠁢󠁥🇻🇳",
  "󠁧󠁢󠁥🇼🇫",
  "󠁧󠁢󠁥🇪🇭",
  "󠁧󠁢󠁥🇾🇪",
  "󠁧󠁢󠁥🇿🇲",
  "󠁧󠁢󠁥🇿🇼",
];

const emojiKeywords = {
  "😀": [
    "grinning face",
    "happy",
    "smile",
    "teeth",
    "smiling",
    "nice",
    "laugh",
    "grinning",
    "grin",
    "face",
    "cheery",
    "cheerful",
  ],
  "😃": [
    "grinning face with big eyes",
    "awesome",
    "big",
    "eyes",
    "face",
    "grin",
    "grinning",
    "happy",
    "mouth",
    "open",
    "smile",
    "smiling",
    "teeth",
    "yay",
  ],
  "😄": [
    "grinning face with smiling eyes",
    "eye",
    "eyes",
    "face",
    "grin",
    "grinning",
    "happy",
    "laugh",
    "lol",
    "mouth",
    "open",
    "smile",
    "smiling",
  ],
  "😁": [
    "beaming face with smiling eyes",
    "beaming",
    "eye",
    "eyes",
    "face",
    "grin",
    "grinning",
    "happy",
    "nice",
    "smile",
    "smiling",
    "teeth",
  ],
  "😆": [
    "grinning squinting face",
    "closed",
    "eyes",
    "face",
    "grinning",
    "haha",
    "hahaha",
    "happy",
    "laugh",
    "lol",
    "mouth",
    "open",
    "rofl",
    "smile",
    "smiling",
    "squinting",
  ],
  "🥹": [
    "face holding back tears",
    "admiration",
    "aww",
    "back",
    "cry",
    "embarrassed",
    "face",
    "feelings",
    "grateful",
    "gratitude",
    "holding",
    "joy",
    "please",
    "proud",
    "resist",
    "sad",
    "tears",
  ],
  "😅": [
    "grinning face with sweat",
    "cold",
    "dejected",
    "excited",
    "face",
    "grinning",
    "mouth",
    "nervous",
    "open",
    "smile",
    "smiling",
    "stress",
    "stressed",
    "sweat",
  ],
  "🤣": [
    "rolling on the floor laughing",
    "crying",
    "face",
    "floor",
    "funny",
    "haha",
    "happy",
    "hehe",
    "hilarious",
    "joy",
    "laugh",
    "lmao",
    "lol",
    "rofl",
    "roflmao",
    "rolling",
    "tear",
  ],
  "😅": [
    "face with tears of joy",
    "crying",
    "face",
    "feels",
    "funny",
    "funny",
    "haha",
    "happy",
    "hehe",
    "hilarious",
    "joy",
    "laugh",
    "lmao",
    "lol",
    "rofl",
    "roflmao",
    "tear",
  ],
  "🙂": [
    "slightly smiling face",
    "face",
    "happy",
    "slightly",
    "smile",
    "smiling",
  ],
  "🙃": ["upside down face", "face", "hehe", "upside down"],
  "🫠": [
    "melting face",
    "disappear",
    "dissolve",
    "embarrassed",
    "face",
    "haha",
    "heat",
    "hot",
    "liquid",
    "lol",
    "melt",
    "melting",
    "sarcasm",
    "sarcastic",
  ],
  "😉": [
    "winking face",
    "face",
    "flirt",
    "hearbreaker",
    "sexy",
    "slide",
    "tease",
    "wink",
    "winking",
    "winks",
  ],
  "😊": [
    "smiling face with smiling eyes",
    "blush",
    "eye",
    "eyes",
    "face",
    "glad",
    "satisfied",
    "smile",
    "smiling",
  ],
  "😇": [
    "smiling face with halo",
    "angel",
    "angelic",
    "angels",
    "blessed",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "halo",
    "happy",
    "innocent",
    "peaceful",
    "smile",
    "smiling",
    "spirit",
    "tale",
  ],
  "🥰": [
    "smiling face with hearts",
    "3",
    "adore",
    "crush",
    "face",
    "heart",
    "hearts",
    "ily",
    "love",
    "romance",
    "smile",
    "smiling",
    "you",
  ],
  "😍": [
    "smiling face with heart-eyes",
    "143",
    "bae",
    "eye",
    "face",
    "feels",
    "heart-eyes",
    "heart eyes",
    "hearts",
    "ily",
    "kisses",
    "love",
    "romance",
    "romantic",
    "smile",
    "xoxo",
  ],
  "🤩": [
    "star struck",
    "excited",
    "eyes",
    "face",
    "grinning",
    "smile",
    "star",
    "star-struck",
    "starry-eyed",
    "starry eyed",
    "wow",
  ],
  "😘": [
    "face blowing a kiss",
    "adorbs",
    "bae",
    "blowing",
    "face",
    "flirt",
    "heart",
    "ily",
    "kiss",
    "love",
    "lover",
    "miss",
    "muah",
    "romantic",
    "lovsmoocher",
    "xoxo",
    "you",
  ],
  "😗": [
    "kissing face",
    "143",
    "date",
    "dating",
    "face",
    "flirt",
    "ily",
    "kiss",
    "love",
    "smooch",
    "smooches",
    "xoxo",
    "you",
  ],
  "☺️": [
    "smiling face",
    "143",
    "date",
    "dating",
    "face",
    "flirt",
    "ily",
    "kiss",
    "love",
    "smooch",
    "smooches",
    "xoxo",
    "you",
  ],
  "😚": [
    "kissing face with closed eyes",
    "143",
    "closed",
    "date",
    "dating",
    "eye",
    "eyes",
    "face",
    "flirt",
    "ily",
    "kiss",
    "kisses",
    "kissing",
    "smooches",
    "xoxo",
  ],
  "😙": [
    "kissing face with smiling eyes",
    "143",
    "closed",
    "date",
    "dating",
    "eye",
    "eyes",
    "face",
    "flirt",
    "ily",
    "kiss",
    "kisses",
    "kissing",
    "love",
    "night",
    "smile",
    "smiling",
  ],
  "🥲": [
    "smiling face with tear",
    "face",
    "glad",
    "grateful",
    "happy",
    "joy",
    "pain",
    "proud",
    "relieved",
    "smile",
    "smiley",
    "smiling",
    "tear",
    "touched",
  ],
  "😋": [
    "face savoring food",
    "delicious",
    "eat",
    "face",
    "food",
    "full",
    "hungry",
    "savor",
    "smile",
    "smiling",
    "tasty",
    "um",
    "yum",
    "yummy",
  ],
  "😛": [
    "face with tongue",
    "awesome",
    "cool",
    "face",
    "nice",
    "party",
    "stuck-out",
    "sweet",
    "tongue",
  ],
  "😜": [
    "winking face with tongue",
    "crazy",
    "epic",
    "eye",
    "face",
    "funny",
    "joke",
    "loopy",
    "nutty",
    "party",
    "stuck-out",
    "tongue",
    "wacky",
    "weirdo",
    "wink",
    "winking",
    "yolo",
  ],
  "🤪": [
    "zany face",
    "crazy",
    "eye",
    "eyes",
    "face",
    "goofy",
    "large",
    "small",
    "zany",
  ],
  "😝": [
    "squinting face with tongue",
    "closed",
    "eye",
    "eyes",
    "face",
    "gross",
    "horrible",
    "omg",
    "squinting",
    "stuck-out",
    "taste",
    "tongue",
    "whatever",
    "yolo",
  ],
  "🤑": ["money-mouth face", "face", "money", "money-mouth", "mouth", "paid"],
  "🤗": [
    "smiling face with open hands",
    "face",
    "hands",
    "hug",
    "hugging",
    "open",
    "smiling",
  ],
  "🤭": [
    "face with hand over mouth",
    "face",
    "giggle",
    "giggling",
    "hand",
    "mouth",
    "oops",
    "realization",
    "secret",
    "shock",
    "sudden",
    "surprise",
    "whoops",
    "sudden realization",
  ],
  "🫢": [
    "face with open eyes and hand over mouth",
    "amazement",
    "awe",
    "disbelief",
    "embarrass",
    "eyes",
    "face",
    "gasp",
    "hand",
    "mouth",
    "omg",
    "open",
    "over",
    "quiet",
    "scared",
    "shock",
    "surprise",
  ],
  "🫣": [
    "face with peeking eye",
    "captivated",
    "embarass",
    "eye",
    "face",
    "hide",
    "hiding",
    "peek",
    "peeking",
    "peep",
    "scared",
    "shy",
    "stare",
  ],
  "🤫": ["shushing face", "face", "quiet", "shh", "shush", "shushing"],
  "🤔": [
    "thinking face",
    "chin",
    "consider",
    "face",
    "hmmm",
    "ponder",
    "pondering",
    "thinking",
    "wondering",
  ],
  "🫡": [
    "saluting face",
    "face",
    "good",
    "luck",
    "ma'am",
    "OK",
    "respect",
    "salute",
    "saluting",
    "sir",
    "troops",
    "yes",
  ],
  "🤐": [
    "zipper-mouth face",
    "face",
    "keep",
    "mouth",
    "quiet",
    "secret",
    "shut",
    "zip",
    "zipper",
    "zipper-mouth",
  ],
  "🤨": [
    "face with raised eyebrow",
    "disapproval",
    "disbelief",
    "distrust",
    "emoji",
    "eyebrow",
    "face",
    "hmm",
    "mild",
    "raised",
    "skeptic",
    "skeptical",
    "skepticism",
    "surprise",
    "what",
    "mild surprise",
    "scepticism",
  ],
  "😐": [
    "neutral face",
    "awkward",
    "blank",
    "deadpan",
    "expressionless",
    "face",
    "fine",
    "jealous",
    "meh",
    "neutral",
    "oh",
    "shade",
    "straight",
    "unamused",
    "unhappy",
    "unimpressed",
    "whatever",
  ],
  "😑": [
    "expressionless face",
    "awkward",
    "dead",
    "expressionless",
    "face",
    "fine",
    "inexpressive",
    "jealous",
    "meh",
    "not",
    "oh",
    "omg",
    "straight",
    "uh",
    "unhappy",
    "unimpressed",
    "whatever",
  ],
  "😶": [
    "face without mouth",
    "awkward",
    "blank",
    "expressionless",
    "face",
    "mouth",
    "mouthless",
    "mute",
    "quiet",
    "secret",
    "silence",
    "silent",
    "speechless",
  ],
  "🫥": [
    "dotted line face",
    "depressed",
    "disappear",
    "dotted",
    "face",
    "hidden",
    "hide",
    "introvert",
    "invisible",
    "line",
    "meh",
    "whatever",
    "wtv",
  ],
  "😶‍🌫️": ["face in clouds", "absentminded", "clouds", "face", "fog", "head"],
  "😏": [
    "smirking face",
    "boss",
    "dapper",
    "face",
    "flirt",
    "homie",
    "kidding",
    "leer",
    "shade",
    "slick",
    "sly",
    "smirk",
    "smug",
    "snicker",
    "suave",
    "suspicious",
    "swag",
  ],
  "😒": [
    "unamused face",
    "...",
    "bored",
    "face",
    "fine",
    "jealous",
    "jel",
    "jelly",
    "pissed",
    "smh",
    "ugh",
    "uhh",
    "unamused",
    "unhappy",
    "weird",
    "whatever",
  ],
  "🙄": [
    "face with rolling eyes",
    "eyeroll",
    "eyes",
    "face",
    "rolling",
    "shade",
    "ugh",
    "whatever",
  ],
  "🙄": [
    "face with rolling eyes",
    "eyeroll",
    "eyes",
    "face",
    "rolling",
    "shade",
    "ugh",
    "whatever",
  ],
  "😬": [
    "grimacing face",
    "awk",
    "awkward",
    "dentist",
    "face",
    "grimace",
    "grimacing",
    "grinning",
    "smile",
    "smiling",
  ],
  "😮‍💨": [
    "face exhaling",
    "blow",
    "blowing",
    "exhale",
    "exhaling",
    "exhausted",
    "face",
    "gasp",
    "groan",
    "relief",
    "sigh",
    "smiley",
    "smoke",
    "whisper",
    "whistle",
  ],
  "🤥": ["lying face", "face", "liar", "lie", "lying", "pinocchio"],
  "🫨": [
    "shaking face",
    "crazy",
    "daze",
    "earthquake",
    "face",
    "omg",
    "panic",
    "shaking",
    "shock",
    "surprise",
    "vibrate",
    "whoa",
    "wow",
  ],
  "😌": ["relieved face", "calm", "face", "peace", "relief", "relieved", "zen"],
  "😔": [
    "pensive face",
    "awful",
    "bored",
    "dejected",
    "died",
    "disappointed",
    "face",
    "losing",
    "lost",
    "pensive",
    "sad",
    "sucks",
  ],
  "😪": [
    "sleepy face",
    "crying",
    "face",
    "good",
    "night",
    "sad",
    "sleep",
    "sleeping",
    "sleepy",
    "tired",
  ],
  "🤤": ["drooling face", "drooling", "face"],
  "😴": [
    "sleeping face",
    "bed",
    "bedtime",
    "face",
    "good",
    "goodnight",
    "nap",
    "night",
    "sleep",
    "sleeping",
    "tired",
    "whatever",
    "yawn",
    "zzz",
  ],
  "😷": [
    "face with medical mask",
    "cold",
    "dentist",
    "dermatologist",
    "doctor",
    "dr",
    "face",
    "germs",
    "mask",
    "medical",
    "medicine",
    "sick",
  ],
  "🤒": ["face with thermometer", "face", "ill", "sick", "thermometer"],
  "🤕": [
    "face with head-bandage",
    "face",
    "bandage",
    "head-bandage",
    "hurt",
    "injury",
    "ouch",
  ],
  "🤢": [
    "nauseated face",
    "face",
    "gross",
    "nasty",
    "nauseated",
    "sick",
    "vomit",
  ],
  "🤮": [
    "face vomiting",
    "barf",
    "ew",
    "face",
    "gross",
    "puke",
    "sick",
    "spew",
    "throw",
    "up",
    "vomit",
    "vomiting",
  ],
  "🤧": [
    "sneezing face",
    "face",
    "fever",
    "flu",
    "gesundheit",
    "sick",
    "sneeze",
    "sneezing",
  ],
  "🥵": [
    "hot face",
    "dying",
    "face",
    "feverish",
    "heat",
    "hot",
    "panting",
    "red-faced",
    "stroke",
    "sweating",
    "tongue",
  ],
  "🥶": [
    "cold face",
    "blue",
    "blue-faced",
    "cold",
    "face",
    "freezing",
    "frostbite",
    "icicles",
    "subzero",
    "teeth",
  ],
  "🥴": [
    "woozy face",
    "dizzy",
    "drunk",
    "eyes",
    "face",
    "intoxicated",
    "mouth",
    "tipsy",
    "uneven",
    "wavy",
    "woozy",
  ],
  "😵": [
    "face with crossed-out eyes",
    "crossed-out",
    "dead",
    "dizzy",
    "eyes",
    "face",
    "feels",
    "knocked",
    "out",
    "sick",
    "tired",
  ],
  "😵‍💫": [
    "face with spiral eyes",
    "confused",
    "dizzy",
    "eyes",
    "face",
    "hypnotized",
    "omg",
    "smiley",
    "spiral",
    "trouble",
    "whoa",
    "woah",
    "woozy",
  ],
  "🤯": [
    "exploding head",
    "blown",
    "explode",
    "exploding",
    "head",
    "mind",
    "mindblown",
    "no",
    "shocked",
    "way",
  ],
  "🤠": ["cowboy hat face", "cowboy", "cowgirl", "face", "hat"],
  "🥳": [
    "partying face",
    "bday",
    "birthday",
    "celebrate",
    "celebration",
    "excited",
    "face",
    "happy",
    "hat",
    "hooray",
    "horn",
    "party",
    "partying",
  ],
  "🥸": [
    "disguised face",
    "disguise",
    "eyebrow",
    "face",
    "glasses",
    "incognito",
    "moustache",
    "mustache",
    "nose",
    "person",
    "spy",
    "tache",
    "tash",
  ],
  "😎": [
    "smiling face with sunglasses",
    "awesome",
    "beach",
    "bright",
    "bro",
    "chilling",
    "cool",
    "face",
    "rad",
    "relaxed",
    "shades",
    "slay",
    "smile",
    "style",
    "sunglasses",
    "swag",
    "win",
  ],
  "🤓": [
    "nerd face",
    "brainy",
    "clever",
    "expert",
    "face",
    "geek",
    "gifted",
    "glasses",
    "intelligent",
    "nerd",
    "smart",
  ],
  "🧐": [
    "face with monocle",
    "classy",
    "face",
    "fancy",
    "monocle",
    "rich",
    "stuffy",
    "wealthy",
  ],
  "😕": [
    "confused face",
    "befuddled",
    "confused",
    "confusing",
    "dunno",
    "face",
    "frown",
    "hm",
    "meh",
    "not",
    "sad",
    "sorry",
    "sure",
  ],
  "🫤": [
    "face with diagonal mouth",
    "confused",
    "confusion",
    "diagonal",
    "disappointed",
    "doubt",
    "doubtful",
    "face",
    "frustrated",
    "frustration",
    "meh",
    "mouth",
    "skeptical",
    "surprised",
    "worried",
    "worry",
  ],
  "😟": [
    "worried face",
    "anxious",
    "butterflies",
    "face",
    "nerves",
    "nervous",
    "sad",
    "stress",
    "stressed",
    "surprised",
    "worried",
    "worry",
  ],
  "🙁": [
    "slightly frowning face",
    "face",
    "frown",
    "frowning",
    "sad",
    "slightly",
  ],
  "☹️": ["frowning face", "face", "frown", "frowning", "sad"],
  "😮": [
    "face with open mouth",
    "believe",
    "face",
    "forgot",
    "mouth",
    "omg",
    "open",
    "shocked",
    "surprised",
    "sympathy",
    "unbelievable",
    "unreal",
    "whoa",
    "wow",
    "you",
  ],
  "😯": [
    "surprised face",
    "epic",
    "face",
    "hushed",
    "omg",
    "stunned",
    "surprised",
    "whoa",
    "woah",
  ],
  "😲": [
    "astonished face",
    "astonished",
    "cost",
    "face",
    "no",
    "omg",
    "shocked",
    "totally",
    "way",
  ],
  "😳": [
    "flushed face",
    "amazed",
    "awkward",
    "crazy",
    "dazed",
    "dead",
    "disbelief",
    "embarrassed",
    "face",
    "flushed",
    "geez",
    "heat",
    "hot",
    "impressed",
    "jeez",
    "what",
    "wow",
  ],
  "🥺": [
    "pleading face",
    "begging",
    "big",
    "eyes",
    "face",
    "mercy",
    "not",
    "pleading",
    "please",
    "pretty",
    "puppy",
    "sad",
    "why",
  ],
  "🥹": [
    "face holding back tears",
    "admiration",
    "aww",
    "back",
    "cry",
    "embarrassed",
    "face",
    "feelings",
    "grateful",
    "gratitude",
    "holding",
    "joy",
    "please",
    "proud",
    "resist",
    "sad",
    "tears",
  ],
  "😦": [
    "frowning face with open mouth",
    "caught",
    "face",
    "frown",
    "frowning",
    "guard",
    "mouth",
    "open",
    "scared",
    "scary",
    "surprise",
    "what",
    "wow",
  ],
  "😧": [
    "anguished face",
    "anguished",
    "face",
    "forgot",
    "scared",
    "scary",
    "stressed",
    "surprise",
    "unhappy",
    "what",
    "wow",
  ],
  "😨": [
    "fearful face",
    "afraid",
    "anxious",
    "blame",
    "face",
    "fear",
    "fearful",
    "scared",
    "worried",
  ],
  "😰": [
    "anxious face with sweat",
    "anxious",
    "blue",
    "cold",
    "eek",
    "face",
    "mouth",
    "nervous",
    "open",
    "rushed",
    "scared",
    "sweat",
    "yikes",
  ],
  "😥": [
    "sad but relieved face",
    "anxious",
    "call",
    "close",
    "complicated",
    "disappointed",
    "face",
    "not",
    "relieved",
    "sad",
    "sweat",
    "time",
    "whew",
  ],
  "😢": [
    "crying face",
    "awful",
    "cry",
    "crying",
    "face",
    "feels",
    "miss",
    "sad",
    "tear",
    "triste",
    "unhappy",
  ],
  "😭": [
    "loudly crying face",
    "bawling",
    "cry",
    "crying",
    "face",
    "loudly",
    "sad",
    "sob",
    "tear",
    "tears",
    "unhappy",
  ],
  "😱": [
    "face screaming in fear",
    "epic",
    "face",
    "fear",
    "fearful",
    "munch",
    "scared",
    "scream",
    "screamer",
    "face",
    "feels",
    "frustrated",
    "mad",
    "sad",
  ],
  "😖": [
    "confounded face",
    "annoyed",
    "confounded",
    "confused",
    "cringe",
    "distraught",
    "face",
    "feels",
    "frustrated",
    "mad",
    "sad",
  ],
  "😣": [
    "persevering face",
    "concentrate",
    "concentration",
    "face",
    "focus",
    "headache",
    "persevere",
    "persevering",
  ],
  "😞": [
    "disappointed face",
    "awful",
    "blame",
    "dejected",
    "disappointed",
    "face",
    "fail",
    "losing",
    "sad",
    "unhappy",
  ],
  "😓": [
    "downcast face with sweat",
    "close",
    "cold",
    "downcast",
    "face",
    "feels",
    "headache",
    "nervous",
    "sad",
    "scared",
    "sweat",
    "yikes",
  ],
  "😩": [
    "weary face",
    "crying",
    "face",
    "fail",
    "feels",
    "hungry",
    "mad",
    "nooo",
    "sad",
    "sleepy",
    "tired",
    "unhappy",
    "weary",
  ],
  "😫": [
    "distraught face",
    "cost",
    "face",
    "feels",
    "nap",
    "sad",
    "sneeze",
    "tired",
  ],
  "😤": [
    "face with steam from nose",
    "anger",
    "angry",
    "face",
    "feels",
    "fume",
    "fuming",
    "furious",
    "fury",
    "mad",
    "nose",
    "steam",
    "triumph",
    "unhappy",
    "won",
  ],
  "😡": [
    "enraged face",
    "anger",
    "angry",
    "enraged",
    "face",
    "feels",
    "mad",
    "maddening",
    "pouting",
    "rage",
    "red",
    "shade",
    "unhappy",
    "upset",
  ],
  "😠": [
    "angry face",
    "anger",
    "angry",
    "blame",
    "face",
    "feels",
    "frustrated",
    "mad",
    "maddening",
    "rage",
    "shade",
    "unhappy",
    "upset",
  ],
  "🤬": [
    "face with symbols on mouth",
    "censor",
    "cursing",
    "cussing",
    "face",
    "mad",
    "mouth",
    "pissed",
    "swearing",
    "symbols",
  ],
  "😈": [
    "smiling face with horns",
    "demon",
    "devil",
    "evil",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "horns",
    "purple",
    "shade",
    "smile",
    "smiling",
    "tale",
  ],
  "👿": [
    "angry face with horns",
    "demon",
    "devil",
    "evil",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "horns",
    "purple",
    "shade",
    "smile",
    "smiling",
    "tale",
    "angry",
  ],
  "💀": [
    "skull",
    "body",
    "dead",
    "death",
    "face",
    "fairy",
    "fairytale",
    "lmao",
    "monster",
    "tale",
    "yolo",
  ],
  "☠️": ["bone", "crossbones", "dead", "death", "face", "monster", "skull"],
  "💩": [
    "pile of poo",
    "bs",
    "comic",
    "doo",
    "dung",
    "face",
    "fml",
    "monster",
    "pile",
    "poo",
    "poop",
    "smelly",
    "smh",
    "stink",
    "stinks",
    "stinky",
    "turd",
  ],
  "🤡": ["clown face", "clown", "face"],
  "👹": [
    "ogre",
    "creature",
    "devil",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "mask",
    "monster",
    "scary",
    "tale",
    "troll",
  ],
  "👺": [
    "angry",
    "creature",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "mask",
    "monster",
    "mean",
    "tale",
    "mask",
  ],
  "👻": [
    "boo",
    "creature",
    "excited",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "halloween",
    "haunting",
    "monster",
    "scary",
    "silly",
    "tale",
  ],
  "👽": [
    "creature",
    "extraterrestrial",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "monster",
    "space",
    "tale",
    "ufo",
  ],
  "👾": [
    "alien",
    "alien monster",
    "extraterrestrial",
    "creature",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "game",
    "gamer",
    "games",
    "monster",
    "pixelated",
    "space",
    "tale",
    "ufo",
  ],
  "🤖": ["robot", "face", "monster"],
  "😺": [
    "grinning cat",
    "animal",
    "cat",
    "face",
    "grinning",
    "mouth",
    "open",
    "smile",
    "smiling",
  ],
  "😸": [
    "grinning cat with smiling eyes",
    "animal",
    "cat",
    "eye",
    "face",
    "grinning",
    "mouth",
    "open",
    "smile",
    "smiling",
  ],
  "😹": [
    "cat with tears of joy",
    "animal",
    "cat",
    "joy",
    "face",
    "laugh",
    "laughing",
    "lol",
    "tear",
    "tears",
  ],
  "😻": [
    "smiling cat with heart-eyes",
    "animal",
    "cat",
    "eye",
    "face",
    "heart",
    "heart-eyes",
    "love",
    "smile",
    "smiling",
  ],
  "😼": [
    "cat with wry smile",
    "animal",
    "cat",
    "face",
    "ironic",
    "wry",
    "smile",
    "smiling",
  ],
  "😽": [
    "kissing cat",
    "animal",
    "cat",
    "face",
    "closed",
    "eye",
    "eyes",
    "kiss",
    "kissing",
  ],
  "🙀": ["weary cat", "animal", "cat", "face", "oh", "surprised", "weary"],
  "😿": ["crying cat", "animal", "cat", "face", "cry", "crying", "sad", "tear"],
  "😾": ["pouting cat", "animal", "cat", "face", "pouting"],
  "🙈": [
    "see-no-evil monkey",
    "embarrassed",
    "evil",
    "face",
    "forbidden",
    "forgot",
    "gesture",
    "hide",
    "monkey",
    "no",
    "omg",
    "prohibited",
    "scared",
    "secret",
    "smh",
    "watch",
  ],
  "🙉": [
    "hear-no-evil monkey",
    "animal",
    "ears",
    "evil",
    "face",
    "forbidden",
    "gesture",
    "hear",
    "monkey",
    "listen",
    "no",
    "not",
    "prohibited",
    "secret",
    "smh",
    "tmi",
  ],
  "🙊": [
    "speak-no-evil monkey",
    "animal",
    "evil",
    "face",
    "forbidden",
    "gesture",
    "oops",
    "monkey",
    "no",
    "not",
    "quiet",
    "secret",
    "speak",
    "stealth",
  ],
  "💌": [
    "love letter",
    "heart",
    "letter",
    "love",
    "mail",
    "romance",
    "valentine",
  ],
  "💘": [
    "heart with arrow",
    "143",
    "adorbs",
    "arrow",
    "cupid",
    "date",
    "emotion",
    "heart",
    "ily",
    "love",
    "romance",
    "valentine",
  ],
  "💝": [
    "heart with ribbon",
    "143",
    "anniversary",
    "kisses",
    "morning",
    "night",
    "sparkle",
    "sparkling",
    "xoxo",
    "heart",
    "ily",
    "ribbon",
    "romance",
    "valentine",
  ],
  "💖": [
    "heart with ribbon",
    "143",
    "anniversary",
    "kisses",
    "morning",
    "night",
    "sparkle",
    "sparkling",
    "xoxo",
    "heart",
    "ily",
    "love",
    "romance",
    "valentine",
  ],
  "💗": [
    "growing heart",
    "143",
    "emotion",
    "excited",
    "growing",
    "heart",
    "heartpulse",
    "ily",
    "kisses",
    "muah",
    "nervous",
    "pulse",
    "xoxo",
  ],
  "💓": [
    "beating heart",
    "143",
    "beating",
    "cardio",
    "emotion",
    "heart",
    "heartbeat",
    "ily",
    "love",
    "pulsating",
    "pulse",
  ],
  "💞": [
    "revolving hearts",
    "143",
    "adorbs",
    "anniversary",
    "emotion",
    "heart",
    "hearts",
    "revolving",
  ],
  "💕": [
    "two hearts",
    "143",
    "date",
    "anniversary",
    "emotion",
    "dating",
    "hearts",
    "heart",
    "ily",
    "kisses",
    "love",
    "loving",
    "two",
    "xoxo",
  ],
  "💟": [
    "heart decoration",
    "143",
    "decoration",
    "hearth",
    "emotion",
    "purple",
    "heart",
    "white",
  ],
  "❣️": [
    "heart exclamation",
    "exclamation",
    "heart",
    "heavy",
    "mark",
    "punctuation",
  ],
  "💔": [
    "broken heart",
    "break",
    "heart",
    "broken",
    "crushed",
    "emotion",
    "heartbroken",
    "lonely",
    "sad",
  ],
  "❤️‍🔥": [
    "heart on fire",
    "break",
    "broken",
    "crushed",
    "emotion",
    "heart",
    "heartbroken",
    "lonely",
    "sad",
  ],
  "❤️‍🩹": [
    "mending heart",
    "healthier",
    "heart",
    "improving",
    "mending",
    "recovering",
    "recuperating",
    "well",
  ],
  "❤️": ["red heart", "emotion", "heart", "love", "red"],
  "🩷": [
    "pink heart",
    "143",
    "adorable",
    "cute",
    "emotion",
    "heart",
    "ily",
    "like",
    "love",
    "pink",
    "special",
    "sweet",
  ],
  "🧡": ["orange heart", "143", "heart", "orange"],
  "💛": [
    "yellow heart",
    "143",
    "heart",
    "cardiac",
    "emotion",
    "ily",
    "love",
    "yellow",
  ],
  "💚": [
    "green heart",
    "143",
    "emotion",
    "green",
    "heart",
    "ily",
    "love",
    "romantic",
  ],
  "💙": [
    "blue heart",
    "143",
    "blue",
    "emotion",
    "heart",
    "ily",
    "love",
    "romance",
  ],
  "🩵": [
    "light blue heart",
    "143",
    "blue",
    "cute",
    "cyan",
    "emotion",
    "heart",
    "ily",
    "light",
    "like",
    "love",
    "sky",
    "special",
    "teal",
  ],
  "💜": [
    "purple heart",
    "143",
    "bestest",
    "emotion",
    "heart",
    "ily",
    "love",
    "ily",
    "purple",
  ],
  "🤎": ["brown heart", "143", "brown", "heart"],
  "🖤": ["black heart", "black", "evil", "heart", "wicked"],
  "🩶": [
    "grey heart",
    "143",
    "emotion",
    "gray",
    "grey",
    "heart",
    "ily",
    "love",
    "silver",
    "slate",
    "special",
  ],
  "🤍": ["white heart", "143", "heart", "white"],
  "💋": [
    "kiss mark",
    "dating",
    "emotion",
    "heart",
    "kiss",
    "kissing",
    "lips",
    "mark",
    "romance",
    "sexy",
  ],
  "💯": [
    "hundred points",
    "100",
    "a+",
    "agree",
    "clearly",
    "definitely",
    "faithful",
    "fleek",
    "full",
    "hundred",
    "keep",
    "perfect",
    "point",
    "score",
    "TRUE",
    "truth",
    "yup",
  ],
  "💢": ["anger symbol", "anger", "angry", "comic", "mad", "symbol", "upset"],
  "💥": [
    "collision",
    "bomb",
    "boom",
    "collide",
    "collision",
    "comic",
    "explode",
  ],
  "💫": ["dizzy", "comic", "shining", "shooting", "star", "stars"],
  "💦": [
    "sweat droplets",
    "comic",
    "drip",
    "droplet",
    "droplets",
    "drops",
    "splashing",
    "squirt",
    "sweat",
    "water",
    "wet",
    "work",
    "workout",
  ],
  "💨": [
    "dashing away",
    "away",
    "cloud",
    "comic",
    "dash",
    "dashing",
    "fart",
    "fast",
    "go",
    "gone",
    "gotta",
    "running",
    "smoke",
  ],
  "🕳️": ["hole"],
  "💬": [
    "speech balloon",
    "balloon",
    "bubble",
    "comic",
    "dialog",
    "message",
    "sms",
    "speech",
    "talk",
    "text",
    "typing",
  ],
  "👁️‍🗨️": [
    "eye in speech bubble",
    "balloon",
    "bubble",
    "eye",
    "speech",
    "witness",
  ],
  "🗯️": ["anger bubble", "anger", "angry", "balloon", "bubble", "mad", "right"],
  "💭": [
    "thought balloon",
    "balloon",
    "bubble",
    "cartoon",
    "cloud",
    "comic",
    "daydream",
    "decisions",
    "dream",
    "idea",
    "invent",
    "invention",
    "realize",
    "think",
    "thoughts",
    "wonder",
  ],
  "💤": [
    "zzz",
    "comic",
    "good",
    "goodnight",
    "night",
    "sleep",
    "sleeping",
    "sleepy",
    "tired",
  ],
  "👋": [
    "waving hand",
    "bye",
    "cya",
    "g2g",
    "greetings",
    "gtg",
    "hand",
    "hello",
    "hey",
    "hi",
    "later",
    "outtie",
    "ttfn",
    "ttyl",
    "wave",
    "yo",
    "you",
  ],
  "🤚": ["raised back of hand", "back", "backhand", "hand", "raised"],
  "🖐️": [
    "hand with fingers splayed",
    "finger",
    "fingers",
    "hand",
    "raised",
    "splayed",
    "stop",
  ],
  "✋": ["raised hand", "5", "five", "hand", "high", "raised", "stop"],
  "🖖": ["vulcan salute", "finger", "hand", "hands", "salute", "Vulcan"],
  "🫱": [
    "rightwards hand",
    "handshake",
    "hand",
    "hold",
    "reach",
    "right",
    "rightward",
    "rightwards",
    "shake",
  ],
  "🫲": [
    "leftwards hand",
    "handshake",
    "hand",
    "hold",
    "reach",
    "left",
    "rightward",
    "rightwards",
    "shake",
  ],
  "🫳": [
    "palm down hand",
    "dismiss",
    "down",
    "drop",
    "dropped",
    "hand",
    "palm",
    "pick",
    "shoo",
    "up",
  ],
  "🫴": [
    "palm up hand",
    "beckon",
    "catch",
    "come",
    "hand",
    "hold",
    "know",
    "life",
    "me",
    "offer",
    "palm",
    "tell",
  ],
  "🫷": [
    "leftwards pushing hand",
    "block",
    "five",
    "halt",
    "hand",
    "high",
    "hold",
    "leftward",
    "leftwards",
    "pause",
    "push",
    "pushing",
    "refuse",
    "slap",
    "stop",
    "wait",
  ],
  "🫸": [
    "rightwards pushing hand",
    "block",
    "five",
    "halt",
    "hand",
    "high",
    "hold",
    "rightward",
    "rightwards",
    "pause",
    "push",
    "pushing",
    "refuse",
    "slap",
    "stop",
    "wait",
  ],
  "👌": [
    "OK hand",
    "awesome",
    "bet",
    "dope",
    "fleek",
    "fosho",
    "got",
    "gotcha",
    "hand",
    "legit",
    "OK",
    "okay",
    "pinch",
    "rad",
    "sure",
    "sweet",
    "three",
  ],
  "🤌": [
    "pinched fingers",
    "fingers",
    "gesture",
    "hand",
    "hold",
    "huh",
    "interrogation",
    "patience",
    "pinched",
    "relax",
    "sarcastic",
    "ugh",
    "what",
    "zip",
  ],
  "🤏": [
    "pinching hand",
    "amount",
    "bit",
    "fingers",
    "hand",
    "little",
    "pinching",
    "small",
    "sort",
  ],
  "✌️": ["victory hand", "hand", "peace", "v", "victory"],
  "🤞": [
    "crossed fingers",
    "cross",
    "crossed",
    "finger",
    "fingers",
    "hand",
    "luck",
  ],
  "🫰": [
    "hand with index finger and thumb crossed",
    "<3",
    "crossed",
    "expensive",
    "finger",
    "hand",
    "heart",
    "index",
    "love",
    "money",
    "snap",
    "thumb",
  ],
  "🤟": [
    "love you gesture",
    "fingers",
    "gesture",
    "hand",
    "ILY",
    "love",
    "love-you",
    "three",
    "you",
  ],
  "🤘": [
    "sign of the horns",
    "finger",
    "hand",
    "horns",
    "rock-on",
    "rock on",
    "sign",
    "love-you",
    "three",
    "you",
  ],
  "🤙": ["call me hand", "call", "hand", "hang", "loose", "me", "Shaka"],
  "👈": [
    "backhand index pointing left",
    "backhand",
    "finger",
    "hand",
    "index",
    "left",
    "point",
    "pointing",
  ],
  "👉": [
    "backhand index pointing right",
    "backhand",
    "finger",
    "hand",
    "index",
    "right",
    "point",
    "pointing",
  ],
  "👆": [
    "backhand index pointing up",
    "backhand",
    "finger",
    "hand",
    "index",
    "up",
    "point",
    "pointing",
  ],
  "🖕": ["middle finger", "finger", "hand", "middle"],
  "👇": [
    "backhand index pointing down",
    "backhand",
    "finger",
    "hand",
    "index",
    "down",
    "point",
    "pointing",
  ],
  "🫵": [
    "index pointing at the viewer",
    "at",
    "finger",
    "hand",
    "index",
    "poke",
    "viewer",
    "you",
    "pointing",
  ],
  "👍": ["thumbs up", "+1", "good", "hand", "like", "thumb", "up", "yes"],
  "👎": [
    "thumbs down",
    "+1",
    "bad",
    "hand",
    "dislike",
    "thumb",
    "thumbs",
    "down",
    "no",
  ],
  "✊": [
    "raised fist",
    "clenched",
    "fist",
    "hand",
    "punch",
    "raised",
    "solidarity",
  ],
  "👊": [
    "oncoming fist",
    "absolutely",
    "agree",
    "boom",
    "bro",
    "bruh",
    "bump",
    "clenched",
    "correct",
    "fist",
    "hand",
    "knuckle",
    "oncoming",
    "pound",
    "punch",
    "rock",
    "ttyl",
  ],
  "🤛": ["left-facing fist", "fist", "left-facing", "leftwards"],
  "🤜": ["right-facing fist", "fist", "right-facing", "rightwards"],
  "👏": [
    "clapping hands",
    "applause",
    "approval",
    "awesome",
    "clap",
    "congrats",
    "congratulations",
    "excited",
    "good",
    "great",
    "hand",
    "homie",
    "job",
    "nice",
    "prayed",
    "well",
    "yay",
  ],
  "🙌": [
    "raising hands",
    "celebration",
    "gesture",
    "hand",
    "hands",
    "hooray",
    "praise",
    "raised",
    "good",
    "raising",
  ],
  "🫶": ["heart hands", "<3", "hands", "heart", "love", "you"],
  "👐": [
    "open hands",
    "cupped",
    "dua",
    "hands",
    "palms",
    "pray",
    "prayer",
    "together",
    "up",
    "wish",
    "cupped hands",
  ],
  "🤝": [
    "handshake",
    "agreement",
    "deal",
    "hand",
    "handshake",
    "meeting",
    "shake",
  ],
  "🙏": [
    "prayer",
    "appreciate",
    "ask",
    "beg",
    "blessed",
    "bow",
    "cmon",
    "five",
    "folded",
    "gesture",
    "hand",
    "high",
    "please",
    "pray",
    "thanks",
    "thx",
  ],
  "✍️": ["writing hand", "hand", "write", "writing"],
  "💅": [
    "nail polish",
    "bored",
    "care",
    "cosmetics",
    "done",
    "makeup",
    "manicure",
    "nail",
    "polish",
    "whatever",
  ],
  "🤳": ["selfie", "camera", "phone", "selfie"],
  "💪": [
    "flex biceps",
    "arm",
    "beast",
    "bench",
    "biceps",
    "bodybuilder",
    "bro",
    "curls",
    "flex",
    "gains",
    "gym",
    "jacked",
    "muscle",
    "press",
    "ripped",
    "strong",
    "weightlight",
  ],
  "🦾": [
    "mechanical arm",
    "arm",
    "accessibility",
    "mechanical",
    "prosthetic",
    "robot",
  ],
  "🦿": [
    "mechanical leg",
    "leg",
    "accessibility",
    "mechanical",
    "prosthetic",
    "robot",
  ],
  "🦵": ["leg", "bent", "foot", "kick", "knee", "limb"],
  "🦶": ["foot", "ankle", "foot", "feet", "kick", "stomp"],
  "👂": ["ear", "body", "hear", "hearing", "listen", "listening", "sound"],
  "🦻": [
    "ear with hearing aid",
    "accessibility",
    "aid",
    "ear",
    "hard",
    "hearing",
  ],
  "👃": ["nose", "body", "noses", "nosey", "odor", "smell", "smells"],
  "🧠": ["brain", "intelligent", "smart"],
  "🫀": [
    "anatomical heart",
    "anatomical",
    "beat",
    "cardiology",
    "heart",
    "heartbeat",
    "organ",
    "pulse",
    "real",
    "red",
  ],
  "🫁": [
    "lungs",
    "breath",
    "breathe",
    "exhalation",
    "inhalation",
    "lung",
    "lungs",
    "organ",
    "respiration",
  ],
  "🦷": ["tooth", "dentist", "pearly", "teeth", "tooth", "white"],
  "🦴": ["bone", "bones", "dog", "skeleton", "wishbone"],
  "👀": [
    "eyes",
    "body",
    "eye",
    "eyes",
    "face",
    "googly",
    "look",
    "looking",
    "omg",
    "peep",
    "see",
    "seeing",
  ],
  "👁️": ["eye", "1", "body", "one"],
  "👅": ["tongue", "body", "lick", "slurp"],
  "👄": ["mouth", "beauty", "body", "kiss", "kissing", "lips", "lipstick"],
  "🫦": [
    "biting lip",
    "anxious",
    "bite",
    "biting",
    "fear",
    "flirt",
    "flirting",
    "kiss",
    "lip",
    "lipstick",
    "nervous",
    "sexy",
    "uncomfortable",
    "worried",
    "worry",
  ],
  "👶": [
    "baby",
    "babies",
    "children",
    "goo",
    "infancy",
    "newborn",
    "pregnant",
    "young",
  ],
  "🧒": ["child", "bright-eyed", "grandchild", "kid", "young", "younger"],
  "👦": [
    "boy",
    "bright-eyed",
    "child",
    "grandson",
    "kid",
    "son",
    "young",
    "younger",
  ],
  "👧": [
    "girl",
    "bright-eyed",
    "child",
    "daughter",
    "girl",
    "granddaughter",
    "kid",
    "Virgo",
    "younger",
    "young",
    "zodiac",
  ],
  "🧑": ["person", "adult"],
  "👱": ["person with blond hair", "blond", "blond-haired", "human", "person"],
  "👨": ["man", "adult", "bro"],
  "🧔": [
    "person with beard",
    "beard",
    "bearded",
    "person",
    "whiskers",
    "bewhiskered",
  ],
  "🧔‍♂️": ["man with beard", "beard", "bearded", "man", "whiskers"],
  "🧔‍♀️": ["woman with beard", "beard", "bearded", "woman", "whiskers"],
  "👨‍🦰": ["man with red hair", "adult", "bro", "man", "red hair"],
  "👨‍🦱": ["man with curly hair", "adult", "bro", "curly hair", "man"],
  "👨‍🦳": ["man with white hair", "adult", "bro", "white hair", "man"],
  "👨‍🦲": ["bald man", "adult", "bro", "bald", "man"],
  "👩": ["woman", "adult", "lady", "woman"],
  "👩‍🦰": ["woman with red hair", "adult", "lady", "woman", "red hair"],
  "🧑‍🦰": ["person with red hair", "adult", "person", "red hair"],
  "👩‍🦱": ["woman with curly hair", "adult", "lady", "woman", "curly hair"],
  "🧑‍🦱": ["person with curly hair", "adult", "person", "curly hair"],
  "👩‍🦳": ["woman with white hair", "adult", "lady", "woman", "white hair"],
  "🧑‍🦳": ["person with white hair", "adult", "person", "white hair"],
  "👩‍🦲": ["bald woman", "adult", "bald", "lady", "woman"],
  "🧑‍🦲": ["bald person", "adult", "bald", "person"],
  "👱‍♀️": [
    "woman with blond hair",
    "blond",
    "blond-haired",
    "blonde",
    "hair",
    "woman",
  ],
  "👱‍♂️": [
    "man with blond hair",
    "blond",
    "blond-haired",
    "blonde",
    "hair",
    "man",
  ],
  "🧓": [
    "older person",
    "adult",
    "elderly",
    "grandparent",
    "old",
    "person",
    "wise",
  ],
  "👴": [
    "old man",
    "adult",
    "bald",
    "elderly",
    "gramps",
    "grandfather",
    "grandpa",
    "man",
    "old",
    "wise",
  ],
  "👵": [
    "old woman",
    "adult",
    "bald",
    "elderly",
    "granny",
    "grandmother",
    "grandma",
    "woman",
    "old",
    "wise",
    "lady",
  ],
  "🙍": [
    "person frowning",
    "annoyed",
    "disappointed",
    "disgruntled",
    "disturbed",
    "frown",
    "frowning",
    "frustrated",
    "gesture",
    "irritated",
    "person",
    "upset",
  ],
  "🙍‍♂️": [
    "man frowning",
    "annoyed",
    "disappointed",
    "disgruntled",
    "disturbed",
    "frown",
    "frowning",
    "frustrated",
    "gesture",
    "irritated",
    "man",
    "upset",
  ],
  "🙍‍♀️": [
    "woman frowning",
    "annoyed",
    "disappointed",
    "disgruntled",
    "disturbed",
    "frown",
    "frowning",
    "frustrated",
    "gesture",
    "irritated",
    "woman",
    "upset",
  ],
  "🙎": [
    "person pounting",
    "downtrodden",
    "disappointed",
    "frown",
    "grimace",
    "person",
    "pouting",
    "scowl",
    "sulk",
    "upset",
    "whine",
  ],
  "🙎‍♂️": [
    "man pounting",
    "downtrodden",
    "disappointed",
    "frown",
    "grimace",
    "man",
    "pouting",
    "scowl",
    "sulk",
    "upset",
    "whine",
  ],
  "🙎‍♀️": [
    "woman pounting",
    "downtrodden",
    "disappointed",
    "frown",
    "grimace",
    "woman",
    "pouting",
    "scowl",
    "sulk",
    "upset",
    "whine",
  ],
  "🙅": [
    "person gesturing NO",
    "forbidden",
    "gesture",
    "hand",
    "NO",
    "not",
    "person",
    "prohibit",
  ],
  "🙅‍♂️": [
    "man gesturing NO",
    "forbidden",
    "gesture",
    "hand",
    "NO",
    "not",
    "man",
    "prohibit",
  ],
  "🙅‍♀️": [
    "woman gesturing NO",
    "forbidden",
    "gesture",
    "hand",
    "NO",
    "not",
    "woman",
    "prohibit",
  ],
  "🙆": [
    "person gesturing OK",
    "exercise",
    "gesture",
    "gesturing",
    "hand",
    "OK",
    "omg",
    "person",
  ],
  "🙆‍♂️": [
    "man gesturing OK",
    "exercise",
    "gesture",
    "gesturing",
    "hand",
    "OK",
    "omg",
    "man",
  ],
  "🙆‍♀️": [
    "woman gesturing OK",
    "exercise",
    "gesture",
    "gesturing",
    "hand",
    "OK",
    "omg",
    "woman",
  ],
  "💁": [
    "person tipping hand",
    "fetch",
    "flick",
    "flip",
    "gossip",
    "hand",
    "person",
    "sarcasm",
    "sarcastic",
    "sassy",
    "seriously",
    "tipping",
    "whatever",
  ],
  "💁‍♂️": [
    "man tipping hand",
    "fetch",
    "flick",
    "flip",
    "gossip",
    "hand",
    "man",
    "sarcasm",
    "sarcastic",
    "sassy",
    "seriously",
    "tipping",
    "whatever",
  ],
  "💁‍♀️": [
    "woman tipping hand",
    "fetch",
    "flick",
    "flip",
    "gossip",
    "hand",
    "woman",
    "sarcasm",
    "sarcastic",
    "sassy",
    "seriously",
    "tipping",
    "whatever",
  ],
  "🙋": [
    "person raising hand",
    "gesture",
    "hand",
    "here",
    "know",
    "me",
    "person",
    "pick",
    "question",
    "raise",
    "raising",
  ],
  "🙋‍♂️": [
    "man raising hand",
    "gesture",
    "hand",
    "here",
    "know",
    "me",
    "man",
    "pick",
    "question",
    "raise",
    "raising",
  ],
  "🙋‍♀️": [
    "woman raising hand",
    "gesture",
    "hand",
    "here",
    "know",
    "me",
    "woman",
    "pick",
    "question",
    "raise",
    "raising",
  ],
  "🧏": [
    "deaf person",
    "accessibility",
    "deaf",
    "ear",
    "gesture",
    "hear",
    "person",
  ],
  "🧏‍♂️": ["deaf man", "accessibility", "deaf", "ear", "gesture", "hear", "man"],
  "🧏‍♀️": [
    "deaf woman",
    "accessibility",
    "deaf",
    "ear",
    "gesture",
    "hear",
    "woman",
  ],
  "🙇": [
    "person bowing",
    "apology",
    "ask",
    "beg",
    "bow",
    "bowing",
    "favor",
    "forgive",
    "gesture",
    "meditate",
    "meditation",
    "pity",
    "man",
    "person",
    "sorry",
  ],
  "🙇‍♂️": [
    "man bowing",
    "apology",
    "ask",
    "beg",
    "bow",
    "bowing",
    "favor",
    "forgive",
    "gesture",
    "meditate",
    "meditation",
    "man",
    "pity",
    "regret",
    "sorry",
  ],
  "🙇‍♀️": [
    "woman bowing",
    "apology",
    "ask",
    "beg",
    "bow",
    "bowing",
    "favor",
    "forgive",
    "gesture",
    "meditate",
    "meditation",
    "woman",
    "pity",
    "regret",
    "sorry",
  ],
  "🤦": [
    "person facepalming",
    "again",
    "bewilder",
    "disbelief",
    "exasperation",
    "facepalm",
    "no",
    "not",
    "oh",
    "omg",
    "person",
    "shock",
    "smh",
  ],
  "🤦‍♂️": [
    "man facepalming",
    "again",
    "bewilder",
    "disbelief",
    "exasperation",
    "facepalm",
    "no",
    "not",
    "oh",
    "omg",
    "man",
    "shock",
    "smh",
  ],
  "🤦‍♀️": [
    "woman facepalming",
    "again",
    "bewilder",
    "disbelief",
    "exasperation",
    "facepalm",
    "no",
    "not",
    "oh",
    "omg",
    "woman",
    "shock",
    "smh",
  ],
  "🤷": [
    "person shrugging",
    "doubt",
    "dunno",
    "guess",
    "idk",
    "ignorance",
    "indifference",
    "knows",
    "person",
    "maybe",
    "shrug",
    "shrugging",
    "whatever",
    "who",
  ],
  "🤷‍♂️": [
    "man shrugging",
    "doubt",
    "dunno",
    "guess",
    "idk",
    "ignorance",
    "indifference",
    "knows",
    "man",
    "maybe",
    "shrug",
    "shrugging",
    "whatever",
    "who",
  ],
  "🤷‍♀️": [
    "woman shrugging",
    "doubt",
    "dunno",
    "guess",
    "idk",
    "ignorance",
    "indifference",
    "knows",
    "woman",
    "maybe",
    "shrug",
    "shrugging",
    "whatever",
    "who",
  ],
  "🧑‍⚕️": [
    "health worker",
    "doctor",
    "health",
    "healthcare",
    "nurse",
    "therapist",
    "worker",
  ],
  "👨‍⚕️": [
    "man health worker",
    "doctor",
    "health",
    "healthcare",
    "nurse",
    "therapist",
    "worker",
    "man",
  ],
  "👩‍⚕️": [
    "woman health worker",
    "doctor",
    "health",
    "healthcare",
    "nurse",
    "therapist",
    "worker",
    "woman",
  ],
  "🧑‍🎓": ["student", "graduate"],
  "👨‍🎓": ["student", "graduate", "man"],
  "👩‍🎓": ["student", "graduate", "woman"],
  "🧑‍🏫": ["teacher", "instructor", "lecturer", "professor"],
  "👨‍🏫": ["man teacher", "instructor", "lecturer", "professor", "man"],
  "👩‍🏫": ["woman teacher", "instructor", "lecturer", "professor", "woman"],
  "🧑‍⚖️": ["judge", "justice", "law", "scales"],
  "👨‍⚖️": ["judge", "justice", "law", "scales", "man"],
  "👩‍⚖️": ["judge", "justice", "law", "scales", "woman"],
  "🧑‍🌾": ["farmer", "gardener", "rancher"],
  "👨‍🌾": ["farmer", "gardener", "rancher", "man"],
  "👩‍🌾": ["farmer", "gardener", "rancher", "woman"],
  "🧑‍🍳": ["cook", "chef"],
  "👨‍🍳": ["cook", "chef", "man"],
  "👩‍🍳": ["cook", "chef", "woman"],
  "🧑‍🔧": ["mechanic", "electrician", "plumber", "tradesperson"],
  "👨‍🔧": ["mechanic", "electrician", "plumber", "tradesperson", "man"],
  "👩‍🔧": ["mechanic", "electrician", "plumber", "tradesperson", "woman"],
  "🧑‍🏭": ["factory worker", "assembly", "factory", "industrial", "worker"],
  "👨‍🏭": [
    "factory worker",
    "assembly",
    "factory",
    "industrial",
    "worker",
    "man",
  ],
  "👩‍🏭": [
    "factory worker",
    "assembly",
    "factory",
    "industrial",
    "worker",
    "woman",
  ],
  "🧑‍💼": [
    "office worker",
    "architect",
    "business",
    "manager",
    "office",
    "white-collar",
    "worker",
  ],
  "👨‍💼": [
    "office worker",
    "architect",
    "business",
    "manager",
    "office",
    "white-collar",
    "worker",
    "man",
  ],
  "👩‍💼": [
    "office worker",
    "architect",
    "business",
    "manager",
    "office",
    "white-collar",
    "worker",
    "woman",
  ],
  "🧑‍🔬": [
    "scientist",
    "biologist",
    "chemist",
    "engineer",
    "mathematician",
    "physicist",
  ],
  "👨‍🔬": [
    "man scientist",
    "biologist",
    "chemist",
    "engineer",
    "mathematician",
    "physicist",
    "scientist",
    "man",
  ],
  "👩‍🔬": [
    "woman scientist",
    "biologist",
    "chemist",
    "engineer",
    "mathematician",
    "physicist",
    "scientist",
    "woman",
  ],
  "🧑‍💻": [
    "technologist",
    "coder",
    "computer",
    "developer",
    "inventor",
    "software",
    "technologist",
  ],
  "👨‍💻": [
    "man technologist",
    "coder",
    "computer",
    "developer",
    "inventor",
    "software",
    "technologist",
    "man",
  ],
  "👩‍💻": [
    "woman technologist",
    "coder",
    "computer",
    "developer",
    "inventor",
    "software",
    "technologist",
    "woman",
  ],
  "🧑‍🎤": [
    "singer",
    "actor",
    "entertainer",
    "rock",
    "rockstar",
    "singer",
    "star",
  ],
  "👨‍🎤": [
    "man singer",
    "actor",
    "entertainer",
    "rock",
    "rockstar",
    "singer",
    "star",
    "man",
  ],
  "👩‍🎤": [
    "woman singer",
    "actor",
    "entertainer",
    "rock",
    "rockstar",
    "singer",
    "star",
    "woman",
  ],
  "🧑‍🎨": ["artist", "palette"],
  "👨‍🎨": ["man artist", "palette", "man"],
  "👩‍🎨": ["woman artist", "palette", "woman"],
  "🧑‍✈️": ["pilot", "plane"],
  "👨‍✈️": ["man pilot", "plane", "man"],
  "👩‍✈️": ["woman pilot", "plane", "woman"],
  "🧑‍🚀": ["astronaut", "rocket", "space"],
  "👨‍🚀": ["man astronaut", "rocket", "space", "man"],
  "👩‍🚀": ["woman astronaut", "rocket", "space", "woman"],
  "🧑‍🚒": ["firefighter", "fire", "firetruck"],
  "👨‍🚒": ["man firefighter", "fire", "firetruck", "man"],
  "👩‍🚒": ["woman firefighter", "fire", "firetruck", "woman"],
  "👮": [
    "policy officer",
    "apprehend",
    "arrest",
    "citation",
    "cop",
    "law",
    "officer",
    "over",
    "police",
    "pulled",
    "undercover",
  ],
  "👮‍♂️": [
    "man policy officer",
    "apprehend",
    "arrest",
    "citation",
    "cop",
    "law",
    "officer",
    "over",
    "man",
    "police",
    "pulled",
    "undercover",
  ],
  "👮‍♀️": [
    "woman policy officer",
    "apprehend",
    "arrest",
    "citation",
    "cop",
    "law",
    "officer",
    "over",
    "woman",
    "police",
    "pulled",
    "undercover",
  ],
  "🕵️": ["detective", "sleuth", "spy"],
  "🕵️‍♂️": ["man detective", "sleuth", "spy", "man"],
  "🕵️‍♀️": ["woman detective", "sleuth", "spy", "woman"],
  "💂": ["guard", "buckingham", "helmet", "london", "palace"],
  "💂‍♂️": ["man guard", "buckingham", "helmet", "london", "palace", "man"],
  "💂‍♀️": ["woman guard", "buckingham", "helmet", "london", "palace", "woman"],
  "🥷": [
    "ninja",
    "assassin",
    "fight",
    "fighter",
    "hidden",
    "person",
    "secret",
    "skills",
    "sly",
    "soldier",
    "stealth",
    "war",
  ],
  "👷": [
    "construction worker",
    "build",
    "construction",
    "fix",
    "hardhat",
    "hat",
    "person",
    "rebuild",
    "remodel",
    "repair",
    "work",
    "worker",
  ],
  "👷‍♂️": [
    "man construction worker",
    "build",
    "construction",
    "fix",
    "hardhat",
    "hat",
    "man",
    "rebuild",
    "remodel",
    "repair",
    "work",
    "worker",
  ],
  "👷‍♂️": [
    "woman construction worker",
    "build",
    "construction",
    "fix",
    "hardhat",
    "hat",
    "woman",
    "rebuild",
    "remodel",
    "repair",
    "work",
    "worker",
  ],
  "🫅": [
    "person with crown",
    "crown",
    "monarch",
    "noble",
    "person",
    "regal",
    "royal",
    "royalty",
  ],
  "🤴": [
    "prince",
    "crown",
    "monarch",
    "noble",
    "regal",
    "royal",
    "royalty",
    "king",
  ],
  "👸": [
    "princess",
    "crown",
    "monarch",
    "noble",
    "regal",
    "royal",
    "royalty",
    "queen",
  ],
  "👳": ["person wearing turban", "person", "turban", "wearing"],
  "👳‍♂️": ["man wearing turban", "turban", "wearing"],
  "👳‍♀️": ["woman wearing turban", "woman", "turban", "wearing"],
  "👲": [
    "person with skullcap",
    "cap",
    "Chinese",
    "gua",
    "guapi",
    "hat",
    "mao",
    "person",
    "pi",
    "skullcap",
  ],
  "🧕": [
    "woman with headscarf",
    "bandana",
    "head",
    "headscarf",
    "hijab",
    "kerchief",
    "mantilla",
    "tichel",
    "woman",
    "head kerchief",
  ],
  "🤵": ["person in tuxedo", "formal", "person", "tuxedo", "wedding"],
  "🤵‍♂️": ["man in tuxedo", "formal", "man", "tuxedo", "wedding"],
  "🤵‍♀️": ["woman in tuxedo", "formal", "woman", "tuxedo", "wedding"],
  "👰": ["person with veil", "veil", "person", "wedding"],
  "👰‍♂️": ["man with veil", "veil", "man", "wedding"],
  "👰‍♀️": ["woman with veil", "veil", "woman", "wedding"],
  "🤰": ["pregnant woman", "pregnant", "woman"],
  "🫄": [
    "pregnant person",
    "belly",
    "bloated",
    "full",
    "overeat",
    "person",
    "pregnant",
    "stuffed",
  ],
  "🫃": [
    "pregnant man",
    "belly",
    "bloated",
    "full",
    "overeat",
    "man",
    "pregnant",
    "stuffed",
  ],
  "🤱": [
    "breast feeding",
    "baby",
    "breast",
    "breast-feeding",
    "feeding",
    "mom",
    "mother",
    "nursing",
    "woman",
  ],
  "👩‍🍼": [
    "woman feeding baby",
    "baby",
    "feeding",
    "feed",
    "mom",
    "mother",
    "nursing",
    "nanny",
    "newborn",
    "woman",
  ],
  "👨‍🍼": [
    "man feeding baby",
    "baby",
    "feeding",
    "feed",
    "dad",
    "father",
    "nursing",
    "newborn",
    "man",
  ],
  "🧑‍🍼": [
    "person feeding baby",
    "baby",
    "feeding",
    "feed",
    "nanny",
    "parent",
    "nursing",
    "newborn",
    "newborn",
  ],
  "👼": [
    "baby angel",
    "angel",
    "baby",
    "church",
    "face",
    "fairy",
    "fairytale",
    "fantasy",
    "tale",
  ],
  "🎅": [
    "Santa Claus",
    "celebration",
    "Christmas",
    "claus",
    "fairy",
    "fantasy",
    "father",
    "holiday",
    "merry",
    "santa",
    "tale",
    "xmas",
  ],
  "🤶": [
    "Mrs. Claus",
    "celebration",
    "Christmas",
    "claus",
    "fairy",
    "Mrs",
    "fantasy",
    "mother",
    "holiday",
    "merry",
    "santa",
    "tale",
    "xmas",
  ],
  "🧑‍🎄": [
    "Mx Claus",
    "celebration",
    "Christmas",
    "claus",
    "fairy",
    "Mx",
    "fantasy",
    "mother",
    "holiday",
    "merry",
    "santa",
    "tale",
    "xmas",
  ],
  "🦸": ["superhero", "good", "hero", "superpower"],
  "🦸‍♂️": ["man superhero", "good", "hero", "superpower", "man"],
  "🦸‍♀️": ["woman superhero", "good", "hero", "superpower", "woman"],
  "🦹": [
    "supervillain",
    "bad",
    "criminal",
    "evil",
    "superpower",
    "supervillain",
    "villain",
  ],
  "🦹‍♂️": [
    "man supervillain",
    "bad",
    "man",
    "criminal",
    "evil",
    "superpower",
    "supervillain",
    "villain",
  ],
  "🦹‍♀️": [
    "woman supervillain",
    "bad",
    "woman",
    "criminal",
    "evil",
    "superpower",
    "supervillain",
    "villain",
  ],
  "🧙": [
    "mage",
    "fantasy",
    "magic",
    "play",
    "sorcerer",
    "sorceress",
    "sorcery",
    "spell",
    "summon",
    "witch",
    "wizard",
  ],
  "🧙‍♂️": [
    "man mage",
    "fantasy",
    "man",
    "magic",
    "play",
    "sorcerer",
    "sorceress",
    "sorcery",
    "spell",
    "summon",
    "witch",
    "wizard",
  ],
  "🧙‍♀️": [
    "woman mage",
    "fantasy",
    "woman",
    "magic",
    "play",
    "sorcerer",
    "sorceress",
    "sorcery",
    "spell",
    "summon",
    "witch",
    "wizard",
  ],
  "🧚": [
    "fairy",
    "fairytale",
    "fantasy",
    "myth",
    "person",
    "pixie",
    "tale",
    "wings",
  ],
  "🧚‍♂️": [
    "man fairy",
    "fairytale",
    "fantasy",
    "myth",
    "man",
    "Puck",
    "Oberon",
    "pixie",
    "tale",
    "wings",
  ],
  "🧚‍♀️": [
    "woman fairy",
    "fairytale",
    "fantasy",
    "myth",
    "woman",
    "Titania",
    "pixie",
    "tale",
    "wings",
  ],
  "🧛": [
    "vampire",
    "blood",
    "Dracula",
    "fangs",
    "halloween",
    "scary",
    "supernatural",
    "teeth",
    "undead",
    "vampire",
  ],
  "🧛‍♂️": [
    "man vampire",
    "blood",
    "Dracula",
    "fangs",
    "halloween",
    "scary",
    "supernatural",
    "teeth",
    "undead",
    "vampire",
    "man",
  ],
  "🧛‍♀️": [
    "woman vampire",
    "blood",
    "Dracula",
    "fangs",
    "halloween",
    "scary",
    "supernatural",
    "teeth",
    "undead",
    "vampire",
    "woman",
  ],
  "🧜": [
    "merperson",
    "creature",
    "fairytale",
    "folklore",
    "merperson",
    "ocean",
    "sea",
    "siren",
    "trident",
    "mermaid",
    "merman",
  ],
  "🧜‍♂️": [
    "merman",
    "creature",
    "fairytale",
    "folklore",
    "merperson",
    "ocean",
    "sea",
    "siren",
    "trident",
    "mermaid",
    "Triton",
    "Neptune",
    "Poseidon",
  ],
  "🧜‍♀️": [
    "mermaid",
    "creature",
    "fairytale",
    "folklore",
    "merwoman",
    "ocean",
    "sea",
    "siren",
    "trident",
    "mermaid",
  ],
  "🧝": [
    "elf",
    "elves",
    "enchantment",
    "fantasy",
    "folklore",
    "magic",
    "LOTR",
    "magical",
    "myth",
  ],
  "🧝‍♂️": [
    "man elf",
    "man",
    "elves",
    "enchantment",
    "fantasy",
    "folklore",
    "magic",
    "LOTR",
    "magical",
    "myth",
  ],
  "🧝‍♀️": [
    "woman elf",
    "woman",
    "elves",
    "enchantment",
    "fantasy",
    "folklore",
    "magic",
    "LOTR",
    "magical",
    "myth",
  ],
  "🧞": ["genie", "dijnn", "fantasy", "jinn", "lamp", "myth", "rub", "wishes"],
  "🧞‍♂️": [
    "man genie",
    "dijnn",
    "fantasy",
    "jinn",
    "lamp",
    "myth",
    "rub",
    "wishes",
  ],
  "🧞‍♀️": [
    "woman genie",
    "dijnn",
    "fantasy",
    "jinn",
    "lamp",
    "myth",
    "rub",
    "wishes",
  ],
  "🧟": [
    "zombie",
    "apocalypse",
    "dead",
    "halloween",
    "horror",
    "scary",
    "undead",
    "walking",
  ],
  "🧟‍♂️": [
    "man zombie",
    "apocalypse",
    "dead",
    "halloween",
    "horror",
    "scary",
    "undead",
    "walking",
  ],
  "🧟‍♀️": [
    "woman zombie",
    "apocalypse",
    "dead",
    "halloween",
    "horror",
    "scary",
    "undead",
    "walking",
  ],
  "🧌": ["troll", "fairy", "fantasy", "monster", "tale", "trolling"],
  "💆": [
    "person getting massage",
    "face",
    "getting",
    "headache",
    "massage",
    "person",
    "relax",
    "relaxing",
    "salon",
    "soothe",
    "spa",
    "tension",
    "therapy",
    "treatment",
  ],
  "💆‍♂️": [
    "man getting massage",
    "face",
    "getting",
    "headache",
    "massage",
    "person",
    "relax",
    "relaxing",
    "salon",
    "soothe",
    "spa",
    "tension",
    "therapy",
    "treatment",
  ],
  "💆‍♀️": [
    "woman getting massage",
    "face",
    "getting",
    "headache",
    "massage",
    "person",
    "relax",
    "relaxing",
    "salon",
    "soothe",
    "spa",
    "tension",
    "therapy",
    "treatment",
  ],
  "💇": [
    "person getting haircut",
    "barber",
    "beauty",
    "chop",
    "cosmetology",
    "cut",
    "groom",
    "hair",
    "salhairon",
    "haircut",
    "parlor",
    "person",
    "shears",
    "style",
  ],
  "💇‍♂️": [
    "man getting haircut",
    "barber",
    "beauty",
    "chop",
    "cosmetology",
    "cut",
    "groom",
    "hair",
    "salhairon",
    "haircut",
    "parlor",
    "person",
    "shears",
    "style",
  ],
  "💇‍♀️": [
    "woman getting haircut",
    "barber",
    "beauty",
    "chop",
    "cosmetology",
    "cut",
    "groom",
    "hair",
    "salhairon",
    "haircut",
    "parlor",
    "person",
    "shears",
    "style",
  ],
  "🚶": [
    "person walking",
    "amble",
    "gait",
    "hike",
    "pace",
    "pedestrian",
    "stride",
    "stroll",
    "walk",
    "walking",
  ],
  "🚶‍♂️": [
    "man walking",
    "amble",
    "gait",
    "hike",
    "pace",
    "pedestrian",
    "stride",
    "stroll",
    "walk",
    "walking",
  ],
  "🚶‍♀️": [
    "woman walking",
    "amble",
    "gait",
    "hike",
    "pace",
    "pedestrian",
    "stride",
    "stroll",
    "walk",
    "walking",
  ],
  "🧍": ["person standing", "person", "stand", "standing"],
  "🧍‍♂️": ["man standing", "man", "stand", "standing"],
  "🧍‍♀️": ["woman standing", "woman", "stand", "standing"],
  "🧎": ["person kneeling", "kneel", "kneeling", "knees"],
  "🧎‍♂️": ["man kneeling", "kneel", "kneeling", "knees"],
  "🧎‍♀️": ["woman kneeling", "kneel", "kneeling", "knees"],
  "🧑‍🦯": [
    "person with cane",
    "accessibility",
    "blind",
    "cane",
    "person",
    "probing",
    "white",
  ],
  "👨‍🦯": [
    "man with cane",
    "accessibility",
    "blind",
    "cane",
    "person",
    "probing",
    "white",
  ],
  "👩‍🦯": [
    "woman with cane",
    "accessibility",
    "blind",
    "cane",
    "person",
    "probing",
    "white",
  ],
  "🧑‍🦼": [
    "person in motorized wheelchair",
    "accessibility",
    "motorized",
    "person",
    "wheelchair",
  ],
  "👨‍🦼": [
    "man in motorized wheelchair",
    "accessibility",
    "motorized",
    "man",
    "wheelchair",
  ],
  "👩‍🦼": [
    "woman in motorized wheelchair",
    "accessibility",
    "motorized",
    "woman",
    "wheelchair",
  ],
  "🧑‍🦽": [
    "person in manual wheelchair",
    "accessibility",
    "manual",
    "person",
    "wheelchair",
  ],
  "👨‍🦽": [
    "man in manual wheelchair",
    "accessibility",
    "manual",
    "man",
    "wheelchair",
  ],
  "👩‍🦽": [
    "woman in manual wheelchair",
    "accessibility",
    "manual",
    "woman",
    "wheelchair",
  ],
  "🏃": [
    "person running",
    "fast",
    "hurry",
    "marathon",
    "move",
    "person",
    "quick",
    "race",
    "racing",
    "run",
    "rush",
    "speed",
  ],
};

const keywordToEmojis = Object.entries(emojiKeywords).reduce(
  (acc, [emoji, keywords]) => {
    keywords.forEach((keyword) => {
      if (!acc[keyword]) {
        acc[keyword] = [];
      }
      acc[keyword].push(emoji);
    });
    return acc;
  },
  {}
);

const EmojiPickerModal = ({ onEmojiClick, onClose }) => {
  const [recentEmojis, setRecentEmojis] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const storedRecent = localStorage.getItem("recentEmojis");
    if (storedRecent) {
      setRecentEmojis(JSON.parse(storedRecent));
    }

    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleEmojiClick = (emoji) => {
    if (typeof onEmojiClick === "function") {
      onEmojiClick(emoji);
    }
    const updatedRecent = [
      emoji,
      ...recentEmojis.filter((e) => e !== emoji),
    ].slice(0, 13);
    setRecentEmojis(updatedRecent);
    localStorage.setItem("recentEmojis", JSON.stringify(updatedRecent));
    onClose();
  };

  const filteredEmojis = useMemo(() => {
    if (!searchTerm) return allEmojis;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return allEmojis.filter(
      (emoji) =>
        emoji.includes(lowerSearchTerm) ||
        (emojiKeywords[emoji] &&
          emojiKeywords[emoji].some((keyword) =>
            keyword.includes(lowerSearchTerm)
          )) ||
        (keywordToEmojis[lowerSearchTerm] &&
          keywordToEmojis[lowerSearchTerm].includes(emoji))
    );
  }, [searchTerm]);

  const renderEmojiSection = (title, emojis) => (
    <div className={classes.emojiSection} key={title}>
      <h3 className={classes.sectionTitle}>{title}</h3>
      <div className={classes.emojiGrid}>
        {emojis.map((emoji, index) => (
          <button
            key={index}
            className={classes.emojiButton}
            onClick={() => handleEmojiClick(emoji)}
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            <div className={classes.searchWrapper}>
              <svg
                class={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>

              <input
                type="text"
                placeholder="Search emojis..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={classes.searchInput}
              />
            </div>
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <div className={classes.emojiPickerContent}>
          {!searchTerm && (
            <>
              {recentEmojis.length > 0 &&
                renderEmojiSection("Recents", recentEmojis)}
              {Object.entries(emojiCategories).map(([category, emojis]) =>
                renderEmojiSection(category, emojis)
              )}
            </>
          )}
          {searchTerm && renderEmojiSection("Results", filteredEmojis)}
        </div>
      </div>
    </div>
  );
};

export default EmojiPickerModal;
