import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export async function generateStartupUrlSlug(startupName) {
  const baseSlug = startupName.toLowerCase().replace(/\s+/g, "");
  let slug = baseSlug;
  let counter = 1;

  while (true) {
    // Check across all users' startups
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    let slugExists = false;

    for (const userDoc of usersSnapshot.docs) {
      const startupsRef = collection(userDoc.ref, "startups");
      const q = query(startupsRef, where("startupUrlSlug", "==", slug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        slugExists = true;
        break;
      }
    }

    if (!slugExists) {
      return slug;
    }

    counter++;
    slug = `${baseSlug}${counter}`;
  }
}

export async function updateStartupUrlSlug(userId, startupId, startupName) {
  const startupRef = doc(firestore, `users/${userId}/startups/${startupId}`);
  const newSlug = await generateStartupUrlSlug(startupName);
  await updateDoc(startupRef, { startupUrlSlug: newSlug });
  return newSlug;
}
