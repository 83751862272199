import React, { createContext, useState, useContext, useEffect } from "react";
import { firestore, auth } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);

  // Inside ChatProvider component
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      console.log("ChatContext: Auth state changed", user?.uid);
      if (user) {
        const chatsRef = collection(firestore, "chats");
        const q = query(
          chatsRef,
          where("participants", "array-contains", user.uid),
          orderBy("lastMessageTime", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const unreadCount = snapshot.docs.reduce((count, doc) => {
            const chatData = doc.data();
            const userUnreadCount = chatData.unreadCount?.[user.uid] || 0;
            console.log("Chat", doc.id, "unread:", userUnreadCount);
            return count + (userUnreadCount > 0 ? 1 : 0);
          }, 0);

          console.log("Setting unreadChatsCount to:", unreadCount);
          setUnreadChatsCount(unreadCount);
        });

        return () => unsubscribe();
      } else {
        setUnreadChatsCount(0);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Add this console.log right before the return statement
  console.log("ChatContext about to provide value:", { unreadChatsCount });
  return (
    <ChatContext.Provider value={{ unreadChatsCount }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
