import React, { useState, useRef, useEffect } from "react";

import classes from "./InterestsDropdown.module.css";

const InterestsDropdown = ({
  label,
  selectedInterests,
  onInterestsChange,
  onInputChange,
  disabled,
  placeholder,
  customClassName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(
      selectedInterests.map((interest) => interest.name).join(", ")
    );
  }, [selectedInterests]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const handleInputBlur = () => {
    const interests = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    onInterestsChange(interests.map((name) => ({ name, id: name })));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  return (
    <div className={classes.customDropdown}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownContainer}>
        <textarea
          ref={inputRef}
          className={`${classes.dropdownHeader} ${classes.textArea} ${
            disabled ? classes.disabled : ""
          } ${customClassName}`} // Add this line
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InterestsDropdown;
