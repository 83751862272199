import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export async function generateCommunityUrlSlug(communityName) {
  const baseSlug = communityName.toLowerCase().replace(/\s+/g, "");
  let slug = baseSlug;
  let counter = 1;

  while (true) {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);
    let slugExists = false;

    for (const userDoc of usersSnapshot.docs) {
      const communitiesRef = collection(userDoc.ref, "communities");
      const q = query(communitiesRef, where("communityUrlSlug", "==", slug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        slugExists = true;
        break;
      }
    }

    if (!slugExists) {
      return slug;
    }

    counter++;
    slug = `${baseSlug}${counter}`;
  }
}

export async function updateCommunityUrlSlug(
  userId,
  communityId,
  communityName
) {
  const communityRef = doc(
    firestore,
    `users/${userId}/communities/${communityId}`
  );
  const newSlug = await generateCommunityUrlSlug(communityName);
  await updateDoc(communityRef, { communityUrlSlug: newSlug });
  return newSlug;
}
