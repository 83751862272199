import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Events.module.css";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import eventImage from "../../../assets/images/startupImage.jpg";
import InterestsDropdown from "../../../components/Dropdowns/InterestsDropdown";
import TagsDropdown from "../../../components/Dropdowns/TagsDropdown";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import EventTypeDropdown from "../../../components/Dropdowns/EventTypeDropdown";

import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);
const index = searchClient.initIndex("events_index");

function Events() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [eventType, setEventType] = useState("All"); // Default to "All" instead of "Extended"

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isInterestsMenuOpen, setIsInterestsMenuOpen] = useState(false);
  const [interests, setInterests] = useState([]);
  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [country, setCountry] = useState({ object: null, inputValue: "" });
  const [city, setCity] = useState({ object: null, inputValue: "" });
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);

  const [hasSearched, setHasSearched] = useState(false);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error loading countries:", error);
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      console.error("Error loading cities:", error);
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  const handleTypeChange = (newType) => {
    setEventType(newType);
    setIsChanged(true);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const checkIfAnyInputHasValue = () => {
    return (
      nameInput.trim() !== "" ||
      tags.length > 0 ||
      !!selectedCountry ||
      !!selectedCity ||
      eventType !== "All"
    );
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const handleInterestsChange = (newInterests) => {
    setInterests(newInterests);
    setIsChanged(true);
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setIsChanged(true);
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(value.trim() !== "");
  };

  const handleEventClick = (event) => {
    navigate(`/event/${event.eventUrlSlug}`);
  };

  const handleInputChange = (inputValue, type) => {
    const currentValues = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    const newValues = currentValues.map((name) => ({ name, id: name }));

    if (type === "tags") {
      setTags(newValues);
    } else if (type === "interests") {
      setInterests(newValues);
    }

    setIsChanged(true);
  };

  const fetchInitialResults = useCallback(async () => {
    if (!currentUser) return;

    setIsInitialLoading(true);
    try {
      let events = [];
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);

      for (const userDoc of usersSnapshot.docs) {
        const eventsRef = collection(firestore, `users/${userDoc.id}/events`);
        const eventsSnapshot = await getDocs(eventsRef);

        const userEvents = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ownerId: userDoc.id,
          ...doc.data(),
        }));

        events = [...events, ...userEvents];
      }

      // Sort events by creation date or start date
      events.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate);
      });

      setInitialResults(events);
    } catch (error) {
      console.error("Error fetching initial results:", error);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const scoreMatch = (
    event,
    searchTags,
    searchCountry,
    searchCity,
    eventType
  ) => {
    let matches = 0;
    const nameParts = nameInput.toLowerCase().trim().split(" ");

    // Name matching
    if (nameInput) {
      if (event.eventName.toLowerCase().includes(nameInput.toLowerCase())) {
        matches++;
      }
    }

    // Tags matching
    const eventTags = (event.tags || []).map((t) => t.toLowerCase().trim());
    searchTags.forEach((tag) => {
      if (eventTags.some((et) => et.includes(tag.name.toLowerCase().trim()))) {
        matches++;
      }
    });

    // Location matching (only for in-person events)
    if (!event.isOnline) {
      if (
        searchCountry &&
        event.country.toLowerCase() === searchCountry.name.toLowerCase()
      ) {
        matches++;
      }
      if (
        searchCity &&
        event.city.toLowerCase() === searchCity.name.toLowerCase()
      ) {
        matches++;
      }
    }

    // Event type matching
    if (eventType !== "All") {
      if (
        (eventType === "Online" && event.isOnline) ||
        (eventType === "In Person" && !event.isOnline)
      ) {
        matches++;
      }
    }

    return matches;
  };

  const handleSearch = useCallback(async () => {
    if (!isChanged) return;
    setIsSearching(true);
    setHasSearched(true);

    try {
      const searchParams = {
        hitsPerPage: 1000,
      };

      const filterConditions = [];

      // Name search
      if (nameInput) {
        searchParams.query = nameInput;
      }

      // Event type filter
      if (eventType !== "All") {
        filterConditions.push(`isOnline:${eventType === "Online"}`);
      }

      // Tags filter
      if (tags.length > 0) {
        const tagFilters = tags.map((t) => `tags:"${t.name}"`).join(" OR ");
        filterConditions.push(`(${tagFilters})`);
      }

      // Location filters (only if not searching for online events)
      if (eventType !== "Online") {
        if (selectedCountry) {
          filterConditions.push(`country:"${selectedCountry.name}"`);
        }
        if (selectedCity) {
          filterConditions.push(`city:"${selectedCity.name}"`);
        }
      }

      if (filterConditions.length > 0) {
        searchParams.filters = filterConditions.join(" AND ");
      }

      const { hits } = await index.search(
        searchParams.query || "",
        searchParams
      );

      // Add match scoring
      const scoredResults = hits.map((event) => ({
        ...event,
        matches: scoreMatch(
          event,
          tags,
          selectedCountry,
          selectedCity,
          eventType
        ),
      }));

      // Sort by matches and then by date
      const sortedResults = scoredResults.sort((a, b) => {
        if (b.matches !== a.matches) {
          return b.matches - a.matches;
        }
        return new Date(b.startDate) - new Date(a.startDate);
      });

      setSearchResults(sortedResults);
    } catch (error) {
      console.error("Error performing search:", error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [nameInput, tags, selectedCountry, selectedCity, eventType, isChanged]);

  useEffect(() => {
    if (currentUser && initialResults.length === 0) {
      fetchInitialResults();
    }
  }, [currentUser, fetchInitialResults, initialResults.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser({ uid: user.uid });
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const shouldShowMatchTally = () => {
    // Only show tally if we have searched AND have search criteria beyond just Type or Name
    return (
      hasSearched &&
      !isSearching &&
      (tags.length > 0 ||
        selectedCountry ||
        selectedCity ||
        eventType !== "All")
    );
  };

  const handleRefresh = () => {
    setNameInput("");
    setInterests([]);
    setTags([]);
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setEventType("All");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    fetchInitialResults();
  };

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage} />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Events
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Ex: Tech Conference 2024"
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {showFilters && (
                <>
                  <div className={classes.filters}>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Type</div>
                      <div>
                        <EventTypeDropdown
                          selectedType={eventType}
                          onTypeChange={handleTypeChange}
                        />
                      </div>
                    </div>
                    {eventType !== "Online" && (
                      <div className={classes.bottom}>
                        <div htmlFor="country" className={classes.filterLabel}>
                          Country
                        </div>
                        <CustomDropdown
                          options={countries}
                          selectedValue={selectedCountry}
                          onChange={handleCountryChange}
                          inputValue={countryInputValue}
                          setInputValue={setCountryInputValue}
                          disabled={loading}
                          placeholder="Ex: United Kingdom"
                        />
                        <div
                          htmlFor="city"
                          className={`${classes.filterLabel} ${
                            !selectedCountry || loading ? classes.disabled : ""
                          }`}
                        >
                          City
                        </div>
                        <CustomDropdown
                          options={cities}
                          selectedValue={selectedCity}
                          onChange={handleCityChange}
                          inputValue={cityInputValue}
                          setInputValue={setCityInputValue}
                          disabled={!selectedCountry || loading}
                          placeholder="Ex: London"
                        />
                      </div>
                    )}
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Tags</div>
                      <TagsDropdown
                        selectedTags={tags}
                        onTagsChange={handleTagsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "tags")
                        }
                        disabled={loading}
                        placeholder="Ex: Technology, Networking"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          className={classes.infoIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Tags must be separated with commas</p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <button
                type="button"
                className={`${classes.button} ${
                  isSearching ? classes.loading : ""
                }`}
                disabled={!isChanged || isSearching}
                onClick={() => {
                  console.log("Search button clicked");
                  handleSearch();
                }}
              >
                {isSearching ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  <>Search</>
                )}
              </button>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((event) => (
                <div
                  key={event.objectID || event.id}
                  className={classes.profileCard}
                  onClick={() => handleEventClick(event)}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img src={event.eventImage || eventImage} alt="" />
                    </div>
                    {shouldShowMatchTally() && hasSearched && !isSearching && (
                      <div className={classes.matchTally}>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.matchCount}>
                          {event.matches}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={classes.profileTitle}>
                    <span className={classes.spacing}>{event.eventName}</span>
                    <span className={classes.connectionType}>
                      • {event.isOnline ? "Online" : "In Person"}
                    </span>
                  </div>
                  <div>
                    {event.tagline && (
                      <div className={classes.bio}>{event.tagline}</div>
                    )}
                    <div className={classes.location}>
                      {event.isOnline
                        ? "Online Event"
                        : `${event.city}, ${event.country}`}
                    </div>
                    {event.link && (
                      <div className={classes.link}>
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {event.linkText || event.link}
                        </a>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                    <div className={classes.data}>
                      <span className={classes.numbers}>
                        {event.attendeeCount || 0}{" "}
                        {event.attendeeCount === 1 ? "Attendee" : "Attendees"}
                      </span>
                    </div>
                    <button
                      className={classes.viewProfileButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEventClick(event);
                      }}
                    >
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      View
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : (
            initialResults.map((event) => (
              <div
                key={event.id}
                className={classes.profileCard}
                onClick={() => handleEventClick(event)}
              >
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={event.eventImage || eventImage} alt="" />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  <span className={classes.spacing}>{event.eventName}</span>
                  <span className={classes.connectionType}>
                    • {event.isOnline ? "Online" : "In Person"}
                  </span>
                </div>
                <div>
                  {event.tagline && (
                    <div className={classes.bio}>{event.tagline}</div>
                  )}
                  <div className={classes.location}>
                    {event.isOnline
                      ? "Online Event"
                      : `${event.city}, ${event.country}`}
                  </div>
                  {event.link && (
                    <div className={classes.link}>
                      <a
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {event.linkText || event.link}
                      </a>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangeRed"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.data}>
                    <span className={classes.numbers}>
                      {event.attendeeCount || 0}{" "}
                      {event.attendeeCount === 1 ? "Attendee" : "Attendees"}
                    </span>
                  </div>
                  <button
                    className={classes.viewProfileButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEventClick(event);
                    }}
                  >
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    View
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Events;
