import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import classes from "./AddAttendeeModal.module.css"; // Reuse the same CSS module

const AddChatModal = ({ onClose, currentUser }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalConnections, setTotalConnections] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  // Fetch connections that aren't already in chats
  useEffect(() => {
    const fetchAvailableConnections = async () => {
      try {
        setLoading(true);

        // Get all user's connections first
        const connectionsRef = collection(
          firestore,
          `users/${currentUser.uid}/connections`
        );
        const q = query(connectionsRef, where("status", "==", "Connected"));
        const querySnapshot = await getDocs(q);

        // Get all chats
        const chatsRef = collection(firestore, "chats");
        const chatsQuery = query(
          chatsRef,
          where("participants", "array-contains", currentUser.uid)
        );
        const chatsSnapshot = await getDocs(chatsQuery);

        // Get active chat participants (only from chats that haven't been deleted)
        const activeChatUsers = new Set();
        chatsSnapshot.docs.forEach((chatDoc) => {
          const chatData = chatDoc.data();
          // Only consider chats that haven't been deleted by the current user
          if (!chatData.deletedBy?.includes(currentUser.uid)) {
            const otherUser = chatData.participants.find(
              (id) => id !== currentUser.uid
            );
            if (otherUser) {
              activeChatUsers.add(otherUser);
            }
          }
        });

        // Get user data for all connections that aren't in active chats
        const connectionsData = await Promise.all(
          querySnapshot.docs
            .filter((doc) => !activeChatUsers.has(doc.id))
            .map(async (connectionDoc) => {
              const userDoc = await getDoc(
                doc(firestore, "users", connectionDoc.id)
              );

              if (userDoc.exists()) {
                const userData = userDoc.data();
                return {
                  id: connectionDoc.id,
                  firstName: userData.firstName || "",
                  lastName: userData.lastName || "",
                  profileImage: userData.profileImage || defaultImage,
                  bio: userData.bio || "",
                  urlSlug: userData.urlSlug || "",
                  link: userData.link || null,
                  linkText: userData.linkText || null,
                  connectionType: "Direct",
                  connectedAt:
                    connectionDoc.data().connectedAt?.toDate() || new Date(0),
                };
              }
              return null;
            })
        );

        const filteredConnections = connectionsData.filter(Boolean);
        setConnections(filteredConnections);
        setTotalConnections(filteredConnections.length);
      } catch (error) {
        console.error("Error fetching connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableConnections();
  }, [currentUser.uid]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserClick = (connection) => {
    navigate("/chats", {
      state: {
        otherUser: {
          id: connection.id,
          name: `${connection.firstName} ${connection.lastName}`,
          image: connection.profileImage,
          bio: connection.bio || "",
          connectionType: connection.connectionType,
        },
      },
    });
    onClose();
  };

  const filteredConnections = connections.filter((connection) => {
    const fullName =
      `${connection.firstName} ${connection.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Start New Chat
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredConnections.length > 0 ? (
              filteredConnections.map((connection) => (
                <div
                  key={connection.id}
                  className={classes.connectionItem}
                  onClick={() => handleUserClick(connection)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={connection.profileImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {connection.connectionType}
                        </span>
                      </div>
                    </h3>
                    {connection.bio && (
                      <p className={classes.bio}>{connection.bio}</p>
                    )}
                    {connection.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={connection.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {connection.linkText || connection.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}>
                No available connections found
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddChatModal;
