import React, { useEffect, useState, useMemo, useCallback } from "react";
import { isEventClosed } from "../../../utils/timeUtils";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import greyStartupImage from "../../../assets/icons/greyStartupImage.jpg";
import greyCommunityImage from "../../../assets/icons/greyCommunityImage.jpg";
import greyEventImage from "../../../assets/icons/greyEventImage.jpg";
import greyPostImage from "../../../assets/icons/greyPostImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import communityImage from "../../../assets/icons/communityImage.jpg";
import eventImage from "../../../assets/icons/eventImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Profile.module.css";
import StartupButtons from "../../../components/Buttons/StartupButtons";
import CommunityButtons from "../../../components/Buttons/CommunityButtons";
import EventButtons from "../../../components/Buttons/EventButtons";
import ProfileModal from "../../../components/Modals/ProfileModal";
import IntroModal from "../../../components/Modals/IntroModal";
import SkillsModal from "../../../components/Modals/SkillsModal";
import { firestore, auth } from "../../../firebase";
import RepostsModal from "../../../components/Modals/RepostsModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import EditCommunityModal from "../../../components/Modals/EditCommunityModal";
import AddStartupModal from "../../../components/Modals/AddStartupModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import EditStartupsModal from "../../../components/Modals/EditStartupsModal";
import FollowModal from "../../../components/Modals/FollowModal";
import AddEventModal from "../../../components/Modals/AddEventModal";
import AddCommunityModal from "../../../components/Modals/AddCommunityModal";
import {
  getDoc,
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  limit,
  updateDoc,
  writeBatch,
  increment,
  serverTimestamp,
  Timestamp,
  where,
} from "firebase/firestore";
import EditEventModal from "../../../components/Modals/EditEventModal";
import { onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/icons/profileImage.jpg";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import InterestsModal from "../../../components/Modals/InterestsModal";
import AddExperienceModal from "../../../components/Modals/AddExperienceModal";
import EditExperienceModal from "../../../components/Modals/EditExperienceModal";
import EditSingleExperienceModal from "../../../components/Modals/EditSingleExperienceModal";
import AddEducationModal from "../../../components/Modals/AddEducationModal";
import EditEducationModal from "../../../components/Modals/EditEducationModal";
import EditSingleEducationModal from "../../../components/Modals/EditSingleEducationModal";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { convertUrlsToLinks } from "../../../utils/textUtils";

function Profile() {
  const [hoverStates, setHoverStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [loadingEventType, setLoadingEventType] = useState(false);
  const [loadingStartupType, setLoadingStartupType] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    intro: "",
    link: "",
    linkText: "",
    skills: [],
    interests: [],
    experiences: [],
    education: [],
    profileImage: "",
  });
  const [displayedEventCount, setDisplayedEventCount] = useState(3);
  const [loadingCommunityType, setLoadingCommunityType] = useState(false);
  const [startupSearchTerm, setStartupSearchTerm] = useState("");
  const [startupType, setStartupType] = useState("Owner");
  const [displayedStartups, setDisplayedStartups] = useState(3);
  const [startupCounts, setStartupCounts] = useState({
    Owner: 0,
    TeamMember: 0,
    Supported: 0,
    Following: 0,
  });
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);

  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const [communityType, setCommunityType] = useState("Owner");
  const [communityCounts, setCommunityCounts] = useState({
    Owner: 0,
    Moderator: 0,
    Member: 0,
  });
  const [eventSearchTerm, setEventSearchTerm] = useState("");
  const [eventData, setEventData] = useState({});
  const [eventType, setEventType] = useState("Owner");
  const [eventCounts, setEventCounts] = useState({
    Owner: 0,
    Attending: 0,
    Attended: 0,
    Organising: 0, // Add this line
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditConnectionsModalOpen, setIsEditConnectionsModalOpen] =
    useState(false);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [displayedCommunities, setDisplayedCommunities] = useState(3);

  const [
    editConnectionsModalScrollPosition,
    setEditConnectionsModalScrollPosition,
  ] = useState(0);
  const [connections, setConnections] = useState([]);
  const [displayedConnections, setDisplayedConnections] = useState(3);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [isLoadingStartups, setIsLoadingStartups] = useState(true);
  const [isLoadingConnections, setIsLoadingConnections] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [showAddCommunityModal, setShowAddCommunityModal] = useState(false);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(true);
  const [addCommunityModalScrollPosition, setAddCommunityModalScrollPosition] =
    useState(0);
  const [showEditCommunityModal, setShowEditCommunityModal] = useState(false);
  const [isEditCommunityModalOpen, setIsEditCommunityModalOpen] =
    useState(false);
  const [
    editCommunityModalScrollPosition,
    setEditCommunityModalScrollPosition,
  ] = useState(0);
  const [showAllCommunities, setShowAllCommunities] = useState(false);

  const [addStartupModalScrollPosition, setAddStartupModalScrollPosition] =
    useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [isAddStartupModalOpen, setIsAddStartupModalOpen] = useState(false);
  const [showEditStartupsModal, setShowEditStartupsModal] = useState(false);
  const [isEditStartupsModalOpen, setIsEditStartupsModalOpen] = useState(false);
  const [editStartupsModalScrollPosition, setEditStartupsModalScrollPosition] =
    useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [startups, setStartups] = useState([]);
  const [loadingStartups, setLoadingStartups] = useState(false);
  const [showAddStartupModal, setShowAddStartupModal] = useState(false);
  const [expandedTexts, setExpandedTexts] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showEditSingleExperienceModal, setShowEditSingleExperienceModal] =
    useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [addEventModalScrollPosition, setAddEventModalScrollPosition] =
    useState(0);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showEditEventsModal, setShowEditEventsModal] = useState(false);
  const [isEditEventsModalOpen, setIsEditEventsModalOpen] = useState(false);
  const [editEventsModalScrollPosition, setEditEventsModalScrollPosition] =
    useState(0);

  const [selectedExperience, setSelectedExperience] = useState(null);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showEditSingleEducationModal, setShowEditSingleEducationModal] =
    useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [displayedReactions, setDisplayedReactions] = useState(3);

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  const [connectionsCount, setConnectionsCount] = useState(0);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [activityType, setActivityType] = useState("Posts");

  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const [visibleSkills, setVisibleSkills] = useState(9);
  const [visibleInterests, setVisibleInterests] = useState(9);
  const [debugExperiences, setDebugExperiences] = useState([]);

  const toggleAddCommunityModal = (success = false) => {
    if (!showAddCommunityModal) {
      setAddCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addCommunityModalScrollPosition);
      }, 0);
    }
    setShowAddCommunityModal(!showAddCommunityModal);
  };

  const handleCommunityTypeChange = (type) => {
    setLoadingCommunityType(true);
    setCommunityType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingCommunityType(false);
    }, 300); // Adjust timing as needed
  };

  const handleCommunityAdded = (newCommunity) => {
    const communityWithTimestamp = {
      ...newCommunity,
      createdAt:
        newCommunity.createdAt instanceof Date
          ? Timestamp.fromDate(newCommunity.createdAt)
          : newCommunity.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the communities state and community counts
    setCommunities((prevCommunities) => [
      communityWithTimestamp,
      ...prevCommunities,
    ]);
    setCommunityCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  // Update the add event modal toggle function
  const toggleAddEventModal = (success = false) => {
    if (!showAddEventModal) {
      setAddEventModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEventModalScrollPosition);
      }, 0);
    }
    setShowAddEventModal(!showAddEventModal);
    setIsAddEventModalOpen(!isAddEventModalOpen);
  };

  const toggleShowAllCommunities = () => {
    setShowAllCommunities(true);
  };

  const handleEventTypeChange = (type) => {
    setLoadingEventType(true);
    setEventType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingEventType(false);
    }, 300); // Adjust timing as needed
  };

  const handleStartupTypeChange = (type) => {
    setLoadingStartupType(true);
    setStartupType(type);
    // Use a small timeout to simulate loading and prevent flickering
    setTimeout(() => {
      setLoadingStartupType(false);
    }, 300); // Adjust timing as needed
  };

  const toggleEditCommunityModal = () => {
    if (!showEditCommunityModal) {
      setEditCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editCommunityModalScrollPosition);
      }, 0);
    }
    setShowEditCommunityModal(!showEditCommunityModal);
    setIsEditCommunityModalOpen(!isEditCommunityModalOpen);
  };

  useEffect(() => {
    if (communities.length > 0) {
      const counts = {
        Owner: communities.filter((c) => c.membershipType === "owner").length,
        Moderator: communities.filter((c) => c.membershipType === "moderator")
          .length,
        Member: communities.filter((c) => c.membershipType === "member").length,
      };
      setCommunityCounts(counts);
    }
  }, [communities]);

  const filteredEvents = useMemo(() => {
    if (!events || !Array.isArray(events)) {
      return [];
    }

    const parseEventDateTime = (event) => {
      const [day, month, year] = event.endDate.split("/");
      const [hours, minutes] = event.endTime.match(/(\d+):(\d+)/).slice(1);
      return new Date(
        Date.UTC(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day),
          parseInt(hours.replace(/^0+/, "")),
          parseInt(minutes.replace(/^0+/, ""))
        )
      );
    };

    const isEventInPast = (event) => {
      if (!event.endDate || !event.endTime || !event.timezone) return false;
      return isEventClosed({
        endDate: event.endDate,
        endTime: event.endTime,
        timezone: event.timezone,
      });
    };

    const sortedEvents = events
      .filter((event) => {
        const eventClosed = isEventInPast(event);

        switch (eventType) {
          case "Owner":
            return event.membershipType === "owner";
          case "Organiser":
            return event.membershipType === "Organising";
          case "Attending":
            return event.membershipType === "attending" && !eventClosed;
          case "Attended":
            return event.membershipType === "attending" && eventClosed;
          default:
            return false;
        }
      })
      .sort((a, b) => {
        if (eventType === "Attending") {
          // For "Attending", show upcoming events by nearest date first
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          return aDate.getTime() - bDate.getTime();
        } else if (eventType === "Attended") {
          // For "Attended", show most recently ended first
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          return bDate.getTime() - aDate.getTime();
        } else if (["Owner", "Organiser"].includes(eventType)) {
          const now = new Date();
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          const aInFuture = aDate > now;
          const bInFuture = bDate > now;

          if (aInFuture && bInFuture) {
            // Both events are upcoming - sort by nearest first
            return aDate.getTime() - bDate.getTime();
          } else if (!aInFuture && !bInFuture) {
            // Both events are past - sort by most recent first
            return bDate.getTime() - aDate.getTime();
          } else {
            // One is future, one is past - future events come first
            return aInFuture ? -1 : 1;
          }
        }

        // Fallback sorting
        const aDate = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bDate = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bDate.getTime() - aDate.getTime();
      });

    // Debug logging
    console.log(
      "Sorted events:",
      sortedEvents.map((event) => ({
        name: event.eventName,
        endDate: event.endDate,
        endTime: event.endTime,
        isPast: isEventInPast(event),
      }))
    );

    return sortedEvents;
  }, [events, eventType]);

  const searchFilteredEvents = useMemo(() => {
    if (!filteredEvents) return [];

    return filteredEvents.filter((event) => {
      const matchesSearch = event.eventName
        ?.toLowerCase()
        .includes(eventSearchTerm.toLowerCase());
      return matchesSearch;
    });
  }, [filteredEvents, eventSearchTerm]);

  const handleShare = useCallback(() => {
    const profileUrl = `${window.location.origin}/${userData.urlSlug}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShareButtonText("Copied");
        setTimeout(() => {
          setShareButtonText("Share");
        }, 5000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  }, [userData.urlSlug]);

  useEffect(() => {
    if (startups.length > 0) {
      const counts = {
        Owner: startups.filter((s) => s.membershipType === "owner").length,
        TeamMember: startups.filter((s) => s.membershipType === "member")
          .length,
        Supported: startups.filter((s) => s.supported).length,
        Following: startups.filter((s) => s.following).length,
      };
      setStartupCounts(counts);
    }
  }, [startups]);

  const filteredCommunities = useMemo(() => {
    if (!communities) return [];

    const filtered = communities.filter((community) => {
      const matchesSearch = community.communityName
        ?.toLowerCase()
        .includes(communitySearchTerm.toLowerCase());

      switch (communityType) {
        case "Owner":
          return matchesSearch && community.membershipType === "owner";
        case "Moderator":
          return matchesSearch && community.membershipType === "moderator";
        case "Member":
          return matchesSearch && community.membershipType === "member";
        default:
          return false;
      }
    });

    return filtered.sort((a, b) => {
      if (communityType === "Owner") {
        // Sort owned communities by createdAt timestamp
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime; // Most recent first
      } else if (communityType === "Member") {
        // Sort member communities by joinedAt timestamp
        const aTime = a.joinedAt?.toDate?.() || new Date(a.joinedAt);
        const bTime = b.joinedAt?.toDate?.() || new Date(b.joinedAt);
        return bTime - aTime; // Most recent first
      } else if (communityType === "Moderator") {
        // Sort moderator communities by acceptedAt timestamp
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime; // Most recent first
      }
      return 0;
    });
  }, [communities, communityType, communitySearchTerm]);

  const handleCommunityUpdated = (updatedCommunities) => {
    // If we received a single community that's been deleted
    if (updatedCommunities.deleted) {
      setCommunities((prevCommunities) =>
        prevCommunities.filter(
          (community) => community.id !== updatedCommunities.id
        )
      );
      // Update the community counts
      setCommunityCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // If we received an array, use it directly
    if (Array.isArray(updatedCommunities)) {
      setCommunities(updatedCommunities);
    } else {
      // If we received a single community update, update it in the existing array
      setCommunities((prevCommunities) =>
        prevCommunities.map((community) =>
          community.id === updatedCommunities.id
            ? {
                ...community,
                ...updatedCommunities,
                membershipType: community.membershipType,
                createdAt: community.createdAt,
              }
            : community
        )
      );
    }
  };

  const toggleEditEventsModal = () => {
    if (!showEditEventsModal) {
      setEditEventsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEventsModalScrollPosition);
      }, 0);
    }
    setShowEditEventsModal(!showEditEventsModal);
    setIsEditEventsModalOpen(!isEditEventsModalOpen);
  };

  // Add this handler function with your other handlers
  const handleEventUpdate = async (updatedEvent) => {
    try {
      const batch = writeBatch(firestore);

      // Update main event document
      const eventRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}`
      );
      batch.update(eventRef, updatedEvent);

      // Get all attendees
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      // Update each attendee's eventsAttending record
      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${eventData.id}`
        );

        // Update the critical time-related fields in the attendee's record
        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          // Also update other important fields that might have changed
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      setEventData((prev) => ({
        ...prev,
        ...updatedEvent,
      }));
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const getOriginalPostRef = async (firestore, originalPosterId, postId) => {
    if (!originalPosterId || !postId) return null;
    try {
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            const startupData = startupSnap.data();
            return {
              ref: doc(
                firestore,
                `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
              ),
              startupData: {
                ...startupData,
                ownerId: userDoc.id,
                startupId: startupId,
                isStartupPost: true,
              },
            };
          }
        }
        return null;
      }

      // For user posts, check personal posts first
      const userPostRef = doc(
        firestore,
        `users/${originalPosterId}/posts/${postId}`
      );
      const userPostSnap = await getDoc(userPostRef);

      if (userPostSnap.exists()) {
        return {
          ref: userPostRef,
          startupData: null,
        };
      }

      // Then check startup posts
      const startupsRef = collection(
        firestore,
        `users/${originalPosterId}/startups`
      );
      const startupsSnapshot = await getDocs(startupsRef);

      for (const startupDoc of startupsSnapshot.docs) {
        const postRef = doc(
          firestore,
          `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
        );
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const startupData = startupDoc.data();
          return {
            ref: postRef,
            startupData: {
              ...startupData,
              ownerId: originalPosterId,
              startupId: startupDoc.id,
              isStartupPost: true,
            },
          };
        }
      }

      return null;
    } catch (error) {
      console.error("Error in getOriginalPostRef:", error);
      return null;
    }
  };

  const handleEventAdded = (newEvent) => {
    // Create an event object with membershipType for proper filtering
    const eventWithMembership = {
      ...newEvent,
      membershipType: "owner",
      ownerId: currentUser.uid,
    };

    // Update the events state with the new event at the beginning of the array
    setEvents((prevEvents) => [eventWithMembership, ...prevEvents]);

    // Update the event counts
    setEventCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  const toggleShowAllEvents = () => {
    setShowAllEvents(true);
  };

  // Add this section component with your other section components

  const fetchPosts = useCallback(async () => {
    if (!currentUser) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${currentUser.uid}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoadingPosts(false);
    }
  }, [currentUser]);

  const fetchReactions = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingReactions(true);
    try {
      const reactionsRef = collection(
        firestore,
        `users/${currentUser.uid}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsPromises = reactionsSnapshot.docs.map(
        async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            reactionData.originalPosterId,
            reactionData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: reactionData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost,
            reactedAt: reactionData.reactedAt?.toDate() || new Date(),
            emoji: reactionData.emoji,
          };
        }
      );

      const reactionsResults = await Promise.all(reactionsPromises);
      const validReactions = reactionsResults.filter(Boolean);
      setReactions(validReactions);
    } catch (error) {
      console.error("Error fetching reactions:", error);
      setReactions([]);
    } finally {
      setLoadingReactions(false);
    }
  }, [currentUser?.uid]);

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      if (!connection?.userData?.firstName || !connection?.userData?.lastName)
        return false;
      const fullName = `${connection.userData.firstName} ${connection.userData.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const fetchReposts = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${currentUser.uid}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsPromises = repostsSnapshot.docs.map(async (docSnapshot) => {
        const repostData = docSnapshot.data();
        const postInfo = await getOriginalPostRef(
          firestore,
          repostData.originalPosterId,
          repostData.postId
        );
        if (!postInfo?.ref) return null;

        const originalPostSnap = await getDoc(postInfo.ref);
        if (!originalPostSnap.exists()) return null;

        const originalPostData = originalPostSnap.data();
        const originalPost = {
          id: repostData.postId,
          ...originalPostData,
          postPreview: {
            mediaType: originalPostData.content?.type || null,
            mediaUrl: originalPostData.content?.url || null,
            text: originalPostData.text || "",
            thumbnail: originalPostData.content?.thumbnail || null,
          },
          reactions: originalPostData.reactions || {},
          reposts: originalPostData.reposts || {},
          commentCount: originalPostData.commentCount || 0,
        };

        if (postInfo.startupData) {
          originalPost.isStartupPost = true;
          originalPost.startupName = postInfo.startupData.startupName;
          originalPost.startupImage = postInfo.startupData.startupImage;
          originalPost.startupId = postInfo.startupData.startupId;
          originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
          originalPost.ownerId = postInfo.startupData.ownerId;
        }

        return {
          id: docSnapshot.id,
          ...repostData,
          originalPost,
          repostedAt: repostData.repostedAt?.toDate() || new Date(),
        };
      });

      const repostsResults = await Promise.all(repostsPromises);
      const validReposts = repostsResults.filter(Boolean);
      setReposts(validReposts);
    } catch (error) {
      console.error("Error fetching reposts:", error);
      setReposts([]);
    } finally {
      setLoadingReposts(false);
    }
  }, [currentUser?.uid]);

  const fetchComments = useCallback(async () => {
    if (!currentUser?.uid) return;

    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${currentUser.uid}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentsPromises = commentedPostsSnapshot.docs.map(
        async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const postInfo = await getOriginalPostRef(
            firestore,
            commentData.originalPosterId,
            commentData.postId
          );
          if (!postInfo?.ref) return null;

          const originalPostSnap = await getDoc(postInfo.ref);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();
          const originalPost = {
            id: commentData.postId,
            ...originalPostData,
            postPreview: {
              mediaType: originalPostData.content?.type || null,
              mediaUrl: originalPostData.content?.url || null,
              text: originalPostData.text || "",
              thumbnail: originalPostData.content?.thumbnail || null,
            },
            reactions: originalPostData.reactions || {},
            reposts: originalPostData.reposts || {},
            commentCount: originalPostData.commentCount || 0,
          };

          if (postInfo.startupData) {
            originalPost.isStartupPost = true;
            originalPost.startupName = postInfo.startupData.startupName;
            originalPost.startupImage = postInfo.startupData.startupImage;
            originalPost.startupId = postInfo.startupData.startupId;
            originalPost.startupUrlSlug = postInfo.startupData.startupUrlSlug;
            originalPost.ownerId = postInfo.startupData.ownerId;
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            originalPost,
            lastCommentedAt:
              commentData.lastCommentedAt?.toDate() || new Date(),
          };
        }
      );

      const commentsResults = await Promise.all(commentsPromises);
      const validComments = commentsResults.filter(Boolean);
      setCommentedPosts(validComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setCommentedPosts([]);
    } finally {
      setLoadingComments(false);
    }
  }, [currentUser?.uid]);

  const toggleAddStartupModal = (success = false) => {
    if (!showAddStartupModal) {
      setAddStartupModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addStartupModalScrollPosition);
      }, 0);
    }
    setShowAddStartupModal(!showAddStartupModal);
    setIsAddStartupModalOpen(!isAddStartupModalOpen);
  };

  const toggleFollowModal = () => {
    if (!showFollowModal) {
      setFollowModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, followModalScrollPosition);
      }, 0);
    }
    setShowFollowModal(!showFollowModal);
    setIsFollowModalOpen(!isFollowModalOpen);
  };

  // Add this toggle function with your other modal toggle functions
  const toggleEditStartupsModal = () => {
    if (!showEditStartupsModal) {
      setEditStartupsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editStartupsModalScrollPosition);
      }, 0);
    }
    setShowEditStartupsModal(!showEditStartupsModal);
    setIsEditStartupsModalOpen(!isEditStartupsModalOpen);
  };

  // Add this handler for when a single startup is selected for editing
  const openEditSingleStartupModal = (startup) => {
    setSelectedStartup(startup);
    // You'll implement the EditSingleStartupModal later
    // setShowEditSingleStartupModal(true);
    setShowEditStartupsModal(false);
  };

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    comments: 0,
  });

  // Add these functions after your other useCallback functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  // Update handleStartupAdded in Profile.js to properly integrate the new startup
  const handleStartupAdded = (newStartup) => {
    const startupWithTimestamp = {
      ...newStartup,
      createdAt:
        newStartup.createdAt instanceof Date
          ? Timestamp.fromDate(newStartup.createdAt)
          : newStartup.createdAt,
      membershipType: "owner", // Explicitly set the membership type
    };

    // Update both the startups state and startup counts
    setStartups((prevStartups) => [...prevStartups, startupWithTimestamp]);
    setStartupCounts((prevCounts) => ({
      ...prevCounts,
      Owner: prevCounts.Owner + 1,
    }));
  };

  // In Profile.js
  const handleStartupUpdated = (startupUpdate) => {
    // Check if this is a deletion
    if (startupUpdate.deleted) {
      setStartups((prevStartups) =>
        prevStartups.filter((startup) => startup.id !== startupUpdate.id)
      );
      // Update startup counts
      setStartupCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setStartups((prevStartups) => {
      return prevStartups.map((startup) =>
        startup.id === startupUpdate.id
          ? {
              ...startup,
              ...startupUpdate,
              membershipType: "owner",
            }
          : startup
      );
    });
  };

  const isMobile = () => {
    return window.innerWidth < 648;
  };

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [profileModalScrollPosition, setProfileModalScrollPosition] =
    useState(0);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [
    addExperienceModalScrollPosition,
    setAddExperienceModalScrollPosition,
  ] = useState(0);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [addEducationModalScrollPosition, setAddEducationModalScrollPosition] =
    useState(0);
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] = useState(false);
  const [
    editExperienceModalScrollPosition,
    setEditExperienceModalScrollPosition,
  ] = useState(0);
  const [isEditExperienceModalOpen, setIsEditExperienceModalOpen] =
    useState(false);
  const [
    editEducationModalScrollPosition,
    setEditEducationModalScrollPosition,
  ] = useState(0);
  const [isEditEducationModalOpen, setIsEditEducationModalOpen] =
    useState(false);

  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);

  const storage = getStorage();

  const navigate = useNavigate();

  const [showFullIntro, setShowFullIntro] = useState(false);

  const [visibleExperiences, setVisibleExperiences] = useState(3);
  const [visibleEducation, setVisibleEducation] = useState(3);

  const [reactions, setReactions] = useState([]);

  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleExpandText = useCallback((id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const MAX_LENGTH = 250; // Same as Intro text

  const renderDescription = (description, id) => {
    if (!description) return null;

    return (
      <>
        <p
          className={`${classes.role} ${
            expandedDescriptions[id] ? classes.expanded : ""
          }`}
          style={{
            whiteSpace: "pre-wrap",
            WebkitLineClamp: expandedDescriptions[id] ? "unset" : 3,
          }}
        >
          {description}
          {!expandedDescriptions[id] && description.split("\n").length > 3 && (
            <span
              className={classes.moreButton}
              onClick={() => toggleDescription(id)}
            >
              ...more
            </span>
          )}
        </p>
        {expandedDescriptions[id] && (
          <span
            className={classes.moreButton}
            onClick={() => toggleDescription(id)}
          >
            Show less
          </span>
        )}
      </>
    );
  };

  const renderIntroText = () => {
    const introText =
      userData.introHtml || "Describe who you are, and what you do.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  // Add this sorting function near other sorting-related code
  const sortedStartups = useMemo(() => {
    if (!startups) return [];
    return [...startups].sort((a, b) => {
      // Convert month strings to numbers for comparison
      const aMonth = monthsList.indexOf(a.monthFounded);
      const bMonth = monthsList.indexOf(b.monthFounded);

      // Create dates for comparison
      const aDate = new Date(a.yearFounded, aMonth);
      const bDate = new Date(b.yearFounded, bMonth);

      // Compare dates first
      if (aDate.getTime() !== bDate.getTime()) {
        return bDate.getTime() - aDate.getTime(); // Newest first
      }

      // If dates are the same, compare creation timestamps
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [startups]);

  const sortedExperiences = useMemo(() => {
    if (!userData.experiences) return [];
    return [...userData.experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [userData.experiences]);

  const handleEventUpdated = (eventUpdate) => {
    // Check if this is a deletion
    if (eventUpdate.deleted) {
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventUpdate.id)
      );
      // Update event counts
      setEventCounts((prevCounts) => ({
        ...prevCounts,
        Owner: prevCounts.Owner - 1,
      }));
      return;
    }

    // Handle normal updates
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === eventUpdate.id
          ? {
              ...event,
              ...eventUpdate,
              membershipType: "owner", // Ensure membership type is preserved
            }
          : event
      )
    );
  };

  const fetchActivityData = useCallback(async () => {
    if (!currentUser?.uid) return;

    const activityTypeMap = {
      Posts: fetchPosts,
      Reactions: fetchReactions,
      Comments: fetchComments,
      Reposts: fetchReposts,
    };

    const fetchFunction = activityTypeMap[activityType];
    if (fetchFunction) {
      await fetchFunction();
    }
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  useEffect(() => {
    if (!currentUser?.uid || !activityType) return;

    const fetchData = async () => {
      switch (activityType) {
        case "Posts":
          await fetchPosts();
          break;
        case "Reactions":
          await fetchReactions();
          break;
        case "Comments":
          await fetchComments();
          break;
        case "Reposts":
          await fetchReposts();
          break;
      }
    };

    fetchData();
  }, [
    currentUser?.uid,
    activityType,
    fetchPosts,
    fetchReactions,
    fetchComments,
    fetchReposts,
  ]);

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      const userConnectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${targetUserId}`
      );
      const userConnectionDoc = await getDoc(userConnectionRef);

      if (
        userConnectionDoc.exists() &&
        userConnectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      return "Extended";
    },
    []
  );

  const fetchConnections = useCallback(async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      // Only get connections with "Connected" status
      const connectionsQuery = query(
        connectionsRef,
        where("status", "==", "Connected")
      );
      const connectionsSnapshot = await getDocs(connectionsQuery);

      const connectionsData = await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          // Verify the connection still exists and is still "Connected"
          const connectionData = connectionDoc.data();
          if (connectionData.status !== "Connected") {
            return null;
          }

          const userDocRef = doc(firestore, "users", connectionDoc.id);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = await checkConnectionType(
              currentUser.uid,
              connectionDoc.id
            );

            // Also verify the reverse connection exists and is "Connected"
            const reverseConnectionRef = doc(
              firestore,
              `users/${connectionDoc.id}/connections/${currentUser.uid}`
            );
            const reverseConnectionDoc = await getDoc(reverseConnectionRef);

            if (
              !reverseConnectionDoc.exists() ||
              reverseConnectionDoc.data().status !== "Connected"
            ) {
              return null;
            }

            return {
              id: connectionDoc.id,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
              connectionType,
              connectedAt: connectionData.connectedAt?.toDate() || new Date(0),
            };
          }
          return null;
        })
      );

      // Filter out null values and sort by connectedAt timestamp
      const validConnections = connectionsData
        .filter(Boolean)
        .sort((a, b) => b.connectedAt - a.connectedAt);

      setConnections(validConnections);
    } catch (error) {
      console.error("Error fetching connections:", error);
    }
  }, [currentUser?.uid, checkConnectionType]);

  const handleConnectionRemoved = useCallback(() => {
    fetchConnections(); // Re-fetch connections after removal
    setConnectionsCount((prev) => prev - 1); // Update the count
  }, [fetchConnections]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const CommunitySection = ({ community }) => {
    const navigate = useNavigate();

    const handleCommunityClick = () => {
      if (community.communityUrlSlug) {
        navigate(`/community/${community.communityUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={community.id} className={classes.userContainer}>
        <img
          src={community.communityImage || communityImage}
          alt={community.communityName}
          className={classes.communityImage}
          onClick={handleCommunityClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = greyCommunityImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleCommunityClick}
              style={{ cursor: "pointer" }}
            >
              {community.communityName}
            </div>
            <span className={classes.userConnectionType}>• Community</span>
          </div>
          {community.tagline && (
            <div className={classes.userBio}>{community.tagline}</div>
          )}
          {community.link && (
            <a
              href={community.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{community.linkText || community.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const StartupSection = ({ startup }) => {
    const navigate = useNavigate();

    const handleStartupClick = () => {
      if (startup.startupUrlSlug) {
        navigate(`/startup/${startup.startupUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={startup.id} className={classes.userContainer}>
        <img
          src={startup.startupImage || startupImage}
          alt={startup.startupName}
          className={classes.startupImage}
          onClick={handleStartupClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = startupImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleStartupClick}
              style={{ cursor: "pointer" }}
            >
              {startup.startupName}
            </div>
            <span className={classes.userConnectionType}>• Startup</span>
          </div>
          {startup.bio && <div className={classes.userBio}>{startup.bio}</div>}
          {startup.link && (
            <a
              href={startup.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{startup.linkText || startup.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromOwnProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
      },
    });
  };

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
    if (currentUser?.uid) {
      const activityTypeMap = {
        Posts: fetchPosts,
        Reactions: fetchReactions,
        Comments: fetchComments,
        Reposts: fetchReposts,
      };

      const fetchFunction = activityTypeMap[selectedActivity];
      if (fetchFunction) {
        fetchFunction();
      }
    }
  };

  const filteredStartups = useMemo(() => {
    if (!startups) return [];

    // First, filter by search term and type
    const filtered = startups.filter((startup) => {
      const matchesSearch = startup.startupName
        ?.toLowerCase()
        .includes(startupSearchTerm.toLowerCase());

      switch (startupType) {
        case "Owner":
          return matchesSearch && startup.membershipType === "owner";
        case "Team Member":
          return matchesSearch && startup.membershipType === "member";
        case "Supported":
          return matchesSearch && startup.supported;
        case "Following":
          return matchesSearch && startup.following;
        default:
          return false;
      }
    });

    // For supported startups, deduplicate by keeping only the most recent donation
    if (startupType === "Supported") {
      const uniqueStartups = filtered.reduce((acc, current) => {
        const existing = acc.find((item) => item.id === current.id);

        if (!existing) {
          // If no existing entry, add current
          acc.push(current);
        } else {
          // If existing entry, keep the one with the most recent donation
          const currentDonationDate =
            current.supportedAt?.toDate?.() || new Date(current.supportedAt);
          const existingDonationDate =
            existing.supportedAt?.toDate?.() || new Date(existing.supportedAt);

          if (currentDonationDate > existingDonationDate) {
            // Replace existing with current if current donation is more recent
            const index = acc.findIndex((item) => item.id === current.id);
            acc[index] = current;
          }
        }

        return acc;
      }, []);

      // Sort deduplicated supported startups by donation date
      return uniqueStartups.sort((a, b) => {
        const aDonationDate =
          a.supportedAt?.toDate?.() || new Date(a.supportedAt);
        const bDonationDate =
          b.supportedAt?.toDate?.() || new Date(b.supportedAt);
        return bDonationDate - aDonationDate; // Most recent donations first
      });
    }

    // For other types, sort as before
    return filtered.sort((a, b) => {
      if (startupType === "Following") {
        const aTime = a.followedAt?.toDate?.() || new Date(a.followedAt);
        const bTime = b.followedAt?.toDate?.() || new Date(b.followedAt);
        return bTime - aTime;
      } else if (startupType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (startupType === "Team Member") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [startups, startupType, startupSearchTerm]);

  const handlePostClick = (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: false,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Reactions":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const handleShowAllComments = () => {
    navigate("/all-comments/me");
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  useEffect(() => {
    if (currentUser && activityType === "Posts") {
      fetchPosts();
    }
  }, [currentUser, activityType, fetchPosts]);

  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <div className={classes.postInteractionCount}>
        {commentCount > 0 && (
          <span
            className={classes.commentText}
            onClick={() => onCommentClick(postId)}
          >
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span
            className={classes.repost}
            onClick={() => onClick(postId, reposts)}
          >
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </div>
    );
  };

  const fetchConnectionsCount = useCallback(async (userId) => {
    const connectionsRef = collection(firestore, `users/${userId}/connections`);
    const connectionsSnapshot = await getDocs(connectionsRef);
    const connectedCount = connectionsSnapshot.docs.filter(
      (doc) => doc.data().status === "Connected"
    ).length;
    setConnectionsCount(connectedCount);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchConnections();
    }
  }, [currentUser, fetchConnections]); // Add fetchConnections as dependency since it's memoized

  useEffect(() => {
    if (currentUser) {
      fetchConnectionsCount(currentUser.uid);
    }
  }, [currentUser, fetchConnectionsCount]);

  const ReactionsSummary = ({ reactions, onClick }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div className={classes.reactionsSummary} onClick={onClick}>
        <div className={classes.reactionStack}>
          {topEmojis.reverse().map((emoji, index) => (
            <div
              key={index}
              className={classes.reactionEmoji}
              style={{ zIndex: topEmojis.length - index }}
            >
              {emoji}
            </div>
          ))}
        </div>
        <span className={classes.reactionCount}>{reactionCount}</span>
      </div>
    );
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const toggleProfileModal = (isSave = false) => {
    if (!showProfileModal) {
      setProfileModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, profileModalScrollPosition);
      }, 0);
    }
    setShowProfileModal(!showProfileModal);
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const toggleIntroModal = (isSave = false) => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const toggleSkillsModal = (isSave = false) => {
    if (!showSkillsModal) {
      // Opening the modal
      setSkillsModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, skillsModalScrollPosition);
      }, 0);
    }
    setShowSkillsModal(!showSkillsModal);
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleInterestsModal = (isSave = false) => {
    if (!showInterestsModal) {
      // Opening the modal
      setModalScrollPosition(window.scrollY);
    } else {
      // Closing the modal
      setTimeout(() => {
        window.scrollTo(0, modalScrollPosition);
      }, 0);
    }
    setShowInterestsModal(!showInterestsModal);
    setIsInterestsModalOpen(!isInterestsModalOpen);
  };

  const toggleAddExperienceModal = (isSave = false) => {
    if (!showAddExperienceModal) {
      setAddExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addExperienceModalScrollPosition);
      }, 0);
    }
    setShowAddExperienceModal(!showAddExperienceModal);
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);
  };

  const toggleEditExperienceModal = () => {
    if (!showEditExperienceModal) {
      setEditExperienceModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editExperienceModalScrollPosition);
      }, 0);
    }
    setShowEditExperienceModal(!showEditExperienceModal);
    setIsEditExperienceModalOpen(!isEditExperienceModalOpen);
    setShowEditSingleExperienceModal(false);
  };

  const toggleAddEducationModal = (isSave = false) => {
    if (!showAddEducationModal) {
      setAddEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addEducationModalScrollPosition);
      }, 0);
    }
    setShowAddEducationModal(!showAddEducationModal);
    setIsAddEducationModalOpen(!isAddEducationModalOpen);
  };

  const toggleEditEducationModal = () => {
    if (!showEditEducationModal) {
      setEditEducationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editEducationModalScrollPosition);
      }, 0);
    }
    setShowEditEducationModal(!showEditEducationModal);
    setIsEditEducationModalOpen(!isEditEducationModalOpen);
    setShowEditSingleEducationModal(false);
  };

  const openEditSingleEducationModal = (education) => {
    setSelectedEducation(education);
    setShowEditSingleEducationModal(true);
    setShowEditEducationModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? true : state))
      );
    }
  };

  const handleMouseLeave = (index) => {
    if (
      index !== 2 ||
      (userData.experiences && userData.experiences.length > 0)
    ) {
      setHoverStates((prevHoverStates) =>
        prevHoverStates.map((state, i) => (i === index ? false : state))
      );
    }
  };

  const handleProfileSave = (newUserData) => {
    setUserData((prevData) => {
      const updatedData = { ...prevData };

      Object.keys(newUserData).forEach((key) => {
        if (key !== "experiences" && key !== "education") {
          updatedData[key] = newUserData[key];
        }
      });

      if (newUserData.experiences) {
        updatedData.experiences = newUserData.experiences;
      }

      if (newUserData.education) {
        updatedData.education = newUserData.education;
      }

      return updatedData;
    });
  };

  const handleExperienceAdded = (newExperience) => {
    setUserData((prevData) => ({
      ...prevData,
      experiences: [...prevData.experiences, newExperience],
    }));
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      return (
        <div className={classes.mediaPreview}>
          <img src={thumbnailUrl} alt="Video preview" />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const handleEducationAdded = (newEducation) => {
    setUserData((prevData) => ({
      ...prevData,
      education: [...(prevData.education || []), newEducation],
    }));
  };

  const userImage = userData.profileImage || profileImage;

  const openEditSingleExperienceModal = (experience) => {
    setSelectedExperience(experience);
    setShowEditSingleExperienceModal(true);
    setShowEditExperienceModal(false);
  };

  const openConnectionsModal = () => {
    setConnectionsModalScrollPosition(window.scrollY);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  // Update the renderComments function to use the new data structure
  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t commented yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you comment on will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> commented on this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>You</span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                commentedPost.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postInteractions}>
                      {commentedPost.originalPost?.reactions &&
                        Object.keys(commentedPost.originalPost.reactions)
                          .length > 0 && (
                          <ReactionsSummary
                            reactions={commentedPost.originalPost.reactions}
                            onClick={() =>
                              handleReactionsClick(
                                commentedPost.originalPost.id,
                                commentedPost.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={commentedPost.originalPost?.id}
                          reposts={commentedPost.originalPost?.reposts}
                          commentCount={
                            commentedPost.originalPost?.commentCount
                          }
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(commentedPost.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
        {commentedPosts.length > 3 &&
          displayedComments < commentedPosts.length && (
            <div
              className={classes.showAllPosts}
              onClick={handleShowAllComments}
            >
              Show all comments
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </>
    );
  };

  const handleReactionClick = (reactionId, reactionEmoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: { fromUserProfile: false, reactionEmoji: reactionEmoji },
    });
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>You haven’t reacted yet</p>{" "}
              <p className={classes.defaultText}>
                Posts you react to will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}>
                  {" "}
                  reacted <span className={classes.textEmoji}>🐘</span> to this{" "}
                </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reacted{" "}
                        <span className={classes.textEmoji}>
                          {reaction.emoji}
                        </span>{" "}
                        to this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() =>
                        handleReactionClick(reaction.id, reaction.emoji)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {reaction.originalPost?.reactions &&
                      Object.keys(reaction.originalPost.reactions).length >
                        0 && (
                        <ReactionsSummary
                          reactions={reaction.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              reaction.originalPost.id,
                              reaction.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <div className={classes.rightInteractions}>
                      <PostInteractionCount
                        postId={reaction.originalPost?.id}
                        reposts={reaction.originalPost?.reposts}
                        commentCount={reaction.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(reaction.originalPost?.id)
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reactions.length > 3 && displayedReactions < reactions.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reactions/me")}
          >
            Show all reactions
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <div>
              <p className={classes.defaultTextBold}>
                You haven’t reposted yet
              </p>{" "}
              <p className={classes.defaultText}>
                Posts you share will be displayed here.
              </p>
              <div className={classes.noPostBorder}></div>
              <div className={classes.postHeader}>
                <span className={classes.postAuthor}>You</span>
                <span className={classes.postMessage}> reposted this </span>
                <span className={classes.dot}>•</span>
                <span>now</span>
              </div>
              <div className={classes.noPost}>
                <div className={classes.postContent}>
                  <img
                    src={greyPostImage}
                    alt="Default startup"
                    className={classes.noPostImage}
                  />
                  <p className={classes.noTextPreview}>Post</p>
                </div>
              </div>
              <div className={classes.noPostInteractions}>
                <div>100 reactions</div>
                <div className={classes.noRightInteractions}>
                  <div>50 comments</div>
                  <span className={classes.dot}>•</span>
                  <div>25 reposts</div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>You</span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {repost.originalPost?.reactions &&
                      Object.keys(repost.originalPost.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={repost.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              repost.originalPost.id,
                              repost.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <PostInteractionCount
                      postId={repost.originalPost?.id}
                      reposts={repost.originalPost?.reposts}
                      commentCount={repost.originalPost?.commentCount}
                      onClick={handleRepostsClick}
                      onCommentClick={() =>
                        handlePostClick(repost.originalPost?.id)
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate("/all-reposts/me")}
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const handleCloseTouch = (modalToggleFunction) => (event) => {
    event.preventDefault();
    modalToggleFunction();
  };

  useEffect(() => {
    if (currentUser) {
      if (activityType === "Posts") {
        fetchPosts();
      } else if (activityType === "Reposts") {
        fetchReposts();
      } else if (activityType === "Reactions") {
        fetchReactions();
      } else if (activityType === "Comments") {
        fetchComments();
      }
    }
  }, [
    currentUser,
    activityType,
    fetchPosts,
    fetchReposts,
    fetchReactions,
    fetchComments,
  ]);

  const fetchActivityCounts = useCallback(async () => {
    if (!currentUser) return;

    try {
      const userId = currentUser.uid;

      // Fetch posts count
      const postsRef = collection(firestore, `users/${userId}/posts`);
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      // Fetch reactions count
      const reactionsRef = collection(
        firestore,
        `users/${userId}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      // Fetch reposts count
      const repostsRef = collection(firestore, `users/${userId}/reposts`);
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      // Fetch comments count
      const commentsRef = collection(
        firestore,
        `users/${userId}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        posts: postsCount,
        reactions: reactionsCount,
        reposts: repostsCount,
        comments: commentsCount,
      });
    } catch (error) {
      console.error("Error fetching activity counts:", error);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchActivityCounts();
    }
  }, [currentUser, fetchActivityCounts]);

  const isActivityEmpty = () => {
    return (
      activityCounts.posts === 0 &&
      activityCounts.reactions === 0 &&
      activityCounts.comments === 0 &&
      activityCounts.reposts === 0
    );
  };

  const renderTruncatedText = useCallback(
    (text, id, htmlContent = null) => {
      if (!text) return null;

      const isExpanded = expandedTexts[id];

      if (text.length <= MAX_LENGTH) {
        return htmlContent ? (
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            onClick={handleLinkClick}
          />
        ) : (
          <p className={classes.description}>{text}</p>
        );
      }

      if (isExpanded) {
        return htmlContent ? (
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            onClick={handleLinkClick}
          />
        ) : (
          <p className={classes.description}>{text}</p>
        );
      }

      // Remove any trailing ellipsis before truncating
      const cleanText = text.replace(/\.{3,}$/, "").trim();
      const truncatedText = cleanText.slice(0, MAX_LENGTH).trim();

      return (
        <p className={classes.description}>
          {htmlContent ? (
            <span
              dangerouslySetInnerHTML={{
                __html: truncatedText,
              }}
              onClick={handleLinkClick}
            />
          ) : (
            truncatedText
          )}
          <span
            className={classes.moreButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleExpandText(id);
            }}
          >
            ...more
          </span>
        </p>
      );
    },
    [expandedTexts, toggleExpandText, handleLinkClick]
  );

  const fetchFollowingCount = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowingCount(userData.followingCount || 0);
      }
    } catch (error) {
      console.error("Error fetching following count:", error);
    }
  }, []);

  const fetchStartupData = async (userId) => {
    try {
      const batch = [];

      // Fetch owned startups
      const startupsSnapshot = await getDocs(
        collection(firestore, `users/${userId}/startups`)
      );
      const startupsData = startupsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        membershipType: "owner",
      }));
      batch.push(...startupsData);

      // Fetch team member startups
      const allUsersSnapshot = await getDocs(collection(firestore, "users"));
      const teamMemberPromises = allUsersSnapshot.docs.map(async (userDoc) => {
        const startupRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupDocs = await getDocs(startupRef);

        const startupTeamPromises = startupDocs.docs.map(async (startupDoc) => {
          const teamRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
          );
          const teamDoc = await getDoc(teamRef);

          if (teamDoc.exists() && teamDoc.data().status === "active") {
            return {
              id: startupDoc.id,
              ownerId: userDoc.id,
              ...startupDoc.data(),
              membershipType: "member",
              role: teamDoc.data().role,
              acceptedAt: teamDoc.data().acceptedAt, // Changed from joinedAt to acceptedAt
            };
          }
          return null;
        });

        const resolvedStartups = await Promise.all(startupTeamPromises);
        return resolvedStartups.filter((startup) => startup !== null);
      });

      // Fetch donated/supported startups
      const donationsRef = collection(firestore, `users/${userId}/donations`);
      const donationsSnapshot = await getDocs(donationsRef);
      const supportedPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donationData = donationDoc.data();
          if (donationData.status !== "completed") return null;

          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            return {
              id: startupDoc.id,
              ...startupDoc.data(),
              supported: true,
              supportedAt: donationData.createdAt,
              ownerId: donationData.startupOwnerId,
              donationAmount: donationData.amount,
              donationCurrency: donationData.currency,
            };
          }
          return null;
        }
      );

      // Fetch followed startups
      const followedStartupsRef = collection(
        firestore,
        `users/${userId}/startupsFollowing`
      );
      const followedSnapshot = await getDocs(followedStartupsRef);
      console.log("Number of followed startups:", followedSnapshot.docs.length);
      console.log(
        "Followed startups raw data:",
        followedSnapshot.docs.map((doc) => doc.data())
      );

      const followedPromises = followedSnapshot.docs.map(async (followDoc) => {
        console.log("Processing follow doc:", followDoc.id, followDoc.data());
        const startupRef = doc(
          firestore,
          `users/${followDoc.data().ownerId}/startups/${followDoc.id}`
        );
        const startupDoc = await getDoc(startupRef);
        console.log("Startup doc exists:", startupDoc.exists());

        if (startupDoc.exists()) {
          return {
            id: startupDoc.id,
            ...startupDoc.data(),
            following: true,
            followedAt: followDoc.data().followedAt,
            ownerId: followDoc.data().ownerId,
          };
        }
        return null;
      });

      const teamMemberStartups = (await Promise.all(teamMemberPromises)).flat();
      const supportedStartups = (await Promise.all(supportedPromises)).filter(
        Boolean
      );
      const followedStartups = (await Promise.all(followedPromises)).filter(
        Boolean
      );

      batch.push(
        ...teamMemberStartups,
        ...supportedStartups,
        ...followedStartups
      );

      const counts = {
        Owner: batch.filter((s) => s.membershipType === "owner").length,
        TeamMember: batch.filter((s) => s.membershipType === "member").length,
        Supported: batch.filter((s) => s.supported).length,
        Following: batch.filter((s) => s.following).length,
      };
      setStartupCounts(counts);

      setStartups(batch);
    } catch (error) {
      console.error("Error fetching startup data:", error);
    }
  };

  // Complete fetchAllData function
  const fetchAllData = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);
        setIsLoadingProfile(true);
        setIsLoadingActivity(true);
        setIsLoadingStartups(true);
        setIsLoadingConnections(true);
        setIsLoadingCommunities(true);
        setIsLoadingEvents(true);

        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFollowingCount(data.followingCount || 0);

          const [experiencesSnapshot, educationSnapshot] = await Promise.all([
            getDocs(collection(firestore, "users", userId, "experiences")),
            getDocs(collection(firestore, "users", userId, "education")),
          ]);

          const experiences = experiencesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            dateAdded: doc.data().dateAdded,
          }));

          const education = educationSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setUserData((prevData) => ({
            ...prevData,
            ...data,
            experiences: experiences || [],
            education: education || [],
          }));
          setIsLoadingProfile(false);

          try {
            await fetchStartupData(userId);
          } finally {
            setIsLoadingStartups(false);
          }

          try {
            await Promise.all([
              fetchPosts(),
              fetchComments(),
              fetchReactions(),
              fetchReposts(),
              fetchActivityCounts(),
            ]);
          } finally {
            setIsLoadingActivity(false);
          }

          try {
            await Promise.all([
              fetchConnectionsCount(userId),
              fetchFollowingCount(userId),
            ]);
          } finally {
            setIsLoadingConnections(false);
          }

          try {
            const userCommunitiesRef = collection(
              firestore,
              `users/${userId}/communities`
            );
            const memberOfRef = collection(
              firestore,
              `users/${userId}/communitiesMemberOf`
            );
            const moderatingRef = collection(
              firestore,
              `users/${userId}/communitiesModerating`
            );

            const [
              userCommunitiesSnapshot,
              memberOfSnapshot,
              moderatingSnapshot,
            ] = await Promise.all([
              getDocs(userCommunitiesRef),
              getDocs(memberOfRef),
              getDocs(moderatingRef),
            ]);

            const ownedCommunitiesData = userCommunitiesSnapshot.docs.map(
              (doc) => ({
                id: doc.id,
                ...doc.data(),
                membershipType: "owner",
                createdAt: doc.data().createdAt,
              })
            );

            // Fetch member communities
            const memberCommunitiesPromises = memberOfSnapshot.docs.map(
              async (memberDoc) => {
                const memberData = memberDoc.data();
                const usersRef = collection(firestore, "users");
                const usersSnapshot = await getDocs(usersRef);

                for (const userDoc of usersSnapshot.docs) {
                  const communityRef = doc(
                    firestore,
                    `users/${userDoc.id}/communities/${memberDoc.id}`
                  );
                  const communityDoc = await getDoc(communityRef);

                  if (communityDoc.exists()) {
                    return {
                      id: memberDoc.id,
                      ownerId: userDoc.id,
                      ...communityDoc.data(),
                      membershipType: "member",
                      joinedAt: memberData.joinedAt || memberData.createdAt,
                    };
                  }
                }
                return null;
              }
            );

            // Fetch moderator communities
            const moderatorCommunitiesPromises = moderatingSnapshot.docs.map(
              async (moderatorDoc) => {
                const moderatorData = moderatorDoc.data();
                const communityRef = doc(
                  firestore,
                  `users/${moderatorData.communityOwnerId}/communities/${moderatorDoc.id}`
                );
                const communityDoc = await getDoc(communityRef);

                if (communityDoc.exists()) {
                  return {
                    id: moderatorDoc.id,
                    ownerId: moderatorData.communityOwnerId,
                    ...communityDoc.data(),
                    membershipType: "moderator",
                    acceptedAt: moderatorData.acceptedAt,
                  };
                }
                return null;
              }
            );

            const [memberCommunitiesData, moderatorCommunitiesData] =
              await Promise.all([
                Promise.all(memberCommunitiesPromises),
                Promise.all(moderatorCommunitiesPromises),
              ]);

            const allCommunitiesData = [
              ...ownedCommunitiesData,
              ...memberCommunitiesData.filter(Boolean),
              ...moderatorCommunitiesData.filter(Boolean),
            ];

            setCommunities(allCommunitiesData);

            const communityCounts = {
              Owner: allCommunitiesData.filter(
                (c) => c.membershipType === "owner"
              ).length,
              Moderator: allCommunitiesData.filter(
                (c) => c.membershipType === "moderator"
              ).length,
              Member: allCommunitiesData.filter(
                (c) => c.membershipType === "member"
              ).length,
            };
            setCommunityCounts(communityCounts);
          } finally {
            setIsLoadingCommunities(false);
          }

          try {
            const ownedEventsRef = collection(
              firestore,
              `users/${userId}/events`
            );
            const eventsOrganisingRef = collection(
              firestore,
              `users/${userId}/eventsOrganising`
            );
            const attendingEventsRef = collection(
              firestore,
              `users/${userId}/eventsAttending`
            );

            const [
              ownedEventsSnapshot,
              eventsOrganisingSnapshot,
              attendingEventsSnapshot,
            ] = await Promise.all([
              getDocs(ownedEventsRef),
              getDocs(eventsOrganisingRef),
              getDocs(attendingEventsRef),
            ]);

            const ownedEvents = ownedEventsSnapshot.docs.map((docSnapshot) => ({
              id: docSnapshot.id,
              ...docSnapshot.data(),
              membershipType: "owner",
            }));

            console.log(
              "Organising events snapshot:",
              eventsOrganisingSnapshot.docs.length,
              "documents found"
            );

            const organiserEventsPromises = eventsOrganisingSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                if (!eventData.eventOwnerId) return null;

                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  const fullEventData = eventSnapshot.data();
                  // Create the event object BEFORE spreading the full event data
                  const organiserEvent = {
                    id: docSnapshot.id,
                    ...fullEventData,
                    membershipType: "Organising", // Make sure this exactly matches what we check for
                    eventOwnerId: eventData.eventOwnerId,
                    acceptedAt: eventData.acceptedAt,
                    // Force these fields again to ensure they're correct
                    membershipType: "Organising", // Set it again after spread to be sure
                    eventName: fullEventData.eventName,
                    eventImage: fullEventData.eventImage,
                    eventUrlSlug: fullEventData.eventUrlSlug,
                    tagline: fullEventData.tagline,
                    link: fullEventData.link,
                    linkText: fullEventData.linkText,
                    isOnline: fullEventData.isOnline,
                    city: fullEventData.city,
                    country: fullEventData.country,
                  };
                  return organiserEvent;
                }
                return null;
              }
            );

            const organiserEvents = (
              await Promise.all(organiserEventsPromises)
            ).filter(Boolean);
            console.log("Final organiser events:", organiserEvents);

            const attendingEventsPromises = attendingEventsSnapshot.docs.map(
              async (docSnapshot) => {
                const eventData = docSnapshot.data();
                const eventRef = doc(
                  firestore,
                  `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
                );
                const eventSnapshot = await getDoc(eventRef);

                if (eventSnapshot.exists()) {
                  return {
                    id: docSnapshot.id,
                    ...eventSnapshot.data(),
                    membershipType: "attending",
                    endDate: eventData.endDate,
                    endTime: eventData.endTime,
                    timezone: eventData.timezone,
                  };
                }
                return null;
              }
            );

            const attendingEvents = (
              await Promise.all(attendingEventsPromises)
            ).filter(Boolean);
            const allEvents = [
              ...ownedEvents,
              ...organiserEvents,
              ...attendingEvents,
            ];

            const counts = {
              Owner: ownedEvents.length,
              Organising: organiserEvents.length, // Make sure this is being set correctly
              Attending: attendingEvents.filter(
                (event) =>
                  !isEventClosed({
                    endDate: event.endDate,
                    endTime: event.endTime,
                    timezone: event.timezone,
                  })
              ).length,
              Attended: attendingEvents.filter((event) =>
                isEventClosed({
                  endDate: event.endDate,
                  endTime: event.endTime,
                  timezone: event.timezone,
                })
              ).length,
            };

            setEvents(allEvents);
            setEventCounts(counts);
          } finally {
            setIsLoadingEvents(false);
          }
        }

        try {
          await fetchConnections();
        } finally {
          setIsLoadingConnections(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      fetchPosts,
      fetchComments,
      fetchReactions,
      fetchReposts,
      fetchConnectionsCount,
      fetchActivityCounts,
      fetchFollowingCount,
      fetchConnections,
    ]
  );

  // Create a combined loading state
  const isPageLoading = useMemo(() => {
    return (
      isLoading ||
      isLoadingProfile ||
      isLoadingActivity ||
      isLoadingStartups ||
      isLoadingConnections
    );
  }, [
    isLoading,
    isLoadingProfile,
    isLoadingActivity,
    isLoadingStartups,
    isLoadingConnections,
  ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchAllData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({});
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [fetchAllData]);

  const isSearching = eventSearchTerm.trim().length > 0;

  if (isPageLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar userImage={userImage} />
        ) : (
          <MainNavbar userImage={userImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const formatJoinDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinal = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return `${month} ${day}${getOrdinal(day)} ${year}`;
  };

  const toggleEditConnectionsModal = () => {
    if (!isEditConnectionsModalOpen) {
      setEditConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editConnectionsModalScrollPosition);
      }, 0);
    }
    setIsEditConnectionsModalOpen(!isEditConnectionsModalOpen);
  };

  return (
    <div
      className={`${classes.page} ${
        isInterestsModalOpen ||
        isSkillsModalOpen ||
        isConnectionsModalOpen || // Add this
        isAddEventModalOpen || // Add this
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isProfileModalOpen ||
        isIntroModalOpen ||
        isAddExperienceModalOpen ||
        isAddEducationModalOpen ||
        isEditExperienceModalOpen ||
        isEditEducationModalOpen ||
        isEditStartupsModalOpen ||
        isAddStartupModalOpen ||
        isFollowModalOpen ||
        isEditCommunityModalOpen ||
        isEditEventsModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isInterestsModalOpen
          ? { top: `-${modalScrollPosition}px` }
          : isSkillsModalOpen
          ? { top: `-${skillsModalScrollPosition}px` }
          : isConnectionsModalOpen // Add this
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isAddEventModalOpen // Add this
          ? { top: `-${addEventModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isProfileModalOpen
          ? { top: `-${profileModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isAddExperienceModalOpen
          ? { top: `-${addExperienceModalScrollPosition}px` }
          : isAddEducationModalOpen
          ? { top: `-${addEducationModalScrollPosition}px` }
          : isEditExperienceModalOpen
          ? { top: `-${editExperienceModalScrollPosition}px` }
          : isEditEducationModalOpen
          ? { top: `-${editEducationModalScrollPosition}px` }
          : isEditStartupsModalOpen
          ? { top: `-${editStartupsModalScrollPosition}px` }
          : isAddStartupModalOpen
          ? { top: `-${addStartupModalScrollPosition}px` }
          : isFollowModalOpen
          ? { top: `-${followModalScrollPosition}px` }
          : isEditCommunityModalOpen
          ? { top: `-${editCommunityModalScrollPosition}px` }
          : isEditEventsModalOpen // Add this line
          ? { top: `-${editEventsModalScrollPosition}px` } // Add this line
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      <div className={classes.content}>
        {showProfileModal && (
          <ProfileModal
            onClose={toggleProfileModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleProfileModal(true);
            }}
          />
        )}
        {showEditCommunityModal && (
          <EditCommunityModal
            onClose={toggleEditCommunityModal}
            currentUser={currentUser}
            onCommunityUpdated={handleCommunityUpdated}
          />
        )}
        {showFollowModal && (
          <FollowModal
            onClose={() => {
              toggleFollowModal();
            }}
            currentUserId={currentUser.uid}
            onUnfollowed={() => {
              fetchFollowingCount(currentUser.uid);
              toggleFollowModal();
            }}
          />
        )}
        {showAddEventModal && (
          <AddEventModal
            onClose={toggleAddEventModal}
            currentUser={currentUser}
            onEventAdded={handleEventAdded}
          />
        )}
        {showEditEventsModal && (
          <EditEventModal
            onClose={toggleEditEventsModal}
            currentUser={currentUser}
            onEventUpdated={handleEventUpdated}
          />
        )}
        {showIntroModal && (
          <IntroModal
            onClose={toggleIntroModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleIntroModal(true);
            }}
          />
        )}
        {showEditStartupsModal && (
          <EditStartupsModal
            onClose={toggleEditStartupsModal}
            currentUser={currentUser}
            onStartupUpdated={handleStartupUpdated}
            onEditSingle={openEditSingleStartupModal}
          />
        )}
        {showSkillsModal && (
          <SkillsModal
            onClose={toggleSkillsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleSkillsModal(true);
            }}
          />
        )}
        {showInterestsModal && (
          <InterestsModal
            onClose={toggleInterestsModal}
            currentUser={currentUser}
            onProfileUpdate={(updatedUserData) => {
              handleProfileSave(updatedUserData);
              toggleInterestsModal(true);
            }}
          />
        )}
        {showAddExperienceModal && (
          <AddExperienceModal
            onClose={toggleAddExperienceModal}
            currentUser={currentUser}
            onExperienceAdded={(newExperience) => {
              handleExperienceAdded(newExperience);
              toggleAddExperienceModal(true);
            }}
          />
        )}
        {showEditExperienceModal && (
          <EditExperienceModal
            onClose={() => {
              toggleEditExperienceModal();
            }}
            currentUser={currentUser}
            onExperienceUpdated={handleProfileSave}
            onEditSingle={openEditSingleExperienceModal}
          />
        )}
        {showEditSingleExperienceModal && selectedExperience && (
          <EditSingleExperienceModal
            experience={selectedExperience}
            onClose={() => {
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onUpdate={(updatedExperience) => {
              handleProfileSave({
                experiences: userData.experiences.map((exp) =>
                  exp.id === updatedExperience.id ? updatedExperience : exp
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            onDelete={(deletedExperienceId) => {
              handleProfileSave({
                experiences: userData.experiences.filter(
                  (exp) => exp.id !== deletedExperienceId
                ),
              });
              setShowEditSingleExperienceModal(false);
              setShowEditExperienceModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showAddCommunityModal && (
          <AddCommunityModal
            onClose={toggleAddCommunityModal}
            currentUser={currentUser}
            onCommunityAdded={handleCommunityAdded}
          />
        )}
        {showAddStartupModal && (
          <AddStartupModal
            onClose={(success) => {
              toggleAddStartupModal(); // This will handle scroll position restoration
            }}
            currentUser={currentUser}
            onStartupAdded={handleStartupAdded}
          />
        )}
        {showAddEducationModal && (
          <AddEducationModal
            onClose={toggleAddEducationModal}
            currentUser={currentUser}
            onEducationAdded={(newEducation) => {
              handleEducationAdded(newEducation);
              toggleAddEducationModal(true);
            }}
          />
        )}
        {showEditEducationModal && (
          <EditEducationModal
            onClose={() => {
              toggleEditEducationModal();
            }}
            currentUser={currentUser}
            onEducationUpdated={handleProfileSave}
            onEditSingle={openEditSingleEducationModal}
          />
        )}
        {showEditSingleEducationModal && selectedEducation && (
          <EditSingleEducationModal
            education={selectedEducation}
            onClose={() => {
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onUpdate={(updatedEducation) => {
              handleProfileSave({
                education: userData.education.map((exp) =>
                  exp.id === updatedEducation.id ? updatedEducation : exp
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            onDelete={(deletedEducationId) => {
              handleProfileSave({
                education: userData.education.filter(
                  (exp) => exp.id !== deletedEducationId
                ),
              });
              setShowEditSingleEducationModal(false);
              setShowEditEducationModal(true);
            }}
            currentUser={currentUser}
          />
        )}
        {showConnectionsModal && (
          <ConnectionsModal
            onClose={toggleConnectionsModal}
            currentUserId={currentUser?.uid}
            loggedInUserId={currentUser?.uid}
            isOwnProfile={true}
            showConnectionDate={true}
            onConnectionRemoved={handleConnectionRemoved}
          />
        )}
        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={() => {
              setShowReactionsModal(false);
              setIsReactionsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, reactionsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser.uid}
          />
        )}
        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={() => {
              setShowRepostsModal(false);
              setIsRepostsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, repostsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser.uid}
          />
        )}

        <div className={classes.centreCards}>
          {/* PROFILE CARD */}
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                onClick={handleCloseTouch(toggleProfileModal)}
                className={classes.profileImage}
              >
                <img src={userImage || defaultImage} alt="Profile" />
              </div>
              <div onClick={handleCloseTouch(toggleProfileModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              {userData.firstName} {userData.lastName}{" "}
              <span className={classes.connectionType}>• You</span>
            </div>

            {(userData.bio || userData.link) && (
              <div className={classes.userDetails}>
                {userData.bio && (
                  <div className={classes.bio}>{userData.bio}</div>
                )}
                {userData.link && (
                  <div className={classes.link}>
                    <a
                      href={userData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {userData.linkText || userData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttons}>
              {!userData.bio && (
                <div
                  className={classes.headlineButton}
                  onClick={handleCloseTouch(toggleProfileModal)}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a headline
                </div>
              )}
              {!userData.link && (
                <div
                  className={classes.button}
                  onClick={handleCloseTouch(toggleProfileModal)}
                >
                  <svg
                    className=""
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Add a link
                </div>
              )}
              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                !userData.introHtml ? classes.emptyCardTitle : ""
              }`}
            >
              About
              <div onClick={handleCloseTouch(toggleIntroModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.introText}>
              <pre>
                {userData.introHtml ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: renderIntroText().text }}
                  />
                ) : (
                  <span className={classes.emptyIntroText}>
                    Tell us your story. What drives you? What are you working on
                    or interested in? Give others a quick snapshot of who you
                    are.
                  </span>
                )}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={handleMoreClick}
                  >
                    ...more
                  </span>
                )}
              </pre>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardAbout}>
              {userData.city && userData.country && (
                <div className={classes.skillContainer}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span
                    className={classes.skillText}
                  >{`${userData.city}, ${userData.country}`}</span>
                </div>
              )}
              {userData.createdAt && (
                <div className={classes.skillContainer}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 19V5h4a1 1 0 0 1 1 1v11h1a1 1 0 0 1 0 2h-6Z" />
                    <path
                      fill-rule="evenodd"
                      d="M12 4.571a1 1 0 0 0-1.275-.961l-5 1.428A1 1 0 0 0 5 6v11H4a1 1 0 0 0 0 2h1.86l4.865 1.39A1 1 0 0 0 12 19.43V4.57ZM10 11a1 1 0 0 1 1 1v.5a1 1 0 0 1-2 0V12a1 1 0 0 1 1-1Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span className={classes.skillText}>
                    {formatJoinDate(userData.createdAt)}
                  </span>
                </div>
              )}
              {userData.urlSlug && (
                <div className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                    />
                  </svg>
                  <span className={classes.skillText}>
                    {`foundry.bz/${userData.urlSlug}`}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={classes.activityCard}>
            <div
              className={`${classes.activityCardTitle} ${
                isActivityEmpty() ? classes.emptyCardTitle : ""
              }`}
            >
              Activity
            </div>

            <ActivityButtons
              selectedActivity={activityType}
              onSelectionChange={handleActivityChange}
              activityCounts={activityCounts}
              showAllButtons={true}
            />
            {activityType === "Posts" && (
              <>
                {loadingPosts ? (
                  <div className={classes.loadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={classes.posts}>
                      {posts.length === 0 ? (
                        <div>
                          <p className={classes.defaultTextBold}>
                            You haven’t posted yet
                          </p>{" "}
                          <p className={classes.defaultText}>
                            Posts you create will be displayed here.
                          </p>
                          <div className={classes.noPostBorder}></div>
                          <div className={classes.postHeader}>
                            <span className={classes.postAuthor}>You</span>
                            <span className={classes.postMessage}>
                              {" "}
                              posted this{" "}
                            </span>
                            <span className={classes.dot}>•</span>
                            <span>now</span>
                          </div>
                          <div className={classes.noPost}>
                            <div className={classes.postContent}>
                              <img
                                src={greyPostImage}
                                alt="Default startup"
                                className={classes.noPostImage}
                              />
                              <p className={classes.noTextPreview}>Post</p>
                            </div>
                          </div>
                          <div className={classes.noPostInteractions}>
                            <div>100 reactions</div>
                            <div className={classes.noRightInteractions}>
                              <div>50 comments</div>
                              <span className={classes.dot}>•</span>
                              <div>25 reposts</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {posts.slice(0, displayedPosts).map((post, index) => (
                            <React.Fragment key={post.id}>
                              {index !== 0 && (
                                <div className={classes.postBorder}></div>
                              )}
                              <div>
                                <div className={classes.postHeader}>
                                  <span className={classes.postAuthor}>
                                    You
                                  </span>
                                  <span className={classes.postMessage}>
                                    {" "}
                                    posted this{" "}
                                  </span>
                                  <span className={classes.dot}>•</span>
                                  <span>{formatTimestamp(post.createdAt)}</span>
                                </div>
                                <div
                                  className={`${classes.post} ${
                                    !post.postPreview.mediaType &&
                                    post.postPreview.text
                                      ? classes.textOnlyPost
                                      : ""
                                  }`}
                                  onClick={() => handlePostClick(post.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className={classes.postContent}>
                                    {post.postPreview &&
                                      post.postPreview.mediaType &&
                                      renderFilePreview(post.postPreview)}
                                    {post.postPreview &&
                                      post.postPreview.text && (
                                        <p className={classes.textPreview}>
                                          {truncateText(
                                            post.postPreview.text,
                                            3
                                          )}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className={classes.postInteractions}>
                                {post.reactions &&
                                  Object.keys(post.reactions).length > 0 && (
                                    <ReactionsSummary
                                      reactions={post.reactions}
                                      onClick={() =>
                                        handleReactionsClick(
                                          post.id,
                                          post.reactions
                                        )
                                      }
                                    />
                                  )}
                                <div className={classes.rightInteractions}>
                                  <PostInteractionCount
                                    postId={post.id}
                                    reposts={post.reposts}
                                    commentCount={post.commentCount}
                                    onClick={handleRepostsClick}
                                    onCommentClick={() =>
                                      handlePostClick(post.id)
                                    }
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </div>
                    {posts.length > 3 && displayedPosts < posts.length && (
                      <div
                        className={classes.showAllPosts}
                        onClick={() => navigate("/all-posts/me")}
                      >
                        Show all posts
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 12H5m14 0-4 4m4-4-4-4"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {activityType === "Reactions" && renderReactions()}
            {activityType === "Comments" && renderComments()}
            {activityType === "Reposts" && renderReposts()}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                connections.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Connections
              {connections.length > 0 && (
                <div onClick={toggleConnectionsModal}>
                  <svg
                    className={classes.experienceIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            {isLoadingConnections ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {connections.length > 0 && (
                  <div className={classes.searchContainer}>
                    <div className={classes.inputWrapper}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={classes.userInput}
                      />
                      <svg
                        className={classes.searchIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}

                {filteredConnections.length > 0 ? (
                  <>
                    {filteredConnections
                      .slice(0, displayedConnections)
                      .map((connection) => (
                        <div
                          key={connection.id}
                          className={classes.userContainer}
                        >
                          <img
                            src={
                              connection.userData?.profileImage || defaultImage
                            }
                            alt={`${connection.userData?.firstName} ${connection.userData?.lastName}`}
                            className={classes.userImage}
                            onClick={() =>
                              navigate(`/${connection.userData?.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                          <div className={classes.userInfo}>
                            <div className={classes.userNameContainer}>
                              <div
                                className={classes.userName}
                                onClick={() =>
                                  navigate(`/${connection.userData?.urlSlug}`)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {connection.userData?.firstName}{" "}
                                {connection.userData?.lastName}
                              </div>
                              <span className={classes.userConnectionType}>
                                • {connection.connectionType}
                              </span>
                            </div>
                            {connection.userData?.bio && (
                              <div className={classes.userBio}>
                                {connection.userData.bio}
                              </div>
                            )}
                            {connection.userData?.link && (
                              <a
                                href={connection.userData.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.userLink}
                              >
                                <span>
                                  {connection.userData.linkText ||
                                    connection.userData.link}
                                </span>
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    {filteredConnections.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (
                            displayedConnections >= filteredConnections.length
                          ) {
                            setDisplayedConnections(3);
                          } else {
                            setDisplayedConnections(
                              Math.min(
                                displayedConnections * 2,
                                filteredConnections.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedConnections >= filteredConnections.length
                          ? "Show less"
                          : "Show more"}
                        {displayedConnections >= filteredConnections.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <p className={classes.defaultText}>
                        Connect with like-minded people in the startup
                        community. Build meaningful relationships and make ideas
                        happen.
                      </p>
                    </div>
                    <div className={classes.people}>
                      <div
                        className={classes.button}
                        style={{ width: "fit-content" }}
                        onClick={() => navigate("/people")}
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        People
                      </div>
                    </div>
                    {connections.length === 0 && (
                      <>
                        <div className={classes.noPostBorder}></div>
                        <div className={classes.userContainer}>
                          <img
                            src={greyProfileImage}
                            alt={greyProfileImage}
                            className={classes.noUserImage}
                          />
                          <div className={classes.startupAbout}>
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div className={classes.noStartupRole}>
                                  Username
                                </div>

                                <span className={classes.userConnectionType}>
                                  • Direct
                                </span>
                              </div>

                              <div className={classes.noStartupName}>
                                Headline
                              </div>
                              <div className={classes.noUserLink}>
                                Link
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                startups.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Startups
              <div>
                <div onClick={toggleAddStartupModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {startupCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditStartupsModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <StartupButtons
              selectedStartupType={startupType}
              onSelectionChange={handleStartupTypeChange}
              startupCounts={startupCounts}
              showAllButtons={true}
            />

            {loadingStartupType ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {startupCounts[startupType.replace(" ", "")] > 0 && (
                  <div className={classes.searchContainer}>
                    <div className={classes.inputWrapper}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={startupSearchTerm}
                        onChange={(e) => setStartupSearchTerm(e.target.value)}
                        className={classes.userInput}
                      />
                      <svg
                        className={classes.searchIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}

                {startupCounts[startupType.replace(" ", "")] === 0 ? (
                  <div>
                    <p className={classes.defaultTextBold}>
                      {startupType === "Owner" &&
                        "You haven't created a startup yet"}
                      {startupType === "Team Member" &&
                        "You haven't joined a startup yet"}
                      {startupType === "Supported" &&
                        "You haven't supported a startup yet"}
                      {startupType === "Following" &&
                        "You aren't following any startups yet"}
                    </p>
                    <p className={classes.defaultText}>
                      {startupType === "Owner" &&
                        "Startups you create will be displayed here."}
                      {startupType === "Team Member" &&
                        "Startups you join will be displayed here."}
                      {startupType === "Supported" &&
                        "Startups you support will be displayed here."}
                      {startupType === "Following" &&
                        "Startups you follow will be displayed here."}
                    </p>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyStartupImage}
                        alt="Default startup"
                        className={classes.noStartupImage}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div className={classes.noStartupRole}>
                            Name
                            <span className={classes.userConnectionType}>
                              • Startup
                            </span>
                          </div>
                        </div>
                        <div className={classes.noStartupName}>Headline</div>
                        <div className={classes.noLink}>
                          Link
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : filteredStartups.length === 0 && startupSearchTerm ? (
                  <>
                    <div>
                      <p className={classes.defaultText}>
                        Explore and discover more startups. Find your next
                        opportunity or potential collaboration.
                      </p>
                    </div>
                    <div className={classes.people}>
                      <div
                        className={classes.button}
                        style={{ width: "fit-content" }}
                        onClick={() => navigate("/startupsandcommunities")}
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.535 7.677c.313-.98.687-2.023.926-2.677H17.46c.253.63.646 1.64.977 2.61.166.487.312.953.416 1.347.11.42.148.675.148.779 0 .18-.032.355-.09.515-.06.161-.144.3-.243.412-.1.111-.21.192-.324.245a.809.809 0 0 1-.686 0 1.004 1.004 0 0 1-.324-.245c-.1-.112-.183-.25-.242-.412a1.473 1.473 0 0 1-.091-.515 1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.401 1.401 0 0 1 13 9.736a1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.4 1.4 0 0 1 9 9.74v-.008a1 1 0 0 0-2 .003v.008a1.504 1.504 0 0 1-.18.712 1.22 1.22 0 0 1-.146.209l-.007.007a1.01 1.01 0 0 1-.325.248.82.82 0 0 1-.316.08.973.973 0 0 1-.563-.256 1.224 1.224 0 0 1-.102-.103A1.518 1.518 0 0 1 5 9.724v-.006a2.543 2.543 0 0 1 .029-.207c.024-.132.06-.296.11-.49.098-.385.237-.85.395-1.344ZM4 12.112a3.521 3.521 0 0 1-1-2.376c0-.349.098-.8.202-1.208.112-.441.264-.95.428-1.46.327-1.024.715-2.104.958-2.767A1.985 1.985 0 0 1 6.456 3h11.01c.803 0 1.539.481 1.844 1.243.258.641.67 1.697 1.019 2.72a22.3 22.3 0 0 1 .457 1.487c.114.433.214.903.214 1.286 0 .412-.072.821-.214 1.207A3.288 3.288 0 0 1 20 12.16V19a2 2 0 0 1-2 2h-6a1 1 0 0 1-1-1v-4H8v4a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2v-6.888ZM13 15a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Startups
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {filteredStartups
                      .slice(0, displayedStartups)
                      .map((startup) => (
                        <StartupSection key={startup.id} startup={startup} />
                      ))}
                    {filteredStartups.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (displayedStartups >= filteredStartups.length) {
                            setDisplayedStartups(3);
                          } else {
                            setDisplayedStartups(
                              Math.min(
                                displayedStartups * 2,
                                filteredStartups.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedStartups >= filteredStartups.length
                          ? "Show less"
                          : "Show more"}
                        {displayedStartups >= filteredStartups.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                communities.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Communities
              <div>
                <div onClick={toggleAddCommunityModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {communityCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditCommunityModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <CommunityButtons
              selectedCommunityType={communityType}
              onSelectionChange={handleCommunityTypeChange}
              communityCounts={communityCounts}
              showAllButtons={true}
            />

            {loadingCommunityType ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {communityCounts[communityType] > 0 && (
                  <div className={classes.searchContainer}>
                    <div className={classes.inputWrapper}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={communitySearchTerm}
                        onChange={(e) => setCommunitySearchTerm(e.target.value)}
                        className={classes.userInput}
                      />
                      <svg
                        className={classes.searchIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}

                {communityCounts[communityType] === 0 ? (
                  <div>
                    <p className={classes.defaultTextBold}>
                      {communityType === "Owner" &&
                        "You haven't created a community yet"}
                      {communityType === "Moderator" &&
                        "You haven't moderated a community yet"}
                      {communityType === "Member" &&
                        "You haven't joined a community yet"}
                    </p>
                    <p className={classes.defaultText}>
                      {communityType === "Owner" &&
                        "Communities you create will be displayed here."}
                      {communityType === "Moderator" &&
                        "Communities you moderate will be displayed here."}
                      {communityType === "Member" &&
                        "Communities you join will be displayed here."}
                    </p>
                    <div className={classes.noPostBorder}></div>
                    <div className={classes.userContainer}>
                      <img
                        src={greyCommunityImage}
                        alt="Default community"
                        className={classes.noCommunityImage}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div className={classes.noStartupRole}>
                            Name
                            <span className={classes.userConnectionType}>
                              • Community
                            </span>
                          </div>
                        </div>
                        <div className={classes.noStartupName}>Headline</div>
                        <div className={classes.noLink}>
                          Link
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : filteredCommunities.length === 0 && communitySearchTerm ? (
                  <>
                    <div>
                      <p className={classes.defaultText}>
                        Discover communities where like-minded people share
                        knowledge and experiences. Join the conversation.
                      </p>
                    </div>
                    <div className={classes.people}>
                      <div
                        className={classes.button}
                        style={{ width: "fit-content" }}
                        onClick={() => navigate("/startupsandcommunities")}
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Communities
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {filteredCommunities
                      .slice(0, displayedCommunities)
                      .map((community) => (
                        <CommunitySection
                          key={community.id}
                          community={community}
                        />
                      ))}
                    {filteredCommunities.length > 3 && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (
                            displayedCommunities >= filteredCommunities.length
                          ) {
                            setDisplayedCommunities(3);
                          } else {
                            setDisplayedCommunities(
                              Math.min(
                                displayedCommunities * 2,
                                filteredCommunities.length
                              )
                            );
                          }
                        }}
                      >
                        {displayedCommunities >= filteredCommunities.length
                          ? "Show less"
                          : "Show more"}
                        {displayedCommunities >= filteredCommunities.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={classes.card}>
            <div
              className={`${classes.extraCardTitle} ${
                events.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Events
              <div>
                <div onClick={toggleAddEventModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                {eventCounts.Owner > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditEventsModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <EventButtons
              selectedEventType={eventType}
              onSelectionChange={handleEventTypeChange}
              eventCounts={eventCounts}
              showAllButtons={true}
            />

            {isLoadingEvents ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            ) : (
              <>
                {loadingEventType ? (
                  <div className={classes.loadingIndicator}>
                    <span
                      className={`${classes.loadingIcon} material-symbols-outlined`}
                    >
                      progress_activity
                    </span>
                  </div>
                ) : (
                  <>
                    {((eventType === "Organiser" &&
                      eventCounts["Organising"] > 0) ||
                      (eventType !== "Organiser" &&
                        eventCounts[eventType] > 0)) && (
                      <div className={classes.searchContainer}>
                        <div className={classes.inputWrapper}>
                          <input
                            type="text"
                            placeholder="Search"
                            value={eventSearchTerm}
                            onChange={(e) => setEventSearchTerm(e.target.value)}
                            className={classes.userInput}
                          />
                          <svg
                            className={classes.searchIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                      </div>
                    )}

                    {(eventType === "Organiser" &&
                      eventCounts["Organising"] === 0) ||
                    (eventType !== "Organiser" &&
                      eventCounts[eventType] === 0) ? (
                      <div>
                        <p className={classes.defaultTextBold}>
                          {eventType === "Owner" &&
                            "You haven't created an event yet"}
                          {eventType === "Organiser" &&
                            "You haven't organised any events yet"}
                          {eventType === "Attending" &&
                            "You aren't attending any events yet"}
                          {eventType === "Attended" &&
                            "You haven't attended any events yet"}
                        </p>
                        <p className={classes.defaultText}>
                          {eventType === "Owner" &&
                            "Events you create will be displayed here."}
                          {eventType === "Organiser" &&
                            "Events you organise will be displayed here."}
                          {eventType === "Attending" &&
                            "Events you're attending will be displayed here."}
                          {eventType === "Attended" &&
                            "Events you've attended will be displayed here."}
                        </p>
                        <div className={classes.noPostBorder}></div>
                        <div className={classes.userContainer}>
                          <img
                            src={greyEventImage}
                            alt="Default event"
                            className={classes.noEventImage}
                          />
                          <div className={classes.startupAbout}>
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div className={classes.noStartupRole}>
                                  Name
                                </div>
                                <span className={classes.userConnectionType}>
                                  • Event
                                </span>
                              </div>
                              <div className={classes.noStartupName}>
                                Headline
                              </div>
                              <div className={classes.noLink}>
                                Link
                                <svg
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : searchFilteredEvents.length === 0 && eventSearchTerm ? (
                      <>
                        <div>
                          <p className={classes.defaultText}>
                            Discover and connect through events. Find gatherings
                            that match your interests and expand your network.
                          </p>
                        </div>
                        <div className={classes.people}>
                          <div
                            className={classes.button}
                            style={{ width: "fit-content" }}
                            onClick={() => navigate("/events")}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Events
                          </div>
                        </div>
                      </>
                    ) : (
                      <div>
                        {searchFilteredEvents
                          .slice(0, displayedEventCount)
                          .map((event) => (
                            <div
                              key={event.id}
                              className={classes.userContainer}
                            >
                              <img
                                src={event.eventImage || eventImage}
                                alt={event.eventName}
                                className={classes.eventImage}
                                onClick={() =>
                                  navigate(`/event/${event.eventUrlSlug}`)
                                }
                                style={{ cursor: "pointer" }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = greyEventImage;
                                }}
                              />
                              <div className={classes.userInfo}>
                                <div className={classes.userNameContainer}>
                                  <div
                                    className={classes.userName}
                                    onClick={() =>
                                      navigate(`/event/${event.eventUrlSlug}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {event.eventName}
                                  </div>
                                  <span className={classes.userConnectionType}>
                                    • Event
                                  </span>
                                </div>
                                {event.tagline && (
                                  <div className={classes.userBio}>
                                    {event.tagline}
                                  </div>
                                )}
                                {event.link && (
                                  <a
                                    href={event.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.userLink}
                                  >
                                    <span>{event.linkText || event.link}</span>
                                    <svg
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                      />
                                    </svg>
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        {searchFilteredEvents.length > 3 && (
                          <div
                            className={classes.showAllExperiences}
                            onClick={() => {
                              if (
                                displayedEventCount >=
                                searchFilteredEvents.length
                              ) {
                                setDisplayedEventCount(3);
                              } else {
                                setDisplayedEventCount(
                                  Math.min(
                                    displayedEventCount * 2,
                                    searchFilteredEvents.length
                                  )
                                );
                              }
                            }}
                          >
                            {displayedEventCount >= searchFilteredEvents.length
                              ? "Show less"
                              : "Show more"}
                            {displayedEventCount >=
                            searchFilteredEvents.length ? (
                              <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m5 15 7-7 7 7"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 9-7 7-7-7"
                                />
                              </svg>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className={classes.rightCards}>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.skills && userData.skills.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Skills
              <div onClick={handleCloseTouch(toggleSkillsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.cardAbout}>
              {userData.skills && userData.skills.length > 0 ? (
                userData.skills.slice(0, visibleSkills).map((skill, index) => (
                  <div key={index} className={classes.skillContainer}>
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="orangered"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                    <span className={classes.skillText}>{skill}</span>
                  </div>
                ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    List your superpowers! What are you great at? Your skills
                    could be the missing piece someone's looking for.
                  </div>

                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Soft skill</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                      </svg>
                      <span className={classes.skillText}>Technical skill</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.skills && userData.skills.length > 9 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleSkills >= userData.skills.length) {
                    setVisibleSkills(9);
                  } else {
                    setVisibleSkills(
                      Math.min(visibleSkills * 2, userData.skills.length)
                    );
                  }
                }}
              >
                {visibleSkills >= userData.skills.length
                  ? "Show less"
                  : "Show more"}
                {visibleSkills >= userData.skills.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>

          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                userData.interests && userData.interests.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Interests
              <div onClick={handleCloseTouch(toggleInterestsModal)}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.cardAbout}>
              {userData.interests && userData.interests.length > 0 ? (
                userData.interests
                  .slice(0, visibleInterests)
                  .map((interest, index) => (
                    <div key={index} className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6 text-gray"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="orangeRed"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>{interest}</span>
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    What gets you excited? Share your passions and interests.
                    You never know who might share them!
                  </div>
                  <div className={classes.defaultContainer}>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>Soft interest</span>
                    </div>
                    <div className={classes.emptySkillContainer}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                      </svg>
                      <span className={classes.skillText}>
                        Technical interest
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {userData.interests && userData.interests.length > 9 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleInterests >= userData.interests.length) {
                    setVisibleInterests(9);
                  } else {
                    setVisibleInterests(
                      Math.min(visibleInterests * 2, userData.interests.length)
                    );
                  }
                }}
              >
                {visibleInterests >= userData.interests.length
                  ? "Show less"
                  : "Show more"}
                {visibleInterests >= userData.interests.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          <div className={classes.experiencesCard}>
            <div
              className={`${classes.experiencesCardTitle} ${
                !userData.experiences || userData.experiences.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Experience
              <div>
                <div onClick={handleCloseTouch(toggleAddExperienceModal)}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>

                <div
                  className={`${classes.pencilIcon} ${
                    !userData.experiences || userData.experiences.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.experiences && userData.experiences.length > 0
                      ? handleCloseTouch(toggleEditExperienceModal)
                      : undefined
                  }
                >
                  {userData.experiences && userData.experiences.length > 0 && (
                    <div onClick={toggleEditExperienceModal}>
                      <svg
                        className={classes.experienceIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.experiencesCardAbout}>
              {sortedExperiences.length > 0 ? (
                sortedExperiences
                  .slice(0, visibleExperiences)
                  .map((experience) => (
                    <div
                      key={experience.id}
                      className={classes.experienceContainer}
                    >
                      <p className={classes.organisation}>
                        {experience.organisation}
                      </p>
                      <p className={classes.role}>{experience.role}</p>

                      <p className={classes.dates}>
                        {experience.startMonth} {experience.startYear} -{" "}
                        {experience.isCurrentlyWorking
                          ? "Current"
                          : `${experience.endMonth} ${experience.endYear}`}
                      </p>
                      {renderTruncatedText(
                        experience.description,
                        `exp-${experience.id}`,
                        experience.descriptionHtml // Pass the HTML content as third parameter
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    Your journey matters. Jot down your key experiences - every
                    bit counts.
                  </div>
                  <div className={classes.noExperienceContainer}>
                    <p className={classes.noOrganisation}>Organisation</p>
                    <p className={classes.noRole}>Role</p>
                    <p className={classes.dates}>January 2024 - Present</p>
                    <p className={classes.noDescription}>Description</p>
                  </div>
                </div>
              )}
            </div>
            {sortedExperiences.length > 3 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleExperiences >= sortedExperiences.length) {
                    setVisibleExperiences(3);
                  } else {
                    setVisibleExperiences(
                      Math.min(visibleExperiences * 2, sortedExperiences.length)
                    );
                  }
                }}
              >
                {visibleExperiences >= sortedExperiences.length
                  ? "Show less"
                  : "Show more"}
                {visibleExperiences >= sortedExperiences.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          <div className={classes.educationCard}>
            <div
              className={`${classes.educationCardTitle} ${
                !userData.education || userData.education.length === 0
                  ? classes.emptyCardTitle
                  : ""
              }`}
            >
              Education
              <div>
                <div onClick={toggleAddEducationModal}>
                  <svg
                    className={classes.addIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                </div>
                <div
                  className={`${classes.pencilIcon} ${
                    !userData.education || userData.education.length === 0
                      ? classes.disabledPencilIcon
                      : ""
                  }`}
                  onClick={
                    userData.education && userData.education.length > 0
                      ? handleCloseTouch(toggleEditEducationModal)
                      : undefined
                  }
                >
                  {!userData.education || userData.education.length === 0 || (
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.educationCardAbout}>
              {userData.education && userData.education.length > 0 ? (
                userData.education
                  .sort((a, b) => {
                    const now = new Date();
                    const currentYear = now.getFullYear();
                    const currentMonth = monthsList.indexOf(
                      now.toLocaleString("default", { month: "long" })
                    );

                    const getEndDate = (edu) => {
                      if (edu.isCurrentlyStudying) {
                        return new Date(currentYear, currentMonth);
                      }
                      return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
                    };

                    const aEnd = getEndDate(a);
                    const bEnd = getEndDate(b);

                    if (aEnd > bEnd) return -1;
                    if (aEnd < bEnd) return 1;

                    const aStart = new Date(
                      `${a.startMonth} 1, ${a.startYear}`
                    );
                    const bStart = new Date(
                      `${b.startMonth} 1, ${b.startYear}`
                    );

                    if (aStart > bStart) return -1;
                    if (aStart < bStart) return 1;

                    return new Date(b.dateAdded) - new Date(a.dateAdded);
                  })
                  .slice(0, visibleEducation)
                  .map((education) => (
                    <div
                      key={education.id}
                      className={classes.educationContainer}
                    >
                      <p className={classes.school}>{education.school}</p>
                      <p className={classes.degree}>{education.degree}</p>
                      <p className={classes.subject}>{education.subject}</p>
                      <p className={classes.grade}>{education.grade}</p>

                      <p className={classes.dates}>
                        {education.startMonth} {education.startYear} -{" "}
                        {education.isCurrentlyStudying
                          ? "Present"
                          : `${education.endMonth} ${education.endYear}`}
                      </p>
                      {renderTruncatedText(
                        education.description,
                        `edu-${education.id}`,
                        education.descriptionHtml
                      )}
                    </div>
                  ))
              ) : (
                <div className={classes.emptyContainer}>
                  <div className={classes.defaultText}>
                    School of life or traditional education? Share your learning
                    path. It might inspire others.
                  </div>
                  <div className={classes.noExperienceContainer}>
                    <p className={classes.noSchool}>School</p>
                    <p className={classes.noDegree}>Degree</p>
                    <p className={classes.noSubject}>Subject</p>
                    <p className={classes.grade}>Grade</p>
                    <p className={classes.dates}>September 2021 - July 2024</p>
                    <p className={classes.noDescription}>Description</p>
                  </div>
                </div>
              )}
            </div>
            {userData.education && userData.education.length > 3 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleEducation >= userData.education.length) {
                    setVisibleEducation(3);
                  } else {
                    setVisibleEducation(
                      Math.min(visibleEducation * 2, userData.education.length)
                    );
                  }
                }}
              >
                {visibleEducation >= userData.education.length
                  ? "Show less"
                  : "Show more"}
                {visibleEducation >= userData.education.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>

          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Profile;
