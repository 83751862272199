import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./InterestsModal.module.css";
import InterestsDropdown from "../Dropdowns/InterestsDropdown";

const InterestsModal = ({ onClose, currentUser, onProfileUpdate }) => {
  const [interests, setInterests] = useState([]);
  const [initialInterests, setInitialInterests] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isChanged, setIsChanged] = useState(false);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  useEffect(() => {
    const fetchUserInterests = async () => {
      setInitialLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().interests) {
          const interestsData = userDoc
            .data()
            .interests.map((interest) => ({ name: interest, id: interest }));
          setInterests(interestsData);
          setInitialInterests(interestsData);
        }
      } catch (error) {
        console.error("Error fetching user interests:", error);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchUserInterests();
  }, [currentUser.uid]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  useEffect(() => {
    const dataChanged =
      JSON.stringify(interests) !== JSON.stringify(initialInterests);
    setIsChanged(dataChanged);
  }, [interests, initialInterests]);

  const handleInterestsChange = (newInterests) => {
    setInterests(newInterests);
    setIsChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      const updatedUserData = {
        ...userData,
        interests: interests.map((interest) => interest.name),
      };

      await updateDoc(userDocRef, updatedUserData);
      onProfileUpdate(updatedUserData);
      // Don't call onClose here
    } catch (error) {
      setError("Failed to update interests. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  const handleInputChange = (inputValue) => {
    const currentInterests = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    const newInterests = currentInterests.map((name) => ({ name, id: name }));
    setIsChanged(
      JSON.stringify(newInterests) !== JSON.stringify(initialInterests)
    );
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit interests
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)} // Pass false for regular close
              onTouchStart={(e) => handleCloseTouch(e, false)} // Pass false for regular close
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.scrollableContent}>
          {initialLoading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <form
              className={classes.interactions}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className={classes.interestsDropdownWrapper}>
                <InterestsDropdown
                  selectedInterests={interests}
                  onInterestsChange={handleInterestsChange}
                  onInputChange={handleInputChange}
                  disabled={loading}
                  placeholder="Ex: Skydiving, Reading"
                  customClassName={classes.maxHeight}
                />
              </div>
            </form>
          )}
          {error && <p className={classes.error}>{error}</p>}
        </div>

        {windowHeight <= 991 && (
          <div className={classes.cardFooter}>
            <div className={classes.smallPrint}>
              <svg
                className={classes.infoIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                  clipRule="evenodd"
                />
              </svg>
              <p>Interests must be separated with commas</p>
            </div>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                // Only close the modal if there are no errors
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}

        {windowHeight > 991 && (
          <div className={classes.cardFooter}>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                // Only close the modal if there are no errors
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterestsModal;
