import React, { useState, useRef, useEffect } from "react";
import defaultImage from "../../assets/images/startupImage.jpg";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./AddStartupModal.module.css";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import imageCompression from "browser-image-compression";
import monthsList from "../../utils/monthsList"; // Add this import
import Cropper from "react-easy-crop";
import {
  generateStartupUrlSlug,
  updateStartupUrlSlug,
} from "../../utils/startupUrlUtils";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import CompanyTypeDropdown from "../Dropdowns/CompanyTypeDropdown";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const AddStartupModal = ({ onClose, currentUser, onStartupAdded }) => {
  const [role, setRole] = useState("");

  const [industry, setIndustry] = useState("");
  const [companyType, setCompanyType] = useState(null);
  const [startupImage, setStartupImage] = useState(null);
  const [currentStartupImageUrl, setCurrentStartupImageUrl] = useState(null);
  const [startupName, setStartupName] = useState("");
  const [bio, setBio] = useState("");
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [monthFounded, setMonthFounded] = useState(null);
  const [yearFounded, setYearFounded] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);

  const formRef = useRef(null);
  const bioTextareaRef = useRef(null);
  const cityCache = React.useMemo(() => ({}), []);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.geabbabababb}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const areRequiredFieldsFilled = () => {
    return (
      startupName.trim() !== "" &&
      selectedCountry !== null &&
      selectedCity !== null &&
      monthFounded !== null &&
      yearFounded !== null &&
      role.trim() !== "" &&
      industry.trim() !== "" &&
      companyType !== null
    );
  };

  const handleRoleChange = (e) => {
    if (e.target.value.length <= 50) {
      setRole(e.target.value);
    }
  };

  const handleIndustryChange = (e) => {
    if (e.target.value.length <= 50) {
      setIndustry(e.target.value);
    }
  };

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    loadCountries();
  }, []);

  const loadCities = React.useCallback(async () => {
    if (!selectedCountry) return;
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleStartupNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setStartupName(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      if (!newLink) {
        setLinkText("");
      }
      setIsValidUrl(validateUrl(newLink));
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleStartupImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        const reader = new FileReader();
        reader.onload = () => {
          setCurrentStartupImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);

        setStartupImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        console.error("Error compressing image:", error);
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !startupName ||
      !selectedCountry ||
      !selectedCity ||
      !monthFounded ||
      !yearFounded ||
      !role ||
      !industry ||
      !companyType
    ) {
      setError("Please fill in all required fields.");
      return false;
    }

    setLoading(true);
    setError("");

    try {
      let startupImageUrl = "";

      if (startupImage && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          currentStartupImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        // Modified path to match Storage rules structure
        const tempStartupId = `temp_${Date.now()}`; // Temporary ID before actual startup creation
        const tempPostId = "profile"; // Or any identifier for the main startup image
        const startupImageRef = ref(
          storage,
          `startupImages/${
            currentUser.uid
          }/${tempStartupId}/${tempPostId}/${Date.now()}.jpg`
        );

        await uploadBytes(startupImageRef, blob);
        startupImageUrl = await getDownloadURL(startupImageRef);
      } else {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        startupImageUrl = userData.defaultStartupImage || "";
      }

      const startupUrlSlug = await generateStartupUrlSlug(startupName);
      const timestamp = serverTimestamp();

      const startupData = {
        startupName,
        bio,
        link,
        linkText: link ? linkText : "",
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
        monthFounded: monthsList[monthFounded - 1],
        yearFounded,
        startupImage: startupImageUrl,
        startupUrlSlug,
        intro: "",
        introHtml: "",
        followingCount: 0,
        role,
        industry,
        companyType,
        createdAt: timestamp,
      };

      const startupsRef = collection(
        firestore,
        `users/${currentUser.uid}/startups`
      );
      const docRef = await addDoc(startupsRef, startupData);

      if (onStartupAdded) {
        onStartupAdded({
          id: docRef.id,
          ...startupData,
          createdAt: new Date(),
        });
      }

      return true;
    } catch (error) {
      console.error("Error adding startup:", error);
      setError("Failed to add startup. Please try again.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors
      if (!error) {
        onClose(true);
      }
    }
  };

  // Add this new function
  const handleClose = (e, isSuccess = false) => {
    // If there's an event, prevent default behavior
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Call onClose with the success state
    onClose(isSuccess);
  };

  const cropSize = { width: 128, height: 128 };

  const handleStartupImageClick = (e) => {
    e.preventDefault();
    const input = document.getElementById("startupImage");
    if (input) {
      input.click();
    }
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Add startup
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)} // Explicitly pass false for close icon
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <div className={classes.imageContainer}>
                <div className={classes.profileImage}>
                  {currentStartupImageUrl && isCropping ? (
                    <div className={classes.cropContainer}>
                      <Cropper
                        image={currentStartupImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        // cropShape="round"
                        showGrid={false}
                        cropSize={cropSize}
                        objectFit="cover"
                      />
                    </div>
                  ) : (
                    <img
                      src={currentStartupImageUrl || defaultImage}
                      alt="Startup"
                    />
                  )}
                </div>
                <button
                  type="button"
                  className={classes.uploadButton}
                  onClick={handleStartupImageClick}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="gray"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <input
                type="file"
                id="startupImage"
                accept="image/*"
                onChange={handleStartupImageChange}
                className={classes.profileButton}
              />
              <label htmlFor="startupName" className={classes.label}>
                Name*
              </label>
              <input
                id="startupName"
                name="startupName"
                type="text"
                placeholder="Ex: AirBedAndBreakfast"
                className={classes.userInput}
                value={startupName}
                onChange={handleStartupNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{startupName.length}/50</div>
              <div className={classes.border}></div>
              <label htmlFor="bio" className={classes.label}>
                Tagline
              </label>
              <textarea
                id="bio"
                ref={bioTextareaRef}
                value={bio}
                onChange={handleBioChange}
                className={classes.textArea}
                placeholder="Ex: Reimagining travel through home sharing"
              />
              <div className={classes.charCount}>{bio.length}/250</div>
              <div className={classes.border}></div>

              <label htmlFor="role" className={classes.label}>
                Role*
              </label>
              <input
                id="role"
                name="role"
                type="text"
                placeholder="Ex: Founder & CEO"
                className={classes.userInput}
                value={role}
                onChange={handleRoleChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{role.length}/50</div>
              <div className={classes.border}></div>

              <label htmlFor="industry" className={classes.label}>
                Industry*
              </label>
              <input
                id="industry"
                name="industry"
                type="text"
                placeholder="Ex: Technology"
                className={classes.userInput}
                value={industry}
                onChange={handleIndustryChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{industry.length}/50</div>
              <div className={classes.border}></div>

              <label htmlFor="companyType" className={classes.label}>
                Company Type*
              </label>
              <CompanyTypeDropdown
                selectedType={companyType}
                onTypeChange={setCompanyType}
                disabled={loading}
              />
              <div className={classes.border}></div>

              <label htmlFor="bio" className={classes.label}>
                Date founded*
              </label>
              <div className={classes.dateContainer}>
                <MonthDropdown
                  selectedMonth={monthFounded}
                  onMonthChange={setMonthFounded}
                  disabled={loading}
                  maxMonth={yearFounded === currentYear ? currentMonth : 12}
                  placeholder="Month"
                  isEndDate={false}
                  startYear={yearFounded}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                />
                <YearDropdown
                  selectedYear={yearFounded}
                  onYearChange={setYearFounded}
                  disabled={loading}
                  maxYear={currentYear}
                  placeholder="Year"
                />
              </div>
              <div className={classes.border}></div>

              <label htmlFor="country" className={classes.label}>
                Location*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United States"
              />
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: San Francisco"
              />
              <div className={classes.border}></div>

              <label htmlFor="link" className={classes.label}>
                Link
              </label>
              <input
                id="link"
                name="link"
                type="url"
                placeholder="Ex: https://example.com"
                className={classes.userInput}
                value={link}
                onChange={handleLinkChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{link.length}/250</div>

              <label
                htmlFor="linkText"
                className={`${classes.linkTextLabel} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                Link text
              </label>
              <input
                id="linkText"
                name="linkText"
                type="text"
                placeholder="Ex: example.com"
                className={`${classes.userInput} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
                value={linkText}
                onChange={handleLinkTextChange}
                disabled={loading || !isValidUrl}
                style={{ opacity: isValidUrl ? 1 : 0.5 }}
              />
              <div
                className={`${classes.smallPrint} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                <p>Customise how your link will appear (optional).</p>
                <span className={classes.charCount}>{linkText.length}/50</span>
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""} ${
              !areRequiredFieldsFilled() ? classes.disabled : ""
            }`}
            disabled={loading || !areRequiredFieldsFilled()}
            onClick={handleSaveButtonClick}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddStartupModal;
