import React, { useState, useRef, useEffect, useMemo } from "react";
import rolesList from "../../utils/rolesList";
import classes from "./RoleDropdown.module.css";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        resolve(func(...args));
      }, delay);
    });
  };
};

const RoleDropdown = ({
  selectedRole,
  onRoleChange,
  onInputChange,
  disabled,
  placeholder,
  maxLength = 50,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const debouncedFilterRoles = useMemo(
    () =>
      debounce((query) => {
        if (!query) return [];
        query = query.toLowerCase();

        const startsWithQuery = rolesList.filter((role) =>
          role.toLowerCase().startsWith(query)
        );

        const includesQuery = rolesList.filter(
          (role) =>
            !role.toLowerCase().startsWith(query) &&
            role.toLowerCase().includes(query)
        );

        return [
          ...startsWithQuery.map((role) => ({
            name: role,
            id: role,
          })),
          ...includesQuery.map((role) => ({
            name: role,
            id: role,
          })),
        ];
      }, 100),
    []
  );

  useEffect(() => {
    setInputValue(selectedRole ? selectedRole.name : "");
  }, [selectedRole]);

  const handleInputChange = (e) => {
    const newValue = e.target.value.slice(0, maxLength); // Limit input to maxLength
    setInputValue(newValue);
    onInputChange(newValue);
    onRoleChange({ name: newValue, id: newValue });

    // Optionally, you can still use the debouncedFilterRoles here
    // if you want to perform any background filtering or validation
    debouncedFilterRoles(newValue).then((filteredRoles) => {
      // You can use filteredRoles for any additional logic if needed
      console.log(filteredRoles);
    });
  };

  return (
    <div className={classes.roleInputContainer}>
      <input
        ref={inputRef}
        className={`${classes.roleInput} ${disabled ? classes.disabled : ""}`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
        maxLength={maxLength} // Add this line
      />
    </div>
  );
};

export default RoleDropdown;
