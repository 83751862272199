import MainNavbar from "../../../components/Navbars/MainNavbar";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import FollowModal from "../../../components/Modals/FollowModal";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./UserProfile.module.css";
import profileImage from "../../../assets/icons/profileImage.jpg";
import { useLocation, useParams } from "react-router-dom";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import RepostsModal from "../../../components/Modals/RepostsModal";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  writeBatch,
  orderBy,
  limit,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firestore } from "../../../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import MutualConnectionsModal from "../../../components/Modals/MutualConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";

function UserProfile() {
  const [followingCount, setFollowingCount] = useState(0);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [startups, setStartups] = useState([]);
  const [showAllStartups, setShowAllStartups] = useState(false);
  const [loadingStartups, setLoadingStartups] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const location = useLocation();
  const { userId } = useParams();
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(location.state?.userData || {});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUserImage, setCurrentUserImage] = useState(profileImage);
  const [connectionStatus, setConnectionStatus] = useState("Connect");
  const [currentUser, setCurrentUser] = useState(null);
  const [connectionCount, setConnectionCount] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hasReceivedRequest, setHasReceivedRequest] = useState(false);
  const [connectionType, setConnectionType] = useState("Extended");
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const { urlSlug } = useParams();
  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [activityType, setActivityType] = useState("Posts");
  const [education, setEducation] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [displayedReactions, setDisplayedReactions] = useState(3);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);

  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);

  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [showAllInterests, setShowAllInterests] = useState(false);
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);

  const toggleShowAllSkills = () => setShowAllSkills(true);
  const toggleShowAllInterests = () => setShowAllInterests(true);
  const toggleShowAllExperiences = () => setShowAllExperiences(true);
  const toggleShowAllEducation = () => setShowAllEducation(true);

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [
    mutualConnectionsModalScrollPosition,
    setMutualConnectionsModalScrollPosition,
  ] = useState(0);
  const [isMutualConnectionsModalOpen, setIsMutualConnectionsModalOpen] =
    useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    comments: 0,
    reposts: 0,
  });

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // For startup posts (originalPosterId starts with "startup_")
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        // Find the startup owner
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            // Found the startup, get its post
            const postRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
            );
            const postSnap = await getDoc(postRef);

            if (postSnap.exists()) {
              return postSnap;
            }
          }
        }
        console.log(
          `Could not find startup post. StartupId: ${startupId}, PostId: ${postId}`
        );
      } else {
        // For user posts (check both personal posts and startup posts)

        // First check user's startups
        const startupsRef = collection(
          firestore,
          `users/${originalPosterId}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);

        // Check each startup's posts
        for (const startupDoc of startupsSnapshot.docs) {
          const postRef = doc(
            firestore,
            `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
          );
          const postSnap = await getDoc(postRef);

          if (postSnap.exists()) {
            return postSnap;
          }
        }

        // If not found in startups, check user's personal posts
        const userPostRef = doc(
          firestore,
          `users/${originalPosterId}/posts/${postId}`
        );
        const userPostSnap = await getDoc(userPostRef);

        if (userPostSnap.exists()) {
          return userPostSnap;
        }

        console.log(
          `Could not find post for user ${originalPosterId} with postId ${postId}`
        );
      }
      return null;
    } catch (error) {
      console.error("Error in getOriginalPostRef:", error);
      return null;
    }
  };

  const fetchReactions = async () => {
    if (!userData.id) return;
    setLoadingReactions(true);
    try {
      const reactionsRef = collection(
        firestore,
        `users/${userData.id}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = await Promise.all(
        reactionsSnapshot.docs.map(async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            reactionData.originalPosterId,
            reactionData.postId
          );

          if (!originalPostSnap) return null;

          const originalPostData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost: {
              id: reactionData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
            },
            reactedAt: reactionData.reactedAt.toDate(),
            emoji: reactionData.emoji,
          };
        })
      );

      const validReactions = reactionsData.filter(
        (reaction) => reaction !== null
      );
      setReactions(validReactions);
    } catch (error) {
      console.error("Error fetching reactions:", error);
    } finally {
      setLoadingReactions(false);
    }
  };

  // Apply the same pattern to fetchReposts and fetchComments
  const fetchReposts = async () => {
    if (!userData.id) return;
    setLoadingReposts(true);
    try {
      const repostsRef = collection(firestore, `users/${userData.id}/reposts`);
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = await Promise.all(
        repostsSnapshot.docs.map(async (docSnapshot) => {
          const repostData = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            repostData.originalPosterId,
            repostData.postId
          );

          if (!originalPostSnap) return null;

          const originalPostData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...repostData,
            originalPost: {
              id: repostData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
            },
            repostedAt: repostData.repostedAt.toDate(),
          };
        })
      );

      const validReposts = repostsData.filter((repost) => repost !== null);
      setReposts(validReposts);
    } catch (error) {
      console.error("Error fetching reposts:", error);
    } finally {
      setLoadingReposts(false);
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const sortedStartups = useMemo(() => {
    if (!startups) return [];
    return [...startups].sort((a, b) => {
      // Convert month strings to numbers for comparison
      const aMonth = monthsList.indexOf(a.monthFounded);
      const bMonth = monthsList.indexOf(b.monthFounded);

      // Create dates for comparison
      const aDate = new Date(a.yearFounded, aMonth);
      const bDate = new Date(b.yearFounded, bMonth);

      // Compare dates first
      if (aDate.getTime() !== bDate.getTime()) {
        return bDate.getTime() - aDate.getTime(); // Newest first
      }

      // If dates are the same, compare creation timestamps
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [startups]);

  const toggleShowAllStartups = () => {
    setShowAllStartups(true);
  };

  const MAX_LENGTH = 250; // Same as in Profile component

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderTruncatedText = (text, id, htmlContent = null) => {
    if (!text) return null;

    const isExpanded = expandedDescriptions[id];

    if (text.length <= MAX_LENGTH) {
      return htmlContent ? (
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          onClick={handleLinkClick}
        />
      ) : (
        <p className={classes.description}>{text}</p>
      );
    }

    if (isExpanded) {
      return htmlContent ? (
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleLinkClick}
        />
      ) : (
        <p className={classes.description}>{text}</p>
      );
    }

    const truncatedText = text.slice(0, MAX_LENGTH).trim();
    return (
      <p className={classes.description}>
        {htmlContent ? (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ) : (
          truncatedText
        )}
        <span
          className={classes.moreButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleDescription(id);
          }}
        >
          ...more
        </span>
      </p>
    );
  };

  const fetchComments = useCallback(async () => {
    if (!userData.id) return;
    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${userData.id}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentedPostsData = await Promise.all(
        commentedPostsSnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            data.originalPosterId,
            data.postId
          );

          if (!originalPostSnap) return null;

          const postData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...data,
            originalPost: {
              id: data.postId,
              ...postData,
              postPreview: {
                mediaType: postData.content?.type || null,
                mediaUrl: postData.content?.url || null,
                text: postData.text || "",
                thumbnail: postData.content?.thumbnail || null,
              },
            },
            lastCommentedAt: data.lastCommentedAt.toDate(),
          };
        })
      );

      const validCommentedPosts = commentedPostsData.filter(
        (post) => post !== null
      );
      setCommentedPosts(validCommentedPosts);
    } catch (error) {
      console.error("Error fetching commented posts:", error);
    } finally {
      setLoadingComments(false);
    }
  }, [userData.id]);

  const renderComments = () => {
    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no commented posts.
            </p>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>
                          {userData.firstName} {userData.lastName}
                        </span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                commentedPost.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postInteractions}>
                      {commentedPost.originalPost?.reactions &&
                        Object.keys(commentedPost.originalPost.reactions)
                          .length > 0 && (
                          <ReactionsSummary
                            reactions={commentedPost.originalPost.reactions}
                            onClick={() =>
                              handleReactionsClick(
                                commentedPost.originalPost.id,
                                commentedPost.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={commentedPost.originalPost?.id}
                          reposts={commentedPost.originalPost?.reposts}
                          commentCount={
                            commentedPost.originalPost?.commentCount
                          }
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(commentedPost.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
        {commentedPosts.length > 3 &&
          displayedComments < commentedPosts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate(`/all-comments/${userData.urlSlug}`)}
            >
              Show all comments
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </>
    );
  };

  // Add these functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchCommentsCount = useCallback(async (userId) => {
    const commentsRef = collection(firestore, `users/${userId}/commentedPosts`);
    const snapshot = await getDocs(query(commentsRef, limit(1)));
    return snapshot.size;
  }, []);

  const renderIntroText = () => {
    const introText = userData.introHtml || "You have no intro.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  const storage = getStorage();

  const navigate = useNavigate();
  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const positionDropdown = () => {
    if (optionsButtonRef.current && dropdownRef.current) {
      const buttonRect = optionsButtonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      // Position the dropdown below the button
      dropdownRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
      dropdownRef.current.style.right = `${
        window.innerWidth - buttonRect.right
      }px`;
    }
  };

  // In UserProfile.js
  const handlePostClick = (postId, isComment = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: true,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Reactions":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const fetchEducation = async (userId) => {
    const educationRef = collection(firestore, `users/${userId}/education`);
    const educationSnapshot = await getDocs(educationRef);
    const educationData = educationSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEducation(educationData);
  };

  const fetchExperiences = async (userId) => {
    const experiencesRef = collection(firestore, `users/${userId}/experiences`);
    const experiencesSnapshot = await getDocs(experiencesRef);
    const experiencesData = experiencesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setExperiences(experiencesData);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleCloseReactionsModal = () => {
    setShowReactionsModal(false);
    setIsReactionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, reactionsModalScrollPosition);
    }, 0);
  };

  const handleCloseRepostsModal = () => {
    setShowRepostsModal(false);
    setIsRepostsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, repostsModalScrollPosition);
    }, 0);
  };

  const handleCloseConnectionsModal = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  const handleCloseMutualConnectionsModal = () => {
    setShowMutualConnectionsModal(false);
    setIsMutualConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, mutualConnectionsModalScrollPosition);
    }, 0);
  };

  const [mutualConnections, setMutualConnections] = useState([]);
  const [showMutualConnectionsModal, setShowMutualConnectionsModal] =
    useState(false);

  const OptionsDropdown = () => {
    return (
      <div className={classes.optionsDropdown}>
        <button className={classes.optionButton}>
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="gray"
            viewBox="0 0 24 24"
          >
            <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z" />
          </svg>
          Report
        </button>
        <button
          className={classes.optionButton}
          onClick={() => console.log("Block clicked")}
        >
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              stroke-linecap="round"
              stroke-width="2"
              d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Block
        </button>
      </div>
    );
  };

  const fetchPosts = useCallback(async () => {
    if (!userData.id) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${userData.id}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = await Promise.all(
        postsSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          let mediaUrl = null;

          if (data.content && data.content.type && data.content.url) {
            try {
              const storageRef = ref(storage, data.content.url);
              mediaUrl = await getDownloadURL(storageRef);
            } catch (error) {
              console.error("Error fetching media URL:", error);
            }
          }

          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate() || new Date(),
            postPreview: {
              mediaType: data.content?.type || null,
              mediaUrl: mediaUrl,
              text: data.text,
              thumbnail: data.content?.thumbnail || null, // Add this line
            },
            reactions: data.reactions || {},
            reposts: data.reposts || {}, // Add this line
            commentCount: data.commentCount || 0,
          };
        })
      );

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } finally {
      setLoadingPosts(false);
    }
  }, [userData.id, firestore, storage]);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <div className={classes.postInteractionCount}>
        {commentCount > 0 && (
          <span
            className={classes.commentText}
            onClick={() => onCommentClick(postId)}
          >
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span
            className={classes.repost}
            onClick={() => onClick(postId, reposts)}
          >
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </div>
    );
  };

  // useEffect(() => {
  //   if (userData.id && activityType === "Posts") {
  //     fetchPosts();
  //   }
  // }, [userData.id, activityType, fetchPosts]);

  const handleActivityChange = (selectedActivity) => {
    // Set loading state immediately when activity changes
    switch (selectedActivity) {
      case "Posts":
        setLoadingPosts(true);
        break;
      case "Reactions":
        setLoadingReactions(true);
        break;
      case "Comments":
        setLoadingComments(true);
        break;
      case "Reposts":
        setLoadingReposts(true);
        break;
    }
    setActivityType(selectedActivity);
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      return (
        <div className={classes.mediaPreview}>
          <img src={thumbnailUrl} alt="Video preview" />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const fetchConnectionCount = async (userId) => {
    try {
      const connectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const userImage = userData.profileImage || profileImage;

  useEffect(() => {
    const fetchCurrentUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCurrentUserImage(userData.profileImage || profileImage);
          }
        } catch (error) {}
      }
    };

    fetchCurrentUserData();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        if (userData.id) {
          checkConnectionStatus(user.uid, userData.id);
          fetchMutualConnections(user.uid, userData.id);
        }
      }
    });

    return () => unsubscribe();
  }, [userData.id]);

  const checkConnectionStatus = async (currentUserId, profileUserId) => {
    if (currentUserId === profileUserId) {
      setConnectionType("You");
      setConnectionStatus("Self");
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${profileUserId}`
    );

    try {
      const connectionDoc = await getDoc(connectionRef);

      if (connectionDoc.exists()) {
        const status = connectionDoc.data().status;
        setConnectionStatus(status);
        setHasReceivedRequest(status === "Received");
        if (status === "Connected") {
          setConnectionType("Direct");
        } else {
          await checkIndirectConnection(currentUserId, profileUserId);
        }
      } else {
        setConnectionStatus("Connect");
        setHasReceivedRequest(false);
        await checkIndirectConnection(currentUserId, profileUserId);
      }
    } catch (error) {
      console.error("Error checking connection status:", error);
      setConnectionStatus("Connect");
      setHasReceivedRequest(false);
      setConnectionType("Extended");
    }
  };

  const checkIndirectConnection = async (currentUserId, profileUserId) => {
    const currentUserConnections = await getDocs(
      collection(firestore, `users/${currentUserId}/connections`)
    );

    for (const conn of currentUserConnections.docs) {
      if (conn.data().status === "Connected") {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${profileUserId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          setConnectionType("Indirect");
          return;
        }
      }
    }

    setConnectionType("Extended");
  };

  const handleConnect = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) {
      console.error("Current user or profile user ID is missing");
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      if (connectionStatus === "Connect") {
        const batch = writeBatch(firestore);

        batch.set(connectionRef, { status: "Pending" });
        batch.set(recipientConnectionRef, { status: "Received" });

        const notificationsRef = collection(
          firestore,
          `users/${userData.id}/notifications`
        );
        const existingRequestsQuery = query(
          notificationsRef,
          where("type", "==", "connection_request"),
          where("from", "==", currentUser.uid)
        );
        const existingRequestsSnapshot = await getDocs(existingRequestsQuery);

        existingRequestsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        const newNotificationRef = doc(notificationsRef);
        batch.set(newNotificationRef, {
          type: "connection_request",
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true,
        });

        await batch.commit();

        setConnectionStatus("Pending");
      } else if (hasReceivedRequest) {
        const batch = writeBatch(firestore);

        // Update connection status
        const now = new Date();
        batch.update(connectionRef, { status: "Connected", connectedAt: now });
        batch.update(recipientConnectionRef, {
          status: "Connected",
          connectedAt: now,
        });

        // Create a new notification for the current user (the one accepting the request)
        const currentUserNotificationRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const newCurrentUserNotificationRef = doc(currentUserNotificationRef);
        batch.set(newCurrentUserNotificationRef, {
          type: "connection_accepted",
          message: `${userData.firstName} ${userData.lastName} is now a connection.`,
          fromUserName: `${userData.firstName} ${userData.lastName}`,
          fromUserImage: userData.profileImage,
          from: userData.id,
          createdAt: new Date(),
          isNew: true,
        });

        // Create a notification for the other user
        const otherUserNotificationRef = collection(
          firestore,
          `users/${userData.id}/notifications`
        );
        const newOtherUserNotificationRef = doc(otherUserNotificationRef);
        batch.set(newOtherUserNotificationRef, {
          type: "connection_accepted",
          message: `${
            currentUser.displayName || "A user"
          } is now a connection.`,
          fromUserName: currentUser.displayName || "A user",
          fromUserImage: currentUser.photoURL || null,
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true,
        });

        // Delete the original connection request notification
        const requestNotificationsRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const q = query(
          requestNotificationsRef,
          where("type", "==", "connection_request"),
          where("from", "==", userData.id)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();

        setConnectionStatus("Connected");
        setConnectionType("Direct");
        setHasReceivedRequest(false);
        setConnectionCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      console.error("Error handling connection:", error);
    }
  };

  const handleRemoveConnection = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) {
      console.error("Current user or profile user ID is missing");
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      const batch = writeBatch(firestore);

      // Delete the connection documents
      batch.delete(connectionRef);
      batch.delete(recipientConnectionRef);

      // Remove the "is now a connection" notification for both users
      const currentUserNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const profileUserNotificationsRef = collection(
        firestore,
        `users/${userData.id}/notifications`
      );

      const [currentUserNotifications, profileUserNotifications] =
        await Promise.all([
          getDocs(
            query(
              currentUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", userData.id)
            )
          ),
          getDocs(
            query(
              profileUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", currentUser.uid)
            )
          ),
        ]);

      currentUserNotifications.forEach((doc) => batch.delete(doc.ref));
      profileUserNotifications.forEach((doc) => batch.delete(doc.ref));

      // Commit the batch
      await batch.commit();

      setConnectionStatus("Connect");
      setConnectionType("Extended");
      setConnectionCount((prevCount) => prevCount - 1);

      // Force a re-check of the connection status
      await checkConnectionStatus(currentUser.uid, userData.id);
    } catch (error) {
      console.error("Error removing connection:", error);
    }
  };

  const handleRemoveRequest = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) return;

    console.log(`Handling remove request for user: ${userData.id}`);

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      // Use a batch write to ensure all operations succeed or fail together
      const batch = writeBatch(firestore);

      batch.delete(connectionRef);
      batch.delete(recipientConnectionRef);

      // Delete notifications for both users
      const currentUserNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const profileUserNotificationsRef = collection(
        firestore,
        `users/${userData.id}/notifications`
      );

      const [currentUserNotifications, profileUserNotifications] =
        await Promise.all([
          getDocs(
            query(
              currentUserNotificationsRef,
              where("from", "in", [userData.id, currentUser.uid]),
              where("type", "in", ["connection_request", "connection_accepted"])
            )
          ),
          getDocs(
            query(
              profileUserNotificationsRef,
              where("from", "in", [userData.id, currentUser.uid]),
              where("type", "in", ["connection_request", "connection_accepted"])
            )
          ),
        ]);

      currentUserNotifications.forEach((doc) => {
        batch.delete(doc.ref);
      });

      profileUserNotifications.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();

      console.log("Successfully removed request and related documents");

      // Update local state
      setConnectionStatus("Connect");
      setHasReceivedRequest(false);
      setConnectionType("Extended");

      // Force a re-check of the connection status
      await checkConnectionStatus(currentUser.uid, userData.id);
    } catch (error) {
      console.error("Error removing request:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
  };

  const handleNavigate = (clickedUserId) => {
    setShowConnectionsModal(false);
    if (clickedUserId === currentUser?.uid) {
      // If the clicked user is the logged-in user, navigate to /profile
      window.location.href = "/profile";
    } else {
      // Otherwise, navigate to the user's profile
      window.location.href = `/user/${clickedUserId}`;
    }
  };

  const fetchMutualConnections = async (currentUserId, profileUserId) => {
    try {
      const currentUserConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const profileUserConnectionsRef = collection(
        firestore,
        `users/${profileUserId}/connections`
      );

      const currentUserConnectionsQuery = query(
        currentUserConnectionsRef,
        where("status", "==", "Connected")
      );
      const profileUserConnectionsQuery = query(
        profileUserConnectionsRef,
        where("status", "==", "Connected")
      );

      const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
        await Promise.all([
          getDocs(currentUserConnectionsQuery),
          getDocs(profileUserConnectionsQuery),
        ]);

      const currentUserConnections = new Set(
        currentUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );
      const profileUserConnections = new Set(
        profileUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );

      const mutualConnectionIds = [...currentUserConnections].filter((id) =>
        profileUserConnections.has(id)
      );

      const mutualConnectionsData = await Promise.all(
        mutualConnectionIds.map(async (userId) => {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          return {
            id: userId,
            ...userDoc.data(),
            profileImage: userDoc.data().profileImage,
          };
        })
      );

      setMutualConnections(mutualConnectionsData);
    } catch (error) {
      console.error("Error fetching mutual connections:", error);
      setMutualConnections([]);
    }
  };

  useEffect(() => {
    if (currentUser && userData.id) {
      checkConnectionStatus(currentUser.uid, userData.id);
    }
  }, [currentUser, userData.id]);

  const ReactionsSummary = ({ reactions, onClick }) => {
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);
    const reactionCount = Object.keys(reactions).length;

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div className={classes.reactionsSummary} onClick={onClick}>
        <div className={classes.reactionStack}>
          {topEmojis.reverse().map((emoji, index) => (
            <div
              key={index}
              className={classes.reactionEmoji}
              style={{ zIndex: topEmojis.length - index }}
            >
              {emoji}
            </div>
          ))}
        </div>
        <span className={classes.reactionCount}>{reactionCount}</span>
      </div>
    );
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  // First, remove/consolidate the multiple useEffects into a single one for activity

  // Remove these useEffects:
  // useEffect(() => {
  //   if (userData.id && activityType === "Posts") {
  //     fetchPosts();
  //   }
  // }, [userData.id, activityType, fetchPosts]);

  // useEffect(() => {
  //   if (!userData.id) return;
  //   const fetchData = async () => {
  //     setLoadingReactions(true);
  //     try {
  //       if (activityType === "Reactions") {
  //         await fetchReactions();
  //       } else if (activityType === "Comments") {
  //         await fetchComments();
  //       } else if (activityType === "Reposts") {
  //         await fetchReposts();
  //       } else if (activityType === "Posts") {
  //         await fetchPosts();
  //       }
  //     } finally {
  //       setLoadingReactions(false);
  //     }
  //   };
  //   fetchData();
  // }, [userData.id, activityType]);

  const [loadingStates, setLoadingStates] = useState({
    initial: true,
    activityContent: false,
  });

  // Add this helper function to manage loading states
  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (!userData.id || !activityType) return;

    const fetchActivityData = async () => {
      updateLoadingState("activityContent", true);

      try {
        switch (activityType) {
          case "Posts":
            await fetchPosts();
            break;
          case "Reactions":
            await fetchReactions();
            break;
          case "Comments":
            await fetchComments();
            break;
          case "Reposts":
            await fetchReposts();
            break;
        }
      } finally {
        updateLoadingState("activityContent", false);
      }
    };

    fetchActivityData();
  }, [userData.id, activityType]);

  const renderReposts = () => {
    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no reposts.
            </p>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <span className={classes.postMessage}>reposted this</span>
                      <span className={classes.dot}>•</span>
                      <span className={classes.postTimestamp}>
                        {formatTimestamp(repost.repostedAt)}
                      </span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost.postPreview.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost.postPreview.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {repost.originalPost.reactions &&
                      Object.keys(repost.originalPost.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={repost.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              repost.originalPost.id,
                              repost.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <PostInteractionCount
                      postId={repost.originalPost.id}
                      reposts={repost.originalPost.reposts}
                      commentCount={repost.originalPost.commentCount}
                      onClick={handleRepostsClick}
                      onCommentClick={() =>
                        handlePostClick(repost.originalPost.id)
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate(`/all-reposts/${userData.urlSlug}`)}
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const renderReactions = () => {
    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no reactions.
            </p>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <span className={classes.postMessage}>
                        {" "}
                        reacted{" "}
                        <span className={classes.textEmoji}>
                          {reaction.emoji}
                        </span>{" "}
                        to this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleReactionClick(reaction.id, reaction)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {reaction.originalPost?.reactions &&
                      Object.keys(reaction.originalPost.reactions).length >
                        0 && (
                        <ReactionsSummary
                          reactions={reaction.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              reaction.originalPost.id,
                              reaction.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <PostInteractionCount
                      postId={reaction.originalPost?.id}
                      reposts={reaction.originalPost?.reposts}
                      commentCount={reaction.originalPost?.commentCount}
                      onClick={handleRepostsClick}
                      onCommentClick={() =>
                        handlePostClick(reaction.originalPost?.id)
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reactions.length > 3 && displayedReactions < reactions.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate(`/all-reactions/${userData.urlSlug}`)}
          >
            Show all reactions
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const handleReactionClick = (reactionId, reaction) => {
    navigate(`/reaction/${reactionId}`, {
      state: {
        fromUserProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
        reactionEmoji: reaction?.emoji || "👍", // Provide a default emoji if not available
      },
    });
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromUserProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
        },
      },
    });
  };

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  useEffect(() => {
    if (showDropdown) {
      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);
    }
    return () => {
      window.removeEventListener("resize", positionDropdown);
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [showDropdown]);

  const sortedExperiences = useMemo(() => {
    if (!experiences) return [];
    return [...experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [experiences]);

  const sortedEducation = useMemo(() => {
    if (!education) return [];
    return [...education].sort((a, b) => {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = monthsList.indexOf(
        now.toLocaleString("default", { month: "long" })
      );

      const getEndDate = (edu) => {
        if (edu.isCurrentlyStudying) {
          return new Date(currentYear, currentMonth);
        }
        return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
      };

      const aEnd = getEndDate(a);
      const bEnd = getEndDate(b);

      if (aEnd > bEnd) return -1;
      if (aEnd < bEnd) return 1;

      const aStart = new Date(`${a.startMonth} 1, ${a.startYear}`);
      const bStart = new Date(`${b.startMonth} 1, ${b.startYear}`);

      if (aStart > bStart) return -1;
      if (aStart < bStart) return 1;

      return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
  }, [education]);

  const renderIntroCard = () => {
    if (!userData.introHtml) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Intro</div>
        <div className={classes.introText}>
          <pre>
            <span
              dangerouslySetInnerHTML={{ __html: renderIntroText().text }}
            />
            {renderIntroText().showMore && (
              <span className={classes.moreButton} onClick={handleMoreClick}>
                ...more
              </span>
            )}
          </pre>
        </div>
      </div>
    );
  };

  const renderStartupsCard = () => {
    if (!startups || startups.length === 0) return null;

    const handleStartupClick = (startup) => {
      if (startup.startupUrlSlug) {
        navigate(`/startup/${startup.startupUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Startups</div>
        <div>
          {sortedStartups
            .slice(0, showAllStartups ? sortedStartups.length : 3)
            .map((startup) => (
              <div key={startup.id} className={classes.startupContainer}>
                {startup.startupImage ? (
                  <img
                    src={startup.startupImage}
                    alt={startup.startupName || startupImage}
                    className={classes.startupImage}
                    onClick={() => handleStartupClick(startup)}
                    style={{ cursor: "pointer" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = startupImage;
                    }}
                  />
                ) : (
                  <div
                    className={classes.defaultImageTwo}
                    onClick={() => handleStartupClick(startup)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={startupImage}
                      alt="Default startup"
                      className={classes.startupImage}
                    />
                  </div>
                )}
                <div className={classes.startupAbout}>
                  <div
                    className={classes.startupRole}
                    onClick={() => handleStartupClick(startup)}
                    style={{ cursor: "pointer" }}
                  >
                    {startup.startupName}
                  </div>
                  {startup.bio && (
                    <div className={classes.startupName}>{startup.bio}</div>
                  )}
                  <div className={classes.startupDate}>
                    {startup.city && startup.country
                      ? `${startup.city}, ${startup.country}`
                      : "Location not specified"}
                  </div>
                  {startup.link && (
                    <div
                      className={classes.startupLink}
                      onClick={handleLinkClick}
                    >
                      <a
                        href={startup.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {startup.linkText || startup.link}
                      </a>
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangeRed"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.matchTally}>
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.382l1.447.723.005.003.027.013.12.056c.108.05.272.123.486.212.429.177 1.056.416 1.834.655C7.481 13.524 9.63 14 12 14c2.372 0 4.52-.475 6.08-.956.78-.24 1.406-.478 1.835-.655a14.028 14.028 0 0 0 .606-.268l.027-.013.005-.002L22 11.381V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.447 7.894.553-.276V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.382l.553.276.002.002.004.002.013.006.041.02.151.07c.13.06.318.144.557.242.478.198 1.163.46 2.01.72C7.019 15.476 9.37 16 12 16c2.628 0 4.98-.525 6.67-1.044a22.95 22.95 0 0 0 2.01-.72 15.994 15.994 0 0 0 .707-.312l.041-.02.013-.006.004-.002.001-.001-.431-.866.432.865ZM12 10a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {startup.role}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {startups.length > 3 && !showAllStartups && (
          <div
            className={classes.showAllExperiences}
            onClick={toggleShowAllStartups}
          >
            Show all startups
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const renderGroupsCard = () => {
    if (!userData.groups || userData.groups.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Groups</div>
        <div className={classes.cardAbout}>
          {/* Render groups content here */}
        </div>
      </div>
    );
  };

  const renderEventsCard = () => {
    if (!userData.events || userData.events.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Events</div>
        <div className={classes.cardAbout}>
          {/* Render events content here */}
        </div>
      </div>
    );
  };

  const renderDonationsCard = () => {
    if (!userData.donations || userData.donations.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Donations</div>
        <div className={classes.cardAbout}>
          {/* Render donations content here */}
        </div>
      </div>
    );
  };

  // Update the modal toggle functions
  const toggleConnectionsModal = () => {
    if (!isConnectionsModalOpen) {
      setConnectionsModalScrollPosition(window.scrollY);
      setIsConnectionsModalOpen(true);
      setShowConnectionsModal(true);
    } else {
      handleCloseConnectionsModal();
    }
  };

  const toggleMutualConnectionsModal = () => {
    if (!isMutualConnectionsModalOpen) {
      setMutualConnectionsModalScrollPosition(window.scrollY);
      setIsMutualConnectionsModalOpen(true);
      setShowMutualConnectionsModal(true);
    } else {
      handleCloseMutualConnectionsModal();
    }
  };

  const toggleReactionsModal = (postId, reactions) => {
    if (!showReactionsModal) {
      setReactionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, reactionsModalScrollPosition);
      }, 0);
    }
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(!showReactionsModal);
    setIsReactionsModalOpen(!isReactionsModalOpen);
  };

  const toggleRepostsModal = (postId, reposts) => {
    if (!showRepostsModal) {
      setRepostsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, repostsModalScrollPosition);
      }, 0);
    }
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(!showRepostsModal);
    setIsRepostsModalOpen(!isRepostsModalOpen);
  };

  const renderSkillsCard = () => {
    if (!userData.skills || userData.skills.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Skills</div>
        <div className={classes.cardAbout}>
          {userData.skills && userData.skills.length > 0 ? (
            userData.skills
              .slice(0, showAllSkills ? userData.skills.length : 9)
              .map((skill, index) => (
                <div key={index} className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="orangeRed"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                  </svg>
                  <span className={classes.skillText}>{skill}</span>
                </div>
              ))
          ) : (
            <>
              <span className={classes.userName}>{userData.firstName}</span> has
              no skills listed.
            </>
          )}
        </div>
        {userData.skills && userData.skills.length > 9 && !showAllSkills && (
          <div
            className={classes.showAllExperiences}
            onClick={toggleShowAllSkills}
          >
            Show all skills
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const renderInterestsCard = () => {
    if (!userData.interests || userData.interests.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Interests</div>
        <div className={classes.cardAbout}>
          {userData.interests && userData.interests.length > 0 ? (
            userData.interests
              .slice(0, showAllInterests ? userData.interests.length : 9)
              .map((interest, index) => (
                <div key={index} className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6 text-gray"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="orangeRed"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                  </svg>
                  <span className={classes.skillText}>{interest}</span>
                </div>
              ))
          ) : (
            <>
              <span className={classes.userName}>{userData.firstName}</span> has
              no Interests.
            </>
          )}
        </div>
        {userData.interests &&
          userData.interests.length > 9 &&
          !showAllInterests && (
            <div
              className={classes.showAllExperiences}
              onClick={toggleShowAllInterests}
            >
              Show all interests
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </div>
    );
  };

  const renderExperiencesCard = () => {
    if (!experiences || experiences.length === 0) return null;
    return (
      <div className={classes.experiencesCard}>
        <div className={classes.experiencesCardTitle}>Experience</div>
        <div className={classes.experiencesCardAbout}>
          {sortedExperiences
            .slice(0, showAllExperiences ? sortedExperiences.length : 3)
            .map((exp) => (
              <div key={exp.id} className={classes.experienceContainer}>
                <p className={classes.organisation}>{exp.organisation}</p>
                <p className={classes.role}>{exp.role}</p>
                <p className={classes.dates}>
                  {exp.startMonth} {exp.startYear} -{" "}
                  {exp.isCurrentlyWorking
                    ? "Present"
                    : `${exp.endMonth} ${exp.endYear}`}
                </p>
                {renderTruncatedText(
                  exp.description,
                  `exp-${exp.id}`,
                  exp.descriptionHtml // Pass the HTML content as third parameter
                )}
              </div>
            ))}
        </div>
        {sortedExperiences.length > 3 && !showAllExperiences && (
          <div
            className={classes.showAllExperiences}
            onClick={toggleShowAllExperiences}
          >
            Show all experiences
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const renderEducationCard = () => {
    if (!education || education.length === 0) return null;
    return (
      <div className={classes.educationCard}>
        <div className={classes.educationCardTitle}>Education</div>
        <div className={classes.educationCardAbout}>
          {sortedEducation
            .slice(0, showAllEducation ? sortedEducation.length : 3)
            .map((edu) => (
              <div key={edu.id} className={classes.educationContainer}>
                <p className={classes.school}>{edu.school}</p>
                <p className={classes.degree}>{edu.degree}</p>
                <p className={classes.subject}>{edu.subject}</p>
                <p className={classes.grade}>{edu.grade}</p>

                <p className={classes.dates}>
                  {edu.startMonth} {edu.startYear} -{" "}
                  {edu.isCurrentlyStudying
                    ? "Present"
                    : `${edu.endMonth} ${edu.endYear}`}
                </p>
                {renderTruncatedText(
                  edu.description,
                  `edu-${edu.id}`,
                  edu.descriptionHtml // Pass the HTML content as third parameter
                )}
              </div>
            ))}
        </div>
        {education.length > 3 && !showAllEducation && (
          <div
            className={classes.showAllExperiences}
            onClick={toggleShowAllEducation}
          >
            Show all education
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const fetchActivityCounts = useCallback(async () => {
    if (!userData.id) return;
    try {
      const userId = userData.id;
      const postsCount = await fetchPostsCount(userId);
      const reactionsCount = await fetchReactionsCount(userId);
      const repostsCount = await fetchRepostsCount(userId);
      const commentsCount = await fetchCommentsCount(userId);

      setActivityCounts({
        Posts: postsCount,
        Reactions: reactionsCount,
        Reposts: repostsCount,
        Comments: commentsCount,
      });

      // If no activity type is set yet, set it to the first non-zero count
      if (!activityType) {
        const firstActivityType = Object.entries({
          Posts: postsCount,
          Reactions: reactionsCount,
          Reposts: repostsCount,
          Comments: commentsCount,
        }).find(([_, count]) => count > 0)?.[0];

        if (firstActivityType) {
          setActivityType(firstActivityType);
        }
      }
    } catch (error) {
      console.error("Error fetching activity counts:", error);
    }
  }, [userData.id, activityType]);

  // Add this useEffect
  useEffect(() => {
    fetchActivityCounts();
  }, [fetchActivityCounts]);

  // Add this useEffect right after where you fetch activity counts
  // Add an initialization flag
  const [hasInitializedActivity, setHasInitializedActivity] = useState(false);

  // Modify the initial activity type useEffect
  useEffect(() => {
    if (activityCounts && !hasInitializedActivity) {
      const activityTypes = ["Posts", "Reactions", "Comments", "Reposts"];
      const firstActiveType = activityTypes.find(
        (type) => activityCounts[type] > 0
      );

      if (firstActiveType) {
        // Keep all other loading states true, only change activity type
        setActivityType(firstActiveType);
        setHasInitializedActivity(true);
      }
    }
  }, [activityCounts, hasInitializedActivity]);

  const renderActivityCard = () => {
    const totalActivityCount = Object.values(activityCounts).reduce(
      (sum, count) => sum + count,
      0
    );

    // For user profiles, we only hide the card if there's no activity
    if (totalActivityCount === 0) {
      return null;
    }

    return (
      <div className={classes.activityCard}>
        <div className={classes.activityCardTitle}>Activity</div>
        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={activityCounts}
          showAllButtons={false}
        />
        {renderActivityContent()}
      </div>
    );
  };

  const renderActivityContent = () => {
    if (loadingStates.activityContent) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    switch (activityType) {
      case "Posts":
        return posts.length > 0 ? (
          renderPosts()
        ) : (
          <p className={classes.white}>{userData.firstName} has no posts.</p>
        );
      case "Reactions":
        return reactions.length > 0 ? (
          renderReactions()
        ) : (
          <p className={classes.white}>
            {userData.firstName} has no reactions.
          </p>
        );
      case "Comments":
        return commentedPosts.length > 0 ? (
          renderComments()
        ) : (
          <p className={classes.white}>
            {userData.firstName} has no commented posts.
          </p>
        );
      case "Reposts":
        return reposts.length > 0 ? (
          renderReposts()
        ) : (
          <p className={classes.white}>{userData.firstName} has no reposts.</p>
        );
      default:
        return null;
    }
  };

  const renderPosts = () => {
    return (
      <>
        <div className={classes.posts}>
          {posts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no posts.
            </p>
          ) : (
            <>
              {posts.slice(0, displayedPosts).map((post, index) => (
                <React.Fragment key={post.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <div className={classes.postMetadata}>
                        <span className={classes.postMessage}>posted this</span>
                        <span className={classes.dot}>•</span>
                        <span className={classes.postTimestamp}>
                          {formatTimestamp(post.createdAt)}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !post.postPreview.mediaType && post.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handlePostClick(post.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {post.postPreview &&
                          post.postPreview.mediaType &&
                          renderFilePreview(post.postPreview)}
                        {post.postPreview && post.postPreview.text && (
                          <p className={classes.textPreview}>
                            {truncateText(post.postPreview.text, 3)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {post.reactions &&
                      Object.keys(post.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={post.reactions}
                          onClick={() =>
                            handleReactionsClick(post.id, post.reactions)
                          }
                        />
                      )}
                    <div className={classes.rightInteractions}>
                      <PostInteractionCount
                        postId={post.id}
                        reposts={post.reposts}
                        commentCount={post.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={handlePostClick}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {posts.length > 3 && displayedPosts < posts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() => navigate(`/all-posts/${userData.urlSlug}`)}
          >
            Show all posts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const fetchFollowingCount = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowingCount(userData.followingCount || 0);
      }
    } catch (error) {
      console.error("Error fetching following count:", error);
      setFollowingCount(0);
    }
  }, []);

  const fetchAllData = useCallback(async (userId) => {
    try {
      setIsLoading(true);

      // Fetch user data
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const data = userDoc.data();

        // Get user's owned startups
        const batch = [];
        const startupsSnapshot = await getDocs(
          collection(firestore, `users/${userId}/startups`)
        );
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          membershipType: "owner",
        }));
        batch.push(...startupsData);

        // Get startups where user is a team member
        const allUsersSnapshot = await getDocs(collection(firestore, "users"));
        const teamMemberPromises = allUsersSnapshot.docs.map(
          async (userDoc) => {
            const startupRef = collection(
              firestore,
              `users/${userDoc.id}/startups`
            );
            const startupDocs = await getDocs(startupRef);

            const startupTeamPromises = startupDocs.docs.map(
              async (startupDoc) => {
                const teamRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
                );
                const teamDoc = await getDoc(teamRef);

                if (teamDoc.exists() && teamDoc.data().status === "active") {
                  return {
                    id: startupDoc.id,
                    ownerId: userDoc.id,
                    ...startupDoc.data(),
                    membershipType: "member",
                    role: teamDoc.data().role,
                  };
                }
                return null;
              }
            );

            const resolvedStartups = await Promise.all(startupTeamPromises);
            return resolvedStartups.filter((startup) => startup !== null);
          }
        );

        const teamMemberStartups = (
          await Promise.all(teamMemberPromises)
        ).flat();
        batch.push(...teamMemberStartups);

        // Sort startups by date and membership type
        const sortedStartups = batch.sort((a, b) => {
          const aMonth = monthsList.indexOf(a.monthFounded);
          const bMonth = monthsList.indexOf(b.monthFounded);
          const aDate = new Date(a.yearFounded, aMonth);
          const bDate = new Date(b.yearFounded, bMonth);

          if (aDate.getTime() !== bDate.getTime()) {
            return bDate.getTime() - aDate.getTime();
          }

          if (a.membershipType !== b.membershipType) {
            return a.membershipType === "owner" ? -1 : 1;
          }

          return (
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
          );
        });

        setStartups(sortedStartups);

        // Count actual connections
        const connectionsRef = collection(
          firestore,
          `users/${userId}/connections`
        );
        const connectionsSnapshot = await getDocs(
          query(connectionsRef, where("status", "==", "Connected"))
        );
        setConnectionCount(connectionsSnapshot.size);

        // Set user data and following count
        setUserData((prevData) => ({
          ...prevData,
          id: userId,
          ...data,
        }));
        setFollowingCount(data.followingCount || 0);

        // Fetch education and experiences
        const educationRef = collection(firestore, `users/${userId}/education`);
        const educationSnapshot = await getDocs(educationRef);
        const educationData = educationSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEducation(educationData);

        const experiencesRef = collection(
          firestore,
          `users/${userId}/experiences`
        );
        const experiencesSnapshot = await getDocs(experiencesRef);
        const experiencesData = experiencesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExperiences(experiencesData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setHasAttemptedLoad(true);
    }
  }, []); // Empty dependency array since we're not using any external functions

  useEffect(() => {
    const fetchUserData = async () => {
      if (!location.state?.userData) {
        try {
          setIsLoading(true);
          setHasAttemptedLoad(false);

          const timeoutId = setTimeout(() => {
            setIsLoading(false);
            setHasAttemptedLoad(true);
          }, 10000);

          const q = query(
            collection(firestore, "users"),
            where("urlSlug", "==", urlSlug)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            await fetchAllData(userDoc.id);
          }

          clearTimeout(timeoutId);
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
          setHasAttemptedLoad(true);
        }
      } else {
        await fetchAllData(location.state.userData.objectID);
      }
    };

    fetchUserData();
  }, [urlSlug, location.state, fetchAllData]);

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  // In UserProfile.js
  const handleMessageClick = () => {
    navigate("/chats", {
      state: {
        otherUser: {
          id: userData.id,
          name: `${userData.firstName} ${userData.lastName}`,
          image: userData.profileImage || defaultImage,
          bio: userData.bio || "",
          connectionType,
        },
        startNewChat: true,
      },
      replace: true,
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        // Fetch current user's profile image
        getDoc(doc(firestore, "users", user.uid)).then((userDoc) => {
          if (userDoc.exists()) {
            setCurrentUserImage(userDoc.data().profileImage || defaultImage);
          }
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobile ? (
          <MobileNavbar userImage={currentUserImage} />
        ) : (
          <MainNavbar userImage={currentUserImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (hasTimedOut) {
    return (
      <div className={classes.page}>
        {isMobile ? (
          <MobileNavbar userImage={currentUserImage} />
        ) : (
          <MainNavbar userImage={currentUserImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const toggleFollowModal = () => {
    if (!showFollowModal) {
      setFollowModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, followModalScrollPosition);
      }, 0);
    }
    setShowFollowModal(!showFollowModal);
    setIsFollowModalOpen(!isFollowModalOpen);
  };

  return (
    <div
      className={`${classes.page} ${
        isConnectionsModalOpen ||
        isMutualConnectionsModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isFollowModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isConnectionsModalOpen
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isMutualConnectionsModalOpen
          ? { top: `-${mutualConnectionsModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isFollowModalOpen // Add this line
          ? { top: `-${followModalScrollPosition}px` } // Add this line
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserImage} />
      ) : (
        <MainNavbar userImage={currentUserImage} />
      )}
      <div className={classes.content}>
        {showConnectionsModal && (
          <ConnectionsModal
            onClose={handleCloseConnectionsModal}
            currentUserId={userData.id}
            loggedInUserId={currentUser?.uid}
            isOwnProfile={false}
            showConnectionDate={false}
          />
        )}
        {showFollowModal && (
          <FollowModal
            onClose={() => {
              toggleFollowModal();
            }}
            currentUserId={userData.id}
            onUnfollowed={() => {
              fetchFollowingCount(userData.id);
              toggleFollowModal();
            }}
            showDropdown={false}
          />
        )}
        {showMutualConnectionsModal && (
          <MutualConnectionsModal
            onClose={handleCloseMutualConnectionsModal}
            currentUserId={userData.id}
            loggedInUserId={currentUser?.uid}
          />
        )}
        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={handleCloseReactionsModal}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser.uid}
          />
        )}
        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={handleCloseRepostsModal}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser.uid}
          />
        )}

        <div className={classes.centreCards}>
          {isLoading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : hasAttemptedLoad &&
            (!userData || Object.keys(userData).length === 0) ? (
            <NoContentCard onRefresh={() => window.location.reload()} />
          ) : (
            <>
              {/* Existing profile card and other content */}
              <div className={classes.profileCard}>
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={userImage || defaultImage} alt="Profile" />
                  </div>
                  <div
                    onClick={() => setShowDropdown(!showDropdown)}
                    className={classes.optionsButton}
                    ref={optionsButtonRef}
                  >
                    <svg
                      className={`${classes.closeIcon} ${
                        showDropdown ? classes.closeIconActive : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        stroke-linecap="round"
                        stroke-width="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01"
                      />
                    </svg>
                  </div>
                </div>
                {showDropdown && (
                  <div ref={dropdownRef} className={classes.optionsDropdown}>
                    <OptionsDropdown />
                  </div>
                )}

                <div className={classes.profileTitle}>
                  {userData.firstName} {userData.lastName}
                  {connectionType !== "You" && (
                    <span className={classes.connectionType}>
                      • {connectionType}
                    </span>
                  )}
                </div>

                <div>
                  <div className={classes.bio}>{userData.bio}</div>
                  <div className={classes.location}>
                    {userData.city}, {userData.country}{" "}
                  </div>
                  {userData.link && (
                    <div className={classes.link}>
                      <a
                        href={userData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userData.linkText || userData.link}
                      </a>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="orangeRed"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  )}
                  <div className={classes.data}>
                    <span
                      className={classes.numbers}
                      onClick={toggleConnectionsModal}
                      style={{ cursor: "pointer" }}
                    >
                      {connectionCount}{" "}
                      {connectionCount === 1 ? "Connection" : "Connections"}
                    </span>
                    <span className={classes.dot}>•</span>
                    <span
                      className={classes.numbers}
                      onClick={toggleFollowModal} // This line is already correct if it exists
                      style={{ cursor: "pointer" }}
                    >
                      {followingCount} Following
                    </span>
                  </div>
                  {mutualConnections.length > 0 && (
                    <div
                      className={classes.mutualConnections}
                      onClick={toggleMutualConnectionsModal}
                    >
                      <div
                        className={`${classes.mutualConnectionsImages} ${
                          mutualConnections.length === 1
                            ? classes.singleImage
                            : classes.multipleImages
                        }`}
                      >
                        {mutualConnections.length >= 1 && (
                          <img
                            src={mutualConnections[0].profileImage}
                            alt=""
                            className={classes.mutualConnectionImage}
                          />
                        )}
                        {mutualConnections.length >= 2 && (
                          <img
                            src={mutualConnections[1].profileImage}
                            alt=""
                            className={`${classes.mutualConnectionImage} ${classes.secondImage}`}
                          />
                        )}
                      </div>
                      <p className={classes.mutualConnectionText}>
                        {mutualConnections.length === 1 && (
                          <>
                            <span
                              className={`${classes.boldName} ${classes.firstUsername} ${classes.singleUsername}`}
                            >
                              {mutualConnections[0].firstName}{" "}
                              {mutualConnections[0].lastName}
                            </span>{" "}
                            is a mutual connection
                          </>
                        )}
                        {mutualConnections.length === 2 && (
                          <>
                            <span
                              className={`${classes.boldName} ${classes.firstUsername}`}
                            >
                              {mutualConnections[0].firstName}{" "}
                              {mutualConnections[0].lastName}
                            </span>{" "}
                            and{" "}
                            <span
                              className={`${classes.boldName} ${classes.secondUsername}`}
                            >
                              {mutualConnections[1].firstName}{" "}
                              {mutualConnections[1].lastName}
                            </span>{" "}
                            are mutual connections
                          </>
                        )}
                        {mutualConnections.length > 2 && (
                          <>
                            <span
                              className={`${classes.boldName} ${classes.firstUsername}`}
                            >
                              {mutualConnections[0].firstName}{" "}
                              {mutualConnections[0].lastName}
                            </span>
                            ,{" "}
                            <span
                              className={`${classes.boldName} ${classes.secondUsername}`}
                            >
                              {mutualConnections[1].firstName}{" "}
                              {mutualConnections[1].lastName}
                            </span>
                            , and{" "}
                            <span className={classes.mutualConnectionNumber}>
                              {mutualConnections.length - 2}
                            </span>{" "}
                            other mutual connection
                            {mutualConnections.length > 3 ? "s" : ""}
                          </>
                        )}
                      </p>
                    </div>
                  )}
                  <div className={classes.buttons}>
                    <button
                      className={
                        connectionStatus === "Connected"
                          ? classes.pendingButton
                          : connectionStatus === "Pending"
                          ? classes.pendingButton
                          : hasReceivedRequest
                          ? classes.connectButton
                          : classes.connectButton
                      }
                      onClick={
                        connectionStatus === "Connected"
                          ? handleRemoveConnection
                          : connectionStatus === "Pending"
                          ? handleRemoveRequest
                          : handleConnect
                      }
                    >
                      <span>
                        {connectionStatus === "Connected" ? (
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        ) : connectionStatus === "Pending" ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : hasReceivedRequest ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                              clip-rule="evenodd"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                      {connectionStatus === "Connected"
                        ? "Connected"
                        : connectionStatus === "Pending"
                        ? "Pending"
                        : hasReceivedRequest
                        ? "Accept"
                        : "Connect"}
                    </button>
                    <button
                      className={`${classes.messageButton} ${
                        connectionType === "Direct"
                          ? classes.directMessageButton
                          : ""
                      }`}
                      onClick={handleMessageClick}
                    >
                      <span>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 5.983C3 4.888 3.895 4 5 4h14c1.105 0 2 .888 2 1.983v8.923a1.992 1.992 0 0 1-2 1.983h-6.6l-2.867 2.7c-.955.899-2.533.228-2.533-1.08v-1.62H5c-1.105 0-2-.888-2-1.983V5.983Zm5.706 3.809a1 1 0 1 0-1.412 1.417 1 1 0 1 0 1.412-1.417Zm2.585.002a1 1 0 1 1 .003 1.414 1 1 0 0 1-.003-1.414Zm5.415-.002a1 1 0 1 0-1.412 1.417 1 1 0 1 0 1.412-1.417Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      Message
                    </button>
                  </div>
                </div>
              </div>
              {renderIntroCard()}
              {renderActivityCard()}
              {renderStartupsCard()}
            </>
          )}
        </div>
        <div className={classes.rightCards}>
          {renderGroupsCard()}
          {renderEventsCard()}
          {renderDonationsCard()}
          {renderSkillsCard()}
          {renderInterestsCard()}
          {renderExperiencesCard()}
          {renderEducationCard()}
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
