import React, { useState, useEffect, useMemo } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./MutualConnectionsModal.module.css";

const StartupInteractionModal = ({
  onClose,
  currentUser,
  onStartupSelect,
  selectedStartup,
  currentUserProfileImage,
}) => {
  const [userStartups, setUserStartups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Get user's full name with proper validation
  const userFullName = useMemo(() => {
    if (!currentUser) return "";
    // Make sure we're using the actual user data, not startup data
    const firstName = currentUser.firstName || "";
    const lastName = currentUser.lastName || "";
    if (!firstName && !lastName) return currentUser.email || ""; // Fallback to email if no name
    return `${firstName} ${lastName}`.trim();
  }, [currentUser]);

  useEffect(() => {
    const fetchUserStartups = async () => {
      if (!currentUser?.uid) return;
      try {
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Ensure we're only storing startup data
        const validStartups = startupsData.filter(
          (startup) => startup.startupName && startup.id
        );
        setUserStartups(validStartups);
      } catch (error) {
        console.error("Error fetching startups:", error);
      }
    };

    fetchUserStartups();
  }, [currentUser]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStartups = useMemo(() => {
    if (!searchTerm.trim()) return userStartups;

    const searchTermLower = searchTerm.toLowerCase();
    return userStartups.filter((startup) => {
      const startupName = (startup.startupName || "").toLowerCase();
      return startupName.includes(searchTermLower);
    });
  }, [userStartups, searchTerm]);

  // Only show personal account if search term matches user's name or is empty
  const showPersonalAccount = useMemo(() => {
    if (!searchTerm.trim()) return true;
    const searchTermLower = searchTerm.toLowerCase();
    const userNameLower = userFullName.toLowerCase();
    return userNameLower.includes(searchTermLower);
  }, [searchTerm, userFullName]);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleSelect = (startup = null) => {
    if (startup) {
      onStartupSelect({
        id: startup.id,
        startupName: startup.startupName,
        startupImage: startup.startupImage,
        bio: startup.bio || "",
        link: startup.link || null,
        linkText: startup.linkText || null,
        startupUrlSlug: startup.startupUrlSlug,
      });
    } else {
      onStartupSelect(null);
    }
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Switch profile
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search profiles"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {/* Personal Account Option */}
            {showPersonalAccount && (
              <div className={classes.connectionItem}>
                <img
                  src={currentUserProfileImage}
                  alt="Personal profile"
                  className={classes.profileImage}
                  onClick={() => handleSelect()}
                />
                <div className={classes.connectionInfo}>
                  <h3 className={classes.name}>
                    <div className={classes.nameWrapper}>
                      <span
                        className={classes.nameText}
                        onClick={() => handleSelect()}
                      >
                        {userFullName}
                      </span>
                      <span className={classes.connectionType}>• You</span>
                    </div>
                  </h3>
                  {currentUser?.bio && (
                    <p className={classes.bio}>{currentUser.bio}</p>
                  )}
                </div>
              </div>
            )}

            {/* Startups List */}
            {filteredStartups.map((startup) => (
              <div key={startup.id} className={classes.connectionItem}>
                <img
                  src={startup.startupImage}
                  alt={startup.startupName}
                  className={classes.profileImage}
                  onClick={() => handleSelect(startup)}
                />
                <div className={classes.connectionInfo}>
                  <h3 className={classes.name}>
                    <div className={classes.nameWrapper}>
                      <span
                        className={classes.nameText}
                        onClick={() => handleSelect(startup)}
                      >
                        {startup.startupName}
                      </span>
                      <span className={classes.connectionType}>• Startup</span>
                    </div>
                  </h3>
                  {startup.bio && <p className={classes.bio}>{startup.bio}</p>}
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default StartupInteractionModal;
