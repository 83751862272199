import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./MainNavbar.module.css";
import icon from "../../assets/images/foundry.png";
import rounded from "../../assets/icons/rounded.png";
import defaultImage from "../../assets/icons/profileImage.jpg";
import { firestore, auth } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNotification } from "../../contexts/NotificationContext";
import { useChat } from "../../contexts/ChatContext";

const DashboardInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
    />
  </svg>
);

const NotificationsInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m10.827 5.465-.435-2.324m.435 2.324a5.338 5.338 0 0 1 6.033 4.333l.331 1.769c.44 2.345 2.383 2.588 2.6 3.761.11.586.22 1.171-.31 1.271l-12.7 2.377c-.529.099-.639-.488-.749-1.074C5.813 16.73 7.538 15.8 7.1 13.455c-.219-1.169.218 1.162-.33-1.769a5.338 5.338 0 0 1 4.058-6.221Zm-7.046 4.41c.143-1.877.822-3.461 2.086-4.856m2.646 13.633a3.472 3.472 0 0 0 6.728-.777l.09-.5-6.818 1.277Z"
    />
  </svg>
);

const MessagesInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"
    />
  </svg>
);

const PeopleInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeWidth="2"
      d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);

const ForumsInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeWidth="2"
      d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
    />
  </svg>
);

const EventsInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"
    />
  </svg>
);

const SettingsInactiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
    />
    <path
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);

const DashboardHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
      clipRule="evenodd"
    />
  </svg>
);

const NotificationsHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path d="M11.209 3.816a1 1 0 0 0-1.966.368l.325 1.74a5.338 5.338 0 0 0-2.8 5.762l.276 1.473.055.296c.258 1.374-.228 2.262-.63 2.998-.285.52-.527.964-.437 1.449.11.586.22 1.173.75 1.074l12.7-2.377c.528-.1.418-.685.308-1.27-.103-.564-.636-1.123-1.195-1.711-.606-.636-1.243-1.306-1.404-2.051-.233-1.085-.275-1.387-.303-1.587-.009-.063-.016-.117-.028-.182a5.338 5.338 0 0 0-5.353-4.39l-.298-1.592Z" />
    <path
      fillRule="evenodd"
      d="M6.539 4.278a1 1 0 0 1 .07 1.412c-1.115 1.23-1.705 2.605-1.83 4.26a1 1 0 0 1-1.995-.15c.16-2.099.929-3.893 2.342-5.453a1 1 0 0 1 1.413-.069Z"
      clipRule="evenodd"
    />
    <path d="M8.95 19.7c.7.8 1.7 1.3 2.8 1.3 1.6 0 2.9-1.1 3.3-2.5l-6.1 1.2Z" />
  </svg>
);

const MessagesHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M4 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h1v2a1 1 0 0 0 1.707.707L9.414 13H15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      d="M8.023 17.215c.033-.03.066-.062.098-.094L10.243 15H15a3 3 0 0 0 3-3V8h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-1v2a1 1 0 0 1-1.707.707L14.586 18H9a1 1 0 0 1-.977-.785Z"
      clip-rule="evenodd"
    />
  </svg>
);

const PeopleHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
      clip-rule="evenodd"
    />
  </svg>
);

const ForumsHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
      clip-rule="evenodd"
    />
  </svg>
);

const EventsHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clip-rule="evenodd"
    />
  </svg>
);

const SettingsHoverIcon = () => (
  <svg
    className="w-6 h-6 text-gray"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="gray"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M9.586 2.586A2 2 0 0 1 11 2h2a2 2 0 0 1 2 2v.089l.473.196.063-.063a2.002 2.002 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.827l-.063.064.196.473H20a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-.089l-.196.473.063.063a2.002 2.002 0 0 1 0 2.828l-1.414 1.414a2 2 0 0 1-2.828 0l-.063-.063-.473.196V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.089l-.473-.196-.063.063a2.002 2.002 0 0 1-2.828 0l-1.414-1.414a2 2 0 0 1 0-2.827l.063-.064L4.089 15H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h.09l.195-.473-.063-.063a2 2 0 0 1 0-2.828l1.414-1.414a2 2 0 0 1 2.827 0l.064.063L9 4.089V4a2 2 0 0 1 .586-1.414ZM8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clip-rule="evenodd"
    />
  </svg>
);

const DashboardActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangered"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangered"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
      clipRule="evenodd"
    />
  </svg>
);

const NotificationsActiveIcon = () => (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangeRed"
    viewBox="0 0 24 24"
  >
    <path d="M11.209 3.816a1 1 0 0 0-1.966.368l.325 1.74a5.338 5.338 0 0 0-2.8 5.762l.276 1.473.055.296c.258 1.374-.228 2.262-.63 2.998-.285.52-.527.964-.437 1.449.11.586.22 1.173.75 1.074l12.7-2.377c.528-.1.418-.685.308-1.27-.103-.564-.636-1.123-1.195-1.711-.606-.636-1.243-1.306-1.404-2.051-.233-1.085-.275-1.387-.303-1.587-.009-.063-.016-.117-.028-.182a5.338 5.338 0 0 0-5.353-4.39l-.298-1.592Z" />
    <path
      fillRule="evenodd"
      d="M6.539 4.278a1 1 0 0 1 .07 1.412c-1.115 1.23-1.705 2.605-1.83 4.26a1 1 0 0 1-1.995-.15c.16-2.099.929-3.893 2.342-5.453a1 1 0 0 1 1.413-.069Z"
      clipRule="evenodd"
    />
    <path d="M8.95 19.7c.7.8 1.7 1.3 2.8 1.3 1.6 0 2.9-1.1 3.3-2.5l-6.1 1.2Z" />
  </svg>
);

const MessagesActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangered"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangered"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M4 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h1v2a1 1 0 0 0 1.707.707L9.414 13H15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      d="M8.023 17.215c.033-.03.066-.062.098-.094L10.243 15H15a3 3 0 0 0 3-3V8h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-1v2a1 1 0 0 1-1.707.707L14.586 18H9a1 1 0 0 1-.977-.785Z"
      clip-rule="evenodd"
    />
  </svg>
);

const PeopleActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangered"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangered"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
      clip-rule="evenodd"
    />
  </svg>
);

const ForumsActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangeRed"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangeRed"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
      clip-rule="evenodd"
    />
  </svg>
);

const EventsActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangeRed"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangeRed"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clip-rule="evenodd"
    />
  </svg>
);

const SettingsActiveIcon = () => (
  <svg
    className="w-6 h-6 text-orangeRed"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="orangeRed"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M9.586 2.586A2 2 0 0 1 11 2h2a2 2 0 0 1 2 2v.089l.473.196.063-.063a2.002 2.002 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.827l-.063.064.196.473H20a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-.089l-.196.473.063.063a2.002 2.002 0 0 1 0 2.828l-1.414 1.414a2 2 0 0 1-2.828 0l-.063-.063-.473.196V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.089l-.473-.196-.063.063a2.002 2.002 0 0 1-2.828 0l-1.414-1.414a2 2 0 0 1 0-2.827l.063-.064L4.089 15H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h.09l.195-.473-.063-.063a2 2 0 0 1 0-2.828l1.414-1.414a2 2 0 0 1 2.827 0l.064.063L9 4.089V4a2 2 0 0 1 .586-1.414ZM8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clip-rule="evenodd"
    />
  </svg>
);

function MainNavbar({ userImage }) {
  const {
    state: { count },
  } = useNotification();
  const { unreadChatsCount } = useChat();
  console.log("Unread chats count:", unreadChatsCount); // Add this debug log
  const [isDashboardHovered, setIsDashboardHovered] = useState(false);
  const [isNotificationsHovered, setIsNotificationsHovered] = useState(false);
  const [isMessagesHovered, setIsMessagesHovered] = useState(false);
  const [isPeopleHovered, setIsPeopleHovered] = useState(false);
  const [isForumsHovered, setIsForumsHovered] = useState(false);
  const [isEventsHovered, setIsEventsHovered] = useState(false);
  const [isSettingsHovered, setIsSettingsHovered] = useState(false);

  const [isDashboardActive, setIsDashboardActive] = useState(false);
  const [isNotificationsActive, setIsNotificationsActive] = useState(false);
  const [isMessagesActive, setIsMessagesActive] = useState(false);

  const [isPeopleActive, setIsPeopleActive] = useState(false);
  const [isForumsActive, setIsForumsActive] = useState(false);
  const [isEventsActive, setIsEventsActive] = useState(false);
  const [isSettingsActive, setIsSettingsActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsDashboardActive(location.pathname === "/dashboard");
    setIsNotificationsActive(location.pathname === "/notifications");
    setIsMessagesActive(location.pathname === "/chats");

    setIsPeopleActive(location.pathname === "/people");
    setIsForumsActive(location.pathname === "/startupsandcommunities");
    setIsEventsActive(location.pathname === "/events");
    setIsSettingsActive(location.pathname === "/settings");
  }, [location.pathname]);

  console.log(
    "MainNavbar render - unreadChatsCount:",
    unreadChatsCount,
    typeof unreadChatsCount
  );

  console.log("MainNavbar - unreadChatsCount received:", unreadChatsCount);

  const handleDashboardMouseEnter = () => {
    setIsDashboardHovered(true);
  };

  const handleDashboardMouseLeave = () => {
    setIsDashboardHovered(false);
  };

  const handleNotificationsMouseEnter = () => {
    setIsNotificationsHovered(true);
  };

  const handleNotificationsMouseLeave = () => {
    setIsNotificationsHovered(false);
  };

  const handleMessagesMouseEnter = () => {
    setIsMessagesHovered(true);
  };

  const handleMessagesMouseLeave = () => {
    setIsMessagesHovered(false);
  };

  const handlePeopleMouseEnter = () => {
    setIsPeopleHovered(true);
  };

  const handlePeopleMouseLeave = () => {
    setIsPeopleHovered(false);
  };

  const handleForumsMouseEnter = () => {
    setIsForumsHovered(true);
  };

  const handleForumsMouseLeave = () => {
    setIsForumsHovered(false);
  };

  const handleEventsMouseEnter = () => {
    setIsEventsHovered(true);
  };

  const handleEventsMouseLeave = () => {
    setIsEventsHovered(false);
  };

  const handleSettingsMouseEnter = () => {
    setIsSettingsHovered(true);
  };

  const handleSettingsMouseLeave = () => {
    setIsSettingsHovered(false);
  };

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
    urlSlug: "", // Add urlSlug to track the user's unique identifier
  });

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const [currentUser, setCurrentUser] = useState(null);

  // New useEffect to update userData when userImage prop changes
  useEffect(() => {
    if (userImage) {
      setUserData((prevData) => ({
        ...prevData,
        profileImage: userImage,
      }));
    }
  }, [userImage]);

  const MessageIconWithCount = () => {
    const { unreadChatsCount } = useChat();
    console.log("MessageIconWithCount render, count:", unreadChatsCount); // Debug log

    return (
      <div className={classes.notificationIconWrapper}>
        {isMessagesActive ? (
          <MessagesActiveIcon />
        ) : isMessagesHovered ? (
          <MessagesHoverIcon />
        ) : (
          <MessagesInactiveIcon />
        )}
        {unreadChatsCount > 0 && (
          <span className={classes.notificationCount}>{unreadChatsCount}</span>
        )}
      </div>
    );
  };

  return (
    <nav className={classes.nav}>
      <div className={classes.navItems}>
        <img src={icon} alt="" className={classes.icon} />
      </div>
      <div className={classes.centreIcons}>
        <Link
          to="/dashboard"
          onMouseEnter={handleDashboardMouseEnter}
          onMouseLeave={handleDashboardMouseLeave}
        >
          {isDashboardActive ? (
            <DashboardActiveIcon />
          ) : isDashboardHovered ? (
            <DashboardHoverIcon />
          ) : (
            <DashboardInactiveIcon />
          )}
        </Link>
        <Link
          to="/notifications"
          onMouseEnter={handleNotificationsMouseEnter}
          onMouseLeave={handleNotificationsMouseLeave}
        >
          <div className={classes.notificationIconWrapper}>
            {isNotificationsActive ? (
              <NotificationsActiveIcon />
            ) : isNotificationsHovered ? (
              <NotificationsHoverIcon />
            ) : (
              <NotificationsInactiveIcon />
            )}
            {count > 0 && (
              <span className={classes.notificationCount}>{count}</span>
            )}
          </div>
        </Link>
        <Link
          to="/chats"
          onMouseEnter={handleMessagesMouseEnter}
          onMouseLeave={handleMessagesMouseLeave}
        >
          <div className={classes.notificationIconWrapper}>
            {console.log(
              "About to check unreadChatsCount condition:",
              unreadChatsCount
            )}
            {isMessagesActive ? (
              <MessagesActiveIcon />
            ) : isMessagesHovered ? (
              <MessagesHoverIcon />
            ) : (
              <MessagesInactiveIcon />
            )}
            {unreadChatsCount > 0
              ? console.log("Condition true, rendering count") || (
                  <span className={classes.notificationCount}>
                    {unreadChatsCount}
                  </span>
                )
              : console.log("Condition false, not rendering count")}
          </div>
        </Link>
        <Link
          to={`/${userData.urlSlug}`} // Changed from "/profile" to use the user's urlSlug
          className={classes.profileIcon}
        >
          <img
            src={userData.profileImage || userImage || defaultImage}
            alt="Profile"
          />
        </Link>
        <Link
          to="/people"
          onMouseEnter={handlePeopleMouseEnter}
          onMouseLeave={handlePeopleMouseLeave}
        >
          {isPeopleActive ? (
            <PeopleActiveIcon />
          ) : isPeopleHovered ? (
            <PeopleHoverIcon />
          ) : (
            <PeopleInactiveIcon />
          )}
        </Link>
        <Link
          to="/startupsandcommunities"
          onMouseEnter={handleForumsMouseEnter}
          onMouseLeave={handleForumsMouseLeave}
        >
          {isForumsActive ? (
            <ForumsActiveIcon />
          ) : isForumsHovered ? (
            <ForumsHoverIcon />
          ) : (
            <ForumsInactiveIcon />
          )}
        </Link>
        <Link
          to="/events"
          onMouseEnter={handleEventsMouseEnter}
          onMouseLeave={handleEventsMouseLeave}
        >
          {isEventsActive ? (
            <EventsActiveIcon />
          ) : isEventsHovered ? (
            <EventsHoverIcon />
          ) : (
            <EventsInactiveIcon />
          )}
        </Link>
      </div>
      <Link
        to="/settings"
        onMouseEnter={handleSettingsMouseEnter}
        onMouseLeave={handleSettingsMouseLeave}
      >
        {isSettingsActive ? (
          <SettingsActiveIcon />
        ) : isSettingsHovered ? (
          <SettingsHoverIcon />
        ) : (
          <SettingsInactiveIcon />
        )}
      </Link>
    </nav>
  );
}

export default MainNavbar;
