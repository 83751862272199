import React, { useState } from "react";
import { waitlist } from "../../utils/waitlistForm";
import illustrationOne from "../../assets/illustrations/illustration.png";
import classes from "./Header.module.css";

function Content() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSignUp = (e) => {
    e.preventDefault();
    waitlist(email, setSuccess, setError);
  };

  return (
    <div className={classes.header}>
      <div className={classes.headerAbout}>
        <h1 className={classes.title1}>One Space.</h1>
        <h1 className={classes.title}>For Startups.</h1>
        <p className={classes.paragraph}>
          The all-in-one platform for the startup community.
        </p>
        <p className={classes.text}>Make ideas happen.</p>
        <form onSubmit={handleSignUp} className={classes.interactions}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.email}
          />
          <button type="submit" className={classes.button}>
            Join waitlist
          </button>
        </form>
        {success === true && (
          <p className={classes.success}>
            <div className={classes.message}>
              <div className={classes.icons}>
                <span class="material-symbols-outlined">check_circle</span>
              </div>
              You're now on the waitlist!
            </div>
          </p>
        )}
        {error && (
          <p className={classes.error}>
            <div className={classes.message}>
              <div className={classes.icons}>
                <span class="material-symbols-outlined">error</span>
              </div>
              {error}
            </div>
          </p>
        )}
      </div>
      <img src={illustrationOne} alt="" className={classes.illustrationOne} />
    </div>
  );
}

export default Content;
