import React, { useState, useRef, useEffect } from "react";
import classes from "./SubjectDropdown.module.css";

const SubjectDropdown = ({
  label,
  selectedSubject,
  onSubjectChange,
  onInputChange,
  disabled,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(selectedSubject ? selectedSubject.name : "");
  }, [selectedSubject]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 50) {
      setInputValue(newValue);
      onInputChange(newValue);
      onSubjectChange({ name: newValue, id: newValue });
    }
  };

  return (
    <div className={classes.customDropdown}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.dropdownContainer}>
        <input
          ref={inputRef}
          className={`${classes.dropdownHeader} ${
            disabled ? classes.disabled : ""
          }`}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          disabled={disabled}
          maxLength={50}
        />
      </div>
      <div className={classes.charCount}>{inputValue.length}/50</div>
    </div>
  );
};

export default SubjectDropdown;
