import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import AddChatModal from "../../../components/Modals/AddChatModal";
import { useLocation } from "react-router-dom";
import { firestore, auth } from "../../../firebase";
import imageCompression from "browser-image-compression";
import {
  collection,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  writeBatch,
  onSnapshot,
  updateDoc,
  arrayUnion,
  increment,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import classes from "./Chats.module.css";
import { convertUrlsToLinks } from "../../../utils/textUtils";

const Chats = () => {
  const [isSending, setIsSending] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };
  const [showAddChatModal, setShowAddChatModal] = useState(false);
  const [addChatModalScrollPosition, setAddChatModalScrollPosition] =
    useState(0);
  const [isAddChatModalOpen, setIsAddChatModalOpen] = useState(false);
  const [videoVolume, setVideoVolume] = useState(false);
  const videoRef = useRef(null);
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const [userProfiles, setUserProfiles] = useState({});
  const [visibleChats, setVisibleChats] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [multipleImages, setMultipleImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const [loadingStates, setLoadingStates] = useState({
    userData: true,
    chats: true,
    messages: true,
    profiles: false,
  });

  const toggleAddChatModal = () => {
    if (!showAddChatModal) {
      setAddChatModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addChatModalScrollPosition);
      }, 0);
    }
    setShowAddChatModal(!showAddChatModal);
    setIsAddChatModalOpen(!isAddChatModalOpen);
  };

  const FilePreview = ({
    fileType,
    filePreview,
    fileName,
    isUploading,
    multipleImages,
    currentImageIndex,
    handleImageChange,
    videoThumbnail,
    onRemove,
  }) => {
    if (isUploading) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    switch (fileType) {
      case "image":
        return (
          <div className={classes.pdfPreview}>
            <div className={classes.pdfBox}>
              <img
                src={multipleImages[currentImageIndex]}
                alt=""
                className={classes.previewImage}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {Array.isArray(fileName)
                    ? fileName[currentImageIndex]
                    : fileName}
                </div>
                <div className={classes.open}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Image ready to send
                </div>
              </div>

              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 24 24"
                onClick={onRemove}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes.pdfPreview}>
            <div className={classes.pdfBox}>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>{fileName}</div>
                <div className={classes.open}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Video viewable once posted.
                </div>
              </div>

              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 24 24"
                onClick={onRemove}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </div>
          </div>
        );

      case "pdf":
        return (
          <div className={classes.pdfPreview}>
            <div className={classes.pdfBox}>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>{fileName}</div>
                <div className={classes.open}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  PDF viewable once posted.
                </div>
              </div>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 24 24"
                onClick={onRemove}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const ChatMessage = ({
    message,
    currentUser,
    userProfiles,
    handleImageChange,
    currentImageIndex,
    videoVolume,
    setVideoVolume,
    videoRef,
    selectedChat,
    firestore,
  }) => {
    const [showDeleteMenu, setShowDeleteMenu] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
      if (!currentUser || message.senderId !== currentUser.uid || isDeleting)
        return;

      setIsDeleting(true);
      try {
        const storage = getStorage();
        const messageRef = doc(
          firestore,
          `chats/${selectedChat.id}/messages/${message.id}`
        );

        // Handle file deletions if message has content
        if (message.content) {
          const deletePromises = [];

          if (message.content.type === "image" && message.content.urls) {
            // Handle multiple images
            message.content.urls.forEach((url) => {
              const fileRef = ref(storage, url);
              deletePromises.push(deleteObject(fileRef));
            });
          } else if (message.content.url) {
            // Handle single file (video, PDF, or single image)
            const fileRef = ref(storage, message.content.url);
            deletePromises.push(deleteObject(fileRef));

            // Delete video thumbnail if exists
            if (message.content.thumbnail) {
              const thumbnailRef = ref(storage, message.content.thumbnail);
              deletePromises.push(deleteObject(thumbnailRef));
            }
          }

          await Promise.all(deletePromises);
        }

        // Delete the message document
        await deleteDoc(messageRef);

        // Update last message in chat if this was the last message
        const chatRef = doc(firestore, `chats/${selectedChat.id}`);
        const isLastMessage = selectedChat.lastMessage?.text === message.text;

        if (isLastMessage) {
          await updateDoc(chatRef, {
            lastMessage: null,
            lastMessageTime: message.createdAt,
          });
        }

        setShowDeleteMenu(false);
      } catch (error) {
        console.error("Error deleting message:", error);
      } finally {
        setIsDeleting(false);
      }
    };

    const renderContent = () => {
      if (!message.content) return null;

      const content = message.content;
      switch (content.type) {
        case "image":
          if (Array.isArray(content.urls)) {
            return (
              <div className="imageCarousel">
                {content.urls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt=""
                    className={`messageImage ${
                      index === currentImageIndex ? "activeImage" : ""
                    }`}
                  />
                ))}
                {content.urls.length > 1 && (
                  <div className="dotNavigation">
                    {content.urls.map((_, index) => (
                      <span
                        key={index}
                        className={`dot ${
                          index === currentImageIndex ? "activeDot" : ""
                        }`}
                        onClick={() => handleImageChange(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            );
          }
          return <img src={content.url} alt="" className="messageImage" />;

        case "video":
          return (
            <div className="videoContainer">
              <video
                ref={videoRef}
                className="postContentVideo"
                playsInline
                muted={!videoVolume}
                loop
                autoPlay
                preload="metadata"
                poster={content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={content.url} type="video/mp4" />
              </video>
              <div className="videoControls">
                <button
                  className="volumeControl"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume(!videoVolume);
                  }}
                >
                  {!videoVolume ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          );

        case "pdf":
          return (
            <a
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
              className="pdfView"
            >
              <div className="pdfBox">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Z"
                  />
                </svg>
              </div>
              <div className="fileContainer">
                <div className="fileAbout">
                  <div className="fileName">
                    {content.fileName
                      ? content.fileName.replace(/\.pdf$/i, "")
                      : "Unnamed PDF"}
                  </div>
                  <div className="open">
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                    View
                  </div>
                </div>
              </div>
            </a>
          );

        default:
          return null;
      }
    };

    const isCurrentUser = message.senderId === currentUser?.uid;
    const senderProfile = userProfiles[message.senderId];

    return (
      <div
        className={classes.messageContainer}
        onMouseLeave={() => setShowDeleteMenu(false)}
      >
        <img
          src={senderProfile?.image}
          alt={senderProfile?.name}
          className={classes.messageUserImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage;
          }}
        />
        <div className={classes.messageContent}>
          <div className={classes.userNameContainer}>
            <span className={classes.userName}>{senderProfile?.name}</span>
            <span className={classes.userConnectionType}>
              •{" "}
              {message.createdAt?.toDate().toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
            {isCurrentUser && (
              <button
                className={classes.optionsButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteMenu(!showDeleteMenu);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </button>
            )}
            {showDeleteMenu && (
              <div className={classes.optionsDropdown}>
                <button
                  className={classes.optionButton}
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                    />
                  </svg>
                  {isDeleting ? "Deleting..." : "Delete message"}
                </button>
              </div>
            )}
          </div>
          {message.text && (
            <div className={classes.messageText}>
              <div
                className={classes.preserveFormatting}
                dangerouslySetInnerHTML={{
                  __html: convertUrlsToLinks(message.text),
                }}
                onClick={handleLinkClick}
              />
            </div>
          )}
          {message.content && renderPostContent(message)}
        </div>
      </div>
    );
  };

  const handleDeleteChat = async () => {
    if (!selectedChat || !currentUser) return;

    try {
      const chatRef = doc(firestore, `chats/${selectedChat.id}`);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) return;

      const chatData = chatDoc.data();
      const deletedBy = chatData.deletedBy || [];

      // If the other user has already deleted the chat
      if (deletedBy.includes(selectedChat.otherUser.id)) {
        const storage = getStorage();
        const batch = writeBatch(firestore);

        // Get all messages to find media content
        const messagesRef = collection(chatRef, "messages");
        const messagesSnapshot = await getDocs(messagesRef);

        // Create array of promises for storage deletion
        const storageDeletePromises = [];

        messagesSnapshot.forEach((doc) => {
          const messageData = doc.data();
          // Delete the message document
          batch.delete(doc.ref);

          // If message has content (image, video, or PDF), delete from storage
          if (messageData.content) {
            if (
              messageData.content.type === "image" &&
              messageData.content.urls
            ) {
              // Handle multiple images
              messageData.content.urls.forEach((url) => {
                const fileRef = ref(storage, url);
                storageDeletePromises.push(deleteObject(fileRef));
              });
            } else if (messageData.content.url) {
              // Handle single file (video, PDF, or single image)
              const fileRef = ref(storage, messageData.content.url);
              storageDeletePromises.push(deleteObject(fileRef));

              // If it's a video, also delete its thumbnail
              if (messageData.content.thumbnail) {
                const thumbnailRef = ref(
                  storage,
                  messageData.content.thumbnail
                );
                storageDeletePromises.push(deleteObject(thumbnailRef));
              }
            }
          }
        });

        // Delete the chat document
        batch.delete(chatRef);

        // Execute all deletions
        await Promise.all([batch.commit(), ...storageDeletePromises]);
      } else {
        // Just mark as deleted for current user and store deletion timestamp
        await updateDoc(chatRef, {
          deletedBy: arrayUnion(currentUser.uid),
          [`deletedAt.${currentUser.uid}`]: serverTimestamp(),
        });
      }

      // Update local state
      setChats((prevChats) =>
        prevChats.filter((chat) => chat.id !== selectedChat.id)
      );
      setSelectedChat(null);
      setShowDropdown(false);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const positionDropdown = () => {
    if (optionsButtonRef.current && dropdownRef.current) {
      const buttonRect = optionsButtonRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
      dropdownRef.current.style.right = `${
        window.innerWidth - buttonRect.right
      }px`;
    }
  };

  useEffect(() => {
    if (showDropdown) {
      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);
    }
    return () => {
      window.removeEventListener("resize", positionDropdown);
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [videoRef]);

  const renderFilePreview = () => {
    if (isProcessing) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <FilePreview
        fileType={fileType}
        filePreview={filePreview}
        fileName={fileName}
        isUploading={isUploading}
        multipleImages={multipleImages}
        currentImageIndex={currentImageIndex}
        handleImageChange={handleImageChange}
        videoThumbnail={videoThumbnail}
        onRemove={handleRemoveFile}
      />
    );
  };

  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const isLoading = Object.values(loadingStates).some(
    (state) => state === true
  );

  const fetchUserProfile = useCallback(async (userId) => {
    if (!userId || userProfiles[userId]) return userProfiles[userId];

    updateLoadingState("profiles", true);
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserProfiles((prev) => ({
          ...prev,
          [userId]: {
            id: userId,
            name: `${userData.firstName} ${userData.lastName}`,
            image: userData.profileImage || defaultImage,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      updateLoadingState("profiles", false);
    }
    return null;
  }, []); // Remove userProfiles from dependencies

  useEffect(() => {
    if (messages.length === 0) {
      updateLoadingState("profiles", false);
      return;
    }

    const unloadedProfiles = messages.some(
      (message) => !userProfiles[message.senderId]
    );

    if (!unloadedProfiles) {
      updateLoadingState("profiles", false);
    }
  }, [messages, userProfiles]);

  useEffect(() => {
    if (chats.length === 0) {
      updateLoadingState("messages", false);
      updateLoadingState("profiles", false);
    }
  }, [chats]);

  useEffect(() => {
    const fetchProfiles = async () => {
      const unloadedProfiles = messages.filter(
        (message) => !userProfiles[message.senderId]
      );

      if (unloadedProfiles.length === 0) {
        updateLoadingState("profiles", false);
        return;
      }

      await Promise.all(
        unloadedProfiles.map((message) => fetchUserProfile(message.senderId))
      );
    };

    if (messages.length > 0) {
      fetchProfiles();
    } else {
      updateLoadingState("profiles", false);
    }
  }, [messages, fetchUserProfile]);

  const formatTime = (date) => {
    return date
      .toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace("am", "AM")
      .replace("pm", "PM");
  };

  useEffect(() => {
    messages.forEach((message) => {
      if (!userProfiles[message.senderId]) {
        fetchUserProfile(message.senderId);
      }
    });
  }, [messages, fetchUserProfile]);

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      try {
        const userConnectionRef = doc(
          firestore,
          `users/${currentUserId}/connections/${targetUserId}`
        );
        const userConnectionDoc = await getDoc(userConnectionRef);

        if (
          userConnectionDoc.exists() &&
          userConnectionDoc.data().status === "Connected"
        ) {
          return "Direct";
        }

        const userConnectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const userConnectionsQuery = query(
          userConnectionsRef,
          where("status", "==", "Connected")
        );
        const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

        for (const conn of userConnectionsSnapshot.docs) {
          const indirectConnectionRef = doc(
            firestore,
            `users/${conn.id}/connections/${targetUserId}`
          );
          const indirectConnectionDoc = await getDoc(indirectConnectionRef);
          if (
            indirectConnectionDoc.exists() &&
            indirectConnectionDoc.data().status === "Connected"
          ) {
            return "Indirect";
          }
        }

        return "Extended";
      } catch (error) {
        console.error("Error checking connection type:", error);
        return "Extended";
      }
    },
    []
  ); // Empty dependency array since it doesn't use any component state

  const fetchChats = useCallback(() => {
    if (!currentUser) {
      return () => {};
    }

    updateLoadingState("chats", true);
    const chatsRef = collection(firestore, "chats");
    const q = query(
      chatsRef,
      where("participants", "array-contains", currentUser.uid),
      orderBy("lastMessageTime", "desc")
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      try {
        const chatsData = await Promise.all(
          snapshot.docs.map(async (chatDoc) => {
            const chatData = chatDoc.data();

            if (chatData.deletedBy?.includes(currentUser.uid)) {
              return null;
            }

            const otherUserId = chatData.participants.find(
              (id) => id !== currentUser.uid
            );

            try {
              const userDocRef = doc(firestore, "users", otherUserId);
              const userDoc = await getDoc(userDocRef);

              if (!userDoc.exists()) return null;

              const userData = userDoc.data();
              const connectionType = await checkConnectionType(
                currentUser.uid,
                otherUserId
              );

              const messagesRef = collection(chatDoc.ref, "messages");
              const messageSnapshot = await getDocs(
                query(messagesRef, limit(1))
              );

              return {
                id: chatDoc.id,
                otherUser: {
                  id: otherUserId,
                  name: `${userData.firstName} ${userData.lastName}`,
                  image: userData.profileImage || defaultImage,
                  bio: userData.bio || "",
                  link: userData.link || "",
                  linkText: userData.linkText || "",
                  connectionType: connectionType,
                },
                lastMessage: chatData.lastMessage,
                unreadCount: chatData.unreadCount?.[currentUser.uid] || 0,
                lastMessageTime: chatData.lastMessageTime,
                hasMessages: !messageSnapshot.empty,
              };
            } catch (error) {
              console.error("Error processing chat:", error);
              return null;
            }
          })
        );

        const validChats = chatsData.filter((chat) => chat !== null);
        setChats(validChats);

        if (validChats.length > 0 && !selectedChat) {
          setSelectedChat(validChats[0]);
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        updateLoadingState("chats", false);
      }
    });

    return unsubscribe;
  }, [currentUser, checkConnectionType]);

  useEffect(() => {
    const loadInitialData = async () => {
      updateLoadingState("userData", true);

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userDoc = await getDoc(doc(firestore, "users", user.uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setCurrentUser({
                ...user,
                ...userData,
              });

              if (location.state?.otherUser) {
                const chatId = [user.uid, location.state.otherUser.id]
                  .sort()
                  .join("_");
                const chatData = {
                  id: chatId,
                  participants: [user.uid, location.state.otherUser.id],
                  createdAt: new Date(),
                  otherUser: location.state.otherUser,
                };
                setSelectedChat(chatData);

                const chatRef = doc(firestore, `chats/${chatId}`);
                const chatDoc = await getDoc(chatRef);
                if (!chatDoc.exists()) {
                  await setDoc(
                    chatRef,
                    {
                      participants: [user.uid, location.state.otherUser.id],
                      createdAt: serverTimestamp(),
                      hasMessages: false,
                      lastMessageTime: serverTimestamp(),
                    },
                    { merge: true }
                  );
                }
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        } else {
          setCurrentUser(null);
        }
        updateLoadingState("userData", false);
      });

      return unsubscribe;
    };

    loadInitialData();
  }, [location.state]);

  // Add this useEffect to handle selection of first chat
  useEffect(() => {
    // Only set selected chat if we have chats and no chat is currently selected
    if (chats.length > 0 && !selectedChat && !location.state?.otherUser) {
      setSelectedChat(chats[0]);
    }
  }, [chats, selectedChat, location.state?.otherUser]);

  useEffect(() => {
    if (!selectedChat?.id || !currentUser) return;

    updateLoadingState("messages", true);
    const chatRef = doc(firestore, `chats/${selectedChat.id}`);
    const messagesRef = collection(chatRef, "messages");

    const setupMessageListener = async () => {
      try {
        const chatDoc = await getDoc(chatRef);
        const chatData = chatDoc.data();
        const deletionTimestamp = chatData?.deletedAt?.[currentUser.uid];

        // Clear unread count when entering chat
        if (chatData.unreadCount?.[currentUser.uid]) {
          await updateDoc(chatRef, {
            [`unreadCount.${currentUser.uid}`]: 0,
          });
        }

        const q = query(messagesRef, orderBy("createdAt", "asc"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const messagesData = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((message) => {
              if (!deletionTimestamp) return true;
              return message.createdAt?.toDate() > deletionTimestamp.toDate();
            });

          setMessages(messagesData);
          updateLoadingState("messages", false);
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error setting up message listener:", error);
        updateLoadingState("messages", false);
      }
    };

    const unsubscribe = setupMessageListener();
    return () => {
      if (unsubscribe) {
        unsubscribe.then((unsub) => unsub && unsub());
      }
    };
  }, [selectedChat?.id, currentUser]);

  useEffect(() => {
    const unsubscribe = fetchChats();
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [fetchChats]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const filteredChats = useMemo(() => {
    return chats.filter((chat) => {
      if (!chat?.otherUser?.name) return false;
      return chat.otherUser.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  }, [chats, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const removeFileExtension = (fileName) => {
    if (typeof fileName !== "string") return fileName;
    return fileName.replace(/\.[^/.]+$/, "");
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.playsInline = true;
      video.muted = true;

      video.onloadedmetadata = () => {
        video.currentTime = 1;
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = video.videoWidth / video.videoHeight;
        let width = 320;
        let height = 320 / aspectRatio;

        if (height > 240) {
          height = 240;
          width = 240 * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.7
        );
      };

      video.onerror = () => {
        console.error("Error loading video for thumbnail");
        resolve(null);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setIsProcessing(true);
      setFile(null);
      setFileType(null);
      setFilePreview(null);

      try {
        // Handle images - modified to handle single image only
        if (selectedFiles[0].type.startsWith("image/")) {
          const compressedImage = await compressImage(selectedFiles[0]);

          setFile([compressedImage]); // Keep as array for consistency with existing code
          setFileType("image");
          setFileName([compressedImage.name]);

          // Create image preview for single image
          const reader = new FileReader();
          reader.onloadend = () => {
            setMultipleImages([reader.result]);
            setFilePreview(reader.result);
          };
          reader.readAsDataURL(compressedImage);
        }
        // Handle videos
        else if (selectedFiles[0].type.startsWith("video/")) {
          setFile(selectedFiles[0]);
          setFileType("video");
          setFileName(removeFileExtension(selectedFiles[0].name));

          // Generate video preview and thumbnail
          const reader = new FileReader();
          reader.onloadend = () => setFilePreview(reader.result);
          reader.readAsDataURL(selectedFiles[0]);

          const thumbnailBlob = await generateVideoThumbnail(selectedFiles[0]);
          if (thumbnailBlob) {
            const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
            setVideoThumbnail(thumbnailUrl);
          }
        }
        // Handle PDFs
        else if (selectedFiles[0].type === "application/pdf") {
          setFile(selectedFiles[0]);
          setFileType("pdf");
          setFileName(removeFileExtension(selectedFiles[0].name));
          setFilePreview(URL.createObjectURL(selectedFiles[0]));
        }
      } catch (error) {
        console.error("Error processing file:", error);
        resetFileInput();
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const FileUploadButtons = ({ onFileSelect }) => {
    const [isImageHovered, setIsImageHovered] = useState(false);
    const [isVideoHovered, setIsVideoHovered] = useState(false);
    const [isFileHovered, setIsFileHovered] = useState(false);

    const ImageIcon = ({ isHovered }) => (
      <svg
        className="imageIcon"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="gray"
        viewBox="0 0 24 24"
      >
        {isHovered ? (
          <>
            <path
              fillRule="evenodd"
              d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
              clipRule="evenodd"
            />
          </>
        ) : (
          <path
            stroke="gray"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
            strokeWidth="1.75"
            d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 18h8l-2-4-1.5 2-2-4L8 18Zm7-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
          />
        )}
      </svg>
    );

    const VideoIcon = ({ isHovered }) => (
      <svg
        className="videoIcon"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        {isHovered ? (
          <path
            fill="gray"
            fillRule="evenodd"
            d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm-2 4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H9Zm0 2h2v2H9v-2Zm7.965-.557a1 1 0 0 0-1.692-.72l-1.268 1.218a1 1 0 0 0-.308.721v.733a1 1 0 0 0 .37.776l1.267 1.032a1 1 0 0 0 1.631-.776v-2.984Z"
            clipRule="evenodd"
          />
        ) : (
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM9 12h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm5.697 2.395v-.733l1.269-1.219v2.984l-1.268-1.032Z"
          />
        )}
      </svg>
    );

    const FileIcon = ({ isHovered }) => (
      <svg
        className="fileIcon"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="gray"
        viewBox="0 0 24 24"
      >
        {isHovered ? (
          <path
            fillRule="evenodd"
            d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
            clipRule="evenodd"
          />
        ) : (
          <path
            stroke="gray"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="none"
            d="M5 17v-5h1.5a1.5 1.5 0 1 1 0 3H5m12 2v-5h2m-2 3h2M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m6 4v5h1.375A1.627 1.627 0 0 0 14 15.375v-1.75A1.627 1.627 0 0 0 12.375 12H11Z"
          />
        )}
      </svg>
    );

    return (
      <div className={classes.buttons}>
        <div
          type="button"
          className="w-10 h-10 flex items-center justify-center rounded-lg hover:bg-gray-100 transition-colors"
          onMouseEnter={() => setIsImageHovered(true)}
          onMouseLeave={() => setIsImageHovered(false)}
          onClick={() => onFileSelect("image")}
        >
          <ImageIcon isHovered={isImageHovered} />
        </div>
        <div
          type="button"
          className="w-10 h-10 flex items-center justify-center rounded-lg hover:bg-gray-100 transition-colors"
          onMouseEnter={() => setIsVideoHovered(true)}
          onMouseLeave={() => setIsVideoHovered(false)}
          onClick={() => onFileSelect("video")}
        >
          <VideoIcon isHovered={isVideoHovered} />
        </div>
        <div
          type="button"
          className="w-10 h-10 flex items-center justify-center rounded-lg hover:bg-gray-100 transition-colors"
          onMouseEnter={() => setIsFileHovered(true)}
          onMouseLeave={() => setIsFileHovered(false)}
          onClick={() => onFileSelect("pdf")}
        >
          <FileIcon isHovered={isFileHovered} />
        </div>
      </div>
    );
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileType(null);
    setFilePreview(null);
    setFileName(null);
    setVideoThumbnail(null);
    setMultipleImages([]);
    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileSelect = (type) => {
    if (fileInputRef.current) {
      resetFileInput();
      if (type === "image") {
        fileInputRef.current.setAttribute("accept", "image/*");
        fileInputRef.current.removeAttribute("multiple"); // Remove multiple attribute for images
      } else if (type === "pdf") {
        fileInputRef.current.setAttribute("accept", ".pdf");
        fileInputRef.current.removeAttribute("multiple");
      } else if (type === "video") {
        fileInputRef.current.setAttribute("accept", "video/*");
        fileInputRef.current.removeAttribute("multiple");
      }
      fileInputRef.current.click();
    }
  };

  const uploadFile = async (file, chatId) => {
    const storage = getStorage();

    // For multiple images
    if (Array.isArray(file)) {
      const uploadPromises = file.map(async (f, index) => {
        const fileName = `image_${index}_${Date.now()}.jpg`;
        const uploadPath = `messageContent/${chatId}/${fileName}`;
        const fileRef = ref(storage, uploadPath);
        const uploadSnapshot = await uploadBytesResumable(fileRef, f);
        return getDownloadURL(uploadSnapshot.ref);
      });

      const downloadURLs = await Promise.all(uploadPromises);

      return {
        type: "image",
        urls: downloadURLs,
        fileNames: file.map((f) => f.name),
      };
    }
    // For single file (video or PDF)
    else {
      const timestamp = Date.now();
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const fileName = `${fileType}_${timestamp}.${fileExtension}`;
      const uploadPath = `messageContent/${chatId}/${fileName}`;
      const fileRef = ref(storage, uploadPath);

      // Upload the main file
      const uploadSnapshot = await uploadBytesResumable(fileRef, file);
      const downloadURL = await getDownloadURL(uploadSnapshot.ref);

      let additionalData = {};

      // Handle video thumbnail if present
      if (fileType === "video" && videoThumbnail) {
        const thumbnailFileName = `thumbnail_${timestamp}.jpg`;
        const thumbnailPath = `messageContent/${chatId}/${thumbnailFileName}`;
        const thumbnailRef = ref(storage, thumbnailPath);
        const thumbnailBlob = await fetch(videoThumbnail).then((r) => r.blob());
        const thumbnailSnapshot = await uploadBytesResumable(
          thumbnailRef,
          thumbnailBlob
        );
        const thumbnailURL = await getDownloadURL(thumbnailSnapshot.ref);
        additionalData.thumbnail = thumbnailURL;
      }

      return {
        type: fileType,
        url: downloadURL,
        fileName: file.name,
        ...additionalData,
      };
    }
  };

  const sendMessage = async () => {
    if (isSending || (!newMessage.trim() && !file) || !selectedChat) return;

    setIsSending(true);
    const chatId = selectedChat.id;
    const chatRef = doc(firestore, `chats/${chatId}`);

    try {
      const chatDoc = await getDoc(chatRef);
      const chatData = chatDoc.exists() ? chatDoc.data() : null;

      let fileData = null;
      if (file) {
        fileData = await uploadFile(file, chatId);
      }

      const messageData = {
        text: newMessage.trim(),
        senderId: currentUser.uid,
        createdAt: serverTimestamp(),
        read: false,
        ...(fileData && { content: fileData }),
      };

      const batch = writeBatch(firestore);
      const currentUnreadCount = chatData?.unreadCount || {};

      // Only increment unread count for other user
      const updatedUnreadCount = {
        ...currentUnreadCount,
        [selectedChat.otherUser.id]:
          (currentUnreadCount[selectedChat.otherUser.id] || 0) + 1,
        [currentUser.uid]: 0, // Always keep current user's unread count at 0 when sending
      };

      let updatedDeletedBy = chatData?.deletedBy || [];
      if (updatedDeletedBy.includes(selectedChat.otherUser.id)) {
        updatedDeletedBy = updatedDeletedBy.filter(
          (id) => id !== selectedChat.otherUser.id
        );
      }

      batch.update(chatRef, {
        participants: [currentUser.uid, selectedChat.otherUser.id],
        hasMessages: true,
        lastMessage: {
          text: messageData.text,
          senderId: currentUser.uid,
          ...(fileData && { content: fileData }),
        },
        lastMessageTime: serverTimestamp(),
        unreadCount: updatedUnreadCount,
        deletedBy: updatedDeletedBy,
      });

      const messagesRef = collection(chatRef, "messages");
      const newMessageRef = doc(messagesRef);
      batch.set(newMessageRef, messageData);

      await batch.commit();
      setNewMessage("");
      handleRemoveFile();
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
    }
  };

  const isSendDisabled = () => {
    if (isSending) return true;
    if (file) return false; // Allow sending if there's a file attached
    return !newMessage.trim(); // Disable if no text and no file
  };

  const renderPostContent = (message) => {
    if (!message.content) return null;

    const content = message.content;
    switch (content.type) {
      case "image":
        if (Array.isArray(content.urls)) {
          return (
            <div className={classes.imageCarousel}>
              {content.urls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt=""
                  className={`${classes.messageImage} ${
                    index === currentImageIndex ? classes.activeImage : ""
                  }`}
                />
              ))}
              {content.urls.length > 1 && (
                <div className={classes.dotNavigation}>
                  {content.urls.map((_, index) => (
                    <span
                      key={index}
                      className={`${classes.dot} ${
                        index === currentImageIndex ? classes.activeDot : ""
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </div>
              )}
            </div>
          );
        }
        return (
          <img src={content.url} alt="" className={classes.messageImage} />
        );

      case "video":
        return (
          <div className={classes.videoContainer}>
            <video
              ref={videoRef}
              className={classes.postContentVideo}
              playsInline
              muted={!videoVolume}
              loop
              autoPlay
              preload="metadata"
              poster={content.thumbnail || ""}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
            >
              <source src={content.url} type="video/mp4" />
            </video>
            <div className={classes.videoControls}>
              <button
                className={classes.volumeControl}
                onClick={(e) => {
                  e.stopPropagation();
                  setVideoVolume(!videoVolume);
                }}
              >
                {!videoVolume ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        );
      case "pdf":
        return (
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.pdfView}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (content.fileName) {
                      if (Array.isArray(content.fileName)) {
                        fileName = content.fileName[0] || "Unnamed PDF";
                      } else if (typeof content.fileName === "string") {
                        fileName = content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                  View
                </div>
              </div>
            </div>
          </a>
        );
      default:
        return null;
    }
  };

  const renderChatPreview = (chat) => (
    <div
      key={chat.id}
      className={`${classes.userContainer} ${
        selectedChat?.id === chat.id ? classes.selectedChat : ""
      }`}
      onClick={() => setSelectedChat(chat)}
    >
      <img
        src={chat.otherUser.image}
        alt={chat.otherUser.name}
        className={classes.userImage}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultImage;
        }}
      />
      <div className={classes.userInfo}>
        <div className={classes.userNameContainer}>
          <span className={classes.userName}>{chat.otherUser.name}</span>
          <span className={classes.userConnectionType}>
            • {chat.otherUser.connectionType}
          </span>
        </div>
        <div className={classes.userBio}>
          {chat.lastMessage?.text || "Start a conversation"}
        </div>
        {chat.lastMessageTime && (
          <div className={classes.lastMessageTime}>
            {formatTime(chat.lastMessageTime.toDate())}
          </div>
        )}
      </div>
    </div>
  );

  const LoadingSpinner = () => (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showAddChatModal && (
        <AddChatModal onClose={toggleAddChatModal} currentUser={currentUser} />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div
            className={classes.card}
            style={{
              height: "38.25rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {selectedChat ? (
              <>
                <div className={classes.chatHeader}>
                  <img
                    src={selectedChat.otherUser.image}
                    alt={selectedChat.otherUser.name}
                    className={classes.userImage}
                  />
                  <div className={classes.headerInfo}>
                    <div className={classes.nameContainer}>
                      <div className={classes.userName}>
                        {selectedChat.otherUser.name}
                      </div>
                      <span className={classes.userConnectionType}>
                        • {selectedChat.otherUser.connectionType}
                      </span>
                    </div>
                    <div className={classes.textWrapper}>
                      {selectedChat.otherUser.bio && (
                        <div className={classes.userBio}>
                          {selectedChat.otherUser.bio}
                        </div>
                      )}
                      {selectedChat.otherUser.link && (
                        <a
                          href={selectedChat.otherUser.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.userLink}
                        >
                          <span>
                            {selectedChat.otherUser.linkText ||
                              selectedChat.otherUser.link}
                          </span>
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => setShowDropdown(!showDropdown)}
                    className={classes.optionsButton}
                    ref={optionsButtonRef}
                  >
                    <svg
                      className={`${classes.closeIcon} ${
                        showDropdown ? classes.closeIconActive : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeWidth="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01"
                      />
                    </svg>
                  </div>
                  {showDropdown && (
                    <div ref={dropdownRef} className={classes.optionsDropdown}>
                      <button className={classes.optionButton}>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="gray"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.09 3.294c1.924.95 3.422 1.69 5.472.692a1 1 0 0 1 1.438.9v9.54a1 1 0 0 1-.562.9c-2.981 1.45-5.382.24-7.25-.701a38.739 38.739 0 0 0-.622-.31c-1.033-.497-1.887-.812-2.756-.77-.76.036-1.672.357-2.81 1.396V21a1 1 0 1 1-2 0V4.971a1 1 0 0 1 .297-.71c1.522-1.506 2.967-2.185 4.417-2.255 1.407-.068 2.653.453 3.72.967.225.108.443.216.655.32Z" />
                        </svg>
                        Report chat
                      </button>
                      <button
                        className={classes.optionButton}
                        onClick={handleDeleteChat}
                      >
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                          />
                        </svg>
                        Delete chat
                      </button>
                    </div>
                  )}
                </div>
                <div className={classes.messagesContainer}>
                  {messages
                    .slice()
                    .reverse()
                    .map((message) => (
                      <ChatMessage
                        key={message.id}
                        message={message}
                        currentUser={currentUser}
                        userProfiles={userProfiles}
                        handleImageChange={handleImageChange}
                        currentImageIndex={currentImageIndex}
                        videoVolume={videoVolume}
                        setVideoVolume={setVideoVolume}
                        videoRef={videoRef}
                        selectedChat={selectedChat}
                        firestore={firestore}
                      />
                    ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className={classes.chatInput}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  {file && (
                    <FilePreview
                      fileType={fileType}
                      filePreview={filePreview}
                      fileName={fileName}
                      isUploading={isUploading}
                      multipleImages={multipleImages}
                      currentImageIndex={currentImageIndex}
                      handleImageChange={handleImageChange}
                      videoThumbnail={videoThumbnail}
                      onRemove={handleRemoveFile}
                    />
                  )}
                  <textarea
                    ref={messageInputRef}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                    className={classes.messageInput}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}
                  />

                  <div className={classes.messageInputWrapper}>
                    <div className={classes.fileUploadContainer}>
                      <FileUploadButtons onFileSelect={handleFileSelect} />
                    </div>
                    <div
                      onClick={sendMessage}
                      className={`${classes.button} ${
                        isSendDisabled() ? classes.disabled : ""
                      }`}
                      style={{
                        opacity: isSendDisabled() ? 0.5 : 1,
                        cursor: isSendDisabled() ? "not-allowed" : "pointer",
                      }}
                    >
                      {isSending ? "Sending..." : "Send"}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.noChatSelected}>
                <p>Select a chat to start messaging</p>
              </div>
            )}
          </div>
        </div>

        <div className={classes.rightCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Chats
              <div onClick={toggleAddChatModal}>
                <svg
                  className={classes.addIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.searchContainer}>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
            </div>
            {filteredChats.length > 0 ? (
              <>
                {filteredChats
                  .slice(0, visibleChats)
                  .map((chat) => renderChatPreview(chat))}
                {filteredChats.length > 3 && (
                  <div
                    className={classes.showAllExperiences}
                    onClick={() => {
                      if (visibleChats >= filteredChats.length) {
                        setVisibleChats(3);
                      } else {
                        setVisibleChats(
                          Math.min(visibleChats * 2, filteredChats.length)
                        );
                      }
                    }}
                  >
                    {visibleChats >= filteredChats.length
                      ? "Show less"
                      : "Show more"}
                    {visibleChats >= filteredChats.length ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className={classes.noChats}>
                {searchTerm
                  ? "No chats found"
                  : "Your chats will be displayed here."}
              </div>
            )}
          </div>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Chats;
