import React from "react";
import { Link } from "react-router-dom";

import classes from "./AllPosts.module.css";

const PostActionPopup = ({
  message,
  onClose,
  postId,
  isRepost,
  isDelete,
  isSuccess,
}) => {
  return (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill={isSuccess ? "orangered" : "gray"}
          viewBox="0 0 24 24"
        >
          {isSuccess ? (
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          ) : (
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1 4h1v5h-1v-5z"
              clipRule="evenodd"
            />
          )}
        </svg>
        <p>{message}</p>
        {isRepost && isSuccess && (
          <Link to={`/repost/${postId}`} className={classes.viewRepostLink}>
            View repost
          </Link>
        )}
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );
};

export default PostActionPopup;
