const companies = [
  "10.Or",
  "2U",
  "3i",
  "3M",
  "4F",
  "7-Eleven",
  "85C Bakery Cafe",
  "AB InBev",
  "Abbott Laboratories",
  "AbbVie",
  "Abiomed",
  "ABN AMRO",
  "ABS-CBN Corporation",
  "Accenture",
  "Acer Inc.",
  "Activision Blizzard",
  "Adidas",
  "Adient",
  "Aditi Technologies",
  "Aditya Birla Group",
  "Adobe",
  "Advanced Micro Devices",
  "Aegon",
  "AEON",
  "Agrosuper",
  "Ahold Delhaize",
  "Airbnb",
  "Airbus",
  "Ajinomoto",
  "Akai",
  "AkzoNobel",
  "Aldi",
  "Alfa Laval",
  "Alibaba Group",
  "All Nippon Airways",
  "Alliance Global",
  "Allianz",
  "Almarai",
  "Alphabet",
  "Alstom",
  "Altice",
  "Altria Group",
  "Amazon",
  "Amcor",
  "American Airlines Group",
  "American Express",
  "American International Group",
  "Andritz AG",
  "ANZ Bank",
  "Aon",
  "Apollo Tyres",
  "Apple",
  "Arcor",
  "Asics",
  "Assicurazioni Generali",
  "Aston Martin",
  "Asus",
  "AsusTek",
  "AT&T",
  "Atari",
  "Avianca",
  "Axa",
  "Axiata Group",
  "Axis Bank Ltd",
  "Ayala Corporation",
  "Bacardi",
  "Baidu",
  "Baker Hughes",
  "Ball Corporation",
  "Banco Bilbao Vizcaya Argentaria",
  "Banco Santander",
  "Bandai Namco Entertainment",
  "Bank of China",
  "Bank of India",
  "Bank of Ireland",
  "Bank of Montreal",
  "Barclays",
  "Barilla",
  "Barnes & Noble",
  "Barrick Gold Corporation",
  "BASF",
  "Bata",
  "Bayer",
  "BBC",
  "BBK Electronics",
  "Becton Dickinson",
  "Beko",
  "Benetton Group",
  "Bertelsmann",
  "Best Buy",
  "Bharti Airtel",
  "Bharti Enterprises",
  "Bihl",
  "Billabong",
  "Black & Decker",
  "BlackRock",
  "BMW",
  "BNP Paribas",
  "Boeing",
  "Bombardier",
  "Books-A-Million",
  "Bose Corporation",
  "Bouygues",
  "BP",
  "BRAC",
  "Bridgestone",
  "British Airways",
  "Brown–Forman",
  "BT Group",
  "Build-A-Bear Workshop",
  "Burberry",
  "BYD",
  "ByteDance",
  "Canon Inc",
  "Capcom",
  "Capgemini",
  "Capital One",
  "Cargill",
  "Cargolux",
  "Caribbean Airlines",
  "Carlsberg Group",
  "Carrefour",
  "Casio",
  "Caterpillar",
  "Celestica",
  "Celkon Mobiles",
  "Cencosud",
  "Century Pacific Food",
  "Chanel",
  "Changhong",
  "Chevron",
  "China Merchants Bank",
  "China Mobile",
  "China Resources",
  "China UnionPay",
  "Chiquita Brands International",
  "Chubb",
  "Chupa Chups",
  "CIMC",
  "Cisco Systems",
  "Citigroup",
  "CJ Group",
  "Coca-Cola Company",
  "Coffee Bean & Tea Leaf",
  "Cognizant Technology Solutions",
  "Colgate-Palmolive",
  "Comac",
  "Concentrix",
  "ConocoPhillips",
  "Coolpad",
  "Copa",
  "Costco",
  "Coty",
  "Creative Labs",
  "Crédit Agricole",
  "Credit Suisse",
  "Crocs",
  "CT Corp",
  "Cummins",
  "Currys",
  "Cyient",
  "CyrusOne",
  "D-Link",
  "Dabur",
  "Daihatsu",
  "Daikin",
  "Dalton Maag",
  "Dangote Group",
  "Danone",
  "Decathlon",
  "Deepin",
  "Dell Technologies",
  "Deloitte",
  "Delta Air Lines",
  "Deutsche Bank",
  "Deutsche Post",
  "Diageo",
  "Dine Brands Global",
  "Dior",
  "Dixons Carphone",
  "DocuSign",
  "Dole Food Company",
  "Dollar Tree",
  "Domino's Pizza",
  "Dongfeng Motor",
  "Dow Inc",
  "Dude Perfect",
  "DuPont",
  "DXC Technology",
  "Dyson",
  "eBay",
  "Electronic Arts",
  "Embraer",
  "Emerson Electric",
  "Emigre",
  "Eni",
  "Ericsson",
  "EY",
  "Estée Lauder Companies",
  "Etisalat",
  "Eva Air",
  "Evercore",
  "Evergreen Marine",
  "ExxonMobil",
  "Ezaki Glico",
  "Faber-Castell",
  "Fairphone",
  "Fast Retailing",
  "FAW Group",
  "FBT",
  "FedEx",
  "Ferrari",
  "Ferrero",
  "Ficosa",
  "Fila",
  "FIS",
  "Font Bureau",
  "FontShop",
  "Ford",
  "Fortinet",
  "Fossil Group",
  "Foxconn",
  "Foxtel",
  "FPT Group",
  "Fujifilm",
  "Fujitsu",
  "Fujiya",
  "Future Group",
  "GameStop",
  "Gap",
  "Garmin",
  "Gartner",
  "Gazprom",
  "Geeknet",
  "Geely",
  "General Electric",
  "General Mills",
  "General Motors",
  "Generali",
  "Gerdau",
  "Giant Bicycles",
  "Globe Telecom",
  "GMA Network",
  "GMM Grammy",
  "GoDaddy",
  "Goldman Sachs",
  "Gong Cha",
  "Goodyear",
  "Google",
  "GoPro",
  "Gree Electric",
  "Grupo Bimbo",
  "Grupo Imagen",
  "GSK",
  "Gucci",
  "Guess",
  "Guinness",
  "GungHo Online Entertainment",
  "H&M",
  "Haier",
  "Halliburton",
  "Hankook",
  "Hard Rock Cafe",
  "Haribo",
  "Harley-Davidson",
  "Harrods",
  "Hartwall",
  "Hasbro",
  "Hearst Corporation",
  "Heineken",
  "Henkel",
  "Hermes",
  "Hewlett Packard Enterprise",
  "Hilti",
  "Hisamitsu",
  "Hisense",
  "Hitachi",
  "HMD Global",
  "Honda",
  "Honeywell",
  "House Foods",
  "HP",
  "HSBC",
  "HTC",
  "Huawei",
  "Huayi Brothers",
  "Huntington Bancshares",
  "Huntington Ingalls Industries",
  "Huntsman Corporation",
  "Hytera",
  "Hyundai Motor Company",
  "IBM",
  "ICAP",
  "ICICI Bank",
  "IJM Corporation",
  "IKEA",
  "Illinois Tool Works",
  "Indeed",
  "Inditex",
  "Infosys",
  "ING Group",
  "Ingersoll Rand",
  "Intel Corporation",
  "Intesa Sanpaolo",
  "Intracom",
  "Israel Railways",
  "Isuzu",
  "J&F Investimentos",
  "Jardine Matheson",
  "Jelly Belly",
  "JG Summit Holdings",
  "JKN Global Group",
  "The J.M. Smucker Company",
  "Johannus",
  "Johnnie Walker",
  "Johnson & Johnson",
  "Jollibee Foods Corporation",
  "JPMorgan Chase",
  "JVCKenwood",
  "JXD",
  "Kappa",
  "Kawasaki",
  "KBC Bank",
  "Kellogg's",
  "Kering",
  "Keumyoung Group",
  "Kikkoman",
  "Kimberly-Clark",
  "Kingston Technology",
  "Kino Indonesia",
  "Kirin Company",
  "Klim Type Foundry",
  "Komatsu",
  "Konami",
  "Korg",
  "KPMG",
  "Kraft Heinz",
  "L'Oréal",
  "Lacoste",
  "Lactalis",
  "Lagardère",
  "Lam Research",
  "Larsen & Toubro",
  "LATAM Airlines",
  "Lazada",
  "Lear",
  "LeEco",
  "Lego Group",
  "Lenovo",
  "Leonardo",
  "Leoni",
  "Levi Strauss",
  "Lexmark",
  "LG Corporation",
  "LG Electronics",
  "Linde",
  "Lindt",
  "Linpus",
  "Lionbridge",
  "Lippo Group",
  "LiuGong",
  "Lockheed Martin",
  "Lopez Holdings Corporation",
  "Lotte Group",
  "Louisa Coffee",
  "Lufthansa",
  "Lukoil",
  "Lupin",
  "Luxgen",
  "Luxottica",
  "LVMH",
  "LyondellBasell Industries",
  "Macy's",
  "Maersk",
  "Mahindra Group",
  "Mama Sita's Holding Company",
  "Mamee Double-Decker",
  "Mars, Incorporated",
  "Marshall Amplification",
  "Mastercard",
  "Maton",
  "Mattel",
  "Max's Group",
  "Maxxis",
  "Mayora Indah",
  "McCain Foods",
  "McDonald's",
  "MediaTek",
  "Meiji Holdings",
  "Meitu",
  "Meizu",
  "melstacorp",
  "Mercedes-Benz Group",
  "Meta Platforms",
  "MG Cars",
  "Michaels",
  "Michelin",
  "Micro-Star International",
  "Micromax Informatics",
  "Microsoft",
  "Miele",
  "Millipore Corporation",
  "Mindtree",
  "Miniso",
  "Mitsubishi Electric",
  "Mizuno Corporation",
  "Mobil",
  "Monde Nissin",
  "Mondelez International",
  "Monotype Imaging",
  "Monotype",
  "Morinaga & Company",
  "Namco Bandai Holdings",
  "Nando's",
  "NBCUniversal",
  "NEC",
  "Nestlé",
  "NetApp",
  "Netflix",
  "New Balance",
  "Newell Brands",
  "Nickelodeon Group",
  "Nike",
  "Nikon",
  "Nintendo",
  "Nio",
  "Nissan",
  "Nivea",
  "Nokia",
  "Nongshim",
  "Norsk Hydro",
  "Novartis",
  "Novo Nordisk",
  "NutriAsia",
  "Nvidia",
  "Oishi",
  "Oknoplast",
  "Olympus Corporation",
  "Ooredoo",
  "Oracle Corporation",
  "Orange",
  "Ornua",
  "Otobi",
  "Ottogi",
  "Panasonic",
  "Pandora",
  "Panini Group",
  "Paramount Global",
  "Parrot",
  "PayPal",
  "Pepper Lunch",
  "PepsiCo",
  "Perficient",
  "Pernod Ricard",
  "Petronas",
  "Petrovietnam",
  "Pfizer",
  "Philips",
  "Pilot",
  "Ping An Bank",
  "Ping An Insurance",
  "Pioneer Corporation",
  "Pirelli",
  "Pladis",
  "Playmobil",
  "PLDT",
  "Pollo Campero",
  "Prada",
  "Procter & Gamble",
  "Proton",
  "Prudential Financial",
  "Puma",
  "PVH",
  "PwC",
  "QatarEnergy",
  "QNB Group",
  "Quala",
  "Qualcomm",
  "Rabobank",
  "Ranpak",
  "Reckitt",
  "Recruit",
  "Red Bull",
  "Regus",
  "Reliance Industries",
  "Renault",
  "Repsol",
  "Republic Biscuit Corporation",
  "Richemont",
  "Ricoh",
  "Robert Bosch GmbH",
  "Rohde & Schwarz",
  "Roland Corporation",
  "Royal Bank of Canada",
  "Royal Swinkels",
  "RPG Group",
  "Rusal",
  "Saab",
  "Salesforce",
  "Samsonite",
  "Samsung",
  "Samyang Foods",
  "San Miguel Corporation",
  "Sandvik",
  "Sanofi",
  "Sanrio",
  "SAP",
  "SAS Group",
  "Sasken Technologies",
  "Sasol",
  "Saudi Aramco",
  "Schindler Group",
  "Schleich",
  "Schlumberger",
  "Schneider Electric",
  "Scholastic Corporation",
  "Schwan-Stabilo",
  "Scotiabank",
  "Seagate Technology",
  "Sega Sammy Holdings",
  "Seiko",
  "Sennheiser",
  "Serta",
  "Servcorp",
  "Severstal",
  "SF Express",
  "SGS",
  "Shell",
  "Shenzhen Airlines",
  "Shiseido",
  "Shopee",
  "Siemens",
  "Sime Darby Properties",
  "SK Group",
  "Škoda Auto",
  "Skullcandy",
  "SM Investments",
  "SmartStudy",
  "Société Bic",
  "Société Générale",
  "SoftBank Group",
  "Sogou",
  "Sony",
  "Sphero",
  "Spin Master",
  "Spotify",
  "Square Enix",
  "Staedtler",
  "Standard Chartered",
  "Starbucks",
  "State Bank of India",
  "Stellantis",
  "Subway",
  "Suntory",
  "Suzuki",
  "Swarovski",
  "Tabasco sauce",
  "Taco Maker",
  "Take-Two Interactive",
  "Tao Kae Noi",
  "Tapestry, Inc.",
  "Target",
  "Tata",
  "Tate & Lyle",
  "TCL Technology",
  "TDK",
  "TEAC Corporation",
  "TechniSat",
  "Telefonica",
  "Televisa",
  "Telstra",
  "Tencent",
  "Tesco",
  "Tesla",
  "Texas Instruments",
  "Textron",
  "The Cartoon Network",
  "The Hershey Company",
  "The Home Depot",
  "The Swatch Group",
  "The Vanguard Group",
  "The Walt Disney Company",
  "Wendy's",
  "Thomson Reuters",
  "TikTok",
  "Ting Hsin International Group",
  "TKK Fried Chicken",
  "TMK",
  "TNT Express",
  "TomTom",
  "Tomy",
  "Toshiba",
  "TotalEnergies",
  "Toyota",
  "TP-Link",
  "TPG Telecom",
  "Trend Micro",
  "TSMC",
  "TV Azteca",
  "Typotheque",
  "Uber",
  "Ubisoft",
  "UMC",
  "Unicredit",
  "Unilab",
  "Unilever",
  "Unisys",
  "UPS",
  "Vanke",
  "Verisk Analytics",
  "Vertiv",
  "Vestas",
  "VF Corporation",
  "Victoria's Secret",
  "Victorinox",
  "Viettel Mobile",
  "Vimpelcom",
  "Vinamilk",
  "Virgin Group",
  "Visa",
  "Vitol",
  "Viva Communications",
  "Vivendi",
  "Vizio",
  "Vodafone",
  "Voith",
  "Volkswagen Group",
  "Volvo",
  "Wal-Mart",
  "Want Want",
  "Warner Bros. Discovery",
  "Wawa",
  "Whirlpool Corporation",
  "Wings Surya",
  "Wingstop",
  "Wipro",
  "Wirecard",
  "Xiaomi Corporation",
  "Xtep",
  "Yamaha Corporation",
  "Yamaha Motor Company",
  "Yandex",
  "Yili Group",
  "Yokohama Rubber Company",
  "YouTube",
  "Yum brands",
  "Zensar Technologies",
  "Zhujiang Beer",
  "Zippo",
  "ZTE",
];

export default Array.from(new Set(companies)).sort((a, b) =>
  a.localeCompare(b, undefined, { sensitivity: "base" })
);
