import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import eventImage from "../../../assets/images/startupImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Event.module.css";
import { firestore } from "../../../firebase";
import EditOrganisersModal from "../../../components/Modals/EditOrganisersModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import EditSingleEventModal from "../../../components/Modals/EditSingleEventModal";
import TagsModal from "../../../components/Modals/TagsModal";
import IntroModal from "../../../components/Modals/IntroModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import AddOrganisersModal from "../../../components/Modals/AddOrganisersModal";
import AddAttendeeModal from "../../../components/Modals/AddAttendeeModal";
import {
  formatEventTime,
  getUserTimezone,
  formatEventTimeRange,
  convertTime,
} from "../../../utils/timeUtils";

import {
  doc,
  getDoc,
  collection,
  writeBatch,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
  increment,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";
import EditAttendeesModal from "../../../components/Modals/EditAttendeesModal";
function Event() {
  const [loadingStates, setLoadingStates] = useState({
    eventData: true,
    hosts: true,
    attendees: true,
    connections: true,
    attendanceStatus: true,
    activities: true,
    eventStatus: true, // Add this new loading state
  });

  const isLoading = Object.values(loadingStates).some(
    (state) => state === true
  );

  const [isEventClosed, setIsEventClosed] = useState(false);

  const [userTimezone, setUserTimezone] = useState(getUserTimezone());
  const [
    editOrganiserModalScrollPosition,
    setEditOrganiserModalScrollPosition,
  ] = useState(0);
  const [isEditOrganiserModalOpen, setIsEditOrganiserModalOpen] =
    useState(false);
  const [addAttendeeModalScrollPosition, setAddAttendeeModalScrollPosition] =
    useState(0);
  const [isAddAttendeeModalOpen, setIsAddAttendeeModalOpen] = useState(false);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [hasAvailableConnections, setHasAvailableConnections] = useState(false);
  const [showEditAttendeeModal, setShowEditAttendeeModal] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const { eventUrlSlug } = useParams();
  const navigate = useNavigate();
  const [editAttendeeModalScrollPosition, setEditAttendeeModalScrollPosition] =
    useState(0);
  const [isEditAttendeeModalOpen, setIsEditAttendeeModalOpen] = useState(false);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [eventPosts, setEventPosts] = useState([]);
  const [eventReactions, setEventReactions] = useState([]);
  const [eventMessages, setEventMessages] = useState([]);
  const [eventReposts, setEventReposts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventData, setEventData] = useState({
    eventName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    eventImage: "",
    intro: "",
    introHtml: "",
    tags: [],
    isOnline: false,
  });

  const [showAddAttendeeModal, setShowAddAttendeeModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null);
  const [tagsModalScrollPosition, setTagsModalScrollPosition] = useState(0);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [
    editSingleEventModalScrollPosition,
    setEditSingleEventModalScrollPosition,
  ] = useState(0);
  const [isEditSingleEventModalOpen, setIsEditSingleEventModalOpen] =
    useState(false);
  const [addOrganiserModalScrollPosition, setAddOrganiserModalScrollPosition] =
    useState(0);
  const [isAddOrganiserModalOpen, setIsAddOrganiserModalOpen] = useState(false);

  const [
    hasAvailableAttendeesConnections,
    setHasAvailableAttendeesConnections,
  ] = useState(false);
  const [inviteCount, setInviteCount] = useState(1);
  const [removeCount, setRemoveCount] = useState(1);
  const [showAddOrganiserModal, setShowAddOrganiserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pendingInvites, setPendingInvites] = useState(new Set());
  const [attendees, setAttendees] = useState([]);
  const [showAllAttendees, setShowAllAttendees] = useState(false);
  const [displayedAttendees, setDisplayedAttendees] = useState(3);
  const [hosts, setHosts] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isOwner, setIsOwner] = useState(false);
  const [eventNotFound, setEventNotFound] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showEditSingleEventModal, setShowEditSingleEventModal] =
    useState(false);
  const [showEditOrganiserModal, setShowEditOrganiserModal] = useState(false);
  const [visibleHosts, setVisibleHosts] = useState(3);
  const [visibleAttendees, setVisibleAttendees] = useState(3);
  const [visibleTags, setVisibleTags] = useState(9);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [displayedHosts, setDisplayedHosts] = useState(3);
  const [attendeesCount, setAttendeesCount] = useState(0);
  const [attendeeStatus, setAttendeeStatus] = useState("Attend");
  const [activityType, setActivityType] = useState("Posts");
  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    messages: 0,
  });

  // Modal states

  useEffect(() => {
    console.log("Event closed status:", isEventClosed); // For debugging
  }, [isEventClosed]);

  const [showInvitePopup, setShowInvitePopup] = useState(false);

  const toggleShowAllKeywords = () => setShowAllKeywords(true);

  const handleAttendeeRemoved = (count) => {
    setRemoveCount(count);
    setShowRemovePopup(true);
    fetchAttendees(); // Re-fetch attendees list
  };

  const toggleAddAttendeeModal = () => {
    if (!showAddAttendeeModal) {
      setAddAttendeeModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addAttendeeModalScrollPosition);
      }, 0);
    }
    setShowAddAttendeeModal(!showAddAttendeeModal);
    setIsAddAttendeeModalOpen(!isAddAttendeeModalOpen);
  };

  const toggleEditAttendeeModal = () => {
    if (!showEditAttendeeModal) {
      setEditAttendeeModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editAttendeeModalScrollPosition);
      }, 0);
    }
    setShowEditAttendeeModal(!showEditAttendeeModal);
    setIsEditAttendeeModalOpen(!isEditAttendeeModalOpen);
  };

  const toggleAddOrganiserModal = () => {
    if (!showAddOrganiserModal) {
      setAddOrganiserModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addOrganiserModalScrollPosition);
      }, 0);
    }
    setShowAddOrganiserModal(!showAddOrganiserModal);
    setIsAddOrganiserModalOpen(!isAddOrganiserModalOpen);
  };

  const toggleEditOrganiserModal = () => {
    if (!showEditOrganiserModal) {
      setEditOrganiserModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editOrganiserModalScrollPosition);
      }, 0);
    }
    setShowEditOrganiserModal(!showEditOrganiserModal);
    setIsEditOrganiserModalOpen(!isEditOrganiserModalOpen);
  };

  const checkAvailableAttendeesConnections = async () => {
    if (!currentUser?.uid || !eventData.id || !eventData.ownerId) return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get all attendees
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      const attendeeIds = new Set(attendeesSnapshot.docs.map((doc) => doc.id));

      // Check if there are any connections that aren't attendees
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !attendeeIds.has(doc.id)
      );

      setHasAvailableAttendeesConnections(hasAvailable);
    } catch (error) {
      console.error("Error checking available connections:", error);
    }
  };

  useEffect(() => {
    checkAvailableAttendeesConnections();
  }, [currentUser?.uid, eventData.id, eventData.ownerId]);

  const checkAvailableConnections = useCallback(async () => {
    if (!currentUser?.uid || !eventData.id || !eventData.ownerId) return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get all hosts (both active and pending)
      const hostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts`
      );
      const hostsSnapshot = await getDocs(hostsRef);

      const hostIds = new Set(hostsSnapshot.docs.map((doc) => doc.id));

      // Check if there are any connections that aren't hosts
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !hostIds.has(doc.id)
      );

      setHasAvailableConnections(hasAvailable);
    } catch (error) {
      console.error("Error checking available connections:", error);
    }
  }, [currentUser?.uid, eventData.id, eventData.ownerId]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  // Make sure this useEffect dependency array includes checkAvailableConnections
  useEffect(() => {
    checkAvailableConnections();
  }, [checkAvailableConnections]);

  useEffect(() => {
    let mounted = true;

    const checkConnections = async () => {
      if (mounted) {
        await checkAvailableConnections();
      }
    };

    checkConnections();

    return () => {
      mounted = false;
    };
  }, [checkAvailableConnections]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchPendingInvites = async () => {
      if (!eventData?.id || !eventData?.ownerId) return;

      try {
        const hostsRef = collection(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/hosts`
        );
        const q = query(hostsRef, where("status", "==", "pending"));
        const snapshot = await getDocs(q);

        const pendingIds = new Set();
        snapshot.forEach((doc) => {
          pendingIds.add(doc.id);
        });
        setPendingInvites(pendingIds);
      } catch (error) {
        console.error("Error fetching pending invites:", error);
      }
    };

    fetchPendingInvites();
  }, [eventData?.id, eventData?.ownerId]);

  // Add this handler for the InvitePopup
  const handleInvitePopupClose = () => {
    setShowInvitePopup(false);
  };

  // Add this new handler function
  const handleRemoveInvite = async (userId) => {
    try {
      const batch = writeBatch(firestore);

      // Delete the host document
      const hostRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts/${userId}`
      );
      batch.delete(hostRef);

      // Delete the notification sent to the user
      const userNotificationsRef = collection(
        firestore,
        `users/${userId}/notifications`
      );
      const notifQuery = query(
        userNotificationsRef,
        where("type", "==", "organiser_invite"),
        where("eventId", "==", eventData.id)
      );
      const notifSnapshot = await getDocs(notifQuery);
      notifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Update local state
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });

      setOpenDropdown(null);
    } catch (error) {
      console.error("Error removing invite:", error);
    }
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  // Add this function with your other utility functions
  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Add this function with your other handlers
  const onInviteSent = () => {
    setShowInvitePopup(true);
  };

  const handleRemoveHost = async (e, hostId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const batch = writeBatch(firestore);

      // Remove user from event's hosts collection
      const hostRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts/${hostId}`
      );
      batch.delete(hostRef);

      // Delete "joined as organiser" notification from owner's notifications
      const ownerNotificationsRef = collection(
        firestore,
        `users/${eventData.ownerId}/notifications`
      );
      const ownerNotifQuery = query(
        ownerNotificationsRef,
        where("type", "==", "organiser_invite_accepted"),
        where("from", "==", hostId),
        where("eventId", "==", eventData.id)
      );
      const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
      ownerNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete "invite accepted/response" notification from host's notifications
      const hostNotificationsRef = collection(
        firestore,
        `users/${hostId}/notifications`
      );
      const hostNotifQuery = query(
        hostNotificationsRef,
        where("type", "==", "organiser_invite_response"),
        where("eventId", "==", eventData.id)
      );
      const hostNotifSnapshot = await getDocs(hostNotifQuery);
      hostNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Update local state
      setHosts((prev) => prev.filter((host) => host.id !== hostId));
      setOpenDropdown(null);

      // Fetch updated hosts list
      fetchHosts();
    } catch (error) {
      console.error("Error removing host:", error);
    }
  };

  const handleShare = useCallback(() => {
    const eventUrl = `${window.location.origin}/event/${eventUrlSlug}`;
    navigator.clipboard
      .writeText(eventUrl)
      .then(() => {
        setShareButtonText("Copied");
        setTimeout(() => {
          setShareButtonText("Share");
        }, 5000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  }, [eventUrlSlug]);

  const fetchAttendees = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesQuery = query(attendeesRef, orderBy("attendedAt", "desc"));
      const attendeesSnapshot = await getDocs(attendeesQuery);

      const attendeesData = await Promise.all(
        attendeesSnapshot.docs.map(async (attendeeDoc) => {
          const userUUID = attendeeDoc.id;
          const attendeeData = attendeeDoc.data();

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let connectionType = "Extended";
            if (currentUser) {
              connectionType = await checkConnectionType(
                currentUser.uid,
                userUUID
              );
            }

            return {
              id: attendeeDoc.id,
              userUUID: userUUID,
              attendedAt: attendeeData.attendedAt,
              connectionType: connectionType,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validAttendees = attendeesData
        .filter((attendee) => attendee !== null)
        .sort((a, b) => b.attendedAt - a.attendedAt);

      setAttendees(validAttendees);
      setAttendeesCount(validAttendees.length);
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  };

  // Add this useEffect to fetch attendees when event data is loaded
  useEffect(() => {
    if (eventData.id && eventData.ownerId) {
      fetchAttendees();
    }
  }, [eventData.id, eventData.ownerId]);

  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);

  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const handleAddOrganiserModalClose = (result) => {
    if (result === "success") {
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.add(selectedUser);
        return newSet;
      });
      onInviteSent();
    }
    setShowAddOrganiserModal(false);
    setSelectedUser(null);
  };

  // In the fetchHosts function, simply treat owner like any other user
  const fetchHosts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    updateLoadingState("hosts", true);
    try {
      const hostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts`
      );
      const hostsQuery = query(hostsRef, where("status", "==", "active"));
      const hostsSnapshot = await getDocs(hostsQuery);

      // Get owner first with correct connection type
      const ownerDoc = await getDoc(doc(firestore, "users", eventData.ownerId));
      const ownerData = ownerDoc.exists() ? ownerDoc.data() : null;

      let hosts = [];
      if (ownerData) {
        const ownerConnectionType = currentUser
          ? await checkConnectionType(currentUser.uid, eventData.ownerId)
          : "Extended";

        hosts.push({
          id: eventData.ownerId,
          userUUID: eventData.ownerId,
          connectionType: ownerConnectionType,
          isOwner: true, // Add this flag
          userData: {
            firstName: ownerData.firstName || "",
            lastName: ownerData.lastName || "",
            profileImage: ownerData.profileImage || "",
            bio: ownerData.bio || "",
            link: ownerData.link || "",
            linkText: ownerData.linkText || "",
            urlSlug: ownerData.urlSlug || "",
          },
        });
      }

      // Then get other hosts
      const otherHosts = await Promise.all(
        hostsSnapshot.docs.map(async (hostDoc) => {
          const userUUID = hostDoc.id;
          if (userUUID === eventData.ownerId) return null; // Skip owner since we already added them

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = currentUser
              ? await checkConnectionType(currentUser.uid, userUUID)
              : "Extended";

            return {
              id: hostDoc.id,
              userUUID: userUUID,
              connectionType: connectionType,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      hosts = [...hosts, ...otherHosts.filter((host) => host !== null)];
      setHosts(hosts);
    } catch (error) {
      console.error("Error fetching hosts:", error);
    } finally {
      updateLoadingState("hosts", false);
    }
  };

  // Update useEffect to fetch hosts when event data is loaded
  useEffect(() => {
    if (eventData.id && eventData.ownerId) {
      fetchHosts();
    }
  }, [eventData.id, eventData.ownerId]);

  useEffect(() => {
    let mounted = true;

    const initializeApp = async () => {
      try {
        if (!eventUrlSlug) return;

        // Reset all loading states
        if (mounted) {
          setLoadingStates({
            eventData: true,
            hosts: true,
            attendees: true,
            connections: true,
            attendanceStatus: true,
            activities: true,
            eventStatus: true,
          });
        }

        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
          if (!mounted) return;

          if (user) {
            setCurrentUser(user);
            await fetchUserData(user.uid);
          } else {
            setCurrentUser(null);
            setIsOwner(false);
          }

          // First fetch event data and determine closed status
          if (mounted) {
            await fetchEventData(eventUrlSlug);
          }

          // Then load the rest of the data in parallel
          if (mounted) {
            await Promise.all([
              fetchHosts().then(
                () => mounted && updateLoadingState("hosts", false)
              ),
              fetchAttendees().then(
                () => mounted && updateLoadingState("attendees", false)
              ),
              checkAvailableConnections().then(
                () => mounted && updateLoadingState("connections", false)
              ),
              checkAttendanceStatus().then(
                () => mounted && updateLoadingState("attendanceStatus", false)
              ),
              fetchActivityData().then(
                () => mounted && updateLoadingState("activities", false)
              ),
            ]);
          }
        });

        return () => {
          mounted = false;
          unsubscribeAuth();
        };
      } catch (error) {
        console.error("Error initializing app:", error);
        if (mounted) {
          setEventNotFound(true);
          // Reset all loading states on error
          setLoadingStates({
            eventData: false,
            hosts: false,
            attendees: false,
            connections: false,
            attendanceStatus: false,
            activities: false,
            eventStatus: false,
          });
        }
      }
    };

    initializeApp();

    return () => {
      mounted = false;
    };
  }, [eventUrlSlug]);

  const fetchActivityData = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      await Promise.all([
        fetchEventPosts(),
        fetchEventReactions(),
        fetchEventMessages(),
        fetchEventReposts(),
      ]);
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };

  // Add this new effect to monitor ownership changes
  useEffect(() => {
    console.log("Ownership status changed:", {
      isOwner,
      currentUserId: currentUser?.uid,
      eventOwnerId: eventData?.ownerId,
    });
  }, [isOwner, currentUser?.uid, eventData?.ownerId]);

  // Add this after the userTimezone state declaration at the top of the component
  useEffect(() => {
    console.log("Time values:", {
      startTime: eventData.startTime,
      endTime: eventData.endTime,
      startDate: eventData.startDate,
      timezone: eventData.timezone,
      userTimezone,
    });
  }, [
    eventData.startTime,
    eventData.endTime,
    eventData.startDate,
    eventData.timezone,
    userTimezone,
  ]);

  const checkEventStatus = useCallback(() => {
    if (!eventData.endDate || !eventData.endTime || !eventData.timezone) {
      setIsEventClosed(false);
      return false;
    }

    try {
      // Convert the event's end time to the user's timezone
      const userTimezone = getUserTimezone();
      const localEndTime = convertTime(
        eventData.endTime,
        eventData.endDate,
        eventData.timezone,
        userTimezone
      );

      // Parse the local end time and handle AM/PM
      const timeMatch = localEndTime.match(/(\d+):(\d+)\s*(AM|PM)/i);
      if (!timeMatch) {
        setIsEventClosed(false);
        return false;
      }

      let [_, hours, minutes, period] = timeMatch;
      hours = parseInt(hours);
      minutes = parseInt(minutes);

      // Convert to 24-hour format
      if (period.toUpperCase() === "PM" && hours !== 12) {
        hours += 12;
      } else if (period.toUpperCase() === "AM" && hours === 12) {
        hours = 0;
      }

      const [day, month, year] = eventData.endDate
        .split("/")
        .map((num) => parseInt(num));

      // Create date object in user's local timezone with exact time
      const eventEndDate = new Date(year, month - 1, day, hours, minutes);
      const now = new Date();

      const isClosed = now > eventEndDate;
      setIsEventClosed(isClosed);
      return isClosed;
    } catch (error) {
      console.error("Error checking event status:", error);
      setIsEventClosed(false);
      return false;
    }
  }, [eventData.endDate, eventData.endTime, eventData.timezone]);

  // In Event.js, add this effect
  useEffect(() => {
    const updateEventStatus = async () => {
      if (!eventData.id || !eventData.ownerId) return;

      const isClosed = checkEventStatus();

      if (isClosed && !eventData.isClosed) {
        try {
          const eventRef = doc(
            firestore,
            `users/${eventData.ownerId}/events/${eventData.id}`
          );
          await updateDoc(eventRef, {
            isClosed: true,
            closedAt: new Date(),
          });

          setEventData((prev) => ({
            ...prev,
            isClosed: true,
            closedAt: new Date(),
          }));
        } catch (error) {
          console.error("Error updating event closed status:", error);
        }
      }
    };

    updateEventStatus();
  }, [eventData.id, eventData.ownerId, checkEventStatus]);

  // In fetchEventData function, modify the connection type check to be consistent:
  const fetchEventData = async (urlSlug) => {
    try {
      updateLoadingState("eventData", true);
      updateLoadingState("eventStatus", true);

      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let eventFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const eventsRef = collection(firestore, `users/${userDoc.id}/events`);
        const eventsQuery = query(
          eventsRef,
          where("eventUrlSlug", "==", urlSlug)
        );
        const eventsSnapshot = await getDocs(eventsQuery);

        if (!eventsSnapshot.empty) {
          eventFound = true;
          const eventDoc = eventsSnapshot.docs[0];
          const data = eventDoc.data();

          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          let ownerConnectionType = "Extended";
          if (currentUser) {
            ownerConnectionType = await checkConnectionType(
              currentUser.uid,
              userDoc.id
            );
          }

          const isCurrentUserOwner = currentUser?.uid === userDoc.id;
          setIsOwner(isCurrentUserOwner);

          const isClosed = checkEventStatus();
          setIsEventClosed(isClosed);

          const eventDataObj = {
            id: eventDoc.id,
            ownerId: userDoc.id,
            eventName: data.eventName || "",
            tagline: data.tagline || "",
            link: data.link || "",
            linkText: data.linkText || "",
            eventImage: data.eventImage || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            tags: data.tags || [],
            isOnline: data.isOnline || false,
            city: data.city || "",
            country: data.country || "",
            address: data.address || "",
            startDate: data.startDate || "",
            endDate: data.endDate || "",
            startTime: data.startTime || "",
            endTime: data.endTime || "",
            timezone: data.timezone || "",
            eventUrlSlug: data.eventUrlSlug || "",
            isClosed: isClosed,
            closedAt: isClosed ? new Date() : null,
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              bio: ownerData.bio || "",
              link: ownerData.link || "",
              linkText: ownerData.linkText || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
              connectionType: ownerConnectionType,
            },
          };

          setEventData(eventDataObj);

          if (isClosed && !data.isClosed) {
            const eventRef = doc(
              firestore,
              `users/${userDoc.id}/events/${eventDoc.id}`
            );
            await updateDoc(eventRef, {
              isClosed: true,
              closedAt: new Date(),
            });
          }

          await Promise.all([
            fetchHosts(),
            fetchAttendees(),
            checkAvailableConnections(),
            checkAvailableAttendeesConnections(),
            checkAttendanceStatus(),
          ]);

          break;
        }
      }

      if (!eventFound) {
        setEventNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
      setEventNotFound(true);
      throw error;
    } finally {
      updateLoadingState("eventData", false);
      updateLoadingState("eventStatus", false);
    }
  };

  const LoadingSpinner = () => (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );

  // Add this effect to monitor ownership changes and auth state
  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            ...userData,
          });
        }
        // Check if the current user is the event owner
        if (eventData?.ownerId) {
          setIsOwner(user.uid === eventData.ownerId);
        }
      } else {
        setCurrentUser(null);
        setIsOwner(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, [eventData?.ownerId]);

  // Helper function to check if user should see owner controls
  const shouldShowContent = (contentType) => {
    switch (contentType) {
      case "editButtons":
        return isOwner;
      case "intro":
        return isOwner || (!isOwner && eventData.introHtml);
      case "tags":
        return (
          isOwner || (!isOwner && eventData.tags && eventData.tags.length > 0)
        );
      case "hosts":
        return isOwner || hosts.length > 0;
      default:
        return false;
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser((prev) => ({
          ...prev,
          ...userData,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleEventUpdated = async (updatedEvent) => {
    try {
      const batch = writeBatch(firestore);

      // Update main event document
      const eventRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}`
      );
      batch.update(eventRef, updatedEvent);

      // Get all attendees
      const attendeesSnapshot = await getDocs(
        collection(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/attendees`
        )
      );

      // Update each attendee's eventsAttending record
      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${eventData.id}`
        );

        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      setEventData((prev) => ({
        ...prev,
        ...updatedEvent,
      }));
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const toggleTagsModal = () => {
    if (!showTagsModal) {
      // Store current scroll position when opening modal
      setTagsModalScrollPosition(window.scrollY);
    } else {
      // Restore scroll position when closing modal
      setTimeout(() => {
        window.scrollTo(0, tagsModalScrollPosition);
      }, 0);
    }
    setShowTagsModal(!showTagsModal);
    setIsTagsModalOpen(!isTagsModalOpen);
  };

  const toggleEditSingleEventModal = () => {
    if (!showEditSingleEventModal) {
      setEditSingleEventModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editSingleEventModalScrollPosition);
      }, 0);
    }
    setShowEditSingleEventModal(!showEditSingleEventModal);
    setIsEditSingleEventModalOpen(!isEditSingleEventModalOpen);
  };

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      // Store current scroll position when opening modal
      setIntroModalScrollPosition(window.scrollY);
    } else {
      // Restore scroll position when closing modal
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const renderActivityCard = () => {
    const hasAnyActivity =
      activityCounts.posts > 0 ||
      activityCounts.reactions > 0 ||
      activityCounts.reposts > 0 ||
      activityCounts.messages > 0;

    // If no activity and not owner, don't show card at all
    if (!hasAnyActivity && !isOwner) {
      return null;
    }

    // Update activityCounts to match the case used in ActivityButtons component
    const formattedActivityCounts = {
      Posts: activityCounts.posts,
      Reactions: activityCounts.reactions,
      Messages: activityCounts.messages,
      Reposts: activityCounts.reposts,
    };

    return (
      <div className={classes.activityCard}>
        <div
          className={`${classes.activityCardTitle} ${
            hasAnyActivity ? "" : classes.emptyCardTitle
          }`}
        >
          Activity
        </div>

        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={formattedActivityCounts}
          // Only show buttons with activity if not owner
          showAllButtons={isOwner}
        />

        {activityType === "Posts" && renderPosts()}
        {activityType === "Reactions" && renderReactions()}
        {activityType === "Comments" && renderComments()}
        {activityType === "Reposts" && renderReposts()}
      </div>
    );
  };

  useEffect(() => {
    if (activityCounts) {
      // If owner, default to Posts
      if (isOwner) {
        setActivityType("Posts");
        return;
      }

      // For non-owners, find first activity type with content
      const activityTypes = ["Posts", "Reactions", "Messages", "Reposts"];
      const firstActiveType = activityTypes.find(
        (type) => activityCounts[type.toLowerCase()] > 0
      );

      if (firstActiveType) {
        setActivityType(firstActiveType);
      }
    }
  }, [activityCounts, isOwner]);

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
    if (currentUser?.uid) {
      const activityTypeMap = {
        Posts: fetchEventPosts,
        Reactions: fetchEventReactions,
        Messages: fetchEventMessages,
        Reposts: fetchEventReposts,
      };

      const fetchFunction = activityTypeMap[selectedActivity];
      if (fetchFunction) {
        fetchFunction();
      }
    }
  };

  const renderPosts = () => {
    if (loadingPosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't posted yet</p>
          <p className={classes.defaultText}>
            Posts you create will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> posted this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <div className={classes.defaultImage}>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't commented yet</p>
          <p className={classes.defaultText}>
            Posts you comment on will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> commented on this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <div className={classes.defaultImage}>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't reacted yet</p>
          <p className={classes.defaultText}>
            Posts you react to will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}>
              {" "}
              reacted <span className={classes.textEmoji}>🐘</span> to this{" "}
            </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <div className={classes.defaultImage}>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't reposted yet</p>
          <p className={classes.defaultText}>
            Posts you share will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> reposted this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <div className={classes.defaultImage}>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleAttend = async () => {
    if (!currentUser || !eventData.id || !eventData.ownerId) return;

    const batch = writeBatch(firestore);

    const attendeeRef = doc(
      firestore,
      `users/${eventData.ownerId}/events/${eventData.id}/attendees/${currentUser.uid}`
    );

    const userEventRef = doc(
      firestore,
      `users/${currentUser.uid}/eventsAttending/${eventData.id}`
    );

    const eventRef = doc(
      firestore,
      `users/${eventData.ownerId}/events/${eventData.id}`
    );

    const userRef = doc(firestore, "users", currentUser.uid);

    try {
      if (attendeeStatus === "Attend") {
        batch.set(attendeeRef, {
          attendedAt: new Date(),
        });

        batch.set(userEventRef, {
          eventId: eventData.id,
          eventOwnerId: eventData.ownerId,
          attendedAt: new Date(),
          endDate: eventData.endDate,
          endTime: eventData.endTime,
          timezone: eventData.timezone,
          eventName: eventData.eventName,
          eventImage: eventData.eventImage,
          eventUrlSlug: eventData.eventUrlSlug,
          isOnline: eventData.isOnline,
          city: eventData.city,
          country: eventData.country,
        });

        batch.update(eventRef, {
          attendeeCount: increment(1),
        });

        batch.update(userRef, {
          attendingCount: increment(1),
        });

        const notificationRef = doc(
          collection(firestore, `users/${eventData.ownerId}/notifications`)
        );
        batch.set(notificationRef, {
          type: "event_attend",
          from: currentUser.uid,
          eventId: eventData.id,
          eventName: eventData.eventName,
          eventUrlSlug: eventData.eventUrlSlug || null,
          eventImage: eventData.eventImage || null,
          eventOwnerId: eventData.ownerId,
          createdAt: new Date(),
          isNew: true,
          message: isEventClosed ? "attended this." : "is now an attendee.",
        });

        await batch.commit();

        setAttendeeStatus("Attending");
        setAttendeesCount((prev) => prev + 1);
        fetchAttendees();
      } else {
        batch.delete(attendeeRef);
        batch.delete(userEventRef);

        batch.update(eventRef, {
          attendeeCount: increment(-1),
        });

        batch.update(userRef, {
          attendingCount: increment(-1),
        });

        const notificationsRef = collection(
          firestore,
          `users/${eventData.ownerId}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "==", "event_attend"),
          where("from", "==", currentUser.uid),
          where("eventId", "==", eventData.id)
        );

        const notificationsSnapshot = await getDocs(q);
        notificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();

        setAttendeeStatus("Attend");
        setAttendeesCount((prev) => Math.max(0, prev - 1));
        fetchAttendees();
      }
    } catch (error) {
      console.error("Error handling attend:", error);
    }
  };

  const fetchEventPosts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const postsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/posts`
      );
      const postsQuery = query(
        postsRef,
        orderBy("createdAt", "desc"),
        limit(20)
      );
      const postsSnapshot = await getDocs(postsQuery);

      const postsData = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setEventPosts(postsData);
    } catch (error) {
      console.error("Error fetching event posts:", error);
    }
  };

  const checkAttendanceStatus = useCallback(async () => {
    if (!currentUser || !eventData.id || !eventData.ownerId) return;

    try {
      const attendeeRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees/${currentUser.uid}`
      );
      const attendeeDoc = await getDoc(attendeeRef);

      if (attendeeDoc.exists()) {
        setAttendeeStatus("Attending");
      } else {
        setAttendeeStatus("Attend");
      }
    } catch (error) {
      console.error("Error checking attendance status:", error);
      setAttendeeStatus("Attend");
    }
  }, [currentUser, eventData.id, eventData.ownerId]);

  const RemovePopup = ({ onClose, count = 1, type = "organiser" }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>
          {count > 1
            ? `${count} ${type}s removed.`
            : `${type.charAt(0).toUpperCase() + type.slice(1)} removed.`}
        </p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const InvitePopup = ({ onClose, isModalOpen, count = 1 }) => (
    <div
      className={`${classes.repostPopup} ${
        isModalOpen ? classes.modalOpen : classes.modalClosed
      }`}
    >
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{count > 1 ? "Invites sent." : "Invite sent."}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Add useEffect to check attendance status when component mounts
  useEffect(() => {
    if (currentUser && eventData.id) {
      checkAttendanceStatus();
    }
  }, [currentUser, eventData.id, checkAttendanceStatus]);

  const fetchEventReactions = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const reactionsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/reactions`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = reactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        reactedAt: doc.data().reactedAt?.toDate() || new Date(),
      }));

      setEventReactions(reactionsData);
    } catch (error) {
      console.error("Error fetching event reactions:", error);
    }
  };

  const fetchEventMessages = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const messagesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/messages`
      );
      const messagesQuery = query(
        messagesRef,
        orderBy("createdAt", "desc"),
        limit(20)
      );
      const messagesSnapshot = await getDocs(messagesQuery);

      const messagesData = messagesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setEventMessages(messagesData);
    } catch (error) {
      console.error("Error fetching event messages:", error);
    }
  };

  const fetchEventReposts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const repostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = repostsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        repostedAt: doc.data().repostedAt?.toDate() || new Date(),
      }));

      setEventReposts(repostsData);
    } catch (error) {
      console.error("Error fetching event reposts:", error);
    }
  };

  const renderIntroText = () => {
    const introText = eventData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: <span dangerouslySetInnerHTML={{ __html: introText }} />,
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: <span dangerouslySetInnerHTML={{ __html: truncatedText }} />,
        showMore: true,
      };
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (eventNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.errorMessage}>Event not found</div>
          </div>
        </div>
      </div>
    );
  }

  const formattedActivityCounts = {
    Posts: activityCounts.posts,
    Reactions: activityCounts.reactions,
    Messages: activityCounts.messages,
    Reposts: activityCounts.reposts,
  };

  const formatTimeWithTimezone = (time, timezone) => {
    if (!time || !timezone) return time;
    return `${time} (${timezone.replace(/_/g, " ")})`;
  };

  const renderActionButtons = () => {
    if (isEventClosed) {
      return (
        <div className={classes.buttons}>
          <button className={classes.following}>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                clipRule="evenodd"
              />
            </svg>
            Closed
          </button>
          <div
            className={`${
              shareButtonText === "Copied" ? classes.following : classes.button
            }`}
            onClick={handleShare}
          >
            {shareButtonText === "Copied" ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
              </svg>
            )}
            {shareButtonText}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${classes.page} ${
        isTagsModalOpen ||
        isIntroModalOpen ||
        isEditSingleEventModalOpen ||
        isAddOrganiserModalOpen ||
        isEditOrganiserModalOpen ||
        isAddAttendeeModalOpen ||
        isEditAttendeeModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isTagsModalOpen
          ? { top: `-${tagsModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isEditSingleEventModalOpen
          ? { top: `-${editSingleEventModalScrollPosition}px` }
          : isAddOrganiserModalOpen
          ? { top: `-${addOrganiserModalScrollPosition}px` }
          : isEditOrganiserModalOpen
          ? { top: `-${editOrganiserModalScrollPosition}px` }
          : isAddAttendeeModalOpen
          ? { top: `-${addAttendeeModalScrollPosition}px` }
          : isEditAttendeeModalOpen // Add this line
          ? { top: `-${editAttendeeModalScrollPosition}px` } // Add this line
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showEditSingleEventModal && (
        <EditSingleEventModal
          event={eventData}
          onClose={toggleEditSingleEventModal}
          onUpdate={handleEventUpdated} // This should be the function name you're using
          currentUser={currentUser}
          isFromEventPage={true}
        />
      )}
      {showEditAttendeeModal && (
        <EditAttendeesModal
          onClose={async (result) => {
            if (result === "success") {
              await fetchAttendees();
            }
            toggleEditAttendeeModal(); // Use the new toggle function
          }}
          eventData={eventData}
          currentUser={currentUser}
          onAttendeeRemoved={(count) => {
            setRemoveCount(count);
            setCurrentModalType("attendee");
            setShowRemovePopup(true);
          }}
        />
      )}
      {showAddAttendeeModal && (
        <AddAttendeeModal
          onClose={async (result) => {
            if (result === "success") {
              await fetchAttendees();
              await checkAvailableAttendeesConnections();
            }
            toggleAddAttendeeModal(); // Use the new toggle function
          }}
          eventData={eventData}
          currentUser={currentUser}
          onInviteSent={(count) => {
            setInviteCount(count);
            setShowInvitePopup(true);
          }}
        />
      )}

      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isEvent={true}
          eventData={eventData}
          eventOwnerId={eventData.ownerId}
          onProfileUpdate={(updatedData) => {
            setEventData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}

      {showTagsModal && (
        <TagsModal
          onClose={() => {
            toggleTagsModal();
            setTimeout(() => {
              window.scrollTo(0, tagsModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          onTagsUpdate={handleEventUpdated}
          eventId={eventData.id}
          eventOwnerId={eventData.ownerId}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={isOwner ? toggleEditSingleEventModal : undefined}
                style={{ cursor: isOwner ? "pointer" : "default" }}
              >
                <img src={eventData.eventImage || eventImage} alt="Event" />
              </div>
              {shouldShowContent("editButtons") && (
                <div onClick={toggleEditSingleEventModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {eventData.eventName}
              <span className={classes.connectionType}>• Event</span>
            </div>

            <div>
              {eventData.tagline && (
                <div className={classes.startupBio}>{eventData.tagline}</div>
              )}

              {eventData.link && (
                <div className={classes.link}>
                  <a
                    href={eventData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {eventData.linkText || eventData.link}
                  </a>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangeRed"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              )}

              {isOwner ? (
                <div className={classes.buttons}>
                  {!eventData.tagline && (
                    <div
                      className={classes.headlineButton}
                      onClick={toggleEditSingleEventModal}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a tagline
                    </div>
                  )}
                  {!eventData.link && (
                    <div
                      className={classes.button}
                      onClick={toggleEditSingleEventModal}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a link
                    </div>
                  )}

                  <div
                    className={`${
                      shareButtonText === "Copied"
                        ? classes.following
                        : classes.button
                    }`}
                    onClick={handleShare}
                  >
                    {shareButtonText === "Copied" ? (
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                      </svg>
                    )}
                    {shareButtonText}
                  </div>
                </div>
              ) : (
                <div className={classes.buttons}>
                  {!isOwner && (
                    <button
                      className={
                        attendeeStatus === "Attended" ||
                        attendeeStatus === "Attending"
                          ? classes.following
                          : classes.headlineButton
                      }
                      onClick={handleAttend}
                    >
                      {attendeeStatus === "Attended" ||
                      attendeeStatus === "Attending" ? (
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                            clipRule="evenodd"
                          />
                          <path
                            fillRule="evenodd"
                            d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {isEventClosed
                        ? attendeeStatus === "Attending" ||
                          attendeeStatus === "Attended"
                          ? "Attended"
                          : "Attended"
                        : attendeeStatus}
                    </button>
                  )}
                  <div
                    className={`${
                      shareButtonText === "Copied"
                        ? classes.following
                        : classes.button
                    }`}
                    onClick={handleShare}
                  >
                    {shareButtonText === "Copied" ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                      </svg>
                    )}
                    {shareButtonText}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.cardTitle}>
              About
              {shouldShowContent("editButtons") && (
                <div onClick={toggleIntroModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            <div className={classes.introText}>
              {eventData.introHtml ? (
                <pre>
                  {renderIntroText().text}
                  {renderIntroText().showMore && (
                    <span
                      className={classes.moreButton}
                      onClick={() => setShowFullIntro(true)}
                    >
                      ...more
                    </span>
                  )}
                </pre>
              ) : (
                isOwner && (
                  <span className={classes.emptyIntroText}>
                    Tell people about your event. What can attendees expect?
                    Share the details that will get people excited to attend.
                  </span>
                )
              )}
            </div>

            {/* Only show border if there's intro text OR if user is owner */}
            {(eventData.introHtml || isOwner) && (
              <div className={classes.noPostBorder}></div>
            )}

            {/* Rest of the About section */}
            <div className={classes.cardAbout}>
              {isEventClosed && (
                <div className={classes.eventPassedContainer}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className={classes.skillText}>
                    This event has already happened
                  </span>
                </div>
              )}
              {eventData.isOnline ? (
                <div className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className={classes.skillText}>Online</span>
                </div>
              ) : (
                <>
                  {eventData.address && (
                    <div className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9a7 7 0 1 1 8 6.93V21a1 1 0 1 1-2 0v-5.07A7.001 7.001 0 0 1 5 9Zm5.94-1.06A1.5 1.5 0 0 1 12 7.5a1 1 0 1 0 0-2A3.5 3.5 0 0 0 8.5 9a1 1 0 0 0 2 0c0-.398.158-.78.44-1.06Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className={classes.skillText}>
                        {eventData.address}
                      </span>
                    </div>
                  )}
                  {eventData.city && eventData.country && (
                    <div className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className={classes.skillText}>
                        {eventData.city}, {eventData.country}
                      </span>
                    </div>
                  )}
                </>
              )}
              {eventData.startDate && eventData.startTime && (
                <div className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 5.05h1a2 2 0 0 1 2 2v2H3v-2a2 2 0 0 1 2-2h1v-1a1 1 0 1 1 2 0v1h3v-1a1 1 0 1 1 2 0v1h3v-1a1 1 0 1 1 2 0v1Zm-15 6v8a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-8H3ZM11 18a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1Z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span className={classes.skillText}>
                    {eventData.startDate}
                    {eventData.startTime &&
                      eventData.timezone &&
                      `, ${formatEventTime(
                        eventData.startTime,
                        eventData.startDate,
                        eventData.timezone,
                        userTimezone,
                        eventData.startDate === eventData.endDate
                          ? eventData.endTime
                          : null
                      )}`}
                  </span>
                </div>
              )}

              {/* Only show end date/time if the dates are different */}
              {eventData.endDate &&
                eventData.startDate !== eventData.endDate && (
                  <div className={classes.skillContainer}>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 5V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v2H3V7a2 2 0 0 1 2-2h1ZM3 19v-8h18v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm5-6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className={classes.skillText}>
                      {eventData.endDate}
                      {eventData.endTime &&
                        `, ${formatEventTime(
                          eventData.endTime,
                          eventData.endDate,
                          eventData.timezone,
                          userTimezone
                        )}`}
                    </span>
                  </div>
                )}
              <div className={classes.skillContainer}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                  />
                </svg>
                <span className={classes.skillText}>
                  foundry.bz/event/{eventData.eventUrlSlug}
                </span>
              </div>
            </div>
          </div>

          {renderActivityCard()}
        </div>

        <div className={classes.rightCards}>
          {/* Hosts Card */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Organisers
              {isOwner && (
                <div className={classes.iconContainer}>
                  {hasAvailableConnections && (
                    <div onClick={toggleAddOrganiserModal}>
                      <svg
                        className={classes.addIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                    </div>
                  )}
                  {/* Only show edit icon if there are hosts besides the owner */}
                  {hosts.length > 1 && (
                    <div onClick={() => toggleEditOrganiserModal()}>
                      <svg
                        className={classes.experienceIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
            {showEditOrganiserModal && (
              <EditOrganisersModal
                onClose={async (result) => {
                  if (result === "success") {
                    await fetchHosts();
                    await checkAvailableConnections();
                  }
                  toggleEditOrganiserModal(); // Use the new toggle function
                }}
                eventData={eventData}
                currentUser={currentUser}
                onOrganiserRemoved={async (count) => {
                  setRemoveCount(count);
                  setCurrentModalType("organiser");
                  setShowRemovePopup(true);
                  await checkAvailableConnections();
                }}
              />
            )}
            {showRemovePopup && currentModalType === "organiser" && (
              <RemovePopup
                onClose={() => {
                  setShowRemovePopup(false);
                }}
                count={removeCount}
                type="organiser"
              />
            )}
            {showRemovePopup && currentModalType === "attendee" && (
              <RemovePopup
                onClose={() => {
                  setShowRemovePopup(false);
                }}
                count={removeCount}
                type="attendee"
              />
            )}

            {hosts.slice(0, visibleHosts).map((host) => (
              <div className={classes.userContainer}>
                <img
                  src={host.userData?.profileImage || defaultImage}
                  alt={`${host.userData?.firstName} ${host.userData?.lastName}`}
                  className={classes.userImage}
                  onClick={() => navigate(`/${host.userData?.urlSlug}`)}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }}
                />
                <div className={classes.userInfo}>
                  <div className={classes.userNameContainer}>
                    <span
                      className={classes.userName}
                      onClick={() => navigate(`/${host.userData?.urlSlug}`)}
                    >
                      {host.userData?.firstName} {host.userData?.lastName}
                    </span>
                    <span className={classes.userConnectionType}>
                      • {host.connectionType}
                    </span>
                  </div>
                  {host.userData?.bio && (
                    <div className={classes.userBio}>{host.userData.bio}</div>
                  )}
                  {host.userData?.link && (
                    <a
                      href={host.userData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.userLink}
                    >
                      <span>
                        {host.userData.linkText || host.userData.link}
                      </span>
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </a>
                  )}
                  {host.isOwner && (
                    <div className={classes.userBadge}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Owner
                    </div>
                  )}
                </div>
              </div>
            ))}

            {/* Show More/Less button */}
            {hosts.length > 3 && (
              <div
                className={classes.showAllExperiences}
                onClick={() => {
                  if (visibleHosts >= hosts.length) {
                    setVisibleHosts(3);
                  } else {
                    setVisibleHosts(Math.min(visibleHosts * 2, hosts.length));
                  }
                }}
              >
                {visibleHosts >= hosts.length ? "Show less" : "Show more"}
                {visibleHosts >= hosts.length ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>
          {showAddOrganiserModal && (
            <AddOrganisersModal
              onClose={(result) => {
                if (result === "success") {
                  setPendingInvites((prev) => {
                    const newSet = new Set(prev);
                    newSet.add(selectedUser);
                    return newSet;
                  });
                  onInviteSent();
                }
                toggleAddOrganiserModal();
              }}
              eventData={eventData}
              currentUser={currentUser}
              onInviteSent={(count) => {
                setInviteCount(count);
                setShowInvitePopup(true);
              }}
            />
          )}
          {showInvitePopup && (
            <InvitePopup
              onClose={handleInvitePopupClose}
              isModalOpen={showAddOrganiserModal}
              count={inviteCount} // Pass the count
            />
          )}
          {(attendees.length > 0 || isOwner) && (
            <div className={classes.card}>
              <div
                className={`${classes.cardTitle} ${
                  attendees.length === 0 ? classes.emptyCardTitle : ""
                }`}
              >
                Attendees
                {isOwner && (
                  <>
                    <div className={classes.iconContainer}>
                      {hasAvailableAttendeesConnections && (
                        <div onClick={toggleAddAttendeeModal}>
                          <svg
                            className={classes.addIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 12h14m-7 7V5"
                            />
                          </svg>
                        </div>
                      )}
                      {attendees.length > 0 && (
                        <div onClick={toggleEditAttendeeModal}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {attendees.length > 0 ? (
                <>
                  {attendees.slice(0, displayedAttendees).map((attendee) => (
                    <div key={attendee.id} className={classes.userContainer}>
                      <img
                        src={attendee.userData?.profileImage || defaultImage}
                        alt={`${attendee.userData?.firstName} ${attendee.userData?.lastName}`}
                        className={classes.userImage}
                        onClick={() =>
                          navigate(`/${attendee.userData?.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div
                            className={classes.userName}
                            onClick={() =>
                              navigate(`/${attendee.userData?.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {attendee.userData?.firstName}{" "}
                            {attendee.userData?.lastName}
                          </div>
                          <span className={classes.userConnectionType}>
                            • {attendee.connectionType}
                          </span>
                        </div>
                        {attendee.userData?.bio && (
                          <div className={classes.userBio}>
                            {attendee.userData.bio}
                          </div>
                        )}
                        {attendee.userData?.link && (
                          <a
                            href={attendee.userData.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.userLink}
                          >
                            <span>
                              {attendee.userData.linkText ||
                                attendee.userData.link}
                            </span>
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="orangeRed"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                  {attendees.length > 3 &&
                    displayedAttendees < attendees.length && (
                      <div
                        className={classes.showAllExperiences}
                        onClick={() => {
                          if (displayedAttendees >= attendees.length) {
                            setDisplayedAttendees(3);
                          } else {
                            setDisplayedAttendees(
                              Math.min(displayedAttendees * 2, attendees.length)
                            );
                          }
                        }}
                      >
                        {displayedAttendees >= attendees.length
                          ? "Show less"
                          : "Show more"}
                        {displayedAttendees >= attendees.length ? (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m5 15 7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                </>
              ) : isOwner ? (
                <>
                  <div>
                    <p className={classes.defaultText}>
                      Get your event noticed! Share it with your network and
                      invite people who might be interested.
                    </p>
                  </div>
                  <div className={classes.noPostBorder}></div>
                  <div className={classes.startupContainer}>
                    <img
                      src={defaultImage}
                      alt={defaultImage}
                      className={classes.userImage}
                    />
                    <div className={classes.startupAbout}>
                      <div className={classes.noStartupRole}>
                        Name
                        <span className={classes.userConnectionType}>
                          • Type
                        </span>
                      </div>
                      <div className={classes.noStartupName}>Bio</div>
                      <div className={classes.noLink}>
                        Link
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
          {shouldShowContent("tags") && (
            <div className={classes.card}>
              <div
                className={`${classes.cardTitle} ${
                  !eventData.tags || eventData.tags.length === 0
                    ? classes.emptyCardTitle
                    : ""
                }`}
              >
                Tags
                {shouldShowContent("editButtons") && (
                  <div onClick={toggleTagsModal}>
                    <svg
                      className={classes.closeIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className={classes.cardAbout}>
                {eventData.tags && eventData.tags.length > 0 ? (
                  <>
                    {eventData.tags.slice(0, visibleTags).map((tag, index) => (
                      <div key={index} className={classes.skillContainer}>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.skillText}>{tag}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  isOwner && (
                    <div className={classes.emptyContainer}>
                      <div className={classes.tagDefaultText}>
                        Add tags to help people find and understand your event.
                      </div>
                      <div className={classes.defaultContainer}>
                        <div className={classes.emptySkillContainer}>
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className={classes.skillText}>Tag</span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {eventData.tags && eventData.tags.length > 9 && (
                <div
                  className={classes.showAllExperiences}
                  onClick={() => {
                    if (visibleTags >= eventData.tags.length) {
                      setVisibleTags(9);
                    } else {
                      setVisibleTags(
                        Math.min(visibleTags * 2, eventData.tags.length)
                      );
                    }
                  }}
                >
                  {visibleTags >= eventData.tags.length
                    ? "Show less"
                    : "Show more"}
                  {visibleTags >= eventData.tags.length ? (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          )}
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Event;
