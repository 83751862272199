import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { firestore } from "../firebase";

const waitlist = async (email, setSuccess, setError) => {
  try {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }

    const waitlistRef = collection(firestore, "waitlist");
    const q = query(waitlistRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      await addDoc(waitlistRef, { email });
      setSuccess(true);
      setError(null);
    } else {
      setSuccess(false);
      setError("You're already on the waitlist.");
    }
  } catch (error) {
    setSuccess(false);
    setError("Hmm... something went wrong.");
  }
};

export { waitlist };
