import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditCommunityModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleCommunityModal from "./EditSingleCommunityModal";

const EditCommunityModal = ({ onClose, currentUser, onCommunityUpdated }) => {
  const [communities, setCommunities] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingCommunity, setEditingCommunity] = useState(null);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleCommunityUpdated = (updatedCommunity) => {
    // Update local state
    const updatedCommunities = communities.map((community) =>
      community.id === updatedCommunity.id
        ? {
            ...community,
            ...updatedCommunity,
            membershipType: community.membershipType,
            createdAt: community.createdAt,
          }
        : community
    );

    // Update local state
    setCommunities(updatedCommunities);

    // Pass the single updated community up to Profile.js
    onCommunityUpdated(updatedCommunity);
  };

  useEffect(() => {
    const fetchCommunities = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const communitiesCollectionRef = collection(userDocRef, "communities");
        const communitiesSnapshot = await getDocs(communitiesCollectionRef);

        const communitiesList = communitiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort communities by creation date (newest first)
        communitiesList.sort(
          (a, b) =>
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
        );

        setCommunities(communitiesList);
      } catch (error) {
        console.error("Error fetching communities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, [currentUser.uid]);

  const toggleDropdown = (communityId) => {
    setOpenDropdown(openDropdown === communityId ? null : communityId);
  };

  const handleEditClick = (community) => {
    setEditingCommunity(community);
    setOpenDropdown(null);
  };

  const handleDeleteCommunity = async (communityId) => {
    try {
      // Find the community to get its image URL
      const community = communities.find((c) => c.id === communityId);
      if (!community) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const communityDocRef = doc(userDocRef, "communities", communityId);

      // Delete the community document first
      await deleteDoc(communityDocRef);

      // Delete the image if it exists and is not the default image
      if (
        community.communityImage &&
        !community.communityImage.includes("startupImage.jpg")
      ) {
        try {
          const storage = getStorage();
          // Convert the full URL to a storage reference path
          const imageUrl = new URL(community.communityImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);

          await deleteObject(imageRef);
        } catch (error) {
          console.error("Error deleting community image:", error);
          // Continue with the community deletion even if image deletion fails
        }
      }

      // Update the local state
      const updatedCommunities = communities.filter(
        (community) => community.id !== communityId
      );
      setCommunities(updatedCommunities);

      // Notify parent component about the deletion with the correct format
      onCommunityUpdated({
        id: communityId,
        deleted: true,
      });

      setOpenDropdown(null);
    } catch (error) {
      console.error("Error deleting community:", error);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingCommunity) {
    return (
      <EditSingleCommunityModal
        community={editingCommunity}
        onClose={() => setEditingCommunity(null)}
        onUpdate={handleCommunityUpdated}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit Communities
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : communities.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No communities found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            communities.map((community) => (
              <div key={community.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {community.communityImage ? (
                      <img
                        src={community.communityImage}
                        alt={community.communityName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default community"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>
                      {community.communityName}
                    </h3>
                    {community.tagline && (
                      <p className={classes.role}>{community.tagline}</p>
                    )}
                    <p className={classes.bio}>Moderator</p>
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === community.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(community.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === community.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(community);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteCommunity(community.id);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCommunityModal;
