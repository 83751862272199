import React from "react";
import classes from "./DonationVisibilityToggle.module.css";

const DonationVisibilityToggle = ({
  isVisible = true,
  isOwner,
  isDonor,
  isDisabled,
  visibility,
  onToggle,
}) => {
  const shouldShowHiddenIcon =
    visibility.hiddenByStartup || visibility.hiddenByDonor;
  const shouldReduceOpacity = isDisabled;

  const handleToggle = (e) => {
    if (isDisabled) return;
    e.preventDefault();
    e.stopPropagation();
    onToggle(!isVisible);
  };

  if (!isOwner && !isDonor) return null;

  return (
    <div
      className={classes.optionsContainer}
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
    >
      <svg
        className={classes.optionsIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 24 24"
        onClick={handleToggle}
        style={{
          cursor: isDisabled ? "default" : "pointer",
          opacity: shouldReduceOpacity ? 0.5 : 1,
          isolation: "isolate",
        }}
      >
        {!shouldShowHiddenIcon ? (
          <>
            <path
              stroke="gray"
              strokeWidth="2"
              d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
            />
            <path
              stroke="gray"
              strokeWidth="2"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </>
        ) : (
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        )}
      </svg>
    </div>
  );
};

export default DonationVisibilityToggle;
