import "./index.css";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { ChatProvider } from "./contexts/ChatContext";
import { BrowserRouter as Router } from "react-router-dom";
import { NotificationProvider } from "./contexts/NotificationContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChatProvider>
        <Router>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </Router>
      </ChatProvider>
    </AuthProvider>
  </React.StrictMode>
);
