import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD5c-Ecgxv0C5WrHNQx02lqrjrF00bkNYE",
  authDomain: "foundry-2024.firebaseapp.com",
  projectId: "foundry-2024",
  storageBucket: "foundry-2024.appspot.com",
  messagingSenderId: "608195603992",
  appId: "1:608195603992:web:eac09fbc51d9a375b89d6d",
  measurementId: "G-9ZZ740K9S9",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { app, firestore, auth, GoogleAuthProvider, signInWithPopup, storage };
