import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditEventModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleEventModal from "./EditSingleEventModal";

const EditEventModal = ({ onClose, currentUser, onEventUpdated }) => {
  const [events, setEvents] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleEventUpdated = (updatedEvent) => {
    // Update local state with the new event data
    const updatedEvents = events.map((event) =>
      event.id === updatedEvent.id ? updatedEvent : event
    );
    setEvents(updatedEvents);

    // Pass the complete updated event to Profile.js
    onEventUpdated(updatedEvent);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const eventsCollectionRef = collection(userDocRef, "events");
        const eventsSnapshot = await getDocs(eventsCollectionRef);

        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort events by creation date (newest first)
        eventsList.sort(
          (a, b) =>
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
        );

        setEvents(eventsList);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentUser.uid]);

  const toggleDropdown = (eventId) => {
    setOpenDropdown(openDropdown === eventId ? null : eventId);
  };

  const handleEditClick = (event) => {
    setEditingEvent(event);
    setOpenDropdown(null);
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      // Find the event to get its image URL
      const event = events.find((e) => e.id === eventId);
      if (!event) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const eventDocRef = doc(userDocRef, "events", eventId);

      // Delete the event document first
      await deleteDoc(eventDocRef);

      // Delete the image if it exists and is not the default image
      if (event.eventImage && !event.eventImage.includes("startupImage.jpg")) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(event.eventImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);

          await deleteObject(imageRef);
        } catch (error) {
          console.error("Error deleting event image:", error);
        }
      }

      // Update the local state
      const updatedEvents = events.filter((event) => event.id !== eventId);
      setEvents(updatedEvents);

      // Pass the deleted event's ID back to Profile.js
      onEventUpdated({
        id: eventId,
        deleted: true,
      });

      setOpenDropdown(null);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingEvent) {
    return (
      <EditSingleEventModal
        event={editingEvent}
        onClose={() => setEditingEvent(null)}
        onUpdate={handleEventUpdated}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit Events
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : events.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No events found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            events.map((event) => (
              <div key={event.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {event.eventImage ? (
                      <img
                        src={event.eventImage}
                        alt={event.eventName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default event"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>{event.eventName}</h3>
                    {event.tagline && (
                      <p className={classes.role}>{event.tagline}</p>
                    )}
                    <p className={classes.bio}>
                      {event.isOnline ? (
                        <>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-7-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                          </svg>
                          Online Event
                        </>
                      ) : (
                        <>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 2a6.5 6.5 0 0 1 6.5 6.5c0 2.191-.96 4.258-2.383 5.991-.718.872-1.503 1.632-2.197 2.178-.358.282-.712.528-1.01.71a4.87 4.87 0 0 1-.734.373c-.065.027-.328.13-.438.162-.407.119-.816.107-1.226-.037a3.664 3.664 0 0 1-.3-.125 4.68 4.68 0 0 1-.734-.373c-.299-.182-.653-.428-1.01-.71-.695-.546-1.48-1.306-2.198-2.178C5.459 12.758 4.5 10.691 4.5 8.5A6.5 6.5 0 0 1 12 2Zm0 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
                          </svg>
                          {event.city}, {event.country}
                        </>
                      )}
                    </p>
                    {event.link && (
                      <div className={classes.link}>
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.linkText || event.link}
                        </a>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === event.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(event.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === event.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(event);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteEvent(event.id);
                            }}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEventModal;
