import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  writeBatch,
  serverTimestamp,
  orderBy,
  addDoc,
  increment,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { onAuthStateChanged } from "firebase/auth";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./SingleReaction.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import RepostsModal from "../../components/Modals/RepostsModal";
import PostModal from "../../components/Modals/PostModal";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";

function SingleReaction() {
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [startupNotFound, setStartupNotFound] = useState(false);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [post, setPost] = useState(null);
  const [errorPopup, setErrorPopup] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const { reactionId } = useParams();
  const [reaction, setReaction] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [expandedPost, setExpandedPost] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [videoVolume, setVideoVolume] = useState(false);
  const videoRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [reactionMessage, setReactionMessage] = useState(null);
  const [isCurrentUserReaction, setIsCurrentUserReaction] = useState(false);
  const location = useLocation();
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [repostMessage, setRepostMessage] = useState(null);
  const [currentReactionEmoji, setCurrentReactionEmoji] = useState(null);
  const [isSafari, setIsSafari] = useState(false);
  const [reactionDisplayData, setReactionDisplayData] = useState(null);
  const [currentUserReaction, setCurrentUserReaction] = useState(null);

  // New state variables for comments
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [visibleComments, setVisibleComments] = useState(10);
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const commentInputRef = useRef(null);
  const replyInputRef = useRef({});

  const handleEditPost = () => {
    setPostModalScrollPosition(window.scrollY);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const fetchComments = useCallback(async () => {
    if (!reaction || !reaction.originalPost) return;

    try {
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${reaction.originalPost.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${reaction.originalPost.id}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));

      const snapshot = await getDocs(q);
      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          // Handle startup comments
          if (commentData.userId?.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            // Find the startup data
            const startupRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/startups/${startupId}`
            );
            const startupSnapshot = await getDoc(startupRef);

            if (!startupSnapshot.exists()) {
              console.log("Startup not found for comment:", startupId);
              return null;
            }

            const startupData = startupSnapshot.data();
            return {
              id: docSnapshot.id,
              ...commentData,
              userName: startupData.startupName,
              userImage: startupData.startupImage || profileImage,
              connectionType: "Startup",
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              startupUrlSlug: startupData.startupUrlSlug,
              postId: reaction.originalPost.id,
              postUserId: reaction.originalPost.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
            };
          }

          // Handle regular user comments
          const userDocRef = doc(firestore, "users", commentData.userId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (!userDocSnapshot.exists()) {
            console.log("User not found for comment:", commentData.userId);
            return null;
          }

          const userData = userDocSnapshot.data();
          let connectionType = "Unknown";
          try {
            connectionType = await checkConnectionType(
              currentUser.uid,
              commentData.userId
            );
          } catch (error) {
            console.error("Error checking connection type:", error);
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            userName: `${userData.firstName} ${userData.lastName}`,
            userImage: userData.profileImage || profileImage,
            connectionType,
            bio: userData.bio || "",
            link: userData.link || null,
            linkText: userData.linkText || null,
            userUrlSlug: userData.urlSlug,
            postId: reaction.originalPost.id,
            postUserId: reaction.originalPost.user.id,
            rootCommentId: commentData.rootCommentId,
            isRootComment: commentData.isRootComment,
          };
        })
      );

      // Filter out any null values (failed fetches)
      const validComments = fetchedComments.filter(
        (comment) => comment !== null
      );

      // Only set comments if they've actually changed
      setComments((current) => {
        const currentIds = new Set(current.map((c) => c.id));
        const newIds = new Set(validComments.map((c) => c.id));

        // Check if the comments are different
        if (
          current.length !== validComments.length ||
          ![...currentIds].every((id) => newIds.has(id))
        ) {
          return validComments;
        }
        return current;
      });
    } catch (error) {
      console.error("Error fetching comments:", error);
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    }
  }, [reaction, currentUser, checkConnectionType]);

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage || profileImage,
        bio: selectedStartup.bio || "",
        link: selectedStartup.link || null, // Ensure null instead of undefined
        linkText: selectedStartup.linkText || null, // Ensure null instead of undefined
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUserProfileImage || profileImage,
      bio: currentUser.bio || "",
      link: currentUser.link || null,
      linkText: currentUser.linkText || null,
      connectionType: "You",
      isStartup: false,
    };
  };

  // Function to add a comment
  const handleAddComment = async (parentCommentId = null) => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[parentCommentId] || "";
    } else {
      commentText = newComment;
    }

    if (!commentText.trim()) return;

    try {
      const commenterInfo = getCommenterInfo(currentUser, selectedStartup);
      const processedCommentText = convertUrlsToLinks(commentText);

      // Get current user data for instant display
      let userData;
      if (selectedStartup) {
        // Get startup data
        const startupRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${selectedStartup.id}`
        );
        const startupSnap = await getDoc(startupRef);
        if (startupSnap.exists()) {
          const startupData = startupSnap.data();
          userData = {
            ...commenterInfo,
            startupUrlSlug: startupData.startupUrlSlug || null,
            link: startupData.link || null,
            linkText: startupData.linkText || null,
            bio: startupData.bio || "",
            name: startupData.startupName,
            image: startupData.startupImage || profileImage,
          };
        }
      } else {
        // Get user data for regular user
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userDocData = userSnap.data();
          userData = {
            ...commenterInfo,
            userUrlSlug: userDocData.urlSlug || null,
            firstName: userDocData.firstName,
            lastName: userDocData.lastName,
            name: `${userDocData.firstName} ${userDocData.lastName}`, // Add this
            bio: userDocData.bio || "",
            link: userDocData.link || null,
            linkText: userDocData.linkText || null,
            image: userDocData.profileImage || profileImage,
          };
        }
      }

      // Get the correct path for comments
      const commentsPath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${reaction.originalPost.id}/comments`
        : `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`;

      const commentsRef = collection(firestore, commentsPath);

      // Get post reference
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${reaction.originalPost.id}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
          );

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();
      const rootCommentId = parentCommentId
        ? await getRootCommentId(
            reaction.originalPost.user.id,
            reaction.originalPost.id,
            parentCommentId
          )
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName:
          userData.name ||
          (selectedStartup
            ? selectedStartup.startupName
            : `${userData.firstName} ${userData.lastName}`),
        userImage: userData.image || profileImage,
        createdAt: now,
        parentCommentId: parentCommentId || null,
        rootCommentId: rootCommentId || null,
        isRootComment: !parentCommentId,
        bio: userData.bio || "",
        connectionType: selectedStartup ? "Startup" : "You",
        link: userData.link || null,
        linkText: userData.linkText || null,
        postUserId: reaction.originalPost.user.id,
        isStartup: Boolean(selectedStartup),
        ...(selectedStartup && {
          startupId: selectedStartup.id,
          startupOwnerId: currentUser.uid,
          startupUrlSlug: userData.startupUrlSlug || null,
        }),
        ...(!selectedStartup && {
          userUrlSlug: userData.userUrlSlug || null,
        }),
      };

      // With this new code:
      const finalCommentData = {
        ...commentData,
        createdAt: serverTimestamp(),
        // Ensure all optional fields are null instead of undefined
        link: commentData.link || null,
        linkText: commentData.linkText || null,
        bio: commentData.bio || "",
        startupUrlSlug: commentData.startupUrlSlug || null,
        userUrlSlug: commentData.userUrlSlug || null,
      };

      // Use finalCommentData in the addDoc call
      const newCommentRef = await addDoc(commentsRef, finalCommentData);

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      // Update commentedPosts collection for the commenter (user or startup)
      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${reaction.originalPost.id}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId:
          reaction.originalPost.user.startupOwnerId ||
          reaction.originalPost.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: reaction.originalPost.id,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      // Handle notifications for new comments
      const shouldNotifyPostOwner =
        !parentCommentId && // Is a root comment
        ((reaction.originalPost.user.isStartup &&
          currentUser.uid !== reaction.originalPost.user.startupOwnerId &&
          (!commenterInfo.isStartup ||
            commenterInfo.ownerId !==
              reaction.originalPost.user.startupOwnerId)) ||
          (!reaction.originalPost.user.isStartup &&
            currentUser.uid !== reaction.originalPost.user.id &&
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== reaction.originalPost.user.id)));

      if (shouldNotifyPostOwner) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: reaction.originalPost.id,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(postOwnerNotificationRef, notificationData);
      }

      // Handle reply notifications
      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          ((parentCommentData.isStartup &&
            currentUser.uid !== parentCommentData.startupOwnerId &&
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
            (!parentCommentData.isStartup &&
              currentUser.uid !== parentCommentData.userId &&
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.userId)));

        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const replyNotificationData = {
            type: "reply",
            postId: reaction.originalPost.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(replyNotificationRef, replyNotificationData);
        }
      }

      await batch.commit();

      // Update local state
      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        reactions: {},
        userUrlSlug: userData.userUrlSlug || null,
        startupUrlSlug: userData.startupUrlSlug || null,
        userName:
          userData.name ||
          (selectedStartup
            ? selectedStartup.startupName
            : `${userData.firstName} ${userData.lastName}`),
        userImage: userData.image || profileImage,
        bio: userData.bio || "",
        link: userData.link || null,
        linkText: userData.linkText || null,
        connectionType: selectedStartup ? "Startup" : "You",
        postId: reaction.originalPost.id,
        postUserId: reaction.originalPost.user.id,
        createdAt: now,
        isStartup: Boolean(selectedStartup),
        ...(selectedStartup && {
          startupId: selectedStartup.id,
          startupOwnerId: currentUser.uid,
        }),
      };

      // Update comments state
      setComments((prevComments) => [...prevComments, newCommentObject]);

      // Update reaction state
      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          commentCount: (prevReaction.originalPost.commentCount || 0) + 1,
          comments: {
            ...(prevReaction.originalPost.comments || {}),
            [commenterInfo.id]: now,
          },
          originalDirectCommentTime: now,
        },
      }));

      // Reset input states
      if (parentCommentId) {
        setReplyComments((prev) => ({ ...prev, [parentCommentId]: "" }));
      } else {
        setNewComment("");
        if (commentInputRef.current) {
          commentInputRef.current.style.height = "auto";
        }
      }
      setActiveCommentId(null);
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      // Use the correct path based on whether the post is from a startup
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${postUserId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(postUserId, postId, commentData.parentCommentId);
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const handleCommentInputChange = (e) => {
    const textarea = e.target;
    setNewComment(textarea.value);

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyInputChange = (e, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [replyId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = (commentId) => {
    const commentExists = comments.some(
      (c) => c.id === commentId || c.replies?.some((r) => r.id === commentId)
    );

    if (!commentExists) {
      setErrorPopup({
        message: "Cannot reply to a non-existent comment.",
        isError: true,
      });
      return;
    }

    setActiveCommentId((prevActiveCommentId) =>
      prevActiveCommentId === commentId ? null : commentId
    );
    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));

    // Add a slight delay before focusing
    setTimeout(() => {
      if (replyInputRef.current[commentId]) {
        const inputElement = replyInputRef.current[commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleLoadMoreComments = () => {
    setVisibleComments((prev) => prev + 10);
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const handleCommentReactionClick = (commentId) => {
    setCommentEmojiPickerScrollPosition(window.scrollY);
    setActiveEmojiPicker(activeEmojiPicker === commentId ? null : commentId);
    setActiveCommentForReaction(commentId);
  };

  const getReactionStatus = (comment) => {
    if (!currentUser) return false;
    const reactorId = selectedStartup
      ? `startup_${selectedStartup.id}`
      : currentUser.uid;
    return comment.reactions?.[reactorId] || null;
  };

  const handleCommentEmojiSelect = async (commentId, emoji) => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    try {
      // Get the correct comments path based on whether it's a startup post
      const commentsPath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${reaction.originalPost.id}/comments`
        : `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}/comments`;

      const commentRef = doc(firestore, commentsPath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        // Remove notification
        if (commentData.userId !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        // Add or update reaction
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        // Determine if notification should be sent
        const shouldNotify =
          (selectedStartup &&
            commentData.userId !== `startup_${selectedStartup.id}` &&
            (!commentData.isStartup ||
              commentData.startupId !== selectedStartup.id)) ||
          (!selectedStartup &&
            commentData.userId !== currentUser.uid &&
            (!commentData.isStartup ||
              commentData.startupOwnerId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: reaction.originalPost.id,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        })
      );

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment reaction:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown(
      openCommentDropdown === commentId ? null : commentId
    );
  };

  const renderCommentHeader = (comment) => {
    const isStartupComment = comment.isStartup;
    const profileUrl = isStartupComment
      ? `/startup/${comment.startupUrlSlug}`
      : `/${comment.userUrlSlug}`;

    return (
      <div className={classes.commentHeader}>
        <div className={classes.commentUserInfo}>
          <img
            src={comment.userImage}
            alt={comment.userName}
            className={classes.commentUserImage}
            onClick={() =>
              handleNavigate(
                isStartupComment ? comment.startupId : comment.userId
              )
            }
            style={{ cursor: "pointer" }}
          />
          <div className={classes.commentUserDetails}>
            <div className={classes.commentUserNameContainer}>
              <span
                className={classes.commentUserName}
                onClick={() =>
                  handleNavigate(
                    isStartupComment ? comment.startupId : comment.userId
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {comment.userName}
              </span>
              <span className={classes.commentConnectionType}>
                • {isStartupComment ? "Startup" : comment.connectionType}
              </span>
            </div>
            {comment.bio && (
              <p className={classes.commentUserBio}>{comment.bio}</p>
            )}
            {comment.link && (
              <div className={classes.commentUserLinkContainer}>
                <a
                  href={comment.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={classes.commentUserLink}
                >
                  <span className={classes.commentUserLinkText}>
                    {comment.linkText || comment.link}
                  </span>
                  <svg
                    className={classes.commentUserLinkIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangered"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </a>
              </div>
            )}
            <div className={classes.commentTimeAndEditDetails}>
              <p className={classes.commentTimestamp}>
                {formatTimestamp(comment.createdAt)}
              </p>
              {comment.edited && (
                <div className={classes.commentEditDot}>
                  •<span className={classes.commentEditText}>Edited</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteComment = async (commentId) => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    try {
      // Get the post owner's ID correctly
      const postOwnerId =
        reaction.originalPost.user.startupOwnerId ||
        reaction.originalPost.user.id;

      // Construct the correct base path for the post and its comments
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${postOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${postOwnerId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${reaction.originalPost.id}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.isStartup) {
        canDelete =
          (selectedStartup && selectedStartup.id === commentData.startupId) ||
          currentUser.uid === commentData.startupOwnerId ||
          currentUser.uid === postOwnerId;
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          currentUser.uid === postOwnerId;
      }

      if (!canDelete) {
        throw new Error("Not authorized to delete this comment");
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set([commentId]);
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        if (commentData.isStartup) {
          const replyNotificationsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("parentCommentId", "==", commentRef.id)
          );

          const replyNotificationsSnapshot = await getDocs(
            replyNotificationsQuery
          );
          await Promise.all(
            replyNotificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
          );

          const commentNotificationRef = doc(
            firestore,
            `users/${commentData.startupOwnerId}/notifications`,
            `comment_${commentRef.id}`
          );
          await deleteDoc(commentNotificationRef);
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            firestore,
            `${basePath}/posts/${reaction.originalPost.id}/comments/${commentData.parentCommentId}`
          );
          const parentCommentSnap = await getDoc(parentCommentRef);

          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (parentCommentData.isStartup) {
              const replyNotificationRef = doc(
                firestore,
                `users/${parentCommentData.startupOwnerId}/notifications`,
                `reply_${commentRef.id}`
              );
              await deleteDoc(replyNotificationRef);
            }
          }
        }
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        if (commentData.userId !== postOwnerId) {
          const notificationRef = doc(
            firestore,
            `users/${postOwnerId}/notifications/comment_${commentRef.id}`
          );
          await deleteDoc(notificationRef);
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            firestore,
            `${basePath}/posts/${reaction.originalPost.id}/comments/${commentData.parentCommentId}`
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.isStartup
            ) {
              const replyNotificationRef = doc(
                firestore,
                `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
              );
              await deleteDoc(replyNotificationRef);
            }
          }
        }

        // Handle reactions on the comment
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          // Handle user reactions
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          await Promise.all(
            userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          // Handle startup reactions
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            await Promise.all(
              startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }

        // Delete replies
        const repliesQuery = query(
          collection(
            firestore,
            `${basePath}/posts/${reaction.originalPost.id}/comments`
          ),
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentRef);

      const batch = writeBatch(firestore);
      const postRef = doc(
        firestore,
        `${basePath}/posts/${reaction.originalPost.id}`
      );

      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      // Update commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${reaction.originalPost.id}`
        );
        const commentedPostDoc = await getDoc(commentedPostRef);

        if (commentedPostDoc.exists()) {
          const updatedComments = { ...commentedPostDoc.data().comments };
          deletedCommentIds.forEach((id) => delete updatedComments[id]);

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Update commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const startupCommentedPostRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${startupId}/commentedPosts/${reaction.originalPost.id}`
        );
        const startupCommentedPostDoc = await getDoc(startupCommentedPostRef);

        if (startupCommentedPostDoc.exists()) {
          const updatedComments = {
            ...startupCommentedPostDoc.data().comments,
          };
          deletedCommentIds.forEach((id) => delete updatedComments[id]);

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(startupCommentedPostRef);
          } else {
            batch.update(startupCommentedPostRef, {
              comments: updatedComments,
            });
          }
        }
      }

      await batch.commit();

      setComments((prevComments) =>
        prevComments.filter((c) => !deletedCommentIds.has(c.id))
      );

      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          commentCount: Math.max(
            (prevReaction.originalPost.commentCount || 0) - deletedCount,
            0
          ),
        },
      }));

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to delete this comment"
            ? "You are not authorized to delete this comment."
            : "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  useEffect(() => {
    if (reaction && reaction.reactions && currentUser) {
      setCurrentUserReaction(reaction.reactions[currentUser.uid] || null);
    }
  }, [reaction, currentUser]);

  const hasInteractions = (reaction) => {
    return (
      reaction &&
      reaction.originalPost &&
      ((reaction.originalPost.reactions &&
        Object.keys(reaction.originalPost.reactions || {}).length > 0) ||
        (reaction.originalPost.reposts &&
          Object.keys(reaction.originalPost.reposts || {}).length > 0) ||
        (reaction.originalPost.commentCount &&
          reaction.originalPost.commentCount > 0))
    );
  };

  useEffect(() => {
    const { state } = location;
    if (state) {
      if (state.fromStartupReactions && state.startupData) {
        // This is coming from a startup's reactions
        setReactionDisplayData({
          isStartup: true,
          startupName: state.startupData.startupName,
          startupImage: state.startupData.startupImage,
          reactionEmoji: state.reactionEmoji,
          startupId: state.startupData.startupId,
          startupOwnerId: state.startupData.startupOwnerId,
          startupUrlSlug: state.startupData.startupUrlSlug,
        });
      } else if (state.fromUserProfile && state.userData) {
        // This is from a user's profile
        setReactionDisplayData({
          firstName: state.userData.firstName,
          lastName: state.userData.lastName,
          profileImage: state.userData.profileImage || profileImage,
          reactionEmoji: state.reactionEmoji,
        });
      } else {
        // This is from the current user's profile
        setReactionDisplayData({
          isCurrentUser: true,
          reactionEmoji: state.reactionEmoji,
        });
      }
    }
  }, [location]);

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  useEffect(() => {
    if (reaction?.originalPost?.reactions && currentUser) {
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      setCurrentReactionEmoji(
        reaction.originalPost.reactions[reactorId] || null
      );
    }
  }, [reaction, currentUser, selectedStartup]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderComments = () => {
    if (!reaction || !reaction.originalPost) return null;
    if (!Array.isArray(comments)) return null;

    const rootComments = comments.filter((comment) => comment.isRootComment);
    const visibleRootComments = rootComments.slice(0, visibleComments);

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = comments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment?.reactions ?? {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {/* Show Edit if user owns comment or if selected startup owns comment */}
                      {(!comment.isStartup &&
                        comment.userId === currentUser.uid) ||
                      (comment.isStartup &&
                        selectedStartup?.id === comment.startupId) ||
                      (comment.isStartup &&
                        currentUser.uid === comment.startupOwnerId) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete for post owner or if user/startup owns the comment */}
                      {(currentUser.uid === reaction.originalPost.user.id ||
                        (!comment.isStartup &&
                          comment.userId === currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id === comment.startupId) ||
                        (comment.isStartup &&
                          currentUser.uid === comment.startupOwnerId)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      )}

                      {/* Show Report if user/startup doesn't own the comment (post owner can also report) */}
                      {(!comment.isStartup &&
                        comment.userId !== currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id !== comment.startupId &&
                          currentUser.uid !== comment.startupOwnerId && (
                            <button
                              className={classes.reportCommentButton}
                              onClick={() => handleReportComment(comment.id)}
                            >
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                                />
                              </svg>
                              Report
                            </button>
                          ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}

              {activeEmojiPicker === comment.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {renderReplies(comment.id)}

              {activeCommentId === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments}
                  onReplyChange={handleReplyInputChange}
                  onSubmit={handleAddComment}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={handleLoadMoreComments}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const [isLoadingComments, setIsLoadingComments] = useState(false);

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
  }) => {
    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUserProfileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={() =>
                handleNavigate(
                  selectedStartup
                    ? `startup/${selectedStartup.startupUrlSlug}`
                    : currentUser.uid
                )
              }
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={(e) => onCommentChange(e)}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
            disabled={isLoadingComments}
          />
        </div>
        <div
          onClick={isLoadingComments ? undefined : onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment.trim() || isLoadingComments ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={(el) => (replyInputRef.current[comment.id] = el)}
            value={replyComments[comment.id] || ""}
            onChange={(e) => onReplyChange(e, comment.id)}
            onTouchStart={handleTouchStart}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={() => onSubmit(comment.id)}
          className={`${classes.addReplyButton} ${
            !replyComments[comment.id]?.trim() ? classes.disabled : ""
          }`}
          disabled={!replyComments[comment.id]?.trim()}
        >
          Reply
        </div>
      </div>
    );
  };

  const renderReplies = (rootCommentId) => {
    if (!reaction || !reaction.originalPost) {
      console.log("No comments available for this post");
      return null;
    }

    const replies = comments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {/* Show Edit if user owns reply or if selected startup owns reply or if startup owner */}
                      {(!reply.isStartup && reply.userId === currentUser.uid) ||
                      (reply.isStartup &&
                        selectedStartup?.id === reply.startupId) ||
                      (reply.isStartup &&
                        currentUser.uid === reply.startupOwnerId) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(reply.id, true)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete for post owner, reply owner, startup owner, or selected startup */}
                      {currentUser.uid === reaction.originalPost.user.id ||
                      (!reply.isStartup && reply.userId === currentUser.uid) ||
                      (reply.isStartup &&
                        selectedStartup?.id === reply.startupId) ||
                      (reply.isStartup &&
                        currentUser.uid === reply.startupOwnerId) ? (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete
                        </button>
                      ) : null}

                      {/* Show Report if not owned by user/startup and not post owner */}
                      {(!reply.isStartup &&
                        reply.userId !== currentUser.uid &&
                        currentUser.uid !== reaction.originalPost.user.id) ||
                      (reply.isStartup &&
                        selectedStartup?.id !== reply.startupId &&
                        currentUser.uid !== reply.startupOwnerId &&
                        currentUser.uid !== reaction.originalPost.user.id) ? (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() =>
                            console.log(`Reporting reply: ${reply.id}`)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                            />
                          </svg>
                          Report
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === reply.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={currentUserProfileImage || profileImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => handleNavigate(currentUser.uid)}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[reply.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, reply.id)}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(reply.id)}
                    className={`${classes.addReplyButton} ${
                      !replyComments[reply.id]?.trim() ? classes.disabled : ""
                    }`}
                    disabled={!replyComments[reply.id]?.trim()}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchReaction = async () => {
      try {
        setLoading(true);
        const { state } = location;
        const fromStartupReactions = state?.fromStartupReactions;
        const startupData = state?.startupData;

        if (fromStartupReactions && startupData) {
          const reactionRef = doc(
            firestore,
            `users/${startupData.startupOwnerId}/startups/${startupData.startupId}/reactedPosts/${reactionId}`
          );
          const reactionSnapshot = await getDoc(reactionRef);

          if (reactionSnapshot.exists()) {
            const reactionData = reactionSnapshot.data();
            let originalPostRef = await getOriginalPostRef(
              reactionData.originalPosterId,
              reactionData.postId
            );

            if (originalPostRef) {
              const originalPostSnap = await getDoc(originalPostRef);
              if (originalPostSnap.exists()) {
                const originalPostData = originalPostSnap.data();
                let originalPostUserData;

                if (originalPostRef.path.includes("/startups/")) {
                  const pathParts = originalPostRef.path.split("/");
                  const ownerId = pathParts[1];
                  const startupId = pathParts[3];

                  const startupRef = doc(
                    firestore,
                    `users/${ownerId}/startups/${startupId}`
                  );
                  const startupSnap = await getDoc(startupRef);

                  if (startupSnap.exists()) {
                    const startupData = startupSnap.data();
                    originalPostUserData = {
                      id: `startup_${startupId}`,
                      firstName: startupData.startupName,
                      lastName: "",
                      profileImage: startupData.startupImage || profileImage,
                      isStartup: true,
                      startupId: startupId,
                      startupOwnerId: ownerId,
                      startupUrlSlug: startupData.startupUrlSlug,
                      bio: startupData.bio || "",
                      link: startupData.link || null,
                      linkText: startupData.linkText || null,
                      city: startupData.city || "",
                      country: startupData.country || "",
                    };
                  }
                } else {
                  const userDoc = await getDoc(
                    doc(firestore, "users", reactionData.originalPosterId)
                  );
                  if (userDoc.exists()) {
                    const userData = userDoc.data();
                    originalPostUserData = {
                      id: reactionData.originalPosterId,
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      profileImage: userData.profileImage || profileImage,
                      isStartup: false,
                      urlSlug: userData.urlSlug,
                      bio: userData.bio || "",
                      link: userData.link || null,
                      linkText: userData.linkText || null,
                    };
                  }
                }

                const connectionType = originalPostUserData.isStartup
                  ? "Startup"
                  : await checkConnectionType(
                      currentUser.uid,
                      originalPostUserData.id
                    );

                setReaction({
                  id: reactionId,
                  emoji: reactionData.emoji,
                  reactedAt: reactionData.reactedAt,
                  user: {
                    id: `startup_${startupData.startupId}`,
                    firstName: startupData.startupName,
                    lastName: "",
                    profileImage: startupData.startupImage,
                    isStartup: true,
                    startupId: startupData.startupId,
                    startupOwnerId: startupData.startupOwnerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                  },
                  originalPost: {
                    ...originalPostData,
                    id: reactionData.postId,
                    user: {
                      ...originalPostUserData,
                      connectionType,
                    },
                    reactions: originalPostData.reactions || {},
                    reposts: originalPostData.reposts || {},
                    commentCount: originalPostData.commentCount || 0,
                    createdAt: originalPostData.createdAt,
                    isEdited: originalPostData.isEdited || false,
                  },
                });
              }
            }
          }
        } else {
          const usersRef = collection(firestore, "users");
          const usersSnapshot = await getDocs(usersRef);

          let reactionData = null;
          let userData = null;
          let originalPostData = null;
          let originalPostUserData = null;

          for (const userDoc of usersSnapshot.docs) {
            const userId = userDoc.id;
            const reactionRef = doc(
              firestore,
              "users",
              userId,
              "reactedPosts",
              reactionId
            );
            const reactionSnapshot = await getDoc(reactionRef);

            if (reactionSnapshot.exists()) {
              reactionData = reactionSnapshot.data();
              userData = userDoc.data();
              userData.id = userId;

              let originalPostRef = await getOriginalPostRef(
                reactionData.originalPosterId,
                reactionData.postId
              );

              if (originalPostRef) {
                const originalPostSnapshot = await getDoc(originalPostRef);
                if (originalPostSnapshot.exists()) {
                  originalPostData = originalPostSnapshot.data();

                  if (originalPostRef.path.includes("/startups/")) {
                    const pathParts = originalPostRef.path.split("/");
                    const ownerId = pathParts[1];
                    const startupId = pathParts[3];

                    const startupRef = doc(
                      firestore,
                      `users/${ownerId}/startups/${startupId}`
                    );
                    const startupSnap = await getDoc(startupRef);

                    if (startupSnap.exists()) {
                      const startupData = startupSnap.data();
                      originalPostUserData = {
                        id: `startup_${startupId}`,
                        firstName: startupData.startupName,
                        lastName: "",
                        profileImage: startupData.startupImage || profileImage,
                        isStartup: true,
                        startupId: startupId,
                        startupOwnerId: ownerId,
                        startupUrlSlug: startupData.startupUrlSlug,
                        bio: startupData.bio || "",
                        link: startupData.link || null,
                        linkText: startupData.linkText || null,
                        city: startupData.city || "",
                        country: startupData.country || "",
                      };
                    }
                  } else {
                    const originalPostUserDoc = await getDoc(
                      doc(firestore, "users", reactionData.originalPosterId)
                    );
                    originalPostUserData = originalPostUserDoc.data();
                    originalPostUserData.id = reactionData.originalPosterId;
                  }

                  break;
                }
              }
            }
          }

          if (
            reactionData &&
            userData &&
            originalPostData &&
            originalPostUserData
          ) {
            const connectionType = originalPostUserData.isStartup
              ? "Startup"
              : await checkConnectionType(
                  currentUser.uid,
                  originalPostUserData.id
                );

            originalPostUserData.connectionType = connectionType;

            const userConnectionType = await checkConnectionType(
              currentUser.uid,
              userData.id
            );

            setReaction({
              ...reactionData,
              id: reactionId,
              user: {
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage || profileImage,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                isStartup: false,
                connectionType: userConnectionType,
                urlSlug: userData.urlSlug,
              },
              originalPost: {
                ...originalPostData,
                id: reactionData.postId,
                user: originalPostUserData,
                reactions: originalPostData.reactions || {},
                reposts: originalPostData.reposts || {},
                commentCount: originalPostData.commentCount || 0,
              },
            });
          }
        }
      } catch (error) {
        console.error("Error fetching reaction:", error);
        setStartupNotFound(true);
      } finally {
        setLoading(false);
        setHasAttemptedLoad(true);
      }
    };

    if (currentUser) {
      fetchReaction();
    }
  }, [reactionId, currentUser, location.state]);

  const getPostRef = (post) => {
    if (!post || !post.user) {
      console.error("Invalid post object:", post);
      return null;
    }

    try {
      if (post.user.isStartup) {
        // For startup posts, use the correct path structure
        return doc(
          firestore,
          `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
        );
      }
      // For regular user posts
      return doc(firestore, `users/${post.user.id}/posts/${post.id}`);
    } catch (error) {
      console.error("Error in getPostRef:", error);
      return null;
    }
  };

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // First check if this post exists in the user's regular posts
      const userPostRef = doc(
        firestore,
        `users/${originalPosterId}/posts/${postId}`
      );
      const userPostSnap = await getDoc(userPostRef);

      if (userPostSnap.exists()) {
        return userPostRef;
      }

      // If not found in user's posts, look in their startups
      const startupsRef = collection(
        firestore,
        `users/${originalPosterId}/startups`
      );
      const startupsSnapshot = await getDocs(startupsRef);

      // Check each startup's posts collection
      for (const startupDoc of startupsSnapshot.docs) {
        const startupPostRef = doc(
          firestore,
          `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
        );
        const startupPostSnap = await getDoc(startupPostRef);

        if (startupPostSnap.exists()) {
          console.log("Found post in startup:", startupPostRef.path);
          return startupPostRef;
        }
      }

      console.warn(
        `Could not find post. PostId: ${postId}, UserId: ${originalPosterId}`
      );
      return null;
    } catch (error) {
      console.error("Error finding post:", error);
      return null;
    }
  };

  const findStartupOwner = async (startupId) => {
    try {
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);

      for (const userDoc of usersSnapshot.docs) {
        const startupRef = doc(
          firestore,
          `users/${userDoc.id}/startups/${startupId}`
        );
        const startupSnapshot = await getDoc(startupRef);

        if (startupSnapshot.exists()) {
          return {
            id: userDoc.id,
            startupData: startupSnapshot.data(),
            startupId: startupId,
          };
        }
      }
      return null;
    } catch (error) {
      console.error("Error finding startup owner:", error);
      return null;
    }
  };

  // Helper function to get post owner data
  const getPostOwnerData = async (posterId) => {
    if (posterId.startsWith("startup_")) {
      const startupId = posterId.replace("startup_", "");
      const startupOwner = await findStartupOwner(startupId);
      if (startupOwner) {
        const startupRef = doc(
          firestore,
          `users/${startupOwner.id}/startups/${startupId}`
        );
        const startupSnapshot = await getDoc(startupRef);
        const startupData = startupSnapshot.data();

        return {
          id: posterId,
          firstName: startupData.startupName,
          lastName: "",
          profileImage: startupData.startupImage,
          isStartup: true,
          startupId: startupId,
          startupOwnerId: startupOwner.id,
        };
      }
    } else {
      const userDoc = await getDoc(doc(firestore, "users", posterId));
      const userData = userDoc.data();
      return {
        id: posterId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
        isStartup: false,
      };
    }
  };

  useEffect(() => {
    let isMounted = true;

    const loadComments = async () => {
      if (!reaction || !currentUser || !showComments || isLoadingComments)
        return;

      setIsLoadingComments(true);
      try {
        await fetchComments();
      } finally {
        if (isMounted) {
          setIsLoadingComments(false);
        }
      }
    };

    loadComments();

    return () => {
      isMounted = false;
    };
  }, [reaction?.originalPost?.id, currentUser?.uid, showComments]); // Only depend on stable identifiers

  useEffect(() => {
    if (reaction && currentUser && showComments) {
      fetchComments();
    }
  }, [reaction, currentUser, showComments, fetchComments]);

  useEffect(() => {
    console.log("Current user ID:", currentUser?.uid);
    console.log("Original post user ID:", reaction?.originalPost?.user?.id);
  }, [currentUser, reaction]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (timestamp.toDate) {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (userId) => {
    console.log("handleNavigate called with userId:", userId);

    if (!userId) return;

    try {
      if (userId.startsWith("startup_")) {
        // Handle startup navigation
        const startupId = userId.replace("startup_", "");
        const ownerDoc = await findStartupOwner(startupId);
        if (ownerDoc) {
          const startupRef = doc(
            firestore,
            `users/${ownerDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);
          if (startupSnap.exists()) {
            const startupData = startupSnap.data();
            navigate(`/startup/${startupData.startupUrlSlug}`);
            return;
          }
        }
      }

      // Handle regular user navigation
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        console.error("User not found");
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error fetching user/startup data:", error);
      setErrorPopup({
        message: "Error loading profile.",
        isError: true,
      });
    }
  };

  const renderPostText = (post) => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPost
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html: convertUrlsToLinks(displayText) + (expandedPost ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPost && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPost(true);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.content.url.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = (totalImages) => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const renderPostContent = (post) => {
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex]}
                    alt=""
                    className={classes.postContentImage}
                    onClick={() => handleNextImage(post.content.url.length)}
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === currentImageIndex ? classes.activeDot : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex(index);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );

      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={videoRef}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume(!videoVolume);
                  }}
                >
                  {!videoVolume ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        const hasNoInteractions =
          (!post.reactions || Object.keys(post.reactions).length === 0) &&
          (!post.reposts || Object.keys(post.reposts).length === 0) &&
          (!post.commentCount || post.commentCount === 0);

        return (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              hasNoInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [reaction]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleEmojiSelect = async (emoji) => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    try {
      const batch = writeBatch(firestore);
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;

      // Get the correct post reference based on whether it's a startup post
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${reaction.originalPost.id}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
          );

      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(reaction.originalPost.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${reaction.originalPost.id}`
        );
        await deleteDoc(reactedPostRef);

        // Delete notification if the reactor is not the post owner
        if (reaction.originalPost.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `reaction_${reaction.originalPost.id}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${reaction.originalPost.id}`
        );
        await setDoc(reactedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: reaction.originalPost.id,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify =
          reaction.originalPost.user.startupOwnerId !== currentUser.uid ||
          (selectedStartup &&
            reaction.originalPost.user.startupId !== selectedStartup.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `reaction_${reaction.originalPost.id}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: reaction.originalPost.id,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          reactions: updatedReactions,
        },
      }));

      setCurrentReactionEmoji(updatedReactions[reactorId] || null);
      setActiveEmojiPicker(null);

      setTimeout(() => {
        window.scrollTo(0, emojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  // Update this function
  const handleReactionClick = () => {
    const reactorId = selectedStartup
      ? `startup_${selectedStartup.id}`
      : currentUser?.uid;
    const currentEmoji = selectedStartup
      ? reaction?.originalPost?.reactions?.[`startup_${selectedStartup.id}`]
      : reaction?.originalPost?.reactions?.[currentUser?.uid];

    if (currentEmoji) {
      handleEmojiSelect(currentEmoji);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(activeEmojiPicker === "post" ? null : "post");
    }
  };

  const handleReactionsClick = () => {
    if (!reaction || !reaction.originalPost) return;
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({
      postId: reaction.originalPost.id,
      reactions: reaction.originalPost.reactions || {},
    });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleDeletePost = async () => {
    if (!currentUser || !reaction || !reaction.originalPost) {
      console.error("No current user or reaction data");
      setErrorPopup({
        message: "Unable to delete post due to missing data.",
        isError: true,
      });
      return;
    }

    try {
      // Verify post exists and user has permission to delete
      const targetPost = reaction.originalPost;
      if (!targetPost) {
        console.error("Post not found");
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Get the correct post reference based on whether it's a startup post
      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${targetPost.id}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${targetPost.id}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete = targetPost.user.isStartup
        ? targetPost.user.startupOwnerId === currentUser.uid
        : targetPost.user.id === currentUser.uid;

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      // Get comments and collect affected users/startups
      const commentsRef = collection(
        firestore,
        targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${targetPost.id}/comments`
          : `users/${targetPost.user.id}/posts/${targetPost.id}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      // Clean up comments in commentedPosts for regular users
      for (const userId of affectedUserIds) {
        try {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${targetPost.id}`
          );
          batch.delete(commentedPostRef);
          operationCount++;
          await commitBatchIfNeeded();
        } catch (error) {
          console.error(
            `Error deleting commentedPost for user ${userId}:`,
            error
          );
        }
      }

      // Clean up comments in commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${targetPost.id}`
            );
            batch.delete(startupCommentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          } catch (error) {
            console.error(
              `Error cleaning up startup commentedPost for ${startupId}:`,
              error
            );
          }
        }
      }

      // Clean up all notifications
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        try {
          // Delete all notifications related to this post
          const notificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("postId", "==", targetPost.id)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);

          notificationsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
            operationCount++;
          });
          await commitBatchIfNeeded();

          // Clean up startup notifications
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("postId", "==", targetPost.id)
            );
            const startupNotificationsSnapshot = await getDocs(
              startupNotificationsQuery
            );
            startupNotificationsSnapshot.docs.forEach((doc) => {
              batch.delete(doc.ref);
              operationCount++;
            });
            await commitBatchIfNeeded();
          }
        } catch (error) {
          console.error(
            `Error cleaning up notifications for user ${userDoc.id}:`,
            error
          );
        }
      }

      // Process reactions
      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupReactedPostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reactedPosts/${targetPost.id}`
                );
                batch.delete(startupReactedPostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userReactedPostRef = doc(
                firestore,
                `users/${userId}/reactedPosts/${targetPost.id}`
              );
              batch.delete(userReactedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up reaction for ${userId}:`, error);
          }
        }
      }

      // Process reposts
      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupRepostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reposts/${targetPost.id}`
                );
                batch.delete(startupRepostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userRepostRef = doc(
                firestore,
                `users/${userId}/reposts/${targetPost.id}`
              );
              batch.delete(userRepostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up repost for ${userId}:`, error);
          }
        }
      }

      // Delete all comments
      const commentDeletePromises = commentsSnapshot.docs.map((commentDoc) =>
        deleteDoc(commentDoc.ref)
      );
      await Promise.all(commentDeletePromises);

      // Clean up media content
      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${targetPost.id}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error deleting files from storage:", error);
        }
      }

      // Delete the post itself
      batch.delete(postRef);
      operationCount++;

      // Commit final batch
      await batch.commit();

      // Navigate to appropriate profile
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (targetPost.user.isStartup) {
          navigate(`/startup/${userData.startupUrlSlug}`);
        } else {
          navigate(`/${userData.urlSlug}`);
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = () => {
    // Implement report post functionality
    console.log("Report post clicked");
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(
      reaction?.originalPost?.reposts || {}
    ).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const handleRepost = async () => {
    if (!currentUser || !reaction || !reaction.originalPost) return;

    const postId = reaction.originalPost.id;
    const reposterInfo = getCommenterInfo(currentUser, selectedStartup);

    try {
      // Get the correct post reference based on whether it's a startup post
      const postRef = reaction.originalPost.user.isStartup
        ? doc(
            firestore,
            `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}/posts/${postId}`
          )
        : doc(
            firestore,
            `users/${reaction.originalPost.user.id}/posts/${postId}`
          );

      const repostsPath = reposterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${reposterInfo.startupId}/reposts`
        : `users/${currentUser.uid}/reposts`;
      const repostedPostRef = doc(firestore, repostsPath, postId);

      let updatedReposts = { ...(reaction.originalPost.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterInfo.id]) {
        // Remove repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: deleteField(),
        });
        delete updatedReposts[reposterInfo.id];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`,
          `repost_${postId}_${reposterInfo.id}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: now,
        });
        updatedReposts[reposterInfo.id] = now;

        await setDoc(repostedPostRef, {
          originalPosterId:
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime:
            reaction.originalPost.originalDirectRepostTime || now,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify =
          reaction.originalPost.user.startupOwnerId !== currentUser.uid ||
          (selectedStartup &&
            reaction.originalPost.user.startupId !== selectedStartup.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              reaction.originalPost.user.startupOwnerId ||
              reaction.originalPost.user.id
            }/notifications`,
            `repost_${postId}_${reposterInfo.id}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterInfo.id,
            reposterName: reposterInfo.name,
            reposterImage: reposterInfo.image,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: reaction.originalPost.text || "",
              mediaType: reaction.originalPost.content?.type || null,
              mediaUrl: reaction.originalPost.content?.url || null,
              fileName: reaction.originalPost.content?.fileName || null,
            },
            ...(reposterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          reposts: updatedReposts,
        },
      }));
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const handleRepostsClick = () => {
    if (!reaction || !reaction.originalPost) return;
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({
      postId: reaction.originalPost.id,
      reposts: reaction.originalPost.reposts || {},
    });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleEditComment = async (commentId, isReply = false) => {
    const commentToEdit = comments.find((c) => c.id === commentId);
    if (!commentToEdit) return;

    const canEdit =
      (commentToEdit.isStartup &&
        (selectedStartup?.id === commentToEdit.startupId ||
          currentUser.uid === commentToEdit.startupOwnerId)) ||
      (!commentToEdit.isStartup && commentToEdit.userId === currentUser.uid);

    if (!canEdit) {
      setErrorPopup({
        message: "You are not authorized to edit this comment.",
        isError: true,
      });
      return;
    }

    setEditingCommentId(commentId);
    const strippedText = stripHtmlTags(commentToEdit.text);
    setEditCommentText(strippedText);
    setOpenCommentDropdown(null);

    setTimeout(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.focus();
        adjustTextareaHeight(editTextareaRef.current);
        const length = editTextareaRef.current.value.length;
        editTextareaRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  const handleSaveCommentChanges = async (commentId, isReply = false) => {
    if (!currentUser || !reaction) return;

    const trimmedText = editCommentText.trim();

    if (!trimmedText) {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      // Get the correct base path based on whether the original post is from a startup
      const basePath = reaction.originalPost.user.isStartup
        ? `users/${reaction.originalPost.user.startupOwnerId}/startups/${reaction.originalPost.user.startupId}`
        : `users/${reaction.originalPost.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${reaction.originalPost.id}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      const canEdit =
        (commentData.isStartup &&
          (selectedStartup?.id === commentData.startupId ||
            currentUser.uid === commentData.startupOwnerId)) ||
        (!commentData.isStartup && commentData.userId === currentUser.uid);

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Get the correct path for notifications based on whether the original post is from a startup
      const notificationsQuery = query(
        collection(
          firestore,
          `users/${
            reaction.originalPost.user.startupOwnerId ||
            reaction.originalPost.user.id
          }/notifications`
        ),
        where("commentId", "==", commentId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);

      const batch = writeBatch(firestore);
      notificationsSnapshot.forEach((doc) => {
        const notificationData = doc.data();
        if (
          notificationData.type === "comment" ||
          notificationData.type === "reply"
        ) {
          batch.update(doc.ref, {
            commentText: trimmedText,
          });
        }
      });
      await batch.commit();

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        )
      );

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to edit this comment"
            ? "You are not authorized to edit this comment."
            : "Error updating comment.",
        isError: true,
      });
    }
  };

  const handleReportComment = (commentId) => {
    console.log(`Reporting comment: ${commentId}`);
    setShowDropdown(false); // Close the dropdown after clicking
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const ReactionHeader = () => {
    if (reactionDisplayData?.isStartup) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={reactionDisplayData.startupImage}
            alt={reactionDisplayData.startupName}
            className={classes.reactionImage}
            onClick={() =>
              navigate(`/startup/${reactionDisplayData.startupUrlSlug}`)
            }
            style={{ cursor: "pointer" }}
          />
          <div className={classes.reactionTextWrapper}>
            <span className={classes.reactionText}>
              <span
                className={classes.reactionUsername}
                onClick={() =>
                  navigate(`/startup/${reactionDisplayData.startupUrlSlug}`)
                }
                style={{ cursor: "pointer" }}
              >
                {reactionDisplayData.startupName}
              </span>
              <span className={classes.reactionMessage}>
                {" "}
                reacted
                <span className={classes.textEmoji}>
                  {reactionDisplayData.reactionEmoji}{" "}
                </span>
                to this
              </span>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.reactionHeader}>
        <img
          src={
            reactionDisplayData?.isCurrentUser
              ? currentUserProfileImage
              : reactionDisplayData?.profileImage
          }
          alt="Profile"
          className={classes.reactionImage}
          onClick={() =>
            reactionDisplayData?.isCurrentUser
              ? handleNavigate(currentUser.uid)
              : handleNavigate(reaction.user.id)
          }
          style={{ cursor: "pointer" }}
        />
        <div className={classes.reactionTextWrapper}>
          <span className={classes.reactionText}>
            <span
              className={classes.reactionUsername}
              onClick={() =>
                reactionDisplayData?.isCurrentUser
                  ? handleNavigate(currentUser.uid)
                  : handleNavigate(reaction.user.id)
              }
            >
              {reactionDisplayData?.isCurrentUser
                ? "You"
                : `${reactionDisplayData?.firstName} ${reactionDisplayData?.lastName}`}
            </span>
            <span className={classes.reactionMessage}>
              {" "}
              reacted{" "}
              <span className={classes.textEmoji}>
                {reactionDisplayData?.isCurrentUser
                  ? currentUserReaction ||
                    reactionDisplayData?.reactionEmoji ||
                    reaction.emoji
                  : reactionDisplayData?.reactionEmoji || reaction.emoji}
              </span>
              to this
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderPostDropdown = () => {
    // Check if current user owns the post (either directly or via startup ownership)
    const isOwnPost = reaction.user.isStartup
      ? reaction.user.startupOwnerId === currentUser.uid
      : reaction.user.id === currentUser.uid;

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button onClick={handleEditPost} className={classes.editPost}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                  clipRule="evenodd"
                />
              </svg>
              Edit
            </button>
            <button onClick={handleDeletePost} className={classes.deletePost}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="gray"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                  clipRule="evenodd"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <button onClick={handleReportPost} className={classes.reportPost}>
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderEditButtons = (commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${reaction.originalPost.user.id}/posts/${reaction.originalPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setReaction((prevReaction) => ({
        ...prevReaction,
        originalPost: {
          ...prevReaction.originalPost,
          text: updatedText,
          isEdited: isEdited,
        },
      }));

      setIsEditingComplete(true);
      setShowEditModal(false);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        isStartupModalOpen ||
        activeEmojiPicker === "post" ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker === "post"
          ? { top: `-${emojiPickerScrollPosition}px` }
          : activeEmojiPicker !== null
          ? { top: `-${commentEmojiPickerScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserProfileImage} />
      ) : (
        <MainNavbar userImage={currentUserProfileImage} />
      )}

      {showEditModal && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={reaction.originalPost}
          onEditComplete={handleEditComplete}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions}
          currentUserId={currentUser?.uid}
        />
      )}

      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={() => {
            setShowRepostsModal(false);
            setIsRepostsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, repostsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts}
          currentUserId={currentUser?.uid}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : reaction ? (
            <div className={classes.postCard}>
              <ReactionHeader />
              <div className={classes.postHeader}>
                <div className={classes.userInfo}>
                  <img
                    className={classes.postImage}
                    src={
                      reaction.originalPost.user.profileImage || profileImage
                    }
                    alt=""
                    onClick={() => {
                      console.log(
                        "Clicking original post user image, user ID:",
                        reaction.originalPost.user.id
                      );
                      handleNavigate(reaction.originalPost.user.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <div className={classes.userDetails}>
                    <div className={classes.userNameContainer}>
                      <p
                        className={classes.userName}
                        onClick={() => {
                          console.log(
                            "Clicking original post user name, user ID:",
                            reaction.originalPost.user.id
                          );
                          handleNavigate(reaction.originalPost.user.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className={classes.nameText}>
                          {`${reaction.originalPost.user.firstName} ${reaction.originalPost.user.lastName}`}
                        </span>
                      </p>
                      {reaction.originalPost.user.connectionType && (
                        <span className={classes.connectionType}>
                          • {reaction.originalPost.user.connectionType}
                        </span>
                      )}
                    </div>
                    {reaction.originalPost.user.bio && (
                      <p className={classes.bio}>
                        {reaction.originalPost.user.bio}
                      </p>
                    )}
                    {reaction.originalPost.user.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={reaction.originalPost.user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {reaction.originalPost.user.linkText ||
                              reaction.originalPost.user.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.timeAndEditDetails}>
                      {reaction.originalPost.createdAt && (
                        <p className={classes.time}>
                          {formatTimestamp(reaction.originalPost.createdAt)}
                        </p>
                      )}
                      {reaction.originalPost.isEdited && (
                        <div className={classes.editDot}>
                          •
                          <span className={classes.editedPostText}>Edited</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.postOptions}>
                  <svg
                    className={`${classes.optionsIcon} ${
                      showDropdown ? classes.optionsIconActive : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {showDropdown && (
                    <div className={classes.optionsDropdown}>
                      {reaction.originalPost.user.id === currentUser.uid ||
                      (reaction.originalPost.user.isStartup &&
                        reaction.originalPost.user.startupOwnerId ===
                          currentUser.uid) ? (
                        <>
                          <button
                            onClick={handleEditPost}
                            className={classes.editPost}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Edit
                          </button>
                          <button
                            onClick={handleDeletePost}
                            className={classes.deletePost}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Delete
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={handleReportPost}
                          className={classes.reportPost}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                            />
                          </svg>
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {reaction.originalPost.text &&
                renderPostText(reaction.originalPost)}
              {renderPostContent(reaction.originalPost)}
              {(hasInteractions(reaction) ||
                reaction?.originalPost?.commentCount > 0) && (
                <div className={classes.postInteractionsInfo}>
                  {reaction.originalPost.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0 && (
                      <div
                        className={classes.reactionsSummary}
                        onClick={handleReactionsClick}
                      >
                        <div className={classes.reactionStack}>
                          {getTopThreeEmojis(reaction.originalPost.reactions)
                            .reverse()
                            .map((emoji, index) => (
                              <div
                                key={index}
                                className={classes.reactionEmoji}
                                style={{
                                  zIndex:
                                    getTopThreeEmojis(
                                      reaction.originalPost.reactions
                                    ).length - index,
                                  fontSize: "var(--emoji-font-size)",
                                  marginLeft: "var(--emoji-margin-left)",
                                }}
                              >
                                {emoji}
                              </div>
                            ))}
                        </div>
                        <span
                          className={classes.reactionCount}
                          style={{ marginLeft: "var(--count-margin-left)" }}
                        >
                          {Object.keys(reaction.originalPost.reactions).length}
                        </span>
                      </div>
                    )}
                  <div className={classes.rightInteractions}>
                    {reaction?.originalPost?.commentCount > 0 && (
                      <span
                        className={`${classes.commentCount} ${
                          showComments ? classes.activeCommentCount : ""
                        }`}
                        onClick={() =>
                          setShowComments((prevState) => !prevState)
                        }
                      >
                        {reaction.originalPost.commentCount} comment
                        {reaction.originalPost.commentCount !== 1 ? "s" : ""}
                      </span>
                    )}
                    {reaction?.originalPost?.commentCount > 0 &&
                      reaction?.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0 && (
                        <span className={classes.dot}>•</span>
                      )}
                    {reaction?.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0 && (
                        <span
                          className={classes.repostCount}
                          onClick={handleRepostsClick}
                        >
                          {Object.keys(reaction.originalPost.reposts).length}{" "}
                          repost
                          {Object.keys(reaction.originalPost.reposts).length !==
                          1
                            ? "s"
                            : ""}
                        </span>
                      )}
                  </div>
                </div>
              )}

              <div className={classes.postInteractions}>
                <StartupInteractionToggle
                  currentUser={{
                    ...currentUser,
                    firstName: reaction?.user?.firstName, // Get firstName from post user data
                    lastName: reaction?.user?.lastName, // Get lastName from post user data
                    bio: reaction?.user?.bio, // Get bio from post user data
                  }}
                  onStartupSelect={setSelectedStartup}
                  selectedStartup={selectedStartup}
                  currentUserProfileImage={currentUserProfileImage}
                  onModalOpen={() => {
                    setStartupModalScrollPosition(window.scrollY);
                    setIsStartupModalOpen(true);
                  }}
                  onModalClose={() => {
                    setIsStartupModalOpen(false);
                    setTimeout(() => {
                      window.scrollTo(0, startupModalScrollPosition);
                    }, 0);
                  }}
                />
                <button
                  className={`${classes.interactionButton} ${
                    (selectedStartup &&
                      reaction?.originalPost?.reactions?.[
                        `startup_${selectedStartup.id}`
                      ]) ||
                    (!selectedStartup && currentReactionEmoji)
                      ? classes.emojiButton
                      : ""
                  }`}
                  onClick={handleReactionClick}
                >
                  {selectedStartup ? (
                    reaction?.originalPost?.reactions?.[
                      `startup_${selectedStartup.id}`
                    ] ? (
                      <span>
                        {
                          reaction.originalPost.reactions[
                            `startup_${selectedStartup.id}`
                          ]
                        }
                      </span>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                        />
                      </svg>
                    )
                  ) : currentReactionEmoji ? (
                    <span>{currentReactionEmoji}</span>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={classes.interactionButton}
                  onClick={() => setShowComments(!showComments)}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                    />
                  </svg>
                </button>
                <button
                  className={`${classes.interactionButton} ${
                    reaction.reposts?.[currentUser?.uid]
                      ? classes.repostedButton
                      : ""
                  }`}
                  onClick={handleRepost}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                    />
                  </svg>
                </button>
                <button className={classes.interactionButton}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                    />
                  </svg>
                </button>
              </div>
              {activeEmojiPicker === "post" && (
                <EmojiPicker
                  onEmojiClick={handleEmojiSelect}
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, emojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {showComments && (
                <div className={classes.commentsSection}>
                  <CommentInputSection
                    currentUser={currentUser}
                    selectedStartup={selectedStartup}
                    newComment={newComment}
                    onCommentChange={handleCommentInputChange}
                    onSubmit={() => handleAddComment()}
                  />
                  <div className={classes.commentsList}>{renderComments()}</div>
                </div>
              )}
            </div>
          ) : hasAttemptedLoad ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No reaction found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default SingleReaction;
