import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./TagsModal.module.css";
import TagsDropdown from "../Dropdowns/TagsDropdown";

const TagsModal = ({
  onClose,
  currentUser,
  onTagsUpdate,
  communityId,
  communityOwnerId,
  eventId,
  eventOwnerId,
}) => {
  const [tags, setTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isChanged, setIsChanged] = useState(false);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      setInitialLoading(true);
      try {
        let docRef;

        if (eventId && eventOwnerId) {
          // Handle event tags
          docRef = doc(firestore, `users/${eventOwnerId}/events/${eventId}`);
        } else {
          // Handle community tags
          docRef = doc(
            firestore,
            `users/${communityOwnerId}/communities/${communityId}`
          );
        }

        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists() && docSnapshot.data().tags) {
          const tagsData = docSnapshot
            .data()
            .tags.map((tag) => ({ name: tag, id: tag }));
          setTags(tagsData);
          setInitialTags(tagsData);
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setInitialLoading(false);
      }
    };

    fetchTags();
  }, [communityId, communityOwnerId, eventId, eventOwnerId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      let docRef;

      if (eventId && eventOwnerId) {
        // Update event tags
        docRef = doc(firestore, `users/${eventOwnerId}/events/${eventId}`);
      } else {
        // Update community tags
        docRef = doc(
          firestore,
          `users/${communityOwnerId}/communities/${communityId}`
        );
      }

      const docSnapshot = await getDoc(docRef);
      const existingData = docSnapshot.data();

      const updatedData = {
        ...existingData,
        tags: tags.map((tag) => tag.name),
      };

      await updateDoc(docRef, updatedData);
      onTagsUpdate(updatedData);
    } catch (error) {
      setError("Failed to update tags. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, []);

  useEffect(() => {
    const dataChanged = JSON.stringify(tags) !== JSON.stringify(initialTags);
    setIsChanged(dataChanged);
  }, [tags, initialTags]);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setIsChanged(true);
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      if (!error) {
        onClose(true);
      }
    }
  };

  const handleInputChange = (inputValue) => {
    const currentTags = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    const newTags = currentTags.map((name) => ({ name, id: name }));
    setIsChanged(JSON.stringify(newTags) !== JSON.stringify(initialTags));
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false);
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit tags
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)}
              onTouchStart={(e) => handleCloseTouch(e, false)}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.scrollableContent}>
          {initialLoading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <form
              className={classes.interactions}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className={classes.skillsDropdownWrapper}>
                <TagsDropdown
                  selectedTags={tags}
                  onTagsChange={handleTagsChange}
                  onInputChange={handleInputChange}
                  disabled={loading}
                  placeholder="Ex: webdev, javascript, frontend, coding"
                  customClassName={classes.maxHeight}
                />
              </div>
            </form>
          )}
          {error && <p className={classes.error}>{error}</p>}
        </div>

        {windowHeight <= 991 && (
          <div className={classes.cardFooter}>
            <div className={classes.smallPrint}>
              <svg
                className={classes.infoIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                  clipRule="evenodd"
                />
              </svg>
              <p>Tags must be separated with commas</p>
            </div>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}

        {windowHeight > 991 && (
          <div className={classes.cardFooter}>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsModal;
