import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore, storage } from "../../../firebase";
import googleIcon from "../../../assets/images/googleIcon.svg";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import profileImage from "../../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import classes from "./SignUp.module.css";
import {
  generateUrlSlug,
  updateUserUrlSlug,
} from "../../../utils/urlSlugUtils";

function SignUp() {
  const { signUp, signInWithGoogle, currentUser } = useAuth();
  const [googleLoading, setGoogleLoading] = useState(false);

  const navigate = useNavigate();
  // const { signUp, currentUser } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city &&
            userData.countryISO2
          ) {
            navigate("/dashboard");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {}
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      setErrorPopup(null);
      try {
        const userCredential = await signUp(email, password);
        const user = userCredential.user;

        // Upload default profile image
        const defaultProfileImageRef = ref(
          storage,
          `profileImages/${user.uid}/default.jpg`
        );
        const profileResponse = await fetch(profileImage);
        const profileBlob = await profileResponse.blob();
        await uploadBytes(defaultProfileImageRef, profileBlob);
        const profileImageUrl = await getDownloadURL(defaultProfileImageRef);

        // Upload default startup image
        const defaultStartupImageRef = ref(
          storage,
          `startupImages/${user.uid}/default.jpg`
        );
        const startupResponse = await fetch(defaultStartupImage);
        const startupBlob = await startupResponse.blob();
        await uploadBytes(defaultStartupImageRef, startupBlob);
        const startupImageUrl = await getDownloadURL(defaultStartupImageRef);

        // Generate a temporary URL slug based on the email
        const tempFirstName = email.split("@")[0];
        const tempLastName = "user";
        const urlSlug = await generateUrlSlug(tempFirstName, tempLastName);

        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          countryISO2: "",
          city: "",
          bio: "",
          intro: "",
          link: "",
          linkText: "",
          profileImage: profileImageUrl,
          urlSlug: urlSlug,
          defaultStartupImage: startupImageUrl, // Add this line
        });

        navigate("/onboarding");
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setErrorPopup({
            message: "This email is already taken.",
            isError: true,
          });
        } else if (error.code === "auth/invalid-email") {
          setErrorPopup({
            message: "Invalid email.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: error.message,
            isError: true,
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup({
        message: "Invalid email/password.",
        isError: true,
      });
    }
  };

  const isFormValid = isEmailValid && isPasswordValid;

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (
        userData &&
        userData.firstName &&
        userData.lastName &&
        userData.country &&
        userData.city
      ) {
        navigate("/dashboard");
      } else if (userData && (userData.firstName || userData.lastName)) {
        navigate("/onboarding");
      } else {
        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          city: "",
        });

        navigate("/onboarding");
      }
    } catch (error) {
      console.log("");
    } finally {
      setGoogleLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {errorPopup && (
          <ErrorPopup
            message={errorPopup.message}
            onClose={() => setErrorPopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Make ideas happen</p>
            </div>
            <div className={classes.altSection}>
              <button
                type="button"
                className={classes.altEmail}
                onClick={handleGoogleSignIn}
                disabled={googleLoading}
              >
                <img src={googleIcon} alt="" className={classes.googleIcon} />
                Continue with Google
              </button>
            </div>
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Create your password"
                className={classes.email}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                disabled={loading}
              />
              <div className={classes.smallPrint}>
                <p>6+ characters required</p>
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isFormValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>

              <div className={classes.buttonMessage}>
                <p>
                  By clicking Continue, you agree to Foundry's{" "}
                  <a href="https://foundry.bz" className={classes.link}>
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="https://foundry.bz" className={classes.link}>
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
              {error && (
                <p className={classes.error}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
