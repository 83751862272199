import emailjs from "@emailjs/browser";

export function sendEmail(name, email, message, setSuccess, setError) {
  if (!name || !email || !message) {
    setError("Please fill in all fields.");
    return;
  }

  const messageData = {
    name: name,
    email: email,
    message: message,
  };

  emailjs
    .send(
      "service_w8xk4ny",
      "template_1e1jls7",
      messageData,
      "KRqlj9JDG4chMMewW"
    )
    .then(
      (result) => {
        setSuccess(true);
        setError(null);
      },
      (error) => {
        setSuccess(false);
        setError("Failed to send message. Please try again later.");
      }
    );
}
