import React, { useState, useEffect } from "react";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import { useAuth } from "../../../contexts/AuthContext";
import { updateUserUrlSlug } from "../../../utils/urlSlugUtils";
import classes from "./InitialProfileSetup.module.css";

function InitialProfileSetup({ onNext }) {
  const { currentUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  const handleFirstNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setLastName(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName) {
      setErrorPopup({
        message: "Please enter both your first and last names.",
        isError: true,
      });
      return;
    }

    if (!currentUser?.uid) {
      setErrorPopup({
        message: "No user found. Please try logging in again.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      await updateUserUrlSlug(currentUser.uid, firstName, lastName);
      onNext();
    } catch (error) {
      console.error("Profile update error:", error);
      setErrorPopup({
        message: error.message || "Failed to update profile. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && !loading && firstName && lastName) {
        handleSubmit(e);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [loading, firstName, lastName]);

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {errorPopup && (
          <ErrorPopup
            message={errorPopup.message}
            onClose={() => setErrorPopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>What's your name?</p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="firstName" className={classes.label}>
                First name
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="Ex: Joe"
                className={classes.email}
                value={firstName}
                onChange={handleFirstNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{firstName.length}/50</div>
              <label htmlFor="lastName" className={classes.label}>
                Last name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Ex: Bloggs"
                className={classes.email}
                value={lastName}
                onChange={handleLastNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{lastName.length}/50</div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!firstName || !lastName || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitialProfileSetup;
