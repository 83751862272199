import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
  writeBatch,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditStartupsModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleStartupModal from "./EditSingleStartupModal";

const EditStartupsModal = ({ onClose, currentUser, onStartupUpdated }) => {
  const [startups, setStartups] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingStartup, setEditingStartup] = useState(null);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchStartups = async () => {
      setLoading(true);
      try {
        const batch = [];

        // 1. Fetch owned startups
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const startupsCollectionRef = collection(userDocRef, "startups");
        const startupsSnapshot = await getDocs(startupsCollectionRef);
        const ownedStartups = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          membershipType: "owner",
        }));
        batch.push(...ownedStartups);

        // 2. Fetch team member startups
        const allUsersSnapshot = await getDocs(collection(firestore, "users"));
        const teamMemberPromises = allUsersSnapshot.docs.map(
          async (userDoc) => {
            const startupRef = collection(
              firestore,
              `users/${userDoc.id}/startups`
            );
            const startupDocs = await getDocs(startupRef);

            const startupTeamPromises = startupDocs.docs.map(
              async (startupDoc) => {
                const teamRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupDoc.id}/team/${currentUser.uid}`
                );
                const teamDoc = await getDoc(teamRef);

                if (teamDoc.exists() && teamDoc.data().status === "active") {
                  return {
                    id: startupDoc.id,
                    ownerId: userDoc.id,
                    ...startupDoc.data(),
                    membershipType: "member",
                    role: teamDoc.data().role,
                  };
                }
                return null;
              }
            );

            const resolvedStartups = await Promise.all(startupTeamPromises);
            return resolvedStartups.filter((startup) => startup !== null);
          }
        );

        const teamMemberStartups = (
          await Promise.all(teamMemberPromises)
        ).flat();
        batch.push(...teamMemberStartups);

        let startupsList = batch;
        startupsList.sort((a, b) => {
          const monthsList = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const aMonth = monthsList.indexOf(a.monthFounded);
          const bMonth = monthsList.indexOf(b.monthFounded);

          const aDate = new Date(a.yearFounded, aMonth);
          const bDate = new Date(b.yearFounded, bMonth);

          if (aDate.getTime() !== bDate.getTime()) {
            return bDate.getTime() - aDate.getTime();
          }

          if (a.membershipType !== b.membershipType) {
            return a.membershipType === "owner" ? -1 : 1;
          }

          return (
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
          );
        });

        setStartups(startupsList);
      } catch (error) {
        console.error("Error fetching startups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStartups();
  }, [currentUser.uid]);

  const handleLeaveStartup = async (startup) => {
    try {
      const batch = writeBatch(firestore);

      // Delete team member document
      const teamMemberRef = doc(
        firestore,
        `users/${startup.ownerId}/startups/${startup.id}/team/${currentUser.uid}`
      );
      batch.delete(teamMemberRef);

      // Delete notifications for both owner and member
      // Delete member's notifications
      const memberNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const memberNotifQuery = query(
        memberNotificationsRef,
        where("type", "in", ["team_invite", "team_invite_response"]),
        where("startupId", "==", startup.id)
      );
      const memberNotifSnapshot = await getDocs(memberNotifQuery);
      memberNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete owner's notifications
      const ownerNotificationsRef = collection(
        firestore,
        `users/${startup.ownerId}/notifications`
      );
      const ownerNotifQuery = query(
        ownerNotificationsRef,
        where("type", "in", ["team_invite_accepted", "team_invite_declined"]),
        where("from", "==", currentUser.uid),
        where("startupId", "==", startup.id)
      );
      const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
      ownerNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Update local state
      const updatedStartups = startups.filter(
        (s) => !(s.id === startup.id && s.membershipType === "member")
      );
      setStartups(updatedStartups);
      onStartupUpdated(updatedStartups);
      setOpenDropdown(null);
    } catch (error) {
      console.error("Error leaving startup:", error);
    }
  };

  const toggleDropdown = (startupId) => {
    setOpenDropdown(openDropdown === startupId ? null : startupId);
  };

  const handleEditClick = (startup) => {
    setEditingStartup(startup);
    setOpenDropdown(null);
  };

  const handleEditClose = () => {
    setEditingStartup(null);
  };

  // In EditStartupsModal.js, modify handleStartupUpdate:
  const handleStartupUpdate = (updatedStartup) => {
    // Find the existing startup to get its createdAt
    const existingStartup = startups.find((s) => s.id === updatedStartup.id);

    // Create updated startup with preserved fields
    const fullUpdatedStartup = {
      ...updatedStartup,
      membershipType: existingStartup.membershipType,
      createdAt: existingStartup.createdAt, // Preserve the createdAt timestamp
    };

    // Update local state
    const updatedStartups = startups.map((startup) =>
      startup.id === updatedStartup.id ? fullUpdatedStartup : startup
    );
    setStartups(updatedStartups);

    // Pass the full updated startup to Profile.js
    onStartupUpdated(fullUpdatedStartup);
  };

  const handleDeleteStartup = async (startupId) => {
    try {
      // Find the startup to get its image URL
      const startup = startups.find((s) => s.id === startupId);
      if (!startup) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const startupDocRef = doc(userDocRef, "startups", startupId);

      // Delete the startup document first
      await deleteDoc(startupDocRef);

      // Delete the image if it exists and is not the default image
      if (
        startup.startupImage &&
        !startup.startupImage.includes("profileImage.jpg")
      ) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(startup.startupImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {
          console.error("Error deleting startup image:", error);
        }
      }

      // Update the local state
      const updatedStartups = startups.filter((s) => s.id !== startupId);
      setStartups(updatedStartups);

      // Notify parent component about the deletion with the correct format
      onStartupUpdated({
        id: startupId,
        deleted: true,
      });

      setOpenDropdown(null);
    } catch (error) {
      console.error("Error deleting startup:", error);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingStartup) {
    return (
      <EditSingleStartupModal
        startup={editingStartup}
        onClose={handleEditClose}
        onUpdate={handleStartupUpdate}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit Startups
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : startups.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No startups found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            startups.map((startup) => (
              <div key={startup.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {startup.startupImage ? (
                      <img
                        src={startup.startupImage}
                        alt={startup.startupName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default startup"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>
                      {startup.startupName}
                    </h3>
                    <p className={classes.role}>{startup.role}</p>

                    <p className={classes.dates}>
                      {startup.monthFounded} {startup.yearFounded}
                    </p>
                    {startup.bio && (
                      <p className={classes.bio}>{startup.bio}</p>
                    )}
                    {/* <p className={classes.following}>
                      {startup.followingCount?.toLocaleString() || 0} Follower
                      {startup.followingCount === undefined ||
                      startup.followingCount !== 1
                        ? "s"
                        : ""}
                    </p> */}
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === startup.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(startup.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === startup.id && (
                        <div className={classes.dropdown}>
                          {startup.membershipType === "owner" ? (
                            <>
                              <div
                                className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(startup);
                                }}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Edit</span>
                              </div>
                              <div
                                className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteStartup(startup.id);
                                }}
                              >
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <span>Delete</span>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`${classes.dropdownItem} ${classes.dropdownItemSingle}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLeaveStartup(startup);
                              }}
                            >
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="gray"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Leave startup
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStartupsModal;
