import { useState, useEffect } from "react";
import classes from "./DonationModal.module.css";

const ErrorPopup = ({ message, onClose }) => (
  <div className={classes.repostPopup}>
    <div className={classes.repostPopupContent}>
      <svg
        className={classes.tickIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="orangered"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
          clipRule="evenodd"
        />
      </svg>
      <p>{message}</p>
    </div>
    <div onClick={onClose} className={classes.repostCloseButton}>
      <svg
        className={classes.closeIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18 17.94 6M18 18 6.06 6"
        />
      </svg>
    </div>
  </div>
);

const DonationModal = ({ startup, onClose, currentUser, isModalOpen }) => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [userCurrency, setUserCurrency] = useState("GBP");
  const [currencySymbol, setCurrencySymbol] = useState("£");
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    setScrollPosition(window.scrollY);
    document.body.classList.add(classes.bodyNoScroll);
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";

    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  useEffect(() => {
    try {
      const userLocale = navigator.language;
      const numberFormat = new Intl.NumberFormat(userLocale, {
        style: "currency",
        currency: "USD",
      });

      const localeToCurrency = {
        "en-GB": "GBP",
        "en-US": "USD",
        "en-CA": "CAD",
        "en-AU": "AUD",
        "fr-FR": "EUR",
        "de-DE": "EUR",
        "it-IT": "EUR",
        "es-ES": "EUR",
        "ja-JP": "JPY",
      };

      const detectedCurrency = localeToCurrency[userLocale] || "GBP";
      setUserCurrency(detectedCurrency);

      const symbolFormat = new Intl.NumberFormat(userLocale, {
        style: "currency",
        currency: detectedCurrency,
      });
      const symbol = symbolFormat
        .format(0)
        .replace(/[\d.,]/g, "")
        .trim();
      setCurrencySymbol(symbol);
    } catch (error) {
      console.error("Error detecting currency:", error);
      setUserCurrency("GBP");
      setCurrencySymbol("£");
    }
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if (value.includes(".") && value.split(".")[1]?.length > 2) {
      return;
    }
    setAmount(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setShowErrorPopup(false);
    setIsLoading(true);

    const amountNum = parseFloat(amount);

    if (!amount || isNaN(amountNum) || amountNum <= 0) {
      setError("Please enter a valid amount");
      setShowErrorPopup(true);
      setIsLoading(false);
      return;
    }

    if (amountNum < 5) {
      setError(`Minimum donation amount is ${currencySymbol}5.`);
      setShowErrorPopup(true);
      setIsLoading(false);
      return;
    }

    try {
      const { getFunctions, httpsCallable } = await import(
        "firebase/functions"
      );
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(
        functions,
        "createCheckoutSession"
      );

      const result = await createCheckoutSession({
        startupId: startup.id,
        startupOwnerId: startup.ownerId,
        amount: amountNum,
        message: message,
        currency: userCurrency.toLowerCase(),
        origin: window.location.origin,
      });

      if (result.data?.url) {
        window.location.href = result.data.url;
      } else {
        throw new Error("Failed to create checkout session");
      }
    } catch (err) {
      console.error("Payment error:", err);
      setError("Failed to process payment. Please try again.");
      setShowErrorPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <div
      className={`${classes.modalBackdrop} ${
        isModalOpen ? classes.modalOpen : classes.modalClosed
      }`}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
      style={{ top: `-${scrollPosition}px` }}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Support
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <form className={classes.interactions} onSubmit={handleSubmit}>
          <label htmlFor="amount" className={classes.label}>
            Amount ({currencySymbol})*
          </label>
          <div className={classes.amountContainer}>
            <input
              id="amount"
              type="text"
              value={amount}
              onChange={handleAmountChange}
              className={`${classes.userInput} ${
                isLoading ? classes.disabledInput : ""
              }`}
              placeholder="0"
              disabled={isLoading}
            />
          </div>
          <div className={classes.smallPrint}>
            <p>Minimum donation amount is {currencySymbol}5</p>
            <div className={classes.white}>{amount.length}</div>
          </div>

          <div className={classes.border}></div>

          <label htmlFor="message" className={classes.label}>
            Message (optional)
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={`${classes.textArea} ${
              isLoading ? classes.disabledInput : ""
            }`}
            placeholder="Add a message of support..."
            rows={3}
            disabled={isLoading}
          />
          <div className={classes.charCount}>{message.length}/250</div>
        </form>

        <div className={classes.cardFooter}>
          <div className={classes.infoSection}>
            <ul className={classes.infoList}>
              <li>
                Your full donation amount will be publicly displayed to
                encourage community support
              </li>
              <li>
                A 10% platform fee helps us maintain and improve our services
              </li>
              <li>
                Your optional message will be displayed alongside your donation
              </li>
              <li>Payments are processed securely through Stripe</li>
              {startup.defaultCurrency &&
                startup.defaultCurrency !== userCurrency && (
                  <li>
                    Currency conversion from {userCurrency} to{" "}
                    {startup.defaultCurrency} is handled automatically by Stripe
                  </li>
                )}
            </ul>
            <div className={classes.stripeInfo}>
              <p>
                Payment availability varies by country. View{" "}
                <a
                  href="https://stripe.com/global"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.stripeLink}
                >
                  Stripe's supported countries
                </a>
              </p>
            </div>
          </div>
          <button
            type="submit"
            className={`${classes.button} ${isLoading ? classes.loading : ""}`}
            disabled={!amount || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </div>

      {showErrorPopup && (
        <ErrorPopup message={error} onClose={() => setShowErrorPopup(false)} />
      )}
    </div>
  );
};

export default DonationModal;
