import Navbar from "../../components/Navbars/Navbar";
import Header from "./Header";
import Content from "./Content";
import Footer from "../../components/Footer/Footer";
import classes from "./Home.module.css";

function Home() {
  return (
    <div>
      <Navbar />
      <div className={classes.page}>
        <div className={classes.content}>
          <Header />
          <Content />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
