import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./CommunityPost.module.css";
import { firestore } from "../../firebase";
import defaultUserImage from "../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../assets/icons/communityImage.jpg";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import PostModal from "../../components/Modals/PostModal";
import { useUpload } from "../../contexts/UploadContext";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  updateDoc,
  deleteDoc,
  addDoc,
  writeBatch,
  serverTimestamp,
  increment,
  setDoc,
  deleteField,
} from "firebase/firestore";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

const CommunityPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [communityData, setCommunityData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isLoading, setIsLoading] = useState(true);
  const [isCommunityMember, setIsCommunityMember] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [showDropdown, setShowDropdown] = useState({});
  const [comments, setComments] = useState({});
  const [activeCommentPostId, setActiveCommentPostId] = useState(null);
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [connectionTypes, setConnectionTypes] = useState({});
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [visibleComments, setVisibleComments] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [successPostMessage, setSuccessPostMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [errorPopup, setErrorPopup] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [videoVolumes, setVideoVolumes] = useState({});
  const videoRefs = useRef({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const commentInputRef = useRef({});
  const replyInputRef = useRef(Object.create(null));
  const editTextareaRef = useRef(null);

  const findPostPath = useCallback(() => {
    if (!post || !communityData) return null;

    const basePath = `users/${communityData.ownerId}/communities/${communityData.id}/posts`;
    return `${basePath}/${post.communityPostId}`;
  }, [post, communityData]);

  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let unsubscribe;

    const initializeApp = async () => {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDoc = await getDoc(doc(firestore, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCurrentUser({
              ...user,
              firstName: userData.firstName,
              lastName: userData.lastName,
              profileImage: userData.profileImage || defaultUserImage,
            });
          }
        } else {
          setCurrentUser(null);
        }
      });
    };

    initializeApp();
    return () => unsubscribe?.();
  }, []);

  const fetchComments = async (postId) => {
    if (!communityData?.id || !postId) return;

    try {
      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postId}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));
      const snapshot = await getDocs(q);

      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          if (commentData.isStartup) {
            return {
              id: docSnapshot.id,
              ...commentData,
              userName: commentData.userName,
              userImage: commentData.userImage,
              connectionType: "Startup",
              bio: commentData.bio || "",
              link: commentData.link || null,
              linkText: commentData.linkText || null,
              postId,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              isStartup: true,
              startupId: commentData.startupId,
              startupOwnerId: commentData.startupOwnerId,
            };
          } else {
            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) return null;

            const userData = userDoc.data();

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || defaultUserImage,
              connectionType: await checkConnectionType(
                currentUser.uid,
                commentData.userId
              ),
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              urlSlug: userData.urlSlug,
              postId,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              isStartup: false,
            };
          }
        })
      );

      const validComments = fetchedComments.filter(Boolean);

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: validComments,
      }));

      setVisibleComments((prev) => ({
        ...prev,
        [postId]: 10,
      }));
    } catch (error) {
      console.error("Error fetching comments:", error);
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    }
  };

  const fetchCommunityPost = async () => {
    if (!postId) return;

    try {
      console.log("Fetching post with ID:", postId);

      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let foundPost = null;
      let foundCommunity = null;

      for (const userDoc of usersSnapshot.docs) {
        const communityPostsRef = collection(
          firestore,
          `users/${userDoc.id}/communityPosts`
        );
        const postDoc = await getDoc(doc(communityPostsRef, postId));

        if (postDoc.exists()) {
          const postData = postDoc.data();

          // Now get the community data using the communityData field from the post
          const communityRef = doc(
            firestore,
            `users/${postData.communityData.ownerId}/communities/${postData.communityData.id}`
          );
          const communitySnap = await getDoc(communityRef);

          if (communitySnap.exists()) {
            foundPost = {
              id: postDoc.id,
              ...postData,
            };
            foundCommunity = {
              id: communitySnap.id,
              ownerId: postData.communityData.ownerId,
              ...communitySnap.data(),
            };
            break;
          }
        }
      }

      if (!foundPost || !foundCommunity) {
        console.log("Post or community not found after searching all users");
        setIsLoading(false);
        return;
      }

      console.log("Setting found post and community data");
      setCommunityData(foundCommunity);
      setPost(foundPost);

      if (currentUser) {
        setIsOwner(currentUser.uid === foundCommunity.ownerId);

        const moderatorRef = doc(
          firestore,
          `users/${foundCommunity.ownerId}/communities/${foundCommunity.id}/moderators/${currentUser.uid}`
        );
        const moderatorDoc = await getDoc(moderatorRef);
        setIsModerator(
          moderatorDoc.exists() && moderatorDoc.data().status === "active"
        );

        const memberRef = doc(
          firestore,
          `users/${foundCommunity.ownerId}/communities/${foundCommunity.id}/members/${currentUser.uid}`
        );
        const memberDoc = await getDoc(memberRef);
        setIsCommunityMember(
          memberDoc.exists() && memberDoc.data().status === "Member"
        );
      }

      await fetchComments(postId);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetchCommunityPost:", error);
      console.error("Error details:", {
        message: error.message,
        code: error.code,
        stack: error.stack,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchCommunityPost();
    }
  }, [currentUser, postId]);

  const handleEditPost = (post) => {
    setEditingPost({
      ...post,
      _path: {
        userId: post.user.startupOwnerId || post.user.id,
        startupId: post.user.startupId,
        isStartup: post.user.isStartup,
        communityId: post.communityId,
        communityOwnerId: post.communityOwnerId,
      },
    });
    setShowEditModal(true);
    setPostModalScrollPosition(window.scrollY);
    setIsPostModalOpen(true);
  };

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      if (!editingPost) return;

      let postRef;
      if (editingPost.user.isStartup) {
        postRef = doc(
          firestore,
          `users/${editingPost.user.startupOwnerId}/startups/${editingPost.user.startupId}/communityPosts/${editingPost.id}`
        );
      } else {
        postRef = doc(
          firestore,
          `users/${editingPost.user.id}/communityPosts/${editingPost.id}`
        );
      }

      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${editingPost.id}`
      );

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
        latestActivity: serverTimestamp(),
      };

      await Promise.all([
        updateDoc(postRef, updateData),
        updateDoc(communityPostRef, {
          lastUpdated: serverTimestamp(),
        }),
      ]);

      setPost((prevPost) => ({
        ...prevPost,
        text: updatedText,
        isEdited: isEdited,
      }));

      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);

      setSuccessPostMessage({
        text: "Post updated successfully.",
        postId: editingPost.id,
      });
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  const handleDeletePost = async () => {
    if (!currentUser || !post) return;

    try {
      const batch = writeBatch(firestore);
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
          affectedUserIds.add(commentData.startupOwnerId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        if (commentData.reactions) {
          for (const [reactorId, emoji] of Object.entries(
            commentData.reactions
          )) {
            if (reactorId.startsWith("startup_")) {
              const startupOwnerId = commentData.startupOwnerId;
              const notificationRef = doc(
                firestore,
                `users/${startupOwnerId}/notifications`,
                `comment_reaction_${commentDoc.id}_${reactorId}`
              );
              batch.delete(notificationRef);
            } else {
              const notificationRef = doc(
                firestore,
                `users/${commentData.userId}/notifications`,
                `comment_reaction_${commentDoc.id}_${reactorId}`
              );
              batch.delete(notificationRef);
            }
          }
        }

        const commentNotificationRef = doc(
          firestore,
          `users/${post.user.id}/notifications`,
          `comment_${commentDoc.id}`
        );
        batch.delete(commentNotificationRef);

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);

          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            const parentOwnerId = parentCommentData.isStartup
              ? parentCommentData.startupOwnerId
              : parentCommentData.userId;

            const replyNotificationRef = doc(
              firestore,
              `users/${parentOwnerId}/notifications`,
              `reply_${commentDoc.id}`
            );
            batch.delete(replyNotificationRef);
          }
        }
      }

      if (post.communityReactions) {
        for (const reactorId of Object.keys(post.communityReactions)) {
          if (reactorId.startsWith("startup_")) {
            const startupId = reactorId.replace("startup_", "");
            const ownerDoc = await getDoc(
              doc(firestore, "users", post.user.startupOwnerId)
            );
            if (ownerDoc.exists()) {
              const notificationRef = doc(
                firestore,
                `users/${ownerDoc.id}/notifications`,
                `community_reaction_${post.id}_${reactorId}`
              );
              batch.delete(notificationRef);
            }
          } else {
            const notificationRef = doc(
              firestore,
              `users/${post.user.id}/notifications`,
              `community_reaction_${post.id}_${reactorId}`
            );
            batch.delete(notificationRef);
          }
        }
      }

      const storage = getStorage();
      const storageBasePath = `communityContent/${communityData.id}/${post.user.id}/${post.id}`;

      if (post.content && post.content.type) {
        try {
          if (
            post.content.type === "image" &&
            Array.isArray(post.content.url)
          ) {
            for (const imageUrl of post.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef).catch((error) =>
                console.error("Error deleting image:", error)
              );
            }
          } else if (["image", "video", "pdf"].includes(post.content.type)) {
            const fileRef = ref(storage, post.content.url);
            await deleteObject(fileRef).catch((error) =>
              console.error("Error deleting file:", error)
            );

            if (post.content.type === "video" && post.content.thumbnail) {
              const thumbnailRef = ref(storage, post.content.thumbnail);
              await deleteObject(thumbnailRef).catch((error) =>
                console.error("Error deleting thumbnail:", error)
              );
            }
          }

          const folderRef = ref(storage, storageBasePath);
          const folderContents = await listAll(folderRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error cleaning up storage:", error);
        }
      }

      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}`
      );
      batch.delete(communityPostRef);

      await batch.commit();

      setDeleteMessage({
        text: "Post deleted successfully.",
        postId: post.id,
        isSuccess: true,
      });

      // Navigate back to community page
      navigate(`/community/${communityData.communityUrlSlug}`);
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    }
  };

  const handleReactionClick = (postId) => {
    if (post.communityReactions?.[currentUser?.uid]) {
      handleEmojiSelect(post.communityReactions[currentUser.uid], postId);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(
        activeEmojiPicker === `post_${postId}` ? null : `post_${postId}`
      );
    }
  };

  const handleReactionsClick = (postId, communityReactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions: communityReactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const postRef = doc(firestore, `${basePath}/communityPosts/${postId}`);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}/reactedCommunityPosts`
        : `users/${currentUser.uid}/reactedCommunityPosts`;

      const reactedPostRef = doc(firestore, `${path}/${postId}`);

      let updatedReactions = { ...(post.communityReactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(postRef, {
          [`communityReactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];
        await deleteDoc(reactedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `community_reaction_${postId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(postRef, {
          [`communityReactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        await setDoc(reactedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          reactedAt: serverTimestamp(),
          emoji: emoji,
          communityId: communityData.id,
          communityName: communityData.communityName,
          communityImage: communityData.communityImage || null,
        });

        const shouldNotify = post.user.isStartup
          ? post.user.startupOwnerId !== currentUser.uid ||
            (selectedStartup && post.user.startupId !== selectedStartup.id)
          : post.user.id !== currentUser.uid;

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `community_reaction_${postId}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: postId,
            communityId: communityData.id,
            communityName: communityData.communityName,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUser.profileImage || defaultUserImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
              ...(post.content?.type === "video" && {
                thumbnail: post.content.thumbnail,
              }),
            },
            originalPosterId: post.user.id || post.userId,
            userId: post.user.id || post.userId,
            reactionCount: Object.keys(post.communityReactions || {}).length,
            commentCount: post.commentCount || 0,
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setPost((prevPost) => ({
        ...prevPost,
        communityReactions: updatedReactions,
      }));

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, emojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (!post || !communityData) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.postNotFound}>Post not found</div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const SuccessPostPopup = ({ message, onClose, postId }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{message}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg width="32" height="32" viewBox="0 0 24 24">
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const ErrorPopup = ({ message, onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg width="24" height="24" fill="red" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
            clipRule="evenodd"
          />
        </svg>
        <p>{message}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg width="32" height="32" viewBox="0 0 24 24">
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Add these functions inside the CommunityPost component, before the return statement

  const checkConnectionType = async (userId, currentUserId) => {
    if (userId === currentUserId) return "You";

    try {
      const connectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${userId}`
      );
      const connectionDoc = await getDoc(connectionRef);

      if (
        connectionDoc.exists() &&
        connectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      const userConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      for (const conn of userConnectionsSnapshot.docs) {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${userId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }

      return "Extended";
    } catch (error) {
      console.error("Error checking connection type:", error);
      return "Extended";
    }
  };

  const renderPostContent = () => {
    if (!post) return null;

    return (
      <>
        <div className={classes.postHeader}>
          <div className={classes.postUserInfo}>
            <img
              className={classes.postImage}
              src={post.user?.profileImage || defaultUserImage}
              alt=""
              onClick={() => navigate(`/${post.user.urlSlug}`)}
            />
            <div className={classes.postUserDetails}>
              <div className={classes.postUserNameContainer}>
                <p
                  className={classes.postUserName}
                  onClick={() => navigate(`/${post.user.urlSlug}`)}
                >
                  <span className={classes.nameText}>
                    {post.user.firstName} {post.user.lastName}
                  </span>
                </p>
                <span className={classes.postConnectionType}>
                  •{" "}
                  {connectionTypes[post.user.id] ||
                    post.user.connectionType ||
                    "Extended"}
                </span>
              </div>
              {post.user.bio && (
                <p className={classes.postBio}>{post.user.bio}</p>
              )}
              {post.user.link && (
                <div className={classes.userLinkContainer}>
                  <a
                    href={post.user.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    className={classes.postUserLink}
                  >
                    <span className={classes.postUserLinkText}>
                      {post.user.linkText || post.user.link}
                    </span>
                    <svg
                      className={classes.postUserLinkIcon}
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangered"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </div>
          {renderPostActions()}
        </div>
        {post.text && renderPostText()}
        {renderPostMedia()}
        {renderPostInteractions()}
        {renderComments()}
      </>
    );
  };

  // Add these functions inside the CommunityPost component, after renderPostContent

  const renderPostActions = () => {
    return (
      <div className={classes.postOptions}>
        <svg
          className={`${classes.optionsIcon} ${
            showDropdown[post.id] ? classes.optionsIconActive : ""
          }`}
          aria-hidden="true"
          width="40"
          height="40"
          fill="none"
          viewBox="0 0 24 24"
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown((prev) => ({ ...prev, [post.id]: !prev[post.id] }));
          }}
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeWidth="3"
            d="M6 12h.01m6 0h.01m5.99 0h.01"
          />
        </svg>
        {showDropdown[post.id] && (
          <div className={classes.optionsDropdown}>
            {post.user.id === currentUser?.uid ||
            (post.user.isStartup &&
              post.user.startupOwnerId === currentUser?.uid) ? (
              <>
                <button
                  onClick={() => handleEditPost(post)}
                  className={classes.editPost}
                >
                  <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 4.998 4 14.998V20h5l10-10m-5-5 2.025-2.025a2.121 2.121 0 0 1 3 0l2 2a2.121 2.121 0 0 1 0 3L19 10m-5-5 5 5"
                    />
                  </svg>
                  Edit
                </button>
                <button
                  onClick={handleDeletePost}
                  className={classes.deletePost}
                >
                  <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 7.5v9.167c0 1.47 1.212 2.666 2.706 2.666h8.588c1.494 0 2.706-1.196 2.706-2.666V7.5M10 11.167v3.333m4-3.333v3.333M4 7.5h16m-3.333-1.667c0-.92-.76-1.666-1.667-1.666h-6c-.907 0-1.667.746-1.667 1.666"
                    />
                  </svg>
                  Delete
                </button>
              </>
            ) : currentUser?.uid === communityData.ownerId || isModerator ? (
              <button onClick={handleDeletePost} className={classes.deletePost}>
                <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 7.5v9.167c0 1.47 1.212 2.666 2.706 2.666h8.588c1.494 0 2.706-1.196 2.706-2.666V7.5M10 11.167v3.333m4-3.333v3.333M4 7.5h16m-3.333-1.667c0-.92-.76-1.666-1.667-1.666h-6c-.907 0-1.667.746-1.667 1.666"
                  />
                </svg>
                Delete
              </button>
            ) : (
              <button className={classes.reportPost}>
                <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                  />
                </svg>
                Report
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderPostText = () => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractions =
      !post.content &&
      (!post.communityReactions ||
        Object.keys(post.communityReactions).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";
    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractions || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <div
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPosts((prev) => ({
                ...prev,
                [post.id]: !prev[post.id],
              }));
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderPostMedia = () => {
    if (!post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.imageCarousel}>
            {Array.isArray(post.content.url) ? (
              <>
                <img
                  key={currentImageIndex[post.id] || 0}
                  src={post.content.url[currentImageIndex[post.id] || 0]}
                  alt=""
                  className={classes.postContentImage}
                  onClick={() =>
                    handleNextImage(post.id, post.content.url.length)
                  }
                />
                {post.content.url.length > 1 && (
                  <div className={classes.dotNavigation}>
                    {post.content.url.map((_, index) => (
                      <span
                        key={index}
                        className={`${classes.dotNav} ${
                          index === (currentImageIndex[post.id] || 0)
                            ? classes.activeDot
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentImageIndex((prev) => ({
                            ...prev,
                            [post.id]: index,
                          }));
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <img
                src={post.content.url}
                alt=""
                className={classes.postContentImage}
              />
            )}
          </div>
        );

      case "video":
        return (
          <div className={classes.videoContainer}>
            <video
              ref={(el) => (videoRefs.current[post.id] = el)}
              className={classes.postContentVideo}
              playsInline
              muted={videoVolumes[post.id] !== true}
              loop
              autoPlay
              preload="metadata"
              poster={post.content.thumbnail || ""}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
            >
              <source src={post.content.url} type="video/mp4" />
            </video>
            <div className={classes.videoControls}>
              <button
                className={classes.volumeControl}
                onClick={(e) => {
                  e.stopPropagation();
                  setVideoVolumes((prev) => ({
                    ...prev,
                    [post.id]: !prev[post.id],
                  }));
                  const video = videoRefs.current[post.id];
                  if (video) {
                    video.muted = !video.muted;
                  }
                }}
              >
                {videoVolumes[post.id] !== true ? (
                  <svg
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        );

      case "pdf":
        return (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              (!post.communityReactions ||
                Object.keys(post.communityReactions).length === 0) &&
              (!post.commentCount || post.commentCount === 0)
                ? classes.pdfPreviewNoInteractions
                : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Z"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      fileName = Array.isArray(post.content.fileName)
                        ? post.content.fileName[0] || "Unnamed PDF"
                        : post.content.fileName;
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg width="14" height="14" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        );

      default:
        return null;
    }
  };

  const renderPostInteractions = () => {
    if (!post) return null;

    const hasReactions =
      post.communityReactions &&
      Object.keys(post.communityReactions).length > 0;
    const hasComments = post.commentCount && post.commentCount > 0;

    return (
      <>
        {(hasReactions || hasComments) && (
          <div className={classes.postInteractionsInfo}>
            {hasReactions && (
              <div
                className={classes.reactionsSummary}
                onClick={() =>
                  handleReactionsClick(post.id, post.communityReactions)
                }
              >
                <div className={classes.reactionStack}>
                  {getTopThreeEmojis(post.communityReactions)
                    .reverse()
                    .map((emoji, index) => (
                      <div
                        key={index}
                        className={classes.reactionEmoji}
                        style={{
                          zIndex:
                            getTopThreeEmojis(post.communityReactions).length -
                            index,
                          fontSize: "var(--emoji-font-size)",
                          marginLeft: "var(--emoji-margin-left)",
                          marginBottom: "var(--emoji-margin-bottom)",
                          marginTop: "var(--emoji-margin-top)",
                        }}
                      >
                        {emoji}
                      </div>
                    ))}
                </div>
                <span
                  className={classes.reactionCount}
                  style={{
                    marginLeft: "var(--count-margin-left)",
                  }}
                >
                  {Object.keys(post.communityReactions).length}
                </span>
              </div>
            )}
            <div className={classes.rightInteractions}>
              {hasComments && (
                <span className={classes.commentCount}>
                  {post.commentCount} comment
                  {post.commentCount !== 1 ? "s" : ""}
                </span>
              )}
            </div>
          </div>
        )}
        <div className={classes.postInteractions}>
          <button
            className={classes.interactionButton}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={currentUser?.profileImage || defaultUserImage}
                alt="Profile"
                className={classes.interactionProfileImage}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </div>
          </button>

          <button
            className={`${classes.interactionButton} ${
              (selectedStartup &&
                post.communityReactions?.[`startup_${selectedStartup.id}`]) ||
              (!selectedStartup && post.communityReactions?.[currentUser?.uid])
                ? classes.emojiButton
                : ""
            }`}
            onClick={() => handleReactionClick(post.id)}
          >
            {selectedStartup ? (
              post.communityReactions?.[`startup_${selectedStartup.id}`] ? (
                <span>
                  {post.communityReactions[`startup_${selectedStartup.id}`]}
                </span>
              ) : (
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                  />
                </svg>
              )
            ) : post.communityReactions?.[currentUser?.uid] ? (
              <span>{post.communityReactions[currentUser.uid]}</span>
            ) : (
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                />
              </svg>
            )}
          </button>

          <button
            className={classes.interactionButton}
            onClick={() => {
              setActiveCommentPostId(post.id);
              if (!comments[post.id]) {
                fetchComments(post.id);
              }
            }}
          >
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
              />
            </svg>
          </button>
        </div>

        {activeEmojiPicker === `post_${post.id}` && (
          <EmojiPicker
            onEmojiClick={(emoji) => handleEmojiSelect(emoji, post.id)}
            onClose={() => {
              setActiveEmojiPicker(null);
              setTimeout(() => {
                window.scrollTo(0, emojiPickerScrollPosition);
              }, 0);
            }}
          />
        )}
      </>
    );
  };

  const renderComments = () => {
    if (!post) return null;

    return (
      <div className={classes.commentsSection}>
        {activeCommentPostId === post.id && (
          <>
            <CommentInputSection
              currentUser={currentUser}
              selectedStartup={selectedStartup}
              newComment={newComment[post.id] || ""}
              onCommentChange={(e) => handleCommentInputChange(e, post.id)}
              onSubmit={() => handleAddComment(post.id)}
              commentInputRef={(el) => (commentInputRef.current[post.id] = el)}
            />
            {comments[post.id] && comments[post.id].length > 0 && (
              <div className={classes.commentsList}>
                {renderCommentList(post.id)}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUser?.profileImage || defaultUserImage
              }
              alt="Your profile"
              className={classes.commentUserImage}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({
      ...prev,
      [postId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyInputChange = (e, postId, commentId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = (commentId) => {
    setActiveCommentId((prevId) => (prevId === commentId ? null : commentId));
    setReplyComments((prev) => ({
      ...prev,
      [commentId]: prev[commentId] || "",
    }));
  };

  const renderCommentList = (postId) => {
    const postComments = comments[postId];
    if (!postComments) return null;

    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultUserImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleUserClick(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleUserClick(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •{" "}
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id &&
                    renderCommentOptions(comment, postId)}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.postDot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}
              {activeEmojiPicker === `comment_${comment.id}` && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(postId, comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, emojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(postId, comment.id)}
              {activeCommentId === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments[postId] || {}}
                  onReplyChange={(e) =>
                    handleReplyInputChange(e, postId, comment.id)
                  }
                  onSubmit={() => handleAddComment(postId, comment.id)}
                  replyInputRef={replyInputRef}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  const handleUserClick = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      }
    } catch (error) {
      console.error("Error navigating to user profile:", error);
    }
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown((prev) => (prev === commentId ? null : commentId));
  };

  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage
                  : currentUser?.profileImage || defaultUserImage
              }
              alt="Your profile"
              className={classes.replyUserImage}
            />
          </div>
          <textarea
            ref={(el) => {
              if (replyInputRef && replyInputRef.current) {
                replyInputRef.current[comment.id] = el;
              }
            }}
            value={replyComments[comment.id] || ""}
            onChange={(e) => onReplyChange(e, comment.id)}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addReplyButton} ${
            !replyComments[comment.id]?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}y`;
    }
  };

  // Add these functions near the top of CommunityPost component:

  const handleAddComment = async (postId, parentCommentId = null) => {
    if (!currentUser || !post) return;

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[postId]?.[parentCommentId] || "";
    } else {
      commentText = newComment[postId] || "";
    }

    if (!commentText.trim()) return;

    try {
      // Get the full post path
      const postPath = findPostPath();
      if (!postPath) {
        throw new Error("Invalid post path");
      }

      let commenterInfo;
      if (selectedStartup) {
        const startupRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${selectedStartup.id}`
        );
        const startupDoc = await getDoc(startupRef);
        const startupData = startupDoc.data();

        commenterInfo = {
          id: `startup_${selectedStartup.id}`,
          name: selectedStartup.startupName,
          image: selectedStartup.startupImage,
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
          connectionType: "Startup",
          isStartup: true,
          startupId: selectedStartup.id,
          ownerId: currentUser.uid,
          startupUrlSlug: startupData.startupUrlSlug,
        };
      } else {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.data();

        commenterInfo = {
          id: currentUser.uid,
          name: `${userData.firstName} ${userData.lastName}`,
          image: userData.profileImage || defaultUserImage,
          bio: userData.bio || "",
          link: userData.link || null,
          linkText: userData.linkText || null,
          connectionType: "You",
          isStartup: false,
          urlSlug: userData.urlSlug,
        };
      }

      const processedCommentText = convertUrlsToLinks(commentText);

      // Use the correct path for comments
      const commentsRef = collection(firestore, `${postPath}/comments`);

      const rootCommentId = parentCommentId
        ? await getRootCommentId(post.communityPostId, parentCommentId)
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio,
        connectionType: commenterInfo.connectionType,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        postUserId: post.user.id,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup
          ? {
              startupId: commenterInfo.startupId,
              startupOwnerId: commenterInfo.ownerId,
              startupUrlSlug: commenterInfo.startupUrlSlug,
            }
          : {
              urlSlug: commenterInfo.urlSlug,
            }),
      };

      const batch = writeBatch(firestore);

      // Add the new comment
      const newCommentRef = await addDoc(commentsRef, {
        ...commentData,
        createdAt: serverTimestamp(),
      });

      // Update the post's comment count
      const postRef = doc(firestore, postPath);
      batch.update(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
      });

      // Handle notifications
      if (!parentCommentId) {
        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: postId,
          communityId: communityData.id,
          communityOwnerId: communityData.ownerId,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: post.text || "",
            mediaType: post.content?.type || null,
            mediaUrl: post.content?.url || null,
            fileName: post.content?.fileName || null,
            ...(post.content?.type === "video" && {
              thumbnail: post.content.thumbnail,
            }),
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(notificationRef, notificationData);
      }

      await batch.commit();

      // Update local state
      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId,
        postUserId: post.user.id,
      };

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [...(prevComments[postId] || []), newCommentObject],
      }));

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
        if (commentInputRef.current[postId]) {
          commentInputRef.current[postId].style.height = "auto";
        }
      }

      setPost((prevPost) => ({
        ...prevPost,
        commentCount: (prevPost.commentCount || 0) + 1,
      }));

      setActiveCommentId(null);
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message: "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  // Update the getRootCommentId function to use the correct path
  const getRootCommentId = async (communityPostId, commentId) => {
    try {
      const postPath = findPostPath();
      if (!postPath) return null;

      const commentRef = doc(firestore, `${postPath}/comments/${commentId}`);

      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        return null;
      }

      const commentData = commentSnap.data();
      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(communityPostId, commentData.parentCommentId);
    } catch (error) {
      console.error("Error getting root comment ID:", error);
      return null;
    }
  };

  const handleCommentReactionClick = (commentId) => {
    setActiveEmojiPicker((prev) =>
      prev === `comment_${commentId}` ? null : `comment_${commentId}`
    );
  };

  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (!currentUser) return;

    try {
      const commentRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;
      }

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, reactions: updatedReactions }
            : comment
        ),
      }));

      setActiveEmojiPicker(null);
    } catch (error) {
      console.error("Error updating comment reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const renderCommentOptions = (comment, postId) => {
    const isOwnComment =
      (selectedStartup &&
        comment.isStartup &&
        selectedStartup.id === comment.startupId) ||
      (!selectedStartup &&
        !comment.isStartup &&
        comment.userId === currentUser?.uid);

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() => handleEditComment(comment.id)}
          >
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M14 4.182a4.136 4.136 0 0 1 2.899-1.182c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Z"
                clipRule="evenodd"
              />
            </svg>
            Edit
          </button>
        )}
        {(isOwnComment || isOwner || isModerator) && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(comment.id)}
          >
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                clipRule="evenodd"
              />
            </svg>
            Delete
          </button>
        )}
      </div>
    );
  };

  const renderCommentText = (comment) => {
    if (!comment || !comment.text) return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={(e) => {
              setEditCommentText(e.target.value);
              const textarea = e.target;
              textarea.style.height = "auto";
              textarea.style.height = `${textarea.scrollHeight}px`;
            }}
            className={classes.editCommentInput}
          />
          <div className={classes.editCommentButtons}>
            <button
              className={`${classes.saveChangesButton} ${
                editCommentText.trim() === "" ? classes.disabledButton : ""
              }`}
              onClick={() =>
                editCommentText.trim() !== "" &&
                handleSaveCommentChanges(comment.postId, comment.id)
              }
            >
              Save changes
            </button>
            <div className={classes.postDot}> • </div>
            <button
              className={classes.cancelEditButton}
              onClick={() => setEditingCommentId(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }

    const text = comment.text;
    const isLongComment = text.length > 149;
    const displayText = expandedComments[comment.id]
      ? text
      : text.slice(0, 149);

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={() =>
              setExpandedComments((prev) => ({ ...prev, [comment.id]: true }))
            }
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    if (!comments[postId]) return null;

    const replies = comments[postId].filter(
      (reply) => reply.rootCommentId === rootCommentId && !reply.isRootComment
    );

    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => (
          <div key={reply.id} className={classes.replyItem}>
            {/* Reply content rendering similar to comments */}
            {renderCommentContent(reply)}
          </div>
        ))}
        {replies.length > visibleRepliesCount && (
          <button
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </button>
        )}
      </div>
    );
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const convertUrlsToLinks = (text) => {
    const urlRegex =
      /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
    return text.replace(urlRegex, (url) => {
      const fullUrl = url.startsWith("http") ? url : `https://${url}`;
      return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" style="color: orangered; font-weight: 900;">${url}</a>`;
    });
  };

  const handleEditComment = (commentId) => {
    const comment = comments[post.id].find((c) => c.id === commentId);
    if (!comment) return;

    setEditingCommentId(commentId);
    const strippedText = comment.text.replace(/<[^>]*>/g, "");
    setEditCommentText(strippedText);
    setOpenCommentDropdown(null);

    setTimeout(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.focus();
        const length = editTextareaRef.current.value.length;
        editTextareaRef.current.setSelectionRange(length, length);
        editTextareaRef.current.style.height = "auto";
        editTextareaRef.current.style.height = `${editTextareaRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const handleDeleteComment = async (commentId) => {
    if (!currentUser || !post) return;

    try {
      const batch = writeBatch(firestore);
      const commentRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      // Delete reactions notifications
      if (commentData.reactions) {
        for (const [reactorId, emoji] of Object.entries(
          commentData.reactions
        )) {
          const notificationRef = doc(
            firestore,
            `users/${commentData.userId}/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );
          batch.delete(notificationRef);
        }
      }

      // Delete replies and their notifications
      const repliesQuery = query(
        collection(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments`
        ),
        where("parentCommentId", "==", commentId)
      );
      const repliesSnapshot = await getDocs(repliesQuery);

      repliesSnapshot.forEach((replyDoc) => {
        const replyRef = doc(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments/${replyDoc.id}`
        );
        batch.delete(replyRef);
      });

      // Delete the comment itself
      batch.delete(commentRef);

      // Update post comment count
      const postRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}`
      );
      batch.update(postRef, {
        commentCount: increment(-(1 + repliesSnapshot.size)),
      });

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: prevComments[post.id].filter(
          (comment) =>
            comment.id !== commentId && comment.parentCommentId !== commentId
        ),
      }));

      setPost((prevPost) => ({
        ...prevPost,
        commentCount: Math.max(
          (prevPost.commentCount || 0) - (1 + repliesSnapshot.size),
          0
        ),
      }));

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const handleSaveCommentChanges = async (postId, commentId) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const commentRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.id}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
        updatedAt: serverTimestamp(),
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const renderCommentContent = (reply) => {
    return (
      <>
        <div className={classes.replyHeader}>
          <div className={classes.replyUserInfo}>
            <img
              src={reply.userImage || defaultUserImage}
              alt={reply.userName}
              className={classes.replyUserImage}
              onClick={() => handleUserClick(reply.userId)}
            />
            <div className={classes.replyUserDetails}>
              <div className={classes.replyUserNameContainer}>
                <span
                  className={classes.replyUserName}
                  onClick={() => handleUserClick(reply.userId)}
                >
                  {reply.userName}
                </span>
                <span className={classes.replyConnectionType}>
                  • {reply.connectionType}
                </span>
              </div>
              {reply.bio && <p className={classes.replyUserBio}>{reply.bio}</p>}
              {reply.link && (
                <div className={classes.replyUserLinkContainer}>
                  <a
                    href={reply.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    className={classes.replyUserLink}
                  >
                    <span className={classes.replyUserLinkText}>
                      {reply.linkText || reply.link}
                    </span>
                    <svg
                      className={classes.replyUserLinkIcon}
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </a>
                </div>
              )}
              <div className={classes.replyTimeAndEditDetails}>
                <p className={classes.replyTimestamp}>
                  {formatTimestamp(reply.createdAt)}
                </p>
                {reply.edited && (
                  <div className={classes.replyEditDot}>
                    • <span className={classes.replyEditText}>Edited</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.replyContent}>{renderCommentText(reply)}</div>
        <div className={classes.replyActions}>
          <div className={classes.replyInteractions}>
            <button
              className={classes.replyReactButton}
              onClick={() => handleCommentReactionClick(reply.id)}
            >
              React
            </button>
            <button
              className={classes.replyReplyButton}
              onClick={() => handleReplyButton(reply.id)}
            >
              Reply
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.postCard}>{renderPostContent()}</div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions || {}}
          currentUserId={currentUser?.uid}
        />
      )}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
    </div>
  );
};

export default CommunityPost;
