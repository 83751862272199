import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import classes from "./InitialLocationSetup.module.css";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const InitialLocationSetup = () => {
  const { currentUser } = useAuth();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countryLoading, setCountryLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const cityCache = useMemo(() => ({}), []);

  useEffect(() => {
    const loadCountries = async () => {
      setCountryLoading(true);
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(data);
      } catch (error) {
      } finally {
        setCountryLoading(false);
      }
    };

    loadCountries();
  }, []);

  const loadCities = useCallback(async () => {
    setCityLoading(true);
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = data;
      setCities(data);
    } catch (error) {
    } finally {
      setCityLoading(false);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCountry || !selectedCity) {
      setErrorPopup({
        message: "Please select your country and city.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
      });

      navigate("/dashboard");
    } catch (error) {
      setErrorPopup({
        message: "Failed to update location. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const isSubmitDisabled =
    loading ||
    !selectedCountry ||
    !selectedCity ||
    countryInputValue !== selectedCountry?.name ||
    cityInputValue !== selectedCity?.name;

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {errorPopup && (
          <ErrorPopup
            message={errorPopup.message}
            onClose={() => setErrorPopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Where are you based?</p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="country" className={classes.label}>
                Country
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United Kingdom"
                loading={countryLoading}
              />

              <label
                htmlFor="city"
                className={`${classes.label} ${
                  !selectedCountry || loading ? classes.disabled : ""
                }`}
              >
                City
              </label>
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: London"
                loading={cityLoading}
              />
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={isSubmitDisabled}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
            </form>

            {error && (
              <p className={classes.error}>
                <span
                  className={`material-symbols-outlined ${classes.errorIcon}`}
                >
                  error
                </span>
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialLocationSetup;
