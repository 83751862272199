import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  writeBatch,
  serverTimestamp,
  addDoc,
  orderBy,
  increment,
} from "firebase/firestore";
import defaultImage from "../../assets/icons/profileImage.jpg";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { onAuthStateChanged } from "firebase/auth";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./SingleComment.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import RepostsModal from "../../components/Modals/RepostsModal";
import { useNavigate, Link } from "react-router-dom";
import PostModal from "../../components/Modals/PostModal";

function SingleComment() {
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [commentDisplayData, setCommentDisplayData] = useState(null);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fromComments, setFromComments] = useState(false);
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [expandedPost, setExpandedPost] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [videoVolume, setVideoVolume] = useState(false);
  const videoRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [repostMessage, setRepostMessage] = useState(null);
  const [fromReactions, setFromReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState(null);
  const location = useLocation();
  const [errorPopup, setErrorPopup] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);

  const [isSafari, setIsSafari] = useState(false);
  const fromUserProfile = location.state?.fromUserProfile || false;

  // New state variables for comments
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [visibleComments, setVisibleComments] = useState(10);
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);

  const commentInputRef = useRef(null);
  const replyInputRef = useRef({});

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleEditPost = () => {
    setPostModalScrollPosition(window.scrollY);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  const fetchComments = useCallback(async () => {
    if (!post || !post.user || !post.id) {
      console.log("Missing data for fetching comments", { post });
      return;
    }

    try {
      // Use the correct path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${post.id}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));

      const snapshot = await getDocs(q);
      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          // Handle startup comments
          if (commentData.isStartup) {
            // Get startup info
            const startupRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
            );
            const startupSnapshot = await getDoc(startupRef);
            const startupData = startupSnapshot.exists()
              ? startupSnapshot.data()
              : null;

            if (!startupData) {
              console.log(
                "Startup data not found for comment:",
                docSnapshot.id
              );
              return null;
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: startupData.startupName,
              userImage: startupData.startupImage || profileImage,
              connectionType: "Startup",
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              postId: post.id,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              startupUrlSlug: startupData.startupUrlSlug,
            };
          } else {
            // Handle regular user comments
            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) {
              console.log("User not found for comment:", docSnapshot.id);
              return null;
            }

            const userData = userDoc.data();
            let connectionType = "Unknown";
            try {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            } catch (error) {
              console.error("Error checking connection type:", error);
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId: post.id,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              userUrlSlug: userData.urlSlug,
            };
          }
        })
      );

      // Filter out any null values (failed comment retrievals)
      const validComments = fetchedComments.filter(
        (comment) => comment !== null
      );

      console.log("Fetched comments:", validComments);

      setComments(validComments);
      setVisibleComments(10); // Reset to show initial set of comments
    } catch (error) {
      console.error("Error fetching comments:", error);
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    }
  }, [post, currentUser, checkConnectionType]);

  useEffect(() => {
    if (post && currentUser) {
      console.log("Fetching comments for post:", post.id);
      fetchComments();
    }
  }, [post, currentUser]);

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0)
    );
  };

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage,
        bio: selectedStartup.bio || "",
        link: selectedStartup.link || null,
        linkText: selectedStartup.linkText || null,
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUserProfileImage,
      bio: currentUser.bio || "",
      link: currentUser.link || null,
      linkText: currentUser.linkText || null,
      connectionType: "You",
      isStartup: false,
    };
  };

  const getCommentsPath = (post) => {
    if (post.user.isStartup) {
      return `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}/comments`;
    }
    return `users/${post.user.id}/posts/${post.id}/comments`;
  };

  const handleAddComment = async (parentCommentId = null) => {
    if (!currentUser || !post) return;

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[parentCommentId] || "";
    } else {
      commentText = newComment;
    }

    if (!commentText.trim()) return;

    try {
      const commenterInfo = getCommenterInfo(currentUser, selectedStartup);
      const processedCommentText = convertUrlsToLinks(commentText);

      // Get the correct path for the post's comments
      const commentsRef = collection(firestore, getCommentsPath(post));

      // Get reference to the post
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${post.id}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      const rootCommentId = parentCommentId
        ? await getRootCommentId(
            post.user.startupOwnerId || post.user.id,
            post.id,
            parentCommentId,
            post.user.isStartup,
            post.user.startupId
          )
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio,
        connectionType: commenterInfo.connectionType,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        postUserId: post.user.id,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup && {
          startupId: commenterInfo.startupId,
          startupOwnerId: commenterInfo.ownerId,
        }),
      };

      const newCommentRef = await addDoc(commentsRef, {
        ...commentData,
        createdAt: serverTimestamp(),
      });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      // Update commentedPosts collection for the commenter (user or startup)
      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${post.id}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId: post.user.startupOwnerId || post.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: post.id,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      // Notify post owner about new comment (if not commenting on own post)
      const shouldNotifyPostOwner =
        !parentCommentId && // Is a root comment
        // For startup posts
        ((post.user.isStartup &&
          // Don't notify if commenter is the startup owner
          currentUser.uid !== post.user.startupOwnerId &&
          // Don't notify if commenter is a startup owned by the post owner
          (!commenterInfo.isStartup ||
            commenterInfo.ownerId !== post.user.startupOwnerId)) ||
          // For regular posts
          (!post.user.isStartup &&
            // Don't notify if commenter is the post owner
            currentUser.uid !== post.user.id &&
            // Don't notify if commenter is a startup owned by the post owner
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== post.user.id)));

      if (shouldNotifyPostOwner) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: post.id,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(postOwnerNotificationRef, notificationData);
      }

      // Handle reply notifications
      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          // For startup parent comments
          ((parentCommentData.isStartup &&
            // Don't notify if replier is the startup owner
            currentUser.uid !== parentCommentData.startupOwnerId &&
            // Don't notify if replier is a startup owned by the parent comment owner
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
            // For regular parent comments
            (!parentCommentData.isStartup &&
              // Don't notify if replier is the comment owner
              currentUser.uid !== parentCommentData.userId &&
              // Don't notify if replier is a startup owned by the comment owner
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.userId)));

        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const replyNotificationData = {
            type: "reply",
            postId: post.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(replyNotificationRef, replyNotificationData);
        }
      }

      await batch.commit();

      // Update local state
      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId: post.id,
        postUserId: post.user.id,
      };

      setComments((prevComments) => [...prevComments, newCommentObject]);

      setPost((prevPost) => ({
        ...prevPost,
        commentCount: (prevPost.commentCount || 0) + 1,
        comments: {
          ...(prevPost.comments || {}),
          [commenterInfo.id]: now,
        },
        originalDirectCommentTime: now,
      }));

      // Reset input states
      if (parentCommentId) {
        setReplyComments((prev) => ({ ...prev, [parentCommentId]: "" }));
      } else {
        setNewComment("");
        if (commentInputRef.current) {
          commentInputRef.current.style.height = "auto";
        }
      }
      setActiveCommentId(null);
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const getRootCommentId = async (
    postUserId,
    postId,
    commentId,
    isStartupPost,
    startupId
  ) => {
    try {
      const basePath = isStartupPost
        ? `users/${postUserId}/startups/${startupId}`
        : `users/${postUserId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(
        postUserId,
        postId,
        commentData.parentCommentId,
        isStartupPost,
        startupId
      );
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  const handleCommentInputChange = (e) => {
    const textarea = e.target;
    setNewComment(textarea.value);

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyInputChange = (e, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [replyId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = (commentId) => {
    setActiveCommentId((prevActiveCommentId) =>
      prevActiveCommentId === commentId ? null : commentId
    );
    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));

    // Add a slight delay before focusing
    setTimeout(() => {
      if (replyInputRef.current[commentId]) {
        const inputElement = replyInputRef.current[commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleLoadMoreComments = () => {
    setVisibleComments((prev) => prev + 10);
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 1) + 9,
    }));
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const handleCommentReactionClick = (commentId) => {
    if (!currentUser || !post) return;

    const comment = comments.find((c) => c.id === commentId);
    if (!comment) {
      setErrorPopup({
        message: "Comment not found.",
        isError: true,
      });
      return;
    }

    // If current emoji exists, remove it directly
    const currentEmoji = selectedStartup
      ? comment.reactions?.[`startup_${selectedStartup.id}`]
      : comment.reactions?.[currentUser.uid];

    if (currentEmoji) {
      handleCommentEmojiSelect(commentId, currentEmoji);
    } else {
      // Otherwise show emoji picker
      setCommentEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(activeEmojiPicker === commentId ? null : commentId);
    }
  };

  const handleCommentEmojiSelect = async (commentId, emoji) => {
    if (!commentId || !currentUser || !post) return;

    try {
      // Get the correct path for the post's comments
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${post.id}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactionKey = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactionKey] === emoji) {
        // Remove reaction
        delete updatedReactions[reactionKey];
        await updateDoc(commentRef, { reactions: updatedReactions });

        // Delete notification for the comment owner
        if (commentData.isStartup) {
          await deleteDoc(
            doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_reaction_${commentId}_${reactionKey}`
            )
          );
        } else {
          await deleteDoc(
            doc(
              firestore,
              `users/${commentData.userId}/notifications`,
              `comment_reaction_${commentId}_${reactionKey}`
            )
          );
        }
      } else {
        // Add or update reaction
        updatedReactions[reactionKey] = emoji;
        await updateDoc(commentRef, { reactions: updatedReactions });

        // Check if we should send a notification
        const shouldNotify = commentData.isStartup
          ? // For startup comments
            selectedStartup
            ? // If reactor is a startup
              commentData.startupOwnerId !== currentUser.uid && // Don't notify if reactor owns the startup that commented
              commentData.startupId !== selectedStartup.id // Don't notify if reactor is the same startup
            : // If reactor is a user
              commentData.startupOwnerId !== currentUser.uid // Don't notify if reactor owns the startup that commented
          : // For user comments
          selectedStartup
          ? // If reactor is a startup
            commentData.userId !== currentUser.uid // Don't notify if reactor owns the commenting user
          : // If reactor is a user
            commentData.userId !== currentUser.uid; // Don't notify if reactor is the commenting user

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactionKey}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: post.id,
            commentId: commentId,
            reactorId: reactionKey,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          // Also update any replies that match the commentId
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId &&
            comment.id === commentId
          ) {
            return { ...comment, reactions: updatedReactions };
          }
          return comment;
        })
      );

      setActiveEmojiPicker(null);
      setTimeout(() => {
        window.scrollTo(0, commentEmojiPickerScrollPosition);
      }, 0);
    } catch (error) {
      console.error("Error updating comment reaction:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const renderPostDropdown = () => {
    // Check if current user owns the post (either directly or via startup ownership)
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid
      : post.user.id === currentUser.uid;

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button onClick={handleEditPost} className={classes.editPost}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14 4.182c.782-.738 1.793-1.182 2.89-1.182 1.089 0 2.136.44 2.898 1.214.758.77 1.212 1.799 1.212 2.889 0 1.092-.438 2.135-1.194 2.904l-1.274 1.283-5.816-5.71 1.285-1.398Zm-2.722 2.77L6.186 12.12l2.187 2.153 5.069-5.224-2.164-2.098Zm-6.299 6.914-1.966 5.812a1.015 1.015 0 0 0 .248 1.018 1.043 1.043 0 0 0 1.04.246l5.876-1.934-5.198-5.142Zm6.984 4.02 5.092-5.182-2.215-2.18-5.068 5.223 2.191 2.139Z"
                  clipRule="evenodd"
                />
              </svg>
              Edit
            </button>
            <button onClick={handleDeletePost} className={classes.deletePost}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="gray"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414Z"
                  clipRule="evenodd"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <button onClick={handleReportPost} className={classes.reportPost}>
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown(
      openCommentDropdown === commentId ? null : commentId
    );
  };

  const getNotificationFields = (baseFields, selectedStartup = null) => {
    if (selectedStartup) {
      return {
        ...baseFields,
        isStartup: true,
        startupId: selectedStartup.id,
        startupOwnerId: currentUser.uid,
        startupName: selectedStartup.startupName,
        startupImage: selectedStartup.startupImage,
      };
    }
    return {
      ...baseFields,
      isStartup: false,
    };
  };

  const handleDeleteComment = async (commentId) => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      if (!post) {
        throw new Error("Post not found");
      }

      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${post.id}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid ||
          currentUser.uid === (post.user.startupOwnerId || post.user.id) ||
          (selectedStartup &&
            `startup_${selectedStartup.id}` === commentData.userId);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          currentUser.uid === (post.user.startupOwnerId || post.user.id);
      }

      if (!canDelete) {
        throw new Error("Not authorized to delete this comment");
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {
          console.error("Error in deleteStartupNotifications:", error);
        }
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        const postOwnerId = post.user.isStartup
          ? post.user.startupOwnerId
          : post.user.id;

        if (commentData.userId !== postOwnerId) {
          await deleteDoc(
            doc(
              firestore,
              `users/${postOwnerId}/notifications/comment_${commentRef.id}`
            )
          );
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.userId.startsWith("startup_")
            ) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        const reactionPromises = [];
        if (commentData.isStartup) {
          const startupOwnerReactionsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const startupOwnerReactionsSnapshot = await getDocs(
            startupOwnerReactionsQuery
          );
          reactionPromises.push(
            ...startupOwnerReactionsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );
        }

        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          reactionPromises.push(
            ...userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            reactionPromises.push(
              ...startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }
        await Promise.all(reactionPromises);

        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentToDeleteRef);

      const batch = writeBatch(firestore);
      const postRef = doc(firestore, `${basePath}/posts/${post.id}`);

      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }
      for (const startupId of affectedStartupIds) {
        batch.update(postRef, {
          [`comments.startup_${startupId}`]: deleteField(),
        });
      }

      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${post.id}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));

        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${post.id}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );

            if (startupCommentedPostSnap.exists()) {
              const startupCommentedPostData = startupCommentedPostSnap.data();
              const updatedComments = { ...startupCommentedPostData.comments };

              deletedCommentIds.forEach((commentId) => {
                delete updatedComments[commentId];
              });

              if (Object.keys(updatedComments).length === 0) {
                batch.delete(startupCommentedPostRef);
              } else {
                batch.update(startupCommentedPostRef, {
                  comments: updatedComments,
                });
              }
            }
          } catch (error) {
            console.error(
              `Error updating commentedPosts for startup ${startupId} under user ${userDoc.id}:`,
              error
            );
          }
        }
      }

      await batch.commit();

      setComments((prevComments) =>
        prevComments.filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        )
      );

      setPost((prevPost) => {
        const updatedPost = {
          ...prevPost,
          commentCount: Math.max(
            (prevPost.commentCount || 0) - deletedCount,
            0
          ),
        };
        const updatedComments = { ...updatedPost.comments };
        affectedUserIds.forEach((userId) => {
          delete updatedComments[userId];
        });
        affectedStartupIds.forEach((startupId) => {
          delete updatedComments[`startup_${startupId}`];
        });
        updatedPost.comments = updatedComments;
        return updatedPost;
      });

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : error.message === "Not authorized to delete this comment"
            ? "You don't have permission to delete this comment."
            : "An error occurred while deleting the comment.",
        isError: true,
      });
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const renderComments = () => {
    console.log("Entering renderComments");
    console.log("Current post:", post);
    console.log("Current comments state:", comments);

    if (!post || !post.id) {
      console.log("No valid post to render comments for");
      return null;
    }

    if (!comments || !Array.isArray(comments)) {
      console.log("Comments is not a valid array");
      return null;
    }

    const rootComments = comments.filter((comment) => comment.isRootComment);
    console.log("Root comments:", rootComments);

    const visibleRootComments = rootComments.slice(0, visibleComments);

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = comments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || defaultImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {/* Show Edit if user owns comment or if selected startup owns comment */}
                      {(!comment.isStartup &&
                        comment.userId === currentUser.uid) ||
                      (comment.isStartup &&
                        selectedStartup?.id === comment.startupId) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(comment.id)}
                        >
                          {/* <svg>...</svg> */}
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete for post owner, user who owns the comment, or startup that owns the comment */}
                      {(currentUser.uid === post.user.id ||
                        (!comment.isStartup &&
                          comment.userId === currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id === comment.startupId)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          {/* <svg>...</svg> */}
                          Delete
                        </button>
                      )}

                      {/* Report option for others */}
                      {!(
                        currentUser.uid === post.user.id ||
                        (!comment.isStartup &&
                          comment.userId === currentUser.uid) ||
                        (comment.isStartup &&
                          selectedStartup?.id === comment.startupId)
                      ) && (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() =>
                            console.log(`Reporting comment: ${comment.id}`)
                          }
                        >
                          {/* <svg>...</svg> */}
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}
              {activeEmojiPicker === comment.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}

              {renderReplies(comment.id)}

              {activeCommentId === comment.id && (
                <ReplySection
                  comment={comment}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  replyComments={replyComments}
                  onReplyChange={handleReplyInputChange}
                  onSubmit={handleAddComment}
                />
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={handleLoadMoreComments}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (rootCommentId) => {
    if (!post) {
      console.log("No comments available for this post");
      return null;
    }

    const replies = comments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || defaultImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id && (
                    <div className={classes.commentOptionsDropdown}>
                      {(!reply.isStartup && reply.userId === currentUser.uid) ||
                      (reply.isStartup &&
                        selectedStartup?.id === reply.startupId) ? (
                        <button
                          className={classes.editCommentButton}
                          onClick={() => handleEditComment(reply.id, true)}
                        >
                          {/* <svg>...</svg> */}
                          Edit
                        </button>
                      ) : null}

                      {/* Show Delete for post owner, user who owns the reply, or startup that owns the reply */}
                      {(currentUser.uid === post.user.id ||
                        (!reply.isStartup &&
                          reply.userId === currentUser.uid) ||
                        (reply.isStartup &&
                          selectedStartup?.id === reply.startupId)) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(reply.id)}
                        >
                          {/* <svg>...</svg> */}
                          Delete
                        </button>
                      )}

                      {/* Report option for others */}
                      {!(
                        currentUser.uid === post.user.id ||
                        (!reply.isStartup &&
                          reply.userId === currentUser.uid) ||
                        (reply.isStartup &&
                          selectedStartup?.id === reply.startupId)
                      ) && (
                        <button
                          className={classes.reportCommentButton}
                          onClick={() =>
                            console.log(`Reporting reply: ${reply.id}`)
                          }
                        >
                          {/* <svg>...</svg> */}
                          Report
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === reply.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {activeCommentId === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage
                            : currentUserProfileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.uid
                          )
                        }
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[reply.id] = el)}
                      value={replyComments[reply.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, reply.id)}
                      placeholder={`Reply as ${
                        selectedStartup
                          ? selectedStartup.startupName
                          : "yourself"
                      }...`}
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() => handleAddComment(reply.id)}
                    className={`${classes.addReplyButton} ${
                      !replyComments[reply.id]?.trim() ? classes.disabled : ""
                    }`}
                    disabled={!replyComments[reply.id]?.trim()}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const { state } = location;
    if (state) {
      if (state.fromStartupComments && state.startupData) {
        // This is coming from a startup's comments
        setCommentDisplayData({
          isStartup: true,
          startupName: state.startupData.startupName,
          startupImage: state.startupData.startupImage,
          startupId: state.startupData.startupId,
          startupOwnerId: state.startupData.startupOwnerId,
          startupUrlSlug: state.startupData.startupUrlSlug,
        });
      } else if (state.fromUserProfile && state.userData) {
        // This is from a user's profile
        setCommentDisplayData({
          firstName: state.userData.firstName,
          lastName: state.userData.lastName,
          profileImage: state.userData.profileImage || profileImage,
        });
      } else {
        // This is from the current user's profile
        setCommentDisplayData({
          isCurrentUser: true,
        });
      }
    }
  }, [location]);

  const PostedByMessage = () => {
    if (!post) {
      console.log("PostedByMessage - post is null");
      return null;
    }

    if (commentDisplayData?.isStartup) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={commentDisplayData.startupImage}
            alt={commentDisplayData.startupName}
            className={classes.reactionImage}
            onClick={() =>
              navigate(`/startup/${commentDisplayData.startupUrlSlug}`)
            }
            style={{ cursor: "pointer" }}
          />
          <div className={classes.reactionTextWrapper}>
            <span className={classes.reactionText}>
              <span
                className={classes.reactionUsername}
                onClick={() =>
                  navigate(`/startup/${commentDisplayData.startupUrlSlug}`)
                }
                style={{ cursor: "pointer" }}
              >
                {commentDisplayData.startupName}
              </span>
              <span className={classes.reactionMessage}>commented on this</span>
            </span>
          </div>
        </div>
      );
    }

    // For regular users, including current user
    let commenterName, commenterImage, commenterId;

    if (commentDisplayData?.isCurrentUser) {
      commenterName = "You";
      commenterImage = currentUserProfileImage || profileImage;
      commenterId = currentUser?.uid;
    } else if (commentDisplayData) {
      commenterName = `${commentDisplayData.firstName} ${commentDisplayData.lastName}`;
      commenterImage = commentDisplayData.profileImage || profileImage;
      commenterId = commentDisplayData.userId;
    } else {
      if (post.commenter) {
        commenterId = post.commenter.id;
        commenterName =
          post.commenter.firstName + " " + post.commenter.lastName;
        commenterImage = post.commenter.profileImage || profileImage;
      } else if (post.comment) {
        commenterId = post.comment.userId;
        commenterName = post.comment.userName;
        commenterImage = post.comment.userImage || profileImage;
      }
    }

    return (
      <div className={classes.reactionHeader}>
        <img
          src={commenterImage}
          alt={commenterName}
          className={classes.reactionImage}
          onClick={() => handleNavigate(commenterId)}
          style={{ cursor: "pointer" }}
        />
        <div className={classes.reactionTextWrapper}>
          <span className={classes.reactionText}>
            <span
              className={classes.reactionUsername}
              onClick={() => handleNavigate(commenterId)}
              style={{ cursor: "pointer" }}
            >
              {commenterName}
            </span>
            <span className={classes.reactionMessage}> commented on this</span>
          </span>
        </div>
      </div>
    );
  };

  const handleRepost = async () => {
    if (!currentUser || !post) return;

    const postId = post.id;
    const reposterInfo = getCommenterInfo(currentUser, selectedStartup);

    try {
      // Get the correct post reference based on whether it's a startup post
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${postId}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${postId}`);

      // Determine the path for storing the repost
      const repostsPath = reposterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${reposterInfo.startupId}/reposts`
        : `users/${currentUser.uid}/reposts`;

      const repostedPostRef = doc(firestore, repostsPath, postId);
      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterInfo.id]) {
        // Remove repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: deleteField(),
        });
        delete updatedReposts[reposterInfo.id];
        await deleteDoc(repostedPostRef);

        // Delete notification
        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `repost_${postId}_${reposterInfo.id}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Add repost
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: now,
        });
        updatedReposts[reposterInfo.id] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify =
          post.user.startupOwnerId !== currentUser.uid ||
          (selectedStartup && post.user.startupId !== selectedStartup.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `repost_${postId}_${reposterInfo.id}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterInfo.id,
            reposterName: reposterInfo.name,
            reposterImage: reposterInfo.image,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(reposterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setPost((prevPost) => ({
        ...prevPost,
        reposts: updatedReposts,
      }));
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            profileImage: userData.profileImage || profileImage,
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchPost = async () => {
    try {
      setLoading(true);
      console.log("Fetching post with ID:", postId);

      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);

      let postData = null;
      let userData = null;
      let commentData = null;

      // First try to find the post
      for (const userDoc of usersSnapshot.docs) {
        const userId = userDoc.id;

        // Check user's regular posts
        const postRef = doc(firestore, `users/${userId}/posts/${postId}`);
        let postSnapshot = await getDoc(postRef);

        // If not found in regular posts, check startup posts
        if (!postSnapshot.exists()) {
          const startupsRef = collection(firestore, `users/${userId}/startups`);
          const startupsSnapshot = await getDocs(startupsRef);

          for (const startupDoc of startupsSnapshot.docs) {
            const startupPostRef = doc(
              firestore,
              `users/${userId}/startups/${startupDoc.id}/posts/${postId}`
            );
            postSnapshot = await getDoc(startupPostRef);

            if (postSnapshot.exists()) {
              postData = postSnapshot.data();
              // Get startup data for post owner if it's a startup post
              const startupData = startupDoc.data();
              userData = {
                id: `startup_${startupDoc.id}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || profileImage,
                isStartup: true,
                startupId: startupDoc.id,
                startupOwnerId: userId,
                startupUrlSlug: startupData.startupUrlSlug,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
              };
              break;
            }
          }
        } else {
          // Regular user post
          postData = postSnapshot.data();
          userData = {
            ...userDoc.data(),
            id: userId,
            isStartup: false,
          };
        }

        if (postData) {
          // Fetch comments for this post
          const commentsRef = collection(
            firestore,
            `users/${userData.id}${
              userData.isStartup ? `/startups/${userData.startupId}` : ""
            }/posts/${postId}/comments`
          );
          const commentsSnapshot = await getDocs(commentsRef);

          // Find the comment made by the startup
          for (const commentDoc of commentsSnapshot.docs) {
            const comment = commentDoc.data();
            if (comment.userId?.startsWith("startup_")) {
              commentData = {
                ...comment,
                id: commentDoc.id,
              };

              // Get the startup's data
              const startupId = comment.userId.replace("startup_", "");
              const startupOwnerDoc = await getDoc(
                doc(firestore, "users", comment.startupOwnerId)
              );
              const startupDoc = await getDoc(
                doc(
                  firestore,
                  `users/${comment.startupOwnerId}/startups/${startupId}`
                )
              );

              if (startupDoc.exists()) {
                const startupData = startupDoc.data();
                commentData.commenter = {
                  id: comment.userId,
                  firstName: startupData.startupName,
                  lastName: "",
                  profileImage: startupData.startupImage || profileImage,
                  isStartup: true,
                  startupId: startupId,
                  startupOwnerId: comment.startupOwnerId,
                  startupUrlSlug: startupData.startupUrlSlug,
                };
              }
              break;
            }
          }
          break;
        }
      }

      if (postData && userData) {
        const connectionType = await checkConnectionType(
          currentUser.uid,
          userData.isStartup ? userData.startupOwnerId : userData.id
        );

        setPost({
          ...postData,
          id: postId,
          user: {
            ...userData,
            connectionType,
          },
          reactions: postData.reactions || {},
          reposts: postData.reposts || {},
          comment: commentData,
          commenter: commentData?.commenter,
        });

        setFromComments(true);
      } else {
        console.log("Post or user data not found");
        setPost(null);
      }
    } catch (error) {
      console.error("Error fetching post:", error);
      setPost(null);
    } finally {
      setLoading(false);
      setHasAttemptedLoad(true);
    }
  };

  useEffect(() => {
    if (currentUser && postId) {
      console.log(
        "Calling fetchPost with currentUser:",
        currentUser.uid,
        "and postId:",
        postId
      );
      fetchPost();
    }
  }, [currentUser, postId]);

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== "function") return "";
    const now = new Date();
    const postDate = timestamp.toDate();
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (userId) => {
    console.log("handleNavigate called with userId:", userId);
    console.log("Current user ID:", currentUser?.uid);

    if (!userId) {
      console.error("handleNavigate called with no userId");
      return;
    }

    try {
      console.log("Fetching user data for ID:", userId);
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data:", userData);
        // Always navigate to urlSlug, even for current user
        if (userData.urlSlug) {
          console.log("Navigating to urlSlug:", userData.urlSlug);
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("No urlSlug found for user:", userId);
          setErrorPopup({
            message: "User profile not found.",
            isError: true,
          });
        }
      } else {
        console.error("User not found for ID:", userId);
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  const renderPostText = (post) => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPost
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractionsAndNoMedia || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html: convertUrlsToLinks(displayText) + (expandedPost ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPost && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPost(true);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.content.url.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === post.content.url.length - 1 ? 0 : prevIndex + 1
    );
  };

  const renderPostContent = (post) => {
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex]}
                    alt=""
                    className={classes.postContentImage}
                    onClick={() => handleNextImage(post.content.url.length)}
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === currentImageIndex ? classes.activeDot : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex(index);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );

      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={videoRef}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume(!videoVolume);
                  }}
                >
                  {!videoVolume ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        const hasNoInteractions =
          (!post.reactions || Object.keys(post.reactions).length === 0) &&
          (!post.reposts || Object.keys(post.reposts).length === 0) &&
          (!post.commentCount || post.commentCount === 0);

        return (
          <a
            href={post.content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              hasNoInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>
                  {(() => {
                    let fileName = "Unnamed PDF";
                    if (post.content.fileName) {
                      if (Array.isArray(post.content.fileName)) {
                        fileName = post.content.fileName[0] || "Unnamed PDF";
                      } else if (typeof post.content.fileName === "string") {
                        fileName = post.content.fileName;
                      }
                      // Remove .pdf extension if present
                      fileName = fileName.replace(/\.pdf$/i, "");
                    }
                    return fileName;
                  })()}
                </div>
                <div className={classes.open}>
                  View
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [post]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleEmojiSelect = async (emoji) => {
    if (!currentUser || !post) return;

    try {
      const batch = writeBatch(firestore);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      // Determine the correct path based on whether the post belongs to a startup
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${post.id}`);

      // Determine the path for storing the reaction record
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;

      let updatedReactions = { ...(post.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        // Remove reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await deleteDoc(reactedPostRef);

        // Delete notification for the post owner
        const notificationRef = doc(
          firestore,
          `users/${
            post.user.startupOwnerId || post.user.id
          }/notifications/reaction_${post.id}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        // Add or update reaction
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await setDoc(reactedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: post.id,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify =
          post.user.startupOwnerId !== currentUser.uid ||
          (selectedStartup && post.user.startupId !== selectedStartup.id);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              post.user.startupOwnerId || post.user.id
            }/notifications/reaction_${post.id}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: post.id,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      // Update local state
      setPost((prevPost) => ({
        ...prevPost,
        reactions: updatedReactions,
      }));

      setActiveEmojiPicker(null);
    } catch (error) {
      console.error("Error updating reaction:", error);
      setErrorPopup({
        message: "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const CommentInputSection = ({
    currentUser,
    selectedStartup,
    newComment,
    onCommentChange,
    onSubmit,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={(e) => onCommentChange(e)}
            placeholder={`Comment as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment.trim() ? classes.disabled : ""
          }`}
          disabled={!newComment.trim()}
        >
          Comment
        </div>
      </div>
    );
  };

  // Add ReplySection component
  const ReplySection = ({
    comment,
    currentUser,
    selectedStartup,
    replyComments,
    onReplyChange,
    onSubmit,
  }) => {
    const activeProfile = selectedStartup || currentUser;
    const profileImage = selectedStartup
      ? selectedStartup.startupImage
      : currentUserProfileImage;
    const navigateToProfile = () =>
      handleNavigate(
        selectedStartup
          ? `startup/${selectedStartup.startupUrlSlug}`
          : currentUser.uid
      );

    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={profileImage}
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={navigateToProfile}
            />
          </div>
          <textarea
            ref={(el) => (replyInputRef.current[comment.id] = el)}
            value={replyComments[comment.id] || ""}
            onChange={(e) => onReplyChange(e, comment.id)}
            onTouchStart={handleTouchStart}
            placeholder={`Reply as ${
              selectedStartup ? selectedStartup.startupName : "yourself"
            }...`}
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={() => onSubmit(comment.id)}
          className={`${classes.addReplyButton} ${
            !replyComments[comment.id]?.trim() ? classes.disabled : ""
          }`}
          disabled={!replyComments[comment.id]?.trim()}
        >
          Reply
        </div>
      </div>
    );
  };

  const handleReactionsClick = () => {
    if (!post || !post.reactions) return;
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({
      postId: post.id,
      reactions: post.reactions,
    });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = () => {
    if (!post || !post.reposts) return;
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({
      postId: post.id,
      reposts: post.reposts,
    });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handleDeletePost = async () => {
    if (!currentUser || !post) {
      console.error("No current user or post data");
      setErrorPopup({
        message: "Unable to delete post due to missing data.",
        isError: true,
      });
      return;
    }

    try {
      // Verify post exists and user has permission to delete
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${post.id}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete = post.user.isStartup
        ? post.user.startupOwnerId === currentUser.uid
        : post.user.id === currentUser.uid;

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      // Get comments and collect affected users/startups
      const commentsRef = collection(
        firestore,
        post.user.isStartup
          ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}/comments`
          : `users/${post.user.id}/posts/${post.id}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      // Clean up comments in commentedPosts for regular users
      for (const userId of affectedUserIds) {
        try {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${post.id}`
          );
          batch.delete(commentedPostRef);
          operationCount++;
          await commitBatchIfNeeded();
        } catch (error) {
          console.error(
            `Error deleting commentedPost for user ${userId}:`,
            error
          );
        }
      }

      // Clean up comments in commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${post.id}`
            );
            batch.delete(startupCommentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          } catch (error) {
            console.error(
              `Error cleaning up startup commentedPost for ${startupId}:`,
              error
            );
          }
        }
      }

      // Clean up notifications
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        try {
          // Delete all notifications related to this post
          const notificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("postId", "==", post.id)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);

          notificationsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
            operationCount++;
          });
          await commitBatchIfNeeded();

          // Clean up startup notifications
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("postId", "==", post.id)
            );
            const startupNotificationsSnapshot = await getDocs(
              startupNotificationsQuery
            );
            startupNotificationsSnapshot.docs.forEach((doc) => {
              batch.delete(doc.ref);
              operationCount++;
            });
            await commitBatchIfNeeded();
          }
        } catch (error) {
          console.error(
            `Error cleaning up notifications for user ${userDoc.id}:`,
            error
          );
        }
      }

      // Process reactions
      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupReactedPostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reactedPosts/${post.id}`
                );
                batch.delete(startupReactedPostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userReactedPostRef = doc(
                firestore,
                `users/${userId}/reactedPosts/${post.id}`
              );
              batch.delete(userReactedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up reaction for ${userId}:`, error);
          }
        }
      }

      // Process reposts
      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupRepostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reposts/${post.id}`
                );
                batch.delete(startupRepostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userRepostRef = doc(
                firestore,
                `users/${userId}/reposts/${post.id}`
              );
              batch.delete(userRepostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {
            console.error(`Error cleaning up repost for ${userId}:`, error);
          }
        }
      }

      // Delete all comments
      const commentDeletePromises = commentsSnapshot.docs.map((commentDoc) =>
        deleteDoc(commentDoc.ref)
      );
      await Promise.all(commentDeletePromises);

      // Clean up media content
      if (postData.content?.type) {
        const storage = getStorage();
        const storageBasePath = post.user.isStartup
          ? `postContent/${post.user.startupOwnerId}/${post.user.startupId}/${post.id}`
          : `postContent/${post.user.id}/${post.id}`;

        const storageRef = ref(storage, storageBasePath);

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            await Promise.all(
              postData.content.url.map(async (url) => {
                const imageRef = ref(storage, url);
                await deleteObject(imageRef);
              })
            );
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {
          console.error("Error deleting files from storage:", error);
        }
      }

      // Delete the post itself
      await deleteDoc(postRef);

      // Navigate to appropriate profile
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (post.user.isStartup) {
          const startupDoc = await getDoc(
            doc(
              firestore,
              `users/${currentUser.uid}/startups/${post.user.startupId}`
            )
          );
          if (startupDoc.exists()) {
            navigate(`/startup/${startupDoc.data().startupUrlSlug}`);
          } else {
            navigate(`/${userData.urlSlug}`);
          }
        } else {
          navigate(`/${userData.urlSlug}`);
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      setErrorPopup({
        message:
          error.message === "Not authorized to delete this post"
            ? "You don't have permission to delete this post."
            : "Failed to delete post.",
        isError: true,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = () => {
    // Implement report post functionality
    console.log("Report post clicked");
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleEditComment = (commentId, isReply = false) => {
    const commentToEdit = comments.find((c) => c.id === commentId);

    if (!commentToEdit) {
      setErrorPopup({
        message: "Comment not found.",
        isError: true,
      });
      return;
    }

    // Check if the current user has permission to edit
    let canEdit = false;

    if (commentToEdit.userId.startsWith("startup_")) {
      const startupId = commentToEdit.userId.replace("startup_", "");
      canEdit =
        commentToEdit.startupOwnerId === currentUser.uid || // Startup owner can edit
        (selectedStartup &&
          `startup_${selectedStartup.id}` === commentToEdit.userId); // Selected startup can edit its own comments
    } else {
      canEdit = commentToEdit.userId === currentUser.uid; // User can edit their own comments
    }

    if (!canEdit) {
      setErrorPopup({
        message: "You don't have permission to edit this comment.",
        isError: true,
      });
      return;
    }

    setEditingCommentId(commentId);
    const strippedText = stripHtmlTags(commentToEdit.text);
    setEditCommentText(strippedText);
    setOpenCommentDropdown(null);

    // Focus and adjust textarea
    setTimeout(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.focus();
        adjustTextareaHeight(editTextareaRef.current);
        const length = editTextareaRef.current.value.length;
        editTextareaRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  const handleSaveCommentChanges = async (commentId, isReply = false) => {
    if (!currentUser || !post) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      // Get the correct path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${post.id}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      // Check if the current user has permission to edit this comment
      let canEdit = false;

      if (commentData.userId.startsWith("startup_")) {
        // For startup comments
        const startupId = commentData.userId.replace("startup_", "");
        canEdit =
          commentData.startupOwnerId === currentUser.uid || // Startup owner can edit
          (selectedStartup &&
            `startup_${selectedStartup.id}` === commentData.userId); // Selected startup can edit its own comments
      } else {
        // For user comments
        canEdit = currentUser.uid === commentData.userId; // User can edit their own comments
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);

      // Update the comment
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Update any relevant comment records in commentedPosts collection
      if (commentData.userId.startsWith("startup_")) {
        // For startup comments
        const startupId = commentData.userId.replace("startup_", "");
        const startupCommentedPostRef = doc(
          firestore,
          `users/${commentData.startupOwnerId}/startups/${startupId}/commentedPosts/${post.id}`
        );
        const startupCommentedPostSnap = await getDoc(startupCommentedPostRef);

        if (startupCommentedPostSnap.exists()) {
          const commentedPostData = startupCommentedPostSnap.data();
          if (commentedPostData.comments?.[commentId]) {
            await updateDoc(startupCommentedPostRef, {
              [`comments.${commentId}.commentText`]: processedText,
            });
          }
        }
      } else {
        // For user comments
        const userCommentedPostRef = doc(
          firestore,
          `users/${commentData.userId}/commentedPosts/${post.id}`
        );
        const userCommentedPostSnap = await getDoc(userCommentedPostRef);

        if (userCommentedPostSnap.exists()) {
          const commentedPostData = userCommentedPostSnap.data();
          if (commentedPostData.comments?.[commentId]) {
            await updateDoc(userCommentedPostRef, {
              [`comments.${commentId}.commentText`]: processedText,
            });
          }
        }
      }

      // Update local state
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        )
      );

      // Reset edit state
      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderEditButtons = (commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(firestore, `users/${post.user.id}/posts/${post.id}`);
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPost((prevPost) => ({
        ...prevPost,
        text: updatedText,
        isEdited: isEdited,
      }));

      setIsEditingComplete(true);
      setShowEditModal(false);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        isStartupModalOpen ||
        activeEmojiPicker === "post" ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isStartupModalOpen
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker === "post"
          ? { top: `-${emojiPickerScrollPosition}px` }
          : activeEmojiPicker !== null
          ? { top: `-${commentEmojiPickerScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserProfileImage} />
      ) : (
        <MainNavbar userImage={currentUserProfileImage} />
      )}
      {showEditModal && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={post}
          onEditComplete={handleEditComplete}
        />
      )}
      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : post ? (
            <div className={classes.postCard}>
              <PostedByMessage />

              <div className={classes.postHeader}>
                <div className={classes.userInfo}>
                  <img
                    className={classes.postImage}
                    src={post.user.profileImage || defaultImage}
                    alt=""
                    onClick={() => handleNavigate(post.user.id)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className={classes.userDetails}>
                    <div className={classes.userNameContainer}>
                      <p
                        className={classes.userName}
                        onClick={() => handleNavigate(post.user.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className={classes.nameText}
                        >{`${post.user.firstName} ${post.user.lastName}`}</span>
                      </p>
                      {post.user.connectionType && (
                        <span className={classes.connectionType}>
                          • {post.user.connectionType}
                        </span>
                      )}
                    </div>
                    {post.user.bio && (
                      <p className={classes.bio}>{post.user.bio}</p>
                    )}
                    {post.user.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={post.user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {post.user.linkText || post.user.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.timeAndEditDetails}>
                      {post.createdAt && (
                        <p className={classes.time}>
                          {formatTimestamp(post.createdAt)}
                        </p>
                      )}
                      {post.isEdited && (
                        <div className={classes.editDot}>
                          •
                          <span className={classes.editedPostText}>Edited</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.postOptions}>
                  <svg
                    className={`${classes.optionsIcon} ${
                      showDropdown ? classes.optionsIconActive : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {showDropdown && renderPostDropdown()}
                </div>
              </div>
              {post.text && renderPostText(post)}
              {renderPostContent(post)}
              {(hasInteractions(post) || post.commentCount > 0) && (
                <div className={classes.postInteractionsInfo}>
                  {post.reactions && Object.keys(post.reactions).length > 0 && (
                    <div
                      className={classes.reactionsSummary}
                      onClick={handleReactionsClick}
                    >
                      <div className={classes.reactionStack}>
                        {getTopThreeEmojis(post.reactions)
                          .reverse()
                          .map((emoji, index) => (
                            <div
                              key={index}
                              className={classes.reactionEmoji}
                              style={{
                                zIndex:
                                  getTopThreeEmojis(post.reactions).length -
                                  index,
                                fontSize: "var(--emoji-font-size)",
                                marginLeft: "var(--emoji-margin-left)",
                                marginBottom: "var(--emoji-margin-bottom)",
                                marginTop: "var(--emoji-margin-top)",
                              }}
                            >
                              {emoji}
                            </div>
                          ))}
                      </div>
                      <span
                        className={classes.reactionCount}
                        style={{ marginLeft: "var(--count-margin-left)" }}
                      >
                        {Object.keys(post.reactions).length}
                      </span>
                    </div>
                  )}
                  <div className={classes.rightInteractions}>
                    {post.commentCount > 0 && (
                      <span
                        className={`${classes.commentCount} ${
                          showComments ? classes.activeCommentCount : ""
                        }`}
                        onClick={() =>
                          setShowComments((prevState) => !prevState)
                        }
                      >
                        {post.commentCount} comment
                        {post.commentCount !== 1 ? "s" : ""}
                      </span>
                    )}
                    {post.commentCount > 0 &&
                      post.reposts &&
                      Object.keys(post.reposts).length > 0 && (
                        <span className={classes.dot}>•</span>
                      )}
                    {post.reposts && Object.keys(post.reposts).length > 0 && (
                      <span
                        className={classes.repostCount}
                        onClick={handleRepostsClick}
                      >
                        {Object.keys(post.reposts).length} repost
                        {Object.keys(post.reposts).length !== 1 ? "s" : ""}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className={classes.postInteractions}>
                <StartupInteractionToggle
                  currentUser={{
                    ...currentUser,
                    firstName: post?.user?.firstName, // Get firstName from post user data
                    lastName: post?.user?.lastName, // Get lastName from post user data
                    bio: post?.user?.bio, // Get bio from post user data
                  }}
                  onStartupSelect={setSelectedStartup}
                  selectedStartup={selectedStartup}
                  currentUserProfileImage={currentUserProfileImage}
                  onModalOpen={() => {
                    setStartupModalScrollPosition(window.scrollY);
                    setIsStartupModalOpen(true);
                  }}
                  onModalClose={() => {
                    setIsStartupModalOpen(false);
                    setTimeout(() => {
                      window.scrollTo(0, startupModalScrollPosition);
                    }, 0);
                  }}
                />
                <button
                  className={`${classes.interactionButton} ${
                    (selectedStartup &&
                      post.reactions?.[`startup_${selectedStartup.id}`]) ||
                    (!selectedStartup && post.reactions?.[currentUser?.uid])
                      ? classes.emojiButton
                      : ""
                  }`}
                  onClick={() => {
                    const currentEmoji = selectedStartup
                      ? post.reactions?.[`startup_${selectedStartup.id}`]
                      : post.reactions?.[currentUser?.uid];

                    if (currentEmoji) {
                      handleEmojiSelect(currentEmoji);
                    } else {
                      setEmojiPickerScrollPosition(window.scrollY);
                      setActiveEmojiPicker("post");
                    }
                  }}
                >
                  {selectedStartup ? (
                    post.reactions?.[`startup_${selectedStartup.id}`] ? (
                      <span>
                        {post.reactions[`startup_${selectedStartup.id}`]}
                      </span>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                        />
                      </svg>
                    )
                  ) : post.reactions?.[currentUser?.uid] ? (
                    <span>{post.reactions[currentUser?.uid]}</span>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={classes.interactionButton}
                  onClick={() => setShowComments((prevState) => !prevState)}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                    />
                  </svg>
                </button>
                <button
                  className={`${classes.interactionButton} ${
                    post.reposts?.[currentUser?.uid]
                      ? classes.repostedButton
                      : ""
                  }`}
                  onClick={handleRepost}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                    />
                  </svg>
                </button>
                <button className={classes.interactionButton}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                    />
                  </svg>
                </button>
              </div>
              {activeEmojiPicker === "post" && (
                <EmojiPicker
                  onEmojiClick={handleEmojiSelect}
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, emojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {showComments && (
                <div className={classes.commentsSection}>
                  <CommentInputSection
                    currentUser={currentUser}
                    selectedStartup={selectedStartup}
                    newComment={newComment}
                    onCommentChange={handleCommentInputChange}
                    onSubmit={() => handleAddComment()}
                  />
                  <div className={classes.commentsList}>{renderComments()}</div>
                </div>
              )}
            </div>
          ) : hasAttemptedLoad ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No comment found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions || {}}
          currentUserId={currentUser?.uid}
        />
      )}
      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={() => {
            setShowRepostsModal(false);
            setIsRepostsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, repostsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts || {}}
          currentUserId={currentUser?.uid}
        />
      )}
    </div>
  );
}

export default SingleComment;
