import React, { useEffect, useState, useCallback } from "react";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Notifications.module.css";
import { firestore, auth } from "../../../firebase";
import defaultUserImage from "../../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
  writeBatch,
  getDocs,
  increment,
  addDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import NotificationDropdown from "./NotificationDropdown";
import { useNavigate, Link } from "react-router-dom";
import { useNotification } from "../../../contexts/NotificationContext";

function Notifications() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [startupData, setStartupData] = useState({});

  const { state, dispatch } = useNotification();
  const [localNewStatus, setLocalNewStatus] = useState({});
  const [deletedNotifications, setDeletedNotifications] = useState(new Set());
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const [communityData, setCommunityData] = useState({});

  const getCurrencySymbol = (currencyCode) => {
    const symbols = {
      GBP: "£",
      USD: "$",
      EUR: "€",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      // Add more currencies as needed
    };
    return symbols[currencyCode.toUpperCase()] || currencyCode;
  };

  const fetchStartupData = async (startupOwnerId, startupId) => {
    try {
      // First try to find the startup in the owner's collection
      let startupDoc = await getDoc(
        doc(firestore, `users/${startupOwnerId}/startups`, startupId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!startupDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialStartupDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/startups`, startupId)
          );
          if (potentialStartupDoc.exists()) {
            startupDoc = potentialStartupDoc;
            break;
          }
        }
      }

      if (startupDoc.exists()) {
        const data = startupDoc.data();
        return {
          name: data.startupName,
          image: data.startupImage || defaultStartupImage,
          urlSlug: data.startupUrlSlug,
        };
      }
      console.log(`No startup document found for ID: ${startupId}`);
      return {
        name: "Unknown Startup",
        image: defaultStartupImage,
        urlSlug: startupId,
      };
    } catch (error) {
      console.error(`Error fetching startup data for ${startupId}:`, error);
      return {
        name: "Unknown Startup",
        image: defaultStartupImage,
        urlSlug: startupId,
      };
    }
  };

  const handleMemberInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      const memberRef = doc(
        firestore,
        `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/members/${currentUser.uid}`
      );

      if (action === "accept") {
        const ownerNotificationsRef = collection(
          firestore,
          `users/${notificationData.communityOwnerId}/notifications`
        );
        const joinRequestQuery = query(
          ownerNotificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", notificationData.communityId)
        );
        const joinRequestSnapshot = await getDocs(joinRequestQuery);
        joinRequestSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        batch.set(memberRef, {
          status: "Member",
          joinedAt: new Date(),
        });

        const userCommunityRef = doc(
          firestore,
          `users/${currentUser.uid}/communitiesMemberOf/${notificationData.communityId}`
        );

        batch.set(userCommunityRef, {
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityImage: notificationData.communityImage,
          communityUrlSlug: notificationData.communityUrlSlug,
          ownerId: notificationData.communityOwnerId,
          joinedAt: new Date(),
          status: "Member",
        });

        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.communityOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "member_invite_accepted",
          from: currentUser.uid,
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityUrlSlug: notificationData.communityUrlSlug,
          communityImage: notificationData.communityImage,
          fromUserName: userFullName,
          createdAt: new Date(),
          isNew: true,
        });

        batch.update(notificationRef, {
          type: "member_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite accepted.`,
          updatedAt: new Date(),
        });
      } else {
        batch.delete(memberRef);

        batch.update(notificationRef, {
          type: "member_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite declined.`,
          updatedAt: new Date(),
        });
      }

      await batch.commit();

      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "member_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {
      console.error("Error handling member invite:", error);
    }
  };

  const handleModeratorInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      const moderatorRef = doc(
        firestore,
        `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/moderators/${currentUser.uid}`
      );

      if (action === "accept") {
        const memberRef = doc(
          firestore,
          `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/members/${currentUser.uid}`
        );
        const memberDoc = await getDoc(memberRef);

        if (memberDoc.exists()) {
          batch.delete(memberRef);

          const userCommunityRef = doc(
            firestore,
            `users/${currentUser.uid}/communitiesMemberOf/${notificationData.communityId}`
          );
          batch.delete(userCommunityRef);
        }

        const ownerNotificationsRef = collection(
          firestore,
          `users/${notificationData.communityOwnerId}/notifications`
        );
        const joinRequestQuery = query(
          ownerNotificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", notificationData.communityId)
        );
        const joinRequestSnapshot = await getDocs(joinRequestQuery);
        joinRequestSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        const moderatorData = {
          status: "active",
          acceptedAt: new Date(),
        };

        batch.set(moderatorRef, moderatorData);

        const communityModeratingRef = doc(
          firestore,
          `users/${currentUser.uid}/communitiesModerating/${notificationData.communityId}`
        );

        batch.set(communityModeratingRef, {
          communityOwnerId: notificationData.communityOwnerId,
          status: "active",
          acceptedAt: new Date(),
        });

        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.communityOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "moderator_invite_accepted",
          from: currentUser.uid,
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityUrlSlug: notificationData.communityUrlSlug,
          communityImage: notificationData.communityImage,
          fromUserName: userFullName,
          isStartup: true,
          startupImage: notificationData.communityImage,
          createdAt: new Date(),
          isNew: true,
        });

        batch.update(notificationRef, {
          type: "moderator_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite accepted.`,
          updatedAt: new Date(),
        });
      } else {
        batch.delete(moderatorRef);

        batch.update(notificationRef, {
          type: "moderator_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } request declined.`,
          updatedAt: new Date(),
        });
      }

      await batch.commit();

      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "moderator_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } request declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {
      console.error("Error handling moderator invite:", error);
    }
  };

  const handleEventClick = async (eventOwnerId, eventId) => {
    try {
      // First try to find the event in the owner's collection
      let eventDoc = await getDoc(
        doc(firestore, `users/${eventOwnerId}/events`, eventId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!eventDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialEventDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/events`, eventId)
          );
          if (potentialEventDoc.exists()) {
            eventDoc = potentialEventDoc;
            break;
          }
        }
      }

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        if (eventData.eventUrlSlug) {
          navigate(`/event/${eventData.eventUrlSlug}`);
        } else {
          // If no URL slug exists, try using the event ID as fallback
          navigate(`/event/${eventId}`);
        }
      } else {
        console.error("Event not found in any user's collection");
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleEventInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      // Get the current user's data for the notification
      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      if (action === "accept") {
        // Add user as attendee
        const attendeeRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}/attendees/${currentUser.uid}`
        );

        batch.set(attendeeRef, {
          attendedAt: new Date(),
        });

        // Update event's attendee count
        const eventRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}`
        );
        batch.update(eventRef, {
          attendeeCount: increment(1),
        });

        // Update user's attending count
        const userRef = doc(firestore, "users", currentUser.uid);
        batch.update(userRef, {
          attendingCount: increment(1),
        });

        // Create notification for event owner with user data included
        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.eventOwnerId}/notifications`
          )
        );
        batch.set(ownerNotificationRef, {
          type: "event_invite_accepted",
          from: currentUser.uid,
          fromUserName: userFullName,
          eventId: notificationData.eventId,
          eventName: notificationData.eventName,
          eventUrlSlug: notificationData.eventUrlSlug,
          eventImage: notificationData.eventImage,
          createdAt: new Date(),
          isNew: true,
          isStartup: true,
          startupImage: notificationData.eventImage,
        });
      }

      // Update the current user's notification
      batch.update(notificationRef, {
        type: "event_invite_response",
        message:
          action === "accept"
            ? `${notificationData.eventName}${
                notificationData.eventName.endsWith("s") ? "'" : "'s"
              } invite accepted.`
            : `${notificationData.eventName}${
                notificationData.eventName.endsWith("s") ? "'" : "'s"
              } request declined.`,
        updatedAt: new Date(),
      });

      await batch.commit();

      // Update local state
      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "event_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.eventName}${
                          notificationData.eventName.endsWith("s") ? "'" : "'s"
                        } invite accepted.`
                      : `${notificationData.eventName}${
                          notificationData.eventName.endsWith("s") ? "'" : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {
      console.error("Error handling event invite:", error);
    }
  };

  const handleStartupClick = async (startupOwnerId, startupId) => {
    try {
      // First try to find the startup in the owner's collection
      let startupDoc = await getDoc(
        doc(firestore, `users/${startupOwnerId}/startups`, startupId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!startupDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialStartupDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/startups`, startupId)
          );
          if (potentialStartupDoc.exists()) {
            startupDoc = potentialStartupDoc;
            break;
          }
        }
      }

      if (startupDoc.exists()) {
        const startupData = startupDoc.data();
        if (startupData.startupUrlSlug) {
          navigate(`/startup/${startupData.startupUrlSlug}`);
        } else {
          // If no URL slug exists, try using the startup ID as fallback
          navigate(`/startup/${startupId}`);
        }
      } else {
        console.error("Startup not found in any user's collection");
        // Optionally navigate to a 404 page or show an error message
        // navigate('/404');
      }
    } catch (error) {
      console.error("Error fetching startup data:", error);
    }
  };

  const { notification } = useNotification();

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  // Replace the existing handlePostPreviewClick in Notifications.js with this:

  const handlePostPreviewClick = async (postId) => {
    try {
      const notification = localNotifications.find((n) => n.postId === postId);

      if (!notification) {
        throw new Error("Notification not found");
      }

      // Get the original poster's ID from notification
      const postOwnerId =
        notification.originalPosterId || notification.from || currentUser.uid;

      // First try to find post in regular posts
      let postDoc = await getDoc(
        doc(firestore, `users/${postOwnerId}/posts`, postId)
      );

      // If not found in regular posts, check community posts
      if (!postDoc.exists()) {
        postDoc = await getDoc(
          doc(firestore, `users/${postOwnerId}/communityPosts`, postId)
        );
      }

      // If still not found, search through all users' communities
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check user's communities
          const communitiesRef = collection(
            firestore,
            `users/${userDoc.id}/communities`
          );
          const communitiesSnapshot = await getDocs(communitiesRef);

          for (const communityDoc of communitiesSnapshot.docs) {
            // Check community posts
            const communityPostRef = doc(
              firestore,
              `users/${userDoc.id}/communities/${communityDoc.id}/posts/${postId}`
            );
            const communityPostDoc = await getDoc(communityPostRef);

            if (communityPostDoc.exists()) {
              postDoc = communityPostDoc;
              break;
            }
          }
          if (postDoc.exists()) break;
        }
      }

      // Navigate based on where we found the post
      if (postDoc.exists()) {
        const postData = postDoc.data();
        if (
          postData.communityId ||
          postDoc.ref.path.includes("communityPosts") ||
          postDoc.ref.path.includes("communities")
        ) {
          navigate(`/communityPost/${postId}`);
        } else {
          navigate(`/post/${postId}`);
        }
      } else {
        console.error("Post not found");
        navigate(`/post/${postId}`); // Fallback to regular post route
      }
    } catch (error) {
      console.error("Error navigating to post:", error);
      navigate(`/post/${postId}`); // Fallback to regular post route on error
    }
  };

  const generateThumbnail = (videoElement, notificationId) => {
    if (videoElement && !videoThumbnails[notificationId]) {
      videoElement.addEventListener("loadeddata", () => {
        videoElement.currentTime = 1; // Set to 1 second
      });

      videoElement.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const thumbnailUrl = canvas.toDataURL();
        setVideoThumbnails((prev) => ({
          ...prev,
          [notificationId]: thumbnailUrl,
        }));
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}yr`;
    }
  };

  const handleUserClick = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const fullName = `${userData.firstName} ${userData.lastName}`;
        return {
          name: fullName,
          profileImage: userData.profileImage || profileImage,
        };
      }
      console.log(`No user document found for ID: ${userId}`);
      return { name: "Unknown User", profileImage: profileImage };
    } catch (error) {
      console.error(`Error fetching user data for ${userId}:`, error);
      return { name: "Unknown User", profileImage: profileImage };
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};

    const fetchNotifications = async () => {
      if (currentUser) {
        setIsLoading(true);
        const notificationsRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );

        unsubscribe = onSnapshot(notificationsRef, async (snapshot) => {
          try {
            const notificationsPromises = snapshot.docs.map(async (doc) => {
              if (!doc.exists()) {
                return null;
              }
              const data = doc.data();

              const validTypes = [
                "connection_request",
                "connection_accepted",
                "connection_declined",
                "reaction",
                "repost",
                "comment",
                "reply",
                "comment_reaction",
                "startup_follow",
                "team_invite",
                "team_invite_accepted",
                "team_invite_declined",
                "team_invite_response",
                "donation_received",
                "join_request",
                "join_accepted",
                "member_added",
                "join_declined",
                "event_attend",
                "organiser_invite",
                "organiser_invite_response",
                "organiser_invite_accepted",
                "event_invite",
                "event_invite_accepted",
                "event_invite_declined",
                "event_invite_response",
                "moderator_invite",
                "moderator_invite_response",
                "moderator_invite_accepted",
                "member_invite",
                "member_invite_response",
                "member_invite_accepted",
              ];

              if (!validTypes.includes(data.type)) {
                return null;
              }

              let userData = {
                name: "Unknown User",
                profileImage: profileImage,
              };
              let postData = { postPreview: {} };
              let startupInfo = null;

              if (
                data.from ||
                data.reactorId ||
                data.reposterId ||
                data.commenterId ||
                data.replierId
              ) {
                const userId =
                  data.from ||
                  data.reactorId ||
                  data.reposterId ||
                  data.commenterId ||
                  data.replierId;
                userData = await fetchUserData(userId);
              }

              if (
                [
                  "moderator_invite",
                  "moderator_invite_response",
                  "moderator_invite_accepted",
                ].includes(data.type)
              ) {
                const communityData = {
                  name: data.communityName || "Unknown Community",
                  image: data.communityImage || defaultStartupImage,
                  urlSlug: data.communityUrlSlug,
                };

                setCommunityData((prev) => ({
                  ...prev,
                  [`${data.communityOwnerId}-${data.communityId}`]:
                    communityData,
                }));
              }

              if (
                [
                  "startup_follow",
                  "team_invite",
                  "team_invite_accepted",
                  "team_invite_declined",
                  "team_invite_response",
                ].includes(data.type)
              ) {
                startupInfo = await fetchStartupData(
                  data.startupOwnerId,
                  data.startupId
                );
                setStartupData((prev) => ({
                  ...prev,
                  [`${data.startupOwnerId}-${data.startupId}`]: startupInfo,
                }));
              }

              if (
                [
                  "reaction",
                  "repost",
                  "comment",
                  "reply",
                  "comment_reaction",
                ].includes(data.type) &&
                data.postId
              ) {
                postData = await fetchPostData(
                  data.originalPosterId || currentUser.uid,
                  data.postId
                );
              }

              return {
                id: doc.id,
                ...data,
                fromUserName: userData.name,
                fromUserImage: userData.profileImage,
                startupName: startupInfo?.name || data.startupName,
                startupImage:
                  startupInfo?.image ||
                  data.startupImage ||
                  defaultStartupImage,
                startupUrlSlug: startupInfo?.urlSlug || data.startupUrlSlug,
                reactionCount: postData.reactionCount,
                commentCount: postData.commentCount,
                postPreview: postData.postPreview,
                commentText: data.commentText || "",
                replyText: data.replyText || "",
                parentCommentPreview: data.parentCommentPreview || {},
                createdAt: data.createdAt?.toDate() || new Date(),
                updatedAt:
                  data.updatedAt?.toDate() ||
                  data.createdAt?.toDate() ||
                  new Date(),
                isNew: data.isNew !== false,
              };
            });

            let newNotifications = (
              await Promise.all(notificationsPromises)
            ).filter(Boolean);
            newNotifications = filterDuplicateRequests(newNotifications);
            newNotifications.sort(
              (a, b) =>
                (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
            );

            setLocalNotifications(newNotifications);
            setLocalNewStatus((prevStatus) => {
              const newStatus = { ...prevStatus };
              newNotifications.forEach((notif) => {
                if (notif.isNew && !(notif.id in newStatus)) {
                  newStatus[notif.id] = true;
                }
              });
              return newStatus;
            });

            setIsLoading(false);
            setHasLoadedOnce(true);
          } catch (error) {
            console.error("Error processing notifications:", error);
            setIsLoading(false);
            setHasLoadedOnce(true);
          }
        });
      } else {
        setIsLoading(false);
        setHasLoadedOnce(true);
      }
    };

    fetchNotifications();

    return () => unsubscribe();
  }, [currentUser]);

  // New function to filter out duplicate connection requests
  const filterDuplicateRequests = (notifications) => {
    const connectionRequests = new Map();

    return notifications.filter((notification) => {
      if (notification.type === "connection_request") {
        if (
          !connectionRequests.has(notification.from) ||
          notification.createdAt >
            connectionRequests.get(notification.from).createdAt
        ) {
          connectionRequests.set(notification.from, notification);
          return true;
        }
        return false;
      }
      return true;
    });
  };

  const fetchPostData = async (postOwnerId, postId) => {
    try {
      // First check regular posts
      let postDoc = await getDoc(
        doc(firestore, `users/${postOwnerId}/posts`, postId)
      );

      // If not found in regular posts, check communityPosts collection
      if (!postDoc.exists()) {
        postDoc = await getDoc(
          doc(firestore, `users/${postOwnerId}/communityPosts`, postId)
        );
      }

      // If still not found, search through all users' communities
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check user's communities
          const communitiesRef = collection(
            firestore,
            `users/${userDoc.id}/communities`
          );
          const communitiesSnapshot = await getDocs(communitiesRef);

          for (const communityDoc of communitiesSnapshot.docs) {
            // Check community posts
            const communityPostRef = doc(
              firestore,
              `users/${userDoc.id}/communities/${communityDoc.id}/posts/${postId}`
            );
            const communityPostDoc = await getDoc(communityPostRef);

            if (communityPostDoc.exists()) {
              postDoc = communityPostDoc;
              break;
            }
          }
          if (postDoc.exists()) break;
        }
      }

      // If still not found, check startups' posts and community posts
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check startups
          const startupsRef = collection(
            firestore,
            `users/${userDoc.id}/startups`
          );
          const startupsSnapshot = await getDocs(startupsRef);

          for (const startupDoc of startupsSnapshot.docs) {
            // Check startup's posts
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupDoc.id}/posts/${postId}`
            );
            const startupPostDoc = await getDoc(startupPostRef);

            if (startupPostDoc.exists()) {
              postDoc = startupPostDoc;
              break;
            }

            // Check startup's community posts
            const startupCommunityPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupDoc.id}/communityPosts/${postId}`
            );
            const startupCommunityPostDoc = await getDoc(
              startupCommunityPostRef
            );

            if (startupCommunityPostDoc.exists()) {
              postDoc = startupCommunityPostDoc;
              break;
            }
          }
          if (postDoc.exists()) break;
        }
      }

      if (postDoc.exists()) {
        const postData = postDoc.data();
        return {
          reactionCount: postData.reactions
            ? Object.keys(postData.reactions).length
            : postData.communityReactions
            ? Object.keys(postData.communityReactions).length
            : 0,
          commentCount: postData.commentCount || 0,
          postPreview: {
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            text: postData.text || "",
            thumbnail: postData.content?.thumbnail || null,
            fileName: postData.content?.fileName || null,
          },
        };
      }

      console.log(`No post document found for ID: ${postId}`);
      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    } catch (error) {
      console.error(`Error fetching post data for ${postId}:`, error);
      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    }
  };

  const handleConnectionRequest = async (
    notificationId,
    fromUserId,
    action
  ) => {
    console.log(
      `Handling connection request: ${action} for notification ${notificationId} from user ${fromUserId}`
    );

    const currentUserRef = doc(firestore, `users/${currentUser.uid}`);
    const fromUserRef = doc(firestore, `users/${fromUserId}`);
    const currentUserConnectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${fromUserId}`
    );
    const fromUserConnectionRef = doc(
      firestore,
      `users/${fromUserId}/connections/${currentUser.uid}`
    );

    try {
      const fromUserData = await fetchUserData(fromUserId);
      const currentUserData = await fetchUserData(currentUser.uid);

      console.log(`From user data:`, fromUserData);
      console.log(`Current user data:`, currentUserData);

      if (action === "accept") {
        await updateDoc(currentUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        await updateDoc(fromUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_accepted",
          message: `${fromUserData.name} is now a connection.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);
        console.log(
          `Updated current user's notification:`,
          updatedNotification
        );

        // Create a new notification for the other user
        const newNotificationRef = doc(
          collection(firestore, `users/${fromUserId}/notifications`)
        );
        const newNotification = {
          type: "connection_accepted",
          message: `${currentUserData.name} is now a connection.`,
          fromUserName: currentUserData.name,
          fromUserImage: currentUserData.profileImage,
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true, // Add this line to mark the notification as new
        };
        await setDoc(newNotificationRef, newNotification);
        console.log(
          `Created new notification for other user:`,
          newNotification
        );
      } else if (action === "ignore") {
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_declined",
          message: `You declined ${fromUserData.name}'s request.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);
        console.log(
          `Updated current user's notification:`,
          updatedNotification
        );

        // Remove the connection documents
        await deleteDoc(currentUserConnectionRef);
        await deleteDoc(fromUserConnectionRef);
      }

      setNotifications((prev) =>
        prev
          .map((notif) => {
            if (notif.id === notificationId) {
              return {
                ...notif,
                type:
                  action === "accept"
                    ? "connection_accepted"
                    : "connection_declined",
                message:
                  action === "accept"
                    ? `${fromUserData.name} is now a connection.`
                    : `You declined ${fromUserData.name}'s request.`,
                fromUserName: fromUserData.name,
                fromUserImage: fromUserData.profileImage,
                updatedAt: new Date(),
              };
            }
            return notif;
          })
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );

      console.log(`Updated notifications in state`);
    } catch (error) {
      console.error("Error handling connection request:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {}
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // In Notifications.js, update the handleTeamInvite function:

  const handleTeamInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      // References to the team member documents
      const startupTeamRef = doc(
        firestore,
        `users/${notificationData.startupOwnerId}/startups/${notificationData.startupId}/team/${currentUser.uid}`
      );
      const userTeamRef = doc(
        firestore,
        `users/${currentUser.uid}/team/${notificationData.startupId}`
      );

      if (action === "accept") {
        const teamMemberData = {
          userId: currentUser.uid,
          role: notificationData.role,
          isCofounder: notificationData.isCofounder || false,
          status: "active",
          acceptedAt: new Date(),
          updatedAt: new Date(),
        };

        const userTeamData = {
          startupId: notificationData.startupId,
          startupOwnerId: notificationData.startupOwnerId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          role: notificationData.role,
          isCofounder: notificationData.isCofounder || false,
          status: "active",
          acceptedAt: new Date(),
          updatedAt: new Date(),
        };

        batch.set(startupTeamRef, teamMemberData);
        batch.set(userTeamRef, userTeamData);

        // Create notification for startup owner
        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.startupOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "team_invite_accepted",
          from: currentUser.uid,
          startupId: notificationData.startupId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          startupImage: notificationData.startupImage,
          role: notificationData.role,
          isCofounder: notificationData.isCofounder || false,
          createdAt: new Date(),
          isNew: true,
        });
      } else {
        // If declined, delete the team member documents
        batch.delete(startupTeamRef);
        batch.delete(userTeamRef);
      }

      // Update the current user's notification
      batch.update(notificationRef, {
        type: "team_invite_response",
        message:
          action === "accept"
            ? `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite accepted.`
            : `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite declined.`,
        updatedAt: new Date(),
      });

      await batch.commit();

      // Update local state
      setLocalNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "team_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {
      console.error("Error handling team invite:", error);
      throw error;
    }
  };

  const renderNotificationContent = (notification) => {
    const getNotificationImage = () => {
      // Special cases where we always want to show event image regardless of user/startup context
      if (
        ["organiser_invite_accepted", "event_invite_accepted"].includes(
          notification.type
        )
      ) {
        return notification.eventImage || defaultStartupImage;
      }

      // Rest of your existing logic for other notification types
      if (
        [
          "team_invite",
          "team_invite_accepted",
          "team_invite_response",
          "startup_follow",
          "donation_received",
          "event_attend",
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
          "organiser_invite",
          "organiser_invite_response",
          "event_invite",
          "event_invite_response",
          "moderator_invite",
          "moderator_invite_accepted",
          "moderator_invite_response",
        ].includes(notification.type) ||
        notification.isStartup
      ) {
        return (
          notification.communityImage ||
          notification.eventImage ||
          notification.startupImage ||
          notification.reactorImage ||
          defaultStartupImage
        );
      }

      return notification.fromUserImage || defaultUserImage;
    };

    const getUserName = () => {
      if (
        notification.isStartup ||
        notification.type === "moderator_invite" ||
        notification.type === "moderator_invite_response" ||
        notification.type === "organiser_invite" ||
        notification.type === "organiser_invite_response"
      ) {
        // Add organiser notification types
        return (
          notification.eventName || // Add this first to prioritize event name
          notification.startupName ||
          notification.reactorName ||
          notification.reposterName ||
          notification.commenterName ||
          notification.replierName ||
          "Unknown Startup" ||
          notification.communityName ||
          "Unknown Community"
        );
      }
      return (
        notification.fromUserName ||
        notification.reactorName ||
        notification.reposterName ||
        notification.commenterName ||
        notification.replierName ||
        "Unknown User"
      );
    };

    const handleOrganiserInvite = async (notificationId, action) => {
      try {
        const batch = writeBatch(firestore);

        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const notificationDoc = await getDoc(notificationRef);
        const notificationData = notificationDoc.data();

        if (!notificationDoc.exists()) {
          throw new Error("Notification not found");
        }

        const hostRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}/hosts/${currentUser.uid}`
        );

        if (action === "accept") {
          const hostData = {
            userId: currentUser.uid,
            status: "active",
            acceptedAt: new Date(),
            updatedAt: new Date(),
          };

          batch.set(hostRef, hostData);

          const eventOrganisingRef = doc(
            firestore,
            `users/${currentUser.uid}/eventsOrganising/${notificationData.eventId}`
          );

          batch.set(eventOrganisingRef, {
            eventOwnerId: notificationData.eventOwnerId,
            status: "active",
            acceptedAt: new Date(),
            updatedAt: new Date(),
          });

          const ownerNotificationRef = doc(
            collection(
              firestore,
              `users/${notificationData.eventOwnerId}/notifications`
            )
          );

          batch.set(ownerNotificationRef, {
            type: "organiser_invite_accepted",
            from: currentUser.uid,
            eventId: notificationData.eventId,
            eventName: notificationData.eventName,
            eventUrlSlug: notificationData.eventUrlSlug,
            eventImage: notificationData.eventImage,
            fromUserName: notification.fromUserName,
            isStartup: true,
            startupImage: notificationData.eventImage,
            createdAt: new Date(),
            isNew: true,
          });

          batch.update(notificationRef, {
            type: "organiser_invite_response",
            message: `${notificationData.eventName}${
              notificationData.eventName.endsWith("s") ? "'" : "'s"
            } invite accepted.`,
            updatedAt: new Date(),
          });
        } else {
          batch.delete(hostRef);

          batch.update(notificationRef, {
            type: "organiser_invite_response",
            message: `${notificationData.eventName}${
              notificationData.eventName.endsWith("s") ? "'" : "'s"
            } request declined.`,
            updatedAt: new Date(),
          });
        }

        await batch.commit();

        setNotifications((prev) =>
          prev
            .map((notif) =>
              notif.id === notificationId
                ? {
                    ...notif,
                    type: "organiser_invite_response",
                    message:
                      action === "accept"
                        ? `${notificationData.eventName}${
                            notificationData.eventName.endsWith("s")
                              ? "'"
                              : "'s"
                          } invite accepted.`
                        : `${notificationData.eventName}${
                            notificationData.eventName.endsWith("s")
                              ? "'"
                              : "'s"
                          } request declined.`,
                    updatedAt: new Date(),
                  }
                : notif
            )
            .sort(
              (a, b) =>
                (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
            )
        );
      } catch (error) {
        console.error("Error handling organiser invite:", error);
      }
    };

    const handleJoinRequest = async (notificationId, fromUserId, action) => {
      try {
        const batch = writeBatch(firestore);

        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const notificationDoc = await getDoc(notificationRef);

        if (!notificationDoc.exists()) {
          throw new Error("Notification not found");
        }

        const notificationData = notificationDoc.data();

        // Fetch the complete community data first
        const communityRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${notificationData.communityId}`
        );
        const communityDoc = await getDoc(communityRef);
        const communityData = communityDoc.exists() ? communityDoc.data() : {};

        const fromUserDoc = await getDoc(doc(firestore, "users", fromUserId));
        const fromUserData = fromUserDoc.data();

        const memberRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${notificationData.communityId}/members/${fromUserId}`
        );

        if (action === "accept") {
          batch.update(memberRef, {
            status: "Member",
            joinedAt: new Date(),
          });

          const userCommunityRef = doc(
            firestore,
            `users/${fromUserId}/communitiesMemberOf/${notificationData.communityId}`
          );

          batch.set(userCommunityRef, {
            communityId: notificationData.communityId,
            communityName: notificationData.communityName,
            communityImage: notificationData.communityImage,
            communityUrlSlug: notificationData.communityUrlSlug,
            ownerId: currentUser.uid,
            joinedAt: new Date(),
            status: "Member",
            bio: communityData.bio || "",
            link: communityData.link || "",
            linkText: communityData.linkText || "",
            tagline: communityData.tagline || "",
            industry: communityData.industry || "",
          });
          const userNotificationRef = doc(
            collection(firestore, `users/${fromUserId}/notifications`)
          );

          batch.set(userNotificationRef, {
            type: "join_accepted",
            communityId: notificationData.communityId,
            communityName: notificationData.communityName,
            communityImage: notificationData.communityImage,
            communityUrlSlug: notificationData.communityUrlSlug,
            isStartup: true,
            startupImage: notificationData.communityImage,
            startupName: notificationData.communityName,
            message: `${notificationData.communityName}${
              notificationData.communityName.endsWith("s") ? "'" : "'s"
            } request accepted.`,
            createdAt: new Date(),
            isNew: true,
          });

          batch.update(notificationRef, {
            type: "member_added",
            fromUserName: `${fromUserData.firstName} ${fromUserData.lastName}`,
            message: `${fromUserData.firstName} ${fromUserData.lastName} is now a member.`,
            updatedAt: new Date(),
          });
        } else {
          batch.delete(memberRef);

          const userCommunityRef = doc(
            firestore,
            `users/${fromUserId}/communitiesMemberOf/${notificationData.communityId}`
          );
          batch.delete(userCommunityRef);

          batch.update(notificationRef, {
            type: "join_declined",
            fromUserName: `${fromUserData.firstName} ${fromUserData.lastName}`,
            message: `${fromUserData.firstName} ${fromUserData.lastName}'s request declined.`,
            updatedAt: new Date(),
          });
        }

        await batch.commit();
      } catch (error) {
        console.error("Error handling join request:", error);
      }
    };

    const handleCommunityClick = async (communityId) => {
      try {
        // First get all users since we don't know which user owns the community
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check each user's communities
          const communityRef = doc(
            firestore,
            `users/${userDoc.id}/communities/${communityId}`
          );
          const communityDoc = await getDoc(communityRef);

          if (communityDoc.exists()) {
            const communityData = communityDoc.data();
            if (communityData.communityUrlSlug) {
              navigate(`/community/${communityData.communityUrlSlug}`);
              return;
            }
          }
        }

        console.error("Community not found");
        // Optionally navigate to a 404 page or show an error message
        // navigate('/404');
      } catch (error) {
        console.error("Error navigating to community:", error);
      }
    };

    const renderTeamNotification = (notification, type) => {
      return (
        <div className={classes.notificationText}>
          <div className={classes.notificationMessage}>
            <p>
              {type === "invite_accepted" && (
                <>
                  <span
                    className={`${classes.userName} ${classes.clickable}`}
                    onClick={() => handleUserClick(notification.from)}
                  >
                    {getUserName()}
                  </span>
                  <span className={classes.messageText}> joined the team.</span>
                  {/* <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span> */}
                </>
              )}
              {type === "invite_response" && (
                <>
                  <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span>
                  <span className={classes.messageText}>
                    {notification.message.includes("accepted")
                      ? "'s invite accepted."
                      : "'s invite declined."}
                  </span>
                </>
              )}
              {type === "invite" && (
                <>
                  <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span>
                  <span className={classes.messageText}>
                    {" "}
                    wants you to join the team.
                  </span>
                </>
              )}
            </p>
          </div>
          <div className={classes.notificationTimestamp}>
            {formatTimestamp(notification.createdAt)}
          </div>
          {type === "invite" && (
            <div className={classes.interactions}>
              <button
                onClick={() => handleTeamInvite(notification.id, "accept")}
                className={classes.button}
              >
                Accept
              </button>
              <button
                onClick={() => handleTeamInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                Decline
              </button>
            </div>
          )}
        </div>
      );
    };

    switch (notification.type) {
      case "team_invite_response":
        return renderTeamNotification(notification, "invite_response");
      case "team_invite_accepted":
        return renderTeamNotification(notification, "invite_accepted");
      case "team_invite":
        return renderTeamNotification(notification, "invite");
      case "member_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                </span>
                <span className={classes.messageText}> wants you to join.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleMemberInvite(notification.id, "accept")}
                className={classes.button}
              >
                Accept
              </button>
              <button
                onClick={() => handleMemberInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                Decline
              </button>
            </div>
          </div>
        );

      case "member_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "member_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}> is now a member.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "moderator_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to be a moderator.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleModeratorInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  className="w-6 h-6"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleModeratorInvite(notification.id, "decline")
                }
                className={classes.ignoreButton}
              >
                <svg
                  className="w-6 h-6"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "moderator_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "moderator_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now a moderator.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "organiser_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to be an organiser.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleOrganiserInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleOrganiserInvite(notification.id, "decline")
                }
                className={classes.ignoreButton}
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "organiser_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                  {notification.eventName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "organiser_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now an organiser.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "startup_follow":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>is now a follower.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "event_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to attend.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleEventInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() => handleEventInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "event_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                  {notification.eventName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "event_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now an attendee.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "event_attend":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  {notification.message || "is now an attendee"}{" "}
                  {/* Add fallback for existing notifications */}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "join_request":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}> wants to join.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleJoinRequest(
                    notification.id,
                    notification.from,
                    "accept"
                  )
                }
                className={classes.button}
              >
                Accept
              </button>
              <button
                onClick={() =>
                  handleJoinRequest(
                    notification.id,
                    notification.from,
                    "decline"
                  )
                }
                className={classes.ignoreButton}
              >
                Decline
              </button>
            </div>
          </div>
        );

      case "join_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}> request accepted.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "member_added":
      case "join_declined":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span className={`${classes.userName} ${classes.clickable}`}>
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {notification.type === "member_added"
                    ? " is now a member."
                    : "'s request declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "connection_request":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>wants to connect.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "accept"
                  )
                }
                className={classes.button}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "ignore"
                  )
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "connection_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  is now a connection.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "connection_declined":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                  {notification.fromUserName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}> request declined.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.reactorId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reacted</span>
                <span className={classes.emoji}>{notification.emoji}</span>
                <span className={classes.messageText}>to this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div
                className={`${classes.post} ${
                  !notification.postPreview.mediaType &&
                  notification.postPreview.text
                    ? classes.textOnlyPost
                    : ""
                }`}
              >
                <div className={classes.postContent}>
                  {notification.postPreview &&
                    notification.postPreview.mediaType &&
                    renderFilePreview(notification.postPreview)}
                  {notification.postPreview &&
                    notification.postPreview.text && (
                      <p className={classes.textPreview}>
                        {notification.postPreview.text}
                      </p>
                    )}
                </div>
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "repost":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={classes.userName}
                  onClick={() => handleUserClick(notification.reposterId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reposted this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div
                className={`${classes.post} ${
                  !notification.postPreview.mediaType &&
                  notification.postPreview.text
                    ? classes.textOnlyPost
                    : ""
                }`}
              >
                <div className={classes.postContent}>
                  {notification.postPreview &&
                    notification.postPreview.mediaType &&
                    renderFilePreview(notification.postPreview)}
                  {notification.postPreview &&
                    notification.postPreview.text && (
                      <p className={classes.textPreview}>
                        {notification.postPreview.text}
                      </p>
                    )}
                </div>
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "comment":
      case "reply":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() =>
                    handleUserClick(
                      notification.commenterId || notification.replierId
                    )
                  }
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  {notification.type === "comment"
                    ? "commented on this."
                    : "replied to this."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText || notification.replyText}
                </div>
              </div>

              {notification.type === "reply" &&
                notification.parentCommentPreview && (
                  <div className={classes.commentReply}>
                    <div className={classes.parentCommentPreview}>
                      {notification.parentCommentPreview.text}
                    </div>
                  </div>
                )}

              <div className={classes.postContent}>
                {notification.postPreview &&
                  notification.postPreview.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview && notification.postPreview.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case "donation_received":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>
                  {` donated ${getCurrencySymbol(notification.currency)}${
                    notification.amount
                  }`}
                  .
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            {notification.message && (
              <div className={`${classes.postPreview}`}>
                <div className={`${classes.post} ${classes.textOnlyPost}`}>
                  <div className={classes.postContent}>
                    <p className={classes.textPreview}>
                      {notification.message}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case "comment_reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.reactorId)}
                >
                  {getUserName()}
                </span>
                <span className={classes.messageText}>reacted</span>
                <span className={classes.emoji}>{notification.emoji}</span>
                <span className={classes.messageText}>to this.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText}
                </div>
              </div>
              <div className={classes.postContent}>
                {notification.postPreview &&
                  notification.postPreview.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview && notification.postPreview.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
            {(notification.reactionCount > 0 ||
              notification.commentCount > 0 ||
              notification.repostCount > 0) && (
              <div className={classes.postInteractionsInfo}>
                {notification.reactionCount > 0 && (
                  <span className={classes.reactionCount}>
                    {notification.reactionCount} reaction
                    {notification.reactionCount !== 1 ? "s" : ""}
                  </span>
                )}
                {((notification.reactionCount > 0 &&
                  notification.commentCount > 0) ||
                  (notification.reactionCount > 0 &&
                    notification.repostCount > 0)) && (
                  <span className={classes.dot}>•</span>
                )}
                {notification.commentCount > 0 && (
                  <span className={classes.commentCount}>
                    {notification.commentCount} comment
                    {notification.commentCount !== 1 ? "s" : ""}
                  </span>
                )}
                {notification.commentCount > 0 &&
                  notification.repostCount > 0 && (
                    <span className={classes.dot}>•</span>
                  )}
                {notification.repostCount > 0 && (
                  <span className={classes.repostCount}>
                    {notification.repostCount} repost
                    {notification.repostCount !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      console.log("Video thumbnail URL:", thumbnailUrl); // Add this for debugging
      return (
        <div className={classes.mediaPreview}>
          {thumbnailUrl ? (
            <img
              src={thumbnailUrl}
              alt="Video preview"
              onError={(e) => {
                console.error("Error loading thumbnail:", e);
                e.target.src = "/path/to/fallback/image.jpg"; // Provide a fallback image
              }}
            />
          ) : (
            <div className={classes.videoPlaceholder}>Video</div>
          )}
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const userImage = userData.profileImage || profileImage;

  const deleteNotification = async (notificationId) => {
    try {
      console.log(`Attempting to delete notification: ${notificationId}`);
      console.log(`Current user: ${currentUser?.uid}`);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );

      // Check if the document exists before attempting to delete
      const docSnap = await getDoc(notificationRef);
      if (!docSnap.exists()) {
        console.log(
          `Notification ${notificationId} already deleted or doesn't exist`
        );
      } else {
        // Attempt to delete the document only if it exists
        await deleteDoc(notificationRef);
        console.log(`Firestore document deleted: ${notificationId}`);
      }

      // Update local state
      setLocalNotifications((prev) =>
        prev.filter((notif) => notif.id !== notificationId)
      );

      // Update localNewStatus
      setLocalNewStatus((prev) => {
        const newStatus = { ...prev };
        delete newStatus[notificationId];
        return newStatus;
      });

      // Add to deletedNotifications set
      setDeletedNotifications((prev) => new Set(prev).add(notificationId));

      console.log(`Local state updated for notification: ${notificationId}`);
    } catch (error) {
      console.error(`Error deleting notification ${notificationId}:`, error);
    }
  };

  const [localNotifications, setLocalNotifications] = useState([]);

  // const markNotificationsAsViewed = async () => {
  //   const batch = writeBatch(firestore);
  //   const notificationsToUpdate = [];

  //   for (const notification of notifications) {
  //     if (notification.isNew) {
  //       const notificationRef = doc(
  //         firestore,
  //         `users/${currentUser.uid}/notifications/${notification.id}`
  //       );
  //       const docSnap = await getDoc(notificationRef);
  //       if (docSnap.exists()) {
  //         batch.update(notificationRef, { isNew: false });
  //         notificationsToUpdate.push(notification.id);
  //       } else {
  //         console.log(`Notification ${notification.id} no longer exists.`);
  //       }
  //     }
  //   }

  //   if (notificationsToUpdate.length > 0) {
  //     await batch.commit();
  //     setNewNotificationsCount(0);
  //     setNotifications((prev) =>
  //       prev.map((notif) =>
  //         notificationsToUpdate.includes(notif.id)
  //           ? { ...notif, isNew: false }
  //           : notif
  //       )
  //     );
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     if (currentUser) {
  //       markNotificationsAsViewed();
  //     }
  //   };
  // }, [currentUser, notifications]);

  const markNotificationsAsOld = useCallback(async () => {
    const batch = writeBatch(firestore);
    let updatedCount = 0;

    for (const notification of localNotifications) {
      if (
        localNewStatus[notification.id] &&
        !deletedNotifications.has(notification.id)
      ) {
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notification.id}`
        );

        // Check if the document still exists before updating
        const docSnap = await getDoc(notificationRef);
        if (docSnap.exists()) {
          batch.update(notificationRef, { isNew: false });
          updatedCount++;
        }
      }
    }

    if (updatedCount > 0) {
      try {
        await batch.commit();
        console.log(`${updatedCount} notifications marked as old`);
        // Reset the notification count in the context
        dispatch({ type: "RESET_COUNT" });
      } catch (error) {
        console.error("Error marking notifications as old:", error);
      }
    }

    // Clear the deletedNotifications set after processing
    setDeletedNotifications(new Set());
  }, [
    localNotifications,
    localNewStatus,
    currentUser,
    dispatch,
    deletedNotifications,
  ]);

  useEffect(() => {
    return () => {
      if (currentUser) {
        markNotificationsAsOld();
      }
    };
  }, [currentUser, markNotificationsAsOld]);

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage || profileImage} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            </div>
          ) : hasLoadedOnce && localNotifications.length > 0 ? (
            <div className={classes.notifications}>
              {localNotifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`${classes.notification} ${
                    notification.type === "connection_accepted" ||
                    notification.type === "connection_declined"
                      ? classes.singleLineNotification
                      : ""
                  }`}
                >
                  <div className={classes.notificationContent}>
                    <div className={classes.profileImageContainer}>
                      <img
                        src={
                          notification.type.includes("team") ||
                          notification.type === "startup_follow" ||
                          notification.type === "event_attend" || // Add this condition
                          notification.type === "donation_received" ||
                          notification.isStartup
                            ? notification.eventImage || // Add this
                              notification.startupImage ||
                              notification.reactorImage
                            : notification.fromUserImage
                        }
                        alt={
                          notification.type.includes("team") ||
                          notification.type === "startup_follow" ||
                          notification.type === "event_attend" || // Add this condition
                          notification.type === "donation_received" ||
                          notification.isStartup
                            ? notification.eventName || // Add this
                              notification.startupName ||
                              notification.reactorName
                            : notification.fromUserName
                        }
                        className={
                          notification.type.includes("team") ||
                          notification.type.includes("moderator") ||
                          notification.type === "startup_follow" ||
                          notification.type === "event_attend" || // Add this condition
                          notification.type === "donation_received" ||
                          notification.isStartup
                            ? classes.startupImage // Use existing startupImage class
                            : classes.profileImage
                        }
                        onClick={() =>
                          notification.type.includes("team") ||
                          notification.type === "startup_follow" ||
                          notification.type === "event_attend" || // Add this condition
                          notification.type === "donation_received" ||
                          notification.isStartup
                            ? notification.type === "event_attend"
                              ? handleEventClick(
                                  notification.eventOwnerId,
                                  notification.eventId
                                )
                              : handleStartupClick(
                                  notification.startupOwnerId ||
                                    notification.reactorId?.split("_")[1],
                                  notification.startupId
                                )
                            : handleUserClick(
                                notification.from || notification.reactorId
                              )
                        }
                      />
                      {localNewStatus[notification.id] && (
                        <div
                          className={
                            notification.type.includes("team") ||
                            notification.type.includes("moderator") ||
                            notification.type === "startup_follow" ||
                            notification.type === "event_attend" || // It's already here, but let's make sure it's working
                            notification.isStartup
                              ? classes.startupNotificationIndicator
                              : classes.newNotificationIndicator
                          }
                        ></div>
                      )}
                    </div>
                    {renderNotificationContent(notification)}
                    <NotificationDropdown
                      onDelete={() => deleteNotification(notification.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : hasLoadedOnce ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No notifications found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <Link to="/dashboard" className={classes.homeButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Home
              </Link>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
